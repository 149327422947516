import React, { useState, Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Col, Row, Button, Form, FormGroup, Input } from 'reactstrap';
import { copyTextToClipboard } from '../../actions/map';


const ShareMapLocation = ({ resourceUrl, providerUrl, copyTextToClipboard, displayModalShareUrl, toggleModalShareUrl }) => {
  
  const [formData, setFormData] = useState({
    shareUrl: ''
  });

  const { shareUrl } = formData;

  const onChange = e =>
      setFormData({[e.target.name]: e.target.value });

  const onCopyText = async e => {
    await copyTextToClipboard(formData);
    toggleModalShareUrl(!displayModalShareUrl);
  };

  useEffect(() => {
    setFormData({ 
      shareUrl: !resourceUrl && !providerUrl ? '' : resourceUrl ? resourceUrl : providerUrl
    });
  }, []);

  return (
    <Fragment>
          <Form className="msstd-form">
            <Row form>
              <Col>
                <FormGroup>
                  <Input type="textarea" 
                  name="shareUrl" 
                  id="shareUrl" 
                  placeholder="Enter Your Email Address. (It will be shared with this user so that they can respond to you.)" 
                  value={shareUrl}
                  onChange={e => onChange(e)} 
                  required
                  />
                </FormGroup>
              </Col>
            </Row>
            <Button type="button" onClick={(e) => onCopyText(e)}>Copy to Clipboard</Button>
          </Form>
    </Fragment>
  );
};

ShareMapLocation.propTypes = {
  copyTextToClipboard: PropTypes.func.isRequired
};


export default connect(
  null,
  { copyTextToClipboard }
)(ShareMapLocation);
