import React, { Fragment } from 'react';
import { Form, FormGroup, Input, Button, InputGroup, InputGroupAddon } from 'reactstrap';

const SearchBar = ({ string, setString, onClick }) => {

    const handleChange = (e) => {
        setString(e.target.value)
    }

    const handleKeyPress = (e) => {
        e.preventDefault();
        onClick();
    }; 

    return (
        <div>
            <Form onSubmit={e=>handleKeyPress(e)}>
                <InputGroup>
                    <Input
                    type="text"
                    name="textSearch"
                    placeholder={`Search by spot name, category, subject, or topic`}
                    value={string}
                    className='form-control search-bar'
                    onChange={(e)=>handleChange(e)}
                    />  
                    <InputGroupAddon addonType="append" >
                      <button type='button' className='append-form-button' value={string} onClick={(e)=>handleKeyPress(e)}>Search</button>
                    </InputGroupAddon>
                </InputGroup>
                
            </Form>
        </div>
    );
};

export default SearchBar;