import React from 'react';
import { Container } from 'reactstrap';

const PrivacyPolicy = () => {
                

    return(
        <Container>
            <h2 className="section-heading">
                <span className="c8 c1">Privacy Policy</span>
            </h2>
            <h4 className="c13" id="h.qta3qlx20sxl">
                    <span className="c8 c1">Last updated: May 26, 2022</span>
                </h4>
            <div className="ml-4">
                <div className="my-3">
                    <p className="c4">
                        <span className="c0 c1">This Privacy Policy includes important information about your personal data and we encourage you to read it carefully.</span>
                    </p>
                    <p className="c4">
                        <span className="c0 c1">This Privacy Policy (&ldquo;Policy&rdquo;) describes the &ldquo;Personal Data&rdquo; that we collect, how we use it, how we share it, your rights and choices, and how you can contact us about our privacy practices. </span>
                    </p>
                    <p className="c4">
                        <span className="c0 c1">When this Policy mentions &ldquo;MySchool,&rdquo; &ldquo;we,&rdquo; &ldquo;us,&rdquo; or &ldquo;our,&rdquo; it refers to MySchool, Inc., located at 651 N. Broad St., Suite 206, Middletown, 19709, United States.</span>
                    </p>
                    <p className="c4">
                        <span className="c0 c1">&ldquo;Site&rdquo; refers to all website URLs, and mobile applications owned by MySchool. </span>
                    </p>
                    <p className="c4">
                        <span className="c0 c1">&ldquo;Personal Data&rdquo; refers to any information that relates to an identified or identifiable individual, and can include information about how you engage with our Services.</span>
                    </p>
                    <p className="c4">
                        <span className="c0 c1">&ldquo;Services&rdquo; means the products and services that MySchool indicates are covered by this Policy.</span>
                    </p>
                    <p className="c4">
                        <span className="c10">Any and all payment processing services through or in connection with your use of the MySchool Platform (&quot;Payment Services&quot;) are provided to a third party service (Stripe - https://stripe.com/) and are subject to the terms of use of those Payment Services.</span>
                    </p>
                    <p className="c4">
                        <span className="c0 c1">&ldquo;You&rdquo; or &ldquo;End Users&rdquo; refers to all users, whether or not you are using the Site without registering or logged in as a registered user.</span>
                    </p>
                    <p className="c4">
                        <span className="c0 c1">A &ldquo;Profile&rdquo; refers to End Users who are saving Personal Data in MySchool&rsquo;s database to support MySchool&rsquo;s Services.</span>
                    </p>
                    <p className="c4">
                        <span className="c0 c1">A &ldquo;Provider&rdquo; refers to End Users who are saving Personal Data about an organization, that may or may not be formally registered with any government body, in MySchool&rsquo;s database to support MySchool&rsquo;s Services.</span>
                    </p>
                </div>
                <div>
                    <h4 className="mt-4 mb-2">
                        <span className="c8 c1">1. Personal Data That We Collect And How We Use and Share It</span>
                    </h4>
                    <h4 className="text-1-1rem">
                        1.1 End Users
                    </h4>
                    <div className='ml-4'>
                        <h5 className="c6" id="h.5jp5k1pe4qvy">
                            <span className="c8">Personal Data That We Collect About End Users</span>
                        </h5>
                            
                        <ol className="c3 lst-kix_rqp8bq43kgf1-0 start" start="1">
                            <li className="c2 li-bullet-0">
                                <span className="c0 c1">MySchool collects an email address, username, password, and the option to receive emails from MySchool at the aforementioned email address.</span>
                                </li>
                                <li className="c2 li-bullet-0">
                                <span className="c0 c1">For End Users paying through the MySchool platform, MySchool only collects a customer identification code that allows for ease of payment processing through our Payment Service. &nbsp;All other payment information is collected by our third party Payment Service. &nbsp; </span>
                                </li>
                                <li className="c2 li-bullet-0">
                                <span className="c0">For End Users saving &ldquo;Favorites,&rdquo; links to data, which may include Personal Data submitted by MySchool End Users will be saved under your End User. &nbsp;These are denoted in the site by a heart icon.</span>
                                </li>
                        </ol>
                        <h5 className="c6" id="h.jrovx69ybv83">
                                <span className="c8 c1">How We Use and Share Personal Data of End Users</span>
                        </h5>
                        <ol className="c3 lst-kix_bnau88fs9q6i-0 start" start="1">
                                <li className="c2 li-bullet-0">
                                <span className="c0 c1">We use your Personal Data to provide the End User Service to you, including security, support, personalization and messages related to the End User Service.</span>
                                </li>
                                <li className="c7 li-bullet-0">
                                <span className="c0 c1">Advertising. We may use your Personal Data to assess your eligibility for and offer you other End User Services. We do not share End User Personal Data with third parties for their marketing or advertising and we do not sell the data of End Users.</span>
                                </li>
                        </ol>
                    </div>
                    <h4 className="text-1-1rem">
                        1.2 Profiles
                    </h4>
                    <div className="ml-4">
                        <p className="c4">
                            <span className="c0 c1">Profiles are created by End Users to extend the capabilities and personalization of MySchool Services for individuals. </span>
                        </p>
                        <h5 className="c6" id="h.wtnqftismg1k">
                            <span className="c8">Personal Data That We Collect About Profiles</span>
                        </h5>
                        <ol className="c3 lst-kix_uycl07bhby62-0 start" start="1">
                            <li className="c2 li-bullet-0">
                            <span className="c0 c1">MySchool collects geographic location information, including city, state, zip code, country, and geolocation information including the longitude and latitude of Profiles. </span>
                            </li>
                            <li className="c2 li-bullet-0">
                            <span className="c0 c1">MySchool processes and shares anonymous address, city, state, zip code and country information with a third party mapping service(MapQuest, Inc. https://mapquest.com) to determine geolocation information including longitude and latitude. &nbsp;No Personal Data other than the address, city, state, zip code and country are sent that could identify the End User.</span>
                            </li>
                            <li className="c2 li-bullet-0">
                            <span className="c0 c1">Geolocation information may be shared, as an option, by Profiles in order to extend MySchool Services.</span>
                            </li>
                            <li className="c2 li-bullet-0">
                            <span className="c0 c1">Profiles can include information about an End User's children, including sex, age and an identifying name or nickname. &nbsp;This information may be shared, as an option, by Profiles in order to extend MySchool Services.</span>
                            </li>
                        </ol>
                        <h5 className="c6" id="h.64w3euqzaapd">
                            <span className="c8 c1">How We Use and Share Personal Data of Profiles</span>
                        </h5>
                        <ol className="c3 lst-kix_7z5yn9qiemwc-0 start" start="1">
                            <li className="c2 li-bullet-0">
                            <span className="c0 c1">We use your Personal Data to provide the End User Service to you, including personalization to the End User Service and connection to other End Users.</span>
                            </li>
                            <li className="c7 li-bullet-0">
                            <span className="c0">Advertising. We may use your Personal Data to assess your eligibility for and offer you other End User Services. Anonymized data may be shared with third parties.</span>
                            </li>
                        </ol>
                    </div>
                    <h4 className="text-1-1rem">
                        1.3 Providers
                    </h4>
                </div>

                <div className="ml-4">
                    <p className="c4">
                        <span className="c0">Providers are created by End Users to extend the capabilities and personalization of MySchool Services for organizations. </span>
                    </p>
                    <h5>
                        <span className="c8">Personal Data That We Collect About Providers</span>
                    </h5>
                        <ol className="c3 lst-kix_8rqviwcauaj0-0 start" start="1">
                            <li className="c2 li-bullet-0">
                            <span className="c0 c1">MySchool collects geographic location information, including city, state, zip code, country, and geolocation information including the longitude and latitude of Providers. </span>
                            </li>
                            <li className="c2 li-bullet-0">
                            <span className="c0 c1">MySchool processes and shares address, city, state, zip code and country information with a third party mapping service(MapQuest, Inc. https://mapquest.com) to determine geolocation information including longitude and latitude. &nbsp;No Personal Data other than the address, city, state, zip code and country are sent that could identify the End User.</span>
                            </li>
                            <li className="c2 li-bullet-0">
                            <span className="c0 c1">Geolocation information are shared, by Providers in order to extend MySchool Services.</span>
                            </li>
                        </ol>
                    <h5>
                        <span className="c8 c1">How We Use and Share Personal Data of Providers</span>
                    </h5>
                        <ol className="c3 lst-kix_dvf09h45ir5j-0 start" start="1">
                            <li className="c2 li-bullet-0">
                            <span className="c0 c1">We use your Personal Data to provide the End User Service to you, including personalization to the End User Service and connection to other End Users.</span>
                            </li>
                            <li className="c7 li-bullet-0">
                            <span className="c0 c1">Advertising. We may use your Personal Data to assess your eligibility for and offer you other End User Services. We do not share End User Personal Data with third parties for their marketing or advertising and we do not sell the data of End Users.</span>
                            </li>
                        </ol>
                </div>
                
                <div className="my-4">
                    <h4 className="mt-4 mb-2">
                        <span className="c8 c1">2. More Ways We Collect, Use and Share Personal Data</span>
                    </h4>
                    <p className="c4">
                        <span className="c0 c1">In addition to the ways we collect and use Personal Data described above, we also process your Personal Data as follows.</span>
                    </p>
                    <p className="c4 c12">
                        <span className="c8 c1">a. Personal Data Collection</span>
                    </p>
                        <ol className="ml-4">
                            <li className="c2 li-bullet-0">
                                <span className="c0 c1">Online Activity. We will collect information about:</span>
                            </li>
                            <ol className="ml-4">
                                <li>
                                <span className="c0 c1">Devices and browsers used to access MySchool Services, and</span>
                                </li>
                                <li>
                                <span className="c0 c1">Usage data associated with those devices and browsers, time spent, pages visited, links clicked, and the pages that led or referred you to MySchool Services.</span>
                                </li>
                            </ol>
                            <li className="c7 li-bullet-0">
                            <span className="c0 c1">Engagement Information. We collect your engagement data such as your registration for events, and entries created though engagement with MySchool Partners as a core part of MySchool&rsquo;s Services.</span>
                            </li>
                            <li className="c7 li-bullet-0">
                            <span className="c0 c1">Comments and Groups. Where our Sites allow you to post content, we will collect Personal Data that you provide in connection with the post.</span>
                            </li>
                        </ol>
                    <p className="c4 c12">
                        <span className="c8">b. Personal Data Usage.</span>
                    </p>
                    <div className="ml-4">
                        <p className="c4 c12">
                            <span className="c0 c1">In addition to the ways described above in which we use Personal Data, we use Personal Data in the following ways:</span>
                        </p>
                    
                        <ol className="c3 lst-kix_4qrgali7k4oe-0 start" start="1">
                            <li className="c2 li-bullet-0">
                            <span className="c0 c1">Improving and Developing our Services. We use Site analytics to analyze usage of our Sites and Services and diagnose technical issues. This uses third-party cookies and third-party analytics software. &nbsp;</span>
                            </li>
                            <li className="c7 li-bullet-0">
                            <span className="c0 c1">Communications. We will use the contact information we have about you to perform the Services either to perform MySchool Services or potentially to provide you with new opportunities as we continually build and improve the product. We may communicate with you using the contact information we have about you to provide information about our Services, invite you to participate in events, surveys, or otherwise communicate with you for our marketing purposes, provided that we do so in accordance with applicable law, including any consent or opt-out requirements. </span>
                            </li>
                            <li className="c7 li-bullet-0">
                            <span className="c0 c1">Social Media and Promotions. If you choose to submit Personal Data to us to participate in an offer, program or promotion, we will use the Personal Data you submit to administer the offer, program or promotion. Based on your permission or opt-out, we will also use that Personal Data and Personal Data you make available on social media to market to you.</span>
                            </li>
                            <li className="c7 li-bullet-0">
                            <span className="c0 c1">Minors. The Services are not directed to minors, including children under the age of 13, and we request that they do not provide Personal Data through the Services.</span>
                            </li>
                        </ol>
                    </div>
                    <p className="c4 c12">
                        <span className="c8 c1">c. Personal Data Sharing for Legal Compliance. </span>
                    </p>
                    <p className="ml-4">
                        <span className="c0 c1">In addition to the ways described above, we maintain the right to share Personal Data as we believe necessary in accordance with applicable laws and to respond to valid legal claims.</span>
                    </p>
                </div>

                <div className="mt-4 mb-2">
                    <h4>
                        <span className="c8 c1">3. Updates and Notifications</span>
                    </h4>
                    <p className="c4">
                        <span className="c0 c1">We may change this Policy from time to time to reflect new services, changes in our privacy practices or relevant laws. The &ldquo;Last updated&rdquo; legend at the top of this Policy indicates when this Policy was last revised. Any changes are effective when we post the revised Policy on the Services.</span>
                    </p>
                </div>

                <div className="mt-4 mb-2">
                    <h4>
                        <span className="c8 c1">4. Contact Us</span>
                    </h4>
                    <p className="c4">
                        <span className="c0 c1">If you have any questions or complaints about this Policy, please contact us at info@myschoolathome.io.</span>
                    </p>
                </div>
            </div>

        </Container>
    );
}

export default PrivacyPolicy;
