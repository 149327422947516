import React, { useState, Fragment, useEffect } from 'react';
import { Col, Row, Form, FormGroup, Label, Input, Spinner } from 'reactstrap';
import { Redirect, Link } from "react-router-dom";
import { connect } from 'react-redux';
import { setAlert } from '../../actions/alert';
import { register } from '../../actions/auth';
import PropTypes from 'prop-types';
// import FacebookCredentials from './FacebookCredentials';
// import GoogleCredentials from './GoogleCredentials';


const RegisterComponent = ({ setAlert, register, auth: {isAuthenticated, user}, alerts }) => {
	
	const [formData, setFormData] = useState({
		username: '',
		email: '',
		password: '',
		password2: ''
	});

  const { username, email, password, password2 } = formData;

  const [ indivMailingListCheck, toggleIndivMailingListCheck ] = useState(true);
  const [ isSubmitting, setIsSubmitting ] = useState(false);

	const onChange = e =>
	    setFormData({ ...formData, [e.target.name]: e.target.value });

	const onSubmit = async e => {
		e.preventDefault();
    setIsSubmitting(true);
		if (password !== password2) {
      setAlert('Passwords do not match', 'danger');
		} else {
			await register({ username, email, password, indivMailingListCheck });
    }
    setIsSubmitting(false);
	};

  useEffect(()=> {
    setIsSubmitting(false);
  },[alerts]);

  //Redirect if logged in and user loaded
  if(isAuthenticated && user && user._id) {
    return <Redirect to='/dashboard' />;
  }

    return (
      <Fragment>
        {/*<div className='text-center'>
          <div>
          Want to expedite the process?  Login with your Facebook credentials
          </div>
          <FacebookCredentials />
        </div>*/}
        <Form onSubmit={e => onSubmit(e)} className="msstd-form container">
          <h4> Registration </h4>
          <Row>
            <Col>
              <FormGroup>
                <Input type="text" 
                name="username" 
                id="username" 
                placeholder="First Name or User Name (3-30 characters)" 
                value={username}
                onChange={e => onChange(e)}/>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Input type="email" 
                    name="email" 
                    id="email" 
                    placeholder="Email Address"
                    value={email}
                    onChange={e => onChange(e)} />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Input type="password" 
                name="password" 
                id="password" 
                placeholder="Password"
                value={password}
                onChange={e => onChange(e)} />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Input type="password" 
                name="password2" 
                id="password2" 
                placeholder="Confirm Password"
                value={password2}
                onChange={e => onChange(e)} />
              </FormGroup>
            </Col>
          </Row>
          <FormGroup check>
            <Input type="checkbox" 
                name="indivMailingListCheck" 
                id="indivMailingListCheck"
                checked={indivMailingListCheck}
                onChange={() => toggleIndivMailingListCheck(!indivMailingListCheck)} />
            <Label htmlFor="indivMailingListCheck" check>Join our Email List? (We send periodic emails about new features. Opt out anytime.)</Label>
          </FormGroup>
          {/*<div className='text-center'>
            <GoogleCredentials />
          </div>*/}
          <button
            disabled={isSubmitting }
            type="submit"
            className="btn btn-myschool-blue btn-set-WH"
          >
            <span id="button-register">
              {isSubmitting ? (
                <Spinner size="sm" className='color-white'/>
              ) : (
                "Sign Up"
              )}
            </span>
          </button>
        <div>
          Already have an account? <Link to='/login'>Log In</Link>
        </div>
        </Form>
                  
      </Fragment>
    );
};

RegisterComponent.propTypes = {
  setAlert: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  auth: PropTypes.object,
  alerts: PropTypes.array
};

const mapStateToProps = state => ({
  auth: state.auth,
  alerts: state.alert
});

export default connect(mapStateToProps, { setAlert, register })(RegisterComponent);
