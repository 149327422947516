import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Badge, Modal, ModalBody, ModalHeader } from 'reactstrap';
import LoginPopup from '../auth/LoginPopup';
import RegisterPopup from '../auth/RegisterPopup';
import CreateProfilePreGeolocate from '../profile-forms/CreateProfilePreGeolocate';
import { getCurrentProfile } from "../../actions/profile";
import checkYellow from '../../images/check-yellow_640-640.svg';
import checkRed from '../../images/check-red_640-640.svg';
import arrowFwdBlue from '../../images/arrow-fwd-blue_640-640.svg';
import ReactGA from 'react-ga';
import ProfileStripeConnectStatusBadge from '../payment/ProfileStripeConnectStatusBadge';

const ConnectProfileFunnel = ({auth, profile: { myProfile, profileLoading }, getCurrentProfile}) => {
  const history = useHistory();
  const [ loginModal, toggleLoginModal ] = useState(false);
  const [ registerModal, toggleRegisterModal ] = useState(false);

  const swapToLoginModal = () => {
    toggleLoginModal(!loginModal);
    toggleRegisterModal(!registerModal);
    ReactGA.event({ category: "Become Host", action: "Login" });
  };

  const swapToRegisterModal = () => {
    toggleLoginModal(!loginModal);
    toggleRegisterModal(!registerModal);
    ReactGA.event({ category: "Become Host", action: "Register" });
  };

  const [ createProfileModal, toggleCreateProfileModal ] = useState(false);

  const openLoginModal = () => {
    ReactGA.event({ category: "Become Host", action: "Login" });
    toggleLoginModal(!loginModal);
  };
  const openRegisterModal = () => {
    ReactGA.event({ category: "Become Host", action: "Register" });
    toggleRegisterModal(!registerModal);
  };
  const openCreateProfileModal = () => {
    ReactGA.event({ category: "Become Host", action: "Create Profile" });
    toggleCreateProfileModal(!createProfileModal);
  };

  const [ isStripeConnected, setStripeConnected ] = useState(false);

  const connectAccount = () => {

    if (isStripeConnected){
      ReactGA.event({ category: "Become Host", action: "Stripe Connected -> create event now" });
      history.push("/create-event");
    } else if (auth.isAuthenticated && myProfile && !isStripeConnected) {
      ReactGA.event({ category: "Become Host", action: "No Stripe Acct -> connect account" });
      history.push(`/profile/choose-payment-method`);
    } else {
      ReactGA.event({ category: "Become Host", action: "Incomplete funnel click" });
    }
  }

  useEffect(() => {
    if (auth && auth.user) {
      getCurrentProfile();
    }
  }, [auth]);

  useEffect(() => {
      ReactGA.event({ category: "Become Host", action: "Arrive on page" });
  },[]);

  return (
      <Fragment>
        <section className="section features-area style-two overflow-hidden ptb_50 bg-overlay">
            <div className="container mb-4">
              <h2 className='text-center color-white mb-4'>
                Create a profile and connect your account!
              </h2>
              <h4 className='text-center color-white mb-4'>
                Just click on the steps below!
              </h4>
                <div className="row text-center">
                  <div className='family-card-flex-set'>

                    <div className="family-card-set my-3 res-margin">
                      <button type='button' className='basic-button' onClick={()=>openRegisterModal()} disabled={auth.isAuthenticated}>
                          <div className="image-box text-center icon-1 p-5 find-family-feature-cards">
                              <div className="featured-img mb-3">
                                  <i className="fas fa-user-plus fa-2x color-myschool-red"/>
                              </div>
                              <div className="icon-text">
                                  <h4 className="mb-2">1. Register and Login</h4>
                                  {auth.isAuthenticated ? 
                                    (
                                      <div>
                                        <img src={checkRed} width='50px' alt='check step 1'/>
                                        <p>Great! You're already done with this step!</p>
                                      </div>
                                    )
                                    : 
                                    <p>It's free and easy!  Click here to do it now!</p>
                                  }
                              </div>
                          </div>
                      </button>
                    </div>

                    <div className="family-card-set my-3 res-margin">
                      <button 
                        type='button' 
                        className='basic-button' 
                        onClick={!auth.isAuthenticated ? 
                          ()=>openLoginModal()
                          :
                          ()=>openCreateProfileModal()
                        }
                        disabled={myProfile}
                      >
                          <div className="image-box text-center icon-1 p-5 find-family-feature-cards">
                              <div className="featured-img mb-3">
                                  <i className="fas fa-address-card fa-2x color-myschool-yellow" />
                              </div>
                              <div className="icon-text">
                                  <h4 className="mb-2">2. Create a profile</h4>
                                  {myProfile ? 
                                    (
                                      <div>
                                        <img src={checkYellow} width='50px' alt='check step 2'/>
                                        <p>Great! You're already done with this step too!</p>
                                      </div>
                                    )
                                    : 
                                    <div>
                                      <p>It's free and easy!  Click here to do it now!</p>
                                    </div>
                                  }
                                  
                              </div>
                          </div>
                      </button>
                    </div>

                    <div className="family-card-set my-3 res-margin">
                      <button type='button' className='basic-button' onClick={()=>connectAccount()}>
                          <div className="image-box text-center icon-1 p-5 find-family-feature-cards">
                              <div className="featured-img mb-3">
                                  <i className="fas fa-dollar-sign fa-2x color-myschool-blue" alt="" />
                              </div>
                              <div className="icon-text">
                                  <h4 className="mb-2">3. Connect your account!</h4>
                                  {auth.isAuthenticated && myProfile ?
                                    (
                                      <div>
                                        <img src={arrowFwdBlue} width='50px' className='blinker' alt='check step 3'/>
                                        <div><ProfileStripeConnectStatusBadge setStripeConnected={setStripeConnected} myProfile={myProfile}/></div>
                                        {isStripeConnected ? 
                                          <p>Awesome! You're already connected! Go create an event now!</p>
                                          :
                                          <div>
                                            <p className='color-danger text-bold'>Click here to connect your account now!</p>
                                            <p> *Optional, but this allows you to accept payments and use all MySchool's tools! We take a small portion of the event price, so if you don't get any bookings,<span className='text-bold'>{' '}you don't pay a thing!</span></p>
                                          </div>
                                        }
                                      </div>
                                    )
                                    :  
                                    (
                                      <div>
                                        <p className='color-danger text-bold'>You must have a profile before connecting your account.</p>
                                      </div>
                                    )
                                  }
                              </div>
                          </div>
                      </button>
                    </div>
                  </div>

                </div>
            </div>
        </section>

      <Modal isOpen={loginModal} centered toggle={() => toggleLoginModal(!loginModal)}>
        <ModalBody className='text-center'>
          <div className='float-right'>
            <button className="basic-button" onClick={() => toggleLoginModal(!loginModal)}>
            <i className='fa fa-times color-light-grey'/>
            </button>
          </div>
          <LoginPopup loginModal={loginModal} toggleLoginModal={toggleLoginModal}/>
          <button onClick={()=>swapToRegisterModal()} className='basic-button text-bold text-underlined'>I need to Register</button>
        </ModalBody>
      </Modal>

      <Modal isOpen={registerModal} centered toggle={() => toggleRegisterModal(!registerModal)}>
        <ModalBody className='text-center'>
          <div className='float-right'>
            <button className="basic-button" onClick={() => toggleRegisterModal(!registerModal)}>
            <i className='fa fa-times color-light-grey'/>
            </button>
          </div>
          <RegisterPopup registerModal={registerModal} toggleRegisterModal={toggleRegisterModal} history={history}/>
          <button onClick={()=>swapToLoginModal()} className='basic-button text-bold text-underlined'>Go to Login</button>
        </ModalBody>
      </Modal>

      <Modal isOpen={createProfileModal} centered toggle={() => toggleCreateProfileModal(!createProfileModal)}>
        <ModalBody className='text-center'>
          <div className='float-right'>
            <button className="basic-button" onClick={() => toggleCreateProfileModal(!createProfileModal)}>
            <i className='fa fa-times color-light-grey'/>
            </button>
          </div>
          <CreateProfilePreGeolocate/>
        </ModalBody>
      </Modal>

    </Fragment>
  );
}

ConnectProfileFunnel.propTypes = {
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  getCurrentProfile: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  profile: state.profile,
});

export default connect(
  mapStateToProps,
  { getCurrentProfile },
)(ConnectProfileFunnel);