import React, { useState } from 'react';
import { Link, useHistory, useParams, Redirect } from 'react-router-dom';
import { Form, Row, Label, Input, Alert, Col, Button } from 'reactstrap';
import api from '../../utils/api';
import ReactGA from 'react-ga';
  
const CheckoutRegNotReq = ({ event, history, toggleImInModal }) => {

  const [ isSubmitting, setIsSubmitting ] = useState(false);
  const [ isSuccess, setSuccess ]= useState(false);
  const [ formData, setFormData] = useState({
    count: 2,
    name: '',
    email: ''
  });
  const { count, name, email } = formData;

  const [ showAlert, setShowAlert ] = useState(false);
  const [ alertMsg, setAlertMsg ] = useState({
    msg: '',
    color: ''
  });

  const onFormChange = e =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (event.slotsAvailable < formData.count) {
      setShowAlert(true);
      setAlertMsg({msg:`There are only ${event.slotsAvailable} slots left!`,color:'danger'});
      setTimeout(()=> {
        setShowAlert(false);
      }, 5000);
    } else {
      setIsSubmitting(true);
      try {
        const submitAttendees = await api.post(`/eventRegistration/regNotReq/${event._id}`, formData);
        if (submitAttendees.status === 200) {
          setIsSubmitting(false);
          setSuccess(true);
          setShowAlert(true);
          setAlertMsg({msg:`You're all set! See you then!`, color:'success'});
          ReactGA.event({ category: "event_registration", action: "click_regNotReq_Event_Submitted", label: `${event._id}`});
          setTimeout(()=> {
            window.location.reload();
          }, 2500);
        }
      } catch (err) {
        console.log(err);
        setIsSubmitting(false);
        setShowAlert(true);
        setAlertMsg({msg:`Error, try again later!`,color:'danger'});
        setTimeout(()=> {
          toggleImInModal(false);
        }, 2500);
      }
    }
  }

  return (
    <div>
      {showAlert ? 
        <Alert className={`my-1 mb-3 text-center alert-modal-${alertMsg.color}`}>{alertMsg.msg}</Alert>
        :''
      }

      {!isSuccess ?
        <Form onSubmit={e=>handleSubmit(e)}>
          <h4 className='mt-1 mb-3 text-center'>Great! How many spots should we reserve?</h4>
          <div>
            <Row>
              <Col>
                <Label for="count">How many? (parents and children)</Label>
                <Input type="number" 
                  name="count" 
                  id="count"
                  value={count}
                  min={1}
                  onChange={e => onFormChange(e)} 
                  required/>
              </Col>
            </Row>
          </div>
          <hr/>
          <div className='' style={{zIndex:'998',border:'1px', borderColor:'#375e97'}}>
            <div className='text-center'>
              (Optional - Allows the host to get in touch if needed.)
            </div>
            <Row>
              <Col sm={6}>
                <Label for="name">Name</Label>
                <Input type="text" 
                name="name" 
                id="name"
                value={name}
                onChange={e => onFormChange(e)} 
                />
              </Col>
              <Col sm={6}>
                <Label for="email">Email</Label>
                <Input type="text" 
                name="email" 
                id="email"
                value={email}
                onChange={e => onFormChange(e)} 
                />
              </Col>
            </Row>
          </div>
          <div className='my-3'>
            <Button 
              type='submit' 
              className='block-button btn-modal-blue'
              disabled={isSubmitting || isSuccess}>
              {!isSubmitting ? 
                <span>Submit</span> 
                : 
                <span className='blinker'>Submitting...</span>
              }
            </Button>
          </div>
        </Form>
        :
        <div>
          <Button 
            type='button' 
            onClick={()=>location.reload()} 
            className='block-button btn-modal-blue'
            >
            Close
          </Button>
        </div>
      }
    </div>
  );
};


export default CheckoutRegNotReq;

