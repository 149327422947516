import React, { Fragment, useState } from 'react';
import { Map } from 'react-leaflet';
import L from 'leaflet';
import ProfileFormMap from './ProfileFormMap';

const corner1 = L.latLng(90,180);
const corner2 = L.latLng(-90,-180);
const maxBounds = L.latLngBounds(corner1, corner2);

// BEGIN MAP
const ProfileFormMapWrapper = ({ leafletMap, displayMap, toggleMap, indivLat, indivLong, formData, setFormData }) => {
  
  //MAP STATE
  const [currentLatLong, setCurrentLatLong] = useState({
    lat: indivLat,
    long: indivLong
  });
  const [currentZoom, setCurrentZoom] = useState(14);
  const [bounds, setBounds] = useState({});
  const currentposition = [currentLatLong.lat, currentLatLong.long];

  let APIKey;
  if (process.env.NODE_ENV === 'production') {
    APIKey = process.env.REACT_APP_MAPTILER_API_KEY
  } else {
    APIKey = process.env.REACT_APP_MAPTILER_API_TEST_KEY
  }

  return (
    <Fragment>
      <div><span className='text-bold'>Don't like your location?</span>{' '}Drag the map point to where you want your spot to appear!</div>
      <Map className="map-wrapper-thumb" center={currentposition} zoom={currentZoom} maxBounds={maxBounds}>
        <ProfileFormMap 
          currentposition={currentposition}
          bounds={bounds}
          setBounds={setBounds}
          APIKey={APIKey}
          indivLat={indivLat}
          indivLong={indivLong}
          formData={formData}
          setFormData={setFormData}
        />
      </Map>
      <div className='text-center my-1'>Location: ({indivLat.toFixed(4)*1}, {indivLong.toFixed(4)*1})</div>
      <div>Click below when you like your spot.</div>
    </Fragment>
  )
};

export default ProfileFormMapWrapper;
