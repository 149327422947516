import React, { useEffect, Fragment, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { Container, Form, FormGroup, Input, Label, Table } from 'reactstrap';
import PropTypes from 'prop-types';
import { adminGetProfilesWithin } from '../../../actions/admin';
import Spinner from '../../layout/Spinner';
import formatDate from '../../../utils/formatDate';
import AdminRoutes from '../AdminRoutes';

const EmailProfilesWithinRadius = ({ adminGetProfilesWithin, profile: {profileLoading, profiles}}) => {

  const history = useHistory();
  
  const [position, setPosition] = useState({
  	lat: 39.4,
  	long: -76.6
  });

  const [dist, setDist] = useState(30);

  const onSubmit = (e) => {
  	e.preventDefault();
  	adminGetProfilesWithin(position, dist);
  };
	
  const onPositionChange = e =>
    setPosition({ ...position, [e.target.name]: e.target.value });

    // const [emailArray, setEmailArray] = useState([]);
    const [copyConfirm, setCopyConfirm] = useState('Copy Emails');
    const copyEmailAddresses = () => {
    	let emailArray = [];
    	profiles.forEach(p=>emailArray.push(p.user.email));
		navigator.clipboard.writeText(emailArray.join(";"));
		setCopyConfirm('Copied!!')
    }

    useEffect(()=> {
    	adminGetProfilesWithin(position, dist);
    },[]);

	return (
    <div className='m-3'>
    	<AdminRoutes/>
        <Form onSubmit={(e)=>onSubmit(e)}>
        	<span>
        		{`Within `}
        		<input
        			type='number'
        			name='dist'
        			value={dist}
        			onChange={e=>setDist(e.target.value)}
    			/>{` miles`}
        		{` of Lat: `}
        		<input
        			type='number'
        			name='lat'
        			value={position.lat}
        			onChange={e=>onPositionChange(e)}
    			/>
    			{`Long: `}
        		<input
        			type='number'
        			name='long'
        			value={position.long}
        			onChange={e=>onPositionChange(e)}
    			/>
			</span>
			<div className='text-center'>
	        	<button type='submit' className='btn btn-myschool-blue'>
	        		Submit
	        	</button>
        	</div>

        </Form>
        <div className='float-right'>
        	<button
        		type='button'
        		className='btn btn-myschool-blue'
        		onClick={()=>copyEmailAddresses()}
        		>
        		{copyConfirm}
        	</button>
        </div>
        <Table striped className='admin-table'>
			<thead>
				<tr> 
				  <th>#</th>
				  <th>UserId</th>
				  <th>ProfileId</th>
				  <th>(lat, long)</th>
				  <th>Email</th>
				</tr>
			</thead>
			<tbody>
            {profiles && profiles.length > 0 ? 
            	profiles.map((profile, i)=>
              (
		                <tr key={profile._id}>
		                  <td>{i+1}</td>
		                  <td>{profile.user && profile.user._id}</td>
		                  <td>{profile._id && profile._id}</td>
		                  <td>{profile.indivLat && profile.indivLong ?`(${(profile.indivLat).toFixed(4)},${profile.indivLong.toFixed(4)})`:''}</td>
		                  <td>{profile.user && profile.user.email}</td>
		                </tr>
              ))
            	:<tr><td>0 results</td></tr>
            }

            </tbody>
        </Table>
        
    </div>
  );
};

EmailProfilesWithinRadius.propTypes = {
  adminGetProfilesWithin: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  admin: state.admin,
  profile: state.profile
});


export default connect(mapStateToProps, { adminGetProfilesWithin })(EmailProfilesWithinRadius);