import React, { useState, Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { setAlert } from '../../actions/alert';
import { Container, Col, Row, Button, Form, FormGroup, Input, Label, Spinner } from 'reactstrap';
import { emailProviderGeneral } from '../../actions/provider';
import SpinnerComponent from '../layout/Spinner';
import api from '../../utils/api';
import ReactGA from 'react-ga';

const EmailProviderGeneral = ({ auth, setAlert }) => {

  const history = useHistory();
  const params = useParams();

  const [ isSubmitting, setSubmitting] = useState(false);
  const [ isSuccess, setSuccess ] = useState(false);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    childAge: '',
    message: ''
  });

  const { name, email, childAge, message } = formData;

  const onChange = e =>
      setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async e => {
    e.preventDefault();
    setSubmitting(true);
    try {
      ReactGA.event({ category: "Provider Profile", action: "Send provider an Email" });
      const res = await api.post(`/provider/${params.providerId}/sendEmail`, formData);
      
      if (res.status === 200 ) {
        setSuccess(true);
        setTimeout(() => {
          history.push(`/classProvider/${params.providerId}`);
        }, 5000);
      }
    } catch (err) {

      if (err && err.response && err.response.data && err.response.data.errors) {
        const errors = err.response.data.errors;
        window.scrollTo(0,0);
        errors.forEach(error => setAlert(error.msg, 'danger'));
      } else {
        setAlert('Error: Please try again later.', 'danger')
      }
    }
  };

  useEffect(()=> {
    setFormData({
      ...formData,
      email: auth && auth.user && auth.user.email ? auth.user.email :''
    })
  },[]);

  return !auth || auth.isLoading ? (
    <SpinnerComponent />
  ):(
      <Fragment>
        <Container>
          { !isSuccess ? 
            (
              <Fragment>
                <Form onSubmit={e => onSubmit(e)} className="msstd-form">
                  <h2> Contact the Organizer! </h2>
                  <Row form>
                    <Col>
                      <Label htmlFor='name'>Name</Label>
                      <FormGroup>
                        <Input type="text" 
                        name="name" 
                        id="name" 
                        placeholder="First and Last Name" 
                        value={name}
                        onChange={e => onChange(e)} />
                      </FormGroup>
                      <Label htmlFor='email'>Email</Label>
                      <FormGroup>
                        <Input type="email" 
                        name="email" 
                        id="email" 
                        placeholder="my-email@gmail.com" 
                        value={email}
                        onChange={e => onChange(e)} />
                      </FormGroup>
                      <Label htmlFor='message'>Please include a message below.</Label>
                      <FormGroup>
                        <Input type='textarea'
                          placeholder='Add a message here.'
                          rows='5'
                          name='message'
                          value={message}
                          onChange={e => onChange(e)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  
                  <Button type="submit" className='btn btn-modal-blue btn-set-WH' disabled={isSubmitting || isSuccess}>
                    <span>
                      {isSubmitting ? (
                        <Spinner size="sm" className='color-white'/>
                      ) : (
                        "Send"
                      )}
                    </span>
                  </Button>
                </Form>
              </Fragment>
            ) 
            :
            (
              <Fragment>
                <div className='text-center'>
                  <h2>Success!  The organizer should be in touch with you soon!</h2>
                  <div className='blinker'>You're being redirected...</div>
                  <button className='basic-button text-underline' onClick={()=>history.goBack()}>Go back now.</button>
                </div>
              </Fragment>
            )
          }
        </Container>
      </Fragment>  
  );
};

EmailProviderGeneral.propTypes = {
  setAlert: PropTypes.func.isRequired,
  emailProviderGeneral: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { emailProviderGeneral, setAlert }
)(EmailProviderGeneral);

