import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import Spinner from '../layout/Spinner';
import ProfileTop from './ProfileTop';
import ProfileAbout from './ProfileAbout';
import { getProfileById } from '../../actions/profile';
import { Container, Modal, ModalBody } from 'reactstrap';
import SendEmailToUser from '../homeschoolmap/SendEmailToUser';


const Profile = ({ getProfileById, profile: { profile, profileLoading }, auth }) => {
  
  const params = useParams();
  const history = useHistory();

  //Email user
  const [ emailUserModal, setEmailUserModal ] = useState(false);
  const [ emailFormData, setEmailFormData ] = useState({
    userId: '',
    username: 'MySchool User',
    message:'',
    signatureName:'',
    signatureEmail:''
  });

  const [ loginModal, toggleLoginModal ] = useState(false);
  const [ registerModal, toggleRegisterModal ] = useState(false);
  const swapLoginRegister = () => {
      toggleLoginModal(!loginModal);
      toggleRegisterModal(!registerModal);
  };

  useEffect(() => {
    getProfileById(params.id);
  }, [profileLoading, params]);

  useEffect(() => {
    if (profile && profile.user && profile.user.username) {
      setEmailFormData({...emailFormData, username: profile.user.username});
    }
  }, [profile]);

  useEffect(() => {
    if (auth && auth.user && auth.user.email) {
      setEmailFormData({...emailFormData, signatureEmail: auth.user.email});
    }
  }, [auth]);

  function onSendEmail(profileUser) {
    setEmailUserModal(!emailUserModal);
    setEmailFormData({
      userId: profileUser._id,
      username: profileUser.username
    });
  }  

  return profileLoading || !profile ? 
    (
      <Spinner /> 
    ) : 
    (
      <Fragment>
        <Container>
          <button onClick={() => history.goBack()} className='btn btn-light'>
            Back
          </button>
          <div className='profile-grid my-1'>
            <ProfileTop profile={profile} auth={auth} />
            <ProfileAbout profile={profile} />
            <div className='text-center mb-5'>
              <button 
                className='btn btn-myschool-blue'
                onClick={()=> onSendEmail(profile.user)}
                >
                  <i className='fas fa-envelope'/>
                  {` Email ${profile.user.username}`}
              </button>
            </div>
          </div>
        </Container>

        <Modal isOpen={emailUserModal} centered toggle={() => setEmailUserModal(!emailUserModal)}>
          <ModalBody>
            <SendEmailToUser emailFormData={emailFormData} setEmailFormData={setEmailFormData} setEmailUserModal={setEmailUserModal}/>
          </ModalBody>
        </Modal>

      </Fragment>
    );
};

Profile.propTypes = {
  getProfileById: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  profile: state.profile,
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { getProfileById }
)(Profile);


