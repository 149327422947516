import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Badge } from 'reactstrap';
import iconUseSpot from '../../../images/icon-use-a-spot.svg';

const data = [
    {
        fontAwesomeIcon: "fas fa-search-location fa-2x color-myschool-red",
        title: "Find Spots",
        content: "Find and save your favorite learning spots!",
        link: "/homeschoolmap"
    },
    {
        fontAwesomeIcon: "fas fa-map-marker-alt fa-2x color-myschool-yellow",
        title: "Share Spots",
        content: "Know a spot not on the map? Operate a learning spot yourself? Put it on the map!",
        link: "/resource-options"
    },
    {
        iconImage: iconUseSpot,
        title: "Use Spots",
        content: "Create in-person classes, field trips, and other events to share with families near you!",
        link: "/event-options"  
    }
]

class FeatureSection extends Component {
    state = {
        data: []
    }
    componentDidMount(){
        this.setState({
            data: data
        })
    }
    render() {
        return (
            <section id="features" className="section features-area style-two overflow-hidden ptb_100">
                <div className="container">
                    <div className="row">
                        {this.state.data.map((item, idx) => {
                            return(
                                <div key={`ffd_${idx}`} className="col-12 col-md-6 col-lg-4 my-3 res-margin">
                                    <Link to={item.link}>
                                        {/* Image Box */}
                                        <div className="image-box text-center icon-1 p-5">
                                            {/* Featured Image */}
                                            <div className="featured-img mb-3">
                                                {item.fontAwesomeIcon && <i className={item.fontAwesomeIcon} alt=""/>}{' '}
                                                {item.iconImage && <img src={item.iconImage} alt="" width='40px'/>}{' '}
                                                {item.badge ? (<p><Badge color='warning'>{item.badge}</Badge></p>):''}
                                            </div>
                                            {/* Icon Text */}
                                            <div className="icon-text">
                                                <h4 className="mb-2">{item.title}</h4>
                                                <p>{item.content}</p>
                                                {item.content2 ? <p className='text-italic text-075rem'>{item.content2}</p>:''}
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </section>
        );
    }
}

export default FeatureSection;