import React, { Fragment, useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SpinnerComponent from '../../layout/Spinner';
import SwitchingGearsSpinner from '../../layout/SwitchingGearsSpinner';

// BEGIN MAP
const EventMapSwitchGears = () => {
  const history = useHistory();
  const location = useLocation();
  return (
      <div className="text-center">
        <h2>We're gearing up for something new!</h2>
        <h4 className="my-3">Unfortunately, at least for now, that means this feature is no longer available.</h4>
        <SwitchingGearsSpinner />
      </div>
  );
};

export default EventMapSwitchGears;