import React, { useState, Fragment, useEffect } from 'react';
import { Col, Row, Button, Form, FormGroup, Input, Spinner } from 'reactstrap';
import axios from 'axios';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const EmailUsForm = ({ toggleModalEmailer, auth: {user, loading} }) => {

	const [isSubmitting, setIsSubmitting] = useState(false);
	const [isSuccess, setIsSuccess] = useState(false);

	const [formData, setFormData] = useState({
		responseEmail: '',
		message: ''
	});

	const { responseEmail, message } = formData;

	const onChange = e =>
	  setFormData({ ...formData, [e.target.name]: e.target.value });

	const onSubmit = async e => {
		e.preventDefault();
		setIsSubmitting(true);
		try {
		    const config = { headers: { 'Content-Type': 'application/json' } };
		    const res = await axios.post('api/company/whatabouta', formData, config);
		    if (res.status === 200) {
		    	setIsSubmitting(false);
		    	setIsSuccess(true);
		    	setTimeout(()=> {
					toggleModalEmailer(false);
			    	setIsSuccess(false);
			    	setFormData({
						responseEmail: '',
						message: ''
					});
				}, 3000);
		    }
		} catch (err) {
			setIsSubmitting(false);
			setIsSuccess(false);
			console.log(err);
		}
	};

	useEffect(()=> {
		if (user && user.email) {
			setFormData({ ...formData, responseEmail: user.email });
		}
	},[user]);

    return(
    	<Fragment>
	        { !isSuccess ?
	            <Form onSubmit={e => onSubmit(e)} className="msstd-form">
					<Row form>
						<Col>
						  <FormGroup>
						    <Input type="email" 
						    name="responseEmail" 
						    id="responseEmail" 
						    placeholder="Enter Your Email Address" 
						    value={responseEmail}
						    onChange={e => onChange(e)} 
						    required/>
						  </FormGroup>
						  <FormGroup>
						    <Input type='textarea'
						    	placeholder='Type your message here.'
						    	rows='5'
						    	name='message'
						    	value={message}
						    	onChange={e => onChange(e)}
						    	required
						    />
						  </FormGroup>
						</Col>
					</Row>
	              
		            <Button type="submit" disabled={isSubmitting || isSuccess} className="btn btn-modal-blue btn-set-WH-long">
		              	<span>
							{isSubmitting ? (
							  <Spinner size="sm" className='color-white'/>
							) : (
							  "Send Email"
							)}
						</span>
					</Button>
	            </Form>
	        : 
	        <div className='text-center' style={{paddingTop: "125px"}}>
	        	<h2>Success!</h2>
		        <h4>Email Sent!</h4>
	        </div>
	        }

      </Fragment>
    );
}

EmailUsForm.propTypes = {
  auth: PropTypes.object
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(  mapStateToProps, { })(EmailUsForm);


