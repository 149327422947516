import React, { Fragment, useState } from 'react';
import { Marker, Popup } from 'react-leaflet';
import { Link } from 'react-router-dom';
import { Row, Col, Card, CardBody, CardText, Button, Spinner, Badge } from 'reactstrap';
import capitalizeName from '../../../utils/capitalizeName';
import heartOutline from '../../../images/heart-outline.svg';
import heartFilled from '../../../images/heart-filled.svg';
import zoomToPoint from '../../../images/icon-zoom-to-point.svg';
import zoomOut from '../../../images/icon-zoom-out.svg';
import plusLogo from '../../../images/logo_myschool-plus_150-150.svg';
import dayjs from 'dayjs';

const GenericListItem = ({ resource, isSettingFav, favoriteResourceHandler, favoriteProviderHandler, auth, setMapFlyTo, zoomInOut, isZoomedIn, history, toggleLoginModal }) => {

  const weekday = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
  const d = dayjs().day();
  let day = weekday[d];

  return (
    <Card className='homeschoolmap-card' onTouchStart={() => setMapFlyTo([resource.lat, resource.long])} onMouseEnter={() => setMapFlyTo([resource.lat, resource.long])} >
      <CardBody className='homeschoolmap-cardbody'>
        {resource.isPortfolioPartner ? 
          <div className='d-block cursor-pointer' onClick={()=>history.push('/myschoolplus/play-and-learn')}>
            <Badge pill className='pill-color-red' style={{width:"100%"}}>
                Summer Scavenger Hunt Partner
            </Badge>
          </div>
          :''
        }

        <Row>

          <Col xs={2} className='homeschoolmap-list-actions'>
            <div className='d-flex align-items-center h-100 '>
              <button type='button' className='btn fav-button' onClick={()=>zoomInOut([resource.lat, resource.long])}>
                { isZoomedIn ? 
                  <img src={zoomOut} width='30px' />
                  :
                  <img src={zoomToPoint} width='30px' />
                }
              </button>
            </div>
          </Col>

          <Col xs={7} className='px-0'>
            <div className=''>
              <CardText className='text-bold'>{resource.name}<span className='color-myschool-red'>{resource.distance && resource.distance >= 1 ? ` (${resource.distance.toFixed()} miles)`:resource.distance == 0 ?` (<1 miles)`:'' }</span></CardText>
              <CardText>{capitalizeName(resource.category)}</CardText>
              { resource.opHours ? <CardText ><span><i className="fas fa-clock"/>{day}{`: `}{resource.opHours[d]}</span></CardText>:''}
              { resource && resource.entryFees ? 
                <CardText><span className='text-bold'><i className="fas fa-dollar-sign"/>{' '}</span>
                  {resource && resource.entryFees.isFree ?
                    `Free`
                    :
                    <span>
                    {resource.entryFees.fees.length > 2 ? 
                      <span>
                        {
                          resource.entryFees.fees.slice(0,2).map((fee,i)=> {
                            return <span key={i}>{i>0 ? ', ':''}{fee.name}{`: `}{fee.price > 0 ? `$${fee.price}`:'Free'}</span>
                          })
                        }
                        ...
                      </span>
                    : resource.entryFees.fees.map((fee,i)=> {
                        return <span key={i}>{i>0 ? ', ':''}{fee.name}{`: `}{fee.price > 0 ? `$${fee.price}`:'Free'}</span>
                      })
                    }
                    </span>
                  }
                  </CardText>
                :''
              }
              { resource && resource.subjects && Array.isArray(resource.subjects) && resource.subjects.length >0 &&
                resource.subjects.map((s,i) => {
                  return <Badge key={i} className={`pill-color-${s.replace(/\s+/g, '')} mr-1`} pill>{s}</Badge>
                })
              }
              { resource.reciprocals && resource.reciprocals.length > 0 ?
                <CardText className='text-bold'>
                  {`Reciprocal Programs: `}
                  {resource.reciprocals.map((recip,i)=> {
                      return (
                          <Badge key={i} className={`pill-color-blue text-07rem mr-1`} pill>
                            {recip}
                            {resource.reciprocalData && resource.reciprocalData[recip] && ` $${resource.reciprocalData[recip].price}`}
                          </Badge>
                        )
                      }
                  )}
                </CardText>
                :''
              }
              { resource.networks && resource.networks.length > 0 ?
                <CardText className='text-bold'>
                  {`Other Programs: `}
                  {resource.networks.map((netw,i)=> {
                      return (
                          <Badge key={i} className={`pill-color-blue text-07rem mr-1`} pill>
                            {netw}
                          </Badge>
                        )
                      }
                  )}
                </CardText>
                :''
              }
              { resource.creditedTo_info && resource.creditedTo_info.length > 0 && resource.creditedTo_info[0].providerName && resource.creditedTo_info[0]._id ? 
                <div className='text-07rem'>Suggested By:<Link to={`/provider/${resource.creditedTo_info[0]._id}`}>{` ${resource.creditedTo_info[0].providerName} `}<i className="fas fa-angle-double-right"/></Link></div>
                : ''
              }
              <div>
                { resource.collectionName && resource.collectionName === 'resource' ?
                  <>
                    {
                      auth.isAuthenticated ? 
                      <button className='basic-button text-12px' onClick={()=>history.push(`/claim-resource/${resource._id}`)}>Claim Spot</button>
                      :
                      <button className='basic-button text-12px' onClick={()=>toggleLoginModal(true)}>Claim Spot</button>
                    }
                    {' | '}
                    {
                      auth.isAuthenticated ? 
                      <button className='basic-button text-12px' onClick={()=>history.push(`/edit-resource/${resource._id}`)}>Edit Spot</button>
                      :
                      <button className='basic-button text-12px' onClick={()=>toggleLoginModal(true)}>Edit Spot</button>
                    }
                  </>
                  :''
                }
              </div>
            </div>
          </Col>

          <Col xs={3} className='homeschoolmap-list-actions'>
            <div className='homeschoolmap-list-actions-buttons'>
                { resource.collectionName && resource.collectionName === 'resource' ? 
                    <div>
                      <Button type='button' className='fav-button justify-content-center' disabled={isSettingFav} onClick={()=>favoriteResourceHandler(resource._id)}>
                        {isSettingFav === false && auth.user && auth.user.myFavResources && auth.user.myFavResources.filter(fav => fav.toString() === resource._id).length > 0 ?
                          <img src={heartFilled} width="15px"/>
                           : 
                         isSettingFav === true ?
                          <Spinner style={{ width: '15px', height: '15px'}} type="grow" color="danger" />
                           : 
                          <img src={heartOutline} width="15px"/>
                        } 
                      </Button>
                      <div className="my-1"/>
                      <Button type='button' className='homeschoolmap-list-action-more' onClick={()=>history.push(`/resource/${resource._id}`)}>
                        more...
                      </Button>
                    </div>
                  : resource.collectionName && resource.collectionName === 'provider' ? 
                    <div>
                      <Button type='button' className='fav-button justify-content-center' disabled={isSettingFav} onClick={()=>favoriteProviderHandler(resource._id)}>
                        {isSettingFav === false && auth.user && auth.user.myFavProviders && auth.user.myFavProviders.filter(fav => fav.toString() === resource._id).length > 0 ?
                          <img src={heartFilled} width="15px"/>
                           : 
                         isSettingFav === true ?
                          <Spinner style={{ width: '15px', height: '15px'}} type="grow" color="danger" />
                           : 
                          <img src={heartOutline} width="15px"/>
                        } 
                      </Button>
                      <div className="my-1"/>
                      <Button type='button' className='homeschoolmap-list-action-more' onClick={()=>history.push(`/classProvider/${resource._id}`)}>
                        more...
                      </Button>
                    </div>
                  :''
                }
            </div>
          </Col>

        </Row>
      </CardBody>
    </Card>
  );
};


export default GenericListItem;