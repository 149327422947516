import React, { useState, Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Col, Row, Button, Form, FormGroup, Input, Alert, Spinner } from 'reactstrap';
import ReactGA from 'react-ga';
import api from '../../utils/api';

const SendEmailToUser = ({ setEmailUserModal, emailFormData, setEmailFormData }) => {
  
  const [ isSubmitting, setIsSubmitting ] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const { userId, username, message, signatureName, signatureEmail } = emailFormData;
  const onChange = e =>
      setEmailFormData({ ...emailFormData, [e.target.name]: e.target.value });

  const [ alertArray, setAlertArray ] = useState([]);
  const onSubmit = async e => {
    e.preventDefault();
    ReactGA.event({ category: "Personal Profile", action: "Email sent to user" });
    setIsSubmitting(true);
    const config = { headers: { 'Content-Type': 'application/json' } };
    const jsonFormData = JSON.stringify(emailFormData);
    try {
      // console.log(jsonFormData);
      const res = await api.post(`/profile/${userId}/sendEmail`, jsonFormData, config);
      // console.log(res);
      if (res.status === 200) {
        setIsSubmitting(false);
        setIsSuccess(true);
        setEmailFormData({
          username: '',
          message:'',
          signatureName:'',
          signatureEmail:''
        });
        setTimeout(()=> {
            setEmailUserModal(false);
            setIsSuccess(false);
          }, 3000);
      }
    } catch (err) {
      setIsSubmitting(false);
      setIsSuccess(false);
      // console.log(err);
      const errors = err.response.data.errors;
      // console.log(errors);
      if (errors) {
          setAlertArray(errors);
          setTimeout(()=> {setAlertArray([])},5000);
      } else {
        setAlertArray([{msg:'There was an error.  Please try again.  If the problem persists, please let us know.'}]);
          setTimeout(()=> {setAlertArray([])},5000);
      }
    }
  };


  return (
    <Fragment>
        { !isSuccess ?
          <Form onSubmit={e => onSubmit(e)} className="msstd-form">
            {alertArray.length > 0 ? 
              alertArray.map((alert, index) =>
                  <Alert color='danger' key={index} className='alert-modal-danger'>
                    {alert.msg}
                  </Alert>
                )
              : ''
            }
            <Row form>
              <Col>
                <FormGroup>
                  Dear {username},
                </FormGroup>
                <FormGroup>
                  <Input type='textarea'
                    placeholder='Message Area'
                    rows='5'
                    name='message'
                    value={message}
                    onChange={e => onChange(e)}
                    required
                  />
                </FormGroup>
                <div>Sincerely,</div>
                <FormGroup>
                  <Input type="text" 
                  name="signatureName"
                  placeholder="Your Name" 
                  value={signatureName}
                  onChange={e => onChange(e)} 
                  required
                  />
                </FormGroup>
                <FormGroup>
                  <Input type="email" 
                  name="signatureEmail"
                  placeholder="Your Email Address" 
                  value={signatureEmail}
                  onChange={e => onChange(e)} 
                  required
                  />
                </FormGroup>
              </Col>
            </Row>
              
            <Button type="submit" disabled={isSubmitting || isSuccess} className="btn btn-modal-blue btn-set-WH-long">
              <span>
                {isSubmitting ? (
                  <Spinner size="sm" className='color-white'/>
                ) : (
                  "Send Email"
                )}
              </span>
            </Button>
          </Form>
        : 
          <div className='text-center py-5'>
            <h2>Success!</h2>
            <h4>Email Sent!</h4>
          </div>
        }

    </Fragment>
  );
};

export default SendEmailToUser;