import React, { Fragment } from 'react';
import { FormGroup, Label, Input } from 'reactstrap';

const RegisterForm = ({ formData, setFormData, indivMailingListCheck, toggleIndivMailingListCheck }) => {

  const { username, email, password, password2 } = formData;

	const onChange = e =>
	    setFormData({ ...formData, [e.target.name]: e.target.value });
      

    return (
      <Fragment>
        <div className="form-group small-form">
          <input type="text" className="form-control" name="username" placeholder="Username (A first name works!)" value={username} onChange={e => onChange(e)} required autoComplete="username" />
        </div>
        <div className="form-group small-form">
          <input type="email" className="form-control" name="email" placeholder="Email" value={email} onChange={e => onChange(e)} required autoComplete="email" />
        </div>
        <div className="form-group small-form">
          <input type="password" className="form-control" name="password" placeholder="Password" value={password} onChange={e => onChange(e)} required autoComplete="new-password"/>
        </div>
        <div className="form-group small-form">
          <input type="password" className="form-control" name="password2" placeholder="Repeat Password" value={password2} onChange={e => onChange(e)} required />
        </div>
        <FormGroup check>
          <Input type="checkbox" 
              name="indivMailingListCheck" 
              id="indivMailingListCheck"
              checked={indivMailingListCheck}
              onChange={() => toggleIndivMailingListCheck(!indivMailingListCheck)} />
          <Label htmlFor="indivMailingListCheck" check>Get periodic email updates?</Label>
        </FormGroup>          
      </Fragment>
    );
};

export default RegisterForm;
