import React, { useState, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import LoginPopup from '../../auth/LoginPopup';
import RegisterPopup from '../../auth/RegisterPopup';
import { Modal, ModalBody } from 'reactstrap';

const FaqSection = ({ isAuthenticated }) => {
    
    const history = useHistory();

    const [ loginModal, toggleLoginModal ] = useState(false);
    const [ registerModal, toggleRegisterModal ] = useState(false);

    const swapLoginRegister = () => {
        toggleLoginModal(!loginModal);
        toggleRegisterModal(!registerModal);
    };

    const data = {
        heading: "Frequently asked questions",
        headingTwo: "Have questions? Look here",
        headingText: "",
        headingTexttwo: "",
        faqText: "Still have questions?",
        faqTextLink: "Tell us what you need."
    };

    const faqData = [
        {
            "id": 1,
            "title": "What does MySchool do?",
            "content": "MySchool builds tools to help the homeschooling community come together to enrich the education for all our children."
        },
        {
            "id": 2,
            "title": "Where do these Spots come from?",
            "content": "Spots are either suggested by a member, or registered by one of our members who actively manages the Spot's profile.  Go to the map and click on \"Suggest a Spot\" or \"Become a Spot\" to help build out your local learning community!",
        },
        {
            "id": 3,
            "title": "I'm new to homeschooling, where should I start?",
            "content": "Like anything, homeschooling has it's own language.  There are some key things you should know about before doing some research in order for 'homeschool speak' to make any sense to you. Click below to download our Homeschool 101 guide.",
            "loginDownload": "https://myschoolathome-public-documents.s3.amazonaws.com/Homeschool+101.pdf"
        }
    ];

    const faqDataTwo = [
        {
            "id": 1,
            "title": "How can I edit my personal information?",
            "content": "Just visit your dashboard.  You control all your data!"
        },
        {
            "id": 2,
            "title": "What do I do?  _____ isn't working!",
            "content": "If anything isn't working for you, let us know by emailing us at info@myschoolathome.io!"
        },
        {
            "id": 3,
            "title": "Do you have a free trial?",
            "content": "Registration and many of our services are totally free!  For paid offerings (classes, field trips, etc.), your money goes to other, hardworking homeschooling parents who offer those services, so we can't offer freebees at their expense."
        }
    ];

    return (
        <Fragment>
            <section className="section faq-area style-two ptb_100">
                <div className="container">
                    <div className="row justify-content-center">
                    <div className="col-12 col-md-10 col-lg-7">
                        {/* Section Heading */}
                        <div className="section-heading text-center">
                            <h2 className="text-capitalize">{data.heading}</h2>
                            <p className="d-none d-sm-block mt-4">{data.headingText}</p>
                            <p className="d-block d-sm-none mt-4">{data.headingTexttwo}</p>
                        </div>
                    </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-12">
                            {/* FAQ Content */}
                            <div className="faq-content">
                                {/* sApp Accordion */}
                                <div className="accordion" id="sApp-accordion">
                                    <div className="row justify-content-center">
                                        <div className="col-12 col-md-6">
                                        {/* Single Card */}
                                        {faqData.map((item, idx) => {
                                            return(
                                                <div key={`fo_${idx}`} className="card border-0">
                                                    {/* Card Header */}
                                                    <div className="card-header bg-inherit border-0 p-0">
                                                    <h2 className="mb-0">
                                                        <button className="btn btn-myschool-blue text-center px-1 py-2" type="button">
                                                            {item.title}
                                                        </button>
                                                    </h2>
                                                    </div>
                                                    {/* Card Body */}
                                                    <div className="card-body px-0 py-3">
                                                        {item.content}
                                                        {item.loginDownload && isAuthenticated ? 
                                                            <div className='text-center'>
                                                                <a href={item.loginDownload} className='btn btn-myschool-red' target="_blank" rel="noopener noreferrer">Download</a>
                                                            </div>
                                                            :
                                                            (item.loginDownload && !isAuthenticated) ?
                                                            <div className='text-center'>
                                                                <button className='btn btn-myschool-red' onClick={()=>toggleRegisterModal(true)}>Click Here to Register and Download</button>
                                                            </div>
                                                            :''
                                                        }
                                                    </div>
                                                </div>
                                            );
                                        })}
                                        </div>
                                        <div className="col-12 col-md-6">
                                        {/* Single Card */}
                                        {faqDataTwo.map((item, idx) => {
                                            return(
                                                <div key={`ft_${idx}`} className="card border-0">
                                                    {/* Card Header */}
                                                    <div className="card-header bg-inherit border-0 p-0">
                                                    <h2 className="mb-0">
                                                        <button className="btn btn-myschool-blue text-center px-1 py-2" type="button">
                                                            {item.title}
                                                        </button>
                                                    </h2>
                                                    </div>
                                                    {/* Card Body */}
                                                    <div className="card-body px-0 py-3">
                                                        {item.content}
                                                    </div>
                                                </div>
                                            );
                                        })}
                                        </div>
                                    </div>
                                    <div className="row justify-content-center">
                                        <p className="text-body text-center pt-4 fw-5">{data.faqText} <a href="/#">{data.faqTextLink}</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Modal isOpen={loginModal} centered toggle={() => toggleLoginModal(!loginModal)}>
              <ModalBody className='text-center'>
                <div className='float-right'>
                  <button className="basic-button" 
                    onClick={() => toggleLoginModal(false)}>
                    <i className='fa fa-times color-light-grey'/>
                  </button>
                </div>
                You have to register and login to do that!
                <LoginPopup loginModal={loginModal} toggleLoginModal={toggleLoginModal}/>
                <button onClick={()=>swapLoginRegister()} className='basic-button text-bold text-underlined'>I need to Register</button>
              </ModalBody>
            </Modal>

            <Modal isOpen={registerModal} centered toggle={() => toggleRegisterModal(!registerModal)}>
              <ModalBody className='text-center'>
                <div className='float-right'>
                  <button className="basic-button" 
                    onClick={() => toggleRegisterModal(false)}>
                    <i className='fa fa-times color-light-grey'/>
                  </button>
                </div>
                <RegisterPopup registerModal={registerModal} toggleRegisterModal={toggleRegisterModal} history={history}/>
                <button onClick={()=>swapLoginRegister()} className='basic-button text-bold text-underlined'>Go to Login</button>
              </ModalBody>
            </Modal>
        </Fragment>
    );
};

FaqSection.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
});

export default connect(  mapStateToProps, {  })(FaqSection);