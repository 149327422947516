import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import AuthModals from '../components/auth/AuthModals';

const MySchoolPlusAccessButton = ({ auth: {isAuthenticated, user}, btnLink, btnText } ) => {
	const history = useHistory();

	const [ loginModal, toggleLoginModal ] = useState(false);
	const [ registerModal, toggleRegisterModal ] = useState(false);

	return (
		<div>
			<button 
				className='btn btn-myschool-blue' 
				onClick={ !isAuthenticated ?
						()=>toggleLoginModal(true)
					: isAuthenticated && (!user || !user.subscriberStatusActive) ?
						()=>history.push(btnLink)
					:
						()=>history.push("/myschoolplus/subscribe")
					}
				>
				{btnText}
			</button>
			<AuthModals toggleLoginModal={toggleLoginModal} loginModal={loginModal} toggleRegisterModal={toggleRegisterModal} registerModal={registerModal} />
		</div>
	)
};

MySchoolPlusAccessButton.defaultProps = {
	btnText: "Become a MySchool+ member to access",
	btnLink: "/myschoolplus/subscribe"
}

MySchoolPlusAccessButton.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps,{ })(MySchoolPlusAccessButton);