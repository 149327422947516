import React, { Fragment } from 'react';
import { Card, CardText } from 'reactstrap';

const ChildRegCard = ({ child, deleteSavedChild, index }) => {

  return (
      <Fragment>
      <Card body inverse className='registration-child-card'>
        <CardText>
          <button className="basic-button float-right" 
            onClick={() => deleteSavedChild(index)}>
            <i className='fa fa-times color-white'/>
          </button>
          <strong>Name:</strong>{` ${child.childName}`}
        </CardText>
        <CardText>
          <strong>Age:</strong>{` ${child.childAge}`}
        </CardText>
        { child.parentName ?
          (
            <CardText>
              <strong>Parent:</strong>{` ${child.parentName}`}
            </CardText>
          ):''
        }
        { child.regEmail ?
          (
            <CardText>
              <strong>Email:</strong>{` ${child.regEmail}`}
            </CardText>
          ):''
        }
        { child.phone ?
          (
            <CardText>
              <strong>Phone:</strong>{` ${child.phone}`}
            </CardText>
          ):''
        }
      </Card>
    </Fragment>
  );
};


export default ChildRegCard;
