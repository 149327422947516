import React,  { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import blankProfileImage from '../../images/profile-img-blank.svg';
import ImageContainer from '../layout/ImageContainer';
import ProfileImageUpload from '../dashboard/ProfileImageUpload';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

const ProfileTop = ({ profile: {image, user, social}, auth }) => {
  
  const [ displayModalPhotoUpload, toggleModalPhotoUpload] = useState(false);

  return (
      <Fragment>
        <div className='text-center'>
          { auth.user._id === user._id ? 
              <div className='text-center'>
                <button 
                  className='btn btn-myschool-blue basic-button-MBP btn-40-circle' 
                  onClick={()=>toggleModalPhotoUpload(true)}
                  style={{position: 'absolute', textAlign: 'center', marginLeft: '40px', zIndex:2}}
                  >
                    <i className='fas fa-pen '/>
                </button>
              </div>
            :''
          }
          <div>
            <ImageContainer
              src={image && image !== 'default.jpg' ? image: blankProfileImage}
              thumb={blankProfileImage}
              width='150px' 
              height='150px' 
              className='round-img' 
              alt='profile image'
            />
          </div>
          <h1 className='large color-myschool-blue'>{user && user.username}</h1>
          <div className='icons my-1'>
            {social && social.twitter && (
              <a href={social.twitter} target='_blank' rel='noopener noreferrer'>
                <i className='fab fa-twitter fa-2x' />
              </a>
            )}
            {social && social.facebook && (
              <a href={social.facebook} target='_blank' rel='noopener noreferrer'>
                <i className='fab fa-facebook fa-2x' />
              </a>
            )}
            {social && social.linkedin && (
              <a href={social.linkedin} target='_blank' rel='noopener noreferrer'>
                <i className='fab fa-linkedin fa-2x' />
              </a>
            )}
            {social && social.youtube && (
              <a href={social.youtube} target='_blank' rel='noopener noreferrer'>
                <i className='fab fa-youtube fa-2x' />
              </a>
            )}
            {social && social.instagram && (
              <a href={social.instagram} target='_blank' rel='noopener noreferrer'>
                <i className='fab fa-instagram fa-2x' />
              </a>
            )}
          </div>
        </div>

        <Modal isOpen={displayModalPhotoUpload} toggle={() => toggleModalPhotoUpload(!displayModalPhotoUpload)}>
          <ModalHeader className='content-justify-center'>
            <div className='float-right'>
              <button className="basic-button" onClick={() => toggleModalPhotoUpload(false)}>
              <i className='fa fa-times color-light-grey'/>
              </button>
            </div>
          </ModalHeader>
          <ModalBody>
            <ProfileImageUpload />
          </ModalBody>
        </Modal>

      </Fragment>
    );
};

ProfileTop.propTypes = {
  profile: PropTypes.object.isRequired
};



export default connect(null, { })(ProfileTop);
