import React, { useEffect, useState } from 'react';
import { Container, Table } from 'reactstrap';
import AdminRoutes from '../AdminRoutes';
import api from '../../../utils/api';

const ProviderAdminInfo = () => {
  
  const [providers, setProviders] = useState([]);

  // useEffect(() => {
  //   const res = await api.post('/v1/', {});
  // }, []);

    return (
      <div>
        <AdminRoutes />
          <Table striped className='admin-table'>
            <thead>
              <tr> 
                <th>#</th>
                <th>ID</th>
                <th>Name</th>
                <th>Views (past mo)</th>
                <th>Views All Time</th>
              </tr>
            </thead>
            <tbody>
              {providers
                .map((provider, i)=>
                (
                  <tr key={provider._id}>
                    <td>{i+1}</td>
                    <td>{provider._id}</td>
                    <td>{provider.providerName}</td>
                    <td>{provider.pageViews}</td>
                    <td>{provider.pageViews}</td>
                  </tr>
                ))
              }
            </tbody>
          </Table>
      </div>
    );
};


export default ProviderAdminInfo;