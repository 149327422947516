import {
  RESOURCE_ERROR,
  GET_RESOURCES,
  GET_RESOURCE,
  GET_RESOURCES_WITHIN_RADIUS,
  GET_RESOURCES_WITHIN_MAPBOUNDS
} from '../actions/types';

const initialState = {
  resource: null,
  resources: [],
  resourceLoading: true,
  error: {}
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_RESOURCE:
      return {
        ...state,
        resource: payload,
        resourceLoading: false
      };
    case GET_RESOURCES:
    case GET_RESOURCES_WITHIN_RADIUS:
    case GET_RESOURCES_WITHIN_MAPBOUNDS:
      return {
        ...state,
        resources: payload,
        resourceLoading: false
      };
    case RESOURCE_ERROR:
      return {
        ...state,
        error: payload,
        resourceLoading: false
      };
    default:
      return state;
  }
}
