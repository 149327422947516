import {
  GET_MEETUP,
  MEETUP_ERROR
} from '../actions/types';

const initialState = {
  meetup: null,
  meetupLoading: true,
  error: {}
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_MEETUP:
      return {
        ...state,
        meetup: payload,
        meetupLoading: false
      };
    case MEETUP_ERROR:
      return {
        ...state,
        error: payload,
        meetupLoading: false
      };
    default:
      return state;
  }
}
