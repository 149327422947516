import React, { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Spinner from '../../layout/Spinner';
import { Card, CardHeader, CardTitle, CardBody, Button } from 'reactstrap';
import EventDashboardBriefListing from './events/EventDashboardBriefListing';
import { getMyEvents } from '../../../actions/event';
import extendCircle from '../../../images/icon-extend-down-circle.svg';

const DashboardHostedEvents = ({ auth, event: {events, eventLoading}, displaySection, getMyEvents }) => {
  const history = useHistory();

  const [displayInfo, toggleInfo] = useState(true);

  useEffect(()=> {
    if (auth && auth.user) {
      getMyEvents();
    }
  },[auth]);

  // const [futureEvents, setFutureEvents] = useState([]);
  // const [pastEvents, setPastEvents] = useState([]);
  // useEffect(()=> {
  //   for(let i = 0; i < events.length; i++) {
  //     const ev = events[i];
  //     console.log(ev);
  //     const d = new Date();
  //     if (ev.startDate >= d) { //need to format date to unix to compare
  //       console.log('future', ev.startDate);
  //     } else if (ev.startDate < d) {
  //       console.log('past', ev.startDate);
  //     }
  //   }
  // },[]);

    return eventLoading ? (
    <Spinner />
  ) : (
    <Fragment>          

        { displaySection.hostedEvents && events !== null? 
          (
            <Card>
              <CardHeader>
                <CardTitle>Events you are Hosting
                  <Button className="basic-button float-right" onClick={() => toggleInfo(!displayInfo)}><img src={extendCircle} width="25px" alt="extend info dropdown"/>
                  </Button>
                </CardTitle>
              </CardHeader>

              { displayInfo &&
                <CardBody>
                  <div className='msclasses my-3'>
                    {events && events.length < 1 ? <div>You are not currently hosting any events</div>
                      :
                      events
                        .map(event => (
                          <Card key={event._id} className='my-2'>
                            <EventDashboardBriefListing event={event} showBookingsLink={true} history={history}/>
                          </Card>
                      ))
                    }                  
                  </div>
                </CardBody>
              }
            </Card>
          ) : ''
        }

    </Fragment>
  );
};

DashboardHostedEvents.propTypes = {
  auth: PropTypes.object.isRequired,
  event: PropTypes.object.isRequired,
  getMyEvents: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  event: state.event
});


export default connect(mapStateToProps, { getMyEvents })(DashboardHostedEvents);

        