import {
  GET_PORTFOLIO_BY_CHILDID,
  GET_PORTFOLIO_STATS_BY_CHILDID,
  EDIT_PORTFOLIO_ENTRY,
  PORTFOLIO_ERROR
} from '../actions/types';

const initialState = {
  portfolio: [],
  portfolioStats: [],
  portfolioEntry: null,
  portfolioLoading: true,
  error: {}
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_PORTFOLIO_BY_CHILDID:
      return {
        ...state,
        portfolio: payload,
        portfolioLoading: false
      };
    case GET_PORTFOLIO_STATS_BY_CHILDID:
      return {
        ...state,
        portfolioStats: payload,
        portfolioLoading: false
      };
    case EDIT_PORTFOLIO_ENTRY:
      return {
        ...state,
        portfolio: state.portfolio.map(entry => {
          // entry._id === payload._id ? payload : entry
          if (entry._id === payload._id) {
            // console.log(entry,'replacing with',payload);
            return payload;
          }
          // console.log('unchanged entry');
          return entry;
        }),
        portfolioLoading: false
      };
    case PORTFOLIO_ERROR:
      return {
        ...state,
        error: payload,
        portfolioLoading: false
      };
    default:
      return state;
  }
}
