import React, { useState, useEffect } from "react";
import ImageContainer from '../layout/ImageContainer';
import blankProfileImage from '../../images/profile-img-blank.svg';
import axios from 'axios';
import Spinner from '../layout/Spinner';

const PortfolioImage = ({ imageKey }) => {
  
  const [image, setImage] = useState("");
  const [ isImgReady, setIsImgReady ] = useState(false);

  //solution source: https://stackoverflow.com/questions/70534780/convert-weird-image-characters-for-use-in-image-src
  function blobToDataURL(blob, callback) {
    var a = new FileReader();
    a.onload = function (e) {
      callback(e.target.result);
    };
    a.readAsDataURL(blob);
	}

  const getImageFromStorage = async () => {
    try {
      const data = {
        key:imageKey
      };
      await axios({
          method: 'POST',
          url: '/api/v1/portfolio/file',
          data: data,
          responseType: 'blob'
        })
        .then((response) => {
          blobToDataURL(response.data, function (dataurl) {
	          setImage(dataurl); 
          });
        })
        .then(()=> {
        	setIsImgReady(true);
        });
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(()=> {
		getImageFromStorage();
  },[]);

  return (
    <div>
    	{isImgReady ? 
				<ImageContainer
	        src={image ? image: blankProfileImage}
	        // width='300px' 
	        // height='300px' 
	        className='' 
	        alt='portfolio image'
	      />
	      :
	      <div>
	      	Getting Image...
		      <Spinner />
	      </div>
	    }
  </div>
  );
};

export default PortfolioImage;