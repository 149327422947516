import api from '../utils/api';
import { setAlert, setLongAlert } from './alert';
import history from '../history';
import queryString from 'query-string';

import {
  GET_PROVIDER,
  GET_MY_PROVIDER,
  PROVIDER_ERROR,
  CLEAR_PROVIDER,
  PROVIDER_DELETED,
  GET_PROVIDERS,
  GET_PROVIDERS_WITHIN_RADIUS,
  GET_PROVIDERS_WITHIN_MAPBOUNDS,
  EMAIL_PROVIDER_GEN_ERROR
} from './types';

// Get current user's provider profile
export const getMyProviders = () => async dispatch => {
  
  try {
    
    const res = await api.get('/provider/MyInfo');
    
    dispatch({
      type: GET_PROVIDERS,
      payload: res.data
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: PROVIDER_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};


// Get MY provider by Provider ID
export const getMyProviderById = providerId => async dispatch => {

  try {
    
    const res = await api.get(`/provider/${providerId}`);
    
    dispatch({
      type: GET_MY_PROVIDER,
      payload: res.data
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: PROVIDER_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get all providers
export const getProviders = () => async dispatch => {
  dispatch({ type: CLEAR_PROVIDER });

  try {
    const res = await api.get('/provider');

    dispatch({
      type: GET_PROVIDERS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: PROVIDER_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

//Get providers within radius with QUERY params
export const getProvidersWithinQuery = ( position, initialQuery ) => async dispatch => {
  
  let queryObject;

  if (!initialQuery.limit ) {
    queryObject = {...initialQuery, limit: 50 }
  } else {
    queryObject = initialQuery
  };

  const { lat, long } = position;

  Object.keys(queryObject).forEach(key => queryObject[key] === 'none' ? delete queryObject[key] : {});
  Object.keys(queryObject).forEach(key => queryObject[key] === undefined ? delete queryObject[key] : {});

  // console.log('queryObject undefined removed', queryObject);

  const query = queryString.stringify(queryObject);
  
  try {
    const res = await api.get(`/provider/providers-within-query/${lat}/${long}/${initialQuery.dist}?${query}`);
    // console.log('results', res.data.results);
    dispatch({
      type: GET_PROVIDERS_WITHIN_RADIUS,
      payload: res.data.data.providers
    });
  } catch (err) {
    dispatch({
      type: PROVIDER_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};


// Get all providers within Radius of a center point
export const getProvidersWithin = position => async dispatch => {
  
  let userPosition = {...position};

  if (!userPosition.dist ) {
    userPosition = {...userPosition, 
      dist: 50
    };
  }

  const { lat, long, dist } = userPosition;

  try {
    const res = await api.get(`/provider/providers-within/${lat}/${long}/${dist}`);
    // console.log(res);
    dispatch({
      type: GET_PROVIDERS_WITHIN_RADIUS,
      payload: res.data.data.providers
    });
  } catch (err) {
    dispatch({
      type: PROVIDER_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get providers within bounds -- this is for updating the Homeschool Map once the bounds change. 
export const getBoundedProviders = (bounds, filterData) => async dispatch => {
  
  const { latMin, latMax, longMin, longMax } = bounds;
  let queryObject;

  if (!filterData.limit ) {
    queryObject = {...filterData, 
      limit: 50
    };
  } else {
    queryObject = filterData
  };

  Object.keys(queryObject).forEach(key => queryObject[key] === 'none' ? delete queryObject[key] : {});
  
  Object.keys(queryObject).forEach(key => queryObject[key] === undefined ? delete queryObject[key] : {});

  const query = queryString.stringify(queryObject);

  try {
    const res = await api.get(`/provider/providers-on-map/${latMin}/${latMax}/${longMin}/${longMax}?${query}`);
    // console.log(res);
    dispatch({
      type: GET_PROVIDERS_WITHIN_MAPBOUNDS,
      payload: res.data.data.providers
    });
  } catch (err) {
    dispatch({
      type: PROVIDER_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get provider by User ID
export const getProvidersById = userId => async dispatch => {
  try {
    const res = await api.get(`/provider/user/${userId}`);

    dispatch({
      type: GET_PROVIDERS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: PROVIDER_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get provider by Provider ID
export const getProviderByProviderId = providerId => async dispatch => {

  try {
    
    const res = await api.get(`/provider/${providerId}`);
    
    dispatch({
      type: GET_PROVIDER,
      payload: res.data
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: PROVIDER_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};


// Create provider
export const createProvider = ( formData, history,edit = false) => async dispatch => {
  try {

    const res = await api.post('/provider', formData);

    dispatch({
      type: GET_PROVIDER,
      payload: res.data
    });

    dispatch(setAlert(edit ? 'Spot Updated' : 'Spot Created', 'success'));

    history.push('/dashboard');
    
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      window.scrollTo(0,0);
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: PROVIDER_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Create provider Version 2
export const createProviderV2 = ( formData ) => async dispatch => {
  try {

    const res = await api.post('/provider', formData);

    dispatch({
      type: GET_PROVIDER,
      payload: res.data
    });

    dispatch(setAlert('Spot Created', 'success'));

    
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      window.scrollTo(0,0);
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: PROVIDER_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Update provider
export const updateProvider = ( formData, history, providerId ) => async dispatch => {
  try {

    const res = await api.put(`/provider/${providerId}`, formData);

    dispatch({
      type: GET_PROVIDER,
      payload: res.data
    });

    history.push('/dashboard');

    dispatch(setAlert('Spot Updated', 'success'));
    
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      window.scrollTo(0,0);
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: PROVIDER_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

//Upload a provider profile image
export const uploadProviderImage = (formData, providerId) => async dispatch => {
  try {
    const configMulti = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    };

    const res = await api.post(`/provider/image/${providerId}`, formData, configMulti);

    dispatch({
      type: GET_PROVIDER,
      payload: res.data
    });

    // window.scrollTo(0,0);
    // dispatch(setLongAlert('Profile Image Uploaded.  You may need to refresh the page before the image will appear.', 'success'));

    // history.push('/dashboard');
    
  } catch (err) {
    console.log(err);
    
    dispatch({
      type: PROVIDER_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

//Send email to provider
export const emailProviderGeneral = (formData, id) => async dispatch => {
  try {
    
    await api.post(`/provider/${id}/sendEmail`, formData);
    
  } catch (err) {

    const errors = err.response.data.errors;

    if (errors) {
      window.scrollTo(0,0);
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: EMAIL_PROVIDER_GEN_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Delete provider profile
export const deleteProvider = (providerId) => async dispatch => {
  if (window.confirm('Are you sure? This CANNOT be undone!')) {
    
    try {
      const res = await api.delete(`/provider/${providerId}`);
      
      dispatch({
        type: PROVIDER_DELETED,
        payload: res.data
      });
      window.scrollTo(0,0);
      dispatch(setAlert('This spot has been permanantly deleted.', 'success'));

    } catch (err) {
      if (err.response) {
        dispatch({
          type: PROVIDER_ERROR,
          payload: { msg: err.response.data, status: err.response.status }
        });
      } else if (err.request) {
        dispatch(setAlert('There was an error. Please reload the page and try again.', 'danger'));
        console.log(err.request);
      } else {
        dispatch(setAlert('There was an error. Please reload the page and try again.', 'danger'));
        console.log('Error msg', err.message);
      }
      window.scrollTo(0,0);
    }
  }
};
