import React, { useState } from 'react';
import EventBriefInfo from '../EventBriefInfo';
import { Card, Tooltip } from 'reactstrap';
import logoStamp from '../../../images/logo100-100.svg';
import { useHistory } from 'react-router-dom';

const EventListItem = ({ event, setMapFlyTo }) => {

  const history = useHistory();

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  const [tooltip1Open, setTooltip1Open] = useState(false);
  const toggle1 = () => setTooltip1Open(!tooltip1Open);

  return ( 
      <Card className={event.isOfficialEvent ? 'event-card event-card-official':'event-card'} onTouchStart={() => setMapFlyTo([event.lat, event.long])} onMouseEnter={() => setMapFlyTo([event.lat, event.long])}>
        { event.isOfficialEvent && 
          <div>
            <div className='card-corner-tab' id={`tooltip-official-event-${event._id}`}><img src={logoStamp} width='20px' className="card-corner-tab-content"/></div>
            <Tooltip placement="top" isOpen={tooltip1Open} target={`tooltip-official-event-${event._id}`} toggle={toggle1}>
              This is an official MySchool event!
            </Tooltip>
          </div>
        }
        { event.isVirtual && 
          <div>
            <div className='card-corner-tab' id={`tooltip-online-event-${event._id}`}><i className="fas fa-video card-corner-tab-content"/></div>
            <Tooltip placement="top" isOpen={tooltipOpen} target={`tooltip-online-event-${event._id}`} toggle={toggle}>
              This event is held virtually!
            </Tooltip>
          </div>
        }
        <EventBriefInfo event={event} history={history}/>
      </Card>
    );
};


export default EventListItem;

