import React, { Fragment } from 'react';
import { Row, Col, Button, Badge } from 'reactstrap';
import CopyTextButton from '../../utils/CopyTextButton';
import Carousel from '../../utils/CarouselComponent';
import MySchoolPlusAccessButton from '../../utils/MySchoolPlusAccessButton';
import capitalizeName from '../../utils/capitalizeName';

const ProviderSidebarDetails = ({ provider, history, user }) => {

  const weekday = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
    
    return (
        <div>
            {/* Single Blog Details */}
            <article className="single-provider-details">
                {/* Provider Content */}
                <div className="provider-content sApp-provider">
                    {/* Provider Details */}
                    <div className="provider-details">
                        <h2 className="py-3">{provider.providerName}</h2>
                        
                        {provider.isPortfolioPartner ? 
                          <div className="mb-2">
                            <Button type="button" className='btn btn-myschool-red' onClick={()=>history.push("/scavenger-hunt")}>
                              Scavenger Hunt Partner
                            </Button>
                          </div>
                          :''
                        }

                        {provider && provider.category ? 
                          <div className="mb-2text-bold">
                            <strong>Category:</strong> {` ${capitalizeName(provider.category)}`}
                          </div>
                          :''
                        }

                        <Row>
                          <Col>
                            {provider && provider.email ? (
                              <div>
                                <strong>Email:</strong>
                                <a href={`mailto:${provider.email}`} target='_blank' rel='noopener noreferrer'>
                                    {` ${provider.email}`}
                                  </a>
                                  {' '}
                                  { (navigator.clipboard) ? 
                                    <CopyTextButton text={provider.email} />
                                    : ''
                                  }
                              </div>
                              ):''
                            }
                            {provider && provider.phone ? (
                              <div>
                                <strong>Phone:</strong>{` ${provider.phone}`}
                              </div>
                              ):''
                            }
                            {provider && provider.website ? (
                              <div>
                                <strong>Website:</strong> <a href={provider.website} target='_blank' rel='noopener noreferrer'>{provider.website}</a>
                              </div>
                              ):''
                            }
                            {provider && provider.educationalLeader ? (
                              <div>
                                <strong>Contact Name:</strong>{` ${provider.educationalLeader}`}
                              </div>
                              ):''
                            }
                            {provider && provider.religiousAffiliation ? (
                              <div>
                                <strong>Religious Affiliation:</strong>{` ${provider.religiousAffiliation}`}
                              </div>
                              ):''
                            }
                            {provider && provider.ageStart && provider.ageEnd ? (
                              <div>
                                <strong>Age:</strong>{` ${provider.ageStart} to ${provider.ageEnd}`}
                              </div>
                              ):''
                            }
                          </Col>
                        </Row>

                        {provider && provider.isDropOff ? (
                          <Row>
                            <Col>
                              <div><strong>Is this a drop-off provider?</strong> {provider.isDropOff && provider.isDropOff === true ? `Yes` : `No`} </div>
                            </Col>
                          </Row>
                          ): '' 
                        }

                        {provider && provider.genInfo ? (
                          <Row className='my-2'>
                            <Col>
                              <strong>Info:</strong>{' '}{provider && provider.genInfo}
                            </Col>
                          </Row>
                          ):''
                        }

                        {provider && provider.typicalDay ? (
                          <Row className='my-2'>
                            <Col>
                              {provider && provider.typicalDay}
                            </Col>
                          </Row>
                          ):''
                        }

                        {provider && provider.whenOperate ? (
                          <Row className='my-2'>
                            <Col>
                              {provider && provider.whenOperate}
                            </Col>
                          </Row>
                          ):''
                        }

                        {provider && (provider.category === 'tutor' || provider.category ==='cooperative' || provider.category === 'tutorial') ?
                          <div>
                          {provider && provider.edPhil ? (
                            <Row>
                              <Col>
                                <strong>Educational Philosophy:</strong> {provider.edPhil}
                              </Col>
                            </Row>
                            ):''
                          }       
                          </div>
                          : ''
                        }
                        
                        { provider && provider.entryFees ? 
                          <Row>
                            <Col>
                                <div>
                                  <div><span className='text-bold'>Entry Fee:{' '}</span>
                                    {provider && provider.entryFees.isFree ?
                                      `Free`
                                      :
                                      <span>
                                      {provider.entryFees.fees.map((fee,i)=> {
                                          return <span key={i}>{i>0 ? ', ':''}{fee.name}{`: `}{fee.price > 0 ? `$${fee.price}`:'Free'}</span>
                                        })
                                      }
                                      </span>
                                    }
                                    </div>
                                </div>
                            </Col>
                          </Row>
                          :''
                        }

                        { provider && ((provider.freeEntryOptions && provider.freeEntryOptions.length > 0) || (provider.reciprocals && provider.reciprocals.length > 0)) ? 
                          <Row>
                            <Col>
                                <div>
                                  <div>
                                    <span className='text-bold'>
                                        Ways to get in free:{' '}
                                    </span>
                                    <div className="ml-3">
                                      {user && user.subscriberStatusActive ?
                                        <div>
                                          {provider && provider.freeEntryOptions ?
                                            <div>
                                              {provider.freeEntryOptions.map((option,i)=> {
                                                  return (
                                                    <div key={i}>
                                                      <span className="text-bold">
                                                        {option.name}
                                                      </span>
                                                      {option.link ?
                                                        <a href={option.link} target='_blank' rel='noopener noreferrer'>{` - ${option.description} >>`}</a>
                                                        :
                                                        <span>{` - ${option.description}`}</span>
                                                      }
                                                    </div>
                                                  )
                                                })
                                              }
                                            </div>
                                            :''
                                          }
                                          { provider.reciprocals && provider.reciprocals.length > 0 ?
                                            <div className='text-bold'>
                                              {`Join a reciprocal program: `}
                                              {provider.reciprocals.map((recip,i)=> {
                                                  return (
                                                      <Badge key={i} className="pill-color-blue text-09rem mr-1 cursor-pointer" pill onClick={()=>history.push("/myschoolplus/reciprocals")}>
                                                        {recip}
                                                        {provider.reciprocalData && provider.reciprocalData[recip] && ` $${provider.reciprocalData[recip].price}`}
                                                      </Badge>
                                                    )
                                                  }
                                              )}
                                            </div>
                                            :''
                                          }
                                        </div>
                                        :
                                        <div>
                                          <MySchoolPlusAccessButton />
                                        </div>
                                      }
                                    </div>
                                  </div>
                                </div>
                            </Col>
                          </Row>
                          :''
                        }

                        { provider && provider.opHours ? 
                          <Row>
                            <Col>
                                <div>
                                  <div className='text-bold'>Hours of Operation:</div>
                                  {provider.opHours.map((dow,i)=> {
                                    return <div key={i} className="ml-4">{weekday[i]}{`: `}{dow}</div>
                                    }
                                  )}
                                </div>
                            </Col>
                          </Row>
                          :''
                        }

                        { provider && provider.qrCodeLoc ?  
                          <Row>
                            <Col>
                              {provider.qrCodeLoc.description ?
                                <div>
                                  <span className='text-bold'>Where to find the MySchool QR code:{' '}</span>
                                  {provider.qrCodeLoc.description}
                                </div>
                                :''
                              }
                              {provider.qrCodeLoc.images ?
                                <div>
                                  <Carousel items={provider.qrCodeLoc.images} />
                                </div>
                                :''
                              }
                            </Col>
                          </Row>
                          :''
                        }
                        
                        <br/>
                        
                        {provider && (provider.category === 'tutor' || provider.category ==='tutorial') && provider.edAndEx?
                          <Row>
                            <Col>
                              <strong>Education/Experience:</strong> {provider.edAndEx}
                            </Col>
                          </Row>
                            : ''
                        }
                        
                        {provider && (provider.category === 'cooperative' || provider.category === 'tutorial') ?
                          <Fragment>

                            {provider && provider.parentInvolveLevel ? (
                              <Row>
                                <Col>
                                  <strong>Parent Involvement Level:</strong> {provider.parentInvolveLevel}
                                </Col>
                              </Row>
                              ): ''
                            }
                            {provider && provider.parentInvolveNarr ? (
                              <Row>
                                <Col>
                                  <strong>Parent Involvement Info:</strong> {provider.parentInvolveNarr}
                                </Col>
                              </Row>
                              ): ''
                            }
                            {provider && provider.meetingFreqNum && provider.meetingFreqUnit ? (
                              <Row>
                                <Col>
                                  <strong>Meeting Frequency:</strong> {provider.meetingFreqNum} times {provider.meetingFreqUnit}
                                </Col>
                              </Row>
                              ): ''
                            }
                            {provider && provider.meetingFreqNarr ? (
                              <Row>
                                <Col>
                                  <strong>Meeting Info:</strong>  {provider.meetingFreqNarr}
                                </Col>
                              </Row>
                              ): ''
                            } 
                            
                            <br/>

                            {provider && provider.slotsAvailable && provider.slotsTotal? (
                              <Row>
                                <Col>
                                  <strong>Slots Available:</strong> {provider.slotsAvailable} of {provider.slotsTotal}
                                </Col>
                              </Row>
                              ): ''
                            } 
                            
                            {provider && provider.enrollFee && provider.enrollFeeUnit? (
                              <Row>
                                <Col>
                                  <strong>Enrollment Fee:</strong> {`$${provider.enrollFee} ${provider.enrollFeeUnit}ly`}
                                </Col>
                              </Row>
                              ): ''
                            } 

                            {provider && provider.ageStart && provider.ageEnd? (
                              <Row>
                                <Col>
                                  <strong>Ages:</strong> {provider.ageStart} to {provider.ageEnd}  
                                </Col>
                              </Row>
                              ): ''
                            } 
                            
                            {provider && provider.guidelines? (
                              <Row>
                                <Col>
                                  <strong>Guidelines:</strong> 
                                  <div>{provider.guidelines}</div>  
                                </Col>
                              </Row>
                              ): ''
                            } 
                            

                            </Fragment>

                            : ''
                        }

                        {provider && provider.category === 'tutorial' ?
                          <Fragment>
                            <Row>
                              <Col>
                                <strong>Head of Educational Services:</strong> {provider && provider.educationalLeader}
                              </Col>
                            </Row>
                          </Fragment>

                            : ''
                        }

                        {provider && provider.networks && provider.networks.length > 0 ?
                            <Row>
                              <Col>
                                <div>
                                  <strong>This organization is a member of the following networks!</strong>
                                </div>
                                {provider.networks.includes("Empowering Parents Idaho") ?
                                  <div className="ml-4">
                                    <span className="text-bold">Idaho's Empowering Parents Program</span>
                                     - Idaho's Board of Education provides funds for parents of K-12 aged children, including homeschooling families, with funds that can be used at approved vendors, like this one!
                                    <div>
                                      <a className="btn btn-myschool-blue" href="https://www.withodyssey.com/empoweringparents" target='_blank' rel='noopener noreferrer'>{` Learn More >>`}</a>
                                    </div>
                                  </div>
                                  :
                                  ''
                                }
                              </Col>
                            </Row>
                          :''
                        }

                        
                    </div>
                </div>                
            </article>
        </div>
    );
}

export default ProviderSidebarDetails;