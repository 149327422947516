import React from 'react';
import { Card, CardBody } from 'reactstrap';
import Rating from 'react-rating';
import Moment from 'react-moment'

const ResourceReviews = ({
  auth,
  deleteResourceReview, 
  resourceReviews: {
    user,
    review,
    rating,
    createdAt,
    _id
  }
}) => {
  return (
    <Card className='my-3'>
      <CardBody>
        { auth && auth.isAuthenticated && auth.user && auth.user._id === user._id ?
          <div className='float-right'>
            <button onClick={()=>deleteResourceReview(_id)} className='basic-button'><i className='fas fa-trash-alt color-myschool-blue' /></button>
          </div>
          : ''
        }
        <div className='text-bold'>{user.username}</div>
        <div>
          <Rating
            emptySymbol="far fa-star color-myschool-blue"
            fullSymbol="fas fa-star color-myschool-blue"
            initialRating={rating}
            readonly
          />
        </div>
        <div>{review}</div>
        {`Reviewed on: `}<Moment date={createdAt} format="MM/DD/YYYY"/>
      </CardBody>
    </Card>
  )
};

export default ResourceReviews;
