import React, { useEffect, useState, Fragment } from "react";
import { Link, withRouter, Redirect, useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from 'axios';
import { createProfileV2, getCurrentProfile } from "../../actions/profile";
import { Form, Container, Row, Col, Label, FormGroup, Input, Modal, ModalBody, Button, Spinner, Alert } from 'reactstrap';
import EducationalPhilosophies from '../company/EducationalPhilosophies';
import HowDoWeProcessUserAddress from '../company/HowDoWeProcessUserAddress';
import ProfileFormMapWrapper from './geoLocation/ProfileFormMapWrapper';
import AddChild from '../child/AddChild';
import { setAlert } from '../../actions/alert';

const CreateProfilePreGeolocate = ({ createProfileV2, getCurrentProfile, setAlert, profile: { myProfile, profileLoading } }) => {

  const history = useHistory();
  //Local alerts
  const [ alertArray, setAlertArray ] = useState([]);

  //Sections
  const [ showSection1, toggleSection1 ] = useState(true);
  const [ showSection2, toggleSection2 ] = useState(false);
  const [ showSection3, toggleSection3 ] = useState(false);

  //Section Actions
  const [ isSuccess1, setIsSuccess1 ] = useState(false);

  const swapSection12 = () => {
    toggleSection1(!showSection1);
    toggleSection2(!showSection2);
    toggleSection3(false);
    setIsSuccess1(false);
  };

  const swapSection23 = () => {
    toggleSection1(false);
    toggleSection2(!showSection2);
    toggleSection3(!showSection3);
  };

  const [displayModalEdPhil, toggleModalEdPhil] = useState(false);

  const [ mapMeData, setMapMeData] = useState({
    address: '',
    city:'',
    state:'',
    zip:'',
  });

  const [ mapOptIn, setMapOptIn ] = useState(true);
  const [ formData, setFormData ] = useState({
    showOnMap: true,
    city: '',
    state: '',
    zip: '',
    indivLat: '',
    indivLong:'',
    coordinates:'',
    bio: '',
    edPhil: '',
    lookingFor: '',
    shareWithOthers: '',
    howDiscoveredMySchool: '',
    image: '',
    covidSocialLevel: '',
    facebook: '',
    virtualMeetups: false
  });
  const { showOnMap, indivLat, indivLong, coordinates, bio, edPhil, lookingFor, shareWithOthers, howDiscoveredMySchool, image, covidSocialLevel, facebook, virtualMeetups } = formData;

  useEffect(() => {
    getCurrentProfile();
  }, []);

  useEffect(() => {
    setMapMeData({
      ...mapMeData,
      city: !profileLoading && myProfile && myProfile.city ? myProfile.city : '',
      state: !profileLoading && myProfile && myProfile.state ? myProfile.state : '',
      zip: !profileLoading && myProfile && myProfile.zip ? myProfile.zip : ''
    });
  }, [profileLoading, myProfile]);

  useEffect(() => {
    setFormData({
      ...formData,
      bio: !profileLoading && myProfile && myProfile.bio ? myProfile.bio : '',
      edPhil: !profileLoading && myProfile && myProfile.edPhil ? myProfile.edPhil : '',
      lookingFor: !profileLoading && myProfile && myProfile.lookingFor ? myProfile.lookingFor : '',
      shareWithOthers: !profileLoading && myProfile && myProfile.shareWithOthers ? myProfile.shareWithOthers : '',
      howDiscoveredMySchool: !profileLoading && myProfile && myProfile.howDiscoveredMySchool ? myProfile.howDiscoveredMySchool : '',
      covidSocialLevel: !profileLoading && myProfile && myProfile.covidSocialLevel ? myProfile.covidSocialLevel : '',
      facebook: !profileLoading && myProfile && myProfile.social && myProfile.social.facebook ? myProfile.social.facebook : '',
      virtualMeetups: !profileLoading && myProfile && myProfile.virtualMeetups ? myProfile.virtualMeetups : false
    });
  }, [profileLoading, myProfile]);

  useEffect(() => {
    setFormData({
      ...formData,
      showOnMap: mapOptIn,
      city: mapMeData.city,
      state: mapMeData.state,
      zip: mapMeData.zip,
    });
  }, [mapOptIn, mapMeData]);

  const onChange = e =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onChangeMapMe = e =>
    setMapMeData({ ...mapMeData, [e.target.name]: e.target.value });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const onSubmit = async e => {
    setIsSubmitting(true);
    e.preventDefault();
    await createProfileV2(formData);
    if (window.location.pathname === '/create-profile') {
      history.push('/dashboard');
    } else {
      window.location.reload();
    }
  };

  const mapMe = async () => {
    setIsSubmitting(true);
    if (!mapMeData.address || !mapMeData.city || !mapMeData.state || !mapMeData.zip ) {
      setAlertArray([{msg:'Please fill out all fields.'}])
    }
    try {
      const config = { headers: { 'Content-Type': 'application/json' } };
      const jsonMapMeData = JSON.stringify(mapMeData);
      // console.log(jsonMapMeData);
      const res = await axios.post('/api/profile/mapMeForm', jsonMapMeData, config);
      if (res.status === 200) {
        setIsSubmitting(false);
        setFormData({
          ...formData,
          indivLat: res.data.indivLat,
          indivLong: res.data.indivLong,
          coordinates: res.data.coordinates
        })
        setIsSuccess1(true);
      }
    } catch (err) {
      setIsSubmitting(false);
      setIsSuccess1(false);
      const errors = err.response.data.errors;
      if (errors) {
          setAlertArray(errors);
          setTimeout(()=> {setAlertArray([])},5000);
      }
    }    

  }

  const [ findFamUrlCopied, setFindFamCopied ] = useState('');

  const copyFindFamilyUrl = async () => {
    try {
      navigator.clipboard.writeText(`https://myschoolathome.io/findFamilies`);
      setFindFamCopied(' - Copied!');
    } catch (err) {
      setFindFamCopied(' - Error!');
    }
  };

  const [geoLocationSuccess, setGeoLocationSuccess] = useState(false);
  const geoLocateMe = () => {
    if('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        setFormData({...formData, indivLat: position.coords.latitude, indivLong: position.coords.longitude });
        setGeoLocationSuccess(true);
        // console.log(position);
      }, () => {
        window.alert('You must allow location services in order to use this function.');
      });
    } else {
      setAlert('Sorry, but this browser doesn\'t support this function', 'danger');
    }
  };


  return profileLoading ? (
      <Spinner className='color-myschool-blue'/>
    ) : (
    <Fragment>
      <Container>
        <h2 className='large text-primary-ms'>Create Your Profile</h2>
        <Form className='form' onSubmit={e => onSubmit(e)}>
        { showSection1 ?
          <Fragment>
            {alertArray.length > 0 ? 
              alertArray.map((alert, index) =>
                  <Alert key={index} className='modal-alert'>
                    {alert.msg}
                  </Alert>
                )
              : ''
            }
            <div className='text-bold'>Would you like other families to be able to find you by appearing on the Homeschool Map?</div>
            <FormGroup check>
              <Label check>
                <Input type="checkbox" 
                  name="mapOptIn"
                  onChange={() => setMapOptIn(!mapOptIn)}
                  checked={mapOptIn}
                />{' '}
                Yes, that'd be great!
              </Label>
            </FormGroup>
            { mapOptIn ? <div className='color-danger text-075rem'>Note: You must do this if you want to find other families on the homeschool map.</div>: ''}
            { mapOptIn ?
              <Fragment>
                { !isSuccess1 ?
                  <div className='text-left'>
                    <FormGroup>
                      <Label className='text-bold'>We don't store your street address!  We just drop a pin on the map near the address provided.  You'll even be able to see and change it before submitting.</Label>
                      <Input type="text" 
                        name="address" 
                        id="address" 
                        placeholder="Street Address: 1234 Main St"
                        value={mapMeData.address}
                        onChange={e => onChangeMapMe(e)} 
                      />
                    </FormGroup>
                    <FormGroup>
                      <Input type="text" 
                      name="city" 
                      id="city" 
                      placeholder="City"
                      value={mapMeData.city}
                      onChange={e => onChangeMapMe(e)} 
                      />
                    </FormGroup>
                    <Row form>
                      <Col md={8}>
                        <FormGroup>
                          <Input type="select" 
                          name="state" 
                          id="state" 
                          placeholder="State (required)"
                          value={mapMeData.state}
                          onChange={e => onChangeMapMe(e)}
                          >
                            <option value="None">Select State...</option>
                            <option value="AL">Alabama</option>
                            <option value="AK">Alaska</option>
                            <option value="AZ">Arizona</option>
                            <option value="AR">Arkansas</option>
                            <option value="CA">California</option>
                            <option value="CO">Colorado</option>
                            <option value="CT">Connecticut</option>
                            <option value="DE">Delaware</option>
                            <option value="DC">District Of Columbia</option>
                            <option value="FL">Florida</option>
                            <option value="GA">Georgia</option>
                            <option value="GU">Guam</option>
                            <option value="HI">Hawaii</option>
                            <option value="ID">Idaho</option>
                            <option value="IL">Illinois</option>
                            <option value="IN">Indiana</option>
                            <option value="IA">Iowa</option>
                            <option value="KS">Kansas</option>
                            <option value="KY">Kentucky</option>
                            <option value="LA">Louisiana</option>
                            <option value="ME">Maine</option>
                            <option value="MD">Maryland</option>
                            <option value="MA">Massachusetts</option>
                            <option value="MI">Michigan</option>
                            <option value="MN">Minnesota</option>
                            <option value="MS">Mississippi</option>
                            <option value="MO">Missouri</option>
                            <option value="MT">Montana</option>
                            <option value="NE">Nebraska</option>
                            <option value="NV">Nevada</option>
                            <option value="NH">New Hampshire</option>
                            <option value="NJ">New Jersey</option>
                            <option value="NM">New Mexico</option>
                            <option value="NY">New York</option>
                            <option value="NC">North Carolina</option>
                            <option value="ND">North Dakota</option>
                            <option value="OH">Ohio</option>
                            <option value="OK">Oklahoma</option>
                            <option value="OR">Oregon</option>
                            <option value="PA">Pennsylvania</option>
                            <option value="Puerto Rico">Puerto Rico</option>
                            <option value="RI">Rhode Island</option>
                            <option value="SC">South Carolina</option>
                            <option value="SD">South Dakota</option>
                            <option value="TN">Tennessee</option>
                            <option value="TX">Texas</option>
                            <option value="UT">Utah</option>
                            <option value="VT">Vermont</option>
                            <option value="VA">Virginia</option>
                            <option value="U.S. Virgin Islands">U.S. Virgin Islands</option>
                            <option value="WA">Washington</option>
                            <option value="WV">West Virginia</option>
                            <option value="WI">Wisconsin</option>
                            <option value="WY">Wyoming</option>
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col md={4}>
                        <FormGroup>
                          <Input 
                            type="text" 
                            name="zip" 
                            id="zip" 
                            placeholder="Zip"
                            value={mapMeData.zip}
                            onChange={e => onChangeMapMe(e)} 
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className='text-center'>
                      <Col>
                        <Button type="button" disabled={isSubmitting} className="btn btn-modal-blue btn-set-WH-long" onClick={()=>mapMe()}>
                          <span>
                            {isSubmitting ? (
                              <Spinner size="sm" className='color-white'/>
                            ) : (
                              "Map Me!"
                            )}
                          </span>
                        </Button>
                      </Col>
                    </Row>
                  </div>
                : 
                  <Fragment>
                    <ProfileFormMapWrapper indivLat={indivLat} indivLong={indivLong} formData={formData} setFormData={setFormData}/>
                    <button type='button' className='btn btn-modal-blue my-1 float-left' disabled={ isSubmitting || !isSuccess1 } onClick={()=>setIsSuccess1(false)}>Back</button>
                  </Fragment>
                }
                
                
              </Fragment>
            :''}
            {!mapOptIn &&
              <div>
                <div className='text-bold'>
                  No problem! The site can still provide a personalized experience if you set your general location.
                </div>
                <div>
                  <button type='button' onClick={()=>geoLocateMe()} className='btn btn-modal-blue my-1' disabled={geoLocationSuccess}>{!geoLocationSuccess ? 'Set my general location':'Success! Click "Next"'}</button>
                </div>
                { geoLocationSuccess ? <div>{`Mapped to: (${(formData.indivLat*1).toFixed(3)},${(formData.indivLong*1).toFixed(3)})`}</div>: ''}
              </div>
            }
            <button type='button' className='btn btn-modal-blue my-1 float-right' disabled={ (mapOptIn && !indivLat && !indivLong) } onClick={()=>swapSection12()}>{(mapOptIn || geoLocationSuccess) ? "Next": "No Thanks"}</button>
          </Fragment>
        :''}
        
            
              
        { showSection2 ? (
            <div>
              <div className='d-flex flex-row-reverse'>
                <Button type="submit" disabled={ isSubmitting } className="btn btn-modal-blue btn-set-WH-long">
                  <span>
                    {isSubmitting ? (
                      <Spinner size="sm" className='color-white'/>
                    ) : (
                      "Skip for now"
                    )}
                  </span>
                </Button>
              </div>
              <Row form>
                <Col className='text-left'>
                  <h4 className='my-2'>Tell people a bit about you</h4>
                  <div className='text-italic my-1'>(This info will be displayed on your profile page)</div>
                </Col>
              </Row>
              <Row form>
                <Col className='text-left'>
                  <Label for='edPhil' className='text-left'>COVID Social Comfort Level</Label>
                  <Input type="select" 
                  name="covidSocialLevel" 
                  id="covidSocialLevel" 
                  value={covidSocialLevel}
                  onChange={e => onChange(e)}>
                    <option value="">Choose One...</option>
                    <option value="no">No Contact - virtual only</option>
                    <option value="low">Minimal Contact - comfortable in small groups</option>
                    <option value="medium">Medium Contact - Abiding by the laws, but seeking normalcy.</option>
                    <option value="high">Not taking COVID precautions</option>
                  </Input>
                </Col>
              </Row>

              <Row form>
                <Col md={9} className='text-left'>
                  <Label for='edPhil' className='text-left'>Educational Philiosophy</Label>
                  <Input type="select" 
                  name="edPhil" 
                  id="edPhil" 
                  value={edPhil}
                  onChange={e => onChange(e)}>
                    <option value="None">Educational Philosophy...</option>
                    <option value="Traditional">Traditional</option>
                    <option value="Classical Education">Classical Education</option>
                    <option value="Unit Study">Unit Study</option>
                    <option value="Leadership Education">Leadership Education</option>
                    <option value="Unschooling">Unschooling</option>
                    <option value="Montessori">Montessori</option>
                    <option value="Charlotte Mason">Charlotte Mason</option>
                    <option value="Knowledge-Based Schooling">Knowledge-Based Schooling</option>
                    <option value="Eclectic">Eclectic</option>
                    <option value="Independent">Independent</option>
                    <option value="Undecided">Undecided</option>
                  </Input>
                </Col>
                <Col md={3}>
                  <Label>Info</Label>
                  <div className='align-middle'>
                    <button
                      onClick={() => toggleModalEdPhil(!displayModalEdPhil)}
                      type='button'
                      className='basic-button'
                    >
                      <i className="fas fa-info-circle color-myschool-blue"/>
                    </button>
                  </div>
                </Col>
              </Row>
            
              <div className='form-group social-input text-left my-2'>
                <Label for='facebook'>Copy the link to your Facebook profile so people can connect!</Label>
                <div>
                  <Input
                    type='text'
                    placeholder='https://facebook.com/...'
                    name='facebook'
                    value={facebook}
                    onChange={e => onChange(e)}
                  />
                </div>
              </div>

              <div className='form-group text-left'>
                <Label for='bio'>Tell others a bit about you and your family (*Req'd to Host)</Label>
                <Input
                  type='textarea'
                  placeholder='A short bio of yourself, family, values, etc. Whatever you would like to know about others is something you should be willing to offer up about yourself.'
                  rows='5'
                  name='bio'
                  value={bio}
                  onChange={e => onChange(e)}
                />
              </div>

              <div className='form-group text-left'>
                <Label for='lookingFor'>What are you looking for to help improve your homeschooling journey?</Label>
                <Input
                  type='textarea'
                  rows='5'
                  name='lookingFor'
                  value={lookingFor}
                  onChange={e => onChange(e)}
                />
              </div>

              <div className='form-group text-left'>
                <Label for='shareWithOthers'>Why did you start homeschooling? Anything else you'd like to share?</Label>
                <Input
                  type='textarea'
                  rows='5'
                  name='shareWithOthers'
                  value={shareWithOthers}
                  onChange={e => onChange(e)}
                />
              </div>
              
              <div className='float-left'>
                <Button type="button" disabled={isSubmitting} className="btn btn-modal-blue btn-set-WH-long" onClick={()=>swapSection12()}>
                  Back
                </Button>
              </div>
              <div className='float-right'>
                <Button type="button" className="btn btn-modal-blue btn-set-WH-long" onClick={()=>swapSection23()}>
                  Next
                </Button>
              </div>
            </div>
          ):''
        }

        { showSection3 ?
          <div>
            <div className='form-group text-left'>
              <Label for='howDiscoveredMySchool'>How did you hear about MySchool?</Label>
              <Input
                type='textarea'
                rows='5'
                name='howDiscoveredMySchool'
                value={howDiscoveredMySchool}
                onChange={e => onChange(e)}
              />
            </div>

            <div className='float-right'>
              <Button type="submit" disabled={ isSubmitting } className="btn btn-modal-blue btn-set-WH-long">
                <span>
                  {isSubmitting ? (
                    <Spinner size="sm" className='color-white'/>
                  ) : (
                    "Submit"
                  )}
                </span>
              </Button>
            </div>
          </div>
          :''
        }

        </Form>
      </Container>

      <Modal isOpen={displayModalEdPhil} toggle={() => toggleModalEdPhil(!displayModalEdPhil)}>
        <ModalBody>
          <EducationalPhilosophies />
        </ModalBody>
      </Modal>

    </Fragment>
  );
};

CreateProfilePreGeolocate.propTypes = {
  createProfileV2: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  setAlert: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  profile: state.profile
});

export default connect(
  mapStateToProps,
  { createProfileV2, getCurrentProfile, setAlert },
)(CreateProfilePreGeolocate);
