import React, { useState, Fragment} from 'react';
import EventBriefInfo from '../EventBriefInfo';
import EventListItem from './EventListItem';
import { Card, Tooltip } from 'reactstrap';

const EventListWrapper = ({ events, setMapFlyTo, history }) => {

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  return ( 
      <div className='event-list'>
        <div className='blinker my-2 text-center'>
          <i className="fas fa-arrow-down color-myschool-red"/>Scroll over an item to center the map on that point.<i className="fas fa-arrow-down color-myschool-red"/>
        </div>
        { events && events.length < 1 ?
          <Card body inverse color="info" className='text-center mx-1'>
            Currently we don't have any events matchings your search parameters.  
            <button className='btn btn-myschool-yellow' 
              onClick={()=>history.push('/create-event')
              }>
              Try hosting your own!
            </button>
          </Card>
          : events && events.map(event => 
          <EventListItem key={event._id} event={event} setMapFlyTo={setMapFlyTo}/>
        )}
      </div>
    );
};


export default EventListWrapper;