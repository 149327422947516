import React, { Fragment, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import ReactGA from 'react-ga';

const WhyBookWithMySchool = ({ isAuthenticated }) => {

	const history = useHistory();
	
	const [ data, setData] = useState({
		venmoAgreement: "Unless directly given the option by Venmo, DO NOT USE VENMO TO TRANSACT WITH PEOPLE YOU DON’T PERSONALLY KNOW, ESPECIALLY IF THE TRANSACTION INVOLVES THE PURCHASE OR SALE OF A GOOD OR SERVICE... These transactions are potentially high risk, are not allowed under Venmo’s User Agreement..."
	}); 

	const [ withoutMySchool, setWithoutMySchool ] = useState([
		'Plan the event',
		'Send out invites to friends',
		'Reach out to new families through social media',
		'Respond to sign ups with meeting info',
		'Follow up with a link to pay',
		'Receive a notice from Venmo that you\'re in violation of the user agreement **',
		'Chase down registrants who don\'t pay',
		'Search for additional attendees last minute to fill unpaid slots',
		'Miss out on the trip because you\'re trying to collect money on site',
		'Promise yourself that you\'ll never host again because it was a nightmare'
	]);

	const [ withMySchool, setWithMySchool ] = useState([
		'Plan the event',
		'Allow your prepaid bookings to flow in',
		'Enjoy the trip!'
	]);

	const [ part1, showPart1 ] = useState(true);
	const [ part2, showPart2 ] = useState(false);
	const [ part3, showPart3 ] = useState(false);
	const [ part4, showPart4 ] = useState(false);

	useEffect(()=> {
		setTimeout(()=> {
			showPart1(false);
			showPart2(true);
			showPart3(false);
			showPart4(false);
		}, 6000);
		setTimeout(()=> {
			showPart1(false);
			showPart2(false);
			showPart3(true);
			showPart4(false);
		}, 9000);
		setTimeout(()=> {
			showPart1(false);
			showPart2(false);
			showPart3(false);
			showPart4(true);
		}, 14000);
	},[]);

	const seePart1 = () => {
		showPart1(true);
		showPart3(false);
	}

	const seePart3 = () => {
		showPart1(false);
		showPart3(true);
	}

	return (
		<Container>
			<div className='text-center'>
				<h2 className='my-4 color-myschool-blue'>Want to host a trip or class?</h2>
			</div>
				{ part4 &&
					<div className='text-center fadeIn my-5'>
						<div className='text-1-4rem color-myschool-blue my-3'>
							Which would you rather do?
						</div>
						<button type='button' onClick={()=>seePart1()} className='btn btn-myschool-blue mx-2'>
							Without MySchool
						</button>
						<button type='button' onClick={()=>seePart3()} className='btn btn-myschool-blue mx-2'>
							With MySchool
						</button>
					</div>
				}
				{ part1 &&
					<div className='my-5'>
						<div className='text-1-4rem color-myschool-blue'>
							You could do it{' '}<span className='color-danger text-bold'>WITHOUT</span>{' '}MySchool and...
						</div>
						<div className="text-1-1rem why-myschool-events-list my-2">
							{withoutMySchool.map((a,i)=> 
								<div key={i} className='my-2'>{a}</div>
							)}
						</div>
						<div className='text-07rem mt-3'>
							{`** As per Venmo's documenation: "${data.venmoAgreement}"`} 
						</div>
					</div>
				}
				{ part2 &&
					<div className='text-center fadeIn my-5'>
						<h2 className='color-myschool-blue my-3 text-bold'>
							or
						</h2>
					</div>
				}
				{ part3 &&
					<div className='my-5'>
						<div className='text-1-4rem color-myschool-blue'>
							You could do it{' '}<span className='text-bold'>WITH</span>{' '}MySchool and...
						</div>
						<div className="text-1-1rem why-myschool-events-list my-2">
							{withMySchool.map((a,i)=> 
								<div key={i} className='my-2'>{a}</div>
							)}
						</div>
						<div>
							<button type='button' className='basic-button text-underlined text-bold' onClick={()=>history.push('/event-options')}>
								Sign me up >>
							</button>
						</div>
					</div>
				}
				<div className='space' />
				<div className='space' />
				<div className='space' />
				<div className='space' />
				<div className='space' />
				<div className='space' />

		</Container>
	);
};

WhyBookWithMySchool.propTypes = {
  isAuthenticated: PropTypes.bool
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
});


export default connect(mapStateToProps, { })(WhyBookWithMySchool);
