import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Spinner from '../layout/Spinner';
import { Form, FormGroup, Label, Button, Input, Row, Col, Container } from 'reactstrap';


const CheckoutChaperoneEventRegistrationChild = ({ event, regChildFormData, onChildRegistrationChange, onChildRegistrationCheckChange, validateChildForm, toggleChildRegistrationForm }) => {

  const { childName, childAge, parentalConsent } = regChildFormData;

  return event === null ? (
    <Spinner />
  ) : (
    <Fragment>
      <Form className='form msstd-form'>
          <Container>
            <h4 className='color-myschool-blue'>Child Registration</h4>
            <Row>
              <Col>
                <Label>Child's Name</Label>
                <Input type="text" 
                  name="childName" 
                  id="childName" 
                  placeholder="Child's Name"
                  value={childName}
                  onChange={e => onChildRegistrationChange(e)} 
                  required/>
              </Col>
            </Row>
            <Row>
              <Col>
                <Label>Age</Label>
                <Input type="number" 
                  name="childAge" 
                  id="childAge"
                  placeholder="Age"
                  value={childAge}
                  onChange={e => onChildRegistrationChange(e)}
                  required />
              </Col>
            </Row>

            <Row>
              <Col>
                <FormGroup check inline>
                  <Input type="checkbox" 
                    name="parentalConsent" 
                    id="parentalConsent"
                    checked={parentalConsent}
                    onChange={e => onChildRegistrationCheckChange(e)}
                    required />{' '}
                  <Label check className={parentalConsent !== true ? 'text-danger':''}>You are this child's parent or legal guardian (required)</Label>
                </FormGroup>
              </Col>
            </Row>
         
            { parentalConsent !== true ?
              <div>
                <Button type="button" className='btn-myschool-blue' disabled>Submit</Button>
                <Button type="button" className='btn-light' onClick={()=>toggleChildRegistrationForm(false)}>Cancel</Button>
                <div className='text-danger'>You must confirm you are the legal guardian before moving on to payment</div>
              </div>
              :
              <div>
                <Button type="button" className='btn-myschool-blue' onClick={()=>validateChildForm()}>Submit</Button>
                <Button type="button" className='btn-light' onClick={()=>toggleChildRegistrationForm(false)}>Cancel</Button>
              </div>
            }
          </Container>
        </Form>
    </Fragment>
  );
};

CheckoutChaperoneEventRegistrationChild.propTypes = {
  event: PropTypes.object.isRequired
};


export default CheckoutChaperoneEventRegistrationChild;


