import {
  GET_ONLINE_EVENTS,
  GET_PAST_ONLINE_EVENTS,
  GET_ONLINE_EVENT,
  GET_MY_ONLINE_EVENTS,
  GET_ONLINE_EVENTS_BY_HOSTID,
  UPDATE_ONLINE_EVENT,
  ONLINE_EVENT_ERROR,
  BOOK_ONLINE_EVENT,
  BOOK_ONLINE_EVENT_ERROR,
  UPDATE_ONLINE_EVENT_BOOKING_COUNT,
  GET_STATUS_CODE
} from '../actions/types';

const initialState = {
  onlineEvents: [],
  onlinePastEvents: [],
  myOnlineEvents: [],
  onlineEvent: null,
  onlineEventloading: true,
  error: {},
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_STATUS_CODE:
      return {
        ...state,
        statusCode: payload
      };
    case GET_ONLINE_EVENTS:
    case GET_ONLINE_EVENTS_BY_HOSTID:
      return {
        ...state,
        onlineEvents: payload,
        onlineEventloading: false
      };
    case GET_MY_ONLINE_EVENTS:
      return {
        ...state,
        myOnlineEvents: payload,
        onlineEventloading: false
      }; 
    case GET_PAST_ONLINE_EVENTS:
      return {
        ...state,
        onlinePastEvents: payload,
        onlineEventloading: false
      }
    case GET_ONLINE_EVENT:
    case UPDATE_ONLINE_EVENT:
      return {
        ...state,
        onlineEvent: payload,
        onlineEventloading: false
      };
    case UPDATE_ONLINE_EVENT_BOOKING_COUNT:
      return {
        ...state,
        onlineEvent: {...state.onlineEvent, userBookings: payload},
        loading: false
      }; 
    case ONLINE_EVENT_ERROR:
    case BOOK_ONLINE_EVENT_ERROR:
      return {
        ...state,
        error: payload,
        onlineEventloading: false
      };
    default:
      return state;
  }
}
