import React, { Fragment, useState, useEffect } from "react";
import { useHistory, Redirect, useParams } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { Container, FormGroup, Input, Label, Modal, ModalHeader, ModalBody, Row, Col } from 'reactstrap';
import api from '../../../utils/api';
import SpinnerComponent from '../../layout/Spinner';
import { setAlert } from '../../../actions/alert';
import { getAllChild } from '../../../actions/child';
import CreateChild from '../../child/CreateChild';
import AuthModals from '../../auth/AuthModals'; 
import checkBlue from '../../../images/check-blue_640-640.svg';
import AddChild from '../../child/AddChild';
import ImageContainer from '../../layout/ImageContainer';
import QRScan from '../../../images/QR_scan_to_portfolio.gif';
import ImageUpload from '../../../utils/ImageUpload';
import CalendarSelectDay from '../../form-tools/CalendarSelectDay';
import dayjs from 'dayjs';

const ActivityToPortfolio = ({ auth: {loading, user, isAuthenticated}, setAlert, children }) => {

  const history = useHistory();
  const params = useParams();

  const [ showLearnMore, toggleShowLearnMore ] = useState(false);
  const [ showDefaultOverride, toggleDefaultOverride ] = useState(false);
  const openRegisterModal = () => {
    toggleShowLearnMore(false);
    toggleRegisterModal(true);
  }

  //Must login/register
  const [ loginModal, toggleLoginModal ] = useState(false);
  const [ registerModal, toggleRegisterModal ] = useState(false);

  const [ activitySuccess, setActivitySuccess ] = useState(false);
  const [ activityFailure, setActivityFailure] = useState(false);

  const [activityInfo, setActivityInfo] = useState({});
  const [activitySubmitData, setActivitySubmitData] = useState({
    dateOverride: Date.now()
  });
  const { dateOverride } = activitySubmitData; 
  // const [childInfo, setChildInfo] = useState([]);
  const [ selChild, setSelChild ] = useState([]);
  const [ childrenAdded, setChildrenAdded] = useState({status:false});

  const [file, setFile] = useState('');
  const [fileSize, setFileSize] = useState(0);
  const [storedThumbnail, setStoredThumbnail] = useState('');

  const [ isSubmitting, setIsSubmitting ] = useState(false);

  const checkActivity = async () => {
    setActivitySuccess(false);
    setActivityFailure(false);
    try {
      // console.log(params.activityId);
      const activityLookup = await api.get(`/v1/portfolio/myschoolplus/activity/${params.activityId}`);
      setActivityInfo(activityLookup.data.data);
      setActivitySubmitData(activityLookup.data.data.defaults);
      setActivitySuccess(true);
    } catch (err) {
      console.log(err);
      setAlert('Error.  Please try again later.', 'danger');
      setActivityFailure(true);
    } 
  };

  useEffect(()=> { 
    checkActivity();
  }, []);

  useEffect(()=> { 
    if (isAuthenticated) {
      getAllChild();
    }
  }, [isAuthenticated]);

  const onChange =(e)=> {
    // check if the check box is checked or unchecked
    if (selChild.includes(e.target.name) === false) {
      setSelChild([...selChild, e.target.name]);
    } else {
      const newArray = selChild.filter(el=> el !== e.target.name);
      setSelChild(newArray);
    }
  };

  const [ textAreaCount, setTextAreaCount ] = useState(0);

  const onTextAreaChange = e => {
    setActivitySubmitData({ ...activitySubmitData, [e.target.name]: e.target.value });
    setTextAreaCount(e.target.value.length);
  };

  const [showCalendar, toggleShowCalendar] = useState(false);

  const updateDate = (newDate, newTime) => {
    const hh = newTime.split(":")[0] * 1;
    const mm = newTime.split(":")[1]  * 1;
    const newDateOverride = new Date(dayjs(newDate).hour(hh).minute(mm));
    setActivitySubmitData({ ...activitySubmitData, dateOverride: newDateOverride});
  };

  const [childNames, setChildNames] = useState([]);
  const submitEntries = async () => {
    setIsSubmitting(true);
    const data = {
      children: selChild,
    };
    if (activitySubmitData.duration) {data.duration = activitySubmitData.duration*1} else {data.duration = 30};
    if (activitySubmitData.subjects) {data.subjects = activitySubmitData.subjects};
    if (activitySubmitData.description) {data.description = activitySubmitData.description};
    if (activitySubmitData.context) {data.context = activitySubmitData.context};
    if (activitySubmitData.dateOverride) {data.dateOverride = activitySubmitData.dateOverride};

    const allData1 = { ...data, image: file };
    const allData = Object.keys(allData1).reduce((f, k) => {
      f.append(k, allData1[k]);
      return f;
    }, new FormData());

    // console.log(allData);
    const configMulti = {headers: {'Content-Type': 'multipart/form-data'}};
    const addedChildren = await api.post('/v1/portfolio/myschoolplus/entry', allData, configMulti);
    
    setChildrenAdded({status:true, count: addedChildren.data.count});
    setIsSubmitting(false);
  };
  
  const [showAddChild, setShowAddChild] = useState(false);
  const {duration, description, subjects, context} = activitySubmitData;
  const updateActivitySubmitData = (e) => {
    setActivitySubmitData({ ...activitySubmitData, [e.target.name]: e.target.value });
  };

  const [ subjectOptions, setSubjectOptions ] = useState([
    "math",
    "english",
    "science",
    "social studies",
    "art",
    "music",
    "physical education",
    "health",
    "history",
    "technology"
    ]);
  const updateSubjects = e => {
    e.preventDefault();
    // check if the check box is checked or unchecked
    if (subjects.includes(e.target.name) === false) {
      setActivitySubmitData({...activitySubmitData, subjects: [...subjects, e.target.name]});
      // console.log({subjects: [...subjects, e.target.name]});
    } else {
      const newArray = subjects.filter(el=> el !== e.target.name);
      setActivitySubmitData({...activitySubmitData, subjects: newArray});
      // console.log(newArray);
    }
  }

  // //Redirect if logged in and user loaded
  // if(isAuthenticated && user && user._id) {
  //   return <Redirect to='/dashboard' />;
  // }

  return (
    <div>
    {
      isAuthenticated && !activitySuccess && !activityFailure ? (
        <SpinnerComponent />
      ) : isAuthenticated && activitySuccess && !childrenAdded.status ? (
        <Container className='text-center'>
          <h2>
            {activityInfo && activityInfo.name ? `Good job completing a session of ${activityInfo.name}!`:`Good job!`}
          </h2>
          <div className='msstd-form-primary-border my-3'>
            <h4 className='mb-2'>Who are you logging this for?</h4>
            {children && children.length > 0 ? 
              <div> 
                <div><button type='button' onClick={()=>setShowAddChild(true)} className='btn btn-myschool-blue-border'>+ Add Child</button></div>
                <div>
                  { children.map(c=> 
                      <Label check key={c._id} className='child-input'>
                        <Input type="checkbox" name={c._id} checked={selChild.includes(c._id)} onChange={e => onChange(e)}/>
                        {`${c.name}`}
                      </Label>
                    )
                  }
                </div>
                <div>
                  <button type='button' onClick={()=>toggleDefaultOverride(true)} className='btn btn-myschool-blue my-2'>See/Update default settings</button>
                </div>
                <button 
                  type='button' 
                  onClick={()=>submitEntries()} 
                  className={isSubmitting ? 'btn btn-myschool-blue my-2 blinker': 'btn btn-myschool-blue my-2'} 
                  disabled={selChild.length < 1 || isSubmitting }>
                  { isSubmitting ? `Submitting...`:`Submit`}
                </button>
                {selChild < 1 ? 
                  <div className='color-danger'>
                    Please select a child
                  </div>
                  :''}
              </div>
              : <div>
                  <div>
                    <div>
                    <CreateChild showChildTable={false}/>
                  </div>
                  </div>
                  <div>
                    No child info available
                  </div>
                </div>
            }
            <Modal isOpen={showAddChild} centered toggle={() => setShowAddChild(!showAddChild)}>
              <ModalHeader className='content-justify-center'>
                <div className='float-right'>
                  <button className="basic-button" onClick={() => setShowAddChild(!showAddChild)}>
                  <i className='fa fa-times color-light-grey'/>
                  </button>
                </div>
                Add a child
              </ModalHeader>
              <ModalBody className='text-center'>
                <AddChild />
              </ModalBody>
              <button className='btn btn-modal-blue content-justify-right' onClick={()=>setShowAddChild(false)}>Skip / Done</button>
            </Modal>
          </div>
        </Container>
      )
      : isAuthenticated && activitySuccess && childrenAdded.status ? (
          <div className='text-center'>
            <div>
              <img src={checkBlue} width='70px' alt='check step 1' className='fadeIn-slow'/>
            </div>
            <h4>
              { childrenAdded.count > 1 ? 
                `Success! Portfolio entries added for ${childrenAdded.count} children.`
                : `Success! Portfolio entry added for ${childrenAdded.count} child.`
              }
            </h4>
            <div className='my-3'>
              <button type="button" onClick={()=>history.push("/dashboard")} className='basic-button'>
              Go to your dashboard to visit the portfolios >>
              </button>
            </div>
            <div>(You may now close this tab.)</div>
          </div>
        )
      : isAuthenticated && activityFailure ? (
        <Container className='text-center'>
          <h2>
            Sorry, but we can't find that activity!
          </h2>
          <div> 
            <button type='button' onClick={()=>checkActivity()} className='btn btn-myschool-red'>Try Again</button>
          </div>
        </Container>
      ) : !isAuthenticated ? (
        <Container  className='text-center'>
          <h2>
            {activityInfo && activityInfo.name ? `Welcome to ${activityInfo.name}!`:`Welcome!`}
          </h2>
          <div className="mt-4 mb-2">
            <button className='btn btn-myschool-blue' type='button' onClick={()=>toggleLoginModal(true)}>Login or sign up to save your visit!</button>
          </div>
          <div className="mb-4">
            <button className='btn btn-myschool-red' type='button' onClick={()=>toggleShowLearnMore(true)}>Learn more</button>
          </div>
        </Container>
      ) : (
        <Container className='text-center'>
          <h2>Error: Please try again later.</h2>
        </Container>
      )
    }
      <div>
        <AuthModals loginModal={loginModal} toggleLoginModal={toggleLoginModal} registerModal={registerModal} toggleRegisterModal={toggleRegisterModal} />
      </div>

      <Modal centered isOpen={showLearnMore} toggle={() => toggleShowLearnMore(!showLearnMore)}>
        <ModalBody>
          <div className='d-flex justify-content-end'>
            <button className="basic-button" onClick={() => toggleShowLearnMore(false)}>
                <i className='fa fa-times color-light-grey'/>
            </button>
          </div>
          <div className="text-center">
            <div className="text-bold text-1-1rem mb-3">
                Keep learning with your kids all summer long!
            </div>
            <div>
              <div>
                <ImageContainer
                  src={QRScan}
                  thumb={QRScan}
                  className='d-inline' 
                  alt='map snippet with personal profile shown'
                />
              </div>
              <div className="my-1">Scan a MySchool QR code to track your visits!</div>
              <div>
                <button className='btn btn-modal-blue' type='button' onClick={()=>openRegisterModal()}>Log this trip!</button>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>

      <Modal centered isOpen={showDefaultOverride} toggle={() => toggleDefaultOverride(!showDefaultOverride)}>
        <ModalBody>
          {showCalendar ?
            <div style={{margin:"auto"}}>
              <CalendarSelectDay 
                date={dateOverride} 
                updateDate={updateDate} 
                selectTime={true} 
                toggleShowCalendar={toggleShowCalendar}
                />
            </div>
            :
            <div>
              <FormGroup>
                <Label for="duration">Duration</Label>
                <Input 
                  type="number"
                  name="duration"
                  value={duration}
                  onChange={e=>updateActivitySubmitData(e)}
                  />
              </FormGroup>
              <FormGroup>
              <Label for="description">Description</Label>
                  <div><small><strong>{`Character Count: ${textAreaCount} of 500`}</strong></small></div>
                  <Input type="textarea" maxLength="500" name="description" id="description" value={description} onChange={(e)=>onTextAreaChange(e)}/>
              </FormGroup>
              <FormGroup>
                <Label for="context">Reference Website</Label>
                <Input 
                  type="text"
                  name="context"
                  value={context}
                  onChange={e=>updateActivitySubmitData(e)}
                  />
              </FormGroup>
              <FormGroup>
                <Label for="subjects">Subject(s)</Label>
                <div>
                  {subjectOptions.map((sub)=>{
                      return (
                        <button 
                          key={`s-${sub}`}
                          name={sub}
                          onClick={e=>updateSubjects(e)}
                          // className="btn-modal-red mx-1"
                          className={subjects && subjects.includes(`${sub}`) ? "btn btn-modal-blue ":"btn btn-modal-blue-border"}
                          >
                          {sub}
                        </button>
                        )
                      }
                    )
                  }
                </div>
              </FormGroup>

              <Row form>
                <Col md={6}>
                  <FormGroup>
                    <Label for="dateOverride">Date</Label>
                    <div>
                      <button className="btn btn-modal-blue-border" onClick={()=>toggleShowCalendar(!showCalendar)}>{dayjs(dateOverride).format("MMM DD, YYYY")}</button>
                    </div>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="timeOverride">Time</Label>
                    <div>
                      <button className="btn btn-modal-blue-border" onClick={()=>toggleShowCalendar(!showCalendar)}>{dayjs(dateOverride).format("h:mm a")}</button>
                    </div>
                  </FormGroup>
                </Col>
              </Row>

              <div>
                <ImageUpload 
                  file={file} 
                  setFile={setFile} 
                  fileSize={fileSize} 
                  setFileSize={setFileSize} 
                  storeThumb={true} 
                  storedThumbnail={storedThumbnail} 
                  setStoredThumbnail={setStoredThumbnail}
                  showFileSize={true}
                  maxFileSize={5000000}
                  isProfileImage={false}/>
              </div>
            
              <div className='text-center'>
                <button 
                  type="button"
                  onClick={()=>toggleDefaultOverride(false)}
                  className="btn btn-modal-blue"
                  >
                  Done
                </button>
              </div>
            </div>
          }
        </ModalBody>
      </Modal>
    </div>
  )
};

ActivityToPortfolio.propTypes = {
  auth: PropTypes.object.isRequired,
  setAlert: PropTypes.func.isRequired,
  getAllChild: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  children: state.child.children,
});


export default connect(mapStateToProps, { setAlert, getAllChild })(ActivityToPortfolio);
