import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Spinner from '../layout/Spinner';
import { Form, FormGroup, Label, Button, Input, Row, Col, Container } from 'reactstrap';


const CheckoutDropOffEventRegistrationChild = ({ event, regFormData, onChildRegistrationChange, onChildRegistrationCheckChange, validateChildForm, toggleChildRegistrationForm }) => {

  const { childName, childAge, parentalConsent, parentName, regEmail, phone } = regFormData;

  return event === null ? (
    <Spinner />
  ) : (
    <Fragment>
      <Form className='form msstd-form'>
          <Container>
            <h4 className='color-myschool-blue'>Child Registration</h4>
            <Row>
              <Col>
                <Label>Child's Name</Label>
                <Input type="text" 
                  name="childName" 
                  id="childName" 
                  placeholder="Child's Name"
                  value={childName}
                  onChange={e => onChildRegistrationChange(e)} 
                  required/>
              </Col>
            </Row>
            <Row>
              <Col>
                <Label>Age</Label>
                <Input type="number" 
                  name="childAge" 
                  id="childAge"
                  value={childAge}
                  placeholder="Age"
                  onChange={e => onChildRegistrationChange(e)}
                  required />
              </Col>
            </Row>

            <Row>
              <Col>
                  <Label>Parent/Guardian Full Name</Label>
                  <Input 
                  type="text" 
                  name="parentName" 
                  id="parentName" 
                  placeholder="First and Last"
                  value={parentName}
                  onChange={e => onChildRegistrationChange(e)} 
                  required/> 
              </Col>
            </Row>

            <Row>
              <Col md={12} lg={6}>
                  <Label>Email</Label>
                  <Input 
                  type="email" 
                  name="regEmail" 
                  id="regEmail" 
                  placeholder="Email Address"
                  value={regEmail}
                  onChange={e => onChildRegistrationChange(e)} 
                  required/> 
              </Col>
              <Col md={12} lg={6}>
                  <Label>Phone</Label>
                  <Input 
                  type="tel" 
                  name="phone" 
                  id="phone" 
                  placeholder="000-000-0000"
                  value={phone}
                  onChange={e => onChildRegistrationChange(e)} 
                  required/>  
              </Col>
            </Row>

            <Row>
              <Col>
                <FormGroup check inline>
                  <Input type="checkbox" 
                    name="parentalConsent" 
                    id="parentalConsent"
                    checked={parentalConsent}
                    onChange={e => onChildRegistrationCheckChange(e)}
                    required />{' '}
                  <Label check>You are this child's parent or legal guardian (required)</Label>
                </FormGroup>
              </Col>
            </Row>
         
            { parentalConsent !== true ?
              <div>
                <Button type="button" disabled>Submit</Button>
                <Button type="button" className='btn-light' onClick={()=>toggleChildRegistrationForm(false)}>Cancel</Button>
                <div className='text-danger'>{'You must confirm you are the legal guardian before moving on to payment'}</div>
              </div>
              :
              <div>
                <Button type="button" className='btn-myschool-blue' onClick={()=>validateChildForm()}>Submit</Button>
                <Button type="button" className='btn-light' onClick={()=>toggleChildRegistrationForm(false)}>Cancel</Button>
              </div>
            }
          </Container>
        </Form>
    </Fragment>
  );
};

CheckoutDropOffEventRegistrationChild.propTypes = {
  event: PropTypes.object.isRequired
};


export default CheckoutDropOffEventRegistrationChild;