module.exports = function formatTimeFromDB(string){ 
    const hh = string.split(":")[0] * 1;
    const mm = string.split(":")[1]  * 1;

    let ampm;
    if (string.split(":").length > 2) {
        ampm = string.split(":")[2];
    } else if (hh < 12) {
        ampm = 'AM';
    } else {
        ampm = 'PM';
    }

    let hhString;
    if (hh === 12 && ampm === "AM") {
        hhString = "00";
    } else if (ampm === "PM" && hh < 12 ) {
        hhString = (hh + 12).toString();
    } else if ( hh < 10  ) {
        hhString = "0" + hh.toString();
    } else {
        hhString = hh.toString();
    }

    let mmString;
    if (mm < 10) {
        mmString = ("0"+mm.toString());
    } else {
        mmString = mm.toString();
    }

    const timeString = hhString + ":" + mmString; 
    // console.log(hhString);
    return timeString; 
};