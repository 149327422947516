import React, { useState, Fragment } from 'react';
import { Container, Col, Row, Button, Form, FormGroup, Input, Modal, ModalHeader, ModalBody, Spinner } from 'reactstrap';
import axios from 'axios';

const WhataboutaTool = () => {

	const [displayModalEmailer, toggleModalEmailer] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [isSuccess, setIsSuccess] = useState(false);

	const [formData, setFormData] = useState({
		responseEmail: '',
		message: ''
	});

	const { responseEmail, message } = formData;

	const onChange = e =>
	  setFormData({ ...formData, [e.target.name]: e.target.value });

	const onSubmit = async e => {
		e.preventDefault();
		setIsSubmitting(true);
		try {
		    const config = { headers: { 'Content-Type': 'application/json' } };
		    const res = await axios.post('api/company/whatabouta', formData, config);
		    if (res.status === 200) {
		    	setIsSubmitting(false);
		    	setIsSuccess(true);
		    	setTimeout(()=> {
					toggleModalEmailer(false);
			    	setIsSuccess(false);
			    	setFormData({
						responseEmail: '',
						message: ''
					});
				}, 3000);
		    }
		} catch (err) {
			setIsSubmitting(false);
			setIsSuccess(false);
		}
	};

    return(
    	<Fragment>
	        <Container className='text-center'>
	        	<div className='space'/>
	            <div className="section-heading">
                    <h2>What's a "What-a-Bouta" tool?</h2>
                </div>
	        	<div className='my-3'>If you were checking out the site and said, "hey, what about a tool to do ...?"  </div>
	        	<div className='my-3'>Well, that's a <strong>"What-a-Bouta"</strong> tool.  </div>
	        	<div className='my-3'>We are building a homeschooling toolbox for our families and yours, and the more suggestions and feedback from our community, the better tools we can build. </div>
	            <div className='space'/>
	            <div>
	            	<button onClick={()=>toggleModalEmailer(!displayModalEmailer)} className="btn btn-myschool-blue">
		            	<i className="far fa-envelope fa-3x"/>
		            	<div className='my-1'>Message us!</div>
	            	</button>
	            </div>
	        </Container>

	        <Modal isOpen={displayModalEmailer} centered toggle={() => toggleModalEmailer(!displayModalEmailer)}>
	        <ModalHeader> Send us a Suggestion! </ModalHeader>
	        <ModalBody style={{height: "350px"}}>
		    {/*alertArray.length > 0 ? 
		    	alertArray.map((alert, index) =>
			        <Alert color="dark" key={index} className='z-index-1000'>
				        {alert.msg}
			        </Alert>
		        )
			    : ''
		    */}
	        { !isSuccess ?
	            <Form onSubmit={e => onSubmit(e)} className="msstd-form">
					<Row form>
						<Col>
						  <FormGroup>
						    <Input type="email" 
						    name="responseEmail" 
						    id="responseEmail" 
						    placeholder="Enter Your Email Address" 
						    value={responseEmail}
						    onChange={e => onChange(e)} 
						    required/>
						  </FormGroup>
						  <FormGroup>
						    <Input type='textarea'
						    	placeholder='Send a message to the provider.'
						    	rows='5'
						    	name='message'
						    	value={message}
						    	onChange={e => onChange(e)}
						    	required
						    />
						  </FormGroup>
						</Col>
					</Row>
	              
		            <Button type="submit" disabled={isSubmitting || isSuccess} className="btn btn-modal-blue btn-set-WH-long">
		              	<span>
							{isSubmitting ? (
							  <Spinner size="sm" className='color-white'/>
							) : (
							  "Send Email"
							)}
						</span>
					</Button>
	            </Form>
	        : 
	        <div className='text-center' style={{paddingTop: "125px"}}>
	        	<h2>Success!</h2>
		        <h4>Email Sent!</h4>
	        </div>
	        }

	        </ModalBody>
	      </Modal>
      </Fragment>
    );
}

export default WhataboutaTool;


