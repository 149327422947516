import React, { useState, Fragment, useEffect } from 'react';
import { Container, Col, Row, Button, Form, FormGroup, Input, Spinner } from 'reactstrap';
import { Redirect, Link, useHistory, useParams } from "react-router-dom";
import { connect } from 'react-redux';
import { setAlert } from '../../actions/alert';
import { passwordReset } from '../../actions/auth';
import PropTypes from 'prop-types';
import api from '../../utils/api';

const ResetPassword = ({ setAlert, passwordReset, isAuthenticated }) => {
	
  const history = useHistory();
  const params = useParams();

	const [formData, setFormData] = useState({
		password: '',
    password2: ''
	});

  const { password, password2 } = formData;
	 
	const onChange = e =>
	    setFormData({ ...formData, [e.target.name]: e.target.value });

  const [ isSubmitting, setIsSubmitting ] = useState(false);

  let timeoutID;
	const onSubmit = async e => {
		e.preventDefault();
		if (password !== password2) {
      setAlert('Passwords do not match', 'danger');
    } else {

      try {
        const body = JSON.stringify({ password });
        const res = await api.post(`/users/reset/${params.token}`, body);
        setAlert('Password Reset! Please go log in.', 'success');
        setIsSubmitting(false);
      } catch (err) {
        if (err && err.response && err.response.data && err.response.data.errors) {
          const errors = err.response.data.errors;
          errors.forEach(error => setAlert(error.msg, 'danger'));
        } else {
          setAlert('Error. Please try again. Contact us if the problem persists.', 'danger')
        }
        setIsSubmitting(false);
      }      
      setFormData({
        password: '',
        password2: ''
      });      
    }
	};

  
  //Redirect if logged in & clear timeout on redirect
  useEffect(()=> {
    if(isAuthenticated) {
      history.push('/');
    }
    return () => clearTimeout(timeoutID);
  },[isAuthenticated]);

    return (
      <Fragment>
        <Container>
          <Form onSubmit={e => onSubmit(e)} className="msstd-form">
            <h4> Enter a new password</h4>
            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Input type="password" 
                  name="password" 
                  id="password" 
                  placeholder="Password"
                  value={password}
                  onChange={e => onChange(e)} />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Input type="password" 
                  name="password2" 
                  id="password2" 
                  placeholder="Confirm Password"
                  value={password2}
                  onChange={e => onChange(e)} />
                </FormGroup>
              </Col>
            </Row>
            <button
              disabled={isSubmitting}
              type="submit"
              className="btn btn-modal-blue btn-set-WH-long"
            >
              <span>
                {isSubmitting ? (
                  <Spinner size="sm" className='color-white'/>
                ) : (
                  "Reset Password"
                )}
              </span>
            </button>
          </Form>
          <div>
          	Nevermind, I remember! <Link to='/login' className="text-bold text-underline">Log In</Link>
          </div>
        </Container>
      </Fragment>
    );
};

ResetPassword.propTypes = {
  setAlert: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
  passwordReset: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, { setAlert, passwordReset })(ResetPassword);
