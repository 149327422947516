import {
  GET_LESSON,
  GET_LESSONS,
  UPDATE_FAVORITES,
  LESSON_ERROR
} from '../actions/types';

const initialState = {
  lesson: null,
  lessons: [],
  lessonLoading: true,
  error: {}
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_LESSON:
      return {
        ...state,
        lesson: payload,
        lessonLoading: false
      };
    case GET_LESSONS:
      return {
        ...state,
        lessons: payload,
        lessonLoading: false
      };
    case LESSON_ERROR:
      return {
        ...state,
        error: payload,
        lessonLoading: false
      };
    default:
      return state;
  }
}
