import React from 'react';

const AnimatedXMark = ({wid}) => {
	return (
		<div style={{width: wid}}>
			<svg className="xmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
				<circle className="xmark__circle" cx="26" cy="26" r="25" fill="none"/>
				<path className="xmark_x" fill="none" d="M 15 15 L 35 35 M 35 15 L 15 35"/>
			</svg>
		</div>
	)

};
AnimatedXMark.defaultProps = {
	wid: '50px'
};

export default AnimatedXMark;
