import React, { Fragment, useState } from 'react';
import { Tooltip } from 'reactstrap';

const EventNoRegTableItem = ({ booking, index }) => {

  const {_id, name, email} = booking;  

  const [tooltipMailOpen, setTooltipMailOpen] = useState(false);
  const toggleMail = () => setTooltipMailOpen(!tooltipMailOpen);

  return (
    <Fragment>
      <tr>
        <td>{index*1 + 1}</td>
        <td>{name && name}</td>
        <td>{email && email}</td>
        <th>---</th>
        <th>---</th>
        <th>---</th>
        <th>---</th>
      </tr>
    </Fragment>
  );
};

export default EventNoRegTableItem;