import React, { useState } from 'react';
import { Container} from 'reactstrap';
import museumGroup from '../../../images/museum_kids_group.jpg';
import ImageContainer from '../../layout/ImageContainer';

const PartnerWhyJoin = () => {

	const [whyJoin, setWhyJoin] = useState([
		{
			id: 1,
			heading: 'Reach new families.',
			text: 'Bring in new visitors!',
		},
		{
			id: 2,
			heading: 'Don\'t advertise. Demonstrate your value.',
			text: 'You have a unique mix of resource materials and knowledge that nobody else has. Show families what you can offer their kids, and you\'ll find engaged, curious visitors, not just passive observers.',
		},
		// {
		// 	id: 3,
		// 	heading: 'Access free analytics tools.',
		// 	text: 'Track which programs and educational resources get the most views so you can focus your efforts on those programs, and see how new programs fair with families.',
		// },
		{
			id: 4,
			heading: 'Get in early, and guide development.',
			text: 'This program launches in 2022, so getting in now means that we can tailor the product to your specific needs, strengths, and strategic goals.',
		},
		{
			id: 5,
			heading: 'Priority search results on the Homeschool Map.',
			text: 'Our users can quickly filter local results to find partners.',
			link: '/homeschoolmap'
		},
		{
			id: 6,
			heading: 'It\'s FREE!',
			text: 'Yep. You don\'t pay a thing to participate.',
		},
		// {
		// 	id: 7,
		// 	heading: 'What\'s holding you back?',
		// 	text: 'It\'s FREE to join. You\'ve likely already developed everything you need to get started. We\'ll get you set up. Now is the time to engage with our fast growing community of engaged families.',
		// },
	]);

  return(
		<Container>
			<div className='text-center'>
		      	<h4 className='text-color-blue text-2rem mb-4'>
		      	Why Join?
		      	</h4>
	      	</div>
	        <div className="row justify-content-around align-items-center">
		      	<div className="col-12 col-lg-7 order-1">
	                <div className="service-text pt-3 pt-lg-0">
	                    {whyJoin.map((item,i)=> {
												return (
													<div key={item.id} className='text-indent-paragraph my-2 text-color-blue'>
															<i className="fas fa-star color-gold"/>{' '}<span className='text-bold'>{item.heading}</span>{` ${item.text}`}{item.link? <button type='button' onClick={()=>history.push(`${item.link}`)} className='basic-button text-bold text-09rem ml-2' >Go see it >></button>:''}
													</div>
												)
											})}
	                </div>
	            </div>
	            <div className="col-12 col-lg-5 order-2">
	                <div className="service-thumb mx-auto">
	                    <ImageContainer
	                      src={museumGroup}
	                      thumb={museumGroup}
	                      className='d-inline d-lg-none' 
	                      alt='kids at a museum'
	                    />
	                    <ImageContainer
	                      src={museumGroup}
	                      thumb={museumGroup}
	                      className='d-none d-lg-inline' 
	                      alt='kids at a museum'
	                    />
	                </div>
	            </div>
	        </div>
		</Container>
  );
};

export default PartnerWhyJoin;