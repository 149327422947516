import React, { useState } from 'react';
import { Container} from 'reactstrap';
import museumGroup from '../../../images/museum_kids_group.jpg';
import ImageContainer from '../../layout/ImageContainer';

const SponsorWhyJoin = () => {

	const [whyJoin, setWhyJoin] = useState([
		{
			id: 1,
			heading: 'Reach new families.',
			text: '',
		},
		{
			id: 2,
			heading: 'Sponsor families locally, statewide, or nationwide.',
			text: 'Your sponsorships target families ONLY in the areas you operate!',
		},
		{
			id: 3,
			heading: 'Extend your advertising beyond the sponsored members.',
			text: "Homeschoolers are ALWAYS sharing learning opportunities by word of mouth. Get your organization's name on the tongues of local homeschoolers, and they'll spread the word about you!",
		},
		{
			id: 4,
			heading: 'Support education for local families.',
			text: 'Your services already support your community, and now your advertising budget can too!',
		},
		// {
		// 	id: 5,
		// 	heading: 'Priority search results on the Homeschool Map.',
		// 	text: 'Our users can quickly filter local results to find partners.',
		// 	link: '/homeschoolmap'
		// },
		// {
		// 	id: 6,
		// 	heading: 'What\'s holding you back?',
		// 	text: 'It\'s FREE to join. You\'ve likely already developed everything you need to get started. We\'ll get you set up. Now is the time to engage with our fast growing community of engaged families.',
		// },
	]);

  return(
		<Container>
			<div className='text-center'>
		      	<h4 className='text-color-blue text-2rem mb-4'>
		      	Why join?
		      	</h4>
	      	</div>
	        <div className="row justify-content-around align-items-center">
		      	<div className="col-12 col-lg-7 order-1">
	                <div className="service-text pt-3 pt-lg-0">
	                    {whyJoin.map((item,i)=> {
							return (
								<div key={item.id} className='text-indent-paragraph my-2 text-color-blue'>
										<i className="fas fa-star color-gold"/>{' '}<span className='text-bold'>{item.heading}</span>{` ${item.text}`}{item.link? <button type='button' onClick={()=>history.push(`${item.link}`)} className='basic-button text-bold text-09rem ml-2' >Go see it >></button>:''}
								</div>
							)
						})}
	                </div>
	            </div>
	            <div className="col-12 col-lg-5 order-2">
	                <div className="service-thumb mx-auto">
	                    <ImageContainer
	                      src={museumGroup}
	                      thumb={museumGroup}
	                      className='d-inline d-lg-none' 
	                      alt='kids at a museum'
	                    />
	                    <ImageContainer
	                      src={museumGroup}
	                      thumb={museumGroup}
	                      className='d-none d-lg-inline' 
	                      alt='kids at a museum'
	                    />
	                </div>
	            </div>
	        </div>
		</Container>
  );
};

export default SponsorWhyJoin;