import {
  GET_PROVIDER_REVIEWS,
  ADD_PROVIDER_REVIEW,
  PROVIDER_REVIEW_DELETED,
  PROVIDER_REVIEW_ERROR
} from '../actions/types';

const initialState = {
  providerReviews: [],
  providerReviewsLoading: true,
  error: {}
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_PROVIDER_REVIEWS:
      return {
        ...state,
        providerReviews: payload,
        providerReviewsLoading: false
      };
    case ADD_PROVIDER_REVIEW:
      return {
        ...state,
        providerReviews: [payload, ...state.providerReviews],
        providerReviewsLoading: false
      };
    case PROVIDER_REVIEW_DELETED:
      return {
        ...state,
        providerReviews: state.providerReviews.filter(review => review._id !== payload),
        providerReviewsLoading: false
      };
    case PROVIDER_REVIEW_ERROR:
      return {
        ...state,
        providerReviewsLoading: false,
        error: payload
      };
    default:
      return state;
  }
}
