import React, { Component } from 'react';
import FaqSection from './FaqOne';

class Faq extends Component {
    render() {
        return (
            <div>
                {/*====== Scroll To Top Area Start ======*/}
                <div id="scrollUp" title="Scroll To Top">
                    <i className="fas fa-arrow-up" />
                </div>
                {/*====== Scroll To Top Area End ======*/}
                <div className="main">
                    <FaqSection />
                </div>
            </div>
        );
    }
}

export default Faq;