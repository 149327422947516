import api from '../utils/api';
import { setAlert, setLongAlert } from './alert';
import {
  GET_PROVIDER_REVIEWS,
  ADD_PROVIDER_REVIEW,
  PROVIDER_REVIEW_DELETED,
  PROVIDER_REVIEW_ERROR
} from './types';

// Get all providers
export const getProviderReviews = (providerId) => async dispatch => {

  try {
    const res = await api.get(`/providerReviews/${providerId}`);

    dispatch({
      type: GET_PROVIDER_REVIEWS,
      payload: res.data
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: PROVIDER_REVIEW_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};


//Post a provider review
export const createProviderReview = (review, rating, providerId) => async dispatch => {

  const formData = {
    review,
    rating
  };

  try {
    
    const res = await api.post(`/providerReviews/${providerId}`, formData);
    
    dispatch({
      type: ADD_PROVIDER_REVIEW,
      payload: res.data
    });

    dispatch(setAlert('Thanks for your review!', 'success'));
    
  } catch (err) {
    
    const errors = err.response.data.errors;

    if (errors) {
      window.scrollTo(0,0);
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    };

    console.log(err);
    if (err.response) {
        dispatch({
          type: PROVIDER_REVIEW_ERROR,
          payload: { msg: err.response.data, status: err.response.status }
        });
        // console.log(err.response);
        window.scrollTo(0,0);
        dispatch(setLongAlert(err.response.data.msg, 'danger'));
      } else if (err.request) {
        console.log(err.request);
      } else {
        console.log('Error msg: ', err.message);
      }

    // dispatch({
    //   type: PROVIDER_REVIEW_ERROR,
    //   payload: { msg: err.response.statusText, status: err.response.status }
    // });
  }
};


// Delete provider review
export const deleteProviderReview = (reviewId) => async dispatch => {
  if (window.confirm('Are you sure? This CANNOT be undone!')) {
    
    try {
      await api.delete(`/providerReviews/${reviewId}`);
      
      dispatch({
        type: PROVIDER_REVIEW_DELETED,
        payload: reviewId
      });

      window.scrollTo(0,0);
      dispatch(setAlert('This review has been permanantly deleted.', 'success'));

    } catch (err) {
      if (err.response) {
        dispatch({
          type: PROVIDER_REVIEW_ERROR,
          payload: { msg: err.response.data, status: err.response.status }
        });
      } else if (err.request) {
        console.log(err.request);
      } else {
        console.log('Error msg', err.message);
      }
      window.scrollTo(0,0);
      dispatch(setAlert('There was an error. Please reload the page and try again.', 'danger'));
      console.log(err.config);
    }
  }
};
