import React, { useState } from 'react';
import ImageContainer from '../../layout/ImageContainer';
import sponsorBadge from '../../../images/badge_sponsor_default.svg';
import sponsorBadgeOnDash from '../../../images/badge_sponsor_dashboardView-your-logo.svg';

const SponsorHero = () => {
    const [data, setData] = useState({
        heading: "Become a sponsor!",
        content1: "Does your organization provide educational opportunities to kids in the K-12 market?",
        content2: "Are you looking to find ways to support education for local families?",
        content3: "By sponsoring year-long memberships to MySchool+, you'll be providing a valuable resource to local families, and your logo will go on their dashboard so that they know who to thank!",
        heroThumb: "/img/welcome_mockup.png"
    });

    return (
        <section id="home" className="section d-flex align-items-center">
            <div className="container">
                <div className="row align-items-center">
                    {/* Welcome Intro Start */}
                    <div className="col-12 col-md-7 col-lg-6">
                        <div className="welcome-intro">
                            <h2 className="text-myschool-blue">{data.heading}</h2>
                            <p className="text-myschool-blue my-4 text-italic">{data.content1}</p>
                            <p className="text-myschool-blue my-4 text-italic">{data.content2}</p>
                            <p className="text-myschool-blue my-4">{data.content3}</p>
                        </div>
                    </div>
                    <div className="col-12 col-lg-6 order-2 text-center">
                        <div>
                            - Sample Dashboard View -
                        </div>
                        <div className="service-thumb mx-auto">
                            <ImageContainer
                              src={sponsorBadgeOnDash}
                              thumb={sponsorBadgeOnDash}
                              className='d-inline d-lg-none' 
                              alt='kids at a museum'
                            />
                            <ImageContainer
                              src={sponsorBadgeOnDash}
                              thumb={sponsorBadgeOnDash}
                              className='d-none d-lg-inline' 
                              alt='kids at a museum'
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
};

export default SponsorHero;
