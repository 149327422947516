import api from '../utils/api';
import { setAlert } from './alert';
import history from '../history';

import {
  GET_PROFILE,
  GET_MY_PROFILE,
  GET_PROFILES,
  GET_PROFILES_WITHIN,
  PROFILE_ERROR,
  CLEAR_PROFILE,
  CLEAR_PROVIDER,
  CLEAR_PROVIDERS,
  ACCOUNT_DELETED,
  EMAIL_ERROR,
  LOGOUT
} from './types';

// Get current users profile
export const getCurrentProfile = () => async dispatch => {
  try {
    
    const res = await api.get('/profile/MyInfo');

    dispatch({
      type: GET_MY_PROFILE,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: PROFILE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get all profiles
export const getProfiles = () => async dispatch => {
  dispatch({ type: CLEAR_PROFILE });

  try {
    const res = await api.get('/profile');

    dispatch({
      type: GET_PROFILES,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: PROFILE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get profile by ID
export const getProfileById = userId => async dispatch => {
  try {
    const res = await api.get(`/profile/user/${userId}`);

    dispatch({
      type: GET_PROFILE,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: PROFILE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get public profile by ID
export const getPublicProfileById = userId => async dispatch => {
  try {
    const res = await api.get(`/profile/myschoolcard/public/user/${userId}`);

    dispatch({
      type: GET_PROFILE,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: PROFILE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get profile by ID
export const getMyIDCard = () => async dispatch => {
  try {
    const res = await api.get(`/profile/myschoolcard`);

    dispatch({
      type: GET_MY_PROFILE,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: PROFILE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

//Get profiles near user (with dates > now --- handled backend).
export const getProfilesWithin = (position, dist) => async dispatch => {
    
  const { lat, long } = position;

  if (!dist ) {
    position = {...position, 
      dist: 100
    };
  }

  try {
    const res = await api.get(`/profile/profiles-within/${lat}/${long}/${dist}`);

    dispatch({
      type: GET_PROFILES_WITHIN,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: PROFILE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get providers within bounds -- this is for updating the Homeschool Map once the bounds change. 
export const getBoundedProfiles = (bounds) => async dispatch => {
  
  const { latMin, latMax, longMin, longMax } = bounds;

  try {
    const res = await api.get(`/profile/profiles-on-map/${latMin}/${latMax}/${longMin}/${longMax}`);

    dispatch({
      type: GET_PROFILES_WITHIN,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: PROFILE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};


//EVEN THOUGH THIS IS CALLED V2, IT'S AN OLDER VERISON!
// Create or update profile V2
export const createProfileV2 = (formData) => async dispatch => {
  try {

    const res = await api.put('/profile/v2', formData);

    dispatch({
      type: GET_MY_PROFILE,
      payload: res.data
    });
    
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      window.scrollTo(0,0);
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: PROFILE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Create or update brief profile (This is intended to get someone's profile started fast!)
export const createProfile = (formData) => async dispatch => {
  try {
    const configMulti = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    };

    const res = await api.post('/profile/brief-profile', formData, configMulti);
    console.log(res.data);
    dispatch({
      type: GET_MY_PROFILE,
      payload: res.data
    });
    
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      window.scrollTo(0,0);
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: PROFILE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Delete account & profile
export const deleteAccount = () => async dispatch => {
  if (window.confirm('Are you sure? This CANNOT be undone!')) {
    try {
      await api.delete('/profile');
      await dispatch({ type: CLEAR_PROFILE });
      await dispatch({ type: CLEAR_PROVIDER });
      await dispatch({ type: CLEAR_PROVIDERS });
      await dispatch({ type: ACCOUNT_DELETED });
      await dispatch({ type: LOGOUT });

      dispatch(setAlert('Your account has been permanantly deleted. We hope you\'ll come back, so we will just say ciao for now!', 'success'));
    } catch (err) {
      dispatch({
        type: PROFILE_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status }
      });
    }
  }
};

//Upload a profile image
export const uploadProfileImage = (formData) => async dispatch => {
  try {
    const configMulti = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    };

    const res = await api.post('/profile/image', formData, configMulti);

    dispatch({
      type: GET_MY_PROFILE,
      payload: res.data
    });

    dispatch(setAlert('Profile Image Uploaded', 'success'));

    history.push('/dashboard');
    
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      window.scrollTo(0,0);
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: PROFILE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

//Send email to user to connect
export const emailUser = (formData, userId, history) => async dispatch => {
  try {
    
    await api.post(`/profile/${userId}/sendEmail`, formData);
    
    dispatch(setAlert('Email sent!', 'success'));

    history.push('/homeschoolmap');

  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: EMAIL_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};


