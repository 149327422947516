import React, { Fragment, useState } from 'react';
import { Marker, Popup } from 'react-leaflet';
import { Link } from 'react-router-dom';
import { Button, Spinner } from 'reactstrap';
import capitalizeName from '../../../utils/capitalizeName';
import heartOutline from '../../../images/heart-outline.svg';
import heartFilled from '../../../images/heart-filled.svg';
import zoomToPoint from '../../../images/icon-zoom-to-point.svg';
import zoomOut from '../../../images/icon-zoom-out.svg';

const ProviderMarker = ({ provider, isSettingFav, favoriteProviderHandler, resourceIcon, auth }) => {


  return (
    <Marker key={provider._id} icon={resourceIcon} position = {[provider.lat, provider.long]}>
      <Popup>
        <Button type='button' className='fav-button float-right' disabled={isSettingFav} onClick={()=>favoriteProviderHandler(provider._id)}>
          {isSettingFav === false && auth.user && auth.user.myFavProviders && auth.user.myFavProviders.filter(fav => fav.toString() === provider._id).length > 0 ?
            <img src={heartFilled} width="20px"/>
             : 
           isSettingFav === true ?
            <Spinner style={{ width: '20px', height: '20px'}} type="grow" color="danger" />
             : 
            <img src={heartOutline} width="20px"/>
          } 
        </Button>
        Type: {provider.category ? 
          provider.category
            .split(' ')
            .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ')
          :''} 
        <br />
        Name: {provider.providerName} <br />
        {provider.website ? <div>Website: <a href={provider.website} target='_blank' rel='noopener noreferrer'>{provider.website}</a></div> : ''}
        <div>{provider.category === 'tutor' || provider.category === 'cooperative' || provider.category === 'tutorial' ?
          `Educational Philosophy: ${provider.edPhil}`
        : ''}
        </div>
        <div>
        {provider.slotsTotal !== undefined && provider.slotsAvailable !== undefined && (provider.category === 'cooperative' || provider.category === 'tutorial') ?
          `Slots available: ${provider.slotsAvailable} of ${provider.slotsTotal}`
        : ''}  
        </div>
        <br />
        <Link to={`/provider/${provider._id}`}>See more info and offerings <i className="fas fa-angle-double-right"></i></Link>
        <br/>
      </Popup>
    </Marker>
  );
};


export default ProviderMarker;
