import {
  GET_CURRICULUM,
  GET_CURRICULUMS,
  CURRICULUM_ERROR
} from '../actions/types';

const initialState = {
  curriculum: null,
  curriculums: [],
  myCurriculums: [],
  curriculumLoading: true,
  error: {}
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_CURRICULUM:
      return {
        ...state,
        curriculum: payload,
        curriculumLoading: false
      };
    case GET_CURRICULUMS:
      return {
        ...state,
        curriculums: payload,
        curriculumLoading: false
      };
    case CURRICULUM_ERROR:
      return {
        ...state,
        error: payload,
        curriculumLoading: false
      };
    default:
      return state;
  }
}
