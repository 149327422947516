import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FeatureProviders from './FeatureProviders';
// import FooterSection from '../../layout/Landing/FooterSection/Footer';
import introVideo from '../../../images/homeschoolMapProfiles.mp4';
import SpinnerComponent from '../../layout/Spinner';
import { getMyProviders } from "../../../actions/provider";

const CreateProviderFunnel = ({auth, provider: {providers, providerLoading}, getMyProviders}) => {

    useEffect(() => {
        getMyProviders();
    }, []);

    return  auth.loading || providerLoading ? (
      <SpinnerComponent/>
    ) :  (
        <Fragment>
            <div className="homepage-5">
                <div className="main">
                    <h2 className='text-center color-white mb-4'>
                        Everyone has knowledge to offer to their community.
                    </h2>
                    <h4 className='text-center color-white mb-4'>
                        Click on the steps below so you can share yours!
                    </h4>
                    <FeatureProviders providers={providers}/>
                    {/*<ReviewSection />*/}
                    <h4 className='text-center color-myschool-blue p-4'>Take a quick tour to see it in action!</h4>
                    <div className='text-center'>
                        <video width="600px" height="auto" controls className='video-wrapper'>
                          <source src={introVideo} type="video/mp4"/>
                        </video>
                    </div>
                </div>
            </div>
    </Fragment>
    );
}

CreateProviderFunnel.propTypes = {
  auth: PropTypes.object.isRequired,
  getMyProviders: PropTypes.func.isRequired,
  provider: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  provider: state.provider,
});

export default connect(
  mapStateToProps,
  { getMyProviders },
)(CreateProviderFunnel);