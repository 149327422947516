import React,  { useState, useEffect }  from 'react';
import {DayPicker} from 'react-day-picker';
import dayjs from 'dayjs';
import 'react-day-picker/dist/style.css';
import {Form, Input} from 'reactstrap';

const CalendarSelectDay = ({date, updateDate, selectTime, toggleShowCalendar}) => {
    
  const [selectedDay, setSelectedDay] = useState(date);
  const [selectedTime, setSelectedTime] = useState(dayjs(date).format("HH:mm"));

  const footer = date ? (
    <p>You selected {dayjs(selectedDay).format('MMM DD, YYYY')}.</p>
  ) : (
    <p>Please pick a day.</p>
  );

  const submitDateChange = () => {
    updateDate(selectedDay, selectedTime);
    toggleShowCalendar(false);
  }

  const cancel =()=> {
    toggleShowCalendar(false);
  }

  useEffect(()=> {
    // console.log(date);
    console.log(selectedDay);
  },[selectedDay]);

  return (
    <div>
      <div>
        <Form inline>
          <DayPicker
            mode="single"
            defaultMonth={new Date(date)}
            required
            selected={selectedDay}
            onSelect={setSelectedDay}
            footer={footer}
          />
          {selectTime ?
            <Input
              type="time"
              name="selectedTime"
              value={selectedTime}
              onChange={(e)=>setSelectedTime(e.target.value)}
              />
            :''}
          </Form>
      </div>
      <div>
        <button className='btn block-button btn-modal-blue' onClick={()=>submitDateChange()}>Done</button>
        <button className='btn block-button btn-modal-red' onClick={()=>cancel()}>Cancel</button>
      </div>
    </div>
  );
}

CalendarSelectDay.defaultProps = {
  date: dayjs(),
  selectTime:false
};

export default CalendarSelectDay;
