import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, ButtonGroup, Container, Row, Col, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { Link } from 'react-router-dom';
import FeatureFamilies from './Features/FeatureFamilies';
import introVideo from '../../images/homeschoolMapProfiles.mp4';
import SpinnerComponent from '../layout/Spinner';
import { getCurrentProfile } from "../../actions/profile";
import axios from 'axios';
import api from '../../utils/api';
import ReactGA from 'react-ga';

const FindFamiliesLandingComponent = ({auth, profile, getCurrentProfile}) => {

    useEffect(() => {
      if (auth && auth.user) {
        getCurrentProfile()
      }
    }, []);

    const [localFamilyCount, setLocalFamilyCount] = useState(0);

    const distance = 60; //miles
    useEffect(() => {
        const countFamiliesNearby = async () => {
            const ipLoc =  await axios.get('https://ipapi.co/json');
            const lat = ipLoc.data.latitude;
            const long = ipLoc.data.longitude;
            const famCount =  await api.get(`profile/profiles-within-count/${lat}/${long}/${distance}`); 
            console.log(famCount);
            setLocalFamilyCount(famCount.data);
        }
        countFamiliesNearby();
    }, []);

    useEffect(() => {
        ReactGA.event({ category: "FindFamilies", action: "Arrive on page" });
    },[]);

    return auth.loading ? (
      <SpinnerComponent/>
    ) :  (
        <Fragment>
            <div className="homepage-5">
                <div className="main bg-overlay ptb_50">
                    <h2 className='text-center color-white my-4'>
                        Want to find homeschooling families near you?
                    </h2>
                    {localFamilyCount > 9 ?
                        <h3 className='text-center color-myschool-yellow my-3'>
                            {`There are ${localFamilyCount} families near you!`}
                        </h3>
                        :''
                    }
                    <FeatureFamilies profile={profile}/>
                    {/*<ReviewSection />*/}
                    <h4 className='text-center color-myschool-blue p-4'>Take a quick tour to see it in action!</h4>
                    <div className='text-center'>
                        <video width="600px" height="auto" controls className='video-wrapper'>
                          <source src={introVideo} type="video/mp4"/>
                        </video>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

FindFamiliesLandingComponent.propTypes = {
  auth: PropTypes.object.isRequired,
  getCurrentProfile: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  profile: state.profile,
});

export default connect(
  mapStateToProps,
  { getCurrentProfile },
)(FindFamiliesLandingComponent);