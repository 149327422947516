import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import RegisterAndProfile from '../../auth/RegisterAndProfile';
import {useHistory} from 'react-router-dom';
import api from '../../../utils/api';
import ReactGA from 'react-ga';

const TrackAllPrograms = ({auth}) => {

    const [data, setData] = useState({
        heading: "Stop doing Google searches to find educational programs near you!",
        content: "Get microschools, homeschooling groups, and more sent directly to you, FREE.",
        content2: "Sign up, and we'll send you a list of programs near you every month.",
        btnText: "Sign up now!",
        formHeading: "Sign up for updates!",
        formBtnText: "By signing up, you accept our",
        formBtnText_2: "Terms",
        formBtnText_3: "Privacy Policy"
    });

    const history = useHistory();
    useEffect(()=>{
        history.replace({search: "?networkTracking=All%20Programs"});
        ReactGA.event({ category: "network-tracking", action: "1-arrive-on-page", label: 'all-programs'});
    },[]);

    const [isTracking, setTracking] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const updateProfileNetworkTracking = async () => {
        setIsSubmitting(true);
        ReactGA.event({ category: "network-tracking", action: "2b-enrolled", label: 'all-programs'});
        const profileUpd = await api.post('/profile/networkTracking',{networkTracking:"All Programs"});
        setTracking(true);
        setIsSubmitting(false);
    };

    return (
        <div>
            <div className="bg-myschool-blue pt-4 ptb_50 d-flex align-items-center">
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        {/* Welcome Intro Start */}
                        <div className="col-12 col-lg-7">
                            <div className="welcome-intro">
                                <h1 className="text-white">{data.heading}</h1>
                                <p className="text-white my-4">{data.content}</p>
                                <p className="text-white my-4">{data.content2}</p>
                            </div>
                        </div>
                        <div className="col-12 col-md-8 col-lg-5">
                            {/* Contact Box */}
                            <div className="bg-white text-center rounded p-4 p-sm-5 mt-5 mt-lg-0 shadow-lg">
                                {/* Contact Form */}
                                <div>
                                    <div className="contact-top">
                                    <h3 className="contact-title">{data.formHeading}</h3>
                                    <h5 className="text-secondary fw-3 py-3">{data.formText}</h5>
                                    </div>
                                    <div className="row">
                                    <div className="col-12">
                                        {auth && auth.user && auth.user.profile ?
                                            <div>
                                                    <button 
                                                    type="button" 
                                                    className="btn btn-myschool-blue py-3 px-4" 
                                                    onClick={()=>updateProfileNetworkTracking()}
                                                    disabled={isTracking}>
                                                    {   isSubmitting ?
                                                            <span className="blinker">Submitting...</span>
                                                        :
                                                        isTracking ?
                                                            <span>You're all set up!</span>
                                                        :
                                                            <span>Click here to sign up!</span>
                                                    }
                                                </button>
                                            </div>
                                            :
                                            <RegisterAndProfile disableSkipThisStep={true}/>
                                        }
                                    </div>
                                    </div>
                                </div>
                                <p className="form-message" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="landing-angle">
                <svg viewBox="0 0 100 100" preserveAspectRatio="none">
                    <polygon fill="#375e97" points="0,0 100,0 0,100" />
                </svg>
            </div>
            
        </div>
    );
}

RegisterAndProfile.propTypes = {
    auth: PropTypes.object
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps, { })(TrackAllPrograms);