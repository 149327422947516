import React, { useEffect, useState, Fragment } from "react";
import { Form, Row, Col, Label, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Spinner } from 'reactstrap';
import convertMinsToHrsMins from '../../../utils/convertMinsToHrsMins';
import CalendarSelectDay from '../../form-tools/CalendarSelectDay';
import dayjs from 'dayjs';

const EditEntry = ({entry, editPortfolioEntry, toggleEditEntry}) => {
  
  const [formData, setFormData] = useState({
    duration: 120,
    description: '',
    subjects: [],
    dateOverride: ''
  });
  const {duration, description, subjects, dateOverride} = formData;

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [ subjectOptions, setSubjectOptions ] = useState([
    "math",
    "english",
    "science",
    "social studies",
    "art",
    "music",
    "physical education",
    "health",
    "history",
    "technology"
    ]);
  const updateSubjects = e => {
    e.preventDefault();
    // check if the check box is checked or unchecked
    if (subjects.includes(e.target.name) === false) {
      setFormData({...formData, subjects: [...subjects, e.target.name]});
      // console.log({subjects: [...subjects, e.target.name]});
    } else {
      const newArray = subjects.filter(el=> el !== e.target.name);
      setFormData({...formData, subjects: newArray});
      // console.log(newArray);
    }
  }

  // const [ subjectEntry, setSubjectEntry ] = useState('');

  // const handleSubjectKeyPress = (e) => {
  //   if(e.key === ","){
  //     e.preventDefault();
  //     setFormData({...formData, subjects: [...subjects, e.target.value]});
  //     setSubjectEntry('');
  //   }
  // };

  // const handleSubjectButton = (e) => {
  //   e.preventDefault();
  //   setFormData({...formData, subjects: [...subjects, e.target.value]});
  //   setSubjectEntry('');
  // };

  // const changeSubjectEntry = e => {
  //   if(e.key !== ","){
  //     setSubjectEntry(e.target.value);
  //   }
  // }

  // const onDeleteSubject = (string) => {
  //   const revArray = subjects.filter(e => e !== string);
  //   // console.log(revArray);
  //   setFormData({...formData, subjects: revArray});
  // } 

  const [ textAreaCount, setTextAreaCount ] = useState(0);

  const onTextAreaChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setTextAreaCount(e.target.value.length);
  };

  const [mins, setMins] = useState(0);
  const [hrs, setHrs] = useState(2);

  const [showCalendar, toggleShowCalendar] = useState(false);

  const updateDate = (newDate, newTime) => {
    const hh = newTime.split(":")[0] * 1;
    const mm = newTime.split(":")[1]  * 1;
    const newDateOverride = new Date(dayjs(newDate).hour(hh).minute(mm));
    setFormData({...formData, dateOverride: newDateOverride});
  }

  const onSubmit = e => {
    e.preventDefault();
    setIsSubmitting(true);
    let postDuration = Number(hrs*60) + Number(mins);
    const postData = {...formData, duration:postDuration};
    editPortfolioEntry(entry._id,postData);
    setIsSubmitting(false);
    toggleEditEntry(false); 
  };

  useEffect(() => {
    setFormData({
      ...formData,
      duration: entry.duration ? Number(entry.duration) : 120,
      description: entry.description ? entry.description : '',
      subjects: entry.subjects ? entry.subjects : [],
      dateOverride: entry.timestamp,
    });
    if (entry && entry.description && entry.description.length > 0 ) {
      setTextAreaCount(entry.description.length)
    } 
  },[]);

  useEffect(() => {
    let h = Math.floor(entry.duration / 60);
    let m = entry.duration % 60;
    setMins(m);
    setHrs(h);
  },[]);

  return (
    <div style={{textAlign:"left"}}>
      {showCalendar ?
        <div style={{margin:"auto"}}>
          <CalendarSelectDay 
            date={dateOverride} 
            updateDate={updateDate} 
            selectTime={true} 
            toggleShowCalendar={toggleShowCalendar}
            />
        </div>
        :
        <Form>
          <h4 className="mb-3 text-center">Edit Entry</h4>
          <FormGroup>
            <Label for="subjects">Subject(s)</Label>
            <div>
              {subjectOptions.map((sub)=>{
                  return (
                    <button 
                      key={`s-${sub}`}
                      name={sub}
                      onClick={e=>updateSubjects(e)}
                      // className="btn-modal-red mx-1"
                      className={subjects && subjects.includes(`${sub}`) ? "btn btn-modal-blue ":"btn btn-modal-blue-border"}
                      >
                      {sub}
                    </button>
                    )
                  }
                )
              }
            </div>
          </FormGroup>

          <FormGroup>
              <Label for="description">Description</Label>
              <div><small><strong>{`Character Count: ${textAreaCount} of 500`}</strong></small></div>
              <Input type="textarea" maxLength='500' name="description" id="description" value={description} onChange={(e)=>onTextAreaChange(e)}/>
          </FormGroup>

          <Row form>
            <Col md={6}>
              <FormGroup>
                <Label for="hrs">Hours</Label>
                <Input type="number" max='6' name="hrs" id="hrs" value={hrs} onChange={(e)=>setHrs(e.target.value)}/>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="mins">Minutes</Label>
                <Input type="number" max='59' name="mins" id="mins" value={mins} onChange={(e)=>setMins(e.target.value)}/>
              </FormGroup>
            </Col>
          </Row>

          <Row form>
            <Col md={6}>
              <FormGroup>
                <Label for="dateOverride">Date</Label>
                <div>
                  <button className="btn btn-modal-blue-border" onClick={()=>toggleShowCalendar(!showCalendar)}>{dayjs(dateOverride).format("MMM DD, YYYY")}</button>
                </div>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="timeOverride">Time</Label>
                <div>
                  <button className="btn btn-modal-blue-border" onClick={()=>toggleShowCalendar(!showCalendar)}>{dayjs(dateOverride).format("h:mm a")}</button>
                </div>
              </FormGroup>
            </Col>
          </Row>

          <div className="text-center">
            <button type="button" onClick={e =>onSubmit(e)} disabled={ isSubmitting } className='btn btn-modal-blue btn-set-WH'>
              <span>
                { !isSubmitting ?
                  `Submit`
                  :
                  <Spinner size="sm" className='color-white'/>
                }
              </span>
            </button>
          </div>

        </Form>
      }
    </div>
  );
}

export default EditEntry;

