import React, { Fragment, useState } from 'react';
import { Marker, Popup } from 'react-leaflet';
import { Link } from 'react-router-dom';
import { Button, Spinner } from 'reactstrap';
import capitalizeName from '../../../utils/capitalizeName';
import heartOutline from '../../../images/heart-outline.svg';
import heartFilled from '../../../images/heart-filled.svg';

const ResourceMarker = ({ resource, isSettingFav, favoriteResourceHandler, resourceIcon, auth, history, toggleLoginModal }) => {


  return (
    <Marker key={resource._id} icon={resourceIcon} position = {[resource.lat, resource.long]}>
      <Popup>
        <Button type='button' className='fav-button float-right' onClick={()=>favoriteResourceHandler(resource._id)}>
          {isSettingFav === false && auth.user && auth.user.myFavResources && auth.user.myFavResources.filter(fav => fav.toString() === resource._id).length > 0 ?
            <img src={heartFilled} width="20px"/>
             : 
           isSettingFav === true ?
            <Spinner style={{ width: '20px', height: '20px'}} type="grow" color="danger" />
             : 
            <img src={heartOutline} width="20px"/>
          } 
        </Button>
        Category: {resource.category && capitalizeName(resource.category)}
        <br />
        Name: {resource.resourceName} <br />
        Website: <a href={resource.website} target='_blank' rel='noopener noreferrer'>{resource.website}</a> <br />
        { resource.creditedTo && resource.creditedTo.providerName && resource.creditedTo._id ? 
          <div className='text-bold'>Suggested By:<Link to={`/provider/${resource.creditedTo._id}`}>{` ${resource.creditedTo.providerName} `}<i className="fas fa-angle-double-right"/></Link></div>
          : ''
        }
        <Link to={`/resource/${resource._id}`}>See more info<i className="fas fa-angle-double-right"></i></Link>
        <div>
          {
            auth.isAuthenticated ? 
            <button className='basic-button text-12px text-bold text-underlined' onClick={()=>history.push(encodeURI(`/create-event?address=${resource.address}&city=${resource.city}&state=${resource.state}&zip=${resource.zip}`))}>Host an event here</button>
            :
            <button className='basic-button text-12px text-bold text-underlined' onClick={()=>toggleLoginModal(true)}>Host an event here</button>
          }
          {' | '}
          {
            auth.isAuthenticated ? 
            <button className='basic-button text-12px text-underlined' onClick={()=>history.push(`/claim-resource/${resource._id}`)}>Claim Spot</button>
            :
            <button className='basic-button text-12px text-underlined' onClick={()=>toggleLoginModal(true)}>Claim Spot</button>
          }
          {' | '}
          {
            auth.isAuthenticated ? 
            <button className='basic-button text-12px text-underlined' onClick={()=>history.push(`/edit-resource/${resource._id}`)}>Edit Spot</button>
            :
            <button className='basic-button text-12px text-underlined' onClick={()=>toggleLoginModal(true)}>Edit Spot</button>
          }
        </div>
      </Popup>
    </Marker>
  );
};


export default ResourceMarker;



