import React, { useEffect, useState, Fragment } from "react";
import { Form, Row, Col, Label, FormGroup, Input, CustomInput, InputGroup, InputGroupAddon, InputGroupText, Button, Spinner, Modal, ModalHeader, ModalBody, Alert, Progress } from 'reactstrap';
import CalendarSelectDays from '../form-tools/CalendarSelectDays';
import calculateTakehomePay from '../../utils/calculateTakehomePay';
import { useParams } from 'react-router-dom';
import ConnectStripeButton from '../../images/ConnectStripe_blurple_2x.png'; 
import formatDate from '../../utils/formatDate'; 
import isValidURL from '../../utils/isValidURL';
import downTriangleIcon from '../../images/icon-extend-down-circle.svg';
import upTriangleIcon from '../../images/icon-extend-up-circle.svg';
import lightbulbOn from '../../images/icon-lightbulb-on.svg';

const EventForm = ({ onSubmit, formData, setFormData, mapMeData, hostInfo, history, previewClosing, setShowPreview, isSubmitting, setIsSubmitting, disableDueToBookings, accountDetails, editMode, copyMode, setAlert, swapSection12 }) => {
  const params = useParams();

  const { category, title, dateRange, description, subjects, isDropOff, meetingInstructions, preparations, expectations, ifClassMissed, learningGoals, uponCompletion, startTime, endTime, costChild, costAdult, suggestDonation, donation, ageStart, ageEnd, slotsTotal, slotsPrefilled, classContact, contactEmail, isUnlisted, isOffSite, offSiteLink, isFreeMeetup, isForParents } = formData;

  const setPWYC = () => {
    setFormData({ ...formData, suggestDonation: true, costAdult: '', costChild: '' });
  };

  const setCost = () => {
    setFormData({ ...formData, suggestDonation: false, donation: '' });
  };

  const onFreeMeetup = () => {
    setFormData({ 
      ...formData, 
      donation: 0,
      suggestDonation: true,
      category: 'Meetup',
      isOffSite: false,
      isFreeMeetup: true,
      offSiteLink: ''
    });
  };

  const onCheckChange = e =>
    setFormData({ ...formData, [e.target.name]: !e.target.checked });

  const onChange = e =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onDateChange = (selectedDays) =>
    setFormData({ ...formData, dateRange: selectedDays });

  const makeParentEvent = (e) => {
    setFormData({ ...formData,
      isForParents: e.target.checked,
      ageStart: 18,
      ageEnd: 100
    });
    // console.log(e.target.checked);
  }

  const [ subjectEntry, setSubjectEntry ] = useState('');

  const changeSubjectEntry = e => {
    if(e.key !== ","){
      setSubjectEntry(e.target.value);
    }
  }

  const onDeleteSubject = (string) => {
    const revArray = subjects.filter(e => e !== string);
    // console.log(revArray);
    setFormData({...formData, subjects: revArray});
  }    

  //default policy inputs
  const strictMissedClass = () => {
    setFormData({ ...formData, ifClassMissed: 'Participants cannot miss any days if they wish to continue the course.  All work must be turned in on time.  The event contact will inform you if you cannot continue the course.  MySchool\'s Standard cancellation policy applies even if the participant is excused from the course.' });
    setTextAreaCount({ ...textAreaCount, ifClassMissed: 278 });
  }

  const relaxedMissedClass = () => { 
    setFormData({ ...formData, ifClassMissed: 'Participants may miss one or multiple sessions and still partake. The participant is responsible for meeting the expectations outlined in the "expectations" section.' });
    setTextAreaCount({ ...textAreaCount, ifClassMissed: 165 });
  }

  const okMissedClass = () => { 
    setFormData({ ...formData, ifClassMissed: 'Everything about this is totally optional. Come. Don\'t come. It\'s up to you!'  });
    setTextAreaCount({ ...textAreaCount, ifClassMissed: 76 });
  }

  const [ textAreaCount, setTextAreaCount ] = useState({
    description: 0,
    included: 0,
    meetingInstructions: 0,
    preparations: 0,
    expectations: 0,
    ifClassMissed: 0,
    learningGoals: 0,
    uponCompletion: 0,
  });

  const onTextAreaChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setTextAreaCount({ ...textAreaCount, [e.target.name]: e.target.value.length});
  };

  const handleSubjectKeyPress = (e) => {
    if(e.key === ","){
      e.preventDefault();
      setFormData({...formData, subjects: [...subjects, e.target.value]});
      setSubjectEntry('');
    }
  };

  const handleSubjectButton = (e) => {
    e.preventDefault();
    setFormData({...formData, subjects: [...subjects, e.target.value]});
    setSubjectEntry('');
  };

  //if Stripe Connect enabled
  const [ connectedAccount, setConnectedAccount ] = useState(false);

  useEffect(()=> {
    if (accountDetails && accountDetails.details_submitted && accountDetails.charges_enabled) {
      setConnectedAccount(true);
    } 
    // else if (!editMode && !copyMode && !previewClosing) {
    //   // onFreeMeetup();
    // }
  },[]);

  useEffect(()=> {
    if (previewClosing === true) {
      //when the preview is closed, check all inputs again and go to the last section
      sect1();
      sect2();
      sect3();
    }
  },[]);

  const connectAccountButton = () => {
    if (params.hostType === 'profile') {
      history.push(`/profile/choose-payment-method`)
    } else if (hostInfo.hostType === 'profile') {
      history.push(`/profile/choose-payment-method`)
    } else if (params.hostType === 'provider' && params.hostId !== "undefined") {
      history.push(`/provider/${params.hostId}/choose-payment-method`)
    } else if (hostInfo.hostType === 'provider' && hostInfo.hostId) {
      history.push(`/provider/${hostInfo.hostId}/choose-payment-method`)
    } else {
      history.push('/dashboard')
    }
  }

  const [ showModalStripeConnect, toggleModalStripeConnect ] = useState(false);

  const [urlInvalid, setUrlInvalid] = useState(false);
  const onExternalUrlChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    if (isValidURL(e.target.value) === false) {
      setUrlInvalid(true);
    } else {
      setUrlInvalid(false);
    }
  };

  //Sections
  const [ showSection1, toggleSection1 ] = useState(true);
  const [ showSection2, toggleSection2 ] = useState(false);
  const [ showSection3, toggleSection3 ] = useState(false);
  const [ showAddSect3, toggleAddSect3 ] = useState(false);
  const [ prog, setProg ] = useState(25);

  const sect1 = () => {
    toggleSection1(true);
    toggleSection2(false);
    toggleSection3(false);
    setProg(25);
    window.scrollTo(0,60);
  };

  const sect2 = () => {
    //section 1 checks
    if (!isOffSite && !isFreeMeetup && !suggestDonation && !isForParents && !costChild ) {
      window.scrollTo(0,0);
      setAlert('You must at least include a cost for the child if you charge a set amount.', 'danger');
      setIsSubmitting(false);
    } else if (!isOffSite && !isFreeMeetup && !suggestDonation && (costChild*1 + costAdult*1 < 1) ) {
      window.scrollTo(0,0);
      setAlert('Total cost for both the child and adult cannot be less than $1. Please add a cost to at least one, or else choose "free."', 'danger');
      setIsSubmitting(false);
    } else if (!isFreeMeetup && !suggestDonation && isOffSite && costChild && costChild.length > 0 && costChild*1 < 1) {
      window.scrollTo(0,0);
      setAlert('Cost per child must be greater than or equal to $1.  Otherwise choose "free"', 'danger');
      setIsSubmitting(false);
    } else if (isOffSite && urlInvalid) {
      window.scrollTo(0,0);
      setAlert('Please enter a valid url for your external link.', 'danger');
      setIsSubmitting(false);
    } else if (isOffSite && offSiteLink.length < 12) {
      window.scrollTo(0,0);
      setAlert('Your url is too short.', 'danger');
      setIsSubmitting(false);
    } else {
      toggleSection1(false);
      toggleSection2(true);
      toggleSection3(false);
      setProg(50);
      window.scrollTo(0,60);
    }
  };

  const sect3 = () => {
    //section 2 checks
    if (!title) {
      window.scrollTo(0,0);
      setAlert('A title is required.', 'danger');
      setIsSubmitting(false);
    } else if (!isOffSite && !classContact) {
      window.scrollTo(0,0);
      setAlert('Please include your name for the confirmation email.', 'danger');
      setIsSubmitting(false);
    } else if (!isOffSite && !contactEmail) {
      window.scrollTo(0,0);
      setAlert('Please include your email address for the confirmation email.', 'danger');
      setIsSubmitting(false);
    } else if (!dateRange || dateRange.length < 1) {
      window.scrollTo(0,0);
      setAlert('You must select at least one date.', 'danger');
      setIsSubmitting(false);
    } else if (!startTime || !endTime) {
      window.scrollTo(0,0);
      setAlert('Please include a start and end time and indicate AM or PM.', 'danger');
      setIsSubmitting(false);
    } else if (!ageStart || !ageEnd) {
      window.scrollTo(0,0);
      setAlert('You must include a start and end to the target age range.', 'danger');
      setIsSubmitting(false);
    } else if (ageStart*1 > ageEnd*1) {
      window.scrollTo(0,0);
      setAlert('Age Range Start must be equal to or less than Age Range End.', 'danger');
      setIsSubmitting(false);
    } else if ( (!isOffSite) && ((slotsTotal*1 < 1 || slotsPrefilled*1 < 0) || (slotsTotal*1 <= slotsPrefilled*1)) ) {
      window.scrollTo(0,0);
      setAlert('The total number of slots must be greater than 0 and greater than the number of slots currently filled.', 'danger');
      setIsSubmitting(false);
    // } else if ( (isFreeMeetup == true || isOffSite == true) && (slotsTotal*1 !== 0 || slotsPrefilled*1 !== 0) && (slotsTotal*1 <= slotsPrefilled*1) ) {
    //   window.scrollTo(0,0);
    //   setAlert('The total number of slots must be greater than 0 and greater than the number of slots currently filled.', 'danger');
    //   setIsSubmitting(false);
    // } else if ( (isFreeMeetup == false && isOffSite == false) && (slotsTotal*1 < 1 || slotsPrefilled*1 < 0 || slotsTotal*1 <= slotsPrefilled*1) ) {
    //   window.scrollTo(0,0);
    //   setAlert('The total number of slots must be greater than 0 and greater than the number of slots currently filled.', 'danger');
    //   setIsSubmitting(false);
    } else {
      toggleSection1(false);
      toggleSection2(false);
      toggleSection3(true);
      setProg(75);
      window.scrollTo(0,60);
    }
  };

  return (
    <Fragment>
      <Form>
        <Progress animated value={prog}>{prog}%{prog === 75 ? ` Almost Done!`:''}</Progress>
        <h4 className='mt-3 mb-2'> Now add some details!</h4>
        <div className='mt-3 mb-2 color-danger'>(Note: All fields are required unless noted as "optional.")</div>

      {/*Begin part 1*/}

        { showSection1 &&
          <div>
            <div className='msstd-form-light-border my-2'>
              <h5 className='my-2'>How do you want to organize and get compensated for your event?</h5>
              <div className='mt-3 mb-2 mx-4'>
                <div className='float-left mt-2 mr-2'>
                  <img src={lightbulbOn} width='25px' className='' alt='lightbulb tip icon'/>
                </div>
                <div>
                  <div>
                    Bookings that use MySchool's integrated payments are over{' '}<span className='text-bold text-underline'>3 TIMES</span><span className='text-bold'>{' '}less likely to be cancelled!</span>{' '} MySchool makes it easy to pay BEFORE registering for a slot so you can
                    <span className='text-bold'>
                      {' '}focus on the families,{' '}
                        <span className='text-bold text-underline'>
                        not
                        </span>
                      {' '}the financials!
                    </span>
                  </div>
                </div>
              </div>
              <FormGroup check>
                <Label check className={!connectedAccount || editMode ? 'text-color-light':''}>
                  <Input type="radio"
                    name="isOffSite"
                    checked={!isOffSite && !isFreeMeetup}
                    onChange={() => {
                        setFormData({...formData, isOffSite: false, isFreeMeetup: false});
                      }}
                    disabled={!connectedAccount || editMode}
                  />{' '}
                  Use MySchool booking & payment tools
                    {!connectedAccount && <span>{` - connect with Stripe to unlock`}</span>}
                </Label>
              </FormGroup>
              <FormGroup check>
                <Label check className={editMode ? 'text-color-light':''}>
                  <Input type="radio"
                    name="isFreeMeetup"
                    checked={isFreeMeetup}
                    onChange={() => onFreeMeetup()}
                    disabled={editMode}
                  />{' '}
                  Use MySchool booking tools for a free event. No money will be collected. 
                </Label>
              </FormGroup>
              <FormGroup check>
                <Label check className={editMode ? 'text-color-light':''}>
                  <Input type="radio"
                    name="isOffSite"
                    checked={isOffSite}
                    onChange={() => {
                        setFormData({...formData, isOffSite: true, isFreeMeetup: false});
                      }}
                    disabled={editMode}
                  />{' '}
                  Link to an external site to manage bookings and payment.
                </Label>
              </FormGroup>
              { isOffSite &&
                <Row>
                  <Col>
                    <FormGroup>
                      <Input invalid={urlInvalid} type="text" placeholder='http://example.com' name="offSiteLink" id="offSiteLink" value={offSiteLink} onChange={(e)=>onExternalUrlChange(e)}/>
                    </FormGroup>
                    { urlInvalid &&
                      <div>
                        <ul className='text-danger'>
                          <li>-The URL must start with either "http://" or "https://"</li>
                        </ul>
                      </div>
                    }
                  </Col>
                </Row>
              }
              {
                !connectedAccount && !editMode ?
                  <Row>
                    <Col className='text-center'>
                      <div className='mt-4 mb-1'>Connect your account to process payments directly through MySchool!</div>
                      <div className='mb-4'>
                        <button className='basic-button' onClick={()=>connectAccountButton()}>
                          <img src={ConnectStripeButton} width='200px' alt='Create Stripe Connect Account'/>
                        </button>
                      </div>
                    </Col>
                  </Row>
                  :''
              }
              <Row form className={connectedAccount ?'mt-4 mb-2':'mb-2'}>
                <Col sm={12} md={6}>
                  <FormGroup check>
                    <Label check>
                      <Input type="radio"
                        name="suggestDonation"
                        checked={suggestDonation === false}
                        onChange={() => setCost()}
                        disabled={isFreeMeetup}
                      />{' '}
                      Charge Set Amount
                    </Label>
                  </FormGroup>
                </Col>
                <Col sm={12} md={6}>
                  <FormGroup check>
                    <Label check>
                      <Input type="radio"
                        name="suggestDonation"
                        checked={suggestDonation === true}
                        onChange={() => setPWYC()}
                        disabled={isFreeMeetup}
                      />{' '}
                      Free (Donations Accepted)
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
              <Row form>
                { suggestDonation === false ? 
                  <Fragment>
                    <Col md={6}>              
                      <FormGroup>
                        <Label for="costChild">Cost per child{isOffSite ? ` (optional)`:''}</Label>
                        <Input type="number" 
                        name="costChild" 
                        id="costChild"
                        value={costChild}
                        min="0"
                        onChange={e => onChange(e)}
                        disabled={isFreeMeetup}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6}>              
                      <FormGroup>
                        <Label for="costAdult">Cost per adult{isOffSite ? ` (optional)`:''}</Label>
                        <Input type="number" 
                        name="costAdult" 
                        id="costAdult"
                        value={costAdult}
                        onChange={e => onChange(e)} 
                        min="0" 
                        disabled={isFreeMeetup}
                        />
                      </FormGroup>
                    </Col>
                  </Fragment>
                  :
                  <Fragment>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="donation">Suggested Amount ($){isOffSite ? ` (optional)`:''}</Label>
                        <Input type="number" 
                          name="donation" 
                          id="donation"
                          value={donation}
                          onChange={e => onChange(e)} 
                          min="0" 
                          disabled={isFreeMeetup}
                        />
                      </FormGroup>
                    </Col>
                  </Fragment>
                }
              </Row>
              { !isOffSite ?
                <Row>
                  <Col>
                    You make: { !suggestDonation ? 
                      `$${calculateTakehomePay(costChild)} per child & $${calculateTakehomePay(costAdult)} per adult`
                      :
                      `$${calculateTakehomePay(donation)} per donation`
                    }.
                  </Col>
                </Row>
                :''
              }
              <div className='d-flex justify-content-between'>
                <Button type='button' className='btn btn-myschool-blue btn-set-WH' onClick={()=>swapSection12()}>Back</Button>
                <Button type='button' className='btn btn-myschool-blue btn-set-WH' onClick={()=>sect2()}>Next</Button>
              </div>
            </div>
          </div>

        }

        {/*Begin part 2*/}

        { showSection2 &&
          <Fragment>
            { hostInfo && !editMode && params.hostType !== 'profile'  ?
              <div className='msstd-form-light-border'>
                <h5 className='mt-3 mb-2'> Spot Info</h5>
                <Row form>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="hostName">Name</Label>
                      <Input type="text"
                      name="hostName"
                      id="hostName"
                      value={hostInfo.hostName}
                      readOnly/>
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                    <Label for="email">Email</Label>
                    <Input type="email"
                      name="email"
                      id="email"
                      value={hostInfo.email}
                      readOnly/>
                    </FormGroup>
                  </Col>
                </Row>
              </div>
              :''
            }
            <Row form>
              <Col>
                <FormGroup>
                  <Label for="category">Category</Label>
                  <Input type="select"
                    name="category"
                    id="category"
                    value={category}
                    onChange={e => onChange(e)} 
                    >
                    <option value="">Select one...</option>
                    <option value="Field Trip">Field Trip</option>
                    <option value="Class">Class</option>
                    <option value="Meetup">Meetup</option>
                    <option value="Camp">Camp</option>
                  </Input>
                </FormGroup>
              </Col>
            </Row>

            <FormGroup>
              <Label for="title">Event Title</Label>
              <Input type="text" 
              name="title" 
              id="title" 
              placeholder="Title" 
              value={title}
              onChange={e => onChange(e)} 
              required/>
            </FormGroup>

            <Label>Do you want this to be a private event?</Label>
            <FormGroup check> 
              <Label check>
                <Input
                  type="radio"
                  name="private" 
                  checked={isUnlisted}
                  onChange={() => setFormData({ ...formData, isUnlisted: true })} 
                  />
                  Make this event private. I'll send the link out myself.
              </Label>
            </FormGroup>
            <FormGroup check>
              <Label check>
                <Input
                type="radio"
                name="public" 
                checked={!isUnlisted}
                onChange={() => setFormData({ ...formData, isUnlisted: false })} 
                />
                Make this event public. Anyone will be able to find it from our events page!
              </Label>
            </FormGroup>
              
            

            <div className='msstd-form-light-border my-3'>
              { !isOffSite && isOffSite === false &&
                <Row form>
                  <Col>
                    <FormGroup>
                      <Label for="classContact">
                        {
                          params.hostType === 'profile' ?
                          'Your name'
                          :
                          'Contact Name'
                        }
                      </Label>
                      <div><small>(Not publicly displayed - only provided in the booking confirmation email.)</small></div>
                      <Input type="text"
                      name="classContact"
                      id="classContact"
                      value={classContact}
                      onChange={e => onChange(e)} 
                      required
                      />
                    </FormGroup>
                  </Col>
                </Row>
              }
              { !isOffSite &&
                <Row form>
                  <Col>
                    <FormGroup>
                    <Label for="contactEmail">
                      {
                        params.hostType === 'profile' ?
                        'Email where registrants can contact you'
                        :
                        'Contact email'
                      }
                    </Label>
                    <div><small>(Not publicly displayed - only provided in the booking confirmation email.)</small></div>
                    <Input type="email"
                      name="contactEmail"
                      id="contactEmail"
                      value={contactEmail}
                      onChange={e => onChange(e)} 
                      required
                      />
                    </FormGroup>
                  </Col>
                </Row>
              }
              <Row>
                <Col>
                  <div>
                    <Label>Date(s)</Label>
                  </div>
                  { !disableDueToBookings ?
                    <CalendarSelectDays onDateChange={onDateChange} dateRange={dateRange}/>
                    :
                    <Alert color="warning">Dates cannot be changed after bookings have been made.</Alert>
                  }
                </Col>
              </Row>
              {dateRange.map((date, i)=> 
                  <span key={i} className='btn btn-myschool-red'>
                    {formatDate(date, 'long')}
                    {' '}
                  </span>
                )
              }
              <Row form>
                <Col md={6}>
                  <FormGroup>
                    <Label for="startTime">Start Time</Label>
                    <Input type="time" 
                    name="startTime" 
                    id="startTime" 
                    placeholder="Start Time"
                    value={startTime}
                    onChange={e => onChange(e)} 
                    required
                    disabled={disableDueToBookings}/>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="endTime">End Time</Label>
                    <Input type="time" 
                      name="endTime" 
                      id="endTime" 
                      placeholder="End Time"
                      value={endTime}
                      onChange={e => onChange(e)} 
                      required
                      disabled={disableDueToBookings}/>
                  </FormGroup>
                </Col>
              </Row>
              <FormGroup check> 
                <Label check>
                  <Input
                    type="checkbox"
                    name="private" 
                    checked={isForParents}
                    onChange={(e)=>makeParentEvent(e)} 
                    disabled={disableDueToBookings}
                    />
                    This event is for parents ONLY!
                </Label>
              </FormGroup>
              { !isForParents ?
                <div>
                  <Row form>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="ageStart">Age Range Start</Label>
                        <Input type="number" 
                          name="ageStart" 
                          id="ageStart" 
                          value={ageStart}
                          onChange={e => onChange(e)}
                          disabled={isForParents} 
                          required/>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="ageEnd">Age Range End</Label>
                        <Input type="number" 
                          name="ageEnd" 
                          id="ageEnd"
                          value={ageEnd}
                          onChange={e => onChange(e)} 
                          disabled={isForParents}
                          required/>
                      </FormGroup>
                    </Col>
                  </Row>
                  {!isOffSite ?
                    <div>
                      <Label>Is this a drop off event?</Label>
                      <FormGroup check>
                        <Label check>
                          <Input type="radio"
                            name='isDropOff_false'
                            checked={!isDropOff}
                            disabled
                          />Not a drop off event - parents must stay
                        </Label>
                      </FormGroup>
                      <FormGroup check>
                        <Label check className='text-color-light'>
                          <Input type="radio"
                            name="isDropOff_true"
                            checked={isDropOff}
                            disabled
                          />Drop off event (Not currently available for MySchool bookings)
                        </Label>
                      </FormGroup>
                    </div>
                    :''
                  }
                </div>
                :
                <div className='space' />
              }

              {/*
                <Row form>
                  <Col>
                    <FormGroup>
                      <Label for="isDropOff">Is this a drop off class?</Label>   
                      <span>
                        <CustomInput type="switch" 
                          name="isDropOff" 
                          id="isDropOff" 
                          checked={isDropOff}
                          onChange={e => onCheckChange(e)} 
                          label={isDropOff ? "Yes":"No"}/>
                      </span>
                    </FormGroup>
                    <div className='color-myschool-red'>
                      { isDropOff ?
                        `Note: Drop off classes allow parents to leave during the class, but you must ensure that you have the required licensing in order to comply with the child care laws in your state. `
                        :
                        `Note: Non-drop-off classes require that parents to stay the entire time. `
                      }
                    </div>
                  </Col>
                </Row>
              */}
              
              <Row form>
                <Col md={6}>
                  <FormGroup>
                    <Label for="slotsTotal">Total Spots {isOffSite == true ? ` (optional)`:`` }</Label>
                    <div>
                      <small>
                        {isDropOff ? 
                        `Includes children only.`
                        :
                        `Includes children and adults.`
                        }
                      </small>
                    </div>
                    <Input type="number" 
                      name="slotsTotal" 
                      id="slotsTotal"
                      value={slotsTotal}
                      onChange={e => onChange(e)} 
                      />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="slotsPrefilled">Spots Currently Filled {isOffSite == true ? ` (optional)`:`` }</Label>
                    <div>
                      <small>
                        { `How many others are already going (including you!)?`}
                      </small>
                    </div>
                    <Input type="number" 
                      name="slotsPrefilled" 
                      id="slotsPrefilled" 
                      value={slotsPrefilled}
                      onChange={e => onChange(e)}
                      />
                  </FormGroup>
                </Col>
              </Row>
            </div>

            <div className='d-flex justify-content-between'>
              <Button type='button' className='btn btn-myschool-blue btn-set-WH' onClick={()=>sect1()}>Back</Button>
              <Button type='button' className='btn btn-myschool-blue btn-set-WH' onClick={()=>sect3()}>Next</Button>
            </div>

          </Fragment>
        }

        {/*Begin part 3*/}

        { showSection3 &&
          <div>
            <FormGroup>
              <Label for="description">Class Description (max 500 char.)</Label>
              <div>(Add your plans for COVID precautions here)</div>
              <div><small><strong>Character Count: {textAreaCount.description}</strong></small></div>
              <Input type="textarea" maxLength='500' name="description" id="description" value={description} onChange={(e)=>onTextAreaChange(e)}/>
            </FormGroup>

            <div>
              <button type='button' onClick={()=>toggleAddSect3(!showAddSect3)} className='btn btn-myschool-blue'>
                {!showAddSect3 ?
                  (
                    <div>
                      Show full details{' '}<img src={downTriangleIcon} width='15px' className='' alt='expand to view additional input fields'/>
                    </div>
                  )
                  :
                  (
                    <div>
                      Hide full details{' '}<img src={upTriangleIcon} width='15px' className='' alt='hide to view additional input fields'/>
                    </div>
                  )
                }
              </button>
            </div>
            {
              showAddSect3 &&
                <div>
                  <FormGroup>
                    <Label for="meetingInstructions">Where are you meeting? (optional - max 300 char.)</Label>
                    <div><small><strong>Character Count: {textAreaCount.meetingInstructions}</strong></small></div>
                    <Input type="textarea" maxLength='500' name="meetingInstructions" id="meetingInstructions" value={meetingInstructions} onChange={(e)=>onTextAreaChange(e)}/>
                  </FormGroup>
                  <FormGroup>
                    <Label for="preparations">What do participants need to do before the class or bring to the class? (optional - max 300 char.)</Label>
                    <div><small><strong>Character Count: {textAreaCount.preparations}</strong></small></div>
                    <Input type="textarea" maxLength='300' name="preparations" id="preparations" value={preparations} onChange={(e)=>onTextAreaChange(e)}/>
                  </FormGroup>
                  <FormGroup>
                    <Label for="expectations">Explain what participants are expected to do. (optional - max 300 char.)</Label>
                    <div><small><strong>Character Count: {textAreaCount.expectations}</strong></small></div>
                    <Input type="textarea" maxLength='300' name="expectations" id="expectations" value={expectations} onChange={(e)=>onTextAreaChange(e)}/>
                  </FormGroup>
                  <FormGroup>
                    <Label for="learningGoals">What will participants learn here? (optional - max 300 char.)</Label>
                    <div><small><strong>Character Count: {textAreaCount.learningGoals}</strong></small></div>
                    <Input type="textarea" maxLength='300' name="learningGoals" id="learningGoals" value={learningGoals} onChange={(e)=>onTextAreaChange(e)}/>
                  </FormGroup>
                
                <FormGroup>
                  <Label for="ifClassMissed">What happens if participants miss a class or don't meet expectations? (optional - max 300 char.)</Label>
                  <div className='d-flex justify-content-end'>
                    <button type='button' onClick={()=>strictMissedClass()} className='btn btn-myschool-blue'>Strict</button>
                    <button type='button' onClick={()=>relaxedMissedClass()} className='btn btn-myschool-blue'>Relaxed</button>
                    <button type='button' onClick={()=>okMissedClass()} className='btn btn-myschool-blue'>No Pressure</button>
                  </div>
                  <div><small><strong>Character Count: {textAreaCount.ifClassMissed}</strong></small></div>
                  <Input type="textarea" maxLength='300' name="ifClassMissed" id="ifClassMissed" value={ifClassMissed} onChange={(e)=>onTextAreaChange(e)}/>
                </FormGroup>

                <FormGroup>
                  <Label for="uponCompletion">Do participants receive anything upon successful completion of the class? (optional - max 300 char.)</Label>
                  <div><small><strong>Character Count: {textAreaCount.uponCompletion}</strong></small></div>
                  <Input type="textarea" maxLength='300' name="uponCompletion" id="uponCompletion" value={uponCompletion} onChange={(e)=>onTextAreaChange(e)}/>
                </FormGroup>
              </div>
              }
            <FormGroup className='mb-4'>
              <Label for="subjectEntry">What subjects can they learn about here? (Comma separated subjects. Ex "math, science")</Label>
              <div><small>Optional, but this allows your event be found when members filter for a specific subject.</small></div>
              <InputGroup>
                <Input type="text" name="subjectEntry" id="subjectEntry" value={subjectEntry} onChange={(e)=>changeSubjectEntry(e)} onKeyPress={(e)=>handleSubjectKeyPress(e)}/>
                <InputGroupAddon addonType="append" >
                  <button type='button' className='append-form-button' value={subjectEntry} onClick={(e)=>handleSubjectButton(e)}>Add item</button>
                </InputGroupAddon>
              </InputGroup>
              {subjects.map((subject, index) =>
                (
                  <span key={index} className='btn btn-myschool-red'>
                    {subject}
                    {' '}
                    <button type='button' className='basic-button' onClick={()=>onDeleteSubject(subject)}><i className='fas fa-times color-white'/></button>
                  </span>
                ))
              }
            </FormGroup>
            <Row className='justify-content-between'>
              <button type='button' className='btn btn-myschool-blue btn-set-WH' onClick={()=>sect2()}>Back</button>
              <button 
                type='button' 
                className='btn btn-myschool-red btn-set-WH' 
                onClick={()=>setShowPreview(true)}>
                  Preview
                </button>
              <button type="button" onClick={e =>onSubmit(e)} disabled={ isSubmitting } className='btn btn-myschool-blue btn-set-WH'>
                <span>
                  { !isSubmitting ?
                    `Submit`
                    :
                    <Spinner size="sm" className='color-white'/>
                  }
                </span>
              </button>
            </Row>
          </div>
        }
      </Form>


      <Modal isOpen={showModalStripeConnect} toggle={() => toggleModalStripeConnect(!showModalStripeConnect)}>
        <ModalHeader className='justify-content-end'>
            <button className="basic-button" onClick={() => toggleModalStripeConnect(false)}>
            <i className='fa fa-times color-light-grey'/>
            </button>
        </ModalHeader>
        <ModalBody className='text-center'>
          <h4 className='text-bold'>MySchool uses Stripe to process payments and put the money right into your bank account!</h4>
          <div className='mt-3 mb-2'>Connect your account to use MySchool's booking tools, send automated email confirmations, track bookings through your dashboard, collect payments, and get paid directly to your bank account!</div>
          <div className='mb-4'>
            <button className='basic-button' onClick={()=>connectAccountButton()}>
              <img src={ConnectStripeButton} width='200px' alt='Create Stripe Connect Account'/>
            </button>
          </div>
        </ModalBody>
      </Modal>

    </Fragment>
  );
}

EventForm.defaultProps = {
  editMode: false,
  copyMode: false,
  disableDueToBookings: false
};

export default EventForm;

