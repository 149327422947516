import React, { Fragment, useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createResource } from '../../actions/resource';
import { getMyProviders } from '../../actions/provider';
import { Container, Form, Row, Col, FormGroup, Label, Input, Tooltip, Spinner } from 'reactstrap';
import SpinnerComponent from '../layout/Spinner';
import capitalizeName from '../../utils/capitalizeName';
import ResourceForm from './ResourceForm';

const CreateResource = ({ createResource, resource: { resourceLoading, resources }, getMyProviders, provider: { providerLoading, providers } }) => {

  const history = useHistory();

  const [formData, setFormData] = useState({
    creditedTo: '',
    category: '',
    resourceName: '',
    email: '',
    address_full: '',
    address: '',
    city:'',
    state:'',
    zip:'',
    educationalLeader: '',
    subjectOfferings: '',
    religiousAffiliation: '',
    genInfo: '',
    typicalDay: '',
    whenOperate: '',
    website: '',
    phone: ''
  });

  const [ tagData, setTagData ] = useState([]);

  const [ subjData, setSubjData ] = useState({
      math: false,
      english: false,
      science: false,
      'social studies': false,
      art: false,
      music: false,
      pe: false,
      health: false,
      history: false,
      'foreign language': false,
      engineering: false,
      'environmental science': false
  });

  const onSubjChange = e => 
    setSubjData({ ...subjData, [e.target.name]: e.target.checked });

  const onSubmit = async e => {
    e.preventDefault();
    setIsSubmitting(true);
    const subjArray = Object.keys(subjData).filter(key=>subjData[key] === true);
    const allData = {...formData, subjects: subjArray, tags: tagData };
    await createResource(allData, history);
    setIsSubmitting(false);
  };

  const [ isSubmitting, setIsSubmitting ] = useState(false);

  useEffect(()=> {
    getMyProviders();
  }, []);


  return providerLoading || !providers ?
    <SpinnerComponent />
   : (
    <Fragment>
      <Container>
        <h1 className='large text-primary-ms'>Suggest a Spot</h1>
        <small>* required fields</small>
        <ResourceForm
          formData={formData}
          setFormData={setFormData}
          subjData={subjData}
          setSubjData={setSubjData}
          tagData={tagData}
          setTagData={setTagData}
          isSubmitting={isSubmitting}
          onSubmit={onSubmit}
          onSubjChange={onSubjChange}
          history={history}
          providers={providers}
        />
      </Container>
    </Fragment>
  );
};

CreateResource.propTypes = {
  createResource: PropTypes.func.isRequired,
  getMyProviders: PropTypes.func.isRequired,
  resource: PropTypes.object.isRequired,
  provider: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  resource: state.resource,
  provider: state.provider
});

export default connect(
  mapStateToProps,
  { createResource, getMyProviders }
)(CreateResource);
