import api from '../utils/api';
import {
  USER_FULL_DATA_LOADED,
  UPDATE_USER_FOLLOWS,
  UPDATE_USER_FOLLOWERS,
  USER_ERROR
} from './types';

//GET all map point favorites
export const getUserData = () => async dispatch => {
  try {
    const res = await api.get('/users/allUserData');

    dispatch({
      type: USER_FULL_DATA_LOADED,
      payload: res.data
    });

  } catch (err) {
    dispatch({
      type: USER_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  };
};

//PUT new follow in user and leader's user docs
export const updateFollow = () => async dispatch => {
  try {
    const res = await api.put('/users/follow/profile/:userId');
    console.log(res.data);
    // dispatch({
    //   type: UPDATE_USER_FOLLOWS,
    //   payload: res.data.follower
    // });

    // dispatch({
    //   type: UPDATE_USER_FOLLOWERS,
    //   payload: res.data.leader
    // });

  } catch (err) {
    dispatch({
      type: USER_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  };
};