import React, { useEffect, useState, Fragment } from "react";
import { Form, Container, Row, Col, Label, FormGroup, CustomInput, Input, Modal, ModalBody, Button, Spinner, Alert } from 'reactstrap';
import ProviderFormMapWrapper from './geoLocation/ProviderFormMapWrapper';
import ProviderForm from './ProviderForm';
import queryString from 'query-string';
import api from '../../utils/api';
import { useHistory } from "react-router-dom";

const CreateProviderPreGeolocate = () => {

  const history = useHistory();
  //Local alerts
  const [ alertArray, setAlertArray ] = useState([]);

  //Sections
  const [ showSection1, toggleSection1 ] = useState(true);
  const [ showSection2, toggleSection2 ] = useState(false);
  const [ showSection3, toggleSection3 ] = useState(false);

  //Section Actions
  const [ isSuccess1, setIsSuccess1 ] = useState(false);

  const goToSection1 = () => {
    toggleSection1(true);
    toggleSection2(false);
    toggleSection3(false);
  };

  const goToSection2 = () => {
    toggleSection1(false);
    toggleSection2(true);
    toggleSection3(false);
  };

  const goToSection3 = () => {
    toggleSection1(false);
    toggleSection2(false);
    toggleSection3(true);
  };

  const [displayModalEdPhil, toggleModalEdPhil] = useState(false);

  const [ mapMeData, setMapMeData] = useState({
    address: '',
    city:'',
    state:'',
    zip:'',
  });

  const [formData, setFormData] = useState({
    category: '',
    providerName: '',
    email: '',
    address: '',
    city:'',
    state:'',
    zip:'',
    lat:'',
    long:'', 
    hideAddress: false,
    edPhil: '',
    edAndEx:'',
    parentInvolveLevel: '',
    parentInvolveNarr: '',
    meetingFreqNum: '',
    meetingFreqUnit: '',
    meetingFreqNarr: '',
    slotsTotal: '',
    slotsPrefilled: '',
    userMembers: '',
    subjectOfferings: '',
    guidelines: '',
    enrollFee: '',
    enrollFeeUnit: '',
    isDropOff: '',
    genInfo: '',
    ageStart: '',
    ageEnd: '',
    educationalLeader: '',
    entranceFee: '',
    religiousAffiliation: '',
    website: '',
    phone: '',
    subjects: [],
    tags: [],
    twitter: '',
    facebook: '',
    linkedin: '',
    youtube: '',
    instagram: ''
  });

  const [ tagData, setTagData ] = useState([]);

  const [ subjData, setSubjData ] = useState({
    math: false,
    english: false,
    science: false,
    'social studies': false,
    art: false,
    music: false,
    pe: false,
    health: false,
    history: false,
    'foreign language': false,
    engineering: false,
    'environmental science': false
  });

  useEffect(() => {
    setFormData({
      ...formData,
      address: mapMeData.address,
      city: mapMeData.city,
      state: mapMeData.state,
      zip: mapMeData.zip,
    });
  }, [mapMeData]);

  const onSubjChange = e => 
    setSubjData({ ...subjData, [e.target.name]: e.target.checked });

  useEffect(() => {
    setFormData({
      ...formData,
      category: queryString.parse(history.location.search).category && queryString.parse(history.location.search).category.length > 0 ? queryString.parse(history.location.search).category: 'individual'
    })
  },[history]);

  const onChange = e =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onChangeMapMe = e =>
    setMapMeData({ ...mapMeData, [e.target.name]: e.target.value });

  const onCheckedChange = e => 
    setFormData({ ...formData, [e.target.name]: e.target.checked });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = async e => {
    e.preventDefault();
    setIsSubmitting(true);
    const subjArray = Object.keys(subjData).filter(key=>subjData[key] === true);
    const allData = {...formData, subjects: subjArray, tags: tagData };
    try {
      const res = await api.post('/provider', formData);
      setAlertArray({msg:'Provider Created'});
      setTimeout(()=> {setAlertArray([])},5000);
      window.location.reload();
    } catch (err) {
      if (err && err.response && err.response.data && err.response.data.errors) {
        const errors = err.response.data.errors;
        window.scrollTo(0,0);
        errors.forEach(error => setAlertArray([...alertArray, {msg: error.msg}]));
      } else {
        setAlertArray({msg:'Provider Created'});
        setTimeout(()=> {setAlertArray([])},5000);
      }
      setIsSubmitting(false);
    }
  };

  const mapMe = async () => {
    setIsSubmitting(true);
    try {
      const jsonMapMeData = JSON.stringify(mapMeData);
      // console.log(jsonMapMeData);
      const res = await api.post('/profile/mapMeForm', jsonMapMeData);
      if (res.status === 200) {
        setIsSubmitting(false);
        setFormData({
          ...formData,
          lat: res.data.indivLat,
          long: res.data.indivLong
        })
        setIsSuccess1(true);
      }
    } catch (err) {
      setIsSubmitting(false);
      setIsSuccess1(false);
      setIsSubmitting(false);
      setIsSuccess1(false);
      if (err.response && err.response.data && err.response.data.errors) {
          const errors = err.response.data.errors;
          setAlertArray(errors);
          setTimeout(()=> {setAlertArray([])},5000);
      } else {
        setAlertArray([{msg: "error"}])
        setTimeout(()=> {setAlertArray([])},5000);
      }
    }

  }


  return (
    <Fragment>
      <Container>
        <h1 className='large text-primary-ms'>Create Your Spot</h1>
        {alertArray.length > 0 ? 
          alertArray.map((alert, index) =>
              <Alert key={index} className='modal-alert'>
                {alert.msg}
              </Alert>
            )
          : ''
        }
        { showSection1 ?
          <Fragment>
            { !isSuccess1 ?
              <div className='text-left'>
                <Form>
                  <FormGroup>
                    <Label className='text-bold'>We use the address to drop a pin on the map.  You'll be able to see and change it before submitting.  You can even hide your address on your public profile if you like.</Label>
                    <Input type="text" 
                      name="address" 
                      id="address" 
                      placeholder="Street Address: 1234 Main St"
                      value={mapMeData.address}
                      onChange={e => onChangeMapMe(e)} 
                    />
                  </FormGroup>
                  <FormGroup>
                    <Input type="text" 
                    name="city" 
                    id="city" 
                    placeholder="City"
                    value={mapMeData.city}
                    onChange={e => onChangeMapMe(e)} 
                    />
                  </FormGroup>
                  <Row form>
                    <Col md={8}>
                      <FormGroup>
                        <Input type="select" 
                        name="state" 
                        id="state" 
                        placeholder="State (required)"
                        value={mapMeData.state}
                        onChange={e => onChangeMapMe(e)}
                        >
                          <option value="None">Select State...</option>
                          <option value="AL">Alabama</option>
                          <option value="AK">Alaska</option>
                          <option value="AZ">Arizona</option>
                          <option value="AR">Arkansas</option>
                          <option value="CA">California</option>
                          <option value="CO">Colorado</option>
                          <option value="CT">Connecticut</option>
                          <option value="DE">Delaware</option>
                          <option value="DC">District Of Columbia</option>
                          <option value="FL">Florida</option>
                          <option value="GA">Georgia</option>
                          <option value="GU">Guam</option>
                          <option value="HI">Hawaii</option>
                          <option value="ID">Idaho</option>
                          <option value="IL">Illinois</option>
                          <option value="IN">Indiana</option>
                          <option value="IA">Iowa</option>
                          <option value="KS">Kansas</option>
                          <option value="KY">Kentucky</option>
                          <option value="LA">Louisiana</option>
                          <option value="ME">Maine</option>
                          <option value="MD">Maryland</option>
                          <option value="MA">Massachusetts</option>
                          <option value="MI">Michigan</option>
                          <option value="MN">Minnesota</option>
                          <option value="MS">Mississippi</option>
                          <option value="MO">Missouri</option>
                          <option value="MT">Montana</option>
                          <option value="NE">Nebraska</option>
                          <option value="NV">Nevada</option>
                          <option value="NH">New Hampshire</option>
                          <option value="NJ">New Jersey</option>
                          <option value="NM">New Mexico</option>
                          <option value="NY">New York</option>
                          <option value="NC">North Carolina</option>
                          <option value="ND">North Dakota</option>
                          <option value="OH">Ohio</option>
                          <option value="OK">Oklahoma</option>
                          <option value="OR">Oregon</option>
                          <option value="PA">Pennsylvania</option>
                          <option value="Puerto Rico">Puerto Rico</option>
                          <option value="RI">Rhode Island</option>
                          <option value="SC">South Carolina</option>
                          <option value="SD">South Dakota</option>
                          <option value="TN">Tennessee</option>
                          <option value="TX">Texas</option>
                          <option value="UT">Utah</option>
                          <option value="VT">Vermont</option>
                          <option value="VA">Virginia</option>
                          <option value="U.S. Virgin Islands">U.S. Virgin Islands</option>
                          <option value="WA">Washington</option>
                          <option value="WV">West Virginia</option>
                          <option value="WI">Wisconsin</option>
                          <option value="WY">Wyoming</option>
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col md={4}>
                      <FormGroup>
                        <Input 
                          type="text" 
                          name="zip" 
                          id="zip" 
                          placeholder="Zip"
                          value={mapMeData.zip}
                          onChange={e => onChangeMapMe(e)} 
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className='text-center'>
                    <Col>
                      <Button type="button" disabled={isSubmitting} className="btn btn-modal-blue btn-set-WH-long" onClick={()=>mapMe()}>
                        <span>
                          {isSubmitting ? (
                            <Spinner size="sm" className='color-white'/>
                          ) : (
                            "Map Me!"
                          )}
                        </span>
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </div>
            : 
              <Fragment>
                <ProviderFormMapWrapper formData={formData} setFormData={setFormData}/>
                <button type='button' className='btn btn-modal-blue my-1 float-left' disabled={ isSubmitting } onClick={()=>setIsSuccess1(false)}>Back</button>
                <button type='button' className='btn btn-modal-blue my-1 float-right' disabled={ isSubmitting } onClick={()=>goToSection2()}>Next</button>
              </Fragment>
            }
          </Fragment>
        :''}
        
            
              
        { showSection2 ? (
            <Fragment>  
              <small>*required</small> 
              <ProviderForm
                formData={formData}
                setFormData={setFormData}
                subjData={subjData}
                setSubjData={setSubjData}
                tagData={tagData}
                setTagData={setTagData}
                isSubmitting={isSubmitting}
                onSubmit={onSubmit}
                onSubjChange={onSubjChange}
                history={history}
              />
            </Fragment>
          ):''
        }

        {/* showSection3 ? 

          <Fragment>
            <h4>Add Your Group's Social Media Links</h4>
              <div className='form-group social-input'>
                <i className='fab fa-twitter fa-2x' />
                {' '}
                <Input
                  type='text'
                  placeholder='https://twitter.com/...'
                  name='twitter'
                  value={twitter}
                  onChange={e => onChange(e)}
                />
              </div>

              <div className='form-group social-input'>
                <i className='fab fa-facebook fa-2x' />
                {' '}
                <Input
                  type='text'
                  placeholder='https://facebook.com/...'
                  name='facebook'
                  value={facebook}
                  onChange={e => onChange(e)}
                />
              </div>

              <div className='form-group social-input'>
                <i className='fab fa-youtube fa-2x' />
                {' '}
                <Input
                  type='text'
                  placeholder='https://youtube.com/...'
                  name='youtube'
                  value={youtube}
                  onChange={e => onChange(e)}
                />
              </div>

              <div className='form-group social-input'>
                <i className='fab fa-linkedin fa-2x' />
                {' '}
                <Input
                  type='text'
                  placeholder='https://linkedin.com/...'
                  name='linkedin'
                  value={linkedin}
                  onChange={e => onChange(e)}
                />
              </div>

              <div className='form-group social-input'>
                <i className='fab fa-instagram fa-2x' />
                {' '}
                <Input
                  type='text'
                  placeholder='https://instagram.com/...'
                  name='instagram'
                  value={instagram}
                  onChange={e => onChange(e)}
                />
              </div>
              <div className='float-left'>
                <Button type="button" disabled={isSubmitting} className="btn btn-modal-blue btn-set-WH-long" onClick={()=>goToSection2()}>
                  Back
                </Button>
              </div>
              <div className='float-right'>
                <Button type="submit" disabled={isSubmitting } className="btn btn-modal-blue btn-set-WH-long">
                  <span>
                    {isSubmitting ? (
                      <Spinner size="sm" className='color-white'/>
                    ) : (
                      "Submit"
                    )}
                  </span>
                </Button>
              </div>
            </Fragment>
          :''
        */}
      </Container>

    </Fragment>
  );
};


export default CreateProviderPreGeolocate;