import {
  GET_RESOURCE_REVIEWS,
  ADD_RESOURCE_REVIEW,
  RESOURCE_REVIEW_DELETED,
  RESOURCE_REVIEW_ERROR
} from '../actions/types';

const initialState = {
  resourceReviews: [],
  resourceReviewsLoading: true,
  error: {}
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_RESOURCE_REVIEWS:
      return {
        ...state,
        resourceReviews: payload,
        resourceReviewsLoading: false
      };
    case ADD_RESOURCE_REVIEW:
      return {
        ...state,
        resourceReviews: [payload, ...state.resourceReviews],
        resourceReviewsLoading: false
      };
    case RESOURCE_REVIEW_DELETED:
      return {
        ...state,
        resourceReviews: state.resourceReviews.filter(review => review._id !== payload),
        resourceReviewsLoading: false
      };
    case RESOURCE_REVIEW_ERROR:
      return {
        ...state,
        resourceReviewsLoading: false,
        error: payload
      };
    default:
      return state;
  }
}
