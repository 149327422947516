import React, { Fragment, useState, useEffect } from "react";
import { useHistory, Redirect, useParams } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { Container, FormGroup, Input, Label, Modal, ModalHeader, ModalBody } from 'reactstrap';
import api from '../../utils/api';
import SpinnerComponent from '../layout/Spinner';
import { setAlert } from '../../actions/alert';
import AuthModals from '../auth/AuthModals'; 
import checkBlue from '../../images/check-blue_640-640.svg';
import AddChild from '../child/AddChild';

const ProviderToPortfolio = ({ auth: {loading, user, isAuthenticated}, setAlert }) => {

  const history = useHistory();
  const params = useParams();

  //Must login/register
  const [ loginModal, toggleLoginModal ] = useState(false);
  const [ registerModal, toggleRegisterModal ] = useState(false);

  const [ providerSuccess, setProviderSuccess ] = useState(false);
  const [ providerFailure, setProviderFailure] = useState(false);

  const [providerInfo, setProviderInfo] = useState({});
  const [childInfo, setChildInfo] = useState([]);
  const [ selChild, setSelChild ] = useState([]);
  const [ childrenAdded, setChildrenAdded] = useState({status:false});

  const checkProvider = async () => {
    setProviderSuccess(false);
    setProviderFailure(false);
    try {
      const providerLookup = await api.get(`/v1/portfolio/myschoolplus/provider/${params.providerId}`);
      console.log(providerLookup.data);
      setProviderInfo(providerLookup.data.data.provider);
      setChildInfo(providerLookup.data.data.children);
      setProviderSuccess(true);
    } catch (err) {
      console.log(err);
      setAlert('Error.  Please try again later.', 'danger');
      setProviderFailure(true);
    } 
  };

  useEffect(()=> { 
    if (isAuthenticated) {
      checkProvider()
    }
  }, [isAuthenticated]);

  useEffect(()=> { 
    if (!isAuthenticated) {
      toggleLoginModal(true);
    }
  }, []);

  const onChange =(e)=> {
    // check if the check box is checked or unchecked
    if (selChild.includes(e.target.name) === false) {
      setSelChild([...selChild, e.target.name]);
    } else {
      const newArray = selChild.filter(el=> el !== e.target.name);
      setSelChild(newArray);
    }
  }

  const [childNames, setChildNames] = useState([]);
  const submitEntries = async () => {
    const data = {
      children: selChild,
      provider: providerInfo,
      duration: 120,
      description: `Visit to ${providerInfo.providerName}`
    };
    if (providerInfo.subjects && providerInfo.subjects.length > 0 ) {
      data.subjects = providerInfo.subjects;
    }

    const addedChildren = await api.post(`/v1/portfolio/myschoolplus/entry`,data);
    
    setChildrenAdded({status:true, count: addedChildren.data.count});
  };
  
  const [showAddChild, setShowAddChild] = useState(false);

  // //Redirect if logged in and user loaded
  // if(isAuthenticated && user && user._id) {
  //   return <Redirect to='/dashboard' />;
  // }

  return isAuthenticated && !providerSuccess && !providerFailure ? (
      <SpinnerComponent />
    ) : isAuthenticated && providerSuccess && !childrenAdded.status ? (
      <Container className='text-center'>
        <h2>
          {providerInfo && providerInfo.providerName ? `Welcome to ${providerInfo.providerName}!`:`Welcome!`}
        </h2>
        <div className='msstd-form-primary-border my-3'>
          <h4 className='mb-2'>Who is joining you?</h4>
          {childInfo && childInfo.length > 0 ? 
            <div> 
              <div><button type='button' onClick={()=>setShowAddChild(true)} className='btn btn-myschool-blue-border'>+ Add Child</button></div>
              <div>
                { childInfo.map(c=> 
                    <Label check key={c._id} className='child-input'>
                      <Input type="checkbox" name={c._id} checked={selChild.includes(c._id)} onChange={e => onChange(e)}/>
                      {`${c.name}`}
                    </Label>
                  )
                }
              </div>
              <button type='button' onClick={()=>submitEntries()} className='btn btn-myschool-blue my-2' disabled={selChild.length < 1}>Submit</button>
            </div>
            : <div>No child info available</div>
          }
          <Modal isOpen={showAddChild} centered toggle={() => setShowAddChild(!showAddChild)}>
            <ModalHeader className='content-justify-center'>
              <div className='float-right'>
                <button className="basic-button" onClick={() => setShowAddChild(!showAddChild)}>
                <i className='fa fa-times color-light-grey'/>
                </button>
              </div>
              Add a child
            </ModalHeader>
            <ModalBody className='text-center'>
              <AddChild />
            </ModalBody>
            <button className='btn btn-modal-blue content-justify-right' onClick={()=>setShowAddChild(false)}>Skip / Done</button>
          </Modal>
        </div>
      </Container>
    )
    : isAuthenticated && providerSuccess && childrenAdded.status ? (
        <div className='text-center'>
          <div>
            <img src={checkBlue} width='70px' alt='check step 1' className='fadeIn-slow'/>
          </div>
          <h4>
            { childrenAdded.count > 1 ? 
              `Success! Portfolio entries added for ${childrenAdded.count} children.`
              : `Success! Portfolio entry added for ${childrenAdded.count} child.`
            }
          </h4>
          <div>(You may now close this tab.)</div>
        </div>
      )
    : isAuthenticated && providerFailure ? (
      <Container className='text-center'>
        <h2>
          Error!
        </h2>
        <div> 
          <button type='button' onClick={()=>checkProvider()} className='btn btn-myschool-red'>Try Again</button>
        </div>
      </Container>
    ) : !isAuthenticated ? (
      <Container  className='text-center'>
        <AuthModals loginModal={loginModal} toggleLoginModal={toggleLoginModal} registerModal={registerModal} toggleRegisterModal={toggleRegisterModal} />
        <button className='btn btn-myschool-blue my-5' type='button' onClick={()=>toggleLoginModal(true)}>Please Log In</button>
      </Container>
    ) : (
      <Container className='text-center'>
        <h2>Error: Please try again later.</h2>
      </Container>
    )
};

ProviderToPortfolio.propTypes = {
  auth: PropTypes.object.isRequired,
  setAlert: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});


export default connect(mapStateToProps, { setAlert })(ProviderToPortfolio);
