import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { Container, Modal, ModalBody } from 'reactstrap';
import Spinner from '../layout/Spinner';
import ProgramItem from './ProgramItem';
import api from '../../utils/api';
import axios from 'axios';
import queryString from 'query-string';
// import { getPrograms } from '../../actions/lesson';
// import ProgramFilters from './ProgramFilters';
// const Programs = ({ getPrograms, program: { programs, programLoading } }) => {

const Programs = () => {

  //Modal
  const [ addProgramModal, toggleProgramModal ] = useState(false);

  //Pagination
  const [ page, setPage ] = useState(0);

  //Sort
  const [ sort, setSort ] = useState(0);

  //Filters
  const [dropdownOpenSort, toggleDropdownSort] = useState(false);
  const [dropdownOpenCost, toggleDropdownCost] = useState(false);
  const [dropdownOpenAge, toggleDropdownAge] = useState(false);
  const [dropdownOpenSubject, toggleDropdownSubject] = useState(false);

  const [filterData, setFilterData] = useState({
    dist: 50,
    subject: undefined,
    age: undefined
  });

  const [currentFilters, setCurrentFilters] =  useState({
    isFree: '',
    subject: '',
    age: ''
  });

  // const handleNextClick = () => {
  //   setPage(page*1+1);
  // };
  

  // const handlePrevClick = () => {
  //   setPage(page*1-1);
  // };
  

  // const handleUpdateQuery = () => {
  //   getPrograms(filterData);
  //   setCurrentFilters({
  //     isFree: filterData.isFree,
  //     subject: filterData.subject,
  //     age: filterData.age
  //   });
  // };

  const history = useHistory();
  const location = useLocation();
  function useQuery() {
    return new URLSearchParams(location.search);
  };
  const query = useQuery();
  const queryObj = {};
  // for (const [key, value] of query.entries()) {
  //   queryObj[key] = value;
  // }
  let locationSearch = location.search ? queryString.parse(location.search) : '';
 
  //MAP STATE
  const [currentLatLong, setCurrentLatLong] = useState({
    lat: 39.3,
    long: -76.6
  });

  const [userLatLong, setUserLatLong] = useState({
    lat: 39.3,
    long: -76.6
  });

  const [currentZoom, setCurrentZoom] = useState(4);
  // const [bounds, setBounds] = useState({
  //   latMin: 39,
  //   latMax: 40,
  //   longMin: -78,
  //   longMax: -75
  // });

  //Set location of map load and find events there
  const [ nearestCity, setNearestCity ] = useState('')
  const [isLocationLoaded, setLocationLoaded ] = useState(false);

  useEffect(() => {
    
    navigator.geolocation.getCurrentPosition(async (position) => {
        (locationSearch && locationSearch.lat && locationSearch.long) ? (
              setCurrentLatLong({ lat: locationSearch.lat, long: locationSearch.long})
            ) : (
              setCurrentLatLong({ lat: position.coords.latitude, long: position.coords.longitude })
        );
        setUserLatLong({ lat: position.coords.latitude, long: position.coords.longitude });
        (locationSearch && locationSearch.zoom) ? setCurrentZoom(15) : setCurrentZoom(10);
        setLocationLoaded(true);
      }, async () => {
        window.alert('You blocked your location, which is totally cool with us. We just use it to set up the starting position for the map. We\'ll center the map in your general area instead.');
        // below is for IP address location services, but Macs can prevent this
        try {
          const ipAddress =  await axios.get('https://ipapi.co/json');
          let userIpLocation = { lat: ipAddress.data.latitude, long: ipAddress.data.longitude};
          setNearestCity(`${ipAddress.data.city}, ${ipAddress.data.region_code} ${ipAddress.data.country}`);
          setCurrentLatLong(userIpLocation);
          setUserLatLong(userIpLocation);
          setCurrentZoom(10);
          setLocationLoaded(true);
        } catch (err) {
          window.alert('You blocked all location services, which is totally cool with us. We just use it to search near you, but you can search manually too! (Re-enable location services anytime so you don\'t have to do that!)');
          const noLocation = { lat: 0, long: 0 };
          setCurrentLatLong(noLocation);
          setUserLatLong(noLocation);
          setCurrentZoom(1);
          setLocationLoaded(true);
        }
      }
    );
  }, []);

  const [programs, setPrograms] = useState([]);
  const [programCount, setProgramCount] = useState(0);
  const getPrograms = async () => {
    try {
      const allPrograms = await api.get(`/v1/programs/${currentLatLong.lat}/${currentLatLong.long}/${filterData.dist}?${query.toString()}`);
      // console.log(allPrograms);
      setPrograms(allPrograms.data.data);
      setProgramCount(allPrograms.data.count);
      setFilterData({...filterData, dist: allPrograms.data.searchRadius});
      setNearestCity(`${allPrograms.data.nearestCity.city}, ${allPrograms.data.nearestCity.state}, ${allPrograms.data.nearestCity.country} `)
    } catch (err) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    if (isLocationLoaded) {
      getPrograms();
    }
  }, [isLocationLoaded]);

  const clearFilters = () => {
    setFilterData({
      dist: 50,
      subject: undefined,
      age: undefined
    });
    history.push('/field-trip-programs');
    query.delete('resource');
    getPrograms();
  }

  return !programs ? (
        <Spinner />
      ) : (
      <Fragment>
        <Container>
          <h1 className='large text-primary-ms'>Programs Near You</h1>
          <div>
            <div className='filter-program'>
              {`Showing ${programCount} results within `}<input type='number' value={filterData.dist} onChange={(e)=>setFilterData({...filterData, dist: e.target.value})} className=''/>
              {` miles of ${nearestCity} `}
            </div>
          </div>
          <div className='d-flex justify-content-end'>
            <button type='button' className='btn btn-myschool-blue' onClick={()=>getPrograms()}>Update</button>
            <button type='button' className='btn btn-myschool-blue mx-1' onClick={()=>clearFilters()}>Reset</button>
            <div className='ml-auto'>
              <button onClick={()=>toggleProgramModal(!addProgramModal)} className='btn btn-rounded btn-myschool-red'>
                Add a Program
              </button>
            </div>
          </div>
          {/*<ProgramFilters 
            filterData={filterData} 
            setFilterData={setFilterData}
            sort={sort}
            setSort={setSort}
            dropdownOpenSort={dropdownOpenSort}
            toggleDropdownSort={toggleDropdownSort}
            dropdownOpenAge={dropdownOpenAge}
            toggleDropdownAge={toggleDropdownAge}
            dropdownOpenCost={dropdownOpenCost}
            toggleDropdownCost={toggleDropdownCost}
            handleUpdateQuery={handleUpdateQuery}
            currentFilters={currentFilters}
            dropdownOpenSubject={dropdownOpenSubject}
            toggleDropdownSubject={toggleDropdownSubject}
          />

          <div>
            <strong className='text-primary-ms'>Showing top {programs.length} results</strong>
          </div>
          */}
          <hr/>
         
            <div className='family-card-flex-set mx-1 my-2'>
              {programs.map(program => (
                  <ProgramItem key={program._id} program={program} />
                ))}
              {programs.length === 0 ? (<h6>No results match this search criteria</h6>) : ''}
            </div>
        </Container>

        <Modal isOpen={addProgramModal} centered toggle={()=>toggleProgramModal(!addProgramModal)}>
          <ModalBody className='text-center'>
            Email us at info@myschoolathome.io to be one of our early program hosts OR to suggest an awesome program that we should add!
          </ModalBody>
        </Modal>

      </Fragment>
    );
};

// Programs.propTypes = {
//   getPrograms: PropTypes.func.isRequired,
//   program: PropTypes.object.isRequired
// };

const mapStateToProps = state => ({
  program: state.program
});

export default connect(
  mapStateToProps,
  {  }
)(Programs);
