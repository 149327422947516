import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Col, Row, Button, Form, FormGroup, Label, Input, Spinner, Modal, ModalBody } from 'reactstrap';
import ImageContainer from '../../layout/ImageContainer';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import iconComingSoon from '../../../images/icon-coming-soon.png';
import storytimeChess from '../../../images/STC_all_components-square.png';
import wrappedPresent from '../../../images/wrapped-present-1.svg';

const Prizes = ({history}) => {

    const [ data, setData ] = useState({
        heading: "How To Play",
        subHeading1_1: "Just scan a QR code at any MySchool partner location, and MySchool will keep track of all the places you go to learn! Anyone can play and build their child's portfolio, and each scan from a MySchool+ member is a chance to win educational prizes, including a",
        subHeading1_2: "$1000 grand prize!",
        heading2: "What does it cost?",
        subHeading2_1: "The game is totally free for any families to collect and track your spots. Prizes are only available for MySchool+ members, but",
        subHeading2_2: "you can score a free year of MySchool+ by sharing your unique referral link!"
    });

    const [showBigGif, setShowBigGif] = useState(false);
    
    const [prizes, setPrizes] = useState([
        {
            title:"A $1000 Check", 
            description: "This is pretty straightforward. It's a check for $1000.",
            description2: "Like, real U.S. dollars to use however you see fit.",
            description3: "...because homeschoolers can stretch $1000 pretty amazingly far.",
            image: "https://images.unsplash.com/photo-1631514623720-7f0996f87b75?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=580&q=80",
            altText: "photo of 100 dollar bills"
        },
        {
            title:"4 tickets to the Fire Museum", 
            description: "4 tickets to visit our museum partner, the Fire Museum of Maryland! Explore the history, technology, and innovation of the fire service through a unique collection of operating apparatus, memorabilia, and programs.",
            image: "https://myschoolathome-profile-images.s3.amazonaws.com/provider-61893d69a4e9340e50d3c595.webp",
            altText: "photo of tickets to the Fire Museum"
        },
        {
            title:"Story Time Chess", 
            description: "Story Time Chess is the only game that teaches children as young as three how to play chess.",
            description2: "Using silly stories, vibrant illustrations, custom chess pieces, and a unique chessboard, Story Time Chess makes it possible for any parent to teach their kids the game—no chess experience required!",
            image: storytimeChess,
            altText: "Story Time chess set"
        },
        {
            title:"FIRST Robotics - DIY Robotics Kits", 
            description: "Not yet released, these are FIRST robotics brand new DIY robotics kits that families can do together! In fact, they're so new, we don't even have a picture yet!",
            description2: "If you win this prize you'll be sent one of these kits as soon as they come off the production line in the fall!",
            image: iconComingSoon,
            altText: "coming soon image"
        },
        {
            title:"...AND MORE!", 
            description: "We've reached out to other educational organizations to find more great, educational resources to offer too!",
            description2: "We'll update this list as more prizes get sent in!",
            image: wrappedPresent,
            altText: "wrapped gift"
        }
    ]);

    const altStorytimeChessObj = {
        title:"Story Time Chess", 
        descriptionClassName: "text-08rem my-1",
        description: "Story Time Chess is the only game that teaches children as young as three how to play chess. Using silly stories, vibrant illustrations, custom chess pieces, and a unique chessboard, Story Time Chess makes it possible for any parent to teach their kids the game—no chess experience required!",
        description2: "Story Time Chess includes: Storybook with illustrations, instructions, and exercises that teach piece movements — Double-sided chessboard for maximum playability — Custom chess pieces with character cutouts — Themed cloth storage bags",
        descriptionList: [
            "Storybook with illustrations, instructions, and exercises that teach piece movements",
            "Double-sided chessboard for maximum playability",
            "Custom chess pieces with character cutouts",
            "Themed cloth storage bags"
            ],
        description3:"Transform your family game night with the brain-boosting power of Story Time Chess's award-winning game set!",
        image: storytimeChess,
        altText: "Story Time chess set"
    }

    const [ seeIfReady1, setSeeIfReady1 ] = useState(false);

    const [ zoomInModal, toggleZoomInModal ] = useState(false);
    const [ showImg, setShowImg ] = useState({});

    const zoomInOnImage = (itemImg) => {
    toggleZoomInModal(!zoomInModal);
    setShowImg(itemImg);
    };


    const settings = {
        autoplay: true,
        autoplaySpeed: 4000,
        dots: true,
        arrows: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        pauseOnHover: true
    };

    return (
        <div className="my-3">
            <div>
              <Slider {...settings}>
               {prizes.map((item,i)=>{
                  return (
                    <div key={i} style={{margin:"auto"}} onClick={()=>zoomInOnImage(item)}>
                        <Row>
                            <div className="col-4">
                                <div className="d-flex justify-content-center align-content-middle">
                                   <img src={item.image} alt={item.altText} width="200px" className="cursor-pointer round-img"/>
                                </div>
                            </div>
                            <div className="col-8">
                                <div className="text-bold text-1-1rem">{item.title}</div>
                                <div className={item.descriptionClassName?item.descriptionClassName:"text-09rem my-1"}>{item.description}</div>
                                {item.description2 ?
                                    <div className={item.descriptionClassName?item.descriptionClassName:"text-09rem my-1"}>{item.description2}</div>
                                    :''
                                }
                                {item.description3 ?
                                    <div className={item.descriptionClassName?item.descriptionClassName:"text-09rem my-1"}>{item.description3}</div>
                                    :''
                                }
                            </div>
                        </Row>
                    </div>
                  )
                })
               }
              </Slider>
            </div>

            <Modal isOpen={zoomInModal} centered toggle={()=>toggleZoomInModal(!zoomInModal)}>
                <ModalBody className="text-center">
                  <img src={showImg.image} alt={showImg.altText} />
                </ModalBody>
            </Modal>
        </div>
    );
}

export default Prizes;