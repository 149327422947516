import React, { useState } from "react";
import ProfileImageUpload from '../dashboard/ProfileImageUpload';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

const ProfileImageContainer = props => {

  const [ displayModalPhotoUpload, toggleModalPhotoUpload] = useState(false);

  return (
    <div className="image-container mb-2">
      { props.auth.user._id === props.user._id ? 
        <img className={props.className} width={props.width} src={props.src} alt={props.alt} onClick={()=>toggleModalPhotoUpload(true)} />
        :
        <img className={props.className} width={props.width} src={props.src} alt={props.alt} />
      }
      
      <Modal isOpen={displayModalPhotoUpload} toggle={() => toggleModalPhotoUpload(!displayModalPhotoUpload)}>
        <ModalHeader className='content-justify-center'>
          <div className='float-right'>
            <button className="basic-button" onClick={() => toggleModalPhotoUpload(false)}>
            <i className='fa fa-times color-light-grey'/>
            </button>
          </div>
        </ModalHeader>
        <ModalBody>
          <ProfileImageUpload />
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ProfileImageContainer;