import api from '../utils/api';
import { setAlert } from './alert';
import queryString from 'query-string';
import {
  GET_PORTFOLIO_BY_CHILDID,
  GET_PORTFOLIO_STATS_BY_CHILDID,
  EDIT_PORTFOLIO_ENTRY,
  DELETE_PORTFOLIO_ENTRY,
  PORTFOLIO_ERROR,
  GET_CHILD
} from './types';
import dayjs from 'dayjs';


// Get all events
export const getChildPortfolio = (childId, initQuery) => async dispatch => {
  try {
    
    let queryObject = initQuery;
    //testing
    const query = queryString.stringify(queryObject);


    const res = await api.get(`/v1/portfolio/child/${childId}?${query}`);

    dispatch({
      type: GET_PORTFOLIO_BY_CHILDID,
      payload: res.data.data
    });

    dispatch({
      type: GET_PORTFOLIO_STATS_BY_CHILDID,
      payload: res.data.summaryData
    });

    dispatch({
      type: GET_CHILD,
      payload: res.data.child
    });

  } catch (err) {
    dispatch({
      type: PORTFOLIO_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Delete entry
export const deletePortfolioEntry = (entryId) => async dispatch => {
  try {
    console.log(entryId);
    //THIS IS NOT SET UP TO BE OPERATIONAL YET!

  } catch (err) {
    dispatch({
      type: PORTFOLIO_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Delete entry
export const editPortfolioEntry = (entryId, formData) => async dispatch => {
  try {

    const res = await api.put(`/v1/portfolio/entry/${entryId}`, formData);
    
    dispatch({
      type: EDIT_PORTFOLIO_ENTRY,
      payload: res.data
    });

  } catch (err) {
    console.error(err);
    dispatch({
      type: PORTFOLIO_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
