import React, { useState } from 'react';
import ReactGA from 'react-ga';
import { useHistory } from 'react-router-dom';
import BackButton from '../../utils/BackButton';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Badge, Table} from 'reactstrap';

const MySchoolPlusFreebies = ({auth: {isAuthenticated, loading, user}}) => {
    const history = useHistory();

    const [freebies, setFreebies] = useState([
        {
            title: 'Core Knowledge Foundation',
            subjects: ['English/Reading', 'Math', 'Science', 'Social Studies', 'History'],
            level: ["beginner", "intermediate"],
            description: 'The Core Knowledge Foundation has tons of quality, knowlege rich resources used in many public schools today.',
            link:'https://www.coreknowledge.org/'
        },
        {
            title: "Khan Academy",
            subjects: ["Math", "Science", "History", "English/Reading", "Economics", "Social Studies", "Computer Programming"],
            level: ["beginner", "intermediate", "advanced"],
            description: "With tons of free content on a range of subjects, and even tracking solutions for homeschoolers, this is a great all-around resource.",
            link: "https://learn.khanacademy.org"
        },
        {
            title: "Khan Academy Kids",
            subjects: ["Music", "Art", "Math Foundations"],
            level: ["beginner"],
            description: "For younger audiences, this provides quality, free, safe content for younger kids.",
            link: "https://learn.khanacademy.org/khan-academy-kids/"
        },
        {
            title: "Art for Kids Hub",
            subjects: ['Art'],
            level: ["beginner"],
            description: "For your beginning artists, these videos are quick, fun and meant for families to do together!",
            link: "https://www.youtube.com/user/ArtforKidsHub"
        },
        {
            title: 'MEP Math',
            subjects: ['Math'],
            level: ["beginner", "intermediate"],
            description: "This is a really high quality, free math curriculum.  It's not particularly user friendly or attractive, and it uses the Queen's English, but the content is great for ages 5-11 (Grades K-6, aka the British \"Reception - Year 6\")!",
            link: 'https://www.cimt.org.uk/projects/mepres/primary/index.htm'
        },
        {
            title: "Lunch Doodles with Mo Willems",
            subjects: ['Art'],
            level: ["beginner", "intermediate"],
            description: "For young, budding cartoonists, author and illustrator Mo Willems (creator of the \"Elephant and Piggie\" series) brings a series of great, quick, beginner animations.",
            link: "https://www.youtube.com/playlist?list=PL14hRqd0PELGbKihHuTqx_pbvCLqGbOkF"
        },
        {
            title: "Draw Every Day with JJK",
            subjects: ['Art'],
            description: "For more advanced artists, professional author and illustrator, Jarrett J. Krosoczka has a really cool series for young, enthusiastic cartoonists.",
            link: "https://www.youtube.com/studiojjk"
        },
        {
            title: "Duolingo",
            subjects: ["Foreign Language"],
            level: ["beginner", "intermediate", "advanced"],
            description: "A fun and engaging way to learn tons of new languages.",
            link: "https://www.duolingo.com/"
        },
        {
            title: "Bozeman Science",
            subjects: ["Science", "Physics", "Chemistry", "Biology"],
            level: ["intermediate", "advanced"],
            description: "Paul Andersen is the creator of these science videos, and they cover a whole range of advanced science courses.",
            link: "http://www.bozemanscience.com/"
        },
        {
            title: "Amoeba Sisters",
            subjects: ["Biology"],
            level: ["intermediate", "advanced"],
            description: "Quality videos to learn all about biology.",
            link: "https://www.youtube.com/playlist?list=PLwL0Myd7Dk1F0iQPGrjehze3eDpco1eVz"
        },
        {
            title: "Scratch",
            subjects: ["Technology", "Coding"],
            level: ["beginner", "intermediate"],
            description: "MIT created Scratch helps beginners learn to code.",
            link: "https://scratch.mit.edu/"
        },
        {
            title: "Marginal Revolution University",
            subjects: ["Economics"],
            level: ["advanced"],
            description: "Quality, entertaining economics content.",
            link: "https://mru.org/"
        },
        {
            title: "KidsHealth",
            subjects: ["Health"],
            level: ["beginner", "intermediate"],
            description: "Go to the \"For Educators\" section of the site and they have subjects to cover, information, quizes, etc.",
            link: "https://kidshealth.org/"
        },
        {
            title: "Cathy Duffy Reviews",
            subjects: ["Curriculum Reviews"],
            level: ["beginner", "intermediate", "advanced"],
            description: "A good place to go if you want to find a TON of curriculum options. It's borderline overwhelming, but a great curriculum repository.",
            link: "https://cathyduffyreviews.com/"
        },
        {
            title: "Mystery Science",
            subjects: ["Science"],
            level: ["beginner", "intermediate", "advanced"],
            description: "Science units and one off lessons about science. MS pairs videos with optional activities you can do at home.",
            link: "https://www.youtube.com/c/crashcourse"
        },
        {
            title: "Crash Course",
            subjects: ["Science" , "History", "Social Studies", "English", "Math", "Media"],
            level: ["intermediate", "advanced"],
            description: "Quality, easy to engage with and fun to watch, Crash Course offers videos covering a wide range of subjects. These videos can supplement any subject you're looking for!",
            link: "https://www.youtube.com/c/crashcourse"
        },
        {
            title: "Seterra Geography",
            subjects: ["Geography","Social Studies"],
            level: ["beginner","intermediate", "advanced"],
            description: "Online (or iOS/Android) geography lessons (...plus a few science and anatomy quizes that they're starting to produce).  Have fun and learn states, countries, flags, etc.",
            link: "https://www.seterra.com/"
        },
        {
            title: "GeoGuessr",
            subjects: ["Geography","Social Studies"],
            level: ["intermediate", "advanced"],
            description: "Online geography games. Get a street view from Google Maps of a famous location, walk around to try and figure out where you are, and locate that spot on a map. The free version gives you a time limit, but that may be the best thing for you.",
            link: "https://geoguessr.com/"
        },
        {
            title: "Chem with Amanda",
            subjects: ["Science","Chemistry"],
            level: ["beginner","intermediate", "advanced"],
            description: "Online chemistry video lessons, problem sets, and tests created by a professional chemist and chemistry professor. There is even a small section for young children with at-home experiments.",
            link: "http://www.chemwithamanda.com/"
        },
        {
            title: "Prodigy Math",
            subjects: ["Math"],
            level: ["beginner", "intermediate"],
            description: "It's like Pokemon, but you have to answer math problems! You're kids will WANT to practice their math and learn more so they can progress in the game.",
            link: "https://www.prodigygame.com"
        },
        {
            title: "Zearn Math",
            subjects: ["Math"],
            level: ["beginner", "intermediate"],
            description: "Online math video lessons, activities, and tests used by schools around the country, and available to families for free!",
            link: "https://www.zearn.org/"
        },
        {
            title: "Typing.com",
            subjects: ["Typing", "Digital Literacy", "Computer Programming"],
            level: ["beginner"],
            description: "Online typing curriculum, digital literacy, and basic computer coding with robust freemium features.",
            link: "https://www.typing.com/"
        },
        {
            title: "TypingClub.com",
            subjects: ["Typing"],
            level: ["beginner"],
            description: "Free, easy to use, online typing curriculum.",
            link: "https://www.typingclub.com/"
        },
        {
            title: "MIT Open Courseware",
            subjects: [""],
            level: ["advanced"],
            description: "For older students, these are amazing resources to feed the curious mind. Search by department and see tons of undergraduate and graduate level courses.  See video lectures, notes, assignments, exams, etc. used in actual classes at MIT!",
            link: "https://ocw.mit.edu/index.htm"
        },
        {
            title: "Euclidea Geometry",
            subjects: ["Math","Geometry"],
            level: ["intermediate", "advanced"],
            description: "Free, interactive puzzles to learn geometry. Available online and for mobile. They also have other games available for geometry and other advanced mathematical play.",
            link: "https://www.euclidea.xyz/"
        },
        {
            title: "Where on Google Earth is Carmen Sandiego?",
            subjects: ["Geography"],
            level: ["beginner","intermediate"],
            description: "Parents who grew up with Carmen Sandiego will be excited to know you can play the game for free on Google Earth! (...and your kids too of course).  Download the app and search for \"Carmen Sandiego\" or launch it on your desktop browser.  It's a fun way to learn about the world!",
            link: "https://experiments.withgoogle.com/where-on-earth"
        },
        {
            title: "EdX",
            subjects: [""],
            level: ["intermediate", "advanced"],
            description: "EdX allow you to audit thousands of courses from top universities for free!",
            link: "https://www.edx.org/"
        },
        // {
        //     title: "Stanford Online",
        //     subjects: [""],
        //     level: ["advanced"],
        //     description: "Stanford courses... free.",
        //     link: "https://online.stanford.edu/free-courses"
        // },
        // {
        //     title: "Harvard Extension",
        //     subjects: ["Art","Science","History","Music","Economics","Personal Finance"],
        //     description: "Havard Extension lets you audit actual Harvard courses. You must register for noncredit if it is available and be at least 15 years old at the time of registration. Courses that do not have a noncredit option may not be audited. Noncredit status means you may not take final exams or submit final projects. However, you are still expected to attend and participate in adherence to instructor expectations. In some courses instructors will accept and correct other written work from noncredit students, but they may not assign grades for that work. As a noncredit student, you do not receive final grades or academic credit, but the courses do appear on your transcript.",
        //     link: "https://www.edx.org/school/harvardx"
        // },
        {
            title: "Coursera",
            subjects: [""],
            level: ["intermediate", "advanced"],
            description: "Coursera allow you to audit thousands of courses for free. If you see the option saying \"You can audit this course for free\", you guessed it, all that content is available for free!  If you're unsure about the quality of the content, the MySchool site was originally built using audited classes on Coursera and other free online education platforms.",
            link: "https://www.coursera.org/"
        },
        {
            title: "Discover Your World With NOAA",
            description: "NOAA - The Oceanic and Atmospheric Administration created this free resources with tons of quality science resources and activites.",
            subjects: ["science"],
            level: ["beginner","intermediate", "advanced"], 
            link: "https://oceanservice.noaa.gov/education/discoveryourworld.html"
        },
        {
            title: "iCivics",
            description: "Online games and resources to learn more about civics in the United States.",
            subjects: ["social studies","civics"],
            level: ["intermediate", "advanced"], 
            link: "https://www.icivics.org/"
        },
        {
            title: "Pilotlight - The Food Education Center",
            description: "Common core aligned learning resource for home and school use to help kids learn more about the foods they eat, and where they come from.",
            subjects: ["health"],
            level: ["beginner","intermediate"], 
            link: "https://foodedu.pilotlightchefs.org/family-resources/"
        },        
        {
            title: "UC Boulder's PhET Interactive Science and Math Simulations",
            description: "PhET Interactive Simulations is a project at the University of Colorado Boulder that creates free interactive math and science simulations.",
            subjects: ["Math","Science"],
            level: ["beginner","intermediate", "advanced"], 
            link: "https://phet.colorado.edu/en/simulations/browse"
        },
        {
            title: "The Good and the Beautiful",
            description: "This is a Christian-based curriculum that offers full year math and language arts curriculums as downloadable PDFs for free.",
            subjects: ["Math","English"],
            level: ["beginner","intermediate"], 
            link: "https://www.goodandbeautiful.com/"
        },
        {
            title: "FitMoney Supersquad",
            description: "An online game to learn all about financial literacy.",
            subjects: ["Math","Financial Literacy"],
            level: ["beginner","intermediate"], 
            link: "https://www.fitmoney.org/supersquad"
        },
        // {
        //     title: "Easy Peasy Homeschool",
        //     description: "This is a Christian-based curriculum, and offers a variety of courses that can be custom built into a curriculum for each of your children.",
        //     subjects: ["Math","English","Science","Social Studies","History","Foreign Lanugage","Art", "Music","Physical Education","Health"],
        //     level: ["beginner","intermediate"], 
        //     link: "https://allinonehomeschool.com/"
        // },
        // {
        //     title: "Google Digital Garage",
        //     subjects: [""],
        //     description: "Thousands of courses for free.",
        //     link: "https://learndigital.withgoogle.com/digitalgarage/courses"
        // },
        // {
        //     title: "Alison",
        //     subjects: [""],
        //     description: ".",
        //     link: "https://alison.com/"
        // },
        // {
        //     title: "Jetpunk Quizes",
        //     subjects: ["Civics","Social Studies", "General Knowledge"],
        //     level: ["beginner","intermediate", "advanced"],
        //     description: "Quizes about... just about anything from academic subjects like civics and world geography to pop culture quizes on things like Harry Potter characters.",
        //     link: "https://www.seterra.com/"
        // },       
    ]);

    const [initData, setInitData] = useState({
        preHeading: "Premium",
        preHeadingspan: "Features",
        heading: "Free Educational Resources",
        headingText: "Learn more. Spend less.",
        headingTexttwo: "We have no affiliation with any of the groups below.  We just really like their stuff, and it's all available for free!"
    });

    // let allSubjects = [];
    // freebies.forEach(i=>allSubjects = [...new Set([...allSubjects, ...i.subjects])]);
    // console.log(allSubjects);

    return (
        <section id="features" className="section features-area style-two overflow-hidden ptb_100">
            <div className="container">
                <BackButton history={history} backTo='/myschoolplus' color='blue' textString='Back to MySchool Plus'/>
                <div className="row justify-content-center">
                    {/* Section Heading */}
                    <div className="section-heading px-3 text-center">
                        <span className="d-inline-block rounded-pill shadow border fw-5 px-4 py-2 mb-3">
                            <i className="far fa-lightbulb text-primary mr-1" />
                            <span className="text-primary">{initData.preHeading}</span>
                            {initData.preHeadingspan}
                        </span>
                        <h2>{initData.heading}</h2>
                        <div className="mt-4 text-bold">
                            {initData.headingText}
                        </div>
                        <div>
                            {initData.headingTexttwo}
                        </div>
                    </div>
                </div>
                
                <div>
                    {freebies.length > 0 ? 
                        <div>
                            <div>
                                <span className='color-myschool-red text-1-5rem'>
                                    {freebies.length}
                                </span>
                                {` free resources!`}
                                {' '}
                                <Badge className={`pill-color-beginner mr-1`} pill>b</Badge> - beginner
                                {' '}
                                <Badge className={`pill-color-intermediate mr-1`} pill>i</Badge> - intermediate
                                {' '}
                                <Badge className={`pill-color-advanced mr-1`} pill>a</Badge> - advanced
                            </div>
                            <Table hover className='table-basic'>
                                <thead>
                                    <tr>
                                        <th>Title</th>
                                        <th>Subjects</th>
                                        <th>Level</th>
                                        <th>Description</th>
                                        <th>Link</th>
                                    </tr>
                                </thead>
                                <tbody> 
                                    {isAuthenticated && user && user.subscriberStatusActive ? 
                                        freebies.map((item, idx) => {
                                            return (
                                                <tr key={`ffd_${idx}`} >
                                                    <td className='py-2'>{item.title}</td>
                                                    <td className='py-2'>
                                                        {item.subjects.map((s,i) => {
                                                              return <Badge key={i} className={`pill-color-${s.toLowerCase().replace(/\s+/g, '')} mr-1`} pill>{s}</Badge>
                                                            })
                                                        }             
                                                    </td>
                                                    <td className='py-2'>
                                                        {item.level ? item.level.map((l,i) => {
                                                              return <Badge key={i} className={`pill-color-${l.toLowerCase().replace(/\s+/g, '')} mr-1`} pill>{l.slice(0,1)}</Badge>
                                                            })
                                                        :''
                                                        } 
                                                    </td>
                                                    <td className='py-2'>{item.description}</td>
                                                    <td className='py-2'><a href={item.link} target="_blank" rel="noopener noreferrer" className='btn btn-myschool-blue-border btn-small'>{`Go to their website >>`}</a></td>
                                                </tr>
                                            );
                                        })
                                        :
                                        freebies.slice(0,4).map((item, idx) => {
                                            return (
                                                <tr key={`fsamp_${idx}`} >
                                                    <td className='py-2'>{item.title}</td>
                                                    <td className='py-2'>
                                                        {item.subjects.map((s,i) => {
                                                              return <Badge key={i} className={`pill-color-${s.toLowerCase().replace(/\s+/g, '')} mr-1`} pill>{s}</Badge>
                                                            })
                                                        }             
                                                    </td>
                                                    <td className='py-2'>
                                                        {item.level ? item.level.map((l,i) => {
                                                              return <Badge key={i} className={`pill-color-${l.toLowerCase().replace(/\s+/g, '')} mr-1`} pill>{l.slice(0,1)}</Badge>
                                                            })
                                                        :''
                                                        } 
                                                    </td>
                                                    <td className='py-2'>{item.description}</td>
                                                    <td className='py-2'><button onClick={()=>history.push('/myschoolplus/subscribe')} className='btn btn-myschool-blue-border btn-small'>{`Sign up to go there >>`}</button></td>
                                                </tr>
                                            );
                                        })
                                    }
                                </tbody>
                            </Table>
                        </div>
                        :<div><h2>Coming soon!</h2></div>
                    }
                </div>
                {!isAuthenticated || !user || !user.subscriberStatusActive ? 
                    <div className="my-3">
                        <button type='button' className="btn block-button btn-myschool-blue" onClick={()=>history.push('/myschoolplus/subscribe')}>
                            {`Access all ${freebies.length} freebies by becoming a MySchool+ member. `}
                        </button>
                    </div> 
                    :''
                }
            </div>

        </section>
    )
};

MySchoolPlusFreebies.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(
  mapStateToProps,
  {  },
)(MySchoolPlusFreebies);
