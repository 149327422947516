import React, { useState, Fragment, useEffect } from 'react';
import { Form, Spinner, Alert, Progress } from 'reactstrap';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { registerOrLogin } from '../../actions/auth';
import { createProfile } from '../../actions/profile';
import { setAlert } from '../../actions/alert'; 
import ProfileImageUpload from '../dashboard/ProfileImageUpload';
import RegisterForm from './RegisterForm';
import ProfileFormBrief from '../profile-forms/ProfileFormBrief';
import CreateChild from '../child/CreateChild';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';
import api from '../../utils/api';
import AnimatedCheckmark from '../../utils/AnimatedCheckmark';
import queryString from 'query-string'; 

const RegisterAndProfile = ({ registerOrLogin, auth: {isAuthenticated, user}, createProfile, toggleRegisterModal, setAlert, alerts, isModal, disableSkipThisStep }) => {
	
  const history = useHistory();
  let referralObj = queryString.parse(history.location.search);
  let referrer = referralObj.referrer;

  const [ prog, setProg ] = useState(33);
  const [ step, setStep ] = useState({
    step1: true,
    step2: false,
    step3: false
  });

  const resetSteps = { 
    step1: false,
    step2: false,
    step3: false
  };

  const changeStep = (activeStep) => {
    console.log('activeStep',activeStep);
    setStep({...resetSteps, [activeStep]: true});
  };

	const [ userFormData, setUserFormData ] = useState({
    username: '',
    email: '',
    password: '',
    password2: ''
  });

  const { username, email, password, password2 } = userFormData; 

  const [indivMailingListCheck, toggleIndivMailingListCheck] = useState(true);
  const [ isSubmitting, setIsSubmitting ] = useState(false);
  
  const onUserSubmit = async e => {
    setIsSubmitting(true);
    if (password !== password2) {
      setAlert('Passwords do not match.', 'danger');
      setIsSubmitting(false);
      console.log('error');
    } else {
      // console.log({ username, email, password, indivMailingListCheck });
      await registerOrLogin({ username, email, password, indivMailingListCheck, referrer });
      changeStep('step2');
      setIsSubmitting(false);
      setProg(66);
    }
  };

  const [ fullAddress, setFullAddress] = useState('');
  const [ profileFormData, setProfileFormData ] = useState({
    city: '',
    state: '',
    zip: '',
    mapOptIn: true,
    howDiscoveredMySchool: '',
    lat: '',
    long: ''
  });
  //Profile Image File
  const [file, setFile] = useState('');
  const [ fileSize, setFileSize ] = useState(0);

  // const [searchAhead, setSearchAhead] = useState([]); //this is the dropdown of selectable addresses
  // const getSearchAheadResults = async (query) => {
  //   setSearchAhead(query);
  //   if (query.length >= 5) {
  //     const searchAheadResults = await api.get(`/profile/searchAhead?query=${query}`);
  //     // setSearchAhead(searchAheadResults);
  //     console.log(searchAheadResults);
  //   } else {
  //     console.log('...keep typing');
  //   }
  // };

  // const onFullAddressChange = e => {
  //   setFullAddress(e.target.value);
  //   if (e.target.value.length > 5) {
  //     getSearchAheadResults();
  //   }
  // };

  // const [ selectedSpot, setSelectedSpot] = useState({}); //this holds the complete data of the chosen searchAhead spot
  // const addressInput = (id) => {
  //   const selectedAddress = searchAhead.find(result => result.id === id);
  //   setSearchAhead(selectedAddress.displayString);
  //   console.log(selectedAddress);
  // }

  // const { address, city, state, zip } = profileFormData;

  const submitProfile = async e => {
    setIsSubmitting(true);
    if (user && user.id) {
      const allData1 = { ...profileFormData, image: file };
      const allData = Object.keys(allData1).reduce((f, k) => {
        f.append(k, allData1[k]);
        return f;
      }, new FormData());
      const configMulti = {headers: {'Content-Type': 'multipart/form-data'}};
      const res = await api.put('/profile/brief-profile', allData, configMulti);
      // console.log(res.data);
      changeStep('step3');
      setIsSubmitting(false);
      setProg(100);
    } else {
      setAlert('Oops! This is our fault, not yours. Please try again later. Redirecting...','danger');
      setTimeout(()=> {
        window.location.reload();
      },3000);
    }
  };

  const [isFormComplete, setFormComplete] = useState(false);

  const completeForm = () => {
    setStep(resetSteps);
    setFormComplete(true);
    setTimeout(()=> {
      if (isModal) {
        window.location.reload();
      } else {
        history.push('/dashboard');
      }
    },3000);
  };

  // //Trigger Page reload once authenticated AND user is loaded.  If done prior to user loaded, Firefox will not store token correctly.
 //  useEffect(()=> {
 //    if (isAuthenticated && user && user._id) {
 //      // window.location.reload();
 //      toggleRegisterModal(false);
 //    }
 //    // return () => clearTimeout(timeoutID);
 //  },[isAuthenticated, user]);

  useEffect(()=> {
    setIsSubmitting(false);
  },[alerts]);

  useEffect(()=> {
    //set step if logged in
    if (isAuthenticated && user) {
      changeStep('step2');
    }
  },[]);

  return (
    <div className='container'>
        { isModal && alerts !== null && alerts.length > 0 && 
          alerts.map(alert => (
            <div key={alert.id} className={`modal-alert alert-modal-${alert.alertType} my-1`}>
              {alert.msg}
            </div>
          ))
        }
        <Progress animated value={prog}>{
            step.step1 ? `Register`
            :
            step.step2 ? `Profile`
            :
            step.step3 ? `Children`
            :
            ''
          }</Progress>
        <h4 className='text-center mt-3 mb-1'> 
          {
            step.step1 ? `Register`
            :
            step.step2 ? `Personalize Your Profile`
            :
            step.step3 ? `Add Children`
            :
            ''
          } 
        </h4>
        {step.step1 ? 
          <div className='text-center'>
            <div className='text-bold color-mt-2'>Join our community to find yours!</div>
            <div className='my-3'>
              <RegisterForm 
                formData={userFormData} 
                setFormData={setUserFormData} 
                indivMailingListCheck={indivMailingListCheck} 
                toggleIndivMailingListCheck={toggleIndivMailingListCheck} 
              />
            </div>
            {referrer &&
              <div className="color-success text-italic my-1">
                You're referral code has been added!
              </div>
            }
            <button
                disabled={isSubmitting}
                type="button"
                onClick={()=>onUserSubmit()}
                className="btn btn-modal-blue btn-set-WH"
              >
              <span>
                {isSubmitting ? (
                  <Spinner size="sm" className='color-white'/>
                ) : (
                  "Submit"
                )}
              </span>
            </button>
          </div>
        :''}

        {step.step2 ?
          <div className='text-center'>
            <div className='my-3'>
              <ProfileFormBrief 
                formData={profileFormData} 
                setFormData={setProfileFormData}
                fullAddress={fullAddress}
                setFullAddress={setFullAddress}
                profile={{myProfile:null,profileLoading:false}}
                isModal={isModal}
                file={file}
                setFile={setFile}
                fileSize={fileSize}
                setFileSize={setFileSize}
              />
            </div>
            <div>
              <button type="button" onClick={()=>submitProfile()} disabled={ isSubmitting || fullAddress.length < 5 } className="btn btn-modal-blue btn-set-WH-long">
                <span>
                  {isSubmitting ? (
                    <Spinner size="sm" className='color-white'/>
                  ) : (
                    "Submit"
                  )}
                </span>
              </button>
            </div>
            {disableSkipThisStep ?
              ''
              :
              <div>
                <button type="button" className="basic-button text-08rem" onClick={()=>completeForm()}>
                  Skip for now >>
                </button>
              </div>
            }
          </div>  
        :''}

        {step.step3 ? 
          <div className='text-center'>
            <div>
              <CreateChild />
            </div>
            <div>
              <button type="button" onClick={()=>completeForm()} disabled={ isSubmitting } className="btn btn-modal-blue btn-set-WH-long">
                <span>
                  {isSubmitting ? (
                    <Spinner size="sm" className='color-white'/>
                  ) : (
                    "Done"
                  )}
                </span>
              </button>
            </div>
          </div>
        :''}

        {isFormComplete ?
          <div className='text-center p-5'> 
            <div className='d-inline-block'>
              <AnimatedCheckmark wid={"150px"} />
            </div>
            <div>
              Rerouting now...
            </div>
          </div>
          :''
        }    
    </div>
  );
};

RegisterAndProfile.propTypes = {
  registerOrLogin: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
  alerts: PropTypes.array.isRequired,
  auth: PropTypes.object
};

RegisterAndProfile.defaultProps = {
  isModal: true,
  disableSkipThisStep: false
}

const mapStateToProps = state => ({
	auth: state.auth,
  alerts: state.alert
});

export default connect(mapStateToProps, { registerOrLogin, createProfile, setAlert })(RegisterAndProfile);
