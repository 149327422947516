import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { Badge, Modal, ModalBody } from 'reactstrap';
import LoginPopup from '../auth/LoginPopup';
import RegisterPopup from '../auth/RegisterPopup';
import PropTypes from 'prop-types';

const AddSpotToMap = ({ isAuthenticated, history, choosePathRef }) => {

    const [ data, setData ] = useState([
        {
            fontAwesomeIcon: "fas fa-map-marker-alt fa-2x color-myschool-blue",
            title: "Recommending a Spot?",
            content: "Fantastic! Click here to post a spot you love to learn with your family so fellow homeschoolers can find it too! Once posted you can even leave comments and ratings.",
            authLink: "/create-resource",
            unauthLink: "/create-resource",
            unauthButton: true
        },
        {
            fontAwesomeIcon: "fas fa-map-marker-alt fa-2x color-myschool-red",
            title: "Adding yourself or your organization?",
            content: "Our members bring their knowledge, passions, and resources to the table to showcase what learning experiences they can offer. Register now to tell the community what you can offer!",
            authLink: "/choose-a-path",
            unauthLink: "/choose-a-path",
            unauthButton: false
        }
    ]);

    const [ loginModal, toggleLoginModal ] = useState(false);
    const [ registerModal, toggleRegisterModal ] = useState(false);

    const swapLoginRegister = () => {
        toggleLoginModal(!loginModal);
        toggleRegisterModal(!registerModal);
    };
    
    return (
        <Fragment>
            <section id="features" ref={choosePathRef} className="section features-area style-two overflow-hidden ptb_50">
                <div className="container">
                    <div className='text-center my-3'>
                        <h2 className='color-myschool-blue'>Add a spot to the map!</h2>
                    </div>

                    <div className="row">

                        {data.map((item, idx) => {
                            return(
                                <div key={`ffd_${idx}`} className="col-12 col-md-6 my-3 res-margin">
                                    <button 
                                        className='basic-button'
                                        type='button' 
                                        onClick={ isAuthenticated ? 
                                            ()=> history.push(`${item.authLink}`)
                                            :
                                            !item.unauthButton ?
                                            ()=> history.push(`${item.unauthLink}`)
                                            :
                                            ()=> toggleRegisterModal(true)
                                            }
                                        >
                                        {/* Image Box */}
                                        <div className="image-box choose-path-card text-center icon-1 p-5">
                                            {/* Featured Image */}
                                            <div className="featured-img mb-3">
                                                {item.fontAwesomeIcon && <i className={item.fontAwesomeIcon} alt=""/>}{' '}
                                                {item.iconImage && <img src={item.iconImage} alt="" width='40px'/>}{' '}
                                                {item.badge ? (<p><Badge color='warning'>{item.badge}</Badge></p>):''}
                                            </div>
                                            {/* Icon Text */}
                                            <div className="icon-text">
                                                <h4 className="mb-2">{item.title}</h4>
                                                <p>{item.content}</p>
                                                {item.content2 ? <p className='text-italic text-075rem'>{item.content2}</p>:''}
                                            </div>
                                        </div>
                                    </button>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </section>

            <Modal isOpen={loginModal} centered toggle={() => toggleLoginModal(!loginModal)}>
                <ModalBody className='text-center'>
                  <LoginPopup loginModal={loginModal} toggleLoginModal={toggleLoginModal}/>
                  <button onClick={()=>swapLoginRegister()} className='basic-button text-bold text-underlined'>I need to Register</button>
                </ModalBody>
            </Modal>

            <Modal isOpen={registerModal} centered toggle={() => toggleRegisterModal(!registerModal)}>
                <ModalBody className='text-center'>
                  <RegisterPopup registerModal={registerModal} toggleRegisterModal={toggleRegisterModal} history={history}/>
                  <button onClick={()=>swapLoginRegister()} className='basic-button text-bold text-underlined'>Go to Login</button>
                </ModalBody>
            </Modal>
        </Fragment>
    );
}

AddSpotToMap.propTypes = {
  isAuthenticated: PropTypes.bool
};

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, { })(AddSpotToMap);