import React, { Fragment, useState } from 'react';
import { Tooltip } from 'reactstrap';

const EventBookingTableItem = ({ booking, onSendEmail, index, cancelBooking, isSubmitting, setIsSubmitting }) => {

  const {_id, parentName, regEmail, phone, childName, childAge} = booking;  

  const [tooltipMailOpen, setTooltipMailOpen] = useState(false);
  const toggleMail = () => setTooltipMailOpen(!tooltipMailOpen);
  const [tooltipCancelOpen, setTooltipCancelOpen] = useState(false);
  const toggleCancel = () => setTooltipCancelOpen(!tooltipCancelOpen);

  return (
    <Fragment>
      <tr>
        <td>{index*1 + 1}</td>
        <td>{parentName ? parentName: '-'}</td>
        <td>{regEmail}</td>
        <td>{phone ? phone: '-'}</td>
        <td>{childName ? childName: '-'}</td>
        <td>{childAge ? childAge : '-'}</td>
        <td>
          
          <button type='button' className='basic-button mx-1' id={`tooltipMailOpen-${_id}`} onClick={()=>onSendEmail(_id)} disabled={!parentName}>
            <i className={parentName ? "far fa-envelope color-myschool-blue":"far fa-envelope color-disabled cursor-default"}/>
          </button>
          <button type='button' className='basic-button mx-1' id={`tooltipCancelOpen-${_id}`} onClick={()=>cancelBooking(_id)} disabled={isSubmitting}>
            {!isSubmitting ? 
              <i className="fas fa-ban color-myschool-blue"/>
              :
              <i className="fas fa-sync-alt color-myschool-blue"/>
            }
          </button>

        </td>
      </tr>

      <Tooltip placement="auto" isOpen={tooltipMailOpen} target={`tooltipMailOpen-${_id}`} toggle={toggleMail}>
        Send mail
      </Tooltip>
      <Tooltip placement="auto" isOpen={tooltipCancelOpen} target={`tooltipCancelOpen-${_id}`} toggle={toggleCancel}>
        Cancel booking
      </Tooltip>

    </Fragment>
  );
};

export default EventBookingTableItem;