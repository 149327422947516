import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Card, CardTitle, CardHeader, CardBody, Spinner } from 'reactstrap';
import PropTypes from 'prop-types';
import SpinnerComponent from '../../layout/Spinner';
import extendCircle from '../../../images/icon-extend-down-circle.svg';

const Dashboard = ({ displaySection, deleteAccount, auth: { user } }) => {

  const [displayInfo, toggleInfo] = useState(true);
  const [ isSubmitting, setIsSubmitting ] = useState(false);

  const deleteMyAccount = async () => {
    setIsSubmitting(true);
    await deleteAccount();
  };

  return !user ? (
    <SpinnerComponent />
  ) : (
    <Fragment>
      { displaySection.deleteAccount && user !== null ? 
        (
          <Card>
            <CardHeader>
              <CardTitle>{`Account Controls`} <Button className="basic-button float-right" onClick={() => toggleInfo(!displayInfo)}><img src={extendCircle} width="25px" alt="extend info dropdown"/></Button></CardTitle>
            </CardHeader>

            { displayInfo &&
              <CardBody className='text-center'>
                <span>
                  <h5><strong>"So long, farewell, auf wiedersehen... goodbye"</strong></h5>
                  <i className="fas fa-heart-broken fa-5x"/>
                </span>
                <div className='my-2'>
                  <button
                    disabled={isSubmitting}
                    type="button"
                    className="btn btn-modal-danger btn-set-WH-long"
                    onClick={() => deleteMyAccount()}
                  >
                    <span>
                      {isSubmitting ? (
                        <Spinner size="sm" className='color-white'/>
                      ) : (
                        'Delete Account'
                      )}
                    </span>
                  </button>
                </div>
              </CardBody>
            }
          </Card>
        )
        : ''
      }
    </Fragment>
  );
};

Dashboard.propTypes = {
  deleteAccount: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});


export default connect(mapStateToProps, {  })(Dashboard);
