import React, { Component } from 'react';
import logoImg from '../../../images/logo100-100.svg';
import { Link } from 'react-router-dom';

class FooterSection extends Component {
    state = {
        data: {
            "text": "Find and follow us through social media for updates!",
            "linkText_1": "Useful Links",
            "linkText_2": "Product Help"
        },
        iconList: [
            {
                "id": 1,
                "linkTo": "https://www.facebook.com/MySchoolMyWay/",
                "link": "facebook",
                "iconClass": "fab fa-facebook color-white"
            },
            {
                "id": 2,
                "linkTo": "https://www.youtube.com/channel/UCiyKN7-EXPG73kQBk0B0UBg",
                "link": "youtube",
                "iconClass": "fab fa-youtube color-white"
            },
            {
                "id": 3,
                "linkTo": "https://www.instagram.com/myschoolathome.io/",
                "link": "instagram",
                "iconClass": "fab fa-instagram color-white"
            },
            {
                "id": 4,
                "linkTo": "http://www.linkedin.com/company/myschoolmyway",
                "link": "linkedin",
                "iconClass": "fab fa-linkedin-in color-white"
            }
        ],
        footerList_1: [
            {
                "id": 1,
                "text": "Home",
                "link": "/"
            },
            {
                "id": 2,
                "text": "About Us",
                "link": "/about"
            },
            {
                "id": 3,
                "text": "Contact",
                "link": "/contact"
            },
            {
                "id": 4,
                "text": "Partnerships",
                "link": "/partnerships"
            }
        ],
        footerList_2: [
            {
                "id": 1,
                "text": "Businesses",
                "link": "/business"
            },
            {
                "id": 2,
                "text": "FAQ",
                "link": "/faqs"
            },
            {
                "id": 3,
                "text": "Terms & Conditions",
                "link": "/termsofuse"
            },
            {
                "id": 4,
                "text": "Privacy",
                "link": "/privacy"
            }
        ]
    }

    

    render() {

        const thisYear = new Date().getFullYear();
        return (
            <div>
                <div className="height-emulator d-none d-lg-block" />
                <footer className="footer-area footer-fixed">
                    {/* Footer Top */}
                    <div className="footer-top ptb_100">
                        <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-6 col-lg-3">
                            {/* Footer Items */}
                            <div className="footer-items">
                                {/* Logo */}
                                <Link className="navbar-brand" to="/">
                                    <img className="logo" src={logoImg} alt="" />
                                </Link>
                                <p className="mt-2 mb-3">{this.state.data.text}</p>
                                {/* Social Icons */}
                                <div className="social-icons d-flex">
                                    {this.state.iconList.map((item, idx) => {
                                        return(
                                            <a key={`fi_${idx}`} className={`${item.link}`} href={item.linkTo} target="_blank" rel="noopener noreferrer">
                                                <i className={`${item.iconClass}`} />
                                                <i className={`${item.iconClass}`} />
                                            </a>
                                        );
                                    })}
                                </div>
                            </div>
                            </div>
                            <div className="col-12 col-sm-6 col-lg-3">
                            {/* Footer Items */}
                            <div className="footer-items">
                                {/* Footer Title */}
                                <h4 className="footer-title mb-2">{this.state.data.linkText_1}</h4>
                                <ul>
                                    {this.state.footerList_1.map((item, idx) => {
                                        return(
                                            <li key={`flo_${idx}`} className="py-2"><Link to={item.link}>{item.text}</Link></li>
                                        );
                                    })}
                                </ul>
                            </div>
                            </div>
                            <div className="col-12 col-sm-6 col-lg-3">
                            {/* Footer Items */}
                            <div className="footer-items">
                                {/* Footer Title */}
                                <h4 className="footer-title mb-2">{this.state.data.linkText_2}</h4>
                                <ul>
                                    {this.state.footerList_2.map((item, idx) => {
                                        return(
                                            <li key={`flt_${idx}`} className="py-2"><Link to={item.link}>{item.text}</Link></li>
                                        );
                                    })}
                                </ul>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    {/* Footer Bottom */}
                    <div className="footer-bottom">
                        <div className="container">
                        <div className="row">
                            <div className="col-12">
                            {/* Copyright Area */}
                            <div className="copyright-area d-flex flex-wrap justify-content-center justify-content-sm-between text-center py-4">
                                {/* Copyright Left */}
                                <div className="copyright-left">Copyright &copy;{` ${thisYear} `} by MySchool Inc.</div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </footer>
            </div>
        );
    }
}

export default FooterSection;