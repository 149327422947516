import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import Spinner from '../layout/Spinner';
import ProviderReviews from './ProviderReviews';
import { getProviderByProviderId } from '../../actions/provider';
import { getProviderReviews, deleteProviderReview } from '../../actions/providerReviews';
import { getEventsByProviderId } from '../../actions/event';
import { Modal, ModalBody } from 'reactstrap';
import ReviewProvider from '../classprovider-forms/ReviewProvider';
import Rating from 'react-rating';
import LoginPopup from '../auth/LoginPopup';
import RegisterPopup from '../auth/RegisterPopup';
import ProviderSidebarProfile from './ProviderSidebarProfile';
import ProviderSidebarDetails from './ProviderSidebarDetails';
import EventListItem from '../events/event-map/EventListItem';
import ReactGA from 'react-ga';

const ProviderSidebarWrapper = ({ getProviderByProviderId, provider: { provider, providerLoading }, getProviderReviews, deleteProviderReview, providerReviews: {providerReviews, providerReviewsLoading }, auth, getEventsByProviderId, event: {eventLoading, events} }) => {

  const history = useHistory();
  const params = useParams();

  const [ showReviewModal, setShowReviewModal ] = useState(false);
  const [ loginModal, toggleLoginModal ] = useState(false);
  const [ registerModal, toggleRegisterModal ] = useState(false);

  const swapLoginRegister = () => {
    toggleLoginModal(!loginModal);
    toggleRegisterModal(!registerModal);
  };

  const goToProviderContact = () => {
    if (auth.isAuthenticated) {
      history.push(`/provider/${params.providerId}/sendEmail`);
    } else {
      toggleLoginModal(!loginModal);
    }
  };

  const seeReviews = useRef(null);
  const scrollToReviews = () => {
      seeReviews.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "start"
      });
  }; 

  const setMapFlyTo = ([lat,long]) => {
    ReactGA.event({ category: "events_on_provider_profile", action: "scroll_over_event", label: `${params.providerId}`});
  }

  useEffect(() => {
    getProviderByProviderId(params.providerId);
  }, [params]);

  useEffect(() => {
    getProviderReviews(params.providerId);
  },[params]);

  useEffect(() => {
    getEventsByProviderId(params.providerId);
  },[params]);

  return !provider || providerLoading || providerReviewsLoading || !providerReviews ? (
        <Spinner />
      ) : (
            <div className="provider">
                <section id="provider" className="section provider-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-lg-3">
                                <ProviderSidebarProfile provider={provider} />
                            </div>
                            <div className="col-12 col-lg-9">
                                <ProviderSidebarDetails provider={provider} history={history} user={auth.user}/>
                            </div>
                        </div>
                        { provider && provider.email ?
                          <div className="my-3">
                              <div className='text-center'>
                                <button type='button' className='btn btn-myschool-blue' onClick={()=>goToProviderContact()}>
                                  Contact the organizer!
                                </button>
                                {provider && provider.ratingsQuantity > 0 ? 
                                  <button type='button' className='btn btn-myschool-blue' onClick={()=>scrollToReviews()}>
                                    See reviews
                                  </button>
                                  :''
                                }
                              </div>
                          </div>
                          : ''
                        }
                        {events.length > 0 ?
                          <div>
                            <h4 className='my-1'>Upcoming Events</h4>
                            <div className='my-2'>
                               {events.map(ev =>
                                  <EventListItem event={ev} key={ev._id} setMapFlyTo={setMapFlyTo}/>
                                )}
                            </div>
                          </div>
                          : ''
                        }
                        <h4 className='my-1' ref={seeReviews}>Reviews</h4>
                        {provider && provider.ratingsAverage && provider.ratingsQuantity > 0 ? 
                          (
                            <div className='vert-align-2rem'>
                                <h4 className='text-bold float-left'>{provider.ratingsAverage}</h4>
                              {" "}
                              <span className='mx-2'>
                                <Rating
                                  emptySymbol="far fa-star fa-2x color-myschool-yellow"
                                  fullSymbol="fas fa-star fa-2x color-myschool-yellow"
                                  fractions={4}
                                  name='ratingData'
                                  initialRating={provider.ratingsAverage}
                                  readonly
                                />
                              </span>
                              {`${provider.ratingsQuantity} total ratings`}
                            </div>
                          ) : ''
                        }
                        <button className='btn btn-myschool-blue' 
                            onClick={
                              !auth.isAuthenticated ?
                              () => toggleLoginModal(!loginModal)
                              :
                              () => setShowReviewModal(!showReviewModal)
                            }
                          >
                            <span><i className="far fa-star"/></span>
                            {" "}Submit a Review
                        </button>
                        <div className='my-2'>
                          {providerReviews.length > 0 ? (providerReviews.map(review =>
                              <ProviderReviews providerReviews={review} auth={auth} deleteProviderReview={deleteProviderReview} key={review._id}/>
                            ))
                          : 
                            (<div>There are no reviews yet for this provider</div>)
                          }
                        </div>
                    </div>

                      <Modal isOpen={showReviewModal} centered toggle={() => setShowReviewModal(!showReviewModal)}>
                        <ModalBody className='text-center'>
                          <ReviewProvider providerId={provider._id} setShowReviewModal={setShowReviewModal} />
                        </ModalBody>
                      </Modal>

                      <Modal isOpen={loginModal} centered toggle={() => toggleLoginModal(!loginModal)}>
                        <ModalBody className='text-center'>
                          You have to register and login to do that!
                          <LoginPopup loginModal={loginModal} toggleLoginModal={toggleLoginModal}/>
                          <button onClick={()=>swapLoginRegister()} className='basic-button text-bold text-underlined'>I need to Register</button>
                        </ModalBody>
                      </Modal>

                      <Modal isOpen={registerModal} centered toggle={() => toggleRegisterModal(!registerModal)}>
                        <ModalBody className='text-center'>
                          <RegisterPopup registerModal={registerModal} toggleRegisterModal={toggleRegisterModal} history={history}/>
                          <button onClick={()=>swapLoginRegister()} className='basic-button text-bold text-underlined'>Go to Login</button>
                        </ModalBody>
                      </Modal>

                </section>
            </div>
        );
}


ProviderSidebarWrapper.propTypes = {
  getProviderByProviderId: PropTypes.func.isRequired,
  provider: PropTypes.object.isRequired,
  getProviderReviews: PropTypes.func.isRequired,
  deleteProviderReview: PropTypes.func.isRequired,
  providerReviews: PropTypes.object,
  event: PropTypes.object,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  provider: state.provider,
  providerReviews: state.providerReviews,
  event: state.event,
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { getProviderByProviderId, getProviderReviews, deleteProviderReview, getEventsByProviderId }
)(ProviderSidebarWrapper);