import React, { useState, Fragment } from "react";
import { Container, Row, Col, Form, FormGroup, Label, Input} from 'reactstrap';
import api from '../../../utils/api';
import isValidURL from '../../../utils/isValidURL';
import dayjs from 'dayjs';

const DiscountForm = () => {

  const [formData, setFormData] = useState({
    name: "",
    short_desc: "",
    description: "",
    area: "national",
    accessLink: "",
    linkQualifyDetails: "", //link to company's website showing rules to obtain the discount
    tags: [],
    isFeatured: false,
    expires: dayjs().format("YYYY-MM-DD")
  });

  const { name, short_desc, description, area, accessLink, tags, isFeatured, expires } = formData;

  const areaOptions = ["national"]

  const onCheckChange = e =>
    setFormData({ ...formData, [e.target.name]: e.target.checked });

  const onChange = e =>
    setFormData({ ...formData, [e.target.name]: e.target.value });


  const [ tagEntry, setTagEntry ] = useState(''); //display of tag text before entry
  const changeTagEntry = e => {
    if(e.key !== ","){
      setTagEntry(e.target.value);
    }
  }

  const handleTagKeyPress = (e) => {
    if(e.key === "," || e.key === "Enter"){
      const newTagArray = [...tags, e.target.value];
      setFormData({...formData, tags: newTagArray});
      setTagEntry('');
    }
  };

  const onDeleteTag = (string) => {
    const tagArray = tags.filter(e => e !== string);
    setFormData({...formData, tags: tagArray});
  };

  const [urlInvalid, setUrlInvalid] = useState(false);
  const onExternalUrlChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    if (isValidURL(e.target.value) === false) {
      setUrlInvalid(true);
    } else {
      setUrlInvalid(false);
    }
  };

  const [isSubmitting, setIsSubmitting] = useState(false); 
  const submitDiscount = async () => {
    setIsSubmitting(true);
    await api.post('/v1/discounts/add-discount', formData);
    setIsSubmitting(false);
    setFormData({
      name: "",
      short_desc: "",
      description: "",
      area: "national",
      accessLink: "",
      linkQualifyDetails: "", //link to company's website showing rules to obtain the discount
      tags: [],
      isFeatured: false,
      expires: dayjs().format("YYYY-MM-DD")
    });
  };

  return (
    <Container>
        <h1>
          Add discount
        </h1>

        <Row form>
          <Col>
            <FormGroup>
              <Label for="name">Discount Name</Label>
              <Input type="text"
                name="name"
                id="name"
                value={name}
                placeholder="Name"
                onChange={e => onChange(e)} 
                required
                />
            </FormGroup>
          </Col>
        </Row>

        <FormGroup>
          <Label for="short_desc">Discount Short Description</Label>
          <Input type="text" 
            name="short_desc" 
            id="short_desc" 
            placeholder="Short Description" 
            value={short_desc}
            onChange={e => onChange(e)} 
            required/>
        </FormGroup>

        <FormGroup>
          <Label for="description">Discount Full Description</Label>
          <Input type="textarea"

            name="description" 
            id="description" 
            placeholder="Full Description" 
            value={description}
            onChange={e => onChange(e)} 
            required/>
        </FormGroup>

        <div>
          <FormGroup>
            <Label for="accessLink">Discount Link</Label>
            <Input 
              invalid={urlInvalid} 
              type="text"
              placeholder='http://example.com' 
              name="accessLink" 
              id="accessLink" 
              value={accessLink} 
              onChange={(e)=>onExternalUrlChange(e)}
              required
              />
          </FormGroup>
          { urlInvalid &&
            <div>
              <ul className='text-danger'>
                <li>-The URL must start with either "http://" or "https://"</li>
              </ul>
            </div>
          }
        </div>

        <FormGroup>
          <Label>Expiration?</Label>
            <Input type="date" 
              name="expires" 
              id="expires" 
              value={expires}
              onChange={e => onChange(e)} 
              />
        </FormGroup>

        <FormGroup>
          <Label>Area</Label>
          <Input type="select" 
            name="area" 
            id="area" 
            value={area}
            onChange={e => onChange(e)} 
            required>
            <option value="national">National</option>
          </Input>
        </FormGroup>

        <FormGroup check className="my-3"> 
          <Label check>
            <Input
              type="checkbox"
              name="isFeatured" 
              checked={isFeatured}
              onChange={(e) => onCheckChange(e)} 
              />
              Featured Discount?
          </Label>
        </FormGroup>

        <FormGroup>
          <span className='text-bold'>Tags: </span>
          <div><small>Type a tag and enter it by pressing the &quot;,&quot; or &quot;Enter&quot; keys.</small></div>
          <div>
            <Input type="text" name="tagEntry" id="tagEntry" value={tagEntry} onChange={(e)=>changeTagEntry(e)} onKeyPress={(e)=>handleTagKeyPress(e)}/>
            {tags.map((tag, index) =>
              (
                <span key={index} className='btn btn-myschool-red'>
                  {tag}
                  {' '}
                  <button type='button' className='basic-button' onClick={()=>onDeleteTag(tag)}><i className='fas fa-times color-white'/></button>
                </span>
              ))
            }
          </div>
        </FormGroup>

        <button
          type="submit" 
          className="btn btn-myschool-blue" 
          onClick={()=>submitDiscount()}
          disabled={isSubmitting}
          >
          {isSubmitting ?
            'Submitting...'
            :'Submit'
          }
        </button>

    </Container>
  );
}

export default DiscountForm;

