import React, { Fragment, useState } from 'react';
import { Marker, Popup } from 'react-leaflet';
import { Link } from 'react-router-dom';
import { Row, Col, Card, CardBody, CardText, Button, Spinner } from 'reactstrap';
import capitalizeName from '../../../utils/capitalizeName';
import heartOutline from '../../../images/heart-outline.svg';
import heartFilled from '../../../images/heart-filled.svg';
import zoomToPoint from '../../../images/icon-zoom-to-point.svg';
import zoomOut from '../../../images/icon-zoom-out.svg';

const ProviderListItem = ({ provider, isSettingFav, favoriteProviderHandler, auth, setMapFlyTo, zoomInOut, isZoomedIn, history }) => {

  return (
    <Card className='homeschoolmap-card' onTouchStart={() => setMapFlyTo([provider.lat, provider.long])} onMouseEnter={() => setMapFlyTo([provider.lat, provider.long])}>
      <CardBody className='homeschoolmap-cardbody'>
        <Row>
          
          <Col xs={2} className='homeschoolmap-list-actions'>
            <div className='d-flex align-items-center h-100 '>
              <button type='button' className='btn fav-button' onClick={()=>zoomInOut([provider.lat, provider.long])}>
                { isZoomedIn ? 
                  <img src={zoomOut} width='30px' />
                  :
                  <img src={zoomToPoint} width='30px' />
                }
              </button>
            </div>
          </Col>
          
          <Col xs={7} className='px-0'>
            <div className=''>
              <CardText className='text-bold'>{provider.providerName}</CardText>
              <CardText className='text-bold'>{capitalizeName(provider.category)}</CardText>
                { provider && provider.subjects && Array.isArray(provider.subjects) && provider.subjects.length>0 &&
                  <CardText>{`Subjects to learn here: ` + provider.subjects.join(', ')}</CardText>
                }
            </div>
          </Col>

          <Col xs={3} className='homeschoolmap-list-actions'>
            <div className='homeschoolmap-list-actions-buttons '>
              <Button type='button' className='fav-button justify-content-center' disabled={isSettingFav} onClick={()=>favoriteProviderHandler(provider._id)}>
                {isSettingFav === false && auth.user && auth.user.myFavProviders && auth.user.myFavProviders.filter(fav => fav.toString() === provider._id).length > 0 ?
                  <img src={heartFilled} width="15px"/>
                   : 
                 isSettingFav === true ?
                  <Spinner style={{ width: '15px', height: '15px'}} type="grow" color="danger" />
                   : 
                  <img src={heartOutline} width="15px"/>
                } 
              </Button>
              <div className="my-1"/>
              <Button type='button' className='homeschoolmap-list-action-more' onClick={()=>history.push(`/classProvider/${provider._id}`)}>
                more...
              </Button>
            </div>
          </Col>

        </Row>
      </CardBody>
    </Card>
  );
};


export default ProviderListItem;
