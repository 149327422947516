import React, { Fragment, useState, useEffect } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Container, Table, Button, Modal, Input, Row, Col, Form, FormGroup, Spinner, Alert, Card } from 'reactstrap';
import EventBookingListItem from './EventBookingListItem';
import EventBookingTableItem from './EventBookingTableItem';
import EventDashboardBriefListing from './EventDashboardBriefListing';
import EventNoRegTableItem from './EventNoRegTableItem';
import { setAlert } from '../../../../actions/alert';
import { getMyEventById, cancelRegistration } from '../../../../actions/event';
import axios from 'axios';
import SpinnerComponent from '../../../layout/Spinner';
import api from '../../../../utils/api';

const EventBookingWrapper = ({ auth, event: {event, eventLoading}, setAlert, getMyEventById, cancelRegistration }) => {

  const params = useParams();
  const history = useHistory();

  const [ isList, toggleIsList ] = useState(false);

  const [ emailAttendeeModal, setEmailAttendeeModal ] = useState(false);

  const [ isSubmitting, setIsSubmitting ] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const [emailFormData, setEmailFormData] = useState({
    regEmail: '',
    parentName: '',
    message:'',
    signatureName:'',
    signatureEmail:''
  });

  const onChange = e => {
    setEmailFormData({ ...emailFormData, [e.target.name]: e.target.value });
  };

 
  const [ alertArray, setAlertArray ] = useState([]);
  const onSubmit = async e => {
    e.preventDefault();
    setIsSubmitting(true);
    const jsonFormData = JSON.stringify(emailFormData);
    try {
      const emailres = await api.post(`/events/emailAttendee`, jsonFormData);
      console.log(emailres);
      setEmailFormData({
        regEmail: '',
        parentName: '',
        message:'',
        signatureName:'',
        signatureEmail:''
      });
      setIsSubmitting(false);
      setIsSuccess(true);
      setTimeout(()=> {
          setEmailAttendeeModal(false);
          setIsSuccess(false);
        }, 3000);
    } catch (err) {
      setIsSubmitting(false);
      setIsSuccess(false);
      console.log(err);
      let errors = [];
      if (err && err.response && err.response.data && err.response.data.errors) {
        errors = err.response.data.errors;
      }
      console.log(errors);
      if (errors) {
          setAlertArray(errors);
          setTimeout(()=> {setAlertArray([])},5000);
      } else {
        setAlertArray([{msg:'There was an error.  Please try again.  If the problem persists, please let us know.'}]);
          setTimeout(()=> {setAlertArray([])},5000);
      }
    }
  };

  function onSendEmail(id) {
    setEmailAttendeeModal(!emailAttendeeModal);
    const bookingData = event.userBookings.find((e) => e._id == id);
    setEmailFormData({
      ...emailFormData,
      regEmail: bookingData.regEmail,
      parentName: bookingData.parentName
    });
  }

  const copyEventUrl = async () => {
    navigator.clipboard.writeText(`${window.location.origin}/events/${event._id}`)
    .then(function() {
      setAlert('Copied to Clipboard', 'success');
    }, function(err) {
      setAlert('Error copying to clipboard, sorry :(', 'danger');
      console.log(err);
    });
  };
  
  const allEmails = [];
  const [ allEmailsCopied, setAllEmailsCopied ] = useState(false);
  const copyEmails = async () => {

    const findEmails = () => {
      if (event.regNotReq && event.regNotReq == true && event.regNotReqInfo) {
        event.regNotReqInfo.forEach(b => {
          if (!allEmails.includes(b.email)) {
            allEmails.push(b.email);
          }
        });
      } else {
        event.userBookings.forEach(b => {
          if (!allEmails.includes(b.regEmail)) {
            allEmails.push(b.regEmail);
          }
        });
      }
    };
    await findEmails();
    const allEmailString = allEmails.join(',');
    navigator.clipboard.writeText(allEmailString);
    setAllEmailsCopied(true);
    setTimeout(()=> {
      setAllEmailsCopied(false);
    },3000)
  };

  useEffect(()=> {
    if (auth && auth.user) {
      getMyEventById(params.eventId);
    }
  },[params, auth]);

  const cancelBooking = async (id) => {
    setIsSubmitting(true);
    await cancelRegistration(id);
    setTimeout(()=>setIsSubmitting(false),3000);
  };


return eventLoading || !event ? 
  (
    <SpinnerComponent />
  ) : (
    <Fragment>
      <Container>
        <div className="section-heading text-center">
          <h2>Your Event Bookings</h2>
        </div>
        <div>
          <button type='button' className='btn btn-myschool-blue' onClick={()=>history.goBack()}>Back</button>
        </div>
        <Card className='my-3 p-2'>
          <EventDashboardBriefListing event={event} showBookingsLink={false} history={history} />
          <div>
            <button type='button' className='btn btn-myschool-blue' onClick={()=>copyEventUrl()}>Copy Event URL</button>
            <Link to={`/events/${event._id}`} className='btn btn-myschool-blue'>Go to listing</Link>
          </div>
        </Card>
        <button type='button' className='btn btn-primary float-right my-2 mx-1' onClick={()=>copyEmails()} disabled={allEmailsCopied}>
          {!allEmailsCopied ? `Copy all emails`: 'Copied!'}
        </button>
        <button type='button' className='btn btn-primary float-right my-2 mx-1' onClick={()=>toggleIsList(!isList)}>
          <i className="fas fa-list-ul"/>{' '}/{' '}<i className="fas fa-table"/>
        </button>
        <div className='space'/>
        { isList ? 
          (
            <Fragment>
              <h4 className='text-primary-ms'>Bookings - List View</h4>
              {event.userBookings.map(booking => (
                <EventBookingListItem key={`list-${booking._id}`}  booking={booking}/>
              ))}
            </Fragment>
          )
          : 
          (
            <Fragment>
              <h4 className='text-primary-ms'>Bookings - Table View</h4>
              <small>Switch to List view with button at right to see detailed information</small>
              <Table striped>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Parent Name</th>
                    <th>Parent Email</th>
                    <th>Phone</th>
                    <th>Child's Name</th>
                    <th>Age</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  { (!event.regNotReq || event.regNotReq === false) ? event.userBookings.map((booking,index) => (
                    <EventBookingTableItem 
                      key={`table-${booking._id}`} 
                      index={index}
                      booking={booking}
                      onSendEmail={onSendEmail}
                      cancelBooking={cancelBooking}
                      setIsSubmitting={setIsSubmitting}
                      isSubmitting={isSubmitting}
                    />
                  ))
                  :
                   event.regNotReqInfo.map((booking,index) => (
                    <EventNoRegTableItem 
                        key={`table-${booking._id}`} 
                        index={index}
                        booking={booking}
                      />
                  ))
                }
                </tbody>
              </Table>
            </Fragment>
          )
        }
      </Container>

      <Modal isOpen={emailAttendeeModal} centered toggle={() => setEmailAttendeeModal(!emailAttendeeModal)}>
        { !isSuccess ?
          <Form onSubmit={e => onSubmit(e)} className="msstd-form">
            {alertArray.length > 0 ? 
              alertArray.map((alert, index) =>
                  <Alert color='danger' key={index} className='alert-modal-danger'>
                    {alert.msg}
                  </Alert>
                )
              : ''
            }
            <Row form>
              <Col>
                <FormGroup>
                  Destination Email Address: {emailFormData.regEmail}
                </FormGroup>
                <FormGroup>
                  Dear {emailFormData.parentName},
                </FormGroup>
                <FormGroup>
                  <Input type='textarea'
                    placeholder='Message Area'
                    rows='5'
                    name='message'
                    value={emailFormData.message}
                    onChange={e => onChange(e)}
                    required
                  />
                </FormGroup>
                <div>Sincerely,</div>
                <FormGroup>
                  <Input type="text" 
                  name="signatureName"
                  placeholder="Your Name" 
                  value={emailFormData.signatureName}
                  onChange={e => onChange(e)} 
                  required
                  />
                </FormGroup>
                <FormGroup>
                  <Input type="email" 
                  name="signatureEmail"
                  placeholder="Your Email Address" 
                  value={emailFormData.signatureEmail}
                  onChange={e => onChange(e)} 
                  required
                  />
                </FormGroup>
              </Col>
            </Row>
              
            <Button type="submit" disabled={isSubmitting || isSuccess} className="btn btn-modal-blue btn-set-WH-long">
              <span>
                {isSubmitting ? (
                  <Spinner size="sm" className='color-white'/>
                ) : (
                  "Send Email"
                )}
              </span>
            </Button>
          </Form>
        : 
          <div className='text-center py-5'>
            <h2>Success!</h2>
            <h4>Email Sent!</h4>
          </div>
        }
      </Modal>

    </Fragment>
  );
};


EventBookingWrapper.propTypes = {
  auth: PropTypes.object.isRequired,
  event: PropTypes.object.isRequired,
  setAlert: PropTypes.func.isRequired,
  getMyEventById: PropTypes.func.isRequired,
  cancelRegistration: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  event: state.event,
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { setAlert, getMyEventById, cancelRegistration }
)(EventBookingWrapper);