import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class FooterBrief extends Component {
    state = {
        data: {
            "text": "Find and follow us through social media for updates!",
            "linkText_1": "Useful Links"
        },
        iconList: [
            {
                "id": 1,
                "linkTo": "https://www.facebook.com/groups/myschoolathome",
                "link": "facebook",
                "iconClass": "fab fa-facebook color-white"
            },
            {
                "id": 2,
                "linkTo": "https://www.instagram.com/myschoolathome.io/",
                "link": "instagram",
                "iconClass": "fab fa-instagram color-white"
            },
            {
                "id": 3,
                "linkTo": "https://www.youtube.com/channel/UCiyKN7-EXPG73kQBk0B0UBg",
                "link": "youtube",
                "iconClass": "fab fa-youtube color-white"
            }
        ],
        footerList_1: [
            {
                "id": 1,
                "text": "Home",
                "link": "/"
            },
            {
                "id": 2,
                "text": "About Us",
                "link": "/about"
            },
            {
                "id": 3,
                "text": "Contact",
                "link": "/contact"
            }
        ]
    }

    render() {

        const thisYear = new Date().getFullYear();

        return (
            <div>
                <footer className="footer-area">
                    <div className="ptb_50">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 text-center">
                                    <ul>
                                        {this.state.footerList_1.map((item, idx) => {
                                            return(
                                                <li key={`flo_${idx}`} className="px-2 d-inline-block"><Link to={item.link}>{item.text}</Link></li>
                                            );
                                        })}
                                    </ul>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="social-icons d-flex justify-content-center">
                                        {this.state.iconList.map((item, idx) => {
                                            return(
                                                <a key={`fi_${idx}`} className={`${item.link}`} href={item.linkTo} target="_blank" rel="noopener noreferrer">
                                                    <i className={`${item.iconClass}`} />
                                                    <i className={`${item.iconClass}`} />
                                                </a>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Footer Bottom */}
                    <div className="footer-bottom">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    {/* Copyright Area */}
                                    <div className="copyright-area d-flex flex-wrap justify-content-center justify-content-sm-between text-center py-4">
                                        <div>Copyright &copy; {` ${thisYear} by MySchool Inc.`}</div>
                                        <div><a href="mailto:info@myschoolathome.io" target='_blank' rel='noopener noreferrer'>info@myschoolathome.io</a></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        );
    }
}

export default FooterBrief;