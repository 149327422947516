import {
  GET_PROVIDER,
  GET_MY_PROVIDER,
  PROVIDER_ERROR,
  CLEAR_PROVIDER,
  UPDATE_PROVIDER,
  PROVIDER_DELETED,
  GET_PROVIDERS,
  CLEAR_PROVIDERS,
  EMAIL_PROVIDER_GEN_ERROR,
  GET_PROVIDERS_WITHIN_RADIUS,
  GET_PROVIDERS_WITHIN_MAPBOUNDS
} from '../actions/types';

const initialState = {
  provider: null,
  myProvider: null,
  providers: [],
  providerLoading: true,
  error: {}
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_PROVIDER:
    case UPDATE_PROVIDER:
      return {
        ...state,
        provider: payload,
        providerLoading: false
      };
    case GET_MY_PROVIDER:
      return {
        ...state,
        myProvider: payload,
        providerLoading: false
      };
    case GET_PROVIDERS:
    case GET_PROVIDERS_WITHIN_RADIUS:
    case GET_PROVIDERS_WITHIN_MAPBOUNDS:
      return {
        ...state,
        providers: payload,
        providerLoading: false
      };
    case PROVIDER_ERROR:
    case EMAIL_PROVIDER_GEN_ERROR:
      return {
        ...state,
        error: payload,
        providerLoading: false
      };
    case CLEAR_PROVIDER:
      return {
        ...state,
        provider: null,
        providerLoading: false
      };
    case CLEAR_PROVIDERS:
      return {
        ...state,
        providers: [],
        providerLoading: false
      };
    case PROVIDER_DELETED:
      return {
        ...state,
        providers: state.providers.filter(provider => provider._id !== payload),
        providerLoading: false
      };
    default:
      return state;
  }
}
