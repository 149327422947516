import React, { Fragment } from 'react';
import { Card, CardText } from 'reactstrap';

const ParentRegCard = ({ parent, deleteSavedParent, index }) => {

  return (
      <Fragment>
      <Card body inverse className='registration-parent-card'>
        <CardText>
          <button className="basic-button float-right" 
            onClick={() => deleteSavedParent(index)}>
            <i className='fa fa-times color-white'/>
          </button>
          <strong>Name:</strong>{` ${parent.parentName}`}
        </CardText>
        <CardText>
          <strong>Email:</strong>{` ${parent.regEmail}`}
        </CardText>
        <CardText>
          <strong>Phone:</strong>{` ${parent.phone}`}
        </CardText>
      </Card>
    </Fragment>
  );
};


export default ParentRegCard;
