import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { connect } from 'react-redux';
import { Card, CardBody, Row, Col, Badge, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { deletePortfolioEntry, editPortfolioEntry } from "../../actions/portfolio";
import Spinner from '../layout/Spinner';
import timechange from '../../utils/timeChange';
import EditEntry from './portfolioEntryTools/EditEntry';
import PortfolioItemDetails from './PortfolioItemDetails';
import truncate from '../../utils/truncate';

const PortfolioItem = ({ portfolioEntry, deletePortfolioEntry, editPortfolioEntry }) => {  
  
  const [ showEditEntry, toggleEditEntry ] = useState(false);
  const [ showDetails, toggleShowDetails ] = useState(false);
  
  const [ showFullDesc, toggleShowFullDesc ] = useState(false);
  const nCharacters = 50;

  const [ showFullSubjects, toggleShowFullSubjects ] = useState(false);
  const nSubjects = 3;

  const entryData = (
    <Row className='align-items-center'>
      <Col xs={2} className='text-center text-075rem px-0'>
        <div>{portfolioEntry && portfolioEntry.timestamp ? dayjs(portfolioEntry.timestamp).format("MM/DD/YY") : ''}</div>
      </Col>
      <Col xs={2} className='text-center text-075rem px-0'>
        <div>
          {portfolioEntry && portfolioEntry.subjects && Array.isArray(portfolioEntry.subjects) && portfolioEntry.subjects.length > 0 && portfolioEntry.subjects.length <= nSubjects ?
              portfolioEntry.subjects.map((s,i) => {
                return <Badge key={i} className={`pill-color-${s.replace(/\s+/g, '')} mr-1`} pill>{truncate(s,14,"")}</Badge>
              })
            :
            portfolioEntry && portfolioEntry.subjects && Array.isArray(portfolioEntry.subjects) && portfolioEntry.subjects.length > 0 && portfolioEntry.subjects.length > nSubjects && !showFullSubjects ?
              <div>
                {portfolioEntry.subjects.slice(0,nSubjects).map((s,i) => {
                  return <Badge key={i} className={`pill-color-${s.replace(/\s+/g, '')} mr-1`} pill>{truncate(s,14,"")}</Badge>
                })}
                <button type="button" className="basic-button text-075rem ml-1" onClick={()=>toggleShowFullSubjects(true)}>
                  ...
                </button>
              </div>
            :
            portfolioEntry && portfolioEntry.subjects && Array.isArray(portfolioEntry.subjects) && portfolioEntry.subjects.length > 0 && portfolioEntry.subjects.length > nSubjects && showFullSubjects ?
              <div>
                {portfolioEntry.subjects.map((s,i) => {
                  return <Badge key={i} className={`pill-color-${s.replace(/\s+/g, '')} mr-1`} pill>{truncate(s,14,"")}</Badge>
                })}
              </div>
            :''
          }
        </div>
      </Col>
      <Col xs={2} className='text-center text-075rem px-0'>
        <div>{portfolioEntry && portfolioEntry.duration && portfolioEntry.duration !== undefined ? 
          `${timechange(portfolioEntry.duration)}` : ''}
        </div>
      </Col>
      <Col xs={4} className='text-075rem px-0'>
        <div> 
          {
            portfolioEntry.description.length <= nCharacters ?
            <div>
              {portfolioEntry.description}
            </div>
            : !showFullDesc && portfolioEntry.description.length > nCharacters ?
              <div>
                {truncate(portfolioEntry.description,nCharacters)}
                <button type="button" className="basic-button text-075rem ml-1" onClick={()=>toggleShowFullDesc(true)}>
                  (expand)
                </button>
              </div>
              :
            showFullDesc && portfolioEntry.description.length > nCharacters ?
              <div>
                {portfolioEntry.description}
                <button type="button" className="basic-button text-075rem ml-1" onClick={()=>toggleShowFullDesc(false)}>
                  (less)
                </button>
              </div>
              :''
          } 
        </div>
      </Col>
      <Col xs={2} className='text-center text-075rem px-1'>
        <div>
          <button type="button" className="basic-button" onClick={()=>toggleEditEntry(true)}>
            <i className="fas fa-pen color-myschool-blue mr-1"/>
          </button>
          {portfolioEntry && portfolioEntry.files && portfolioEntry.files.length > 0 ?
            <button type="button" className="basic-button" onClick={()=>toggleShowDetails(true)}>
              <i className="fas fa-image color-myschool-blue mx-1"/>
            </button>
            :
            ''
          }
        </div>
      </Col>
    </Row>
  );

  return !portfolioEntry ? (
    <Spinner />
    ) : (
    <Fragment> 
      <div className='my-3 mx-2'/>
      <Card>               
        <CardBody>
            <div>
              {entryData}
            </div>
        </CardBody> 
      </Card>

      <Modal isOpen={showEditEntry} toggle={() => toggleEditEntry(!showEditEntry)}>
        <ModalHeader className='justify-content-end'>
            <button className="basic-button" onClick={() => toggleEditEntry(false)}>
              <i className='fa fa-times color-light-grey'/>
            </button>
        </ModalHeader>
        <ModalBody className='text-center'>
          <EditEntry entry={portfolioEntry} editPortfolioEntry={editPortfolioEntry} toggleEditEntry={toggleEditEntry}/>
        </ModalBody>
      </Modal>

      <Modal isOpen={showDetails} toggle={() => toggleShowDetails(!showDetails)}>
        <ModalHeader className='justify-content-end'>
          <button className="basic-button" onClick={() => toggleShowDetails(false)}>
            <i className='fa fa-times color-light-grey'/>
          </button>
        </ModalHeader>
        <ModalBody className='text-center'>
          <PortfolioItemDetails portfolioEntry={portfolioEntry} />
        </ModalBody>
      </Modal>
    </Fragment>
  )  

};


PortfolioItem.propTypes = {
  portfolioEntry: PropTypes.object.isRequired,
  deletePortfolioEntry: PropTypes.func.isRequired,
  editPortfolioEntry: PropTypes.func.isRequired
};

export default connect(
  null,
  { deletePortfolioEntry, editPortfolioEntry }
)(PortfolioItem);
