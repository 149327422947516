import React, { Fragment, useState, useEffect } from 'react';
import { Link, NavLink, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { logout } from '../../actions/auth';
import LogoImg from '../../images/logo100-100.svg';
import hamburger from '../../images/hamburger-lt.svg';
import iconTimes from '../../images/icon-times.svg';
import { Badge, Modal, ModalBody } from 'reactstrap';
import AuthModals from '../auth/AuthModals';
import lockLocked from '../../images/icon-lock-locked.svg';

const Header = ({ auth: { isAuthenticated, loading, user }, logout, history }) => {

    const [ collapsed, setCollapsed ] = useState(true);
    const [ loginModal, toggleLoginModal ] = useState(false);
    const [ registerModal, toggleRegisterModal ] = useState(false);

    const swapLoginRegister = () => {
        toggleLoginModal(!loginModal);
        toggleRegisterModal(!registerModal);
    };

    const toggleNavbar = () => setCollapsed(!collapsed);

    useEffect(()=> {
        setCollapsed(true);
    },[window.location.pathname]);

    const authLinks = (
        <ul className="navbar-nav" id="navbar-nav">
            <li className="nav-item dropdown">
                <div className="nav-link dropdown-toggle cursor-pointer" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Find
                </div>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                    <li>
                        <Link className="dropdown-item" to="/homeschoolmap">The Homeschool Map</Link>
                    </li>
                    <li>
                        <Link className="dropdown-item" to="/findFamilies">Find Local Families</Link>
                    </li>
                </ul>
            </li>
            { user && user.subscriberStatusActive && user.subscriberStatusActive === true ?
                <li className="nav-item dropdown">
                    <div className="nav-link dropdown-toggle cursor-pointer" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        MySchool+
                    </div>
                    <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                        <li>
                            <Link className="dropdown-item" to="/myschoolplus">Learn More</Link>
                        </li>
                        <li>
                            <Link className="dropdown-item" to="/myschoolplus/my-card">My I.D. Card</Link>
                        </li>
                        <li>
                            <Link className="dropdown-item" to="/myschoolplus/discounts">Discounts</Link>
                        </li>
                        <li>
                            <Link className="dropdown-item" to="/myschoolplus/free-learning-resources">Free Educational Resources</Link>
                        </li>
                        <li>
                            <Link className="dropdown-item" to="/myschoolplus/reciprocals">Reciprocal Navigator</Link>
                        </li>
                        <li>
                            <Link className="dropdown-item" to="/myschoolplus/play-and-learn">Scavenger Hunt</Link>
                        </li>
                    </ul>
                </li>
                :
                <li className="nav-item">
                    <Link className="nav-link" to='/myschoolplus'>
                        MySchool+
                    </Link>
                </li>
            }
            <li className="nav-item">
                <Link className="nav-link" to='/post-a-spot'>
                    Put it on the Map
                </Link>
            </li>  
            <li className="nav-item">
                <Link className="nav-link" to='/dashboard'>
                    Dashboard
                </Link>
            </li>
            <li className="nav-item">
                <a className="nav-link" onClick={logout} href='#!'>
                    Logout
                </a>
            </li>
        </ul>
    );

    const guestLinks = (

        <ul className="navbar-nav" id="navbar-nav">
            <li className="nav-item dropdown">
                <Link className="nav-link dropdown-toggle" to="/#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Find
                </Link>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                    <li>
                        <Link className="dropdown-item" to="/homeschoolmap">The Homeschool Map</Link>
                    </li>
                    <li>
                        <Link className="dropdown-item" to="/findFamilies">Find Local Families</Link>
                    </li>
                </ul>
            </li>
            <li className="nav-item">
                <Link className="nav-link" to='/myschoolplus'>
                    MySchool+
                </Link>
            </li>
            <li className="nav-item">
                <Link className="nav-link" to='/post-a-spot'>
                    Put it on the Map
                </Link>
            </li>  
            <li className="nav-item">
                <button className='basic-button-nav-link nav-link' onClick={() => toggleRegisterModal(!registerModal)}>Login/Sign Up</button>
            </li>
        </ul>
    );

    return (
        <Fragment>
            <header className="navbar navbar-sticky navbar-expand-lg navbar-light">
                    <div className="container position-relative">
                        <Link className="navbar-brand" to="/">
                            <img className="navbar-brand-regular" width="49px" src={LogoImg} alt="myschool logo" />
                            <img className="navbar-brand-sticky" width="49px" src={LogoImg} alt="sticky myschool logo" />
                        </Link>
                    <button onClick={()=>toggleNavbar()} className="navbar-toggler d-lg-none" type="button" aria-label="Toggle navigation">
                       <img src={hamburger} width='40px' alt="open nav panel"/>
                    </button>
                    { !collapsed ?
                        <div className="navbar-inner">
                            {/*  Mobile Menu Toggler */}
                            <button onClick={()=>toggleNavbar()} className="navbar-toggler d-lg-none" type="button" aria-label="Toggle navigation">
                                <img src={iconTimes} width='15px' alt="close nav panel"/>
                            </button>
                            <nav>
                                {!loading && (
                                    <Fragment>{isAuthenticated ? authLinks : guestLinks}</Fragment>
                                )}
                            </nav>
                        </div>
                        :
                        <div className="navbar-lg">
                            <nav>
                                {!loading && (
                                    <Fragment>{isAuthenticated ? authLinks : guestLinks}</Fragment>
                                )}
                            </nav>
                        </div>
                    }
                        
                        
                    </div>
            </header>

            <AuthModals toggleLoginModal={toggleLoginModal} loginModal={loginModal} toggleRegisterModal={toggleRegisterModal} registerModal={registerModal} />
        </Fragment>
    );
};

Header.propTypes = {
  logout: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { logout }
)(withRouter(Header));