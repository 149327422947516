import api from '../utils/api';
import { setAlert } from './alert';
import queryString from 'query-string';
import {
  GET_EVENTS,
  GET_EVENT,
  GET_EVENTS_WITHIN_RADIUS,
  GET_EVENTS_WITHIN_MAPBOUNDS,
  ADD_EVENT,
  UPDATE_EVENT,
  DELETE_EVENT,
  EVENT_ERROR,
  REMOVE_USER_REG
} from './types';


// Post search text query
export const saveTextSearch = (geoData, userLoc, queryObject) => async dispatch => {
  const body = {
    ...queryObject,
    userLocation: userLoc,
    geoData: geoData
  };
  // console.log('geoData:',geoData, ' userLoc: ', userLoc, ' query: ', queryObject);
  try {
    await api.post(`/v1/eventSearch`, body);

  } catch (err) {
    console.log('search not saved:', err);
  }
};

// Get all events
export const getEvents = () => async dispatch => {
  try {
    
    // {date[0]: {$gt: new Date("2020-02-20T00:00:00.000Z")}}
    let query = '';

    const res = await api.get(`/events?${query}`);

    dispatch({
      type: GET_EVENTS,
      payload: res.data
    });


  } catch (err) {
    dispatch({
      type: EVENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get all events by provider AFTER NOW
export const getEventsByProviderId = (providerId) => async dispatch => {
  try {
    const res = await api.get(`/events/provider/${providerId}`);
    dispatch({
      type: GET_EVENTS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: EVENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};


// Get all events by resource AFTER NOW
export const getEventsByResourceId = (resourceId) => async dispatch => {
  try {
    const res = await api.get(`/events/resource/${resourceId}`);
    dispatch({
      type: GET_EVENTS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: EVENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get past class examples
// export const getPastEvents = () => async dispatch => {
//   try {
    
//     const res = await api.get(`/events/pastEvents`);

//     dispatch({
//       type: GET_PAST_ONLINE_CLASSES,
//       payload: res.data
//     });

//   } catch (err) {
//     dispatch({
//       type: EVENT_ERROR,
//       payload: { msg: err.response.statusText, status: err.response.status }
//     });
//   }
// };

// Get event by User Id
export const getMyEvents = () => async dispatch => {
  try {

    const res = await api.get(`/events/myEvents`);
    
    dispatch({
      type: GET_EVENTS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: EVENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get event by event ID
export const getEventByEventId = eventId => async dispatch => {
  
  try {
    const res = await api.get(`/events/${eventId}`);
    
    dispatch({
      type: GET_EVENT,
      payload: res.data
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: EVENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get event by event ID
export const getMyEventById = eventId => async dispatch => {
  // console.log(id);
  try {
    const res = await api.get(`/events/myEvents/${eventId}`);
    
    dispatch({
      type: GET_EVENT,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: EVENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};


// Get all events within Radius of a center point
export const getEventsWithin = (position, queryObject) => async dispatch => {
    
  if (!position.dist ) {
    position = {...position, 
      dist: 50
    };
  };

  const { lat, long, dist } = position;
  Object.keys(queryObject).forEach(key => queryObject[key] === 'none' ? delete queryObject[key] : {});
  Object.keys(queryObject).forEach(key => queryObject[key] === undefined ? delete queryObject[key] : {});
  if(queryObject.dist) {delete queryObject.dist};
  const query = queryString.stringify(queryObject);
  
  try {
    const res = await api.get(`/events/events-within/${lat}/${long}/${dist}?${query}`);
    
    dispatch({
      type: GET_EVENTS_WITHIN_RADIUS,
      payload: res.data.data
    });
  } catch (err) {
    dispatch({
      type: EVENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get events within bounds -- this is for updating the Homeschool Map once the bounds change. 
export const getBoundedEvents = (bounds, filterData) => async dispatch => {
  
  const { latMin, latMax, longMin, longMax } = bounds;
  let queryObject;

  if (!filterData.limit ) {
    queryObject = {...filterData, 
      limit: 50
    };
  } else {
    queryObject = filterData
  }

  Object.keys(queryObject).forEach(key => queryObject[key] === 'none' ? delete queryObject[key] : {});
  
  Object.keys(queryObject).forEach(key => queryObject[key] === undefined ? delete queryObject[key] : {});

  const query = queryString.stringify(queryObject);

  try {
    const res = await api.get(`/events/events-on-map/${latMin}/${latMax}/${longMin}/${longMax}?${query}`);

    dispatch({
      type: GET_EVENTS_WITHIN_MAPBOUNDS,
      payload: res.data.data
    });
  } catch (err) {
    dispatch({
      type: EVENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};




// Validate registration form
export const validateRegistration = ( regFormData ) => async dispatch => {

  try {

    //Log registration info to userBooking array
    const configReg = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    
    // console.log('regFormData', regFormData);

    await api.post('/events/validateRegistration', regFormData, configReg);

  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      window.scrollTo(0,0);
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: EVENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }

};



// Add Event
export const addEvent = ( formData, hostType, hostId, history ) => async dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  try {
    const res = await api.post(`/events/${hostType}/${hostId}`, formData, config);

    dispatch({
      type: ADD_EVENT,
      payload: res.data
    });

    dispatch(setAlert('Event created!', 'success'));

    history.push('/dashboard');

  } catch (err) {

    if (err.response && err.response.data && err.response.data.errors) {
      window.scrollTo(0,0);
      const errors = err.response.data.errors;
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: EVENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });

    if (err.response && err.response.data && err.response.data.msg) {
      window.scrollTo(0,0);
      dispatch(setAlert(err.response.data.msg, 'danger'));
    }
    
  }
};


// Update Event
export const updateEvent = ( formData, eventId, history ) => async dispatch => {

  try {
    const res = await api.put(`/events/${eventId}`, formData);

    dispatch({
      type: UPDATE_EVENT,
      payload: res.data
    });

    dispatch(setAlert('Event updated', 'success'));

    history.push(`/events/${eventId}`);

  } catch (err) {

    const errors = err.response.data.errors;

    if (errors) {
      window.scrollTo(0,0);
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: EVENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });

    if (err.response.data.msg) {
      window.scrollTo(0,0);
      dispatch(setAlert(err.response.data.msg, 'danger'));
    }
    
  }
};


// Server needs upd prior to implementation
// Delete Event
export const deleteEvent = eventId => async dispatch => {
  if (window.confirm('Are you sure? This CANNOT be undone!')) {
    try {
      await api.delete(`/events/${eventId}`);

      dispatch({
        type: DELETE_EVENT,
        payload: eventId
      });

      dispatch(setAlert('Event Deleted', 'success'));

    } catch (err) {

      if (err.response && err.response.data && err.response.data.msg) {
        dispatch({
          type: EVENT_ERROR,
          payload: { msg: err.response.data.msg, status: err.response.status }
        });
        dispatch(setAlert(err.response.data.msg, 'danger'));
      }
    }
  }
};

// Cancel event registration
export const cancelRegistration = (regId) => async dispatch => {
  if (window.confirm('Are you sure you want to cancel this booking?')) {
    try {
      const res = await api.put(`/eventRegistration/cancel/${regId}`);
      dispatch({
        type: REMOVE_USER_REG,
        payload: res.data
      });

      window.scrollTo(0,0);
      dispatch(setAlert('A cancellation email was sent to the parent email address provided.', 'success'));

    } catch (err) {
      dispatch({
        type: EVENT_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status }
      });
    }
  }
};