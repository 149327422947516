import React, { useState, Fragment } from 'react';
import { Modal, ModalBody, Card, CardImg, CardBody, CardText, CardDeck } from 'reactstrap';
import Spinner from '../layout/Spinner';
import orgVAHomeschoolersLogo from '../../images/Org_VA_Homeschoolers.jpg';
import SCSpedLogo from '../../images/SCSpedLogo.png';
import ImageContainer from '../layout/ImageContainer';

const Partnerships = ({ isAuthenticated }) => {

    return (
        <Fragment>
            {/*====== Scroll To Top Area Start ======*/}
            <div id="scrollUp" title="Scroll To Top">
                <i className="fas fa-arrow-up" />
            </div>
            {/*====== Scroll To Top Area End ======*/}
            <div className="main">
                <div className="section faq-area style-two ptb_100">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12 col-md-10 col-lg-7">
                                {/* Section Heading */}
                                <div className="section-heading text-center">
                                    <h2 className="text-capitalize">Partnerships</h2>
                                    <p className="mt-4">We don't make any money off these links.  We just like their stuff and thought you might too.</p>
                                </div>
                            </div>
                        </div>

                        {/* Partnerships */}
                        <div className='row justify-content-center'>
                            <div className='col-12 col-sm-6 col-md-3'>
                                <Card className="color-white partner-card">
                                    <a href="#" target="_blank" rel="noopener noreferrer">
                                        <CardImg top width="80%" src={SCSpedLogo} alt="SPED Homeschool logo" />
                                        <CardBody className="card-body px-0 py-3">
                                            <CardText className='color-myschool-blue text-center'>
                                                Homeschooling organization and podcast focused on families with special needs children.  
                                            </CardText>
                                        </CardBody>
                                    </a>
                                </Card>
                            </div>
                            <div className='col-12 col-sm-6 col-md-3'>
                                <Card className="color-white partner-card">
                                    <a href="https://myschoolathome.io/classProvider/5fe0c6bdc88ab40e9a5a7372" target="_blank" rel="noopener noreferrer">
                                        <CardImg top width="80%" src={orgVAHomeschoolersLogo} alt="Organization of Virginia Homeschoolers logo" />
                                        <CardBody className="card-body px-0 py-3">
                                            <CardText className='color-myschool-blue text-center'>
                                                A statewide homeschooling organization from Virginia.  
                                            </CardText>
                                        </CardBody>
                                    </a>
                                </Card>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Partnerships;


