import React, { useState, Fragment, useEffect } from 'react';
import { Form, FormGroup, Input, Spinner } from 'reactstrap';
import { Redirect, Link } from "react-router-dom";
import { connect } from 'react-redux';
import { login } from '../../actions/auth';
import PropTypes from 'prop-types';


const LoginComponent = ({ login, auth: {isAuthenticated, user}, alerts }) => {
	
	const [formData, setFormData] = useState({
		email: '',
		password: ''
	});

  const { email, password } = formData;
	
	const onChange = e =>
	    setFormData({ ...formData, [e.target.name]: e.target.value });

  const [ isSubmitting, setIsSubmitting ] = useState(false);

	const onSubmit = async e => {
		e.preventDefault();
		setIsSubmitting(true);
    await login(email,password);
	};

  useEffect(()=> {
    setIsSubmitting(false);
  },[alerts]);

	//Redirect if logged in and user loaded
  if(isAuthenticated && user && user._id) {
    return <Redirect to='/dashboard' />;
  }

    return (
      <Fragment>
        <Form onSubmit={e => onSubmit(e)} className="msstd-form container">
          <h4> Login to your Profile </h4>
          <div className="mt-4" />
          <FormGroup>
            <Input type="email" 
                name="email" 
                id="email" 
                placeholder="Email Address"
                value={email}
                onChange={e => onChange(e)} />
          </FormGroup>
          <FormGroup>
            <Input type="password" 
                name="password" 
                id="password" 
                placeholder="Password"
                value={password}
                onChange={e => onChange(e)} />
          </FormGroup>
          <button
            disabled={isSubmitting}
            type="submit"
            className="btn btn-myschool-blue btn-set-WH"
          >
            <span>
              {isSubmitting ? (
                <Spinner size="sm" className='color-white'/>
              ) : (
                "Sign In"
              )}
            </span>
          </button>
        <div>
          Don't have an account? <Link to='/register' className='text-bold text-underline'>Register</Link>
        </div>
        <div>
          Forgot your password? ...if I had a nickel. <Link to='/forgot' className='text-bold text-underline'>Reset Password</Link>
        </div>
        </Form>          
      </Fragment>
    );
};

LoginComponent.propTypes = {
  login: PropTypes.func.isRequired,
  auth: PropTypes.object,
  alerts: PropTypes.array
};

const mapStateToProps = state => ({
	auth: state.auth,
  alerts: state.alert
});

export default connect(mapStateToProps, { login })(LoginComponent);
