import React, { Fragment, useState } from 'react'
import { Link } from 'react-router-dom';
import { FormGroup, Input, Button, Label, DropdownItem, Row, Col } from 'reactstrap';
import queryString from 'query-string';
import capitalizeName from '../../utils/capitalizeName';

const FilterProvidersAndResources = ({ filterCatData, setFilterCatData, filterReligData, setFilterReligData, filterSubjData, setFilterSubjData, addQueries, setAddQueries, togglePandRFilter, pAndRFilter }) => {

  const onChange = e =>
    setAddQueries({ ...addQueries, [e.target.name]: e.target.value });
  const onCatChange = e =>
    setFilterCatData({ ...filterCatData, [e.target.name]: e.target.checked });
  const onReligChange = e => 
    setFilterReligData({ ...filterReligData, [e.target.name]: e.target.checked });
  const onSubjChange = e => 
    setFilterSubjData({ ...filterSubjData, [e.target.name]: e.target.checked });


  return ( 
    <Fragment>
      <div className='my-2'>
        I'm looking for a
      </div>
      <FormGroup check className='my-2'>
        {Object.keys(filterCatData).map(cat => 
            <Label check key={cat} className='mx-1 homeschool-map-filter-input'>
              <Input type="checkbox" name={cat} checked={filterCatData[cat]} onChange={e => onCatChange(e)}/>
              {` ${capitalizeName(cat)}`}
            </Label>
          )
        }
      </FormGroup>
      <DropdownItem divider/>
      <div className='my-2'>
        <span>
          within{' '}
          <Input type='number' 
            name='dist' 
            value={addQueries.dist} 
            onChange={e=>onChange(e)} 
            className='homeschool-map-filter-input-dist'
            />
          {` miles`}
        </span>
      </div>
      <DropdownItem divider/>
      <div className='my-2'>
        where my child can learn about any of the following subjects
      </div>
      <FormGroup check className='my-2'>
      {Object.keys(filterSubjData).map(sub => 
          <Label check key={sub} className='mx-1 homeschool-map-filter-input'>
            <Input type="checkbox" name={sub} checked={filterSubjData[sub]} onChange={e => onSubjChange(e)}/>
            {(sub === 'pe') ? 'PE' : ` ${capitalizeName(sub)}`}
          </Label>
        )
      }
      </FormGroup>
      <DropdownItem divider/>
      <div className='my-2'>
        and is based in any of these faiths{' '}
      </div>
      <FormGroup check className='my-2'>
        {Object.keys(filterReligData).map(relig => 
            <Label check key={relig} className='mx-1 homeschool-map-filter-input'>
              <Input type="checkbox" name={relig} checked={filterReligData[relig]} onChange={e => onReligChange(e)}/>
              {` ${capitalizeName(relig)}`}
            </Label>
          )
        }
      </FormGroup> 
          
        <Button type='submit' className='btn block-button btn-myschool-blue'>Submit</Button> 
    </Fragment>
  )
};

export default FilterProvidersAndResources;
