import React, { useState } from 'react';
import { Container} from 'reactstrap';
import qrToPortfolio from '../../../images/qrCode-to-portfolio.png';
import ImageContainer from '../../layout/ImageContainer';

const PortfolioPartnerWhatIs = () => {

	const [we, setWe] = useState([
		{
			id: 1,
			heading: 'Sends you a custom QR code.',
			text: '',
		},
		{
			id: 2,
			heading: 'Helps bring our members to you.',
			text: '',
		},
		{
			id: 3,
			heading: 'Tracks member visits for their child\'s homeschool portfolio.*' ,
			text: '',
		},
		{
			id: 4,
			heading: 'Provides the chance to win rewards and prizes to our members for visiting.',
			text: '',
		}
	]);

	const [you, setYou] = useState([
		{
			id: 1,
			heading: 'Keep the QR code at reception so our members can scan it upon entry.',
			text: '',
		}
	]);

	const [notes, setNotes] = useState([
		{
			id: 1,
			noteId: '*',
			noteDesc:'Many states require homeschoolers to keep a portfolio.  All others are highly encouraged to do so both for legal reasons, and because they can use it to show the things they\'ve learned and areas of focus in their studies.'  
		}
	]);

  return(
		<Container>
			<div className="row justify-content-around align-items-center">
		        <div className="row justify-content-around align-items-center">
		            <div className="col-12 col-lg-6 order-2">
		            	<h4 className='text-color-blue text-1-2rem my-2'>
		            		What Does MySchool Do?
	            		</h4>
		                <div className="service-text pt-3 pt-lg-0">
		                    {we.map((item,i)=> {
								return (
									<div key={item.id} className='text-indent-paragraph my-2 text-color-blue'>
											<i className="fas fa-star color-gold"/>{' '}<span className='text-bold'>{item.heading}</span>{` ${item.text}`}{item.link? <button type='button' onClick={()=>history.push(`${item.link}`)} className='basic-button text-bold text-09rem ml-2' >Go see it >></button>:''}
									</div>
								)
							})}
		                </div>
		                <h4 className='text-color-blue text-1-2rem mt-4 mb-2'>
		            		What Do You Do?
	            		</h4>
		                <div className="service-text pt-3 pt-lg-0">
		                    {you.map((item,i)=> {
								return (
									<div key={item.id} className='text-indent-paragraph my-2 text-color-blue'>
											<i className="fas fa-star color-gold"/>{' '}<span className='text-bold'>{item.heading}</span>{` ${item.text}`}{item.link? <button type='button' onClick={()=>history.push(`${item.link}`)} className='basic-button text-bold text-09rem ml-2' >Go see it >></button>:''}
									</div>
								)
							})}
		                </div>
		            </div>
		            <div className="col-12 col-lg-6 order-1">
		                <div className="service-thumb mx-auto">
		                    <ImageContainer
		                      src={qrToPortfolio}
		                      thumb={qrToPortfolio}
		                      className='d-inline d-lg-none' 
		                      alt='kids at a museum'
		                    />
		                    <ImageContainer
		                      src={qrToPortfolio}
		                      thumb={qrToPortfolio}
		                      className='d-none d-lg-inline' 
		                      alt='kids at a museum'
		                    />
		                </div>
		            </div>
		        </div>
	        </div>
	        <div>
	    	{notes.map((note,i)=> {
				return (
						<div key={note.id} style={{height:'3rem'}} className='text-color-blue text-075rem'>
								{`${note.noteId} ${note.noteDesc}`}
						</div>
					)
				})}
			</div>
		</Container>
  );
};

export default PortfolioPartnerWhatIs;