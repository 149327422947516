import React, { Fragment, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Map } from 'react-leaflet';
import L from 'leaflet';
import EventFormMap from './EventFormMap';

const corner1 = L.latLng(90,180);
const corner2 = L.latLng(-90,-180);
const maxBounds = L.latLngBounds(corner1, corner2);

// BEGIN MAP
const EventMapWrapper = ({ formData, setFormData }) => {

  const history = useHistory();
  const params = useParams();
  
  //MAP STATE
  const [bounds, setBounds] = useState({});
  const currentposition = [formData.lat, formData.long];

  let APIKey;
  if (process.env.NODE_ENV === 'production') {
    APIKey = process.env.REACT_APP_MAPTILER_API_KEY
  } else {
    APIKey = process.env.REACT_APP_MAPTILER_API_TEST_KEY
  }

  return (
    <Fragment>
      <div>Below is the location where your event's spot will appear. You can click and hold on the marker to drag it to a new location. <span className='text-bold'>This will not change the address you put in, only where the event appears on the map.</span>  If you use MySchool's integrated booking tools (available for free meetups and hosts with connected accounts), the exact address will be hidden on your listing and <span className='text-bold'>only</span> provided in the booking confirmation email to members that have registered for the event.</div>
      <Map className="map-wrapper-thumb" center={currentposition} zoom={14} maxBounds={maxBounds}>
        <EventFormMap 
          currentposition={currentposition}
          bounds={bounds}
          setBounds={setBounds}
          APIKey={APIKey}
          formData={formData}
          setFormData={setFormData}
        />
      </Map>
      <div className='text-center my-1'>Location: ({formData.lat ? formData.lat.toFixed(4)*1 : undefined}, {formData.long ? formData.long.toFixed(4)*1: undefined})</div>
      <div className='text-center'>Click "Next" when you like your spot.</div>
    </Fragment>
  )
};

export default EventMapWrapper;
