import React, { Fragment, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SpinnerComponent from '../../layout/Spinner';
import { Map } from 'react-leaflet';
import L from 'leaflet';
import { getProfilesWithin, getCurrentProfile } from '../../../actions/profile';
import { setAlert } from '../../../actions/alert'; 
import { registerAndFindMeResources, updateFavResource, updateFavProvider } from '../../../actions/auth';
import { getUserData } from '../../../actions/user';  
import { saveTextSearch, getBoundedMapResults, getResultsWithin } from '../../../actions/map';
import HomeschoolMap from './HomeschoolMap';
import HomeschoolMapListWrapper from './HomeschoolMapListWrapper';
import FilterProvidersAndResources from '../FilterProvidersAndResources';
import { Row, Col, Button, ButtonGroup, FormGroup, Modal, ModalBody, ButtonDropdown, DropdownToggle, DropdownMenu, Form, Label, Input, Tooltip, Badge } from 'reactstrap';
import queryString from 'query-string';
import resourceIconImg from '../../../images/resource-map-icon.svg';
import yellowIconImg from '../../../images/map-marker_yellow_svg.svg';
import RegisterWithResourceRequest from '../../auth/RegisterWithResourceRequest';
import SendEmailToUser from '../SendEmailToUser';
import LoginPopup from '../../auth/LoginPopup';
import RegisterPopup from '../../auth/RegisterPopup';
import SearchBar from '../../../utils/SearchBar';
import axios from 'axios';
import lockLocked from '../../../images/icon-lock-locked-blue.svg';
import lockLockedRed from '../../../images/icon-lock-locked-red.svg';
import ReactGA from 'react-ga';

const corner1 = L.latLng(90,180);
const corner2 = L.latLng(-90,-180);
const maxBounds = L.latLngBounds(corner1, corner2);
let APIKey;
if (process.env.NODE_ENV === 'production') {
  APIKey = process.env.REACT_APP_MAPTILER_API_KEY
} else {
  APIKey = process.env.REACT_APP_MAPTILER_API_TEST_KEY
}

// BEGIN MAP
const HomeschoolmapWrapper = ({ getProfilesWithin, getCurrentProfile, profile, resource, leafletMap, auth, user, copyTextToClipboard, saveTextSearch, getBoundedMapResults, getResultsWithin, getUserData, updateFavResource, updateFavProvider }) => {
  const history = useHistory();
  const [isMapUpdating, setMapUpdating] = useState(false);

  let searchQuery = {};
  searchQuery = queryString.parse(history.location.search);

  const [ loginModal, toggleLoginModal ] = useState(false);
  const [ registerModal, toggleRegisterModal ] = useState(false);
  
  const swapLoginRegister = () => {
    toggleLoginModal(!loginModal);
    toggleRegisterModal(!registerModal);
  };
  const [ createProfileModal, toggleCreateProfileModal ] = useState(false);

  //MAP STATE
  const [currentLatLong, setCurrentLatLong] = useState({
    lat: 39.3,
    long: -76.6
  });

  const [userLatLong, setUserLatLong] = useState({
    lat: 39.3,
    long: -76.6
  });

  const [currentZoom, setCurrentZoom] = useState(4);
  const currentposition = [currentLatLong.lat, currentLatLong.long];
  
  const [bounds, setBounds] = useState({
    latMin: 39,
    latMax: 40,
    longMin: -78,
    longMax: -75
  });

  //FILTER STATE
  //checkboxes to show or not show the data currently in redux store
  const [profileFilter, toggleProfileFilter] = useState(true);
  const [pAndRFilter, togglePandRFilter] = useState(true);
  const [showMyFavs, toggleShowMyFavs] = useState(false);
  const [showProfiles, toggleShowProfiles] = useState(false);
  const [showResources, toggleShowResources] = useState(true);
  const showPeople = () => {
    
    toggleShowMyFavs(false);
    toggleShowProfiles(true);
    toggleShowResources(false);
  };

  const showSpots = () => {
    toggleShowProfiles(false);
    toggleShowResources(true);
  };

  //Dropdowns for query filters
  const [ dDPandRFilters, toggleDDPandRFilters ] =  useState(false);

  //Filter form Data
  const [ filterCatData, setFilterCatData ] = useState({
      tutor: false,
      cooperative: false,
      tutorial: false,
      'hybrid homeschool': false,
      'homeschool enrichment center': false,
      'microschool': false,
      park: false,
      museum: false,
      library: false,
      'resource center': false,
      umbrella: false,
      'nature center': false,
      'forest school': false,
      farm: false,
      'support group': false,
      aquarium: false,
      zoo: false,
      other: false
  });
  const [ filterReligData, setFilterReligData ] = useState({
      Secular: false,
      Christianity: false,
      Judaism: false
  });
  const [ filterSubjData, setFilterSubjData ] = useState({
      math: false,
      english: false,
      science: false,
      'social studies': false,
      art: false,
      music: false,
      pe: false,
      health: false,
      history: false,
      'foreign language': false,
      engineering: false,
      'environmental science': false
  });

  const supportedReciprocals = ['acm','aza','astc','anca','gbha'];
  const [addQueries, setAddQueries] = useState({
    tags:[],
    dist: 50,
    reciprocals: []
  });
  
  const [ textSearch, setTextSearch ] = useState("");

  //show hide 'update filter' button
  const [ showUpdateFilter, toggleShowUpdateFilter ] =  useState(false);

  //show hide 'update map here' to refresh with map bounds
  const [ showUpdateMapHere, setShowUpdateMapHere ] = useState(false);

  const onFilterSubmit = e => {
    e.preventDefault();
    ReactGA.event({ category: "HomeschoolMap", action: "Search", label: "filter search" });
    toggleDDPandRFilters(false);
    if (showUpdateFilter) {
      toggleShowUpdateFilter(false);
    };
    const catArray = Object.keys(filterCatData).filter(key=>filterCatData[key] === true);
    const religArray = Object.keys(filterReligData).filter(key=>filterReligData[key] === true);
    const subjArray = Object.keys(filterSubjData).filter(key=>filterSubjData[key] === true);
    const query = {
      subjects: subjArray,
      religion: religArray,
      category: catArray,
      dist: addQueries.dist,
      tags: addQueries.tags,
      reciprocals: addQueries.reciprocals
    }
    getResultsWithin(loadLocation, query);
    saveTextSearch(loadLocation, userLatLong, query);
    setMapUpdating(true);
    history.replace({search: '?'});
  };

  const onFilterMapBoundsSubmit = e => {
    e.preventDefault();
    let query = {};
    if (textSearch.length > 0) {
      ReactGA.event({ category: "HomeschoolMap", action: "Search", label: "text search" });
      query = {
        textSearch: textSearch,
        dist: addQueries.dist
      }
    } else {
      ReactGA.event({ category: "HomeschoolMap", action: "Search", label: "filter search" });
      toggleDDPandRFilters(false);
      if (showUpdateFilter) {
        toggleShowUpdateFilter(false);
      };
      const catArray = Object.keys(filterCatData).filter(key=>filterCatData[key] === true);
      const religArray = Object.keys(filterReligData).filter(key=>filterReligData[key] === true);
      const subjArray = Object.keys(filterSubjData).filter(key=>filterSubjData[key] === true);
      query = {
        subjects: subjArray,
        religion: religArray,
        category: catArray,
        dist: addQueries.dist,
        tags: addQueries.tags,
        reciprocals: addQueries.reciprocals
      }
    }
    getBoundedMapResults(bounds, query);
    setShowUpdateMapHere(false);
    setMapUpdating(true);
    history.replace({search: '?'});
  };

  const onTextSearchSubmit = () => {
    ReactGA.event({ category: "HomeschoolMap", action: "Search", label: "text search" });
    const query = {
      textSearch: textSearch,
      dist: addQueries.dist,
      reciprocals: addQueries.reciprocals
    }
    getBoundedMapResults(bounds, query);
    setShowUpdateMapHere(false);
    saveTextSearch(bounds, userLatLong, {textSearch: textSearch});
    setMapUpdating(true);
    history.replace({search: '?'});
  };


  //RECIPROCALS QUICK FIND
  const [ dDReciprocals, toggleDDReciprocals ] = useState(false); 
  const updateRecipArray = (selected) => {
    const prevArray = addQueries.reciprocals
    let newArray = [];
    if (prevArray.includes(selected)) {
      newArray = prevArray.filter(i=>i !== selected);
    } else {
      newArray = [...prevArray, selected];
    }
    setAddQueries({...addQueries, reciprocals: newArray});
    console.log('newArray',newArray)
  }
  const submitUpdRecips = (e) => {
    toggleDDReciprocals(false);
    onFilterSubmit(e);
  }

  // const [ displayUpdateQuery, toggleDisplayUpdateQuery ] = useState(false);

  const [ noResultsNearYouModal, toggleNoResultsNearYouModal ] = useState(false);

  //Email user
  const [ emailUserModal, setEmailUserModal ] = useState(false);
  const [ emailFormData, setEmailFormData ] = useState({
    userId: '',
    username: 'MySchool User',
    message:'',
    signatureName:'',
    signatureEmail:''
  });

  function onSendEmail(id) {
    setEmailUserModal(!emailUserModal);
    const emailProfile = profile.profiles.find((e) => e._id === id);
    setEmailFormData({
      userId: emailProfile.user._id,
      username: emailProfile.user.username
    });
  }
  // const [displayModalShareUrl, toggleModalShareUrl] = useState(false);

  const goToResourceOptions = () => {
    history.push('/resource-options');
  }

  //member profiles search radius
  const profileDist = 50;

  //Set location of map load and find resources there
  const [ loadLocation, setLoadLocation ] = useState({lat: 39.3,long: -76.6});
  const [isLocationLoaded, setLocationLoaded ] = useState(false);
  //If no location provided at all
  const [ isNoLocation, setNoLocation ] = useState(false);

  let noLocation = { lat: 0, long: 0 };
  
  useEffect(() => {

    var geoLocOptions = {
      enableHighAccuracy: true,
      timeout: 8000
    };

      navigator.geolocation.getCurrentPosition((position) => {

          //set query position
          if ('lat' in searchQuery && 'long' in searchQuery) {
            setLoadLocation({ lat: searchQuery.lat, long: searchQuery.long });
            setCurrentLatLong({ lat: searchQuery.lat, long: searchQuery.long });
          } else {
            setLoadLocation({ lat: position.coords.latitude, long: position.coords.longitude });
            setCurrentLatLong({ lat: position.coords.latitude, long: position.coords.longitude });
          }
          setUserLatLong({ lat: position.coords.latitude, long: position.coords.longitude });
          
          //set query Zoom
          if ('zoom' in searchQuery) {
            setCurrentZoom(searchQuery.zoom);
          } else if ('lat' in searchQuery && 'long' in searchQuery) {
            setCurrentZoom(16);
          } else {
            setCurrentZoom(10);
          }

          setLocationLoaded(true);

        }, async () => {
          window.alert('You blocked your location, which is totally cool with us. We just use it to set up the starting position for the map. We\'ll center the map in your general area instead.');
          // below is for IP address location services, but Mac prevents this
          try {
            const ipAddress =  await axios.get('https://ipapi.co/json');
            // console.log(ipAddress.data);

            let userIpLocation = { lat: ipAddress.data.latitude, long: ipAddress.data.longitude};
              if ('lat' in searchQuery && 'long' in searchQuery) {
                setLoadLocation({ lat: searchQuery.lat, long: searchQuery.long });
                setCurrentLatLong({ lat: searchQuery.lat, long: searchQuery.long });
              } else {
                setLoadLocation(userIpLocation);
                setCurrentLatLong(userIpLocation);
              }
              setUserLatLong(userIpLocation);

              //set query Zoom
              if ('zoom' in searchQuery) {
                setCurrentZoom(searchQuery.zoom);
              } else if ('lat' in searchQuery && 'long' in searchQuery) {
                setCurrentZoom(16);
              } else {
                setCurrentZoom(10);
              }

              setLocationLoaded(true);
          } catch (err) {
            window.alert('You blocked all location services, which is totally cool with us. We just use it to set up the starting position for the map, but you can just zoom in to your location and refresh the map there.  Re-enable location services anytime so you don\'t have to do that!');
            setLoadLocation(noLocation);
            setCurrentLatLong(noLocation);
            setUserLatLong(noLocation);
            setCurrentZoom(1);
            setLocationLoaded(true);
            setNoLocation(true);
          }
        },geoLocOptions
      );
    
  }, []);

  useEffect(()=> {
    if (isLocationLoaded) {
      // console.log(loadLocation);
      if (searchQuery && Object.keys(searchQuery).length > 0) {
        //Add to state
        if (searchQuery.dist) setAddQueries({...addQueries, dist: searchQuery.dist}); 
        if (searchQuery.reciprocals && Array.isArray(searchQuery.reciprocals)) {
          setAddQueries({...addQueries, reciprocals: searchQuery.reciprocals})
        } else if (searchQuery.reciprocals) {
          setAddQueries({...addQueries, reciprocals: [searchQuery.reciprocals]})
        }

        //convert Category query
        let catQuery = {};
        if (searchQuery && searchQuery.category && Array.isArray(searchQuery.category)) {
          for (let i=0; i<searchQuery.category.length; i++) {
            const cat = searchQuery.category[i].toString();
            catQuery = {...catQuery, [cat]: true}
          }
        } else if (searchQuery && searchQuery.category) {
            const cat = searchQuery.category.toString();
            catQuery = {...catQuery, [cat]: true}
        }
        const filterCatObj = Object.assign({}, filterCatData, catQuery);
        setFilterCatData(filterCatObj);

        //convert Subjects query
        let subjQuery = {};
        if (searchQuery && searchQuery.subject && Array.isArray(searchQuery.subject)) {
          for (let i=0; i<searchQuery.subject.length; i++) {
            const subj = searchQuery.subject[i].toString();
            subjQuery = {...subjQuery, [subj]: true}
          }
        } else if (searchQuery && searchQuery.subject) {
            const subj = searchQuery.subject.toString();
            subjQuery = {...subjQuery, [subj]: true}
        }
        const filterSubjObj = Object.assign({}, filterSubjData, subjQuery);
        setFilterSubjData(filterSubjObj);

        toggleDDPandRFilters(false);
        const catArray = Object.keys(filterCatObj).filter(key=>filterCatObj[key] === true);
        const query = {
          subjects: [],
          religion: [],
          category: catArray,
          dist: addQueries.dist,
          tags: addQueries.tags,
          reciprocals: searchQuery.reciprocals
        }
        getResultsWithin(loadLocation, query);
        setMapUpdating(true);
      } else {
        getResultsWithin(loadLocation, {});
        setMapUpdating(true);
      }
    };
    
  },[isLocationLoaded]);

  useEffect(() => {
    if (isLocationLoaded && auth.isAuthenticated) {
      getProfilesWithin(loadLocation, profileDist);
    }
  },[isLocationLoaded, auth]);

  useEffect(() => {
    if (auth.isAuthenticated) {
      getUserData();
    }
  },[auth]);

  useEffect(() => {
    if (auth.isAuthenticated) {
      getCurrentProfile();
    }
  },[auth]);

  useEffect(() => {
    setBounds({
        latMin: currentLatLong.lat - 0.5,
        latMax: currentLatLong.lat + 0.5,
        longMin: currentLatLong.long - 1,
        longMax: currentLatLong.long + 1
    }); 
  },[currentLatLong]);


  const timeoutNoResultsModal = () => {
    setTimeout(()=>{ toggleNoResultsNearYouModal(true); }, 4000);
  };

  function setNoResultsModal() {
    timeoutNoResultsModal();
  };

  function stopNoResultsModal() {
    clearTimeout(timeoutNoResultsModal);
  };


  //Handle Map Focus
  const [ mapFlyTo, setMapFlyTo ] = useState(null);

  //split all query matching results into arrays based upon the collection they are in.
  // const [providerPoints, setProviderPoints] = useState([]);
  // const [resourcePoints, setResourcePoints] = useState([]);

  // useEffect(()=> {
  //   console.log('checking status of resource', resource.resources.length);
  //   if (resource && resource.resources && resource.resources.length > 0) {
  //     console.log('sortingResources');
  //     const resourceArray = resource.resources.filter(r => r.collectionName === "resource");
  //     const providerArray = resource.resources.filter(r => r.collectionName === "provider");
  //     console.log(resourceArray);
  //     console.log(providerArray);
  //     // setResourcePoints(resource.resources.filter(r => r.collectionName === "resource"));
  //     // setProviderPoints(resource.resources.filter(r => r.collectionName === "provider")); //technically this is redundant, after resourceFilter, everything left should be providers.
  //   }
  // },[resource.resources, resource.resourceLoading]);

  const [ currFilterArray, setCurrFilterArray] = useState([]);
  //current filters
  const updateCurrentFilters = () => {
    setMapUpdating(false);
    let currFilters = [];
    if (textSearch.length > 0) {
      currFilters = [`"${textSearch}" within map area`];
    } else {
      currFilters = [];
      const catArray = Object.keys(filterCatData).filter(key=>filterCatData[key] === true);
      if (catArray.length > 0) currFilters = [...currFilters, ...catArray];
      const religArray = Object.keys(filterReligData).filter(key=>filterReligData[key] === true);
      if (religArray.length > 0) currFilters = [...currFilters, ...religArray];
      const subjArray = Object.keys(filterSubjData).filter(key=>filterSubjData[key] === true);
      if (subjArray.length > 0) currFilters = [...currFilters, ...subjArray];
      if (addQueries.dist && addQueries.reciprocals.length < 1) currFilters = [...currFilters, `within ${addQueries.dist} miles`];
      if (addQueries.reciprocals) currFilters = [...currFilters, ...addQueries.reciprocals];
    } 
    setCurrFilterArray(currFilters);
  };

  //UPDATE FILTERS ANYTIME THERE IS A CHANGE IN THE RESOURCE OBJECT
  useEffect(()=> {
    updateCurrentFilters();
  },[resource]);

  const clearFilters = () => {
    history.replace({search: '?'});
    const clearedCats = {}
    Object.keys(filterCatData).forEach(key => clearedCats[key] = false);
    setFilterCatData(clearedCats);
    const clearedSubj = {}
    Object.keys(filterSubjData).forEach(key => clearedSubj[key] = false);
    setFilterSubjData(clearedSubj);
    const clearedRelig = {};
    Object.keys(filterReligData).forEach(key => clearedRelig[key] = false);
    setFilterReligData(clearedRelig);
    setAddQueries({tags:[],dist: 50,reciprocals:[]});
    getResultsWithin(loadLocation, {});
    setMapUpdating(true);
  }


  //Quick Finds
  const selectFieldTrips = () => {
    toggleShowResources(true);
    toggleShowProfiles(false);
    ReactGA.event({ category: "HomeschoolMap", action: "Quick find", label: "field trips" });
    const query = {
      category: ['park','museum','nature center','farm','zoo','aquarium'],
      dist: addQueries.dist
    }
    setFilterCatData({...filterCatData, park:true, museum:true, 'nature center':true, farm:true, zoo:true, aquarium:true})
    getResultsWithin(loadLocation, query);
    setMapUpdating(true);
    history.replace({search: '?'});
    saveTextSearch(loadLocation, userLatLong, query);
  };

  const selectGroups = () => {
    toggleShowResources(true);
    toggleShowProfiles(false);
    ReactGA.event({ category: "HomeschoolMap", action: "Quick find", label: "groups" });
    const query = {
      category: ['cooperative','tutorial','forest school', 'support group'],
      dist: addQueries.dist
    }
    setFilterCatData({...filterCatData, cooperative:true, tutorial:true, 'forest school':true, 'support group':true, 'microschool':true, 'hybrid homeschool':true, 'homeschool enrichment center': true})
    getResultsWithin(loadLocation, query);
    setMapUpdating(true);
    history.replace({search: '?'});
    saveTextSearch(loadLocation, userLatLong, query);
  };

  useEffect(()=> {
    if ('show' in searchQuery) {
      if (searchQuery.show === 'profiles') {
        toggleShowResources(false);
        toggleShowProfiles(true);
      } else if (searchQuery.show === 'resources') {
        toggleShowResources(true);
        toggleShowProfiles(false);
      } 
    }
  },[searchQuery]);

  return profile.loading || resource.resourceLoading || !isLocationLoaded ? (
    <SpinnerComponent />
  ) : (
      <Fragment>
        <div className="filterPane form">                    
          <div className='text-center'>
          <h2>
            <span>The {history.location.pathname === "/myschoolmap" ?` MySchool ` : ` Homeschool `}Map</span>
          </h2>
          <h3 className='my-2'>
            Places Your Kids Can Learn
          </h3>
          <h3>
            <span className="text-font-tangerine color-myschool-blue">Made with love, by users like you! -{' '}<Link className="text-underlined" to={"/post-a-spot"}>Contribute Now!</Link></span>
          </h3>
          </div>
          <div className='d-flex justify-content-start py-1 mx-1'>
            <ButtonDropdown group={false} direction="down" isOpen={dDPandRFilters} toggle={() => toggleDDPandRFilters(!dDPandRFilters)}>
              <DropdownToggle
                className='cursor-pointer btn btn-myschool-blue-border btn-map-filter'
              >
                  Filters 
                {' '}<i className="fas fa-caret-down"/>
              </DropdownToggle>
              <DropdownMenu className='homeschool-map-filter-dd' style={{zIndex:1001}}>
                <div className='m-2'>
                  <Form className='form' onSubmit={e => onFilterSubmit(e)}>
                    <FilterProvidersAndResources 
                      bounds={bounds}
                      showMyFavs={showMyFavs}
                      toggleShowMyFavs={toggleShowMyFavs}
                      togglePandRFilter={togglePandRFilter}
                      filterCatData={filterCatData}
                      filterReligData={filterReligData}
                      filterSubjData={filterSubjData}
                      setFilterCatData={setFilterCatData}
                      setFilterReligData={setFilterReligData}
                      setFilterSubjData={setFilterSubjData}
                      addQueries={addQueries}
                      setAddQueries={setAddQueries}
                    />
                  </Form>
                </div>
              </DropdownMenu>
            </ButtonDropdown>
            <Col>
              <SearchBar string={textSearch} setString={ setTextSearch } onClick={onTextSearchSubmit}/>
            </Col>
          </div>

            <div className='d-flex justify-content-between mx-1'>
              <div className='d-inline-flex'>
                Quick find:{''}
                  <div>
                    <button className='btn btn-myschool-blue-border' onClick={()=>selectFieldTrips()}>
                      <img src={resourceIconImg} alt="resource icon" height="15px" width="15px"/>
                      Field Trips
                    </button>
                  </div>
                  <div>
                    <button className='btn btn-myschool-blue-border' onClick={()=>selectGroups()}>
                      <img src={yellowIconImg} alt="resource icon" height="15px" width="15px"/>
                      Programs
                    </button>
                  </div>
                  {/* IN DEV
                  <div>
                    <ButtonDropdown group={false} direction="auto" isOpen={dDReciprocals} 
                      toggle={
                        !auth || !auth.user || !auth.user.subscriberStatusActive ?
                          () => history.push('/myschoolplus')
                          :
                          () => toggleDDReciprocals(!dDReciprocals)
                          }
                      > 
                      <DropdownToggle
                        className='cursor-pointer btn btn-myschool-blue-border btn-map-filter'
                      >
                          {!auth || !auth.user || !auth.user.subscriberStatusActive ? <img src={lockLockedRed} width='15px'/>:''}{` Reciprocals`} 
                        {' '}<i className="fas fa-caret-down"/>
                      </DropdownToggle>
                      <DropdownMenu style={{zIndex:1001}}>
                        <div className='m-2'>
                          <Form className='form' onSubmit={(e) => submitUpdRecips(e)}>
                            <FormGroup check className='my-2'>
                              {supportedReciprocals.map((recip,i) => 
                                  <Label check key={recip} className='mx-1 homeschool-map-filter-input'>
                                    <Input type="checkbox" name={recip} checked={addQueries.reciprocals.includes(recip)} onChange={e => updateRecipArray(e.target.name)}/>
                                    {` ${recip}`}
                                  </Label>
                                )
                              }
                            </FormGroup>
                            <div className='d-flex justify-content-center'>
                              <Button type='submit' className='btn btn-myschool-blue'>
                                Submit
                              </Button>
                            </div>
                          </Form>
                        </div>
                      </DropdownMenu>
                    </ButtonDropdown>
                  </div>
                  */}
              </div>
              <div className='filter-search-items'>
                { auth.isAuthenticated ?
                  <Label check className='mx-1 homeschool-map-filter-input'>
                    <Input type="checkbox" name="showMyFavs" checked={showMyFavs} onChange={() => toggleShowMyFavs(!showMyFavs)}/>
                      Only show favorites
                  </Label>
                  :
                  <Label check className='mx-1 homeschool-map-filter-input color-light-grey'>
                    <Input type="checkbox" name="showMyFavs" checked={showMyFavs} disabled/>
                      Only show favorites (log in)
                  </Label>
                } 
              </div>
            </div>

            <div className='d-flex justify-content-between mx-1'>
              <div className='mx-1'>
                <span className={ isMapUpdating ? 'color-myschool-red blinker text-bold':'text-bold'}>
                  {`Showing ${resource.resources.length} results`}
                </span>
                {' '}
                { !isMapUpdating && currFilterArray && currFilterArray.length > 0 ? currFilterArray.map((s,i) => {
                      return <Badge key={i} className={`pill-color-blue text-07rem mr-1`} pill>{s}</Badge>
                  })
                  :
                  <Badge className={`pill-color-red text-07rem mr-1 blinker`} pill>...Loading</Badge>
                }
              </div>
              <div>
                <button className='btn btn-myschool-blue btn-small' onClick={()=>clearFilters()}>
                  Clear filters 
                </button>
              </div>
            </div>
        </div>

        <div> 
          <ButtonGroup style={{width:'100%'}}>
            <Button type='button' className={showResources ? 'btn btn-myschool-blue':'btn btn-myschool-blue-border'} onClick={()=>showSpots()}>
              Spots
            </Button>
            <Button type='button' className={showProfiles ? 'btn btn-myschool-red':'btn btn-myschool-red-border'} 
              onClick={ 
                auth && auth.isAuthenticated && profile && profile.myProfile && profile.myProfile.showOnMap && profile.myProfile.showOnMap === true ?
                  ()=>showPeople() 
                : auth && auth.isAuthenticated ?
                  ()=>toggleCreateProfileModal(true)
                : ()=>toggleLoginModal(true) 
              }>
              {!auth || !auth.isAuthenticated || !profile || !profile.myProfile || !profile.myProfile.showOnMap ? <img src={lockLockedRed} className='mr-1' width='12px' alt='locked icon'/>: ''}People
            </Button>
          </ButtonGroup>
        </div>  

        <Row>
          <Col sm={12} md={8} className='homeschoolmap-map-container'>
            <Map className="homeschoolmap-map" center={[loadLocation.lat, loadLocation.long]} zoom={currentZoom} maxBounds={maxBounds}>
              <HomeschoolMap 
                profile={profile} 
                resources={resource.resources}
                profileFilter={profileFilter}
                pAndRFilter={pAndRFilter}
                currentposition={[loadLocation.lat, loadLocation.long]}
                userLatLong={userLatLong}
                auth={auth}
                user={user}
                bounds={bounds}
                setBounds={setBounds}
                toggleLoginModal={toggleLoginModal}
                showMyFavs={showMyFavs}
                APIKey={APIKey}
                onSendEmail={onSendEmail}
                history={history}
                mapFlyTo={mapFlyTo}
                filterCatData={filterCatData}
                filterSubjData={filterSubjData}
                filterReligData={filterReligData}
                addQueries={addQueries}
                setAddQueries={setAddQueries}
                setShowUpdateMapHere={setShowUpdateMapHere}
                showUpdateMapHere={showUpdateMapHere}
                setNoLocation={setNoLocation}
                showProfiles={showProfiles}
                showResources={showResources}
                onFilterMapBoundsSubmit={onFilterMapBoundsSubmit}
              />
            </Map>
          </Col>
          <Col sm={12} md={4} className='homeschoolmap-list-container'>
            <HomeschoolMapListWrapper
              profile={profile} 
              resources={resource.resources}
              // providers={providerPoints}
              auth={auth}
              user={user}
              showMyFavs={showMyFavs}
              bounds={bounds}
              setBounds={setBounds}
              setMapFlyTo={setMapFlyTo}
              history={history}
              setAlert={setAlert}
              toggleLoginModal={toggleLoginModal}
              setCurrentZoom={setCurrentZoom}
              updateFavProvider={updateFavProvider}
              updateFavResource={updateFavResource}
              isNoLocation={isNoLocation}
              showResources={showResources}
              showProfiles={showProfiles}
            />
          </Col>
        </Row>

        <Modal isOpen={loginModal} centered toggle={() => toggleLoginModal(!loginModal)}>
          <ModalBody className='text-center'>
            <div className='float-right'>
              <button className="basic-button" 
                onClick={() => toggleLoginModal(false)}>
                <i className='fa fa-times color-light-grey'/>
              </button>
            </div>
            You have to register and login to do that!
            <LoginPopup loginModal={loginModal} toggleLoginModal={toggleLoginModal}/>
            <button onClick={()=>swapLoginRegister()} className='basic-button text-bold text-underlined'>I need to Register</button>
          </ModalBody>
        </Modal>

        <Modal isOpen={registerModal} centered toggle={() => toggleRegisterModal(!registerModal)}>
          <ModalBody className='text-center'>
            <div className='float-right'>
              <button className="basic-button" 
                onClick={() => toggleRegisterModal(false)}>
                <i className='fa fa-times color-light-grey'/>
              </button>
            </div>
            <RegisterPopup registerModal={registerModal} toggleRegisterModal={toggleRegisterModal} history={history}/>
            <button onClick={()=>swapLoginRegister()} className='basic-button text-bold text-underlined'>Go to Login</button>
          </ModalBody>
        </Modal>

        <Modal isOpen={noResultsNearYouModal} centered toggle={() => toggleNoResultsNearYouModal(!noResultsNearYouModal)}>
          <ModalBody className='text-center'>
            <h5>We don't have any resources or providers nearby yet!  </h5>
            <RegisterWithResourceRequest 
              currentLatLong={currentLatLong} 
              toggleNoResultsNearYouModal={toggleNoResultsNearYouModal} 
              setAlert={setAlert} 
              registerAndFindMeResources={registerAndFindMeResources} 
              history={history}
              auth={auth}
              stopNoResultsModal={stopNoResultsModal}
            />
          </ModalBody>
        </Modal>

        <Modal isOpen={emailUserModal} centered toggle={() => setEmailUserModal(!emailUserModal)}>
          <ModalBody>
            <SendEmailToUser emailFormData={emailFormData} setEmailFormData={setEmailFormData} setEmailUserModal={setEmailUserModal}/>
          </ModalBody>
        </Modal>

        <Modal isOpen={createProfileModal} centered toggle={() => toggleCreateProfileModal(!createProfileModal)}>
          <ModalBody className='text-center my-2'>
            <div className='float-right'>
              <button className="basic-button" 
                onClick={() => toggleCreateProfileModal(false)}>
                <i className='fa fa-times color-light-grey'/>
              </button>
            </div>
            <h4 className='color-myschool-blue my-2'>You need a profile to do that!</h4>
            <button type='button' onClick={()=>history.push('/findfamilies')} className='btn btn-modal-red'>Create a Profile</button>
          </ModalBody>
        </Modal>
        
      </Fragment>
  );
};

HomeschoolmapWrapper.propTypes = {
  getProfilesWithin: PropTypes.func.isRequired,
  getCurrentProfile: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  resource: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  getBoundedMapResults: PropTypes.func.isRequired,
  getResultsWithin: PropTypes.func.isRequired,
  getUserData: PropTypes.func.isRequired,
  updateFavProvider: PropTypes.func.isRequired,
  updateFavResource: PropTypes.func.isRequired,
  saveTextSearch: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  user: state.user,
  profile: state.profile,
  resource: state.resource,
  leafletMap: state.leafletMap
});

export default connect(
  mapStateToProps,
  { getProfilesWithin, getCurrentProfile, saveTextSearch, getBoundedMapResults, getResultsWithin, getUserData, updateFavProvider, updateFavResource }
)(HomeschoolmapWrapper);