import React, { Fragment, useEffect, useState } from "react";
import { useParams, useHistory } from 'react-router-dom';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Container, Spinner, Alert } from 'reactstrap';
import { getMyProviders, getMyProviderById } from '../../actions/provider';
import { getCurrentProfile } from '../../actions/profile';
import { setAlert } from '../../actions/alert';
import CreateProviderEvent from './CreateProviderEvent';
import CreateProfileEvent from './CreateProfileEvent';
import ChooseSpot from './ChooseSpot';
import blankProfileImage from '../../images/profile-img-blank.svg';
import mapIcon from '../../images/group-map-icon.svg'
import SpinnerComponent from '../layout/Spinner';
import api from '../../utils/api';

const CreateEventWrapper = ({ auth: { loading, user }, profile: { profileLoading, myProfile }, provider: {providerLoading, providers, myProvider}, getMyProviders, getMyProviderById, getCurrentProfile, setAlert}) => {
  const params = useParams();
  const history = useHistory();
  const [spot, setSpot] = useState('');

  useEffect(()=> {
    if (user) {
      getMyProviders();
    }
  },[user]);

  useEffect(()=> {
    if (user) {
      getCurrentProfile();
    }
  },[user]);

  useEffect(()=> {
    if (params.hostType == 'provider' && params.hostId !== undefined) {
      setSpot(`${params.hostId}`); 
      getMyProviderById(params.hostId);
    }
  },[params]);


  const profileButtonConstants = (
    <Fragment>
      <div className="featured-img mb-3">
        <img src={blankProfileImage} width='100px' className='round-img m-2' alt='profile image'/>
      </div>
      <div className="icon-text">
        <h4 className="mb-2">I am</h4>
        <p>Host an event using your personal profile information</p>
      </div>
    </Fragment>
  );

  //Define the button function and output text based on what details the user still needs to fill out
  const profileButton = (
    <Fragment>
      {myProfile && myProfile.isHostReady && myProfile._id ?
        <button type='button' className='basic-button' onClick={()=>history.push(`/create-event/profile/${myProfile._id}${history.location.search}`)}>
          <div className="image-box choose-path-card text-center icon-1 p-4">
            {profileButtonConstants}
          </div>
        </button>
        :
        myProfile && !myProfile.isHostReady ?
        <button type='button' className='basic-button' onClick={()=>history.push(`/create-profile`)}>
          <div className="image-box choose-path-card text-center icon-1 p-4">
            {profileButtonConstants}
            <Alert color='danger' className='text-09rem'>
              Profile details missing - Click to complete them!
            </Alert>
          </div>
        </button>
        :
        !myProfile ?
        <button type='button' className='basic-button' onClick={()=>history.push(`/create-profile`)}>
          <div className="image-box choose-path-card text-center icon-1 p-4">
            {profileButtonConstants}
            <Alert color='danger' className='text-09rem'>
              Create a profile to unlock! (Click here to do so now!)
            </Alert>
          </div>
        </button>
        :''
      }
    </Fragment>
  );

  const providerButtonConstants = (
    <Fragment>
      {/* Image */}
      <div className="featured-img mb-3">
          <img src={mapIcon} width='100px' className='round-img m-2' alt='spot map icon'/>
      </div>
      {/* Text */}
      <div className="icon-text">
          <h4 className="mb-2">One of My Spots</h4>
          <p>Host an event as a representative of one of your spots</p>
      </div>
    </Fragment>
  );

  const providerButton = (
    <Fragment>
      {myProvider && myProvider.isHostReady && myProvider._id ?
        <Fragment>
          <button type='button' onClick={()=>history.push(`/create-event/provider/${myProvider._id}`)} className='basic-button'>
            <div className="image-box choose-path-card text-center icon-1 p-4">
              {providerButtonConstants}
            </div>
          </button>
          <ChooseSpot providerLoading={providerLoading} providers={providers} myProvider={myProvider} history={history} spot={spot} setSpot={setSpot} params={params}/>
        </Fragment>
        :
        myProvider && !myProvider.isHostReady ?
        <Fragment>
          <button type='button' className='basic-button' onClick={()=>history.push(`/dashboard`)}>
            <div className="image-box choose-path-card text-center icon-1 p-4">
              {providerButtonConstants}
              <Alert color='danger' className='text-09rem'>
                {`Hosting Disabled. Additional profile details are required. Click here to go to your dashboard and update your spot profile.`}
              </Alert>
            </div>
          </button>
          <ChooseSpot providerLoading={providerLoading} providers={providers} myProvider={myProvider} history={history} spot={spot} setSpot={setSpot} params={params}/>
        </Fragment>
        :
        !providerLoading && !myProvider ?
        <Fragment>
          <button type='button' className='basic-button' onClick={()=>history.push(`/create-provider`)}>
            <div className="image-box choose-path-card text-center icon-1 p-4">
              {providerButtonConstants}
              <Alert color='danger' className='text-09rem'>
                {`Please choose a spot.`}
              </Alert>
            </div>
          </button>
          <ChooseSpot providerLoading={providerLoading} providers={providers} myProvider={myProvider} history={history} spot={spot} setSpot={setSpot} params={params}/>
        </Fragment>
        :''
      }
    </Fragment>
  );


  //if the host is not defined in params, this will bring up buttons to allow the user to choose one.
  const noHost = (
    <Fragment>
      <section className="section features-area style-two overflow-hidden pb-5">
        <div className="container">
          <div className='text-center my-3'>
              <h2 className='color-myschool-blue'>Awesome! Who is hosting the event?</h2>
          </div>
          <div className='row'>
            <div className="col-12 col-md-6 my-3 res-margin">
              {profileButton}
            </div>
            <div className="col-12 col-md-6 my-3 res-margin">
              {providerButton}
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );

  return providerLoading || profileLoading || loading || !user ? (
      <SpinnerComponent className='color-myschool-blue'/>
    ) : (
    <Container>
      <div>
        {params.hostType === 'provider' && params.hostId !== "undefined" && params.hostId !== undefined  ?
          (
            <CreateProviderEvent/>
          )
        : params.hostType === 'profile' && params.hostId !== "undefined" && params.hostId !== undefined ? 
          (
            <CreateProfileEvent/>
          )
        : 
          (
            <Fragment>{noHost}</Fragment>
          )
        }
      </div>
    </Container>
  );
}


CreateEventWrapper.propTypes = {
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  getCurrentProfile: PropTypes.func.isRequired, 
  getMyProviders: PropTypes.func.isRequired,
  getMyProviderById: PropTypes.func.isRequired,
  provider: PropTypes.object.isRequired,
  setAlert: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  profile: state.profile,
  provider: state.provider
});

export default connect(mapStateToProps,{ getMyProviders, getMyProviderById, getCurrentProfile, setAlert })(CreateEventWrapper);

