import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { Alert } from 'reactstrap';
import { setAlert } from '../../actions/alert';
import ReactGA from 'react-ga';

const LikeWhatWeDoBanner = ({ setAlert }) => {
	const history = useHistory();
	const location = useLocation();
	const [visible, setVisible] = useState(true);

	const onDismiss = () => { 
		setVisible(false);
		window.sessionStorage.setItem('likeWhatWeDoBanner',true);
	}

	const goToMySchoolPlus = async () => {
		ReactGA.event({ category: "MySchool_Plus", action: "Banner_click", label: `` });
		history.push(`/myschoolplus/subscribe`);
	};

	const label = (`Find discounts, free curriculum resources, and more tools with `)

	// const copyEventUrl = async () => {
	// 	try {
	// 		await navigator.clipboard.writeText(`${window.location.origin}`);
	// 		setAlert('Copied to clipboard', 'success');
	// 		ReactGA.event({ category: "likeWhatWeDoBanner", action: "Copy our URL" });
	// 	} catch (err) {
	// 		setAlert('Error copying to clipboard, sorry :(', 'danger');
	// 	}
	// };

	useEffect(()=> {
		const isBannerDismissed = window.sessionStorage.getItem('likeWhatWeDoBanner');
		// console.log('isBannerDismissed', isBannerDismissed);
		if (isBannerDismissed ) {
			setVisible(false);
		} 
	},[location.href]);

    return(
    	<Fragment>
	        <Alert color="info" isOpen={visible} toggle={onDismiss}>
		        <div className='text-center text-09rem px-4'>
			        {label}
			        <span>
			        	<button type='button' 
				        	className='basic-button text-underlined text-09rem color-myschool-red text-bold'
				        	onClick={()=>goToMySchoolPlus()}
				        	>
				        	{`MySchool+`}
			        	</button>
			        </span>
			        {/*<a href="https://facebook.com/groups/myschoolathome" target='_blank' rel='noopener noreferrer' className="basic-button text-bold text-09rem">Check it out!>></a>*/}  
			        {/*
			        <div>
				        { navigator.clipboard ? 
					        <button 
					        	type='button' 
					        	className='basic-button text-underlined text-09rem color-myschool-red text-bold' 
					        	onClick={()=>copyEventUrl()}>
					        	Click here to copy our URL!
				        	</button>
				        	: 'Click on the URL bar to copy the site address!'
				        }
			        </div>
			      	*/}
		    	</div>
		    </Alert>
	    </Fragment>
    );
}

LikeWhatWeDoBanner.propTypes = {
  setAlert: PropTypes.func.isRequired
};

export default connect(
  null,
  { setAlert }
)(LikeWhatWeDoBanner);