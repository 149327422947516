import React from 'react';
import { Container, Col, Row } from 'reactstrap';

const EducationalPhilosophies = () => {
    return(
        <Container>
            <Row>
                <Col className='text-center'>
                    <img src="/assets/images/logo100-100.svg" className="mslogo-img" alt="Logo for About Page"/>
                </Col>
            </Row>
            <Row>
                <Col className='text-center'>
                    <h4 className="aboutHeading">Educational Philosophies</h4>
                </Col>
            </Row>
            <Row>
                <Col>
                    <ol>
                        <li>
                        <strong>Traditional </strong> - This is what most public and private schools follow.  Textbooks and resources are dedicated to each subject.  That information is discussed or read about and you are tested on recollection of those facts.
                        </li>
                        <li>
                        <strong>Classical Education </strong> (<a href="https://welltrainedmind.com/" target='_blank' rel='noopener noreferrer'>welltrainedmind.com</a>) - Childhood learning is divided into three different stages: grammar (the foundation years), logic (the middle school years), and rhetoric (the high school years). This three-part model is called the trivium.
                        </li>
                        <li>
                        <strong>Unit Study</strong> - Unit studies take a theme or topic and incorporate many typical school subjects (language arts, math, science, social studies, music, art, health, etc.) into that "unit."
                        </li>
                        <li> 
                        <strong>Leadership Education</strong> (<a href="https://www.tjed.org/" target='_blank' rel='noopener noreferrer'>tjed.org</a>) - This leadership model, also known as Thomas Jefferson Education, or TJEd, focuses on teaching students how to think as opposed to what to think. It emphasizes reading classics and discussing them with a mentor (a parent or other adult). TJEd.org describes it as follows: “Every student has inner genius, and the best educational results are usually attained when a mentor continually assesses what the individual student needs and tries to help deliver it.”
                        </li>
                        <li>
                        <strong>Unschooling (Self-Directed Education)</strong> (<a href="https://www.self-directed.org/" target='_blank' rel='noopener noreferrer'>self-directed.org</a>) - This style of schooling allows the topics of study to be driven by the child so that their educational path is self-directed. This method hinges on the assertion that children are curious by nature, and have an innate desire to learn.  Often unschooling families may use a unit study approach to delving into topics or themes that the child(ren) choose.
                        </li>
                        <li>
                        <strong>Montessori</strong> (<a href="https://amshq.org/" target='_blank' rel='noopener noreferrer'>amshq.org</a>) - Maria Montessori, an Italian physician, developed this philosophy in the early 1900s. It focuses on hands-on experience, movement, choice, and order in the learning environment.
                        </li>
                        <li>
                        <strong>Charlotte Mason </strong>(<a href="https://simplycharlottemason.com/" target='_blank' rel='noopener noreferrer'>simplycharlottemason.com</a>) - Mason was a 19th century British educational reformer whose philosophy emphasized high-quality literature, nature study, and narration -- or students' repeating learned information in their own words. 
                        </li>
                        <li>
                        <strong>Knowledge-Based Schooling</strong> (<a href="https://www.coreknowledge.org/" target='_blank' rel='noopener noreferrer'>coreknowledge.org</a>) -  Founded by E.D. Hirsch, the organization that promotes this style of education describes it as follows: “Knowledge-based schooling puts the emphasis of early education on teaching and learning an enabling core of broadly shared knowledge—enabling because it builds strong foundations for later learning and opens doors to effective participation and mutual understanding in the wider society. Such knowledge is possessed by successful adults and taken for granted by literate writers and speakers. It’s the broad and diverse knowledge that makes responsible citizenship possible.” 
                        </li>
                        <li>
                        <strong>Eclectic </strong>- This is a blending or mixing-and-matching of different methods where families adapt different philosophies to different situations, topics, etc.
                        </li>
                        <li>
                        <strong>Independent </strong>- For those who don’t fit into the aforementioned categories.  You should include more information on your philosophy in your bio if you choose this.
                        </li>
                        <li>
                        <strong>Undecided </strong>- I’m not sure yet.  I’m still weighing my options.
                        </li>
                    </ol>
                </Col>
            </Row>
        </Container>
    );
}

export default EducationalPhilosophies;