import React, { Fragment, Component, useState, useEffect } from 'react';
import { Jumbotron, ButtonGroup } from 'reactstrap';
import ReactGA from 'react-ga';
import jumbotronImgLg from '../../../images/jumbotron-connections_1200-350.png';
import jumbotronImgMd from '../../../images/jumbotron-connections_700-350.png';
import jumbotronImgSm from '../../../images/jumbotron-connections_350-350.png';
import useWindowSize from '../../../utils/useWindowSize';

const Hero2 = ({ scrollToVideo, scrollToPeople, scrollToPlaces, scrollToThings, history }) => {

    const windowSize = useWindowSize();

    const [jumboImage, setJumboImage] = useState({
        image:jumbotronImgLg,
        width: '1200px',
        height: '350px'
    });

    useEffect(()=> {
        if (windowSize.width < 768 && windowSize.width >= 576) {
            setJumboImage({image:jumbotronImgMd, width:'700', height:'350'})
        } else if (windowSize.width < 576) {
            setJumboImage({image:jumbotronImgSm, width:'700', height:'350'})
        } else {
            setJumboImage({image:jumbotronImgLg, width:'1200', height:'350'});
        }
    },[windowSize]);

    const [ data, setData ] = useState({
        heading: "The Portal to Your Local Homeschooling Community",
        headingBase: "Find Local Homeschooling",
        headingSuffixArray: ["Families","Spots","Resources","and More!"],
        // headingSuffixArrayLinks:["/findFamilies","/homeschoolmap","/MySchoolPlus"]
        problem: "What if \"school\" wasn't just ONE place?  What if it was EVERYWHERE you went to learn?",
        solution: "MySchool helps you find, connect with, and learn alongside other homeschooling families near you.",
        lifeIsBetterNow: "",
        tagLine: "Learn more together, with MySchool."
    });

    const onPeopleClick = () => {
        ReactGA.event({ category: "Landing", action: "People to meet" });
        scrollToPeople();
    };

    const peopleButton = (
            <button 
                onClick={()=>onPeopleClick()} 
                className="btn btn-myschool-red glowing">
                People to Meet
            </button>
    );

    const onPlacesClick = () => {
        ReactGA.event({ category: "Landing", action: "Places to go" });
        scrollToPlaces();
    };

    const placesButton = (
            <button 
                onClick={()=>onPlacesClick()} 
                className="btn btn-myschool-yellow color-myschool-blue glowing">
                Places to Go
            </button>
    );

    const onThingsToDoClick = () => {
        ReactGA.event({ category: "Landing", action: "Things to do" });
        scrollToThings();
    };

    const thingsButton = (
            <button 
                onClick={()=>onThingsToDoClick()} 
                className="btn btn-myschool-blue glowing">
                Things to Do
            </button>
    );

    let suffixPosition = 0;
    const [headingSuffix,setHeadingSuffix] = useState(data.headingSuffixArray[suffixPosition]);

    const headingCycler = () => {
        setInterval(function() {
            suffixPosition++;
            setHeadingSuffix((prevState) => {
                if (suffixPosition >= data.headingSuffixArray.length) {
                    suffixPosition = 0;
                }
                return data.headingSuffixArray[suffixPosition];
            });
        }, 2000); 
    };

    useEffect(()=> {
        headingCycler();
        return clearInterval(headingCycler);
    },[]);

        return (
            <Fragment>
            <section className="section hero-area">
                
            
                <div className="container pt-5 px-5">
                    <div className='row text-center mb-4'>
                        <div className='col-12'>
                            <div className='text-center'>
                                <h1 className="color-myschool-blue">
                                    <div>
                                        {`${data.headingBase} `}
                                    </div>
                                    <div>
                                        {headingSuffix}
                                    </div>
                                </h1>
                            </div>
                        </div>
                    </div>
                    {/*Jumbotron*/}
                    <div className='row text-center mb-4'>
                        <div className='col-12'>
                            <div className='jumbotron-container'>
                                <img src={ jumboImage.image } width={jumboImage.width} height={jumboImage.height} className="" alt="Jumbotron - MySchool connections image"/>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center justify-content-center">
                        {/* Welcome Intro Start */}
                        <div className="col-12">
                            <div className="welcome-intro">
                                <div className='text-center'>
                                    <div className='col-lg-8 offset-lg-2'>
                                        <div className="text-1-1rem my-4 mb-2">
                                            <div className='color-myschool-blue text-bold my-3'>{data.solution}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            </Fragment>
        );
};

export default (Hero2);