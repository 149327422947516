import React, { useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
// import ReactGA from 'react-ga';

const SalonLanding = () => {
  const history = useHistory();


  // const watchVideo = (videoName) => {
    // ReactGA.event({ category: "FiresideChat", action: "Watch video", label: `${videoName}` });
    // console.log(videoName);
  // };

  const videos = [
    {
      id: 1,
      name: 'Jenny Grove-Bradshaw of Compass Classes',
      state: 'Virginia',
      link: 'https://www.youtube.com/embed/feflvLrFimM?rel=0'
    },
    {
      id: 2,
      name: 'Jennifer Deville and Kristin Royce of Global Foundations Tutorial',
      state: 'Maryland',
      link: 'https://www.youtube.com/embed/vlukDHdjt_s?rel=0'
    },
    {
      id: 3,
      name: 'College Admissions for Homeschoolers',
      state: 'National',
      link: 'https://www.youtube.com/embed/-MZJroyExCU?rel=0'
    }
  ];


  // //create Refs for each element in the array
  // const arrLength = videos.length;
  // const videoRef = useRef([]);

  // if (videoRef.current.length !== arrLength) {
  //   // add or remove refs
  //   videoRef.current = Array(arrLength).fill().map((_, i) => videoRef.current[i]);
  // }

  // // //set scrollTo a specific element
  // // const scrollToRef = ref => ref.current.scrollIntoView({
  // //     behavior: "smooth",
  // //     block: "nearest",
  // //     inline: "start"
  // //   });
  // // const scrollToVideo = (i) => {
  // //   console.log(elRefs,'/',i);
  // //   // if (i >= 0 && i < arrLength && Number.isInteger(i*1)) {
  // //   //   elRefs.current[i].scrollIntoView({
  // //   //       behavior: "smooth",
  // //   //       block: "nearest",
  // //   //       inline: "start"
  // //   //   });
  // //   // }
  // //   scrollToRef(elRefs[i]);
  // // };

  // const scrollToVideo = id => {
  //   // videoRef.current = 1;
  //   console.log('videoRef',videoRef);
  //   // refs[id].current.scrollIntoView({
  //   //   behavior: 'smooth',
  //   //   block: 'start',
  //   // });
  // }

    
  // // useEffect(()=> {
  // //   scrollToVideo(1);
  // // },[]);

  return !videos || videos.length < 1 ? (
    <div>
      No videos currently available.  Check out out YouTube channel to watch!
    </div>
  ) : (
    <Container className='text-center'>
      <h1 className='color-myschool-blue my-3'>Salon Discussions</h1>
      <h4 className='color-myschool-blue my-3'>Chats about homeschooling from experienced homeschoolers</h4>
      <div>
        { videos.map((video,i)=> {
            return(
              <Row key={video.id} className='my-5'> 
                <h5 className='color-myschool-blue mb-3'>{`Episode ${video.id}: ${video.name}`}</h5>
                <Col md={{size:8, offset:2}} className='vid-frame iframe-container'>
                  <div
                    style={{
                      position: "relative",
                      paddingBottom: "56.25%" /* 16:9 */,
                      paddingTop: 25,
                      height: 0
                    }}
                  >
                    <iframe
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%"
                      }}
                      src={video.link} //'https://www.youtube.com/embed/EUQ5EP_DJh8?rel=0'
                      frameBorder="0" 
                      allow='autoplay; encrypted-media'
                      allowFullScreen
                      title={video.name}
                    />
                  </div>
                </Col>
              </Row>
            )
          })
        }
      </div>
    </Container>
  );
};

export default SalonLanding;