import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { Container, Input, Label } from 'reactstrap';
import SpinnerComponent from '../layout/Spinner';

const ChooseSpot = ({ providers, providerLoading, history, setSpot, spot, myProvider, params }) => {

  const handleChange = (e) => {
    if (e.target.value === 'none') {
      history.push(`/create-provider`);
    } else {
      history.push(`/create-event/provider/${e.target.value}${history.location.search}`);
    }
  }

  return (
      <Fragment>
        <Input
          type='select'
          name='spot'
          onChange={e => handleChange(e)}
          >
          {myProvider && myProvider._id && myProvider.providerName && params.hostId === myProvider._id ?
            <option value='myProvider._id'>{myProvider.providerName}</option>
            :
            <option value=''>Choose spot...</option>
          }
          {providers && providers.length > 0 ?
            providers.map(spot=>
              (
                <option key={spot.id} value={spot.id}>{spot.providerName}</option>
              )
            )
            :
            <option value='none'>{`No Registered Spots - Click to create one!`}</option>
          }
        </Input>
      </Fragment>
  );
};

export default (ChooseSpot);