import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, useParams, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import Spinner from '../layout/Spinner';
import { Card, CardHeader, CardTitle, Container } from 'reactstrap';
import EventBriefInfo from './EventBriefInfo';
import EventDetails from './EventDetails';
// import WhoIsAttending from './WhoIsAttending';
import { getEventByEventId } from '../../actions/event';
import ReactGA from 'react-ga';

const EventDetailWrapper = ({ auth, getEventByEventId, event: {event, eventLoading} }) => {

	const params = useParams();
  const history = useHistory();

	useEffect(() => {
    getEventByEventId(params.eventId);
	}, []);

  useEffect(()=> {
    ReactGA.event({ category: "event", action: "view_event", label: `${params.eventId}`});
  },[]);

  return eventLoading || !event || !auth? (
        <Spinner />
      ) : (
        <Container>
          <div className='d-flex'>
            <div className='p-2'>
              <Link to={`/events`} className='btn btn-myschool-blue'><i className="fas fa-arrow-left"/>{' '}Back to Find Events</Link>
            </div>
          </div> 
          <Card className='class-card special-hover'>
            <CardHeader>
              <CardTitle className="text-primary-ms class-title">
                {event.title}
                { auth && auth.user && auth.user._id && event && event.user && event.user._id && event.user._id === auth.user._id &&
                  <button 
                    className='btn btn-myschool-blue float-right' 
                    onClick={()=>history.push(`/edit-event/${event._id}`)}
                    >
                    <i className='fas fa-pen '/>{` Edit Event`}
                  </button>
                }
              </CardTitle>  
            </CardHeader>
            <EventBriefInfo event={event}/>
            <EventDetails event={event} auth={auth} history={history}/>
          
            {/* !event.isOffSite && auth.isAuthenticated ?
              <WhoIsAttending event={event} isAuthenticated={auth.isAuthenticated} hostUser={event.user} />
              :''
            */}
          </Card>
        </Container>
  );
};


EventDetailWrapper.propTypes = {
  event: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  getEventByEventId: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  event: state.event
});

export default connect(
  mapStateToProps,
  { getEventByEventId }
)(EventDetailWrapper);
