import React, { useState } from "react";
import queryString from 'query-string';
import api from '../../utils/api'; 

let fbAppId;
let redirectURI;
if (process.env.NODE_ENV === 'production') {
  fbAppId = process.env.REACT_APP_FACEBOOK_APP_ID;
  redirectURI = `https://myschoolathome.io/login/facebook/authenticate`;
} else {
  fbAppId = process.env.REACT_APP_FACEBOOK_APP_ID_TEST;
  redirectURI = `https://localhost:3000/login/facebook/authenticate`;
}

const FacebookLoginButton = () => {

  const [isSubmitting, setIsSubmitting] = useState(false);

  const beginLoginProcess = async () => {
    setIsSubmitting(true);

    try {
      const scopeArray = ['email, public_profile']; //Advanced permissions incl. user_link, user_location, user_friends 

      const fbParams = {
        client_id: fbAppId,
        redirect_uri: redirectURI,
        scope: scopeArray.join(','), // comma seperated string - https://developers.facebook.com/docs/permissions/reference#login_permissions
        response_type: 'code',
        auth_type: 'rerequest',
        state: process.env.REACT_APP_FACEBOOK_STATE_CONF
      };
      console.log('fbParams from Login Button: ',fbParams);
      const facebookLoginUrl = `https://www.facebook.com/v12.0/dialog/oauth?${queryString.stringify(fbParams)}`;
      window.location = facebookLoginUrl;
    } catch (err) {
      setIsSubmitting(false);
      console.log(err);
    }
  };

  return (
    <div>
      <button type='button' className='btn' style={{backgroundColor:"#3b5999"}} onClick={()=>beginLoginProcess()}>
        <span className="button-label"><i className="fab fa-facebook color-white"/>{' '}Sign in with Facebook</span>
      </button>
    </div>
  );
};

export default FacebookLoginButton;