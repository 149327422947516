import React, { useState, Fragment } from 'react';
import blankProfileImage from '../../images/profile-img-blank.svg';
import { Row, Col } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import ImageContainer from '../layout/ImageContainer';


const ProviderSidebarProfile = ({ provider: { category, image, hideAddress, lat, long, address, city, state, zip, social } }) => {

  const history = useHistory();

  const addressSelector = (a, c, s, z) => {
    if (navigator.userAgent.toLowerCase().match(/mobile/i)) {
        const urlMobile=encodeURI(`https://maps.google.com/maps/dir/?daddr=${a}, ${c}, ${s} ${z}`);
        window.open(urlMobile,"","rel=noopener,rel=noreferrer");
      } else {
        const urlDesktop= encodeURI(`https://google.com/maps/dir/?api=1&destination=${a}, ${c}, ${s} ${z}`);
        window.open(urlDesktop,"","rel=noopener,rel=noreferrer");
      }
      
  }

  const latLongSelector = (la, lo) => {
    if (navigator.userAgent.toLowerCase().match(/mobile/i)) {
        const urlMobile=encodeURI(`https://maps.google.com/maps/dir/?daddr=${la},${lo}`);
        window.open(urlMobile,"","rel=noopener,rel=noreferrer");
      } else /* else use Google */{
        const urlDesktop= encodeURI(`https://google.com/maps/dir/?api=1&destination=${la}, ${lo}`);
        window.open(urlDesktop,"","rel=noopener,rel=noreferrer");
      }
  }

    return (
        <div className='overflow-hidden'>
            <aside className="sidebar">
                <div className='text-center'>
                  {!image && category && (category === 'museum' || category === 'nature center' || category === 'park' )?
                    <div className='mt-3' style={{
                      backgroundImage: `url(/assets/images/badge_${category.toLowerCase().replace(/ /g, '')}.svg)`,
                      backgroundSize: 'cover',
                      backgroundRepeat: 'no-repeat',
                      width:'150px',
                      height:'150px',
                      marginLeft: 'auto',
                      marginRight: 'auto'
                    }}></div>
                    :
                    <ImageContainer
                      src={image && image !== 'default.jpg' ? image: blankProfileImage}
                      thumb={blankProfileImage}
                      width='150px' 
                      height='150px' 
                      className='round-img' 
                      alt='profile image'
                    />
                  }
                </div>
                {/* Single Widget */}
                <div className="single-widget">
                    {/* Category Widget */}
                    <div className='m-2 text-center'>
                        {address && <div>{address}</div>}
                        {long && lat && 
                          <div>
                            <button onClick={()=>history.push(`/homeschoolmap?lat=${lat}&long=${long}&zoom=16`)} className='basic-button text-underlined text-14px text-bold'>
                              <i className="fas fa-angle-double-left"/>{' '}Find it on our map 
                            </button>
                          </div>
                        }
                        {address && hideAddress === true ? 
                          <div> 
                            { lat && long &&
                              <button onClick={()=>latLongSelector(lat, long)} className='basic-button text-underlined text-14px'>
                                Get Google Map Directions <i className="fas fa-angle-double-right"/>
                              </button>
                            }
                          </div>
                        :
                          <div>
                          {address && city && state && zip &&
                            <Fragment>
                              <button onClick={()=>addressSelector(address, city, state, zip)} className='basic-button text-underlined text-14px'>
                                Get Google Map Directions{' '}<span><i className="fas fa-angle-double-right"/></span>
                              </button>
                            </Fragment>
                          }
                          </div>
                        }
                        <div>{city}, {state} {zip}</div>
                    </div>
                </div>
                {/* Single Widget */}
                <div className="single-widget">
                    {/* Category Widget */}
                    <div className='m-2 text-center'>
                      <Row>
                        <Col className='d-flex justify-content-center'>
                          {social && social.twitter && (
                            <div className='mx-1'>
                              <a href={social.twitter} target='_blank' rel='noopener noreferrer'>
                                <i className='fab fa-twitter fa-2x' />
                              </a>
                            </div>
                          )}
                          {social && social.facebook && (
                            <div className='mx-1'>
                              <a href={social.facebook} target='_blank' rel='noopener noreferrer'>
                                <i className='fab fa-facebook fa-2x' />
                              </a>
                            </div>
                          )}
                          {social && social.youtube && (
                            <div className='mx-1'>
                              <a href={social.youtube} target='_blank' rel='noopener noreferrer'>
                                <i className='fab fa-youtube fa-2x' />
                              </a>
                            </div>
                          )}
                          {social && social.linkedin && (
                            <div className='mx-1'>
                              <a href={social.linkedin} target='_blank' rel='noopener noreferrer'>
                                <i className='fab fa-linkedin fa-2x' />
                              </a>
                            </div>
                          )}
                          {social && social.instagram && (
                            <div className='mx-1'>
                              <a href={social.instagram} target='_blank' rel='noopener noreferrer'>
                                <i className='fab fa-instagram fa-2x' />
                              </a>
                            </div>
                          )}
                        </Col>
                      </Row>
                    </div>
                  </div>
            </aside>
        </div>
    );
}

export default ProviderSidebarProfile;