import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import blankProfileImage from '../../images/profile-img-blank.svg';
import { Card, Row } from 'reactstrap';
import ImageContainer from '../layout/ImageContainer';

const ProfileItem = ({ profile, user }) => {

  return (
    <Card className='profile my-3 p-4'>
      <Row>
        <div className='col-sm-12 col-md-4'>
          <div>
            <ImageContainer
              src={profile.image && profile.image !== 'default.jpg' ? profile.image: blankProfileImage}
              thumb={blankProfileImage}
              width='100px' 
              height='100px' 
              className='round-img' 
              alt='profile image'
            />
          </div>
        </div>
        <div className='col-sm-12 col-md-8'>
          {user && user.username && <div className='text-bold'>{user.username}</div>}
          {profile && profile.user && profile.user.username && <div className='text-bold'>{profile.user.username}</div>}
          <div>
            {profile && profile.city}, {profile && profile.state} {profile && profile.zip}
          </div>
            <Link 
              to={
                user && user._id ?
                `/profile/${user._id}`
                :
                `/profile/${profile.user._id}`
              } 
              className='btn btn-primary'>
              Go to Profile
            </Link>
        </div>
      </Row>
    </Card>

  );
};

ProfileItem.propTypes = {
  profile: PropTypes.object.isRequired
};

export default ProfileItem;
