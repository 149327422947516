import React, { useState, Fragment, useEffect } from 'react';
import { Alert, Spinner } from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getCurrentProfile } from '../../../actions/profile';
import AuthModals from '../../auth/AuthModals';
import RegisterAndProfileModal from '../../auth/RegisterAndProfileModal';
import ImageContainer from '../../layout/ImageContainer';
import AnimatedXMark from '../../../utils/AnimatedXMark';
import AnimatedCheckmark from '../../../utils/AnimatedCheckmark';

const AreYouReady = ({ auth: {user, loading, isAuthenticated}, profile: {myProfile, profileLoading}, getCurrentProfile }) => {

	const history = useHistory();

	const [ loginModal, toggleLoginModal ] = useState(false);
	const [ registerModal, toggleRegisterModal ] = useState(false);
	const [ regAndProfModal, toggleRegAndProfModal ] = useState(false);


	useEffect(()=> {
		if (user) {
			getCurrentProfile();
		}
	},[user]);

	const [ levelCompleted, setLevelCompleted ] = useState(0);
	//levels: none = 0, register = 1, profile = 2, children = 3

	useEffect(()=> {
		if (!loading && user && myProfile && myProfile.children && myProfile.children.length > 0 ) {
			setLevelCompleted(3)
		} else if (!loading && user && myProfile && myProfile.isLocated) {
			setLevelCompleted(2)
		} else if (!loading && user) {
			setLevelCompleted(1)
		} else if (!loading && !user) {
			setLevelCompleted(0)
		}
	},[user, myProfile]);

  return (
		<div>
			<Alert color="white">
	      <div>
	      	{ user && user.profile && myProfile && myProfile.state !== ('MD' || 'DC') ? 
	      		<Alert color='danger'>
	      			Sorry! We don't have partners your area yet! BUT if you recruit a museum or nature center near you, we'll give you a free year of MySchool+ so you can play and win too!
      			</Alert>
	      			:''
      		}
      		<div className="d-flex justify-content-center">
	      		<div className="col-lg-4 col-md-6 col-sm-8 col-xs-10">
	      			<div className='text-center'>
									<button className="btn btn-myschool-red" 
										disabled={levelCompleted === 3 && user && user.subscriberStatusActive}
										onClick={levelCompleted === 0 ?
											()=>toggleRegisterModal(true)
											: levelCompleted === 1 ?
											()=>toggleRegAndProfModal(true)
											: levelCompleted === 2 ?
											()=>toggleRegAndProfModal(true)
											: levelCompleted === 3 && (!user || !user.subscriberStatusActive) ? 
											()=>history.push('/myschoolplus/subscribe')
											:()=>console.log('this function is disabled.')
										}
										>
										{levelCompleted === 3 && (!user || !user.subscriberStatusActive) ? 
											`You're ready to play! Join MySchool+ for a chance to win prizes!`
											: levelCompleted === 3 && user && user.subscriberStatusActive ? 
											<span><i className="fas fa-trophy color-myschool-yellow"/>{` You are in it to win it!`}</span>
											:levelCompleted < 3 && levelCompleted > 0 ? 
												"Finish getting ready!"
											: "Get started now!"}
									</button>
	    				</div>
	    				<h4 className='mt-2 mb-1 text-color-blue'>
	    					Ready to play?
	    				</h4>
	    				<div className='ml-3'>
			          <div className='d-flex'>
				          { levelCompleted > 0  ? 
				          		<AnimatedCheckmark wid={"20px"}/>
				          	:
				          		<AnimatedXMark wid={"20px"}/>
				        	}	{` - Register`}
			        	</div>
			        	<div className='d-flex'>
				          { levelCompleted > 1 ? 
				          		<AnimatedCheckmark wid={"20px"}/>
				          	:
				          		<AnimatedXMark wid={"20px"}/>
			          	} {` - Set your location`}
			        	</div>
			        	<div className='d-flex'>
				          { levelCompleted > 2 ? 
				          		<AnimatedCheckmark wid={"20px"}/>
				          	:
				          		<AnimatedXMark wid={"20px"}/>
				        	}{` - Add children`}
			        	</div>
		        	</div>
		        	<h4 className='mt-2 mb-1 text-color-blue'>
	    					Eligible to win prizes?
	    				</h4>
	    				<div className='ml-3 d-flex'>
			          { user && user.subscriberStatusActive ? 
			          		<AnimatedCheckmark wid={"20px"}/>
				          	:
			          		<AnimatedXMark wid={"20px"}/>
			        	}{` - MySchool+ member`}
		        	</div>
	        	</div>
        	</div>		
				</div>
			</Alert>

    	<AuthModals toggleLoginModal={toggleLoginModal} loginModal={loginModal} toggleRegisterModal={toggleRegisterModal} registerModal={registerModal} />
			<RegisterAndProfileModal regAndProfModal={regAndProfModal} toggleRegAndProfModal={toggleRegAndProfModal}/>
		</div>
    );
}

AreYouReady.propTypes = {
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  getCurrentProfile: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  profile: state.profile
});

export default connect(  mapStateToProps, { getCurrentProfile })(AreYouReady);