import React from 'react';
import { Container } from 'reactstrap';

const DataDeletion = () => {

    const paragraphs = [  
        'Please read these terms and conditions carefully as they contain important information about your legal rights, remedies and obligations. By accessing or using the MySchool Platform, you agree to comply with and be subject to the following terms and conditions, as well as our Privacy Policy, which also governs your use of the MySchool Platform, and is incorporated herein by reference (collectively, the “Terms”).',
        'Last Updated: March 2021',
        'First off, thank you for using MySchool’s service! If you ever work with anyone who does something better than we do, or you have ideas on how we can improve, please tell us. We are here to serve you, and if you ever doubt that then you are invited to tell us to get our acts together.',
        'These Terms constitute a legally binding agreement ("Agreement") between you and MySchool (as defined below) governing your access to and use of the MySchool website, including any subdomains thereof, and any other websites through which MySchool makes its services available (collectively, "Site"), our mobile, tablet and other smart device applications, and application program interfaces (collectively, "Application") and all associated services (collectively, "MySchool Services"). The Site, Application and MySchool Services together are hereinafter collectively referred to as the “MySchool Platform”.',
        'When these Terms mention “MySchool,” “we,” “us,” or “our,” it refers to MySchool, Inc., located at 651 N. Broad St., Suite 206, Middletown, 19709, United States.',
        'Any and all payment processing services through or in connection with your use of the MySchool Platform ("Payment Services") are provided to a third party service (Stripe - https://stripe.com/) and are subject to the terms of use of those Payment Services.',
        'Providers alone are responsible for identifying, understanding, and complying with all laws, rules and regulations that apply to their Listings and Provider Services (as defined below).',
    ];

    const list = [
        {
            title: 'Our Commitment',
            list: [ 
                'We love all our members, and that means respecting their rights to leave if they like.  As such, this information is intended to make it easy to delete all information that you have stored on MySchool.',
                'If you still have questions, you can always email the team at info@myschoolathome.io'
            ]            
        },
        {
            title: 'How to Delete Your Data',
            list: [ 
                'You can edit and delete fields of your personal profiles (for individuals), "spot" profiles (for organizations), and user information from your Dashboard, by going to the section that corresponds to the information you\'re updating and clicking the "Edit" button.',
                'You can delete "spot" profiles by scrolling to the spot you want to delete and clicking the "Delete Profile" button at the bottom of the spot profile section.',
                'You can delete all personal profiles (for individuals), "spot" profiles (for organizations), and user information from your Dashboard.  Simply scroll down to "Account Controls" and click "Delete Account".  You\'ll be asked to verify your decision because after you delete it, we cannot recover it for you.',
                'If you have any questions, just ask! You can send us an email at info@myschoolathome.io.'
            ]            
        }
    ];
                

    return(
        <Container>
           <h2 className='section-heading'>Deleting Your Data</h2>

            <ol className='pl-2'>
                { list.map((l, i) => 
                    (
                        <li key={i}>
                            <div className='text-bold'>{l.title}</div>
                            <ol className='py-3 pl-4' type="I">
                                { l.list.length > 1 ?
                                    l.list.map((l2, i2)=> 
                                        <li key={i2}>
                                            {l2}
                                        </li>
                                    )
                                    :
                                    l.list.map((l2, i2)=> 
                                        <div key={i2}>
                                            {l2}
                                        </div>
                                    )
                                }
                            </ol>
                        </li>
                    )
                    
                )}
            </ol>
        </Container>
    );
}

export default DataDeletion;
