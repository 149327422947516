import React, { Fragment } from 'react';
import { CardText, Badge } from 'reactstrap';
import dayjs from 'dayjs';
import { Link, useHistory } from 'react-router-dom';
import formatTime from '../../utils/formatTime';

const EventBriefInfo = ({ event }) => {

  const history = useHistory();
  let gradeStart;
  if (event.ageStart*1 < 5) {
    gradeStart = "PK"
  } else if (event.ageStart*1 === 5) {
    gradeStart = "K"
  } else if (event.ageStart*1 === 18) {
    gradeStart = 12
  } else {
    gradeStart = event.ageStart*1 - 5
  };

  let gradeEnd;
  if (event.ageEnd*1 < 5) {
    gradeEnd = " - PK"
  } else if (event.ageEnd*1 === 5) {
    gradeEnd = " - K"
  } else if (event.ageEnd*1 === 18) {
    gradeEnd = " - 12"
  } else if (event.ageStart*1 <= 18 && event.ageEnd*1 > 18) {
    gradeEnd = "+"
  } else if (event.ageStart*1 > 18 && event.ageEnd*1 > 18) {
    gradeEnd = ""
  }
  else {
    gradeEnd = ` - ${(event.ageEnd*1) - 5}`
  };

  let eventCatColor;
  if (event.category === "Field Trip") {
    eventCatColor = "science"
  } else if (event.category === "Class") {
    eventCatColor = "myschool-red"
  } else if (event.category === "Other")  {
    eventCatColor = "myschool-yellow"
  } else if (event.category === "Meetup")  {
    eventCatColor = "myschool-blue"
  }
  else {
    eventCatColor = "myschool-blue"
  };

  return (
      <Fragment>
      <button type='button' className='basic-button align-left' onClick={()=>history.push(`/events/${event._id}`)}>
        <CardText>
          <Badge className={`badge-${eventCatColor}`} pill>{event && event.category}</Badge>{' '}<strong>{event && event.title}</strong>
        </CardText>
        <CardText>
          {!event.isForParents ? 
            <Fragment>
              Ages {event.ageStart === event.ageEnd ? 
                (`${event.ageEnd}`) 
                : event.ageEnd*1 > 18 ?
                ( `${event.ageStart}+`)
                :
                ( `${event.ageStart}-${event.ageEnd}`)
              }{` / `} 
              {event.ageStart === event.ageEnd ? 
                `Grade ${gradeEnd}`
              : ( `Grades ${gradeStart}${gradeEnd}`)
              }
            </Fragment>
            :
            <Fragment>
              This event is intended for{' '}<span className='text-underline'>parents</span>
            </Fragment>
          }
        </CardText>
        <CardText>
          <i className="far fa-calendar-alt"/>
          {event.dateRange ?
            <Fragment>
              {" "}
                { event.dateRange.sort((a, b) => a.valueOf() - b.valueOf()).map((date, i) => {
                    return (
                      <span key={`date-array-${i}`}>
                        {(i ? ', ': '') + dayjs(date).format("DD-MMM")}
                      </span>
                    )
                  })
              }
            </Fragment>
            :''
          }
          {(!event.dateRange || event.dateRange.length < 1) && event.startDate && event.endDate && event.daysEachWeek  ?
            <Fragment>
              {` ${event.daysEachWeek} from ${dayjs(event.startDate).format("DD-MMM-YYYY")} to ${dayjs(event.endDate).format("DD-MMM-YYYY")}`}
            </Fragment>
            :''
          }
        </CardText>
        <CardText>
          <i className="far fa-clock"/>{" "}{formatTime(event.startTime)} - {formatTime(event.endTime)}
        </CardText>
        <CardText>
          <strong>Cost:</strong> 
            { event.isFreeMeetup ? 
              ` Free!`
              : event.suggestDonation ? 
              ` Free (Suggested Donation: $${event.donation})`
              : ` Child: $${event.costChild} / Adult: $${event.costAdult} `
            }
        </CardText>
      </button>
      { event.provider && event.provider.providerName ?
        <CardText className='color-myschool-blue'>
          <strong>Host:</strong> {`${event.provider.providerName} `}<Link to={`/provider/${event.provider._id}`} className='text-underline'>...learn more</Link>
        </CardText>
        :
        event.profile && event.user && event.user.username ?
        <CardText className='color-myschool-blue'>
          <strong>Host:</strong> {`${event.user.username} `}<Link to={`/profile/${event.user._id}`} className='text-underline'>...learn more</Link>
        </CardText>
        : ''
      }
    </Fragment>
  );
};


export default EventBriefInfo;
