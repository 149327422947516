import React, { useEffect, useState, Fragment } from "react";
import { useParams, useHistory } from 'react-router-dom';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import api from '../../utils/api';
import { Container, Row, Col, Label, FormGroup, Input, Button, Spinner, Alert } from 'reactstrap';
import SpinnerComponent from '../layout/Spinner';
import { addEvent } from '../../actions/event';
import { getMyProviderById } from '../../actions/provider';
import { setAlert } from '../../actions/alert';
import EventMapWrapper from './geoLocation/EventMapWrapper';
import EventForm from './EventForm';
import EventPreview from '../events/EventPreview';
import calculateTakehomePay from '../../utils/calculateTakehomePay';
import queryString from 'query-string';
import isValidURL from '../../utils/isValidURL';

const CreateProviderEvent = ({ addEvent, getMyProviderById, provider: {myProvider, providerLoading}, auth, setAlert }) => {
  const history = useHistory();
  const params = useParams();

  let formPrefillObj = queryString.parse(history.location.search, {parseBooleans: true, arrayFormat: 'bracket'});
  const [ preFilledDataLoaded, setPreFilledDataLoaded] = useState(false);
  const [ copyMode, setCopyMode ] = useState(false);

  //Local alerts
  const [ alertArray, setAlertArray ] = useState([]);

  //Sections
  const [ showSection1, toggleSection1 ] = useState(true);
  const [ showSection2, toggleSection2 ] = useState(false);
  const [ showPreview, setShowPreview ] = useState(false);
  const [ previewClosing, setPreviewClosing ] = useState(false);
  const onPreviewClose = () => {
    setShowPreview(false);
    setPreviewClosing(true);
    setTimeout(()=>{setPreviewClosing(false)},1000);
  };

  //Section Actions
  const swapSection12 = () => {
    toggleSection1(!showSection1);
    toggleSection2(!showSection2);
  };

  const [ mapMeData, setMapMeData] = useState({
    address: '',
    city:'',
    state:'',
    zip:'',
  });

  const onChangeMapMe = (e) => {
    setMapMeData({ ...mapMeData, [e.target.name]: e.target.value });
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const [hostInfo, setHostInfo ] = useState({
    hostName:'',
    email:''
  });

  const [formData, setFormData] = useState({
    category: 'Meetup',
    title: '',
    address: '',
    city:'',
    state:'',
    zip:'',
    lat: 25,
    long: -71,
    image: '',
    dateRange: [],
    startTime: '',
    endTime: '',
    costAdult: '',
    costChild:'',
    suggestDonation: false,
    donation: '',
    ageStart: '',
    ageEnd: '',
    slotsTotal: '',
    slotsPrefilled: '',
    website: '',
    phone: '',
    classContact: '',
    contactEmail: '',
    included: '',
    preparations: '',
    expectations: '',
    ifClassMissed: '',
    learningGoals: '',
    uponCompletion: '',
    description: '',
    subjects: [],
    isDropOff: false,
    isUnlisted: false,
    offSiteLink: '',
    isOffSite: false,
    isFreeMeetup: true,
    isForParents: false
  });

  useEffect(() => {
    getMyProviderById(params.hostId);
  }, [params]);

  //If Provider, check if Provider is ready to host events
  const [ providerLoaded, setProviderLoaded] = useState(false);
  const [ providerAccountDetails, setProviderAccountDetails] = useState({});
  useEffect(()=> {
    const getProviderStripeAccount = async () => {
      try {
        const stripeAccount = await api.get(`/v1/stripeConnect/stripe-connect-account/provider/${myProvider._id}`);
        setProviderAccountDetails(stripeAccount.data);
        setProviderLoaded(true);
        if (stripeAccount.data && (!formPrefillObj || !formPrefillObj.category)) {
          setFormData({...formData, isOffSite: false});
        }
      } catch (err) {
        // console.log(err);
        setProviderLoaded(true);
        setFormData({...formData, isOffSite: true});
      } 
    };
    if (params.hostType === 'provider' && params.hostId !== 'undefined' && !providerLoading && myProvider && myProvider.stripeAccount) {
      getProviderStripeAccount();
    };
  }, [params, providerLoading, myProvider]);


  useEffect(() => {
    setHostInfo({
      hostName: !providerLoading && myProvider && myProvider.providerName ? myProvider.providerName:'',
      email: !providerLoading && myProvider && myProvider.email ? myProvider.email: ''
    });
  }, [providerLoading, myProvider]);

  useEffect(()=> {
    setMapMeData({...mapMeData, 
      address: formPrefillObj && formPrefillObj.address ? formPrefillObj.address:'',
      city: formPrefillObj && formPrefillObj.city ? formPrefillObj.city:'',
      state: formPrefillObj && formPrefillObj.state ? formPrefillObj.state:'',
      zip: formPrefillObj && formPrefillObj.zip ? formPrefillObj.zip:''
    });
    setFormData({...formData, ...formPrefillObj});
    setPreFilledDataLoaded(true);
    if (formPrefillObj && formPrefillObj.category) {
      setCopyMode(true);
    }
  },[history.location.search]);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [ showMap, setShowMap ] = useState(false);

  const mapMe = async () => {
    setIsSubmitting(true);
    try {
      const config = { headers: { 'Content-Type': 'application/json' } };
      const jsonMapMeData = JSON.stringify(mapMeData);
      const res = await api.post('/events/geolocate', jsonMapMeData, config);
      if (res.status === 200) {
        setIsSubmitting(false);
        setFormData({
          ...formData,
          lat: res.data.lat,
          long: res.data.long
        });
        setShowMap(true);
      }
    } catch (err) {
      setIsSubmitting(false);
      const errors = err.response.data.errors;
      if (errors) {
          setAlertArray(errors);
          setTimeout(()=> {setAlertArray([])},5000);
      }
    }

  }


  const onSubmit = async e => {
    e.preventDefault();
    setIsSubmitting(true);
    const postData = {...formData, isLive: true};

    if (formData.description.length < 20) {
      window.scrollTo(0,0);
      setAlert('Please include a longer description', 'danger');
      setIsSubmitting(false);
    } else {
      await addEvent(postData, params.hostType, params.hostId, history);
      // console.log(postData, params.hostId);
      setIsSubmitting(false);
    }
  };

  return providerLoading || !myProvider || !preFilledDataLoaded ? (
      <SpinnerComponent className='color-myschool-blue'/>
    ) : (
    <Fragment>
      <Container>
        <h2 className='large text-primary-ms'>Create an Event</h2>
        {alertArray.length > 0 ? 
          alertArray.map((alert, index) =>
              <Alert color="dark" key={index} className='z-index-1000'>
                {alert.msg}
              </Alert>
            )
          : ''
        }
            
        { showSection1 && !showMap ?
          <div className='my-3'>
            <FormGroup>
              <Label className='text-bold'>Great! Where is it going to take place?</Label>
              <Input type="text" 
                name="address" 
                id="address" 
                placeholder="Street Address: 1234 Main St"
                value={mapMeData.address}
                onChange={e => onChangeMapMe(e)} 
              />
            </FormGroup>
            <FormGroup>
              <Input type="text" 
              name="city" 
              id="city" 
              placeholder="City"
              value={mapMeData.city}
              onChange={e => onChangeMapMe(e)} 
              />
            </FormGroup>
            <Row form>
              <Col md={8}>
                <FormGroup>
                  <Input type="select" 
                  name="state" 
                  id="state" 
                  placeholder="State (required)"
                  value={mapMeData.state}
                  onChange={e => onChangeMapMe(e)}
                  >
                    <option value="None">Select State...</option>
                    <option value="AL">Alabama</option>
                    <option value="AK">Alaska</option>
                    <option value="AZ">Arizona</option>
                    <option value="AR">Arkansas</option>
                    <option value="CA">California</option>
                    <option value="CO">Colorado</option>
                    <option value="CT">Connecticut</option>
                    <option value="DE">Delaware</option>
                    <option value="DC">District Of Columbia</option>
                    <option value="FL">Florida</option>
                    <option value="GA">Georgia</option>
                    <option value="GU">Guam</option>
                    <option value="HI">Hawaii</option>
                    <option value="ID">Idaho</option>
                    <option value="IL">Illinois</option>
                    <option value="IN">Indiana</option>
                    <option value="IA">Iowa</option>
                    <option value="KS">Kansas</option>
                    <option value="KY">Kentucky</option>
                    <option value="LA">Louisiana</option>
                    <option value="ME">Maine</option>
                    <option value="MD">Maryland</option>
                    <option value="MA">Massachusetts</option>
                    <option value="MI">Michigan</option>
                    <option value="MN">Minnesota</option>
                    <option value="MS">Mississippi</option>
                    <option value="MO">Missouri</option>
                    <option value="MT">Montana</option>
                    <option value="NE">Nebraska</option>
                    <option value="NV">Nevada</option>
                    <option value="NH">New Hampshire</option>
                    <option value="NJ">New Jersey</option>
                    <option value="NM">New Mexico</option>
                    <option value="NY">New York</option>
                    <option value="NC">North Carolina</option>
                    <option value="ND">North Dakota</option>
                    <option value="OH">Ohio</option>
                    <option value="OK">Oklahoma</option>
                    <option value="OR">Oregon</option>
                    <option value="PA">Pennsylvania</option>
                    <option value="Puerto Rico">Puerto Rico</option>
                    <option value="RI">Rhode Island</option>
                    <option value="SC">South Carolina</option>
                    <option value="SD">South Dakota</option>
                    <option value="TN">Tennessee</option>
                    <option value="TX">Texas</option>
                    <option value="UT">Utah</option>
                    <option value="VT">Vermont</option>
                    <option value="VA">Virginia</option>
                    <option value="U.S. Virgin Islands">U.S. Virgin Islands</option>
                    <option value="WA">Washington</option>
                    <option value="WV">West Virginia</option>
                    <option value="WI">Wisconsin</option>
                    <option value="WY">Wyoming</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Input 
                    type="text" 
                    name="zip" 
                    id="zip" 
                    placeholder="Zip"
                    value={mapMeData.zip}
                    onChange={e => onChangeMapMe(e)} 
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row className='text-center'>
              <Col>
                <Button type="button" disabled={isSubmitting} className="btn btn-modal-blue btn-set-WH-long" onClick={()=>mapMe()}>
                  <span>
                    {isSubmitting ? (
                      <Spinner size="sm" className='color-white'/>
                    ) : (
                      "Map It!"
                    )}
                  </span>
                </Button>
              </Col>
            </Row>
          </div>
          : 
          showMap ?
            <Fragment>
              <EventMapWrapper formData={formData} setFormData={setFormData}/>
              <button type='button' className='btn btn-modal-blue my-1 float-left' disabled={ isSubmitting || !showMap } onClick={()=>setShowMap(false)}>Back</button>
              <button 
                type='button' 
                className='btn btn-modal-blue my-1 float-right' 
                disabled={ (!formData.lat && !formData.long) } 
                onClick={()=>{
                    swapSection12()
                    setShowMap(false);
                  }
                }>
                Next
              </button>
            </Fragment>
          : 
          ''
        }
                      
        { showSection2 & !showPreview ? 
          (
            <Fragment>  
              <EventForm 
                onSubmit={onSubmit}
                formData={formData}
                setFormData={setFormData}
                mapMeData={mapMeData}
                setAlert={setAlert}
                hostInfo={hostInfo}
                history={history}
                setShowPreview={setShowPreview}
                isSubmitting={isSubmitting}
                setIsSubmitting={setIsSubmitting}
                accountDetails={providerAccountDetails}
                previewClosing={previewClosing}
                swapSection12={swapSection12}
                copyMode={copyMode}
              />
            </Fragment>
          )
          : showSection2 & showPreview ? 
          (
            <Fragment>  
              <div className='d-flex justify-content-end'>
                <Button className='btn btn-myschool-red' onClick={()=>onPreviewClose()}>Close Preview</Button>
              </div>
              <EventPreview 
                formData={formData}
                auth={auth}
                history={history}
              />
              <div className='d-flex justify-content-end'>
                <Button className='btn btn-myschool-red' onClick={()=>onPreviewClose()}>Close Preview</Button>
              </div>
            </Fragment>
          ) : ''
        }

      </Container>
    </Fragment>
  );
}

CreateProviderEvent.propTypes = {
  getMyProviderById: PropTypes.func.isRequired,
  addEvent: PropTypes.func.isRequired,
  provider: PropTypes.object.isRequired,
  event: PropTypes.object.isRequired,
  setAlert: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  event: state.event,
  provider: state.provider,
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { setAlert, getMyProviderById, addEvent }
)(CreateProviderEvent);

