import React, { Fragment, useState } from 'react';
import { Container, Table } from 'reactstrap';
import Spinner from '../layout/Spinner';
import AdminRoutes from './AdminRoutes';
import { useHistory } from 'react-router-dom';

const AdminDashboard = () => {

  const history = useHistory();

  // const [showUsers, setShowUsers] = useState(true);
  // const [showProfilesWithin, setShowProfilesWithin] = useState(false);
  // // const [showCronJobs, setShowCronJobs] = useState(false);

  // const onShowUsers = () => {
  //   setShowUsers(true);
  //   setShowProfilesWithin(false);
  //   // setShowCronJobs(false);
  // };

  // const onShowProfilesWithin = () => {
  //   setShowUsers(false);
  //   setShowProfilesWithin(true);
  //   // setShowCronJobs(false);
  // };

  // const onShowCronJobs = () => {
  //   setShowUsers(false);
  //   setShowProfilesWithin(false);
  //   // setShowCronJobs(true);
  // }
  
    return (
    <div>
      <Container>
        <h2 className='text-center color-myschool-blue'>Admin Dashboard</h2>
          <AdminRoutes />
          {/*
            <button 
              type='button'
              className='btn btn-myschool-blue'
              onClick={()=>onShowUsers()}
              >
              User List
            </button>
            <button 
              type='button'
              className='btn btn-myschool-blue'
              onClick={()=>onShowProfilesWithin()}
              >
              Profiles Within
            </button>
            {showUsers ?
              <UserList />
              : ''
            }
            {showProfilesWithin ?
              <EmailProfilesWithinRadius />
              :
              ''
            }
        */}
      </Container>
    </div>
  );
};

export default AdminDashboard;


// event - <EventBookingListItem key={`list-${booking._id}`}  booking={booking}/>