import { combineReducers } from 'redux';
import alert from './alert';
import auth from './auth';
import profile from './profile';
import resource from './resource';
import provider from './provider';
import curriculum from './curriculum';
import lesson from './lesson';
import child from "./child";
import onlineEvent from './onlineEvent';
import onlineBooking from './onlineBooking';
import providerReviews from './providerReviews';
import resourceReviews from './resourceReviews';
import family from './family';
import event from './event';
import admin from './admin';
import user from './user';
import meetupVirtual from './meetupVirtual';
import portfolio from './portfolio';

export default combineReducers({
  alert,
  auth,
  profile,
  resource,
  provider,
  curriculum,
  lesson,
  child,
  onlineEvent,
  onlineBooking,
  providerReviews,
  resourceReviews,
  family,
  event,
  admin,
  user,
  meetupVirtual,
  portfolio
});
