import api from '../utils/api';
import { setAlert } from './alert';
import queryString from 'query-string';

import {
  GET_RESOURCE,
  GET_RESOURCES,
  GET_RESOURCES_WITHIN_RADIUS,
  GET_RESOURCES_WITHIN_MAPBOUNDS,
  RESOURCE_ERROR
} from './types';


// Get all resources
export const getResources = () => async dispatch => {
  
  try {
    const res = await api.get('/resources');

    dispatch({
      type: GET_RESOURCES,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: RESOURCE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get all resources within Radius of a center point
export const getResourcesWithin = position => async dispatch => {
  
  if (!position.limit ) {
    position = {...position, 
      dist: 50
    };
  }

  const { lat, long, dist } = position;

  try {
    const res = await api.get(`/resources/resources-within/${lat}/${long}/${dist}`);
    // console.log(res);
    dispatch({
      type: GET_RESOURCES_WITHIN_RADIUS,
      payload: res.data.data.resources
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: RESOURCE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

//Get resources with QUERY
export const getResourcesWithinQuery = ( position, initialQuery ) => async dispatch => {
  
  let queryObject;

  if (!initialQuery.limit ) {
    queryObject = {...initialQuery, limit: 50 }
  } else {
    queryObject = initialQuery
  };

  const { lat, long } = position;

  Object.keys(queryObject).forEach(key => queryObject[key] === 'none' ? delete queryObject[key] : {});
  Object.keys(queryObject).forEach(key => queryObject[key] === undefined ? delete queryObject[key] : {});

  const query = queryString.stringify(queryObject);

  try {
    const res = await api.get(`/resources/resources-within-query/${lat}/${long}/${initialQuery.dist}?${query}`);
    // console.log('results', res.data.results);
    // console.log('results', res.data.data.resources);
    dispatch({
      type: GET_RESOURCES_WITHIN_RADIUS,
      payload: res.data.data.resources
    });
  } catch (err) {
    console.log(err.response);
    dispatch({
      type: RESOURCE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};


// Get resources within bounds -- this is for updating the Homeschool Map once the bounds change. 
export const getBoundedResources = (bounds, filterData) => async dispatch => {
  
  const { latMin, latMax, longMin, longMax } = bounds;
  
  let queryObject;

  if (!filterData.limit ) {
    queryObject = {...filterData, 
      limit: 50
    };
  } else {
    queryObject = filterData
  };

  Object.keys(queryObject).forEach(key => queryObject[key] === 'none' ? delete queryObject[key] : {});
  
  Object.keys(queryObject).forEach(key => queryObject[key] === undefined ? delete queryObject[key] : {});

  const query = queryString.stringify(queryObject);

  try {
    const res = await api.get(`/resources/resources-on-map/${latMin}/${latMax}/${longMin}/${longMax}?${query}`);
    // console.log(res);
    dispatch({
      type: GET_RESOURCES_WITHIN_MAPBOUNDS,
      payload: res.data.data.resources
    });
  } catch (err) {
    dispatch({
      type: RESOURCE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Create resource
export const createResource = ( formData, history ) => async dispatch => {
  try {

    const res = await api.post('/resources', formData);

    dispatch({
      type: GET_RESOURCE,
      payload: res.data
    });

    dispatch(setAlert('Success!  Thanks for the suggestion!', 'success'));

    let goToCoords;
    if (res.data.long && res.data.lat) {goToCoords = `lat=${res.data.lat}&long=${res.data.long}`};

    history.push(`/homeschoolmap?${goToCoords}`);
    
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: RESOURCE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Update resource
export const updateResource = ( formData, history, resourceId ) => async dispatch => {
  try {

    const res = await api.put(`/resources/${resourceId}`, formData);

    dispatch({
      type: GET_RESOURCE,
      payload: res.data
    });

    dispatch(setAlert('Spot Updated', 'success'));

    let goToCoords;
    if (res.data.long && res.data.lat) {goToCoords = `lat=${res.data.lat}&long=${res.data.long}`};

    history.push(`/homeschoolmap?${goToCoords}`);
    
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: RESOURCE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get resource by Resource ID
export const getResourceByResourceId = resourceId => async dispatch => {

  try {
    
    const res = await api.get(`/resources/${resourceId}`);

    dispatch({
      type: GET_RESOURCE,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: RESOURCE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};