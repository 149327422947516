import React, { useState, Fragment, useEffect } from 'react';
import { Button, Form, FormGroup, Input, Container, Spinner } from 'reactstrap';
import { Redirect, Link, useHistory } from "react-router-dom";
import { connect } from 'react-redux';
import { setAlert } from '../../actions/alert';
import PropTypes from 'prop-types';
import api from '../../utils/api';

const Forgot = ({ setAlert, isAuthenticated }) => {
	
  const history = useHistory();

	const [formData, setFormData] = useState({
		email: ''
	});

  const { email } = formData;
	
	const onChange = e =>
	    setFormData({ ...formData, [e.target.name]: e.target.value });

  const [ isSubmitting, setIsSubmitting ] = useState(false);

  let timeoutID;
	const onSubmit = async e => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const res = await api.post('/users/forgot', formData);
      setAlert('Success! Follow the link in your email to reset your password.', 'success');
      setIsSubmitting(false);
    } catch (err) {
      if (err && err.response && err.response.data && err.response.data.errors) {
        const errors = err.response.data.errors;
        errors.forEach(error => setAlert(error.msg, 'danger'));
      } else {
        setAlert('Error. Please try again. Contact us if the problem persists.', 'danger')
      }
      setIsSubmitting(false);
    }
    setFormData({
      email: ''
    });
  };

    return (
      <Fragment>
        <Container>
          <Form onSubmit={e => onSubmit(e)} className="msstd-form">
            <h4> Enter your email address to receive a password reset email</h4>
            <FormGroup>
              <Input type="email" 
                  name="email" 
                  id="email" 
                  placeholder="Email Address"
                  value={email}
                  onChange={e => onChange(e)} />
            </FormGroup>
            <button
              disabled={isSubmitting}
              type="submit"
              className="btn btn-modal-blue btn-set-WH-long2"
            >
              <span>
                {isSubmitting ? (
                  <Spinner size="sm" className='color-white'/>
                ) : (
                  "Send Reset Email"
                )}
              </span>
            </button>
          </Form>
          <div>
          	Nevermind, I remember! <Link to='/login'>Log In</Link>
          </div>
        </Container>
          
      </Fragment>
    );
};

Forgot.propTypes = {
  isAuthenticated: PropTypes.bool,
  setAlert: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, { setAlert })(Forgot);
