import React, { Fragment, useState } from 'react';
import { Marker, Popup } from 'react-leaflet';
import { Link } from 'react-router-dom';
import { Button, Spinner, Badge } from 'reactstrap';
import capitalizeName from '../../../utils/capitalizeName';
import heartOutline from '../../../images/heart-outline.svg';
import heartFilled from '../../../images/heart-filled.svg';
import zoomToPoint from '../../../images/icon-zoom-to-point.svg';
import zoomOut from '../../../images/icon-zoom-out.svg';
import dayjs from 'dayjs';

const GenericMarker = ({ resource, isSettingFav, favoriteResourceHandler, favoriteProviderHandler, resourceIcon, yellowIcon, partnerIcon, auth, history, toggleLoginModal }) => {

  const weekday = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
  const d = dayjs().day();
  let day = weekday[d];

  const groupCategories = [
    "tutorial",
    "cooperative",
    "hybrid homeschool", 
    "forest school",
    "microschool", 
    "homeschool enrichment center", 
    "resource center", 
    "support group"
  ];

  return (
    <Marker 
      key={resource._id} 
      icon={resource.isPortfolioPartner ? partnerIcon 
         : groupCategories.includes(resource.category) ? yellowIcon 
         : resourceIcon } 
      position = {[resource.lat, resource.long]}
      >
      <Popup>
        { resource.collectionName && resource.collectionName === 'resource' ? 
            <div className='float-right'>
              <Button type='button' className='fav-button justify-content-center' disabled={isSettingFav} onClick={()=>favoriteResourceHandler(resource._id)}>
                {isSettingFav === false && auth.user && auth.user.myFavResources && auth.user.myFavResources.filter(fav => fav.toString() === resource._id).length > 0 ?
                  <img src={heartFilled} width="15px"/>
                   : 
                 isSettingFav === true ?
                  <Spinner style={{ width: '15px', height: '15px'}} type="grow" color="danger" />
                   : 
                  <img src={heartOutline} width="15px"/>
                } 
              </Button>
            </div>
          : resource.collectionName && resource.collectionName === 'provider' ? 
            <div className='float-right'>
              <Button type='button' className='fav-button justify-content-center' disabled={isSettingFav} onClick={()=>favoriteProviderHandler(resource._id)}>
                {isSettingFav === false && auth.user && auth.user.myFavProviders && auth.user.myFavProviders.filter(fav => fav.toString() === resource._id).length > 0 ?
                  <img src={heartFilled} width="15px"/>
                   : 
                 isSettingFav === true ?
                  <Spinner style={{ width: '15px', height: '15px'}} type="grow" color="danger" />
                   : 
                  <img src={heartOutline} width="15px"/>
                } 
              </Button>
            </div>
          :''
        }
        {resource.isPortfolioPartner ? 
          <div>
            <Badge pill className='pill-color-red'>
              Scavenger Hunt Partner
            </Badge>
          </div>
          :''
        }
        <div className='text-bold'>{resource.name}</div>
        <div>{resource.category && capitalizeName(resource.category)}</div>
        { resource.opHours ? <div><span><i className="fas fa-clock"/>{' '}{day}{`: `}{resource.opHours[d]}</span></div>:''}
        { resource && resource.entryFees ? 
          <div><span className='text-bold'><i className="fas fa-dollar-sign"/>{' '}</span>
            {resource && resource.entryFees.isFree ?
              `Free`
              :
              <span>
              {resource.entryFees.fees.length > 2 ? 
                <span>
                  {
                    resource.entryFees.fees.slice(0,2).map((fee,i)=> {
                      return <span key={i}>{i>0 ? ', ':''}{fee.name}{`: `}{fee.price > 0 ? `$${fee.price}`:'Free'}</span>
                    })
                  }
                  ...
                </span>
              : resource.entryFees.fees.map((fee,i)=> {
                  return <span key={i}>{i>0 ? ', ':''}{fee.name}{`: `}{fee.price > 0 ? `$${fee.price}`:'Free'}</span>
                })
              }
              </span>
            }
            </div>
          :''
        }

        { resource.collectionName && resource.collectionName === 'provider' ? 
          <div>
            <Link to={`/provider/${resource._id}`}>See more info and offerings <i className="fas fa-angle-double-right"></i></Link>
          </div>
          :
          resource.collectionName && resource.collectionName === 'resource' ? 
          <div>
            <Link to={`/resource/${resource._id}`}>See more info<i className="fas fa-angle-double-right"></i></Link>
            { resource.creditedTo_info && resource.creditedTo_info.length > 0 && resource.creditedTo_info[0].providerName && resource.creditedTo_info[0]._id ? 
              <div className='text-07rem'>Suggested By:<Link to={`/provider/${resource.creditedTo_info[0]._id}`}>{` ${resource.creditedTo_info[0].providerName} `}<i className="fas fa-angle-double-right"/></Link></div>
              : ''
            }
            <div>
              {
                auth.isAuthenticated ? 
                <button className='basic-button text-12px text-bold text-underlined' onClick={()=>history.push(encodeURI(`/create-event?address=${resource.address}&city=${resource.city}&state=${resource.state}&zip=${resource.zip}`))}>Host an event here</button>
                :
                <button className='basic-button text-12px text-bold text-underlined' onClick={()=>toggleLoginModal(true)}>Host an event here</button>
              }
              {' | '}
              {
                auth.isAuthenticated ? 
                <button className='basic-button text-12px text-underlined' onClick={()=>history.push(`/claim-resource/${resource._id}`)}>Claim Spot</button>
                :
                <button className='basic-button text-12px text-underlined' onClick={()=>toggleLoginModal(true)}>Claim Spot</button>
              }
              {' | '}
              {
                auth.isAuthenticated ? 
                <button className='basic-button text-12px text-underlined' onClick={()=>history.push(`/edit-resource/${resource._id}`)}>Edit Spot</button>
                :
                <button className='basic-button text-12px text-underlined' onClick={()=>toggleLoginModal(true)}>Edit Spot</button>
              }
            </div>
          </div>
          :''
        }
      </Popup>
    </Marker>
  );
};

export default GenericMarker;
