import React, { useState, Fragment, useEffect } from 'react';
import { Container, Col, Row, Table, Button } from 'reactstrap';
import axios from 'axios';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import AuthModals from '../../auth/AuthModals';
import museumGroup from '../../../images/museum_kids_group.jpg';
import ImageContainer from '../../layout/ImageContainer';
import SponsorWhyJoin from './SponsorWhyJoin';
import SponsorWhatIs from './SponsorWhatIs';
import SponsorTable from './SponsorTable';
import SponsorHero from './SponsorHero';
import SponsorWhatIsMySchool from './SponsorWhatIsMySchool';

const SponsorWrapper = ({ auth: {user, loading, isAuthenticated}, profile: {myProfile, profileLoading}, provider: {providers, providerLoading} }) => {

	const history = useHistory();

	const [ loginModal, toggleLoginModal ] = useState(false);
	const [ registerModal, toggleRegisterModal ] = useState(false);

  return(
		<div className='plan-table-wrapper ptb_50'>
			<Container>
				<div className="price-heading text-center">
					<div className="section-heading">
		        <h2 className='text-color-blue'>Ad-Free Advertising</h2>
	        </div>
	      </div>
	      
	      <SponsorHero />

	      <SponsorWhatIsMySchool />

	      <div className='my-5'>
	      	<SponsorWhatIs history={history}/>
	      </div>
	      <div className='my-5'>
        	<a href="mailto:justin@myschoolathome.io?subject=MySchool%20Sponsor%20Program&body=I'm with (insert your organization name) and am interested in the Myschool Sponsor Program!" target='_blank' rel='noopener noreferrer' className='btn block-button btn-myschool-blue'>
          	Email Justin@myschoolathome.io to get started!
        	</a>
        </div>
	      <hr />
	      <div className='my-5'>
	      	<SponsorWhyJoin/>
	      </div>
	      <div className='my-5'>
        	<a href="mailto:justin@myschoolathome.io?subject=MySchool%20Sponsor%20Program&body=I'm with (insert your organization name) and am interested in the Myschool Sponsor Program!" target='_blank' rel='noopener noreferrer' className='btn block-button btn-myschool-blue'>
          	Email us to get started!
        	</a>
        </div>
        <hr />
        <div className='my-5'>
	      	<SponsorTable />
	      </div>
	      <div className='my-5'>
        	<a href="mailto:justin@myschoolathome.io?subject=MySchool%20Sponsor%20Program&body=I'm with (insert your organization name) and am interested in the Myschool Sponsor Program!" target='_blank' rel='noopener noreferrer' className='btn block-button btn-myschool-blue'>
          	Interested? Email us to get started!
        	</a>
        </div>
			</Container>

    	<AuthModals toggleLoginModal={toggleLoginModal} loginModal={loginModal} toggleRegisterModal={toggleRegisterModal} registerModal={registerModal} />
		</div>
    );
}

SponsorWrapper.propTypes = {
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  provider: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  profile: state.profile,
  provider: state.provider
});

export default connect(  mapStateToProps, { })(SponsorWrapper);