import React, { useState, useEffect } from 'react';
import myschoolLogo from '../../../images/logo100-100.svg';
import myschoolPlusLogo from '../../../images/logo_myschool-plus_150-150.svg';
import api from '../../../utils/api';
import { useHistory, useLocation, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AuthModals from '../../auth/AuthModals'; 
import { setAlert } from '../../../actions/alert';

const SubscriptionPayment = ({ auth, setAlert }) => {
  let [message, setMessage] = useState('');
  let [success, setSuccess] = useState(false);
  let [sessionId, setSessionId] = useState('');
  let [redirectUrl, setRedirectUrl] = useState('');

  const history = useHistory();
  const location = useLocation(); 

  const checkoutButton = async () => {
    try {
      const body = JSON.stringify({lookup_key:"price_1KPVfLCNdVLHQ1M91mrOuQvB",userId:auth.user._id});
      const res = await api.post(`/v1/myschoolplus/create-checkout-session`,body);
      setSessionId(res.data.sessionId);
      window.location.href = res.data.url;
    } catch (err) {
      if (err && err.response && err.response.data && err.response.data.msg) {
        setAlert(err.response.data.msg, 'danger');
      }
      console.log(err);
    }
  };

  const customerPortalButton = async () => {
    try {
      const res = await api.post(`/v1/myschoolplus/create-portal-session`,{});
      window.location.href = res.data.url;
    } catch (err) {
      if (err && err.response && err.response.data && err.response.data.msg) {
        setAlert(err.response.data.msg, 'danger');
      }
      console.log(err);
    }
  }

  const Logo = () => (
    <div>
      <img src={myschoolPlusLogo} alt="myschool logo" height="150px" width="150px"/>
    </div>
  );

  const [ loginModal, toggleLoginModal ] = useState(false);
  const [ registerModal, toggleRegisterModal ] = useState(false);

  const ProductDisplay = () => (
    <section className="container text-center">
      <div className="product">
        <Logo />
        <div className="description">
          <h4 className="my-4">Get MySchool<span className="text-superscript text-1-2rem">+</span>{' '}Access for only $25 a year</h4>
          <div className="mt-3 mb-1">
            <p className="text-bold">Learn more. Spend less.</p>
          </div>
        </div>
      </div>
      <div>
        <button id="checkout-and-portal-button" type="button" onClick={()=> auth.isAuthenticated ? checkoutButton() :toggleLoginModal(true)} className="btn btn-myschool-blue mt-4 mb-5">
          Get MySchool<span className="text-superscript">+</span>{' '}access now!
        </button>
        <div className="text-italic">
          If you're not 100% satisfied, we'll refund your money. You can't lose! You can only save. 
        </div>
      </div>
      <AuthModals loginModal={loginModal} toggleLoginModal={toggleLoginModal} registerModal={registerModal} toggleRegisterModal={toggleRegisterModal} />
    </section>
  );

  const SuccessDisplay = () => {
    return (
      <section className="container text-center">
        <div className="product Box-root">
          <Logo />
          <div className="description Box-root">
            <h4>Thanks for subscribing to MySchool<span className="text-superscript text-1-2rem">+</span>!</h4>
          </div>
        </div>
        <div>
          <button type="button" onClick={()=> customerPortalButton()} className="btn btn-myschool-blue mt-4 mb-5">
            Manage your billing information
          </button>
        </div>
      </section>
    );
  };

  const Message = ({ message, history }) => (
    <section className="container text-center">
      <p className="my-3">{message}</p>
      <div>
        <button type="button" className="btn btn-myschool-blue" onClick={()=>history.push('/myschoolplus')}>Go Back to MySchool Plus</button>
        <button type="button" className="btn btn-myschool-blue" onClick={()=>{setMessage('');setSuccess(false);setSessionId('');setRedirectUrl('')}}>Nevermind, I'd like to subscribe.</button>
      </div>
    </section>
  );

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get('success')) {
      setSuccess(true);
      setSessionId(query.get('session_id'));
    }

    if (query.get('canceled')) {
      setSuccess(false);
      setMessage(
        "Order canceled -- No worries! Think about it and come back whenever you're ready!"
      );
    }
  }, [sessionId]);

  //Redirect if active subscriber
  if (auth && auth.user && auth.user.subscriberStatusActive ) {
    return <Redirect to='/myschoolplus' />;
  }

  if (!success && message === '') {
    return <ProductDisplay />;
  } else if (success && sessionId !== '') {
    return <SuccessDisplay sessionId={sessionId} />;
  } else {
    return <Message message={message} history={history}/>;
  }
}

SubscriptionPayment.propTypes = {
  auth: PropTypes.object,
  setAlert: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { setAlert },
)(SubscriptionPayment);