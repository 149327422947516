import React, { useEffect, useState } from "react";
import { useLocation, useHistory, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import api from '../../utils/api';
import setAuthToken from '../../utils/setAuthToken';
import {loadUser} from '../../actions/auth';

const FacebookAuthenticate = ({auth: {isAuthenticated, user}, loadUser}) => {
  const location = useLocation();
  const history = useHistory();

  const [ loginStatus, setLoginStatus ] = useState('');

  useEffect(()=> {
    const authCode = queryString.parse(location.search); //{code, state}
    
    if (authCode.state == process.env.REACT_APP_FACEBOOK_STATE_CONF) {
      try {
        const fbCreds = async (authCode) => {
          const res = await api.post(`/auth/facebook`,authCode);
          console.log(res);
          if (res.status === 200) {
            setLoginStatus('success');
            setAuthToken(res.data.token);
            await loadUser();
            setTimeout(()=> {
              history.replace('/getting-started');
            },1000);
          } else {
            setLoginStatus('fail');
            setTimeout(()=> {
              history.replace('/getting-started');
            },3000);
          }
        } 
        fbCreds(authCode);
      } catch (err) {
        console.log(err);
      }
    } else {
      history.replace('/');
    }
  },[location]);

  //Redirect if logged in and user loaded
  // if(isAuthenticated && user && user._id) {
  //   return <Redirect to='/dashboard' />;
  // }

  return (
      <div className='text-center'>
        { loginStatus === 'success' ? (
            <div>
              <h2 className="button-label my-3 color-myschool-blue">Congratulations, you're in!</h2>
              <h4 className="button-label my-3 color-myschool-blue blinker"> Redirecting to complete your profile... </h4>
            </div>
          ) : loginStatus === 'fail' ? (
            <div>
              <h2 className="button-label my-3 color-myschool-blue">Sorry! There was an issue with Facebook.</h2>
              <h4 className="button-label my-3 color-myschool-blue">You can still create a profile here to get started though!</h4>
              <h4 className="button-label my-3 color-myschool-blue blinker"> Redirecting you there now... </h4>
            </div>
          ) : (
            <div>
              <h4 className="button-label my-3 color-myschool-blue blinker">Logging in...</h4>
            </div>
          )
        }
      </div>
      );
};

FacebookAuthenticate.propTypes = {
  auth: PropTypes.object
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps, { loadUser })(FacebookAuthenticate);
