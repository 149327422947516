import React, { Fragment, useEffect, useState, useRef, useCallback } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Marker, Popup, TileLayer, Map, useLeaflet } from 'react-leaflet';
import L from 'leaflet';
import userIconImg from '../../../images/user-map-icon.svg';
import { Form, FormGroup, Label, Input, Button, Modal, ModalHeader, ModalBody, Spinner } from 'reactstrap';


// Custom Icons for Leaflet

const userIcon = L.icon({
  iconUrl: userIconImg,
  iconSize: [40,40],
  iconAnchor: [20,39],
  popupAnchor: [0,-40]
});

// BEGIN MAP
const ProfileFormMap = ({ bounds, setBounds, currentposition, APIKey, indivLat, indivLong, formData, setFormData }) => {

  const { map } = useLeaflet();

  useEffect(() => {
    map.touchZoom.enable();
    // map.zoomControl.remove();
    // map.dragging.disable();
  },[]);

  //Make Marker Draggable
  const [draggable, setDraggable] = useState(true);
  const [position, setPosition] = useState([indivLat, indivLong]);
  const markerRef = useRef(null);

  const updatePosition = () => {
    const marker = markerRef.current;
    if (marker != null) {
      setPosition([marker.leafletElement.getLatLng().lat, marker.leafletElement.getLatLng().lng]);
    }
  };

  const toggleDraggable = useCallback(() => {
    setDraggable((d) => !d)
  }, []);

  useEffect(() => {
    setFormData({
      ...formData,
      indivLat: position[0],
      indivLong: position[1]
    });
  },[position]);

  return (
    <Fragment>

      <TileLayer
        attribution='<a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>'
        url={`https://api.maptiler.com/maps/streets/{z}/{x}/{y}.png?key=${APIKey}`}
      /> 

      <Marker 
        icon={userIcon}
        onDragend={updatePosition}
        draggable={draggable}
        position={position}
        ref={markerRef}>
        <Popup minWidth={90} className='text-center'>
          <span>
            This is where your personal profile point will appear! Click and hold on the marker to drag it to a new location.
          </span>
        </Popup>
      </Marker>

    </Fragment>
  )
};

export default ProfileFormMap;
