import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Spinner from '../layout/Spinner';
import { Form, Label, Button, Input, Row, Col, Container } from 'reactstrap';


const CheckoutChaperoneEventRegistrationParent = ({ event, regParentFormData, onParentRegistrationChange, validateParentForm, toggleParentRegistrationForm }) => {

  const { parentName, regEmail, phone } = regParentFormData;

  return event === null ? (
    <Spinner />
  ) : (
    <Fragment>
      <Form className='form msstd-form'>
          <Container>
            <h4 className='color-myschool-blue'>Parent Registration</h4>
            <Row>
              <Col>
                  <Label>Parent/Guardian Full Name</Label>
                  <Input 
                  type="text" 
                  name="parentName" 
                  id="parentName" 
                  placeholder="First and Last"
                  value={parentName}
                  onChange={e => onParentRegistrationChange(e)} 
                  required/> 
              </Col>
            </Row>

            <Row>
              <Col md={12} lg={6}>
                  <Label>Email</Label>
                  <Input 
                  type="email" 
                  name="regEmail" 
                  id="regEmail" 
                  placeholder="Email Address"
                  value={regEmail}
                  onChange={e => onParentRegistrationChange(e)} 
                  required/> 
              </Col>
              <Col md={12} lg={6}>
                  <Label>Phone</Label>
                  <Input 
                  type="tel" 
                  name="phone" 
                  id="phone" 
                  placeholder="000-000-0000"
                  value={phone}
                  onChange={e => onParentRegistrationChange(e)} 
                  required/>  
              </Col>
            </Row>
            <div>
              <Button type="button" className='btn-myschool-blue' onClick={()=>validateParentForm()}>Submit</Button>
              <Button type="button" className='btn-light' onClick={()=>toggleParentRegistrationForm(false)}>Cancel</Button>
            </div>
          </Container>
        </Form>
    </Fragment>
  );
};

CheckoutChaperoneEventRegistrationParent.propTypes = {
  event: PropTypes.object.isRequired
};


export default CheckoutChaperoneEventRegistrationParent;


