import { setAlert } from './alert';
import api from '../utils/api';
import queryString from 'query-string';

import {
  GET_RESOURCES_WITHIN_RADIUS,
  GET_RESOURCES_WITHIN_MAPBOUNDS,
  RESOURCE_ERROR
} from './types';

// Post search text query
export const saveTextSearch = (geoData, userLoc, queryObject) => async dispatch => {
  const body = {
    ...queryObject,
    userLocation: userLoc,
    geoData: geoData
  };

  try {
    await api.post(`/v1/mapSearch`, body);

  } catch (err) {
    console.log('search not saved:', err);
  }
};

// Get 
export const copyTextToClipboard = formData => async dispatch => {
  
  const text = formData.shareUrl;
  // console.log(text);

    // function fallbackCopyTextToClipboard(text) {
    //   var textArea = document.createElement("textarea");
    //   textArea.value = text;
    //   textArea.style.position="fixed";  //avoid scrolling to bottom
    //   document.body.appendChild(textArea);
    //   textArea.focus();
    //   textArea.select();

    //   try {
    //     var successful = document.execCommand('copy');
    //     var msg = successful ? 'successful' : 'unsuccessful';
    //     console.log('Fallback: Copying text command was ' + msg);
    //     dispatch(setAlert('Copied to Clipboard', 'success'));
    //   } catch (err) {
    //     console.error('Fallback: Oops, unable to copy', err);
    //     dispatch(setAlert('Error copying to clipboard, sorry :(', 'danger'));
    //   }

    //   document.body.removeChild(textArea);
    // }

  // if (!navigator.clipboard) {
  //   fallbackCopyTextToClipboard(text);
  //   return;
  // }
  navigator.clipboard.writeText(text).then(function() {
    console.log('Async: Copying to clipboard was successful!');
    dispatch(setAlert('Copied to Clipboard', 'success'));
  }, function(err) {
    console.error('Async: Could not copy text: ', err);
    dispatch(setAlert('Error copying to clipboard, sorry :(', 'danger'));
  });
};


//Get results within radius and with QUERY
export const getResultsWithin = ( position, initialQuery ) => async dispatch => {
  
  let queryObject;

  if (!initialQuery.limit ) {
    queryObject = {...initialQuery, limit: 50 }
  } else {
    queryObject = initialQuery
  };

  if (!queryObject.dist ) {
    queryObject = {...queryObject, dist: 50 }
  }

  const { lat, long } = position;

  Object.keys(queryObject).forEach(key => queryObject[key] === 'none' ? delete queryObject[key] : {});
  Object.keys(queryObject).forEach(key => queryObject[key] === undefined ? delete queryObject[key] : {});

  const query = queryString.stringify(queryObject);
  
  try {
    const res = await api.get(`/v1/homeschoolmap/within/${lat}/${long}/${queryObject.dist}?${query}`);
    // console.log('results', res.data);
    dispatch({
      type: GET_RESOURCES_WITHIN_RADIUS,
      payload: res.data.data
    });
  } catch (err) {
    console.log(`Error: ${err?.response?.data}`);
    dispatch({
      type: RESOURCE_ERROR,
      payload: { msg: 'error getting resources', status: err.response.status }
    });
  }
};

// Get results within bounds
export const getBoundedMapResults = (bounds, filterData) => async dispatch => {
  
  const { latMin, latMax, longMin, longMax } = bounds;
  let queryObject;

  if (!filterData.limit ) {
    queryObject = {...filterData, 
      limit: 50
    };
  } else {
    queryObject = filterData
  };

  Object.keys(queryObject).forEach(key => queryObject[key] === 'none' ? delete queryObject[key] : {});
  
  Object.keys(queryObject).forEach(key => queryObject[key] === undefined ? delete queryObject[key] : {});

  const query = queryString.stringify(queryObject);

  try {
    const res = await api.get(`/v1/homeschoolmap/on-map/${latMin}/${latMax}/${longMin}/${longMax}?${query}`);
    // console.log(res);
    dispatch({
      type: GET_RESOURCES_WITHIN_MAPBOUNDS,
      payload: res.data.data
    });
  } catch (err) {
    console.log(`Error: ${err?.response?.data}`);
    dispatch({
      type: RESOURCE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};