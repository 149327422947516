import React, {  } from 'react';

const SponsorWhatIsMySchool = () => {

    return (
        <div className="extended-content-container">
            <div className="section work-area bg-overlay overflow-hidden ptb_50">
                <div className="extended-content">
                    <div className="row justify-content-center">
                        <div className="col-12 col-lg-6">
                            {/* HowWork Content */}
                            <div className="work-content text-center">
                                <h2 className="text-white my-5">So, what is MySchool?</h2>
                                <div className="container">
                                    <p className="text-white my-3 mt-sm-3 mb-sm-4 text-1-1rem text-italic">We believe{' '}<span className="text-bold">SCHOOL</span>{' '} should not be just one place. It should be every place you go to learn.</p>
                                    <p className="text-white my-3 mt-sm-3 mb-sm-4">MySchool is a free homeschooling network that connects families with educational opportunities{' '}<span className="text-bold">OUTSIDE THE HOME</span>.</p>
                                    <p className="text-white my-3 mt-sm-3 mb-sm-4"><span className="text-bold text-1-1rem color-myschool-yellow">MySchool+</span>{' '}is our premium subscription service that extends MySchool's functionality, including free educational content curation, educational discounts, and more!</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SponsorWhatIsMySchool;