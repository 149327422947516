import React, { useState, useEffect } from "react";
import { Container } from 'reactstrap';
import ImageContainer from '../layout/ImageContainer';
import ProfileImageContainer from '../layout/ProfileImageContainer';
import blankProfileImage from '../../images/profile-img-blank.svg';
import userIconImg from '../../images/user-map-icon.svg';
import dayjs from 'dayjs';
import myschoolLogo from '../../images/logo100-100.png';

const MySchoolPlusIdCard = ({ profile, auth, activateMembership, disableButtons, history }) => {


	let currentSchoolYear1; 
	if (dayjs().format('M')*1 >= 8)  {currentSchoolYear1 = dayjs().format('YYYY')} else {currentSchoolYear1 = dayjs().subtract(1,'y').format('YYYY')};
	let currentSchoolYear2;
	if (dayjs().format('M')*1 >= 8 )  {currentSchoolYear2 = dayjs().add(1,'y').format('YYYY')} else {currentSchoolYear2 = dayjs().format('YYYY')};
	const currentSchoolYear = `${currentSchoolYear1} - ${currentSchoolYear2}`

  return (
		<div className="idcard-container">
			<div className="idcard-profile-img">
	            <ProfileImageContainer
	              src={profile.image && profile.image !== 'default.jpg' ? profile.image: blankProfileImage}
	              thumb={blankProfileImage}
	              width='150px' 
	              height='150px' 
	              className='round-img' 
	              alt='profile image'
	              auth={auth}
	              user={profile.user}
	            />
	        </div>
		    
		    <div className="idcard-content">
			    <div className="idcard-sub-content">
			        <h4>{profile.user && profile.user.fullname ? profile.user.fullname : profile.user && profile.user.username ? profile.user.username:''}</h4>
			        <p className='text-bold'>Homeschool Teacher</p>
			        {profile.user && profile.user.id ? <div className='text-075rem'>{`ID#: ${profile.user.id.slice(-8)}`}</div>:''}
			        <div>School Year:{` ${currentSchoolYear}`}</div>
			        { profile && profile.state ? 
			        	<div>
				        	<span className="location"><img src={userIconImg} alt="map icon" height="15px" width="15px"/>{' '}{profile.city?`${profile.city}, `:''}{profile.state}</span>
				        </div>
			        	:''
			        }
			        { profile && profile.user &&  profile.user.subscriberStatusActive &&  profile.user.subscriberStatusActive === true ? 
			        	<div>
				        	Status: <span className='color-success text-bold'>ACTIVE</span><span className='text-075rem'>{' '}(Exp: {dayjs(profile.user.paidSubscriberExpires).format('MM/YYYY')})</span>
				        	<div><span className='text-075rem'>{`Issued: ${dayjs(profile.user.paidSubscriberExpires).subtract(1,'y').format('MM/DD/YYYY')}`}</span></div>
				        </div>
			        	:   profile && profile.user &&  profile.user.subscriberStatusActive === false ?
			        	<div>
				        	Status: <span className='color-danger text-bold'>INACTIVE</span><span className='text-075rem'>{' '}(Exp: {dayjs(profile.user.paidSubscriberExpires).format('MM/YYYY')})</span>
				        	<div><button type='button' onClick={()=>activateMembership()} className='btn btn-myschool-blue btn-075'>Activate</button></div>
				        </div>
			        	: 
			        	<div>
				        	Status: <span className='color-danger text-bold'>INACTIVE</span><span className='text-075rem'></span>
				        	<div><button type='button' onClick={()=>activateMembership()} className='btn btn-myschool-blue btn-075'>Activate</button></div>
				        </div>
			        }
			    </div>
			    {/*profile.user && profile.user.myschoolcardqr ?
				    <div className="idcard-qr-img mb-3">
			            <ImageContainer
			              src={profile.user.myschoolcardqr}
			              thumb={profile.user.myschoolcardqr}
			              width='100px' 
			              height='100px'
			              alt='qr code'
			            />
			        </div>
			        :<div className='my-5'><div className='space'/></div>
			    */}
			    <button className="idcard-btn" type='button' onClick={()=>history.push(`/profile/${profile.user._id}`)} disabled={!history || disableButtons}>
			    	Go to my profile
			    </button>
			    <div className='mt-4'>
	        	<img src={myschoolLogo} alt="myschool logo" height="25px" width="25px"/>{' '}MySchool
	        </div>
			</div>
		</div>
	);
};

MySchoolPlusIdCard.defaultProps = {
	disableButtons: false
};

export default MySchoolPlusIdCard;
