import React, { useState, Fragment, useEffect } from 'react';
import { Container, Col, Row, Table, Button } from 'reactstrap';
import axios from 'axios';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import AuthModals from '../../auth/AuthModals';

const PricingPlanTable = ({ auth: {user, loading, isAuthenticated}, profile: {myProfile, profileLoading}, provider: {providers, providerLoading} }) => {

	const history = useHistory();

	const [ loginModal, toggleLoginModal ] = useState(false);
	const [ registerModal, toggleRegisterModal ] = useState(false);

	const [planFeatures, setPlanFeatures] = useState([
		{
			itemDesc:'Explore the homeschool and event maps',
			user: true,
			profile: true,
			provider: true
		},
		{
			itemDesc:'Add suggested spots to the homeschool map',
			user: true,
			profile: true,
			provider: true
		},
		{
			itemDesc:'Manage a spot on the homeschool map',
			user: false,
			profile: false,
			provider: true
		},
		{
			itemDesc:'Save your favorite spots',
			user: true,
			profile: true,
			provider: true
		},
		{
			itemDesc:'Register for events (field trips, classes, meetups, and more!)',
			user: true,
			profile: true,
			provider: true
		},
		{
			itemDesc:'Find other homeschooling families\' profiles on the homeschool map',
			user: false,
			profile: true,
			provider: false
		},
		{
			itemDesc:'Post events (field trips, classes, meetups, and more!)',
			user: false,
			profile: true,
			provider: true
		},
		{
			itemDesc:'Receive weekly email updates with events near you',
			user: false,
			profile: true,
			provider: false
		},
		{
			itemDesc:'Have your events included in weekly event emails to our members',
			user: false,
			profile: true,
			provider: true
		},
		{
			itemDesc:'Collect bookings for free events and meetups - accessible from your dashboard',
			user: false,
			profile: true,
			provider: true
		},
		{
			itemDesc:'Collect payments and bookings for events (*Requires a free, Stripe Connect account)',
			user: false,
			profile: true,
			provider: true
		},
		// {
		// 	itemDesc:'Access to a personal portfolio with all your attended events (coming soon!)',
		// 	user: false,
		// 	profile: true,
		// 	provider: false
		// },
		{
			itemDesc:'Increase your exposure by tagging your spot profile when you post a suggested spot',
			user: false,
			profile: false,
			provider: true
		}
	]);

	const [planData, setPlanData] = useState([
		{
			id: 1,
			planName: 'User',
			planDesc: 'Basic Usage',
			planLink: ''
		},
		{
			id: 2,
			planName: 'Personal Profile',
			planDesc: 'For homeschooling families!',
			planLink: ''
		},
		{
			id: 3,
			planName: 'Member "Spot"',
			planDesc: 'For businesses and organizations!',
			planLink: ''
		}
	]);


	//GIVE FEEDBACK TO WHAT THEY HAVE AND HAVE NOT YET COMPLETED
	//CHECK IF REGISTERED
	// useEffect(()=> {
	// 	if (user) {
			
	// 	}
	// },[user]);

	//CHECK IF PROFILE CREATED
	// useEffect(()=> {
	// 	if (user) {
			
	// 	}
	// },[user, myProfile]);

	//CHECK PROFILE STRIPE CONNECT STATUS
	// useEffect(()=> {
	// 	if (user) {
			
	// 	}
	// },[user, providers]);

    return(
		<div className='plan-table-wrapper'>
			<div className="price-heading text-center my-5">
        <h2 className='text-color-blue'>Choose the path that fits your needs</h2>
      </div>
			<Table borderless striped hover className='table-basic plan-table'>
    		<thead>
    			<tr>
						<th>&nbsp;</th>
						{ planData.map(plan => {
							return (
								<th key={plan.id} className='text-center'>
									<div className='plan-table-head'>
										<div className='text-1-2rem plan-table-name'>{plan.planName}</div>
										<div className='text-07rem plan-table-desc'>
											{plan.planDesc}
										</div>
									</div>
								</th>
								);
							}
						)}
					</tr>
    		</thead>
    		<tbody className='plan-outline text-075rem text-color-blue'>
    			<tr>
						<td className='text-1-2rem text-bold'>Cost</td>
						<td className='text-1-2rem text-bold text-center'>Free!</td>
						<td className='text-1-2rem text-bold text-center'>Free!</td>
						<td className='text-1-2rem text-bold text-center'>Free!</td>
					</tr>
					{planFeatures.map((item,i)=> {
						return (
							<tr key={i} style={{height:'3rem'}}>
								<td style={{width:'40%'}}>
									{item.itemDesc}
								</td>
								<td style={{width:'20%'}} className='text-center'>
									{item.user === true ? <i className="fas fa-check-circle text-1-2rem color-success"/>:<i className="fas fa-times-circle text-1-2rem color-danger"/>}
								</td>
								<td style={{width:'20%'}} className='text-center'>
									{item.profile === true ? <i className="fas fa-check-circle text-1-2rem color-success"/>:<i className="fas fa-times-circle text-1-2rem color-danger"/>}
								</td>
								<td style={{width:'20%'}} className='text-center'>
									{item.provider === true ? <i className="fas fa-check-circle text-1-2rem color-success"/>:<i className="fas fa-times-circle text-1-2rem color-danger"/>}
								</td>
							</tr>
						)
					})}
					<tr>
						<td>How do I get started?</td>
						<td className='text-center'><button type='button' onClick={()=>toggleRegisterModal(!registerModal)} className='btn btn-myschool-blue' disabled={isAuthenticated}>{!isAuthenticated ? 'Sign up!': 'Done!'}</button></td>
						<td className='text-center'><button type='button' onClick={()=>history.push('/getting-started')} className='btn btn-myschool-blue'>Create Profile!</button></td>
						<td className='text-center'><button type='button' onClick={()=>history.push('/business')} className='btn btn-myschool-blue'>Create a Spot!</button></td>
					</tr>
				</tbody>
    	</Table>

    	<AuthModals toggleLoginModal={toggleLoginModal} loginModal={loginModal} toggleRegisterModal={toggleRegisterModal} registerModal={registerModal} />
		</div>
    );
}

PricingPlanTable.propTypes = {
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  provider: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  profile: state.profile,
  provider: state.provider
});

export default connect(  mapStateToProps, { })(PricingPlanTable);








