import React, { useState } from 'react';
import { Container, Table } from 'reactstrap';
import { useHistory } from 'react-router-dom';

const AdminRoutes = () => {

  const history = useHistory();
  
    return (
    <div>
      <Container>
        <button type='button'
          className='btn btn-myschool-blue'
          onClick={()=>history.push('/admin/user-list')}
          >
          User List
        </button>
        <button type='button'
          className='btn btn-myschool-blue'
          onClick={()=>history.push('/admin/profiles-within')}
          >
          Profiles Within
        </button>
        <button type='button'
          className='btn btn-myschool-blue'
          onClick={()=>history.push('/admin/profiles')}
          >
          Profiles Query
        </button>
        <button type='button'
          className='btn btn-myschool-blue'
          onClick={()=>history.push('/admin/providers')}
          >
          Providers
        </button>
        <button type='button'
          className='btn btn-myschool-blue'
          onClick={()=>history.push('/admin/add-discount')}
          >
          Add Discount
        </button>
      </Container>
    </div>
  );
};

export default AdminRoutes;