import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Badge, Modal, ModalBody, ModalHeader } from 'reactstrap';
import LoginPopup from '../../auth/LoginPopup';
import RegisterPopup from '../../auth/RegisterPopup';
import CreateProfilePreGeolocate from '../../profile-forms/CreateProfilePreGeolocate';
import checkYellow from '../../../images/check-yellow_640-640.svg';
import checkRed from '../../../images/check-red_640-640.svg';
import arrowFwdBlue from '../../../images/arrow-fwd-blue_640-640.svg';
import AddChild from '../../child/AddChild';
import ReactGA from 'react-ga';

const FeatureFamilies = ({auth, profile: { myProfile, profileLoading }}) => {
  const history = useHistory();
  const [ loginModal, toggleLoginModal ] = useState(false);
  const [ registerModal, toggleRegisterModal ] = useState(false);

  const swapToLoginModal = () => {
    toggleLoginModal(!loginModal);
    toggleRegisterModal(!registerModal);
    ReactGA.event({ category: "FindFamilies", action: "Login" });
  };

  const swapToRegisterModal = () => {
    toggleLoginModal(!loginModal);
    toggleRegisterModal(!registerModal);
    ReactGA.event({ category: "FindFamilies", action: "Register" });
  };

  const [ createProfileModal, toggleCreateProfileModal ] = useState(false);

  useEffect(()=> {
    if (auth.isAuthenticated && !myProfile) {
      toggleLoginModal(false);
      toggleRegisterModal(false);
      toggleCreateProfileModal(true);
    };
  },[]);

  //Is Profile done? Add Children?
  const [ showAddChild, setShowAddChild ] = useState(false);
  const [ showAddChildTrigger, setShowAddChildTrigger ] = useState(false);

  useEffect(()=> {
    if (!showAddChildTrigger && auth.isAuthenticated && myProfile && myProfile.showOnMap && myProfile.indivLat && myProfile.indivLat !== 25 && myProfile.indivLong) {
      setShowAddChild(true);
      setShowAddChildTrigger(true);
    }
  },[myProfile]);

  useEffect(()=> {
    if (showAddChild) {
      toggleCreateProfileModal(false);
    }
  },[showAddChild]);

  const openLoginModal = () => {
    ReactGA.event({ category: "FindFamilies", action: "Login" });
    toggleLoginModal(!loginModal);
  };
  const openRegisterModal = () => {
    ReactGA.event({ category: "FindFamilies", action: "Register" });
    toggleRegisterModal(!registerModal);
  };
  const openCreateProfileModal = () => {
    ReactGA.event({ category: "FindFamilies", action: "Create Profile" });
    toggleCreateProfileModal(!createProfileModal);
  };
  const openAddChildrenModal = () => {
    ReactGA.event({ category: "FindFamilies", action: "Add Children" });
    setShowAddChild(true);
  };
  const goToHomeschoolMap = () => {
    // ReactGA.event({ category: "FindFamilies", action: "Add Children" });
    if (auth.isAuthenticated && myProfile){
      ReactGA.event({ category: "FindFamilies", action: "Has Profile -> off to HS Map" });
    } else if (auth.isAuthenticated && !myProfile) {
      ReactGA.event({ category: "FindFamilies", action: "No Profile -> off to HS Map" });
    } else {
      ReactGA.event({ category: "FindFamilies", action: "No User, No Profile -> off to HS Map" });
    }
    history.push("/homeschoolmap?show=profiles");
  }

  return (
      <Fragment>
        <section className="section features-area style-two overflow-hidden">
            <div className="container mb-4">
              <h2 className='text-center color-white mb-4'>
                Create a profile to get started!
              </h2>
              <h4 className='text-center color-white mb-4'>
                Just click on the steps below!
              </h4>
                <div className="row text-center">
                  <div className='family-card-flex-set'>

                    <div className="family-card-set my-3 res-margin">
                      <button type='button' className='basic-button' onClick={()=>openRegisterModal()} disabled={auth.isAuthenticated}>
                          <div className="image-box text-center icon-1 p-5 find-family-feature-cards">
                              <div className="featured-img mb-3">
                                  <i className="fas fa-user-plus fa-2x color-myschool-red"/>
                              </div>
                              <div className="icon-text">
                                  <h4 className="mb-2">1. Register and Login</h4>
                                  {auth.isAuthenticated ? 
                                    (
                                      <div>
                                        <img src={checkRed} width='50px' alt='check step 1'/>
                                        <p>Great! You're already done with this step!</p>
                                      </div>
                                    )
                                    : 
                                    <p>It's free and easy!  Click here to do it now!</p>
                                  }
                              </div>
                          </div>
                      </button>
                    </div>

                    <div className="family-card-set my-3 res-margin">
                      <button 
                        type='button' 
                        className='basic-button' 
                        onClick={!auth.isAuthenticated ? 
                          ()=>openLoginModal()
                          :
                          ()=>openCreateProfileModal()
                        }
                      >
                          <div className="image-box text-center icon-1 p-5 find-family-feature-cards">
                              <div className="featured-img mb-3">
                                  <i className="fas fa-address-card fa-2x color-myschool-yellow" />
                              </div>
                              <div className="icon-text">
                                  <h4 className="mb-2">2. Create a profile</h4>
                                  {myProfile && myProfile.showOnMap && myProfile.indivLat && myProfile.indivLat !== 25 && myProfile.indivLong ? 
                                    (
                                      <div>
                                        <img src={checkYellow} width='50px' alt='check step 2'/>
                                        <p>Great! You're already done with this step too!</p>
                                        <div className='btn btn-myschool-blue' onClick={()=>openAddChildrenModal()}>+ Add a child</div>
                                      </div>
                                    )
                                    : 
                                    <div>
                                      <p>It's free and easy!  Click here to do it now!</p>
                                    </div>
                                  }
                                  
                              </div>
                          </div>
                      </button>
                    </div>

                    <div className="family-card-set my-3 res-margin">
                      <button type='button' className='basic-button' onClick={()=>goToHomeschoolMap()}>
                          <div className="image-box text-center icon-1 p-5 find-family-feature-cards">
                              <div className="featured-img mb-3">
                                  <i className="fas fa-search-location fa-2x color-myschool-blue" alt="" />
                              </div>
                              <div className="icon-text">
                                  <h4 className="mb-2">3. Go to the map!</h4>
                                  {auth.isAuthenticated && myProfile && myProfile.showOnMap && myProfile.indivLat && myProfile.indivLat !== 25 && myProfile.indivLong ? 
                                    (
                                      <div>
                                        <img src={arrowFwdBlue} width='50px' className='blinker' alt='check step 3'/>
                                        <p>Awesome! Click here to head to the map!</p>
                                      </div>
                                    )
                                    : 
                                    <p>Browse the homeschool map to find families that live nearby.</p>
                                  }
                                  {(myProfile && (myProfile.showOnMap === false || myProfile.indivLat === 25)) ? <p className='text-danger'>You must opt in to appear on the map and choose a profile location to see other members.  Go back to step 2 to do this.</p>:''}
                              </div>
                          </div>
                      </button>
                    </div>
                  </div>

                </div>
            </div>
        </section>

      <Modal isOpen={loginModal} centered toggle={() => toggleLoginModal(!loginModal)}>
        <ModalBody className='text-center'>
          <div className='float-right'>
            <button className="basic-button" onClick={() => toggleLoginModal(!loginModal)}>
            <i className='fa fa-times color-light-grey'/>
            </button>
          </div>
          <LoginPopup loginModal={loginModal} toggleLoginModal={toggleLoginModal}/>
          <button onClick={()=>swapToRegisterModal()} className='basic-button text-bold text-underlined'>I need to Register</button>
        </ModalBody>
      </Modal>

      <Modal isOpen={registerModal} centered toggle={() => toggleRegisterModal(!registerModal)}>
        <ModalBody className='text-center'>
          <div className='float-right'>
            <button className="basic-button" onClick={() => toggleRegisterModal(!registerModal)}>
            <i className='fa fa-times color-light-grey'/>
            </button>
          </div>
          <RegisterPopup registerModal={registerModal} toggleRegisterModal={toggleRegisterModal} history={history}/>
          <button onClick={()=>swapToLoginModal()} className='basic-button text-bold text-underlined'>Go to Login</button>
        </ModalBody>
      </Modal>

      <Modal isOpen={createProfileModal} centered toggle={() => toggleCreateProfileModal(!createProfileModal)}>
        <ModalBody className='text-center'>
          <div className='float-right'>
            <button className="basic-button" onClick={() => toggleCreateProfileModal(!createProfileModal)}>
            <i className='fa fa-times color-light-grey'/>
            </button>
          </div>
          <CreateProfilePreGeolocate/>
        </ModalBody>
      </Modal>

      <Modal isOpen={showAddChild} centered toggle={() => setShowAddChild(!showAddChild)}>
        <ModalHeader className='content-justify-center'>
          <div className='float-right'>
            <button className="basic-button" onClick={() => setShowAddChild(!showAddChild)}>
            <i className='fa fa-times color-light-grey'/>
            </button>
          </div>
          Add your children so that you can connect with other families whose children are around the same age.
        </ModalHeader>
        <ModalBody className='text-center'>
          <AddChild />
        </ModalBody>
        <button className='btn btn-modal-blue content-justify-right' onClick={()=>setShowAddChild(false)}>Skip / Done</button>
      </Modal>

    </Fragment>
  );
}

FeatureFamilies.propTypes = {
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  profile: state.profile,
});

export default connect(
  mapStateToProps,
  { },
)(FeatureFamilies);