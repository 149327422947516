import React, { Fragment } from 'react';
import { Card, CardBody, Row, Col, Badge, Modal, ModalHeader, ModalBody } from 'reactstrap';
import Spinner from '../../layout/Spinner';
import { useHistory } from 'react-router-dom';

const MySchoolActivity = ({ activity }) => {  
  
  const history = useHistory();

  const entryData = (
    <Row className='align-items-center'>
		<Col xs={3}>
			{activity && activity.image ? 
				<img src={activity.image} width="100" alt="activity image"/>
				:
				<div>
					<svg height="100" width="100">
					   <circle cx="50" cy="50" r="40" stroke="black" strokeWidth="0" fill="#FB6542" />
					</svg>
					<div style={{position:"absolute", top:"38px",left:"38px"}} className="text-color-white">
						Activity
					</div>
				</div>
			}
	  	</Col>
	  	<Col xs={9}>
	  		<div className="text-1-2rem">
				{activity && activity.name}
			</div>
	  	</Col>
    </Row>
  );

  return !activity ? (
    <Spinner />
    ) : (
    <Fragment> 
      <div className='my-3 mx-2'/>
      <Card onClick={()=>history.push(`/myschoolplus/activity/${activity._id}`)} className="cursor-pointer">               
        <CardBody>
            <div>
              {entryData}
            </div>
        </CardBody> 
      </Card>
    </Fragment>
  )  

};

export default MySchoolActivity;