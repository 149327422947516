import React, { Fragment, useState } from 'react';
import { Map } from 'react-leaflet';
import L from 'leaflet';
import ProviderFormMap from './ProviderFormMap';

const corner1 = L.latLng(90,180);
const corner2 = L.latLng(-90,-180);
const maxBounds = L.latLngBounds(corner1, corner2);

// BEGIN MAP
const ProviderFormMapWrapper = ({ formData, setFormData }) => {
  
  const { lat, long } = formData;
  //MAP STATE
  const [bounds, setBounds] = useState({});
  const currentposition = [lat, long];

  let APIKey;
  if (process.env.NODE_ENV === 'production') {
    APIKey = process.env.REACT_APP_MAPTILER_API_KEY
  } else {
    APIKey = process.env.REACT_APP_MAPTILER_API_TEST_KEY
  }

  return (
    <Fragment>
      <div className='text-center my-1'>Drag the point to change your location.</div>
      <div className='text-075rem'>(This will not change the address provided, but you can hide the address in the next step)</div>
      <Map className="map-wrapper-thumb" center={currentposition} zoom={15} maxBounds={maxBounds}>
        <ProviderFormMap 
          currentposition={currentposition}
          bounds={bounds}
          setBounds={setBounds}
          APIKey={APIKey}
          lat={lat}
          long={long}
          formData={formData}
          setFormData={setFormData}
        />
      </Map>
      <div className='text-center my-1'>Location: ({lat ? lat.toFixed(4)*1 : 'error'}, {long ? long.toFixed(4)*1 : 'error'})</div>
      <div>Click 'Next' when you like your spot.</div>
    </Fragment>
  )
};

export default ProviderFormMapWrapper;
