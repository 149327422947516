import React from 'react';
import { Container } from 'reactstrap';
import ImageContainer from '../layout/ImageContainer';
import blankProfileImage from '../../images/profile-img-blank.svg';
import justinPic from '../../images/Justin-Shell-Headshot.jpg';

const AboutUs = () => {

    const quote = 
    {
        quote: "Maps do not show you where you WILL go in your life. They show you where you MIGHT go in your life. But you very rarely go to a place that is not on your personal map.",
        author: "John Green", 
        knownFor: "author of \"Paper Towns\" & \"The Fault in Our Stars\""
    }

const myschool = 
    {
        mission: 
            "MySchool's mission is help communities bring together all of the diverse resources that they have available, whether that's a museum, a park, an educational organization, a homeschool coop, and even other people so that any homeschooling family can see their local learning community and choose the places that they want to make part of their \"school\".",
        whatIsMySchool: 
            "Most education technology today focuses on moving learning online. MySchool is different. It's a social networking platform that helps homeschooling families take learning offline. We leverage the tools of the digital world to make it easier for parents to find educational opportunities for their kids in the real world.",
        whyMaps: "The internet is exploding with amazing educational resources, but access to nearly limitless disparate information is only one piece of the learning puzzle.  Without learning offline, we would miss out on experiencing the very world that we aim to learn about. Maps allow us to easily visualize our local learning landscape. They provide a window into the things that we can go experience and learn from that are all around us, but maybe never even knew about. They show the possibilities. MySchool aims to help you find the resources that are available all around you so that you can point to it and say \" see this museum, this park, this group, these people... they're all part of my school."
    }

const aboutJustin = "Justin Shell is a father of three and the founder of MySchool. After spending a decade as a naval architect and marine engineer, he decided to start a new career to support his children's education and to share that journey with others. He is a self-directed and self-taught computer programmer, a proponent of community-based learning, a lover of the outdoors, and an avid learner of all things. He built MySchool because he didn't feel equipped to homeschool his kids, and wanted a way to find other people, organizations, and resources willing to share their knowledge and expertise with the local learning community.  MySchool provides to tools to empower each community to come together to build out their local learning landscape."

    return(
        <Container>
            <div className="row text-center">
                <div className="col text-center">
                    <div>
                        <img src="/assets/images/logo100-100.svg" className="mslogo-img" alt="Logo for About Page"/>
                    </div>
                    <div className="m-4">
                        <h4>What is MySchool?</h4>
                    </div>
                    <div>
                        {myschool.whatIsMySchool}
                    </div>
                    <div>
                        {myschool.mission}
                    </div>
                    <div className="mt-4">
                        <h4>Why is Everything on Maps?</h4>
                    </div>
                    <div>
                        <blockquote className="block-quote my-4">
                            <div>
                                {quote.quote}
                            </div>
                            <cite className="block-quote-citation">
                                {quote.author},{' '}{quote.knownFor}
                            </cite>
                        </blockquote>
                    </div>
                    <div>
                        {myschool.whyMaps}
                    </div>
                </div>
            </div>
            <div className='m-5'/>
            <div className="row">
                <div className="col text-center">
                    <div className="mt-4">
                        <h4 className="aboutHeading">About the Founder</h4>
                    </div>
                    <div className="mt-4">
                        <ImageContainer
                            src={justinPic}
                            thumb={blankProfileImage}
                            width='200px' 
                            height='200px' 
                            className='round-img' 
                            alt='justin shell founder image'
                          />
                    </div>
                    <div>
                        {aboutJustin}
                    </div>
                </div>
            </div>
            <div className='ptb_50'/>
        </Container>
    );
}

export default AboutUs;