import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, ButtonGroup, Alert, Container, Row, Col, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';
import ReactGA from 'react-ga';
import Pricing from './Pricing';
import idCardBlue from '../../images/icon-id-card-blue.svg';
import exampleProfileImage from '../../images/example-profile-image.jpg';
import exampleProfileQR from '../../images/example-profile-qr.svg';
import scanQRIcon from '../../images/scan-qr-code.png';
import scanQRIconToPortfolio from '../../images/qrCode-to-portfolio.png';
import scanQRIcon_1 from '../../images/qrCode-to-portfolio_1.png';
import scanQRIconToPortfolio_2 from '../../images/qrCode-to-portfolio_2.png';
import scanQRIconDiscountMem from '../../images/qrCode-discountMembership.png';
import scanQRIconDiscountMem_2 from '../../images/qrCode-discountMembership_2.png';
import myschoolLandingQR from '../../images/scan-qr-code.png';
import whyId from '../../images/why_id_card_graphic.svg';
import partnerIconImg from '../../images/map-marker_partner_red_svg2.svg';
import MySchoolPlusIdCard from './MySchoolPlusIdCard';
import dayjs from 'dayjs';

const MySchoolPlusLanding = ({auth: {isAuthenticated, loading, user}}) => {

    const history = useHistory();

    const [showIdExample, toggleIdExample ] = useState(false);
    const idCardExample = () => {
        ReactGA.event({ category: "MySchool_Plus", action: "Landing_click", label: `ID_Card` });
        toggleIdExample(!showIdExample);
    };

    const browseDiscounts = () => {
        ReactGA.event({ category: "MySchool_Plus", action: "Landing_click", label: `Discounts` });
        history.push('/myschoolplus/discounts');
    };

    const reciprocals = () => {
        ReactGA.event({ category: "MySchool_Plus", action: "Landing_click", label: `Reciprocals` });
        history.push('/myschoolplus/reciprocals');
    };

    const freeLearning = () => {
        ReactGA.event({ category: "MySchool_Plus", action: "Landing_click", label: `Free Educational Resources` });
        history.push('/myschoolplus/free-learning-resources');
    };

    const [ showPortfolioTracking, togglePortfolioTracking ] = useState(false);
    const portfolioTracking = () => {
        ReactGA.event({ category: "MySchool_Plus", action: "Landing_click", label: `Portfolio_Tracking` });
        togglePortfolioTracking(!showPortfolioTracking);
    };

    const clickPlayAndLearn = () => {
        ReactGA.event({ category: "MySchool_Plus", action: "Landing_click", label: `Play_and_Learn` });
        history.push('/myschoolplus/play-and-learn');
    };

    const [showPartnerInfo, togglePartnerInfo ] = useState(false);
    const findMySchoolPartners = () => {
        ReactGA.event({ category: "MySchool_Plus", action: "Landing_click", label: `Find_Partners` });
        togglePartnerInfo(!showPartnerInfo);
        // history.push('/homeschoolmap?myschoolcardpartner=true');
    };

    const [showWhyPortfolio, toggleWhyPortfolio ] = useState(false);
    const whyCreateAPortfolio = () => {
        ReactGA.event({ category: "MySchool_Plus", action: "Landing_click", label: `Why_Create_A_Portfolio` });
        toggleWhyPortfolio(!showWhyPortfolio);
        //https://hslda.org/teaching-my-kids/recordkeeping
    };

    const [showIdMoreInfo, toggleIdMoreInfo ] = useState(false);
    const idCardMoreInfo = () => {
        ReactGA.event({ category: "MySchool_Plus", action: "Landing_click", label: `Why_ID_Card` });
        toggleIdExample(false);
        toggleIdMoreInfo(!showIdMoreInfo);
    }


    const [initData, setInitData] = useState({
        preHeading: "Premium",
        preHeadingspan: "Features",
        heading: "MySchool Plus",
        headingText1: "Learn more.",
        headingText2: "Spend less.",
        data: [
            {
                faImage: "fas fa-money-bill-wave fa-2x color-myschool-red",
                title: "Discounts",
                savings: "$100's in savings",
                content: "With ways to save money on the homeschooling things you need, this membership pays for itself!",
                click: browseDiscounts,
                clickText: 'Browse Discounts'
            },
            {
                image: idCardBlue,
                title: "Digital Homeschool ID",
                savings: "Savings and Entry",
                content: "Make it easy to prove you're a homeschool teacher with this digital ID card.",
                click: idCardExample,
                clickText: 'See It!',
                moreInfoClick: idCardMoreInfo,
                moreInfoText: 'Why do I need an I.D.?' 
            },
            {
                faImage: "far fa-compass fa-2x color-myschool-yellow",
                title: "Reciprocal Navigator",
                savings: "Find Free Entry",
                content: "Museum reciprocal programs can get you in hundreds of museums for free or at discounted prices. Find the best programs and their members with this tool!",
                click: reciprocals,
                clickText: 'More Info'
            },
            {
                faImage: "fab fa-creative-commons-nc fa-2x color-myschool-red",
                title: "Free Educational Resources",
                savings: "Learn for Free",
                content: "There are tons of quality, free resources available. Here are just a few we've used personally and really like.",
                click: freeLearning,
                clickText: 'Browse Resources'
            },
            {
                faImage: "fas fa-book fa-2x color-myschool-blue",
                title: "Easy Portfolio Tracking",
                content: "Simply scan a MySchool QR code at any MySchool Partner location, and it'll save the visit to your child's digital portfolio!",
                click: portfolioTracking,
                clickText: 'See How',
                moreInfoClick: findMySchoolPartners,
                moreInfoText: 'Who are these partners?',
                moreInfoClick2: whyCreateAPortfolio,
                moreInfoText2: 'Why create a portfolio?'
            },
            // {
            //     faImage: "fas fa-gift fa-2x color-myschool-yellow",
            //     title: "Summer Scavenger Hunt!",
            //     savings: "Win Cool Stuff",
            //     content: "Each time you scan a MySchool QR code, you're entered to win cool prizes, inlcuding the grand prize of $1000!",
            //     click: clickPlayAndLearn,
            //     clickText: 'See More!'
            // }
        ]
    });

    const exampleUser = {
        id: '9ca8cf3b9ca8cf3b',
        username:'Aly_Arbor',
        fullname: 'Alison Arbor',
        subscriberStatusActive: true,
        paidSubscriberExpires: dayjs().add(1,'year'),
        myschoolcardqr: exampleProfileQR
    };
    const exampleAuth = {
        isAuthenticated: true,
        user: exampleUser
    }
    const exampleProfileObj = {
        image: exampleProfileImage,
        user: exampleUser,
        city: 'Columbia',
        state: 'MD'
    }

    return (
        <section id="features" className="section features-area style-two overflow-hidden ptb_50">
            <div className="container">
                <div className="row justify-content-center text-center">
                    {/* Section Heading */}
                    <div className="section-heading" style={{marginBottom: "50px"}}>
                        <span className="d-inline-block rounded-pill shadow border fw-5 px-4 py-2 mb-3">
                            <i className="far fa-lightbulb text-primary mr-1" />
                            <span className="text-primary">{initData.preHeading}</span>
                            {initData.preHeadingspan}
                        </span>
                        <h2>{initData.heading}</h2>
                        <div className="mt-4 text-bold">
                            <span className='color-myschool-blue'>{initData.headingText1}</span>{' '}<span className='color-success'>{initData.headingText2}</span>
                        </div>
                        {user && user.subscriberStatusActive ? 
                            ''
                            :
                            <div className='text-center mt-3'>
                                <button type='button' className='btn btn-myschool-blue my-4' onClick={()=>history.push('/myschoolplus/subscribe')}>
                                    Subscribe to unlock all the money saving features!
                                </button>
                            </div>
                        }
                    </div>
                </div>
                
                <div className="row">
                    {initData.data.map((item, idx) => {
                        return(
                            <div key={`ffd_${idx}`} className="col-12 col-md-6 col-lg-4 my-3 res-margin">
                                {/* Image Box */}
                                <div className="image-box text-center icon-1 p-5">
                                {/* Featured Image */}
                                { item.comingSoon && 
                                    <div className='d-inline' style={{position:'relative',top:'-10px',margin:'0'}}>
                                        <Alert color='warning'>Coming Summer 2022!</Alert>
                                    </div>
                                }
                                {item.image ? 
                                    <div className="featured-img mb-3">
                                        <img src={item.image} width='40' alt='myschool I.D. card'/>
                                    </div>
                                    :''
                                }
                                {item.faImage ? 
                                    <div className="featured-img mb-3">
                                        <i className={item.faImage}/>
                                    </div>
                                    :''
                                }
                                {/* Icon Text */}
                                <div className="icon-text">
                                    <h4 className="mb-2">{item.title}</h4>
                                    {item.savings && 
                                        <div className="text-bold color-success">
                                            {item.savings}
                                        </div>
                                    }
                                    <p>{item.content}</p>
                                </div>
                                { item.click && item.clickText ? 
                                    <div className='d-inline'>
                                        <button type='button' className='btn btn-myschool-blue' onClick={()=>item.click()}>{item.clickText}</button>
                                        {item.click2 ? <button type='button' className='btn btn-myschool-blue ml-2' onClick={()=>item.click2()}>{item.clickText2}</button>:''}
                                    </div>
                                    :''
                                }
                                { item.moreInfoClick && item.moreInfoText ? 
                                    <div className=''>
                                        <button type='button' className='basic-button text-09rem text-underlined' onClick={()=>item.moreInfoClick()}>{item.moreInfoText}</button>    
                                    </div>
                                    :''
                                }
                                { item.moreInfoClick2 && item.moreInfoText2 ? 
                                    <div className=''>
                                        <button type='button' className='basic-button text-09rem text-underlined' onClick={()=>item.moreInfoClick2()}>{item.moreInfoText2}</button>    
                                    </div>
                                    :''
                                }
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>

            <Pricing history={history} user={user}/>

            <Modal isOpen={showIdExample} centered toggle={() => toggleIdExample(!showIdExample)} className='modal-transparent'>
                <ModalBody className='text-center'>
                    <div className='float-right'>
                        <button className="btn btn-myschool-blue color-white" onClick={() => toggleIdExample(false)}>
                            Close{' '}<i className='fa fa-times color-white'/>
                        </button>
                    </div>
                    <div className='pt_75'/>
                    <MySchoolPlusIdCard 
                        profile={exampleProfileObj} 
                        auth={exampleAuth} 
                        disableButtons={true} 
                        />
                </ModalBody>
            </Modal>

            <Modal isOpen={showPortfolioTracking} centered toggle={() => togglePortfolioTracking(!showPortfolioTracking)} size="lg" >
                <ModalBody className='text-center'>
                    <div className='float-right'>
                        <button className="basic-button" onClick={() => togglePortfolioTracking(false)}>
                            <i className='fa fa-times color-myschool-blue'/>
                        </button>
                    </div>
                    <Row className='my-4'> 
                        <Col md={{size:8, offset:2}} className='iframe-container'>
                          <div
                            style={{
                              position: "relative",
                              paddingBottom: "56.25%" /* 16:9 */,
                              paddingTop: 25,
                              height: 0,
                              borderLeft: 0,
                              borderRight: 0
                            }}
                          >
                            <iframe
                              style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "100%",
                                height: "100%"
                              }}
                              src="https://www.youtube.com/embed/06vdPrsSvOQ?rel=0"
                              frameBorder="0" 
                              allow='autoplay; encrypted-media'
                              allowFullScreen
                              title="The Learning Scavenger Hunt"
                            />
                          </div>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>

            <Modal isOpen={showPartnerInfo} centered toggle={() => togglePartnerInfo(!showPartnerInfo)} className=''>
                <ModalBody className='text-center'>
                    <div className='float-right'>
                        <button className="basic-button" onClick={() => togglePartnerInfo(false)}>
                            <i className='fa fa-times color-myschool-blue'/>
                        </button>
                    </div>
                    <p className='my-3 text-bold'>
                        Look for the partner icon on the{' '}<span className='cursor-pointer text-underlined' onClick={()=>history.push('/homeschoolmap')}>MySchool Map</span>{' '}to find partner locations near you!
                    </p>
                    <p className=''>
                        <img src={partnerIconImg} width='100' alt='partner icon'/>
                    </p>
                    <p className='my-3'>
                        Right now, we're working with museums, nature centers, and other educational organizations in the Maryland, DC, and Virginia areas!  If you're interested in becoming a partner, email us! If you tell a group and they partner with us, you're next year of MySchool+ is on us!
                    </p>
                    <p>
                        <a href={`mailto:info@myschoolathome.io?subject=I'm interested in becoming a MySchool Partner!`} target='_blank' rel='noopener noreferrer' className='btn btn-modal-blue'>
                            info@myschoolathome.io
                        </a>
                    </p>
                </ModalBody>
            </Modal>

            <Modal isOpen={showWhyPortfolio} centered toggle={() => toggleWhyPortfolio(!showWhyPortfolio)} className=''>
                <ModalBody className=''>
                    <div className='float-right'>
                        <button className="basic-button" onClick={() => toggleWhyPortfolio(false)}>
                            <i className='fa fa-times color-myschool-blue'/>
                        </button>
                    </div>
                    <p className='my-3 text-center text-bold text-1-2rem'>
                        Why should I keep a portfolio?
                    </p>
                    <div className='my-3'>
                        <span className='text-bold text-1-2rem'>Legal Requirements</span>{' '}- 48 U.S. states have legal requirements that can be satisfied in part by keeping a portfolio.  
                    </div>
                    <div className='my-3'>
                        <span className='text-bold text-1-2rem'>As a learning tool</span>{' '}- Did you know that just by discussing a trip you took, you can help your children better retain the things they learned about while on the trip? A portfolio can help you look back and chat about the awesome kearning experiences you've shared in the past. 
                    </div>
                    {/*<div className='my-3'>
                        <span className='text-bold text-1-2rem'>You can win stuff!</span>{' '}- We love playing games at MySchool. So we figured, why not make a game out of building the portfolio? So, scan a QR code. Build your child's portfolio. And MySchool+ users get a chance to win cool stuff too! 
                    </div>*/}
                </ModalBody>
            </Modal>

            <Modal isOpen={showIdMoreInfo} centered toggle={() => toggleIdMoreInfo(!showIdMoreInfo)} className=''>
                <ModalBody className='text-center'>
                    <div className='float-right'>
                        <button className="basic-button" onClick={() => toggleIdMoreInfo(false)}>
                            <i className='fa fa-times color-myschool-blue'/>
                        </button>
                    </div>
                    <p className='my-3 text-bold text-1-2rem'>
                        Why do you need an I.D.?
                    </p>
                    <div>
                        <img src={whyId} width='300' style={{borderRadius:'10px'}} alt='digital I.D. card icon'/>
                    </div>
                    <p>
                        A homeschool ID card can qualify you for
                        <span className="text-bold">teacher discounts</span> 
                        and gets you 
                        <span className="text-bold">entry to homeschool days</span>
                        {' '}and other activities specifically created for homeschoolers!
                        <div className="text-bold">A DIGITAL ID also guarantees you'll never forget it at home!
                        </div>
                    </p>

                </ModalBody>
            </Modal>

        </section>
    );
};


MySchoolPlusLanding.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(
  mapStateToProps,
  {  },
)(MySchoolPlusLanding);