import React from 'react';
import { Route, Switch } from 'react-router-dom';
import NotFound from '../layout/NotFound';
import {AppError} from '../layout/AppError'; 
import PrivateRoute from '../routing/PrivateRoute';
import PaidPortfolioRoute from '../routing/PaidPortfolioRoute';
import AdminRoute from './AdminRoute';
import RegisterComponent from '../auth/RegisterComponent';
import LoginComponent from '../auth/LoginComponent';
import RegisterAndProfilePageWrapper from '../auth/RegisterAndProfilePageWrapper';
import FacebookAuthenticate from '../auth/FacebookAuthenticate';
import Alert from '../layout/Alert';
import Dashboard from '../dashboard/Dashboard';
import DashboardProfile from '../dashboard/dashboard-sections/DashboardProfile';
import CreateProfilePreGeolocate from '../profile-forms/CreateProfilePreGeolocate';
import Profiles from '../profiles/Profiles';
import Profile from '../profile/Profile';
import CreateProvider from '../classprovider-forms/CreateProvider';
import EditProvider from '../classprovider-forms/EditProvider';
import CreateResource from '../resources/CreateResource';
import EditResource from '../resources/EditResource';
import ClaimResource from '../resources/ClaimResource';
import ResourceProfile from '../resources/resource-profile/ResourceWrapper';
import Homeschoolmap from '../homeschoolmap/homeschoolmap-list/HomeschoolMapWrapper';
import ContactComponent from '../company/ContactComponent';
import AboutComponent from '../company/AboutComponent';
import Faq from '../company/FaqSection/Faq';
import Forgot from '../auth/Forgot';
import ResetPassword from '../auth/ResetPassword';
import EditUser from '../auth/EditUser';
import EducationalPhilosophies from '../company/EducationalPhilosophies';
import TermsAndConditions from '../company/TermsAndConditions';
import Privacy from '../company/Privacy';
import LikeWhatWeDoBanner from '../company/LikeWhatWeDoBanner';
import SendEmailToUser from '../homeschoolmap/SendEmailToUser';
import AddChild from '../child/AddChild';
import WhataboutaTool from '../company/WhataboutaTool';
import WhyRegister from '../company/WhyRegister';
import VirtualParentMeetups from '../company/VirtualParentMeetups';
import ProviderSidebarWrapper from '../classprovider/ProviderSidebarWrapper';
import ProviderRedirect from '../classprovider/ProviderRedirect';
import FindFamiliesLandingComponent from '../landing/FindFamiliesLandingComponent';
import GettingStartedProfile from '../landing/GettingStartedProfile';
import ConnectProfileFunnel from '../landing/ConnectProfileFunnel';
import ProviderLanding from '../classprovider/ProviderLanding';
import BusinessLanding from '../classprovider/BusinessLanding';
import EmailProvider from '../classprovider/EmailProvider';
import CreateProviderFunnel from '../classprovider/create-provider-funnel/CreateProviderFunnel';
import EventLanding from '../events/landing/EventLanding';
import CreateEventWrapper from '../event-forms/CreateEventWrapper';
import EditEvent from '../event-forms/EditEvent';
import EventMapSwitchGears from '../events/event-map/EventMapSwitchGears';
import EventMapWrapper from '../events/event-map/EventMapWrapper';
import EventDetailWrapper from '../events/EventDetailWrapper';
import CheckoutDropOffEventWrapper from '../events/CheckoutDropOffEventWrapper';
import CheckoutChaperoneEventWrapper from '../events/CheckoutChaperoneEventWrapper';
import CheckoutParentEventWrapper from '../events/CheckoutParentEventWrapper';
import CheckoutVirtualEventWrapper from '../events/CheckoutVirtualEventWrapper';
import EventBookingWrapper from '../dashboard/dashboard-sections/events/EventBookingWrapper';
import AdminDashboard from '../admin/AdminDashboard';
import EmailProfilesWithQuery from '../admin/admin-functions/EmailProfilesWithQuery';
import EmailProfilesWithinRadius from '../admin/admin-functions/EmailProfilesWithinRadius';
import UserList from '../admin/admin-functions/UserList';
import ProviderAdminInfo from '../admin/admin-functions/ProviderAdminInfo';
import AddDiscount from '../admin/admin-functions/AddDiscount';
import Partnerships from "../company/Partnerships";
import AddSpotToMap from "../homeschoolmap/AddSpotToMap";
import ChoosePath from "../landing/Features/ChoosePath";
import MeetupVirtual from "../meetups-virtual/MeetupVirtual";
import ProfilePayoutMethod from "../payment/ProfilePayoutMethod";
import StripeConnectProfileSuccess from "../payment/StripeConnectProfileSuccess";
import ProviderPayoutMethod from "../payment/ProviderPayoutMethod";
import StripeConnectProviderSuccess from "../payment/StripeConnectProviderSuccess";
import FooterBrief from "../layout/FooterBrief";
import SalonLanding from "../salon-discussions/SalonLanding";
import PricingPlanTable from '../company/pricing-plans/PricingPlanTable';
import WhyBookWithMySchool from '../events/landing/WhyBookWithMySchool';
import DataDeletion from '../company/DataDeletion';
import LoginTestingComp from '../auth/LoginTestingComp';
import Programs from '../programs/Programs';
import MySchoolPlusLanding from '../myschoolplus/MySchoolPlusLanding';
import MySchoolPlusIdCardWrapper from '../myschoolplus/MySchoolPlusIdCardWrapper';
import MySchoolPlusStatus from '../myschoolplus/MySchoolPlusStatus';
import ProviderToPortfolio from '../myschoolplus/ProviderToPortfolio';
import AuthorityToPortfolio from '../myschoolplus/QRCodeScans/AuthorityToPortfolio';
import ActivityToPortfolio from '../myschoolplus/QRCodeScans/ActivityToPortfolio';
import Portfolio from '../portfolio/Portfolio';
import MySchoolPlusPartners from '../myschoolplus/partners/PartnerWrapper';
import MySchoolPlusSponsors from '../myschoolplus/sponsors/SponsorWrapper';
import MySchoolPlusDiscounts from '../myschoolplus/MySchoolPlusDiscounts';
import ReciprocalWrapper from '../myschoolplus/reciprocals/ReciprocalWrapper';
import MySchoolPlusSignUp from '../myschoolplus/MySchoolPlusSignUp';
import MySchoolPlusFreebies from '../myschoolplus/MySchoolPlusFreebies';
import SubscriptionPayment from '../payment/myschoolplus/SubscriptionPayment';
import QRCodeScanLanding from '../myschoolplus/QRCodeScanLanding/QRCodeScanLanding'; 
import ArtifactsWrapper from '../myschoolplus/artifacts/ArtifactsWrapper';
import EmpoweringParentsIdaho from '../landing/state-funding-programs/EmpoweringParentsIdaho';
import TrackAllPrograms from '../landing/network-tracking/TrackAllPrograms';

const Routes = () => {
  return (
    <section className='header-spacing-margin'>
      <React.StrictMode>
        <LikeWhatWeDoBanner />
        <Alert />
        <AppError>
          <Switch>
            <Route exact path='/register' component={RegisterComponent} />
            <Route exact path='/login' component={LoginComponent} />
            <Route exact path='/login/facebook/authenticate' component={FacebookAuthenticate} />
            <Route exact path='/about' component={AboutComponent} />
            <Route exact path='/contact' component={ContactComponent} />
            <Route exact path='/forgot' component={Forgot} />
            <Route exact path='/faqs' component={Faq} />
            <Route exact path='/reset/:token' component={ResetPassword} />
            <Route exact path='/EdPhilosophies' component={EducationalPhilosophies} />
            <Route exact path='/WhataboutaTool' component={WhataboutaTool} />
            <Route exact path='/why-register' component={WhyRegister} />
            <Route exact path='/virtual-parent-meetups' component={VirtualParentMeetups} />
            <Route exact path='/termsofuse' component={TermsAndConditions} />
            <Route exact path='/privacy' component={Privacy} />
            <Route exact path='/deleting-your-data' component={DataDeletion} />
            <Route exact path='/partnerships' component={Partnerships} />
            <Route exact path='/Homeschoolmap' component={Homeschoolmap} />
            <Route exact path='/myschoolmap' component={Homeschoolmap} />
            <Route exact path='/provider/:providerId' component={ProviderSidebarWrapper} />
            <Route exact path='/classprovider/:providerId' component={ProviderRedirect} /> {/*Legacy rerouting*/}
            <Route exact path='/resource/:resourceId' component={ResourceProfile} />
            <Route exact path='/findFamilies' component={FindFamiliesLandingComponent} />
            <Route exact path='/getting-started' component={RegisterAndProfilePageWrapper} />
            <Route exact path='/create-host-profile' component={ConnectProfileFunnel} />
            <Route exact path='/resource-options' component={ProviderLanding} />
            <Route exact path='/business' component={BusinessLanding} />
            <Route exact path='/become-a-resource-member' component={CreateProviderFunnel} />
            <Route exact path='/event-options' component={EventLanding} />
            <Route exact path='/events' component={EventMapSwitchGears} />
            <Route exact path='/events/:eventId' component={EventDetailWrapper} />
            {/*<Route exact path='/events/:eventId/roster' component={EventRoster} /> ON HOLD */}
            <Route exact path='/post-a-spot' component={AddSpotToMap} />
            <Route exact path='/choose-a-path' component={ChoosePath} />
            <Route exact path='/salon-discussions' component={SalonLanding} />
            <Route exact path='/compare-plans' component={PricingPlanTable} />
            <Route exact path='/why-myschool-events' component={WhyBookWithMySchool} />
            <Route exact path='/field-trip-programs' component={Programs} />
            <Route exact path='/myschoolplus' component={MySchoolPlusLanding} />
            <Route exact path='/myschoolplus/user/:userId' component={MySchoolPlusStatus} />
            <Route exact path='/myschoolplus/my-card' component={MySchoolPlusIdCardWrapper} />
            <Route exact path='/myschoolcard/visit/:providerId' component={ProviderToPortfolio} /> {/* Legacy */}
            <Route exact path='/myschoolplus/visit/:providerId' component={ProviderToPortfolio} />
            <Route exact path='/myschoolplus/activity/:activityId' component={ActivityToPortfolio} />
            <Route exact path='/myschoolplus/visit/authority/:authorityId' component={AuthorityToPortfolio} />
            <Route exact path='/myschoolplus/partner' component={MySchoolPlusPartners} />
            <Route exact path='/myschoolplus/sponsor' component={MySchoolPlusSponsors} />
            <Route exact path='/myschoolplus/discounts' component={MySchoolPlusDiscounts} />
            <Route exact path='/myschoolplus/reciprocals' component={ReciprocalWrapper} />
            <Route exact path='/myschoolplus/free-learning-resources' component={MySchoolPlusFreebies} />
            <Route exact path='/myschoolplus/subscribe' component={SubscriptionPayment} />
            <Route exact path='/myschoolplus/play-and-learn' component={QRCodeScanLanding} />
            <Route exact path='/scavenger-hunt' component={QRCodeScanLanding} />
            <Route exact path='/empowering-parents-idaho' component={EmpoweringParentsIdaho} />
            <Route exact path='/programs-near-me' component={TrackAllPrograms} />
            <PrivateRoute exact path='/dashboard' component={Dashboard} />
            <PrivateRoute exact path='/profile/choose-payment-method' component={ProfilePayoutMethod} />
            <PrivateRoute exact path='/profile/choose-payment-method/error' component={StripeConnectProfileSuccess} />
            <PrivateRoute exact path='/profile/choose-payment-method/ok' component={StripeConnectProfileSuccess} />
            <PrivateRoute exact path='/provider/:providerId/choose-payment-method' component={ProviderPayoutMethod} />
            <PrivateRoute exact path='/provider/:providerId/choose-payment-method/error' component={StripeConnectProviderSuccess} />
            <PrivateRoute exact path='/provider/:providerId/choose-payment-method/ok' component={StripeConnectProviderSuccess} />
            <PrivateRoute exact path='/edit-user' component={EditUser} />
            <PrivateRoute exact path='/create-profile' component={CreateProfilePreGeolocate} />
            <PrivateRoute exact path='/edit-profile' component={CreateProfilePreGeolocate} />
            <PrivateRoute exact path='/profiles' component={Profiles} />
            <PrivateRoute exact path='/profile/:id' component={Profile} />
            <PrivateRoute exact path='/send-user-email' component={SendEmailToUser} />
            <PrivateRoute exact path='/create-provider' component={CreateProvider} />
            <PrivateRoute exact path='/edit-provider/:providerId' component={EditProvider} />
            <PrivateRoute exact path='/provider/:providerId/sendEmail' component={EmailProvider} />
            <PrivateRoute exact path='/create-resource' component={CreateResource} />
            <PrivateRoute exact path='/edit-resource/:resourceId' component={EditResource} />
            <PrivateRoute exact path='/claim-resource/:resourceId' component={ClaimResource} />
            <PrivateRoute exact path='/create-event' component={CreateEventWrapper} />
            <PrivateRoute exact path='/create-event/:hostType' component={CreateEventWrapper} />
            <PrivateRoute exact path='/create-event/:hostType/:hostId' component={CreateEventWrapper} />
            <PrivateRoute exact path='/edit-event/:eventId' component={EditEvent} />
            <PrivateRoute exact path='/dashboard/myEvents/:eventId' component={EventBookingWrapper} />
            <PrivateRoute exact path='/events/:eventId/checkout' component={CheckoutChaperoneEventWrapper} />
            <PrivateRoute exact path='/events/:eventId/parentEvent/checkout' component={CheckoutParentEventWrapper} />
            <PrivateRoute exact path='/events/:eventId/dropOff/checkout' component={CheckoutDropOffEventWrapper} />
            <PrivateRoute exact path='/events/:eventId/virtual/checkout' component={CheckoutVirtualEventWrapper} />
            <PrivateRoute exact path='/meetups-virtual/:meetupId' component={MeetupVirtual} />
            <PrivateRoute exact path='/portfolio/child/:childId' component={Portfolio} />
            <PrivateRoute exact path='/portfolio/artifacts' component={ArtifactsWrapper} />
            <AdminRoute exact path='/admin' component={AdminDashboard} />
            <AdminRoute exact path='/admin/profiles' component={EmailProfilesWithQuery} />
            <AdminRoute exact path='/admin/profiles-within' component={EmailProfilesWithinRadius} />
            <AdminRoute exact path='/admin/user-list' component={UserList} />
            <AdminRoute exact path='/admin/providers' component={ProviderAdminInfo} />
            <AdminRoute exact path='/admin/add-discount' component={AddDiscount} />
            <Route exact path='/admin/login' component={LoginTestingComp} />
            <Route component={NotFound} />
          </Switch>
        </AppError>
        <FooterBrief />
      </React.StrictMode>
    </section>
  );
};

export default Routes;
