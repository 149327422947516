import axios from 'axios';

const getGeolocation = () => new Promise((resolve, reject) => {
	// console.log('getting geolocation');
	const res = {};

	if ('geolocation' in navigator) {
		const geoLocOptions = {
	      enableHighAccuracy: true,
	      timeout: 8000
	    };

    	navigator.geolocation.getCurrentPosition((position) => {
				res.status = 'success';
				res.coordinates = { lat: position.coords.latitude, long: position.coords.longitude };
				resolve(res);
			}, async () => {
				window.alert('Unblock location services to ensure the most accurate result.');
				// below is for IP address location services, but Mac prevents this
				try {
					const ipAddress =  await axios.get('https://ipapi.co/json');
					res.status = 'success';
					res.coordinates = { lat: ipAddress.data.latitude, long: ipAddress.data.longitude}
					resolve(res);
				} catch (err) {
					res.status = 'error';
					res.msg ='Sorry this function isn\'t available';
					reject(res);
				}
			},
			geoLocOptions
		);
	} else {
		res.status = 'error';
		res.msg = 'Sorry this function isn\'t available';
		reject(res);
	}
});    


export default getGeolocation;