import React, { Fragment } from "react";
import { Container, Row, Col, Table } from "reactstrap";
import { useHistory } from "react-router-dom";

const ProviderTable = ({ providers }) => {
  
  const history = useHistory();
  // const [formData, setFormData] = useState({
  //   Providers_id: "",
  //   ProvidersNameData: "",
  //   ProvidersBirthDay: "",
  //   gender: ""
  // });

  // const onSubmit = async (e) => {
  //   e.preventDefault();

  //   if (formData.Providers_id != "") {
  //     const data = {
  //       Providers_id: formData.Providers_id,
  //       name: formData.ProvidersNameData,
  //       dob: formData.ProvidersBirthDay,
  //       isUserParentOrGuardian: checkGuardian,
  //       gender: formData.gender,
  //       isVisible: isVisible
  //     };
  //     await createUpdateProviders(data);
  //   } else {
  //     const data = {
  //       name: formData.ProvidersNameData,
  //       dob: formData.ProvidersBirthDay,
  //       isUserParentOrGuardian: checkGuardian,
  //       gender: formData.gender,
  //       isVisible: isVisible
  //     };
  //     await createUpdateProviders(data);
  //   }
  //   window.location.reload();
  // };

  const goToProviderProfile = (providerId) => {
  	history.push(`/classProvider/${providerId}`);
  }

  const goToProviderStripeConnect = (providerId) => {
    history.push(`/provider/${providerId}/choose-payment-method`);
  }

  return (
    <Fragment>
      <Container>
        <Row>
          <Col>
          <div className='mt-4' />
          <h4>Your Spots</h4>
          <br />
            <Table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Connect now!</th>
                  <th>Skip, go to profile</th>
                </tr>
              </thead>
              <tbody className='text-075rem'>
                {providers
                  ? providers.map((provider, i) => (
                      <tr key={i}>
                        <td>{provider.providerName}</td>
                        <td>
                          <button type='button' className='basic-button text-075rem' onClick={()=>goToProviderStripeConnect(provider._id)}>
                          	<i className="fas fa-arrow-right color-myschool-blue"/>
                          </button>
                        </td>
                        <td>
                          <button type='button' className='basic-button text-075rem' onClick={()=>goToProviderProfile(provider._id)}>
                            <i className="fas fa-arrow-right color-myschool-blue"/>
                          </button>
                        </td>
                      </tr>
                    ))
                  : ""}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};


export default ProviderTable;
