import React, { Fragment, useRef, useState, useEffect } from 'react';
import { Jumbotron, Button, ButtonGroup, Container, Row, Col, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';
import jumbotronLogoImg from '../../images/logo100-100.svg';
import HeroSection from './HeroSection/Hero2';
import FeatureSection from './Features/FeatureFive';
import ChoosePath from './Features/ChoosePath';
import DiscoverPeople from './DiscoverSection/DiscoverPeople';
import DiscoverThings from './DiscoverSection/DiscoverThings';
import DiscoverLearningSpots from './DiscoverSection/DiscoverLearningSpots';
import Work from './WorkSection/Work';
import ReviewSection from './ReviewSection/ReviewTwo';
import FooterSection from './FooterSection/Footer';
import introVideo from '../../images/MySchoolIntro_Brief_v210407.mp4';
import queryString from 'query-string';
import ReactGA from 'react-ga';

const LandingWrapper2 = () => {

    const videoRef = useRef(null);
    const scrollToVideo = () => {
        videoRef.current.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
            inline: "start"
        });
    };

    const choosePathRef = useRef(null);
    const scrollToChoosePath = () => {
        choosePathRef.current.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
            inline: "start"
        });
    }; 

    const peopleRef = useRef(null);
    const scrollToPeople = () => {
        peopleRef.current.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
            inline: "start"
        });
    };

    const placesRef = useRef(null);
    const scrollToPlaces = () => {
        placesRef.current.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
            inline: "start"
        });
    };

    const thingsRef = useRef(null);
    const scrollToThings = () => {
        thingsRef.current.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
            inline: "start"
        });
    }; 

    const history = useHistory();

    const findOtherFamilies = () => {
        ReactGA.event({ category: "Landing", action: "Profile to find other families" });
        history.push('/getting-started');
        console.log('findOtherFamilies');
    };

    const homeschoolMapFeatures = () => {
        ReactGA.event({ category: "Landing", action: "Profile to unlock map features" });
        history.push('/getting-started');
        console.log('homeschoolMapFeatures');
    };

    const personalizedEventEmails = () => {
        ReactGA.event({ category: "Landing", action: "Profile to get event emails" });
        history.push('/getting-started');
        console.log('personalizedEventEmails');
    };

    return(
        <Fragment>
            <div className="homepage-5">
                <div className="main">
                    <HeroSection 
                        scrollToPeople={scrollToPeople}
                        scrollToPlaces={scrollToPlaces}
                        scrollToThings={scrollToThings} 
                        history={history}
                        />
                    <DiscoverPeople peopleRef={peopleRef} />
                    <div className='text-center'>
                        <button type='button' onClick={()=>findOtherFamilies()} className='btn btn-myschool-blue'>Click here to find other families now!</button>
                    </div>
                    <DiscoverLearningSpots history={history} placesRef={placesRef} />
                    <div className='text-center'>
                        <button type='button' onClick={()=>homeschoolMapFeatures()} className='btn btn-myschool-blue'>Click here to unlock all the map features!</button>
                    </div>
                    {/*
                    <DiscoverThings thingsRef={thingsRef} />
                    <div className='text-center'>
                        <button type='button' onClick={()=>personalizedEventEmails()} className='btn btn-myschool-blue'>Click here to sign up for personalized emails with events near you!</button>
                    </div>
                    <FeatureSection />
                    */}
                    <ChoosePath history={history} choosePathRef={choosePathRef}/>
                    {/*<ReviewSection />*/}
                    <h4 className='text-center color-myschool-blue p-4'>Take a quick tour to see what we do!</h4>
                    <div className='text-center my-4' ref={videoRef}>
                        <video width="600px" height="auto" controls className='video-wrapper'>
                          <source src={introVideo} type="video/mp4"/>
                        </video>
                    </div>
                    <FooterSection />
                </div>
            </div>
        </Fragment>
    );
}

export default LandingWrapper2;