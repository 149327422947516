import React, { Fragment, useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createProvider, getMyProviders } from '../../actions/provider';
import { Container } from 'reactstrap';
import queryString from 'query-string';
import Spinner from '../layout/Spinner';
import ProviderForm from './ProviderForm';

const CreateProvider = ({ createProvider }) => {
  const location = useLocation();
  const history = useHistory();

  const [formData, setFormData] = useState({
    category: '',
    providerName: '',
    email: '',
    address: '',
    city:'',
    state:'',
    zip:'',
    hideAddress: false,
    edPhil: '',
    edAndEx:'',
    parentInvolveLevel: '',
    parentInvolveNarr: '',
    meetingFreqNum: '',
    meetingFreqUnit: '',
    meetingFreqNarr: '',
    slotsTotal: '',
    slotsPrefilled: '',
    userMembers: '',
    subjectOfferings: '',
    guidelines: '',
    enrollFee: '',
    enrollFeeUnit: '',
    isDropOff: '',
    genInfo: '',
    ageStart: '',
    ageEnd: '',
    whenOperate: '',
    typicalDay: '',
    educationalLeader: '',
    entranceFee: '',
    religiousAffiliation: '',
    website: '',
    phone: '',
    subjects: [],
    tags: [],
    twitter: '',
    facebook: '',
    linkedin: '',
    youtube: '',
    instagram: ''
  });

  const [ tagData, setTagData ] = useState([]);

  const [ subjData, setSubjData ] = useState({
      math: false,
      english: false,
      science: false,
      'social studies': false,
      art: false,
      music: false,
      pe: false,
      health: false,
      history: false,
      'foreign language': false,
      engineering: false,
      'environmental science': false
  });

  const onSubjChange = e => 
    setSubjData({ ...subjData, [e.target.name]: e.target.checked });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = async e => {
    e.preventDefault();
    setIsSubmitting(true);
    const subjArray = Object.keys(subjData).filter(key=>subjData[key] === true);
    const allData = {...formData, subjects: subjArray, tags: tagData };
    await createProvider(allData, history, false);
    setIsSubmitting(false);
  };

  useEffect(() => {
    setFormData({
      ...formData,
      category: queryString.parse(location.search).category && queryString.parse(location.search).category.length > 0 ? queryString.parse(location.search).category: 'individual'
    })
  },[location]);

  return (
    <Fragment>
      <Container>
        <h1 className='large text-primary-ms'>Create Your Spot</h1>
        <small>* required fields</small>
        <ProviderForm
          formData={formData}
          setFormData={setFormData}
          subjData={subjData}
          setSubjData={setSubjData}
          tagData={tagData}
          setTagData={setTagData}
          isSubmitting={isSubmitting}
          onSubmit={onSubmit}
          onSubjChange={onSubjChange}
          history={history}
        />
      </Container>

    </Fragment>
  );
};

CreateProvider.propTypes = {
  createProvider: PropTypes.func.isRequired
};


export default connect(
  null,
  { createProvider }
)(CreateProvider);
