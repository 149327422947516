import React, { Fragment, useState, useEffect } from "react";
import { useHistory, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { Container } from 'reactstrap';
import ConnectStripeButton from '../../images/ConnectStripe_blurple_2x.png'; 
import api from '../../utils/api';
import SpinnerComponent from '../layout/Spinner';
import { setAlert } from '../../actions/alert';

const StripeConnectProfileSuccess = ({ auth: {loading, user}, setAlert }) => {

  const history = useHistory();

  const [ accountLoaded, setAccountLoaded] = useState(false);
  const [ accountDetails, setAccountDetails] = useState({});

  useEffect(()=> {
    const getStripeAccount = async () => {
      try {
        const stripeAccount = await api.get('/v1/stripeConnect/stripe-connect-account/profile');
        // console.log('stripeAccount Details: ',stripeAccount);
        setAccountDetails(stripeAccount.data);
        setAccountLoaded(true);
      } catch (err) {
        console.log(err);
        setAlert('Error.  Please try again later.', 'danger');
        setAccountLoaded(true);
      } 
    };
    if (user) {
      getStripeAccount();
    };
  }, [user]);

  const [ redirectUrl, setRedirectUrl ] = useState('');
  const stripeConnect = async () => {
    try {
      const res = await api.post('/v1/stripeConnect/stripe-connect-account/profile', {});
      setRedirectUrl(res.data.url);
    } catch (err) {
      console.log(err);
      setAlert('Error. Try again later.', 'danger');
    } 
  };

  if (redirectUrl && redirectUrl.length > 0) {
    window.location.href = redirectUrl;
  };

  return !user || !accountLoaded ? (
    <SpinnerComponent />
    ) :(
    <Container className='text-center'>
      {accountDetails && accountDetails.details_submitted === true ?
        <Fragment>
          <h2 className='color-myschool-blue my-4'>Success! You've completed setup!</h2>
          <div className='my-3'>Once Stripe verifies all the information, you'll be able to see on your dashboard if your account is active and you can being hosting!</div>
          <div className='my-3'><button className='btn btn-myschool-blue' onClick={()=>history.push('/dashboard')}>Go to Dashboard</button></div>
        </Fragment>
        :
        accountDetails && accountDetails.details_submitted === false ?
        <Fragment>
          <h2 className='color-myschool-blue my-4'>Wait! You're not quite finished! Click below to complete setup.</h2>
          <div>
            <button className='basic-button my-3' onClick={()=>stripeConnect()}>
              <img src={ConnectStripeButton} width='200px' alt='Create Stripe Connect Account'/>
            </button>
          </div>
        </Fragment>
        :
        <SpinnerComponent />
      }
    </Container>
  );
};

StripeConnectProfileSuccess.propTypes = {
  auth: PropTypes.object.isRequired,
  setAlert: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});


export default connect(mapStateToProps, { setAlert })(StripeConnectProfileSuccess);
