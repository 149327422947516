import React, { } from 'react';
import { Row, Col } from 'reactstrap';

const VideoLesson = ({ lesson }) => {
  console.log(lesson);
    return (
        <div>
           <Row> 
              <Col md={{size:8, offset:2}} className='vid-frame iframe-container'>
                <div
                  style={{
                    position: "relative",
                    paddingBottom: "56.25%" /* 16:9 */,
                    paddingTop: 25,
                    height: 0
                  }}
                >
                  <iframe
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%"
                    }}
                    src={lesson.embedLink} //'https://www.youtube.com/embed/EUQ5EP_DJh8?rel=0'
                    frameBorder="0" 
                    allow='autoplay; encrypted-media'
                    allowFullScreen
                    title={lesson.name}
                  />
                </div>
              </Col>
            </Row> 
        </div>
    );
}

export default VideoLesson;