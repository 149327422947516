import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import Spinner from '../layout/Spinner';
import { Form, FormGroup, Label, Button, Input, Row, Col, Container } from 'reactstrap';


const CheckoutVirtualEventRegistration = ({ event, regFormData, setRegFormData, onRegistrationChange, onRegistrationCheckChange, setCheckoutFormData, checkoutFormData, storeAttendee, toggleRegistrationForm }) => {

  const { childName, childAge, parentalConsent, parentName, regEmail, phone } = regFormData;

  const [ofAge, setOfAge] = useState(true);

  const setOverEighteen = () => {
    setOfAge(true);
    setRegFormData({...regFormData,
      childAge: '',
      parentName: '', 
      parentalConsent: true
    });
  };

  const setUnderEighteen = () => {
    setOfAge(false);
    setRegFormData({...regFormData,
      parentalConsent: false
    });
  };

  const setEmailAddresses = (e) => {
    setRegFormData({...regFormData,
      regEmail: e.target.value
    });
    setCheckoutFormData({...checkoutFormData,
      checkoutEmail: e.target.value
    });
  };

  return (
    <Fragment>
      <Form className='form msstd-form'>
          <Container>
            <h4 className='color-myschool-blue mb-3'>Registration</h4>
            <FormGroup>
              <Label>Full Name</Label>
              <Input type="text" 
                name="childName" 
                id="childName" 
                placeholder="Full Name"
                value={childName}
                onChange={e => onRegistrationChange(e)} 
                required/>
            </FormGroup>

            <Label>Are you over 18?</Label>
            <FormGroup check> 
              <Label check>
                <Input
                  type="radio" 
                  checked={ofAge}
                  onChange={() => setOverEighteen()} 
                  />
                  Yes
              </Label>
            </FormGroup>
            <FormGroup check>
              <Label check>
                <Input check
                type="radio"
                checked={!ofAge}
                onChange={() => setUnderEighteen()} 
                />
                No
              </Label>
            </FormGroup>

            { !ofAge &&
              <FormGroup>
                <Label>Age (if under 18)</Label>
                <Input type="number" 
                  name="childAge" 
                  id="childAge"
                  value={childAge}
                  placeholder="Age"
                  onChange={e => onRegistrationChange(e)}
                   />
              </FormGroup>
            }

            { !ofAge &&
              <FormGroup>
                <Label>Parent/Guardian Full Name</Label>
                <Input 
                type="text" 
                name="parentName" 
                id="parentName" 
                placeholder="First and Last"
                value={parentName}
                onChange={e => onRegistrationChange(e)} 
                required/> 
              </FormGroup>
            }

            <Row form className='my-3'>
              <Col md={12} lg={6}>
                  <Label>Email</Label>
                  <Input 
                  type="email" 
                  name="regEmail" 
                  id="regEmail" 
                  placeholder="Email Address"
                  value={regEmail}
                  onChange={(e) => setEmailAddresses(e)} 
                  required/> 
              </Col>
              <Col md={12} lg={6}>
                  <Label>Phone</Label>
                  <Input 
                  type="tel" 
                  name="phone" 
                  id="phone" 
                  placeholder="000-000-0000"
                  value={phone}
                  onChange={e => onRegistrationChange(e)} 
                  required/>  
              </Col>
            </Row>

            
            <FormGroup check inline className='mb-3'>
              <Label check>
                <Input type="checkbox" 
                  name="parentalConsent" 
                  id="parentalConsent"
                  checked={parentalConsent}
                  onChange={e => onRegistrationCheckChange(e)}
                  required
                />{' '}
                {ofAge ? 
                  'You are over 18'
                  :
                  'You are this child\'s parent or legal guardian'
                }
              </Label>
            </FormGroup>
         
            { parentalConsent !== true ?
              <div>
                <Button type="button" disabled>Submit</Button>
                <Button type="button" className='btn-light' onClick={()=>toggleRegistrationForm(false)}>Cancel</Button>
                <div className='text-danger'>{'You must confirm you are the legal guardian or over 18.'}</div>
              </div>
              :
              <div>
                <Button type="button" className='btn-myschool-blue' onClick={()=>storeAttendee()}>Submit</Button>
                <Button type="button" className='btn-light' onClick={()=>toggleRegistrationForm(false)}>Cancel</Button>
              </div>
            }
          </Container>
        </Form>
    </Fragment>
  );
};

CheckoutVirtualEventRegistration.propTypes = {
  event: PropTypes.object.isRequired
};


export default CheckoutVirtualEventRegistration;