import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import SpinnerComponent from '../../layout/Spinner';
import { getResourceByResourceId } from '../../../actions/resource';
import { getEventsByResourceId } from '../../../actions/event';
import { Container, Modal, ModalBody } from 'reactstrap';
import LoginPopup from '../../auth/LoginPopup';
import RegisterPopup from '../../auth/RegisterPopup';
import ResourceDetails from './ResourceDetails';
import ResourceReviews from './ResourceReviews';
import { getResourceReviews, deleteResourceReview } from '../../../actions/resourceReviews';
import Rating from 'react-rating';
import ReviewResource from '../ReviewResource';
import EventListItem from '../../events/event-map/EventListItem';
import ProgramItem from '../../programs/ProgramItem';
import ReactGA from 'react-ga';

const ResourceWrapper = ({ getResourceByResourceId, resource: { resource, resourceLoading }, auth, getResourceReviews, deleteResourceReview, resourceReviews: {resourceReviews, resourceReviewsLoading }, getEventsByResourceId, event: {eventLoading, events} }) => {

  const history = useHistory();
  const params = useParams();

  const [ showReviewModal, setShowReviewModal ] = useState(false);
  const [ loginModal, toggleLoginModal ] = useState(false);
  const [ registerModal, toggleRegisterModal ] = useState(false);

  const swapLoginRegister = () => {
    toggleLoginModal(!loginModal);
    toggleRegisterModal(!registerModal);
  };


  const addressSelector = (a, c, s, z) => {
    if (navigator.userAgent.toLowerCase().match(/mobile/i)) {
        const urlMobile=encodeURI(`https://maps.google.com/maps/dir/?daddr=${a}, ${c}, ${s} ${z}`);
        window.open(urlMobile,"","rel=noopener,rel=noreferrer");
      } else {
        const urlDesktop= encodeURI(`https://google.com/maps/dir/?api=1&destination=${a}, ${c}, ${s} ${z}`);
        window.open(urlDesktop,"","rel=noopener,rel=noreferrer");
      } 
  }

  const setMapFlyTo = ([lat,long]) => {
    ReactGA.event({ category: "events_on_resource_profile", action: "scroll_over_event", label: `${params.resourceId}`});
  }

  useEffect(() => {
    getResourceByResourceId(params.resourceId);
  }, [params]);

  useEffect(() => {
    getResourceReviews(params.resourceId);
  }, [params]);

  useEffect(() => {
    getEventsByResourceId(params.resourceId);
  },[params]);

  return !resource || resourceLoading ? (
        <SpinnerComponent />
      ) : (
            <div className="resource">
              <div className="container">
                  <div className="row">
                      <div className="col-12 col-lg-3">
                          <div className='mx-1 text-center'>
                            <div>
                              {resource.category && ( resource.category === 'museum' || resource.category === 'nature center' || resource.category === 'park' )?
                                <div className='mt-3' style={{
                                  backgroundImage: `url(/assets/images/badge_${resource.category.toLowerCase().replace(/ /g, '')}.svg)`,
                                  backgroundSize: 'cover',
                                  backgroundRepeat: 'no-repeat',
                                  width:'150px',
                                  height:'150px',
                                  marginLeft: 'auto',
                                  marginRight: 'auto'
                                }}></div>
                                :
                                <div className='my-5'/>
                              }
                            </div>
                            <div>
                              {resource.creditedTo ? 
                                <div className='text-bold'>
                                  Suggested By:<Link to={`/provider/${resource.creditedTo._id}`}>{` ${resource.creditedTo.providerName} `}<i className="fas fa-angle-double-right"/></Link>
                                </div>
                                :''
                              }
                            </div>
                            { resource.doNotMap && resource.doNotMap === true ?
                              ''
                              :
                              <div>
                                <div>
                                  {resource.address} 
                                </div>
                                  {resource.long && resource.lat && 
                                    <div>
                                      <button onClick={()=>history.push(`/homeschoolmap?lat=${resource.lat}&long=${resource.long}&zoom=16`)} className='basic-button text-underlined text-14px text-bold'>
                                        <i className="fas fa-angle-double-left"/>{' '}Find it on our map 
                                      </button>
                                    </div>
                                  }
                                <div>
                                  {resource.doNotMap && resource.doNotMap && resource.address && resource.city && resource.state && resource.zip &&
                                    <button onClick={()=>addressSelector(resource.address, resource.city, resource.state, resource.zip)} className='basic-button text-underlined text-14px text-bold'>
                                      Get Google Map Directions <i className="fas fa-angle-double-right"/>
                                    </button>
                                  }
                                </div>
                              </div>
                            }
                            <div>{resource.city}, {resource.state} {resource.zip}</div>
                            <div>
                              {
                                auth.isAuthenticated ? 
                                <button className='basic-button text-07rem text-bold text-underlined color-myschool-blue' onClick={()=>history.push(`/claim-resource/${resource._id}`)}>Claim Spot</button>
                                :
                                <button className='basic-button text-07rem text-bold text-underlined color-myschool-blue' onClick={()=>toggleLoginModal(true)}>Claim Spot</button>
                              }
                              {' | '}
                              {
                                auth.isAuthenticated ? 
                                <button className='basic-button text-07rem text-bold text-underlined color-myschool-blue' onClick={()=>history.push(`/edit-resource/${resource._id}`)}>Edit Spot</button>
                                :
                                <button className='basic-button text-07rem text-bold text-underlined color-myschool-blue' onClick={()=>toggleLoginModal(true)}>Edit Spot</button>
                              }
                            </div>
                        </div>
                      </div>
                      <div className="col-12 col-lg-9">
                          <ResourceDetails resource={resource} history={history} user={auth.user}/>
                      </div>
                  </div>

                  {resource.programs && resource.programs.length > 0 ?
                    <div>
                      <h4 className='my-1'>Programs for Field Trips</h4>
                      <div className='my-2'>
                         {resource.programs.map(p =>
                            <ProgramItem program={p} key={p._id}/>
                          )}
                      </div>
                    </div>
                    : ''
                  }

                  {events.length > 0 ?
                    <div>
                      <h4 className='my-1'>Upcoming Events</h4>
                      <div className='my-2'>
                         {events.map(ev =>
                            <EventListItem event={ev} key={ev._id} setMapFlyTo={setMapFlyTo}/>
                          )}
                      </div>
                    </div>
                    : ''
                  }

                  <h4 className='my-1'>Reviews</h4>
                        {resource && resource.ratingsAverage  && resource.ratingsQuantity > 0 ? 
                          (
                            <div className='vert-align-2rem'>
                                <h4 className='text-bold float-left'>{resource.ratingsAverage}</h4>
                              {" "}
                              <span className='mx-2'>
                                <Rating
                                  emptySymbol="far fa-star fa-2x color-myschool-yellow"
                                  fullSymbol="fas fa-star fa-2x color-myschool-yellow"
                                  fractions={4}
                                  name='ratingData'
                                  initialRating={resource.ratingsAverage}
                                  readonly
                                />
                              </span>
                              {`${resource.ratingsQuantity} total ratings`}
                            </div>
                          ) : ''
                        }
                        <button className='btn btn-myschool-blue' 
                            onClick={
                              !auth.isAuthenticated ?
                              () => toggleLoginModal(!loginModal)
                              :
                              () => setShowReviewModal(!showReviewModal)
                            }
                          >
                            <i className="far fa-star"/>
                            {" "}Submit a Review
                        </button>
                        <div className='my-2'>
                          {resourceReviews.length > 0 ? (resourceReviews.map(review =>
                              <ResourceReviews resourceReviews={review} auth={auth} deleteResourceReview={deleteResourceReview} key={review._id}/>
                            ))
                          : 
                            (<div>There are no reviews yet for this resource</div>)
                          }
                        </div>
              </div>

              <Modal isOpen={loginModal} centered toggle={() => toggleLoginModal(!loginModal)}>
                <ModalBody className='text-center'>
                  You have to register and login to do that!
                  <LoginPopup loginModal={loginModal} toggleLoginModal={toggleLoginModal}/>
                  <button onClick={()=>swapLoginRegister()} className='basic-button text-bold text-underlined'>I need to Register</button>
                </ModalBody>
              </Modal>

              <Modal isOpen={registerModal} centered toggle={() => toggleRegisterModal(!registerModal)}>
                <ModalBody className='text-center'>
                  <RegisterPopup registerModal={registerModal} toggleRegisterModal={toggleRegisterModal} history={history}/>
                  <button onClick={()=>swapLoginRegister()} className='basic-button text-bold text-underlined'>Go to Login</button>
                </ModalBody>
              </Modal>

              <Modal isOpen={showReviewModal} centered toggle={() => setShowReviewModal(!showReviewModal)}>
                <ModalBody className='text-center'>
                  <ReviewResource resourceId={resource._id} setShowReviewModal={setShowReviewModal} />
                </ModalBody>
              </Modal>

            </div>
        );
}


ResourceWrapper.propTypes = {
  getResourceByResourceId: PropTypes.func.isRequired,
  resource: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  resourceReviews: PropTypes.object.isRequired,
  getResourceReviews: PropTypes.func.isRequired,
  deleteResourceReview: PropTypes.func.isRequired,
  getEventsByResourceId: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  resource: state.resource,
  auth: state.auth,
  resourceReviews: state.resourceReviews,
  event: state.event
});

export default connect(
  mapStateToProps,
  { getResourceByResourceId, getResourceReviews, deleteResourceReview, getEventsByResourceId }
)(ResourceWrapper);