import React, { Fragment, useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import LoginPopup from '../auth/LoginPopup';
import RegisterPopup from '../auth/RegisterPopup';
import { Modal, ModalBody } from 'reactstrap';
import ReactGA from 'react-ga';
import resourceCreditVideo from '../../images/resourceSuggestionCredit_201030.mp4';

const ProviderLanding = ({ isAuthenticated }) => {

  const history = useHistory();

  const [ loginModal, toggleLoginModal ] = useState(false);
  const [ registerModal, toggleRegisterModal ] = useState(false);
  const [ showCreditVideo, toggleCreditVideo ] = useState(false);

  const swapLoginRegister = () => {
    toggleLoginModal(!loginModal);
    toggleRegisterModal(!registerModal);
  };

  const goToPostResource = () => {
    ReactGA.event({ category: "Resources", action: "Suggest Resource" });
    history.push('/create-resource');
  };

  const goToBecomeResource = () => {
    ReactGA.event({ category: "Resources", action: "Become Resource" });
    history.push('/create-provider');
  };

  useEffect(() => {
    ReactGA.event({ category: "Resources", action: "Learning about resources" });
  }, []);
  

  return (
    <Fragment>
      <div className='text-center'>
        
          <div>
              <div className="main">
                  <section id="pricing" className="section price-plan-area bg-gray overflow-hidden ptb_100">
                      <div className="container">
                          <div className="row justify-content-center">
                              <div className="col-12 col-md-10 col-lg-7">
                                  {/* Section Heading */}
                                  <div className="section-heading text-center">
                                      <h2>Learning Spots</h2>
                                      <p className="d-block mt-4">It takes a village. Find yours on MySchoolatHome.</p>
                                      <p className="d-block mt-4">Know a great spot to learn that isn't on the map yet?  <span className='text-bold'>Suggest it!</span></p>
                                      <p className="d-block mt-4">Do you run a group, co-op, or company that offers opportunities to homeschooling families?  Or, do you want to start a co-op or host classes and events for fellow homeschoolers?  <span className='text-bold'>Become a spot!</span></p>
                                      <p  className="d-block mt-4">
                                        <span className='text-underline-red text-bold'>LEARN</span>
                                        {' '}
                                        <span className='text-underline-yellow text-bold'>MORE</span>
                                        {' '}
                                        <span className='text-underline-blue text-bold'>TOGETHER</span>
                                        {'.'}
                                      </p>
                                  </div>
                              </div>
                          </div>
                          <div className="row justify-content-center">
                              <div className="col-12 col-sm-10 col-lg-8">
                                  <div className="row">

                                      <div className="col-12 col-lg-6 mb-2">
                                          {/* Single Price Plan */}
                                          <div className="plan-fixed-height text-center p-5">
                                              {/* Plan Title */}
                                              <div className="plan-title my-2 my-sm-3">
                                                  <h4 className="text-uppercase">Suggest a Spot</h4>
                                              </div>
                                              {/* Plan  */}
                                              <div className="plan-price pb-2 pb-sm-3">
                                                <h1 className="color-primary"><small className="fw-7">Free</small></h1>
                                              </div>
                                              {/* Plan Description */}
                                              <div className="plan-description">
                                                  <ul className="plan-features">
                                                      <li className="border-top py-3"><i className="fas fa-check color-myschool-blue"/>{'  '}Any registered user can suggest spots!</li>
                                                      <li className="border-top py-3"><i className="fas fa-check color-myschool-blue"/>{'  '}Add spots that your family loves to the map.</li>
                                                      <li className="border-top py-3"><i className="fas fa-check color-myschool-blue"/>{'  '}Already registered a spot? You can link suggested spots to your profile page to increase your exposure to our community!{` `}<button className='basic-button text-superscript' onClick={()=>toggleCreditVideo(true)}><i className="fas fa-info-circle"/></button></li>
                                                      <li>
                                                        <button className="btn btn-myschool-blue-border mt-4" 
                                                          onClick={ isAuthenticated ?
                                                            ()=>goToPostResource()
                                                            :
                                                            ()=>toggleRegisterModal(true)}
                                                        >
                                                          Suggest One Now!
                                                        </button>
                                                      </li>
                                                  </ul>
                                              </div>
                                          </div>
                                      </div>

                                      <div className="col-12 col-lg-6 mb-2">
                                          {/* Single Price Plan */}
                                          <div className="plan-fixed-height text-center p-5">
                                              {/* Plan Title */}
                                              <div className="plan-title my-2 my-sm-3">
                                                  <h4 className="text-uppercase">Become a Spot</h4>
                                              </div>
                                              {/* Plan  */}
                                              <div className="plan-price pb-2 pb-sm-3">
                                                <h1 className="color-primary"><small className="fw-7">Free</small></h1>
                                              </div>
                                              {/* Plan Description */}
                                              <div className="plan-description">
                                                  <ul className="plan-features">
                                                      <li className="border-top py-3"><i className="fas fa-check color-myschool-blue"/>{'  '}Become part of the Homeschool Map.</li>
                                                      <li className="border-top py-3"><i className="fas fa-check color-myschool-blue"/>{'  '}Manage your spots from your dashboard.</li>
                                                      <li className="border-top py-3"><i className="fas fa-check color-myschool-blue"/>{'  '}Curate your spot's information to help families understand what you can offer.</li> 
                                                      <li className="border-top py-3"><i className="fas fa-check color-myschool-blue"/>{'  '}Host classes, field trips, events, etc.</li>                                                       
                                                      <li>
                                                        <button className="btn btn-myschool-blue-border mt-4" 
                                                          onClick={ isAuthenticated ?
                                                            ()=>goToBecomeResource()
                                                            :
                                                            ()=>toggleRegisterModal(true)}
                                                        >
                                                          Become One Now!
                                                        </button>
                                                      </li>
                                                  </ul>
                                                  <div><small>*See <Link to='/termsofuse' className='text-underlined'>terms of use</Link> for details on how you can make money through the platform by hosting events.</small></div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div className='my-5'>
                        <button className='basic-button text-bold text-underlined' onClick={()=>toggleRegisterModal(true)}>
                          Register
                        </button> 
                        {' '} or {' '} 
                        <button className='basic-button text-bold text-underlined' onClick={()=>toggleLoginModal(true)}>
                          Login
                        </button> 
                        {' '} to get started!
                      </div>
                  </section>
              </div>
          </div>
      </div>
        

    <Modal isOpen={loginModal} centered toggle={() => toggleLoginModal(!loginModal)}>
        <ModalBody className='text-center'>
          <LoginPopup loginModal={loginModal} toggleLoginModal={toggleLoginModal}/>
          <button onClick={()=>swapLoginRegister()} className='basic-button text-bold text-underlined'>I need to Register</button>
        </ModalBody>
      </Modal>

      <Modal isOpen={registerModal} centered toggle={() => toggleRegisterModal(!registerModal)}>
        <ModalBody className='text-center'>
          <RegisterPopup registerModal={registerModal} toggleRegisterModal={toggleRegisterModal} history={history}/>
          <button onClick={()=>swapLoginRegister()} className='basic-button text-bold text-underlined'>Go to Login</button>
        </ModalBody>
      </Modal>

      <Modal isOpen={showCreditVideo} centered toggle={() => toggleCreditVideo(!showCreditVideo)} className='modal-lg'>
          <ModalBody>
            <video height="auto" controls className='modal-video'>
              <source src={resourceCreditVideo} type="video/mp4"/>
            </video>
            {/*
            <div
              style={{
                position: "relative",
                paddingBottom: "56.25%",
                paddingTop: 25,
                height: 0
              }}
            >
              <iframe
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%"
                }}
                src="https://www.youtube.com/embed/J4duaTNtVmI" 
                frameBorder="0" 
                autoPlay
                allow='autoplay; encrypted-media'
                allowFullScreen
                title="Get credit for your suggestions"
              />
            </div>
          */}
          </ModalBody>
        </Modal>

    </Fragment>
  );
};

ProviderLanding.propTypes = {
  isAuthenticated: PropTypes.bool
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
});


export default connect(mapStateToProps, { })(ProviderLanding);
