const isValidURL = (urlString) => {
	
	const regex = new RegExp(/^(http|https):\/\/[^ "]+$/);

  // If the URL is empty return false
  if (urlString.length < 1)
  {
     return false;
  }
 
  // Return true if the URL matched the ReGex
  return regex.test(urlString);

}


export default isValidURL;