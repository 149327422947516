import React, { useEffect, Fragment, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { Container, Form, FormGroup, Input, Label, Table } from 'reactstrap';
import PropTypes from 'prop-types';
import { adminGetProfilesWithQuery } from '../../../actions/admin';
import Spinner from '../../layout/Spinner';
import formatDate from '../../../utils/formatDate';
import AdminRoutes from '../AdminRoutes';

const EmailProfilesWithQuery = ({ adminGetProfilesWithQuery, auth, profile: {profileLoading, profiles}}) => {

  const history = useHistory();
  
  const [query, setQuery] = useState({
  	state: ''
  });

  const onSubmit = (e) => {
  	e.preventDefault();
  	adminGetProfilesWithQuery(query);
  };
	
  const onQueryChange = e =>
    setQuery({ ...query, [e.target.name]: e.target.value });

  const [copyConfirm, setCopyConfirm] = useState('Copy Emails');
  const copyEmailAddresses = () => {
  	let emailArray = [];
  	profiles.forEach(p=>emailArray.push(p.user.email));
		navigator.clipboard.writeText(emailArray.join(";"));
		setCopyConfirm('Copied!!')
  }

    useEffect(()=> {
    	adminGetProfilesWithQuery({});
    },[auth]);

	return (
    <Container>
      <AdminRoutes />
        <Form onSubmit={(e)=>onSubmit(e)}>
        	<span>
            <Label for="hostName">State</Label>
            <Input type="text"
              name="state"
              id="state"
              value={query.state}
              onChange={e=>onQueryChange(e)}
            />
					</span>
				<div className='text-center'>
	        	<button type='submit' className='btn btn-myschool-blue'>
	        		Submit
	        	</button>
        	</div>

        </Form>
        <div className='float-right'>
        	<button
        		type='button'
        		className='btn btn-myschool-blue'
        		onClick={()=>copyEmailAddresses()}
        		>
        		{copyConfirm}
        	</button>
        </div>
        <Table striped className='admin-table'>
					<thead>
						<tr> 
						  <th>#</th>
						  <th>UserId</th>
						  <th>ProfileId</th>
						  <th>State</th>
						  <th>Email</th>
						</tr>
					</thead>
					<tbody>
            {profiles && profiles.length > 0 ? 
            	profiles.map((profile, i)=>
              (
		                <tr key={profile._id}>
		                  <td>{i+1}</td>
		                  <td>{profile.user && profile.user._id}</td>
		                  <td>{profile._id && profile._id}</td>
		                  <td>{profile.state && profile.state}</td>
		                  <td>{profile.user && profile.user.email}</td>
		                </tr>
              ))
            	:<tr><td>0 results</td></tr>
            }

            </tbody>
        </Table>
    </Container>
  );
};

EmailProfilesWithQuery.propTypes = {
  adminGetProfilesWithQuery: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
	auth: state.auth,
  admin: state.admin,
  profile: state.profile
});


export default connect(mapStateToProps, { adminGetProfilesWithQuery })(EmailProfilesWithQuery);