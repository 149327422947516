import React, { } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { Card, CardBody, Row, Col, Badge } from 'reactstrap';
import Spinner from '../layout/Spinner';
import timechange from '../../utils/timeChange';
import PortfolioImage from './PortfolioImage';

const PortfolioItemDetails = ({ portfolioEntry }) => {  
  
  return !portfolioEntry ? (
    <Spinner />
    ) : (
    <div> 
      <div>
        {`Entry Date: `}{portfolioEntry && portfolioEntry.timestamp ? dayjs(portfolioEntry.timestamp).format("MMM DD, YYYY h:mm a") : ''}
      </div>
      <div>
        {`Subjects: `}
        {portfolioEntry && portfolioEntry.subjects && Array.isArray(portfolioEntry.subjects) && portfolioEntry.subjects.length >0 &&
          portfolioEntry.subjects.map((s,i) => {
            return <Badge key={i} className={`pill-color-${s.replace(/\s+/g, '')} mr-1`} pill>{s}</Badge>
          })
        }
      </div>
      <div>{portfolioEntry && portfolioEntry.duration && portfolioEntry.duration !== undefined ? 
        `Duration: ${timechange(portfolioEntry.duration)}` : ''}
      </div>
      <div> {`Description: `}{portfolioEntry && portfolioEntry.description} </div>
      {portfolioEntry && portfolioEntry.files && portfolioEntry.files.length > 0 ?
        <div>{`${portfolioEntry.files.length} files found`}</div>
        :''
      }
      <div>
        {portfolioEntry && portfolioEntry.files && portfolioEntry.files.length > 0 ?
          portfolioEntry.files.map((file, i)=> 
              <PortfolioImage key={`img-${i}`} imageKey={file.key} />
          )
          :''
        }
      </div>
    </div>
  )  

};



export default PortfolioItemDetails;
