import React, { Fragment, Component, useState, useEffect } from 'react';
import { Col, Row, Button, Form, FormGroup, Label, Input, Spinner, Modal, ModalBody } from 'reactstrap';
import girlAtAquarium from '../../../images/girl-at-aquarium_764-420.jpg';
import ImageContainer from '../../layout/ImageContainer';

const DiscoverLearningSpots = ({ history, placesRef }) => {

    const [ data, setData ] = useState({
        heading: "Places to Go",
        subHeading: "We call all the places on our site \"spots\".  All our spots are either active members on our site, or member generated suggestions, so you can find a handpicked list of amazing learning spots near you! You can even rate and review them, and save your favorites!",
        subHeading2: "...did we mention this is free too?"
    });

    return (
        <section ref={placesRef} className="section service-area overflow-hidden ptb_50">
            <div className="container">
                <div className="row justify-content-around align-items-center">
                    <div className="col-12 col-lg-7 order-2 order-lg-1">
                        <div className="service-text pt-3 pt-lg-0">
                            <h2 className="mb-4">{data.heading}</h2>
                            <p className='my-3'>{data.subHeading}</p>
                            <p className='my-3'>{data.subHeading2}</p>
                        </div>
                    </div>
                    <div className="col-12 col-lg-5 order-1 order-lg-2">
                        <div className="service-thumb mx-auto">
                            <ImageContainer
                              src={girlAtAquarium}
                              thumb={girlAtAquarium}
                              alt='Girl at the aquarium'
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default DiscoverLearningSpots;
