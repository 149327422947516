import axios from 'axios';
import store from '../store';
import { LOGOUT } from '../actions/types';

const api = axios.create({
  baseURL: '/api',
  headers: {
    'Content-Type': 'application/json'
  }
});
/**
 intercept any error responses from the api
 and check if the token is no longer valid.
 ie. Token has expired or user is no longer
 authenticated.
 temporarily disabled logout the user if the token has expired.  Needs to account for not existent profiles/providers/etc that throw a similar error.
**/

api.interceptors.response.use(
  res => res,
  err => {
    
    if (err.response.status === 401) {
      // store.dispatch({ type: LOGOUT });
      console.log('Unauthorized. Please log in.');
    }
    return Promise.reject(err);
  }
);

export default api;