import {
  USER_FULL_DATA_LOADED,
  USER_ERROR,
  UPDATE_USER_FOLLOWS,
  UPDATE_USER_FOLLOWERS
} from '../actions/types';

const initialState = {
  userLoading: true,
  user: null
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case USER_FULL_DATA_LOADED:
      return {
        ...state,
        user: payload,
        userLoading: false
      };
    case UPDATE_USER_FOLLOWS:
      return {
        ...state,
        user: {...state.user, follows: payload},
        userLoading: false
      };
    case UPDATE_USER_FOLLOWERS:
      return {
        ...state,
        user: {...state.user, followers: payload},
        userLoading: false
      };
    case USER_ERROR:
      return {
        ...state,
        user: null,
        userLoading: false
      };
    default:
      return state;
  }
}
