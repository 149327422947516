import React, { useState } from 'react';
import { Container} from 'reactstrap';
import handshake from '../../../images/Cytonn_Photographya-sm.jpg';
import ImageContainer from '../../layout/ImageContainer';

const PartnerWhoCanJoin = () => {

	const [info, setInfo] = useState([
		{
			id: 1,
			heading: 'Are you an educational organization?',
			text: 'Do you provide the opportunity for visitors to explore and learn?',
		},
		{
			id: 2,
			heading: 'Do you support open exploration?',
			text: 'Can visitors come by anytime you\'re open to explore without meeting minimum group sizes or preregistering for programs?*',
		},
		{
			id: 3,
			heading: 'If so, JOIN US!',
			text: 'Click on the button to email justin@myschoolathome.io and we\'ll get you set up!',
		}
	]);

  return(
		<Container>
			<div className='text-center'>
		      	<h4 className='text-color-blue text-2rem mb-4'>
		      	Who can Join?
		      	</h4>
	      	</div>
	        <div className="row justify-content-around align-items-center">
		      	<div className="col-12 col-lg-7 order-2">
	                <div className="service-text pt-3 pt-lg-0">
	                    {info.map((item,i)=> {
							return (
								<div key={item.id} className='text-indent-paragraph my-2 text-color-blue'>
										<i className="fas fa-star color-gold"/>{' '}<span className='text-bold'>
											{item.heading}
										</span>
										{item.text && ` ${item.text}`}
								</div>
							)
						})}
	                </div>
	                <div className='text-07rem'>
	                	*If you only offer scheduled classes or programs, email us to be part of our beta testing group for fall 2022.
	                </div>
	            </div>
	            <div className="col-12 col-lg-5 order-1">
	                <div className="service-thumb mx-auto">
	                    <ImageContainer
	                      src={handshake}
	                      thumb={handshake}
	                      className='d-inline' 
	                      alt='handshake by Cytonn Photographya on Unsplash'
	                    />
	                </div>
	            </div>
	        </div>
		</Container>
  );
};

export default PartnerWhoCanJoin;