import api from '../utils/api';
import { setAlert } from './alert';
import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  CLEAR_PROFILE,
  CLEAR_PROVIDER,
  CLEAR_PROVIDERS,
  EMAILFORPASSWORD_SUCCESS,
  PASSWORD_RESET,
  USER_UPDATED,
  USER_UPDATE_FAILED,
  UPDATE_FAV_RESOURCE,
  UPDATE_FAV_PROVIDER,
  UPDATE_FAV_LESSON
} from './types';

// Load User
export const loadUser = () => async dispatch => {

  try {
    const res = await api.get('/auth');
    
    dispatch({
      type: USER_LOADED,
      payload: res.data
    });

  } catch (err) {
    dispatch({
      type: AUTH_ERROR
    });
  }
};

// Login User
export const login = (email, password) => async dispatch => {
  const body = { email, password };

  try {
    const res = await api.post('/auth', body);
    
    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data
    });

    dispatch(loadUser());
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: LOGIN_FAIL
    });
  }
};

// Register User
export const register = ({ username, email, password, indivMailingListCheck }) => async dispatch => {

  const body = JSON.stringify({ username, email, password, indivMailingListCheck });

  try {
    const res = await api.post('/users', body);
    
    dispatch({
      type: REGISTER_SUCCESS,
      payload: res.data
    });

    dispatch(setAlert('Registration Successful!', 'success'));
    dispatch(loadUser());

  } catch (err) {
    window.scrollTo(0,0);
    const errors = err.response.data.errors;

    if (errors) {
      // console.log(errors);
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: REGISTER_FAIL
    });
  }
};

// Register New User or Login Existing One
export const registerOrLogin = ({ username, email, password, indivMailingListCheck, referrer }) => async dispatch => {

  const body = JSON.stringify({ username, email, password, indivMailingListCheck, referrer });

  try {
    const res = await api.post('/users/register-or-login', body);
    
    dispatch({
      type: REGISTER_SUCCESS,
      payload: res.data
    });

    dispatch(setAlert('Success!', 'success'));
    dispatch(loadUser());

  } catch (err) {
    window.scrollTo(0,0);
    const errors = err.response.data.errors;

    if (errors) {
      // console.log(errors);
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    if (err.response.data.issue && err.response.data.issue === 'existing_user' && err.response.data.email && err.response.data.password) {
      dispatch(loadUser());
    } else {
      dispatch({
        type: REGISTER_FAIL
      });
    }
  }
};

// Register User w/ request for resources
export const registerAndFindMeResources = ({ username, email, password, indivMailingListCheck, currentLatLong }) => async dispatch => {

  const body = JSON.stringify({ username, email, password, indivMailingListCheck, currentLatLong });

  try {
    const res = await api.post('/users/findResources', body);

    dispatch({
      type: REGISTER_SUCCESS,
      payload: res.data
    });

    dispatch(setAlert('Registration Successful!', 'success'));

    dispatch(loadUser());
    
  } catch (err) {
    window.scrollTo(0,0);
    const errors = err.response.data.errors;

    if (errors) {
      console.log(errors);
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: REGISTER_FAIL
    });
  }
};


// Logout / Clear Profile
export const logout = () => async dispatch => {
  await dispatch({ type: CLEAR_PROFILE });
  await dispatch({ type: CLEAR_PROVIDER });
  await dispatch({ type: CLEAR_PROVIDERS });
  await dispatch({ type: LOGOUT });
};

// Send Email for Password Reset
export const emailForPassword = ({ email }) => async dispatch => {

  const body = JSON.stringify({ email });

  try {
    const res = await api.post('/users/forgot', body);

    dispatch({ 
      type: EMAILFORPASSWORD_SUCCESS,
      payload: res.data
     });

    dispatch(setAlert('Email Sent!', 'success'));

  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      console.log(errors);
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: REGISTER_FAIL
    });
  }
};


// Password Reset
export const passwordReset = ({ password, match }) => async dispatch => {

  let token = match.params.token;

  const body = JSON.stringify({ password });

  try {
    const res = await api.post(`/users/reset/${token}`, body);

    dispatch({ 
      type: PASSWORD_RESET,
      payload: res.data
    });

    dispatch(setAlert('Password Reset! Please go to Log In', 'success'));

  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      console.log(errors);
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: LOGOUT
    });
  }
};

// Update User Data
export const editUser = ( formData, history ) => async dispatch => {

  try {
    const res = await api.put('/users', formData);

    dispatch({
      type: USER_UPDATED,
      payload: res.data
    });

  history.push('/dashboard');
  
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      console.log(errors);
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: USER_UPDATE_FAILED
    });
  }
};

// Add favorite resource
export const updateFavResource = resourceId => async dispatch => {

  try {
    const res = await api.put(`/users/favorite/resource/${resourceId}`);

    dispatch({
      type: UPDATE_FAV_RESOURCE,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Add favorite provider
export const updateFavProvider = providerId => async dispatch => {

  try {
    const res = await api.put(`/users/favorite/provider/${providerId}`);

    dispatch({
      type: UPDATE_FAV_PROVIDER,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Add favorite lesson
export const updateFavLesson = lessonId => async dispatch => {
  try {
    const res = await api.put(`/users/favorite/lesson/${lessonId}`);

    dispatch({
      type: UPDATE_FAV_LESSON,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};


