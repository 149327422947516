import React, { useEffect, Fragment, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { Container } from 'reactstrap';
import PropTypes from 'prop-types';
import { deleteAccount } from '../../actions/profile';
import Spinner from '../layout/Spinner';
import DashboardProfile from './dashboard-sections/DashboardProfile';
import DashboardUser from './dashboard-sections/DashboardUser';
import DashboardProvider from './dashboard-sections/DashboardProvider';
import DeleteAccount from './dashboard-sections/DeleteAccount';
import DashboardHostedEvents from './dashboard-sections/DashboardHostedEvents';
// import DashboardHostedOnlineEvents from './dashboard-sections/DashboardHostedOnlineEvents';

const Dashboard = ({ getMyProviders, deleteAccount, auth: { user, loading } }) => {

  const history = useHistory();

  const [ displaySection, toggleDisplaySection] = useState({
    userInfo: true,
    profileInfo: true,
    providerInfo: true,
    myFavs: true,
    deleteAccount: true,
    hostedEvents: true,
    hostedOnlineClasses: false
  });

  const toggleSection = (e) => 
    toggleDisplaySection({...displaySection, [e.target.name]: e.target.checked});

  return loading || !user ? (
    <Spinner />
  ) : (
    <Fragment>
      <Container>
        <div className='my-4'>
          <h2 className='text-color-blue'><strong>Dashboard for {user && user.username}</strong></h2>
        </div>
        
        <DashboardUser 
          displaySection={displaySection} 
          toggleSection={toggleSection}
          history={history}
        />

        <DashboardProfile
          displaySection={displaySection} 
          toggleSection={toggleSection}
          history={history}
        />
     

        <hr />

        <DashboardProvider 
          displaySection={displaySection} 
          toggleSection={toggleSection}
          history={history}
        />

        <hr />

        <DashboardHostedEvents 
          displaySection={displaySection} 
          toggleSection={toggleSection}
          history={history}
        />
       

        <hr />

        <DeleteAccount 
          displaySection={displaySection} 
          toggleSection={toggleSection}
          deleteAccount={deleteAccount}
        />

        <div className='space'/>
        
      </Container>
    </Fragment>
  );
};

Dashboard.propTypes = {
  deleteAccount: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps, { deleteAccount })(Dashboard);

