import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment';

const ProfileAbout = ({ profile: {indivLat, indivLong, city, state, zip, bio, edPhil, lookingFor, shareWithOthers, user, children, covidSocialLevel, virtualMeetups }}) => (
  <div className='profile-about text-center p-2 mb-2'>
    {city && state && zip && (
      <Fragment>
        <div><i className="fas fa-map-marker-alt fa-2x"/>{' '}</div>
        <div><Link to={`/homeschoolmap?lat=${indivLat}&long=${indivLong}&zoom=15`} className='basic-button'>Find Me</Link></div>
        <div><strong>{`${city}, ${state} ${zip}`}</strong></div>
        <br/>
      </Fragment>
    )}

    {
      children && children.length > 0 ?
        children.map((c, i) => (
          c.isVisible ? (
            <Fragment key={i}>
              {c.gender === 'male' ? `Boy` : c.gender === 'female' ? `Girl` : `Child`}
              {` - `}
              { moment().diff(c.dateOfBirth, 'years')*1 > 0 ? 
                `${moment().diff(c.dateOfBirth, 'years')}`
                :
                `${moment().diff(c.dateOfBirth, 'months')} mo.`
              }
              {`, `}
            </Fragment>
          ) : ''
        ))
        :''
    }

    {covidSocialLevel && (
      <Fragment>
        <p><strong>COVID Comfort Level:</strong>{' '}
          {
            (covidSocialLevel === 'no') ?
            `No Contact - virtual only`
            : 
            (covidSocialLevel === 'low') ?
            `Minimal Contact - comfortable in small groups`
            :
            (covidSocialLevel === 'medium') ?
            `Medium Contact - Abiding by the laws, but seeking normalcy`
            :
            (covidSocialLevel === 'high') ?
            `Not taking COVID precautions`
            :''
          }
        </p>
      </Fragment>
    )}
    {edPhil && (
      <Fragment>
        <p><strong>Educational Philosophy:</strong>{' '}{edPhil}</p>
      </Fragment>
    )}
    {bio && (
      <Fragment>
        <p><strong>General Info:</strong>{' '}{bio}</p>
      </Fragment>
    )}
    {lookingFor && (
      <Fragment>
        <p><strong>What are you looking for?</strong>{' '}{lookingFor}</p> 
      </Fragment>
    )}
    {shareWithOthers && (
      <Fragment>
        <p><strong>What do you want to share with others?</strong>{' '}{shareWithOthers}</p> 
      </Fragment>
    )}
    <p><span className='text-bold'>Virtual Meetups?</span>{' '}
      {
        virtualMeetups ?
        `I am interested in virtual meetups`
        :
        `I am not currently interested in vitual meetups`
      }
    </p>
  </div>
);

ProfileAbout.propTypes = {
  profile: PropTypes.object.isRequired
};

export default ProfileAbout;
