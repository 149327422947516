import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';
import AreYouReady from './AreYouReady';
import HowToPlay from './HowToPlay';
import BackButton from '../../../utils/BackButton';

const QRCodeScanLanding = ({auth: {isAuthenticated, loading, user}}) => {

    const history = useHistory();

    const [initData, setInitData] = useState({
        preHeading: "Premium",
        preHeadingspan: "Features",
        heading: "Summer Scavenger Hunt",
        dates: "June 1 - Aug 31, 2022"
    });

    const [ seeIfReady1, setSeeIfReady1 ] = useState(false);
    const [ seeIfReady2, setSeeIfReady2 ] = useState(false);

    return (
        <Container className="ptb_50">
            <div className="container">
                <BackButton history={history} backTo='/myschoolplus' color='blue' textString='Back to MySchool Plus'/>
                <div className="row justify-content-center text-center">
                    {/* Section Heading */}
                    <div className="section-heading mt-4" style={{marginBottom: "20px"}}>
                        <h2>{initData.heading}</h2>
                        <div className='mt-4'>
                            <h4 className='text-color-blue'>{initData.dates}</h4>
                        </div>
                    </div>
                </div>

                {seeIfReady1 ?
                    <AreYouReady />
                    :
                    <div className='d-flex justify-content-center'>
                        <div className='col-6'> 
                            <button className='btn block-button btn-myschool-red' onClick={()=>setSeeIfReady1(true)}>Click to see if you're ready to play!</button>
                        </div>
                    </div>
                }
                
                <HowToPlay history={history}/>

            </div>
            
        </Container>
    );
};


QRCodeScanLanding.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(
  mapStateToProps,
  {  },
)(QRCodeScanLanding);