import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, Form, FormGroup, Label, Input } from 'reactstrap';
import { createResourceReview } from '../../actions/resourceReviews';
import Rating from 'react-rating';
import { setAlert } from '../../actions/alert';

const ReviewResource = ({ resourceId, createResourceReview, setShowReviewModal }) => {
  
  const [reviewData, setReview] = useState('');
  const [ratingData, setRating] = useState(0);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = async e => {
    e.preventDefault();
    if (ratingData === 0 ) {
      setAlert('Rating must be greater than 0', 'danger');
	}
    setIsSubmitting(true);
    await createResourceReview(reviewData, ratingData, resourceId);
    setIsSubmitting(false);
    setShowReviewModal(false);
  };

  return (
    <Fragment>
      
        <Form className='form' onSubmit={e => onSubmit(e)}>
            <FormGroup>
                <Rating
        					emptySymbol="far fa-star fa-2x"
        					fullSymbol="fas fa-star fa-2x"
        					fractions={2}
        					name='ratingData'
        					initialRating={ratingData}
        					onClick={(value) => setRating(value)}
        				/>
            </FormGroup>

            <FormGroup>
				<Label for="review">What did you do here and what did you think of this spot?</Label>
				<Input 
					type='textarea' 
					rows='4'
					name='reviewData'
					value={reviewData}
					onChange={(e) => setReview(e.target.value)}
				/>
            </FormGroup>

          <Button type='submit' className='block-button' disabled={isSubmitting}>Submit</Button>
        </Form>
    </Fragment>
  );
};

ReviewResource.propTypes = {
  setAlert: PropTypes.func.isRequired,
  createResourceReview: PropTypes.func.isRequired
};


export default connect(null, { setAlert, createResourceReview })(ReviewResource);
