import React, { Fragment, useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import ProfileImageUpload from '../ProfileImageUpload';
import { getCurrentProfile } from '../../../actions/profile';
import SpinnerComponent from '../../layout/Spinner';
import CreateProfilePreGeolocate from '../../profile-forms/CreateProfilePreGeolocate';
import DashboardProfileItem from './DashboardProfileItem';
import AddChild from '../../child/AddChild';

const DashboardProfile = ({ getCurrentProfile, profile: { myProfile, profileLoading }, displaySection, child }) => {
  const history = useHistory();
  
  const [ displayModalPhotoUpload, toggleModalPhotoUpload ] = useState(false);
  const [ createProfileModal, toggleCreateProfileModal ] = useState(false);
  const [ displayInfo, toggleInfo ] = useState(true);

  const [ showAddChild, setShowAddChild ] = useState(false);

  useEffect(() => {
    getCurrentProfile();
  }, []);

  return profileLoading ? (
    <SpinnerComponent />
  ) : !profileLoading && myProfile === null ? (
    <Fragment>
      <hr />
      <button type='button' onClick={()=>history.push('/findFamilies')} className='btn btn-myschool-blue'>Create a Personal Profile</button> 
    </Fragment>
  ) : (
    <Fragment>          
      <hr />
        { displaySection.profileInfo && (
            <DashboardProfileItem
              myProfile={myProfile}
              displayInfo={displayInfo}
              toggleInfo={toggleInfo}
              toggleModalPhotoUpload={toggleModalPhotoUpload}
              displayModalPhotoUpload={displayModalPhotoUpload}
              history={history}
              child={child}
            />
          )
        }

        <Modal isOpen={displayModalPhotoUpload} toggle={() => toggleModalPhotoUpload(!displayModalPhotoUpload)}>
          <ModalBody>
            <ProfileImageUpload />
          </ModalBody>
        </Modal>

        <Modal isOpen={createProfileModal} centered toggle={() => toggleCreateProfileModal(!createProfileModal)}>
          <ModalBody className='text-center'>
            <CreateProfilePreGeolocate/>
          </ModalBody>
        </Modal>

        <Modal isOpen={showAddChild} centered toggle={() => setShowAddChild(!showAddChild)}>
        <ModalHeader className='content-justify-center'>
          <div className='float-right'>
            <button className="basic-button" onClick={() => setShowAddChild(!showAddChild)}>
            <i className='fa fa-times color-light-grey'/>
            </button>
          </div>
          Add your children so that you can connect with other families whose children are around the same age.
        </ModalHeader>
        <ModalBody className='text-center'>
          <AddChild />
        </ModalBody>
        <button className='btn btn-modal-blue content-justify-right' onClick={()=>setShowAddChild(false)}>Submit</button>
      </Modal>

    </Fragment>
  );
};

DashboardProfile.propTypes = {
  getCurrentProfile: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  child: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  profile: state.profile,
  child: state.child
});

export default connect(mapStateToProps, { getCurrentProfile })(DashboardProfile);