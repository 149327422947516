import React, {} from 'react';
import { Card, CardBody, CardTitle, CardSubtitle, CardText, CardImg, Button } from 'reactstrap';

const ArtifactCard = ({ artifact: {image, category, imagePadding, video, answer, question, description, authority}}) => {
	
	const artifactExample = {
		_id:"629f58bd5a90c063555e677a",
		image:"https://www.usna.edu/100Objects/_files/images/thumb_01.jpg",
		video:"https://www.youtube.com/embed/SYT2o-Iehk0?start=87",
		question:"What is pictured here?",
		answer:"The Crypt of John Paul Jones",
		authority:"622a28d16d69183284bbbb5c"
	};

	let catColor = "#375e97";

	if (category === "history") {
		catColor = "#fbd46a";
	} else if (category === "science") {
		catColor = "#016c10";
	} 

	return (
		<Card className="flip-card">
			<div className="flip-card-inner" style={{border:`3px solid ${catColor}`}}>
				<div className="flip-card-front">
					{ image && 
						<div className="artifact-card-image" style={imagePadding && 
								{	padding: `${imagePadding[0]}px ${imagePadding[1]}px ${imagePadding[0]}px ${imagePadding[1]}px`
								}}>
					        <CardImg top width="100%" src={image} alt="artifact image" />
				        </div>
			        }
			        <CardBody className="text-center">
				        <CardSubtitle tag="h6">{question}</CardSubtitle>
			        </CardBody>
		        </div>
		        <div className="flip-card-back">
			        <CardBody className="text-center">
						<CardTitle tag="h5">{answer}</CardTitle>
						{description && 
							<CardText>{description}</CardText>
						}
						{ video &&
				        	<div>
				        		<iframe width="100%" src={video} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
				        	</div>
				        }
			        </CardBody>
		        </div>

	        </div>
	     </Card>
	)
}

export default ArtifactCard;