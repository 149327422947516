import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, ButtonGroup, Container, Row, Col, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { Link } from 'react-router-dom';
import FeatureFamilies from './Features/FeatureFamilies';
import introVideo from '../../images/homeschoolMapProfiles.mp4';
import SpinnerComponent from '../layout/Spinner';
import { getCurrentProfile } from "../../actions/profile";
import ReactGA from 'react-ga';

const GettingStartedProfile = ({auth, profile, getCurrentProfile}) => {

    useEffect(() => {
      if (auth && auth.user) {
        getCurrentProfile()
      }
    }, []);

    useEffect(() => {
        ReactGA.event({ category: "Get Started", action: "Arrive on page" });
    },[]);

    return auth.loading ? (
      <SpinnerComponent/>
    ) :  (
        <Fragment>
            <div className="homepage-5">
                <div className="main bg-overlay ptb_50">
                    <FeatureFamilies profile={profile}/>
                </div>
            </div>
    </Fragment>
    );
}

GettingStartedProfile.propTypes = {
  auth: PropTypes.object.isRequired,
  getCurrentProfile: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  profile: state.profile,
});

export default connect(
  mapStateToProps,
  { getCurrentProfile },
)(GettingStartedProfile);