import React, { useState, Fragment, useEffect } from "react";
import { Form, Container, Row, Col, Label, FormGroup, Input, Modal, ModalBody, Button, Spinner, Alert, Card } from 'reactstrap';
import { setAlert } from '../../actions/alert';
import getGeolocation from '../../utils/getGeolocation';
import api from '../../utils/api';
import ImageUpload from '../../utils/ImageUpload';
import axios from 'axios';
import {useHistory} from 'react-router-dom';
import queryString from 'query-string';

const ProfileFormBrief = ({ profile: { myProfile, profileLoading }, formData, setFormData, file, setFile, fullAddress, setFullAddress, isModal, fileSize, setFileSize }) => {
  
  const history = useHistory();
  const addProfileProps = queryString.parse(history.location.search);
  
  useEffect(()=> {
    setFormData({...formData, ...addProfileProps});
  },[]);

  const { howDiscoveredMySchool, mapOptIn } = formData;

  const [ isFindingAddress, setIsFindingAddress] = useState(false);
  const [ isAddressFound, setAddressFound] = useState(false);

  const mapMe = async () => {
    setIsFindingAddress(true);
    const obj = await getGeolocation();
    if (obj.status === 'error') {
      setAlert(obj.msg);
    } else {
      const findAddress = async () => {
        const locQuery = JSON.stringify(obj.coordinates);
        const data = await api.post(`/profile/reverseGeolocate`,locQuery);
        const addressInfo = data.data;
        setFullAddress(`${addressInfo.city}, ${addressInfo.state}`)
        setAddressFound(true);
        setFormData({...formData, city:addressInfo.city, state:addressInfo.state, zip:addressInfo.zip, lat:addressInfo.indivLat, long:addressInfo.indivLong})
      }
      findAddress();
    }
    setIsFindingAddress(false);
  };

  const [manualMap, toggleManualMap] = useState(false);

  const [addressinput, setAddressInput] = useState('');
  const [searchAhead, setSearchAhead] = useState([]);
  const [gettingSearchAhead, setGettingSearchAhead] = useState(false);
  const getSearchAheadResults = async (string) => {
    setAddressInput(string);
    //search ahead updates every 5 characters
    if (string.length > 0 && string.length % 5 === 0) { 
      setGettingSearchAhead(true);
      const searchAheadResults = await api.get(`/v1/homeschoolmap/searchAhead/query?string=${string}`);
      setSearchAhead(searchAheadResults.data.data);
      setGettingSearchAhead(false);
    }
  };

  const addressInput = (slug) => {
    const selectedPoi = searchAhead.find(result => result.slug === slug);
    setSearchAhead([]);
    const latRounded = selectedPoi.place.geometry.coordinates[1].toFixed(1);
    const longRounded = selectedPoi.place.geometry.coordinates[0].toFixed(1);
    const addressString = `${selectedPoi.place.properties.city}, ${selectedPoi.place.properties.stateCode} - [${latRounded}, ${selectedPoi.place.geometry.coordinates[0]}]`;
    setAddressInput(addressString);
    setFullAddress(addressString);
    setFormData({...formData,
      city: selectedPoi.place.properties.city,
      state: selectedPoi.place.properties.stateCode,
      zip: selectedPoi.place.properties.postalCode,
      lat: selectedPoi.place.geometry.coordinates[1],
      long: selectedPoi.place.geometry.coordinates[0],
    });
  };

  const onChange = (e) => {
    setFormData({...formData, [e.target.name]: e.target.value});
  };

  const onCheckChange = (e) => {
    setFormData({...formData, [e.target.name]: e.target.checked});
  };

  // const [ findFamUrlCopied, setFindFamCopied ] = useState('');

  // const copyFindFamilyUrl = async () => {
  //   try {
  //     navigator.clipboard.writeText(`https://myschoolathome.io/findFamilies`);
  //     setFindFamCopied(' - Copied!');
  //   } catch (err) {
  //     setFindFamCopied(' - Error!');
  //   }
  // };

  // const [geoLocationSuccess, setGeoLocationSuccess] = useState(false);
  // const geoLocateMe = () => {
  //   if('geolocation' in navigator) {
  //     navigator.geolocation.getCurrentPosition((position) => {
  //       setFormData({...formData, indivLat: position.coords.latitude, indivLong: position.coords.longitude });
  //       setGeoLocationSuccess(true);
  //       // console.log(position);
  //     }, () => {
  //       window.alert('You must allow location services in order to use this function.');
  //     });
  //   } else {
  //     setAlert('Sorry, but this browser doesn\'t support this function', 'danger');
  //   }
  // };
  const [whyLocation, toggleWhyLocation] = useState(false);

  return (
    <Fragment>
      <div>
        <ImageUpload file={file} setFile={setFile} fileSize={fileSize} setFileSize={setFileSize}/>
      </div>
      <div>
        <div className='d-flex justify-content-start'>
          Set your location{' '}<span className='text-07rem'>(required)</span>{' '}
          <div className='text-superscript ml-2'>
            <button type='button' className='basic-button' onClick={()=>toggleWhyLocation(!whyLocation)}>
              <i className='fa fa-question-circle' />
            </button>
          </div>
        </div>

        { manualMap ?
          <div className="text-left">
            <Input type="text"
              name="addressinput" 
              value={addressinput}
              onChange={e => getSearchAheadResults(e.target.value)}
            >
            </Input>
            {gettingSearchAhead || searchAhead.length > 0 ?
              <Card className="my-1">
                {gettingSearchAhead ? 
                  <div className="blinker">
                    Finding spots...
                  </div>
                  :
                  <div>
                    <div>
                      <button className="basic-button" onClick={() => setSearchAhead([])}>
                      <i className='fa fa-times color-light-grey'/>
                      </button>
                    </div>
                    <ul>
                      {searchAhead.map((poi, i)=> {
                          return <option key={`${poi.slug}-${i}`} className="px-2 py-1 selected-item overflow-hidden" onClick={() => addressInput(poi.slug)}>{poi.displayString}</option>
                        })
                      }
                    </ul>
                  </div>
                }
              </Card>
              :''
            }
            <div className="text-center">
              <button className="basic-button text-underlined text-07rem" onClick={()=>toggleManualMap(!manualMap)}>Automatically geolocate me!</button>
            </div>
          </div>
          :
          <div>
            <div className="small-form my-2">
              <button type="button" disabled={isFindingAddress || isAddressFound} className={!isModal ? `btn btn-block btn-myschool-blue mx-0`:`btn btn-block btn-modal-blue mx-0`} onClick={() => mapMe()} >
                <span>
                    {isFindingAddress ? (
                      `Searching...`
                    ) : isAddressFound ? (
                      'Success!'
                    ) : ( 
                      <span><i className="fa fa-crosshairs color-grey" />{' '}Map me</span>
                    )}
                  </span>
              </button>
            </div>
            <div className="text-center">
              <button className="basic-button text-underlined text-07rem" onClick={()=>toggleManualMap(!manualMap)}>Input Address Instead</button>
            </div>
            <div className="small-form my-2">
              <input type="text" className="form-control" name="fullAddress" placeholder="Your mapped address" disabled value={fullAddress} onChange={e => setFullAddress(e.target.value)} required />
            </div>
          </div>
      }
      </div>

      <div className="my-2">
        <FormGroup check>
          <Label check>
            <Input type="checkbox" 
              name="mapOptIn"
              id="mapOptIn"
              onChange={e=>onCheckChange(e)}
              checked={mapOptIn}
            />{' '}
            I want to appear on the MySchool Map.
          </Label>
        </FormGroup>
      </div>

      <div className="mt-4">
        <div className='form-group text-left'>
          <Label for='howDiscoveredMySchool'>How did you hear about MySchool?</Label>
          <Input
            type='text'
            name='howDiscoveredMySchool'
            value={howDiscoveredMySchool}
            onChange={e => onChange(e)}
          />
        </div>
      </div>

      <Modal centered isOpen={whyLocation} toggle={()=>toggleWhyLocation(!whyLocation)} className='text-center'>
        <div className='d-flex justify-content-end mt-1 mx-3'>
          <button className="basic-button" onClick={() => toggleWhyLocation(!whyLocation)}>
          <i className='fa fa-times color-light-grey'/>
          </button>
        </div>
        <ModalBody className='my-3'>
          <p className='text-bold'>
            Why add your location?
          </p>
          Get additional information, discounts and offers about opportunities near you! We don't store your street address either. We store a point near you so you get the most pertinent information possible while respecting your privacy.
        </ModalBody>
      </Modal>

    </Fragment>
  );
};

ProfileFormBrief.defaultProps = {
  isModal: true
};

export default ProfileFormBrief;
