import React, { Fragment, useEffect } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import HeaderComponent from './components/layout/Header';
import LandingWrapper2 from './components/landing/LandingWrapper2';
import CookieConsentBanner from './components/layout/CookieConsentBanner';
import Routes from './components/routing/Routes';
import ScrollToTop from './ScrollToTop';
import WithTracker from './WithTracker';
import history from './history';

// Redux
import { Provider } from 'react-redux';
import store from './store';
import { loadUser } from './actions/auth';
import setAuthToken from './utils/setAuthToken';
import { LOGOUT } from './actions/types';


const App = () => {

  useEffect(() => {
    // check for token in LS
    if (localStorage.token) {
      setAuthToken(localStorage.token);
      // console.log('app looking for token', localStorage.token.length)
    }
    // console.log('app loadUser');
    store.dispatch(loadUser());

    // log user out from all tabs if they log out in one tab
    window.addEventListener('storage', () => {
      if (!localStorage.token) store.dispatch({ type: LOGOUT });
    });
  }, []);


  // let trackUserId;
  // useEffect(() => {
  //   if (auth && auth.user) {
  //     trackUserId = auth.user._id;
  //   }
  // }, [auth]);

  return (
    <Provider store={store}>
      <Router history={history}>
        <Fragment>
          <ScrollToTop />
          <HeaderComponent />
          <Switch>
            <Route exact path='/' component={WithTracker(LandingWrapper2, {})} />
            <Route component={WithTracker(Routes, {})} />
          </Switch>
          <CookieConsentBanner />
        </Fragment>
      </Router>
    </Provider>
  );
};

export default App;
