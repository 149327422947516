export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const REMOVE_ALERTS = 'REMOVE_ALERTS';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const USER_LOADED = 'USER_LOADED';
export const USER_FULL_DATA_LOADED = 'USER_FULL_DATA_LOADED';
export const USER_ERROR = 'USER_ERROR';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const GET_PROFILE = 'GET_PROFILE';
export const GET_MY_PROFILE = 'GET_MY_PROFILE';
export const GET_PROFILES = 'GET_PROFILES';
export const GET_PROFILES_WITHIN = 'GET_PROFILES_WITHIN';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const CLEAR_PROFILE = 'CLEAR _PROFILE';
export const PROFILE_ERROR = 'PROFILE_ERROR';
export const EMAIL_ERROR = 'EMAIL_ERROR'; 
export const ACCOUNT_DELETED = 'ACCOUNT_DELETED';
export const UPDATE_LIKES = 'UPDATE_LIKES';
export const BOOK_CLASS = 'BOOK_CLASS';
export const BOOK_CLASS_ERROR = 'BOOK_CLASS_ERROR';
export const UPDATE_BOOKING_COUNT = 'UPDATE_BOOKING_COUNT';
export const ADD_COMMENT = 'ADD_COMMENT';
export const REMOVE_COMMENT = 'REMOVE_COMMENT';
export const GET_RESOURCE = 'GET_RESOURCE';
export const GET_RESOURCES = 'GET_RESOURCES';
export const GET_RESOURCES_WITHIN_RADIUS = 'GET_RESOURCES_WITHIN_RADIUS';
export const GET_RESOURCES_WITHIN_MAPBOUNDS = 'GET_RESOURCES_WITHIN_MAPBOUNDS';
export const RESOURCE_ERROR = 'RESOURCE_ERROR';
export const GET_RESOURCE_REVIEWS ='GET_RESOURCE_REVIEWS';
export const ADD_RESOURCE_REVIEW ='ADD_RESOURCE_REVIEW';
export const RESOURCE_REVIEW_DELETED ='RESOURCE_REVIEW_DELETED';
export const RESOURCE_REVIEW_ERROR = 'RESOURCE_REVIEW_ERROR';
export const EMAILFORPASSWORD_SUCCESS = 'EMAILFORPASSWORD_SUCCESS';
export const PASSWORD_RESET = 'PASSWORD_RESET';
export const GET_USER_EVENTS = 'GET_USER_EVENTS';
export const USER_UPDATED = 'USER_UPDATED';
export const USER_UPDATE_FAILED = 'USER_UPDATE_FAILED';
export const UPDATE_FAV_RESOURCE = 'UPDATE_FAV_RESOURCE';
export const UPDATE_FAV_PROVIDER = 'UPDATE_FAV_PROVIDER';
export const UPDATE_FAV_LESSON = 'UPDATE_FAV_LESSON';
export const GET_PROVIDER = 'GET_PROVIDER';
export const GET_MY_PROVIDER = 'GET_MY_PROVIDER';
export const PROVIDER_ERROR = 'PROVIDER_ERROR';
export const CLEAR_PROVIDER = 'CLEAR_PROVIDER';
export const UPDATE_PROVIDER = 'UPDATE_PROVIDER';
export const PROVIDER_DELETED = 'PROVIDER_DELETED';
export const GET_PROVIDERS = 'GET_PROVIDERS';
export const GET_PROVIDERS_WITHIN_RADIUS = 'GET_PROVIDERS_WITHIN_RADIUS';
export const GET_PROVIDERS_WITHIN_MAPBOUNDS = 'GET_PROVIDERS_WITHIN_MAPBOUNDS';
export const CLEAR_PROVIDERS = 'CLEAR_PROVIDERS';
export const EMAIL_PROVIDER_ERROR = 'EMAIL_PROVIDER_ERROR';
export const GET_EVENTS_BY_USER = 'GET_EVENTS_BY_USER';
export const GET_EVENTS_BY_PROVIDER = 'GET_EVENTS_BY_PROVIDER';
export const EMAIL_PROVIDER_GEN_ERROR = 'EMAIL_PROVIDER_GEN_ERROR';
export const GET_PROVIDER_REVIEWS ='GET_PROVIDER_REVIEWS';
export const ADD_PROVIDER_REVIEW ='ADD_PROVIDER_REVIEW';
export const PROVIDER_REVIEW_DELETED ='PROVIDER_REVIEW_DELETED';
export const PROVIDER_REVIEW_ERROR = 'PROVIDER_REVIEW_ERROR';
export const GET_PORTFOLIO_BY_CHILDID = 'GET_PORTFOLIO_BY_CHILDID';
export const GET_PORTFOLIO_STATS_BY_CHILDID = 'GET_PORTFOLIO_STATS_BY_CHILDID';
export const EDIT_PORTFOLIO_ENTRY = 'EDIT_PORTFOLIO_ENTRY';
export const PORTFOLIO_ERROR = 'PORTFOLIO_ERROR';
export const GET_CURRICULUM = 'GET_CURRICULUM';
export const GET_CURRICULUMS = 'GET_CURRICULUMS';
export const CURRICULUM_ERROR = 'CURRICULUM_ERROR'; 
export const GET_LESSON = 'GET_LESSON';
export const GET_LESSONS = 'GET_LESSONS';
export const LESSON_ERROR = 'LESSON_ERROR';
export const GET_CHILDS = 'GET_CHILDS';
export const GET_CHILD = 'GET_CHILD';
export const SELECTED_CHILD = 'SELECTED_CHILD';
export const GET_AWAKE_BED_TIME = 'GET_AWAKE_BED_TIME';
export const ADD_AWAKE_BED_TIME = 'ADD_AWAKE_BED_TIME';
export const UPDATE_AWAKE_BED_TIME = 'UPDATE_AWAKE_BED_TIME';
export const ADD_NEW_CARD = 'ADD_NEW_CARD';
export const UPDATE_ROUTINE_CARD = 'UPDATE_ROUTINE_CARD';
export const GET_CHILD_ROUTINE_CARD_BY_ID = 'GET_CHILD_ROUTINE_CARD_BY_ID';
export const GET_CHILD_ALL_ROUTINE_CARD_BY_ID = 'GET_CHILD_ALL_ROUTINE_CARD_BY_ID';
export const ADD_CHILD_SUBJECT = 'ADD_CHILD_SUBJECT';
export const UPDATE_CHILD_SUBJECT = 'UPDATE_CHILD_SUBJECT';
export const DELETE_CHILD_SUBJECT = 'DELETE_CHILD_SUBJECT';
export const GET_CHILD_SUBJECT = 'GET_CHILD_SUBJECT';
export const DELETE_ROUTINE_CARD =  'DELETE_ROUTINE_CARD';
export const GET_RESOURCE_CARD =  'GET_RESOURCE_CARD';
export const GET_SINGLE_RESOURCE_CARD = 'GET_SINGLE_RESOURCE_CARD';
export const UPDATE_RESOURCE_CARD =  'UPDATE_RESOURCE_CARD';
export const DELETE_RESOURCE_CARD =  'DELETE_RESOURCE_CARD';
export const ADD_RESOURCE_CARD =  'ADD_RESOURCE_CARD';
export const ADD_RESOURCE_CARD_PERDAY  = 'ADD_RESOURCE_CARD_PERDAY';
export const SET_CURRENT_DAY = 'SET_CURRENT_DAY';
export const GET_ONLINE_EVENTS = 'GET_ONLINE_EVENTS';
export const GET_PAST_ONLINE_EVENTS = 'GET_PAST_ONLINE_EVENTS';
export const GET_ONLINE_EVENT = 'GET_ONLINE_EVENT';
export const GET_MY_ONLINE_EVENTS = 'GET_MY_ONLINE_EVENTS';
export const GET_ONLINE_EVENTS_BY_HOSTID = 'GET_ONLINE_EVENTS_BY_HOSTID';
export const ADD_ONLINE_EVENT = 'ADD_ONLINE_EVENT';
export const UPDATE_ONLINE_EVENT ='UPDATE_ONLINE_EVENT';
export const ONLINE_EVENT_ERROR = 'ONLINE_EVENT_ERROR';
export const BOOK_ONLINE_EVENT = 'BOOK_ONLINE_EVENT';
export const UPDATE_ONLINE_EVENT_BOOKING_COUNT = 'UPDATE_ONLINE_EVENT_BOOKING_COUNT';
export const BOOK_ONLINE_EVENT_ERROR = 'BOOK_ONLINE_EVENT_ERROR';
export const GET_STATUS_CODE = 'GET_STATUS_CODE';
export const GET_ONLINE_BOOKINGS_BY_EVENTID = 'GET_ONLINE_BOOKINGS_BY_EVENTID';
export const ONLINE_BOOKING_ERROR = 'ONLINE_BOOKING_ERROR';
export const GET_FAMILY = 'GET_FAMILY';
export const PUT_FAMILY = 'PUT_FAMILY';
export const DELETE_FAMILY = 'DELETE_FAMILY';
export const FAMILY_ERROR = 'FAMILY_ERROR';
export const CLEAR_FAMILY = 'CLEAR_FAMILY';
export const GET_EVENTS = 'GET_EVENTS';
export const GET_EVENT = 'GET_EVENT';
export const GET_EVENTS_WITHIN_RADIUS = 'GET_EVENTS_WITHIN_RADIUS';
export const GET_EVENTS_WITHIN_MAPBOUNDS = 'GET_EVENTS_WITHIN_MAPBOUNDS';
export const ADD_EVENT = 'ADD_EVENT';
export const UPDATE_EVENT = 'UPDATE_EVENT';
export const DELETE_EVENT = 'DELETE_EVENT';
export const EVENT_ERROR = 'EVENT_ERROR';
export const ADMIN_ERROR = 'ADMIN_ERROR';
export const GET_ADMIN_LEVEL_USERDATA = 'GET_ADMIN_LEVEL_USERDATA';
export const GET_MEETUP = 'GET_MEETUP';
export const MEETUP_ERROR = 'MEETUP_ERROR';
export const UPDATE_USER_FOLLOWS = 'UPDATE_USER_FOLLOWS';
export const UPDATE_USER_FOLLOWERS = 'UPDATE_USER_FOLLOWERS';
export const REMOVE_USER_REG  = 'REMOVE_USER_REG ';