import {
  GET_EVENTS,
  GET_EVENT,
  GET_EVENTS_WITHIN_RADIUS,
  GET_EVENTS_WITHIN_MAPBOUNDS,
  ADD_EVENT,
  UPDATE_EVENT,
  DELETE_EVENT,
  EVENT_ERROR,
  REMOVE_USER_REG
} from '../actions/types';

const initialState = {
  events: [],
  pastEvents: [],
  myEvents: [],
  event: null,
  eventLoading: true,
  error: {}
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_EVENTS:
    case GET_EVENTS_WITHIN_RADIUS:
    case GET_EVENTS_WITHIN_MAPBOUNDS:
      return {
        ...state,
        events: payload,
        eventLoading: false
      };
    case GET_EVENT:
      return {
        ...state,
        event: payload,
        eventLoading: false
      };
    case ADD_EVENT:  
    case UPDATE_EVENT:
      return {
        ...state,
        events: [payload, ...state.events],
        eventLoading: false
      };
    case DELETE_EVENT:
      return {
        ...state,
        events: state.events.filter(event => event._id !== payload),
        pastEvents: state.events.filter(event => event._id !== payload),
        myEvents: state.events.filter(event => event._id !== payload),
        eventLoading: false
      };
    case REMOVE_USER_REG:
      return {
        ...state,
        event: {...state.event, userBookings: payload},
        eventLoading: false
      };
    case EVENT_ERROR:
      return {
        ...state,
        error: payload,
        eventLoading: false
      };
    default:
      return state;
  }
}
