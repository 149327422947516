import React, { useState, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import LoginPopup from '../auth/LoginPopup';
import RegisterPopup from '../auth/RegisterPopup';
import CreateProfilePreGeolocate from '../profile-forms/CreateProfilePreGeolocate';
import { Modal, ModalBody } from 'reactstrap';
import { setAlert } from '../../actions/alert';
import checkRed from '../../images/check-red_640-640.svg';

const VirtualParentMeetups = ({ setAlert, auth }) => {
    
    const history = useHistory();

    const [ loginModal, toggleLoginModal ] = useState(false);
    const [ registerModal, toggleRegisterModal ] = useState(false);
    const swapLoginRegister = () => {
        toggleLoginModal(!loginModal);
        toggleRegisterModal(!registerModal);
    };
    const [ createProfileModal, toggleCreateProfileModal ] = useState(false);

    //Create search function in FAQs
    // function searchStringInArray (str, strArray) {
    //     for (var j=0; j<strArray.length; j++) {
    //         if (strArray[j].match(str)) return j;
    //     }
    //     return -1;
    // }

    const data = {
        heading: "Virtual Parent Meetups",
        headingText: "Want to meet, ask questions, and collaborate with other local homeschooling parents? It's a chance to learn what others are doing, ask about things you're looking for, etc!",
        headingTexttwo: "Want to meet, ask questions, and collaborate with other local homeschooling parents?",
        faqText: "Have more questions?",
        faqTextLink: "Tell us what you're looking for."
    };

    const [copySuccess, setCopySuccess] = useState(false);

    const copyUrl = async () => {
        try {
            await navigator.clipboard.writeText(`${window.location.href}`);
            setCopySuccess(true);
            setTimeout(()=> {
                setCopySuccess(false);
            },1500);
        } catch (err) {
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: 'smooth'
            });
            setAlert('Error copying to clipboard, sorry :(', 'danger');
        }
    };

    const faqData = [
        {
            "id": 1,
            "title": "How does it work?",
            "content": "You just need to register, and create a profile to be eligible. (Make sure to check the box that says \"I'm interested in virtual parent meetups\".)  When enough parents in your local area sign up, we email everyone to see if they want to attend a virtual local meetup."
        },
        {
            "id": 2,
            "title": "Where do I sign up?",
            "content": "Just use the \"Register\" and \"Create a Profile\" buttons above.",
        },
        {
            "id": 3,
            "title": "How much does it cost?",
            "content": "It's free. We are a network of homeschoolers, so our goal is to connect homeschoolers, help them learn from each other, and learn ourselves too!"
        },
        {
            "id": 4,
            "title": "When do you host meetups?",
            "content": "We schedule them once we get a group of parents signed up in a local area.  We send out a little survey asking what date/time is best for those involved.  Whatever time slot gets the most votes is the time we host it!"
        }
    ];

    const faqDataTwo = [
        {
            "id": 1,
            "title": "Can I expedite a local meetup?",
            "content": "Absolutely! Just copy the link by clicking the button below, and post it to your local FB group, Listserv, or wherever you interact online with other homeschoolers.",
            "button": true
        },
        {
            "id": 2,
            "title": "So, how does MySchool make money?",
            "content": "We help our members offer classes, meetups, and other learning experiences to each other. When someone offers an event, you can book it right through MySchool.  If it's an event you have to pay for or you decided to donate to the host, we take a portion of the booking cost to help us maintain the site."
        },
        {
            "id": 3,
            "title": "Do I have to give out my address when creating my profile?",
            "content": "Nope. You have to put in an address to get a pin dropped in your general area. During your profile setup, you can drag the pin anywhere you want so that it's near your home, but not at a point you'd feel uncomfortable.  We NEVER store our profiles' addresses."
        },
        {
            "id": 4,
            "title": "Why not do this in person?",
            "content": "Think of this as a starting point for in-person meetups.  It's easy, quick, gets you introduced to other families, and you can connect with them later on."
        }
    ];

    const loggedInAlert = () => {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
        setAlert('You\'re already logged in! Just create a profile, and you\'re all set!','success');
    }

    const registerLoginButton = (
        <Fragment>
            <button className='btn btn-myschool-red btn-150-circle mx-4' 
                onClick={
                    !auth.isAuthenticated ?
                    ()=>toggleRegisterModal(!registerModal)
                    :
                    ()=>loggedInAlert()
                }
            >
            {!auth.isAuthenticated ? 
                'Register': 'Register - DONE!'
            }
            </button>
        </Fragment>
    );

    const createProfile = (
        <Fragment>
            <button className='btn btn-myschool-blue btn-150-circle mx-4' 
                onClick={
                    !auth.isAuthenticated ?
                    ()=>toggleLoginModal(!loginModal)
                    :
                    ()=>toggleCreateProfileModal(!createProfileModal)
                }
            >
            {auth && auth.user && auth.user.profile ? 
                'Update your Profile'
                :
                'Create a Profile'}
            </button>
        </Fragment>
    );

    return (
        <Fragment>
            <section className="section faq-area style-two ptb_100">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-10 col-lg-7">
                            {/* Section Heading */}
                            <div className="section-heading text-center">
                                <h2 className="text-capitalize">{data.heading}</h2>
                                <p className="d-none d-sm-block mt-4">{data.headingText}</p>
                                <p className="d-block d-sm-none mt-4">{data.headingTexttwo}</p>
                            </div>
                        </div>
                    </div>

                    <div className='row justify-content-center'>
                        <div className="section-heading text-center">
                            {registerLoginButton}{createProfile}
                        </div>
                    </div>

                    <div className='row justify-content-center mt-4'>
                        <div className="section-heading text-center">
                            <h2 className="text-capitalize">{data.faqText}</h2>
                        </div>
                    </div>

                    <div className="row justify-content-center">
                        <div className="col-12">
                            {/* FAQ Content */}
                            <div className="faq-content">
                                {/* sApp Accordion */}
                                <div className="accordion">
                                    <div className="row justify-content-center">
                                        <div className="col-12 col-md-6">
                                        {/* Single Card */}
                                        {faqData.map((item, idx) => {
                                            return(
                                                <div key={`fo_${idx}`} className="card border-0">
                                                    {/* Card Header */}
                                                    <div className="card-header bg-inherit border-0 p-0">
                                                    <h2 className="mb-0">
                                                        <button className="btn btn-myschool-blue text-center px-1 py-2" type="button">
                                                            {item.title}
                                                        </button>
                                                    </h2>
                                                    </div>
                                                    {/* Card Body */}
                                                    <div className="card-body px-0 py-3">
                                                        {item.content}
                                                    </div>
                                                </div>
                                            );
                                        })}
                                        </div>
                                        <div className="col-12 col-md-6">
                                        {/* Single Card */}
                                        {faqDataTwo.map((item, idx) => {
                                            return(
                                                <div key={`ft_${idx}`} className="card border-0">
                                                    {/* Card Header */}
                                                    <div className="card-header bg-inherit border-0 p-0">
                                                    <h2 className="mb-0">
                                                        <button className="btn btn-myschool-blue text-center px-1 py-2" type="button">
                                                            {item.title}
                                                        </button>
                                                    </h2>
                                                    </div>
                                                    {/* Card Body */}
                                                    <div className="card-body px-0 py-3">
                                                        {item.content}
                                                        {item.button ?
                                                            <div className='text-center'>
                                                                <button className={copySuccess ? `btn btn-success`:`btn btn-myschool-red`} onClick={()=>copyUrl()}>
                                                                {copySuccess ? 'COPIED!':'Copy URL'}
                                                                </button>
                                                            </div>
                                                            :''
                                                        }
                                                    </div>
                                                </div>
                                            );
                                        })}
                                        </div>
                                    </div>
                                    <div className="row justify-content-center">
                                        <p className="text-body text-center pt-4 fw-5">{data.faqText} <a href="/#">{data.faqTextLink}</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Modal isOpen={loginModal} centered toggle={() => toggleLoginModal(!loginModal)}>
              <ModalBody className='text-center'>
                <div className='float-right'>
                  <button className="basic-button" 
                    onClick={() => toggleLoginModal(false)}>
                    <i className='fa fa-times color-light-grey'/>
                  </button>
                </div>
                You have to register and login to do that!
                <LoginPopup loginModal={loginModal} toggleLoginModal={toggleLoginModal}/>
                <button onClick={()=>swapLoginRegister()} className='basic-button text-bold text-underlined'>I need to Register</button>
              </ModalBody>
            </Modal>

            <Modal isOpen={registerModal} centered toggle={() => toggleRegisterModal(!registerModal)}>
              <ModalBody className='text-center'>
                <div className='float-right'>
                  <button className="basic-button" 
                    onClick={() => toggleRegisterModal(false)}>
                    <i className='fa fa-times color-light-grey'/>
                  </button>
                </div>
                <RegisterPopup registerModal={registerModal} toggleRegisterModal={toggleRegisterModal} history={history}/>
                <button onClick={()=>swapLoginRegister()} className='basic-button text-bold text-underlined'>Go to Login</button>
              </ModalBody>
            </Modal>

            <Modal isOpen={createProfileModal} centered toggle={() => toggleCreateProfileModal(!createProfileModal)}>
                <ModalBody className='text-center'>
                  <div className='float-right'>
                    <button className="basic-button" onClick={() => toggleCreateProfileModal(!createProfileModal)}>
                    <i className='fa fa-times color-light-grey'/>
                    </button>
                  </div>
                  <CreateProfilePreGeolocate/>
                </ModalBody>
            </Modal>

        </Fragment>
    );
};

VirtualParentMeetups.propTypes = {
    auth: PropTypes.object,
    setAlert: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(  mapStateToProps, { setAlert })(VirtualParentMeetups);