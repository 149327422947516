import React, { useState, useEffect, Fragment } from 'react';
import { Link } from 'react-router-dom';
import {loadStripe} from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutEventPayment from './CheckoutEventPayment';
import SpinnerComponent from '../layout/Spinner';

 //set appropriate stripe publishable api key as per environment
let stripePublishableKey;
if (process.env.NODE_ENV === 'production') {
  stripePublishableKey = 'pk_live_FHNDDvhgvHgEyV7P5ZgoTMXN00rCtnOVyn';
} else {
  stripePublishableKey = 'pk_test_yQJvwwUlfBm2IlgmcUgRgjEp00FMG97sdP';
}
let stripePromise;


const CheckoutEventPaymentWrapper = ({ stripeConnectedAccount, eventId, checkoutFormData, savedChildFormData, savedParentFormData, userId, history }) => {
  const [stripeLoaded, setStripeLoaded] = useState(false);

  useEffect(() => {
    const createStripeKey = async () => {
      const setConnectedAccount = () => {
        if (stripeConnectedAccount) {
          stripePromise = loadStripe(stripePublishableKey, {stripeAccount: stripeConnectedAccount});
        } else {
          stripePromise = loadStripe(stripePublishableKey, {stripeAccount: stripeConnectedAccount});
        }
      }
      await setConnectedAccount();
      setStripeLoaded(true);
    };
    
    createStripeKey();
  },[]);

  return !stripeLoaded ? (
    <SpinnerComponent />
    ) : (
    <Fragment>
      <Elements stripe={stripePromise}>
        <CheckoutEventPayment eventId={eventId} checkoutFormData={checkoutFormData} savedChildFormData={savedChildFormData} savedParentFormData={savedParentFormData} history={history} userId={userId} />
      </Elements>
    </Fragment>
  );
};


export default CheckoutEventPaymentWrapper;