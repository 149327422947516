import React, { Fragment, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Spinner, Badge } from 'reactstrap';
import api from '../../utils/api';

const ProfileStripeConnectStatusBadge = ({ myProfile, setStripeConnected }) => {

  const history = useHistory();
  const [ accountLoaded, setAccountLoaded] = useState(false);
  const [ accountDetails, setAccountDetails] = useState({});

  useEffect(()=> {
    const getStripeAccount = async () => {
      try {
        const stripeAccount = await api.get('/v1/stripeConnect/stripe-connect-account/profile');
        // console.log('stripeAccount Details: ',stripeAccount);
        setAccountDetails(stripeAccount.data);
        setAccountLoaded(true);
        if (stripeAccount.data.details_submitted && stripeAccount.data.charges_enabled) {
          setStripeConnected(true);
        }
      } catch (err) {
        setAccountLoaded(true);
      } 
    };
    if (myProfile && myProfile.stripeAccount) {
      getStripeAccount();
    } else {
      setAccountLoaded(true);
    };
  }, [myProfile]);

  return (
    <Fragment>
      {accountLoaded && accountDetails && accountDetails.details_submitted && accountDetails.charges_enabled ?
        <Badge color="success" pill>Stripe account connected!</Badge>
        :
        accountLoaded && accountDetails && accountDetails.details_submitted && !accountDetails.charges_enabled ?
          <Badge color="warning" pill>In process, pending verification</Badge>
        : accountLoaded && (!accountDetails || !accountDetails.details_submitted || !accountDetails.charges_enabled) ?
          <Badge color="danger" pill>Not connected</Badge>
        :
        <Spinner size="sm" className='color-myschool-blue'/>
      }
    </Fragment>

  );
};

export default ProfileStripeConnectStatusBadge;

