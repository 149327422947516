import React, { Fragment, useState, useEffect } from "react";
import { useHistory, Redirect, useParams } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { Container, FormGroup, Input, Label, Modal, ModalHeader, ModalBody } from 'reactstrap';
import api from '../../../utils/api';
import SpinnerComponent from '../../layout/Spinner';
import { setAlert } from '../../../actions/alert';
import { getAllChild } from '../../../actions/child';
import CreateChild from '../../child/CreateChild';
import AuthModals from '../../auth/AuthModals'; 
import checkBlue from '../../../images/check-blue_640-640.svg';
import AddChild from '../../child/AddChild';
import ImageContainer from '../../layout/ImageContainer';
import QRScan from '../../../images/QR_scan_to_portfolio.gif';
import ImageUpload from '../../../utils/ImageUpload';

const AuthorityToPortfolio = ({ auth: {loading, user, isAuthenticated}, setAlert, children }) => {

  const history = useHistory();
  const params = useParams();
  // console.log(children);

  const [ showLearnMore, toggleShowLearnMore ] = useState(false);
  const openRegisterModal = () => {
    toggleShowLearnMore(false);
    toggleRegisterModal(true);
  }

  //Must login/register
  const [ loginModal, toggleLoginModal ] = useState(false);
  const [ registerModal, toggleRegisterModal ] = useState(false);

  const [ authoritySuccess, setAuthoritySuccess ] = useState(false);
  const [ authorityFailure, setAuthorityFailure] = useState(false);

  const [authorityInfo, setAuthorityInfo] = useState({});
  // const [childInfo, setChildInfo] = useState([]);
  const [ selChild, setSelChild ] = useState([]);
  const [ childrenAdded, setChildrenAdded] = useState({status:false});

  //Add image
  const [file, setFile] = useState('');
  const [fileSize, setFileSize] = useState(0);
  const [storedThumbnail, setStoredThumbnail] = useState('');

  const [ isSubmitting, setIsSubmitting ] = useState(false);

  const checkAuthority = async () => {
    setAuthoritySuccess(false);
    setAuthorityFailure(false);
    try {
      const authorityLookup = await api.get(`/v1/portfolio/myschoolplus/authority/${params.authorityId}`);
      // console.log(authorityLookup.data);
      setAuthorityInfo(authorityLookup.data.data);
      setAuthoritySuccess(true);
    } catch (err) {
      console.log(err);
      setAlert('Error.  Please try again later.', 'danger');
      setAuthorityFailure(true);
    } 
  };

  useEffect(()=> { 
    checkAuthority();
  }, []);

  useEffect(()=> { 
    if (isAuthenticated) {
      getAllChild();
    }
  }, [isAuthenticated]);

  const onChange =(e)=> {
    // check if the check box is checked or unchecked
    if (selChild.includes(e.target.name) === false) {
      setSelChild([...selChild, e.target.name]);
    } else {
      const newArray = selChild.filter(el=> el !== e.target.name);
      setSelChild(newArray);
    }
  }

  const [childNames, setChildNames] = useState([]);
  const submitEntries = async () => {
    setIsSubmitting(true);
    const data = {
      children: selChild,
      authority: authorityInfo
    };
    if (authorityInfo.defaults && authorityInfo.defaults.time) {data.duration = authorityInfo.defaults.time*1} else {data.duration = 120};
    if (authorityInfo.defaults && authorityInfo.defaults.description) {data.description = authorityInfo.defaults.description} else {data.description = `Visit to ${authorityInfo.name}`};
    if (authorityInfo.defaults && authorityInfo.defaults.subjects) {
      data.subjects = authorityInfo.defaults.subjects;
    } else if (authorityInfo.provider && authorityInfo.provider.subjects && authorityInfo.provider.subjects.length > 0 ) {
      data.subjects = authorityInfo.provider.subjects;
    } else if (authorityInfo.resource && authorityInfo.resource.subjects && authorityInfo.resource.subjects.length > 0 ) {
      data.subjects = authorityInfo.resource.subjects;
    }

    const allData1 = { ...data, image: file };
    const allData = Object.keys(allData1).reduce((f, k) => {
      f.append(k, allData1[k]);
      return f;
    }, new FormData());

    const configMulti = {headers: {'Content-Type': 'multipart/form-data'}};
    const addedChildren = await api.post(`/v1/portfolio/myschoolplus/entry`,allData, configMulti);
    
    setChildrenAdded({status:true, count: addedChildren.data.count});
    setIsSubmitting(false);
  };
  
  const [showAddChild, setShowAddChild] = useState(false);

  // //Redirect if logged in and user loaded
  // if(isAuthenticated && user && user._id) {
  //   return <Redirect to='/dashboard' />;
  // }

  return (
    <div>
    {
      isAuthenticated && !authoritySuccess && !authorityFailure ? (
        <SpinnerComponent />
      ) : isAuthenticated && authoritySuccess && !childrenAdded.status ? (
        <Container className='text-center'>
          <h2>
            {authorityInfo && authorityInfo.name ? `Welcome to ${authorityInfo.name}!`:`Welcome!`}
          </h2>
          <div className='msstd-form-primary-border my-3'>
            <h4 className='mb-2'>Who is joining you?</h4>
            {children && children.length > 0 ? 
              <div> 
                <div><button type='button' onClick={()=>setShowAddChild(true)} className='btn btn-myschool-blue-border'>+ Add Child</button></div>
                <div>
                  { children.map(c=> 
                      <Label check key={c._id} className='child-input'>
                        <Input type="checkbox" name={c._id} checked={selChild.includes(c._id)} onChange={e => onChange(e)}/>
                        {`${c.name}`}
                      </Label>
                    )
                  }
                </div>
                <div className="text-center">
                  <ImageUpload 
                    file={file} 
                    setFile={setFile} 
                    fileSize={fileSize} 
                    setFileSize={setFileSize} 
                    storeThumb={true} 
                    storedThumbnail={storedThumbnail} 
                    setStoredThumbnail={setStoredThumbnail}
                    showFileSize={true}
                    maxFileSize={5000000}
                    isProfileImage={false}/>
                </div>
                <button 
                  type='button' 
                  onClick={()=>submitEntries()} 
                  className={isSubmitting ? 'btn btn-myschool-blue my-2 blinker': 'btn btn-myschool-blue my-2'} 
                  disabled={selChild.length < 1 || isSubmitting }>
                  { isSubmitting ? `Submitting...`:`Submit`}
                </button>
                {selChild < 1 ? 
                  <div className='color-danger'>
                    Please select a child
                  </div>
                  :''}
              </div>
              : <div>
                  <div>
                    <div>
                    <CreateChild showChildTable={false}/>
                  </div>
                  </div>
                  <div>
                    No child info available
                  </div>
                </div>
            }
            <Modal isOpen={showAddChild} centered toggle={() => setShowAddChild(!showAddChild)}>
              <ModalHeader className='content-justify-center'>
                <div className='float-right'>
                  <button className="basic-button" onClick={() => setShowAddChild(!showAddChild)}>
                  <i className='fa fa-times color-light-grey'/>
                  </button>
                </div>
                Add a child
              </ModalHeader>
              <ModalBody className='text-center'>
                <AddChild />
              </ModalBody>
              <button className='btn btn-modal-blue content-justify-right' onClick={()=>setShowAddChild(false)}>Skip / Done</button>
            </Modal>
          </div>
        </Container>
      )
      : isAuthenticated && authoritySuccess && childrenAdded.status ? (
          <div className='text-center'>
            <div>
              <img src={checkBlue} width='70px' alt='check step 1' className='fadeIn-slow'/>
            </div>
            <h4>
              { childrenAdded.count > 1 ? 
                `Success! Portfolio entries added for ${childrenAdded.count} children.`
                : `Success! Portfolio entry added for ${childrenAdded.count} child.`
              }
            </h4>
            <div className='my-3'>
              <button type="button" onClick={()=>history.push("/dashboard")} className='basic-button'>
              Go to your dashboard to visit the portfolios >>
              </button>
            </div>
            <div>(You may now close this tab.)</div>
          </div>
        )
      : isAuthenticated && authorityFailure ? (
        <Container className='text-center'>
          <h2>
            Sorry, but we can't figure out where you are!
          </h2>
          <div> 
            <button type='button' onClick={()=>checkAuthority()} className='btn btn-myschool-red'>Try Again</button>
          </div>
        </Container>
      ) : !isAuthenticated ? (
        <Container  className='text-center'>
          <h2>
            {authorityInfo && authorityInfo.name ? `Welcome to ${authorityInfo.name}!`:`Welcome!`}
          </h2>
          <div className="mt-4 mb-2">
            <button className='btn btn-myschool-blue' type='button' onClick={()=>toggleLoginModal(true)}>Login or sign up to save your visit!</button>
          </div>
          <div className="mb-4">
            <button className='btn btn-myschool-red' type='button' onClick={()=>toggleShowLearnMore(true)}>Learn more</button>
          </div>
        </Container>
      ) : (
        <Container className='text-center'>
          <h2>Error: Please try again later.</h2>
        </Container>
      )
    }
      <div>
        <AuthModals loginModal={loginModal} toggleLoginModal={toggleLoginModal} registerModal={registerModal} toggleRegisterModal={toggleRegisterModal} />
      </div>

      <Modal centered isOpen={showLearnMore} toggle={() => toggleShowLearnMore(!showLearnMore)}>
        <ModalBody>
          <div className='d-flex justify-content-end'>
            <button className="basic-button" onClick={() => toggleShowLearnMore(false)}>
                <i className='fa fa-times color-light-grey'/>
            </button>
          </div>
          <div className="text-center">
            <div className="text-bold text-1-1rem mb-3">
                Keep learning with your kids all summer long!
            </div>
            <div>
              <div>
                <ImageContainer
                  src={QRScan}
                  thumb={QRScan}
                  className='d-inline' 
                  alt='map snippet with personal profile shown'
                />
              </div>
              <div className="my-1">Scan a MySchool QR code to track your visits!</div>
              <div>
                <button className='btn btn-modal-blue' type='button' onClick={()=>openRegisterModal()}>Log this trip!</button>
              </div>
            </div>
          </div>
        </ModalBody>
    </Modal>
    </div>
  )
};

AuthorityToPortfolio.propTypes = {
  auth: PropTypes.object.isRequired,
  setAlert: PropTypes.func.isRequired,
  getAllChild: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  children: state.child.children,
});


export default connect(mapStateToProps, { setAlert, getAllChild })(AuthorityToPortfolio);
