import React, { useEffect, Fragment, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { Container, Card, CardBody, CardHeader, Button, Form, FormGroup, Input, Modal, ModalHeader, ModalBody, Row, Col, ButtonDropdown, DropdownMenu, DropdownToggle, Label } from 'reactstrap';
import PropTypes from 'prop-types';
import Spinner from '../layout/Spinner';
// import PrintPortfolioPreferences from './PrintPortfolioPreferences';
import PortfolioSummary from './PortfolioSummary';
import PortfolioMap from './PortfolioMap';
import PortfolioItem from './PortfolioItem';
import MyChildSchoolResources from './portfolioEntryTools/MyChildSchoolResources';
import { getChildPortfolio } from '../../actions/portfolio';
import capitalizeName from '../../utils/capitalizeName';
//Map Setup
import { Map } from 'react-leaflet';
import L from 'leaflet';
import dayjs from 'dayjs';

const Portfolio = ({ auth, getChildPortfolio, portfolio: { portfolio, portfolioStats, portfolioLoading }, child: {child} }) => {

  const history = useHistory();
  const params = useParams();

  //displayed Data after FE filters
  const [portfolioDisplay, setPortfolioDisplay] = useState([]);

  const [displayModalIntro, toggleModalIntro] = useState(false);
  const [displayModalChildName, toggleModalChildName] = useState(false);
  const [displayModalPreferences, toggleModalPreferences] = useState(false);
  
  //Views
  const [ display, setDisplay ] = useState({
    summary: false,
    list: true,
    map: false,
    "add entry": false
  });

  const resetDisplay = { 
    summary: false,
    list: false,
    map: false,
    "add entry": false
  };

  const [queryData, setQueryData] = useState({
    startDate: '2022-09-01'
  });
  const { startDate } = queryData;

  const changeDisplay = (activeDisplay) => {
    // console.log('activeDisplay',activeDisplay);
    setDisplay({...resetDisplay, [activeDisplay]: true});
  };

  useEffect(() => {
    if (auth.isAuthenticated) {
      getChildPortfolio(params.childId,queryData); 
    }
  }, [params, auth.isAuthenticated]);

  const refreshResults = () => {
    getChildPortfolio(params.childId,queryData); 
  }

  const [showSubjFilter, toggleShowSubjFilter] = useState(false);
  const allSubjects = ["math","english","science","social studies","art","music","physical education","health","history","foreign language","engineering","environmental science"];
  const [ filterSubjData, setFilterSubjData ] = useState([...allSubjects]);

  const onSubjChange = (subj, preset) => {
    //updateFilterDropdown
    let newFilters;
    if (preset === "all") {
        newFilters = [...allSubjects];
      }
      else if (preset === "none") {
        newFilters = [];
      }
      else if (preset === null && filterSubjData.includes(subj)){
        newFilters = filterSubjData.filter(s=>s !== subj);
      } else {
        newFilters = [...filterSubjData,subj];
      }
    setFilterSubjData(newFilters);
    //update displayed portfolio data with new filters 
    const displayedData = portfolio.filter(entry=> entry.subjects.some(s=>newFilters.includes(s)));
    setPortfolioDisplay(displayedData);
  }

  const subjFilterDropdownButton = (
    <ButtonDropdown group={false} direction="down" isOpen={showSubjFilter} toggle={() => toggleShowSubjFilter(!showSubjFilter)}>
      <DropdownToggle
        className='basic-button text-075rem'
        style={{backgroundColor: "#fff", color: "#565656", fontWeight: 400, padding:"5px", borderStyle: "solid", borderColor: "#000", borderWidth: "1.8px"}}
      >
          Subj. 
        {' '}<i className="fas fa-caret-down"/>
      </DropdownToggle>
      <DropdownMenu className='homeschool-map-filter-dd' style={{zIndex:1001}}>
        <div className='m-2'>
          <Form className='form' >
            <FormGroup check className='my-2'>
              {allSubjects.map(subj => 
                  <Label check key={subj} className='mx-1 homeschool-map-filter-input'>
                    <Input type="checkbox" 
                      name={subj} 
                      checked={filterSubjData.includes(subj)} 
                      onChange={e => onSubjChange(e.target.name, null)}/>
                    {` ${capitalizeName(subj)}`}
                  </Label>
                )
              }
            </FormGroup>
            <button type="button" className='basic-button text-075rem mx-1' onClick={()=>onSubjChange(null,"all")}>Select All</button>
            <button type="button" className='basic-button text-075rem mx-1' onClick={()=>onSubjChange(null,"none")}>Clear</button>
          </Form>
        </div>
      </DropdownMenu>
    </ButtonDropdown>
  );

  const headingsCard = (
    <Card>               
      <CardBody>
        <Row>
          <Col xs={2} className='text-center text-075rem px-0'>
            <div>Date</div>
          </Col>
          <Col xs={2} className='text-center text-075rem px-0'>
            <div>
              {subjFilterDropdownButton}
            </div>
          </Col>
          <Col xs={2} className='text-center text-075rem px-0'>
            <div>
              Duration
            </div>
          </Col>
          <Col xs={4} className='text-075rem px-1'>
            <div> Description </div>
          </Col>
          <Col xs={2} className='text-center text-075rem px-0'>
            <div> Actions </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );

  //MAP Setup
  const corner1 = L.latLng(90,180);
  const corner2 = L.latLng(-90,-180);
  const maxBounds = L.latLngBounds(corner1, corner2);
  let APIKey;
  if (process.env.NODE_ENV === 'production') {
    APIKey = process.env.REACT_APP_MAPTILER_API_KEY
  } else {
    APIKey = process.env.REACT_APP_MAPTILER_API_TEST_KEY
  }
  const [userLatLong, setUserLatLong] = useState([39.2542066,-76.6971416]);
  const [currentZoom, setCurrentZoom] = useState(10);
  useEffect(()=> {
    if(auth.user && auth.user.profile && auth.user.profile.location && auth.user.profile.location.coordinates){
      setUserLatLong([auth.user.profile.location.coordinates[1], auth.user.profile.location.coordinates[0]]);
    }
  },[auth.user]);

  useEffect(()=> {
    setPortfolioDisplay(portfolio);
    setFilterSubjData([...allSubjects]);
  },[portfolio]);

  return !portfolio || portfolioLoading ? (
    <Spinner />
    ) : (
      <div>
        <Container>
          <span><h1 className='text-primary-ms text-center'>{child && child.name ? `${child.name}'s `:''}Portfolio</h1></span>
          <div className="d-flex justify-content-center msstd-border-tb">
            <ul className="nav">
                {Object.keys(display).map((dis,i)=> {
                  return (
                    <li className="nav-item mx-2" key={i}>
                      <button type='button' className={`nav-link btn ${display[dis] ? 'btn-myschool-blue':'btn-myschool-blue-border'}`} onClick={()=>changeDisplay(dis)}>
                        {capitalizeName(dis)}
                      </button>
                    </li>
                    )
                })}              
            </ul>
            
          </div>

          <div className='text-center my-3'>
            Showing results after{' '}
            <input type="date" className="text-underlined" name='startDate' value={startDate} onChange={(e)=>setQueryData({...queryData, [e.target.name]:e.target.value})}/>
            <button onClick={()=>refreshResults()} className="btn btn-myschool-blue">Update Results</button>
            <div>
              Showing{` ${portfolioDisplay.length} `}entries.
            </div>
          </div>

          { auth.user && !auth.user.subscriberStatusActive ? 
            <div className='text-center my-3'>
              <h4 className='color-myschool-blue my-3'>
                You aren't currently a MySchool+ Member!  Find out more by clicking the button below! 
              </h4>
              <div className='mx-5'>
                You can access your portfolio throughout the Summer Scavenger Hunt from June 1 - Aug 31, 2022.  Sign up for MySchool+ and you can use it (plus all the other benefits) anytime!
              </div>
              <div>
                <button onClick={()=>history.push('/myschoolplus')} className='btn btn-myschool-blue'>
                  Find out more!
                </button>
              </div>
            </div>
            :
            ''
          }

          { display["add entry"] ?
            <div>
              <MyChildSchoolResources child={child}/>
            </div>  
            :''
          }

          { display.summary &&
            <div className='my-5'>
              <PortfolioSummary portfolioStats={portfolioStats} portfolio={portfolio}/>
            </div>
          }

          { display.list &&
            <div className='my-5'>
              <div>{headingsCard}</div>
              {portfolioDisplay.map((entry, i)=> {
                  return <PortfolioItem key={entry._id} portfolioEntry={entry} />
                })
              }
            </div>
          }

          { display.map &&
            <div className='my-5'>
              <Map className="homeschoolmap-map" center={userLatLong} zoom={currentZoom} maxBounds={maxBounds}>
                <PortfolioMap 
                  portfolio={portfolio}
                  userLatLong={userLatLong}
                  auth={auth}
                  APIKey={APIKey}
                  history={history}
                />
              </Map>
            </div>
          }
          

          {/*...CURRENTLY UNDER CONSTRUCTION  */}
          {/*<Modal isOpen={displayModalPreferences} toggle={() => toggleModalPreferences(!displayModalPreferences)}>
            <PrintPortfolioPreferences childrenNames={portfolio.childrenNames} />
          </Modal>*/}
          {/*<PortfolioFilters portfolio={portfolio} />*/}
          
          {/*Card style column labels */}
        </Container>
      </div>
    );
};

Portfolio.propTypes = {
  auth: PropTypes.object.isRequired,
  portfolio: PropTypes.object.isRequired,
  child: PropTypes.object.isRequired,
  getChildPortfolio: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  portfolio: state.portfolio,
  child: state.child
});

export default connect(mapStateToProps, { getChildPortfolio })(Portfolio);


