import {
  GET_ONLINE_BOOKINGS_BY_EVENTID,
  ONLINE_BOOKING_ERROR
} from '../actions/types';

const initialState = {
  onlineBookings: [],
  onlineBookingloading: true,
  error: {}
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_ONLINE_BOOKINGS_BY_EVENTID:
      return {
        ...state,
        onlineBookings: payload,
        onlineBookingloading: false
      };
    case ONLINE_BOOKING_ERROR:
      return {
        ...state,
        error: payload,
        onlineBookingloading: false
      };
    default:
      return state;
  }
}
