import React, { useEffect } from "react";
import ReactGA from "react-ga";

let tracker;

if (process.env.NODE_ENV === 'development') {
  tracker = "UA-151475683-2";
  console.log('DEV MODE');
} else {
  // console.log('PRODUCTION MODE');
  tracker = "UA-151475683-1";
}

ReactGA.initialize(tracker);

const withTracker = (WrappedComponent, options = {}) => {
  const trackPage = page => {
    ReactGA.set({
      page,
      ...options
    });
    ReactGA.pageview(page);
  };

  const HOC = props => {
    useEffect(() => trackPage(props.location.pathname), [
      props.location.pathname
    ]);

    return <WrappedComponent {...props} />;
  };

  return HOC;
};

export default withTracker;

// export const trackUser = userId => ReactGA.set({ userId });

// export const trackEvent = (category, action, label) =>
//   ReactGA.event({ category, action, label });

 // let gaClientId = isAuthenticated ? trackUser('auth.user._id') : '';
