import React, { Fragment, useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { editUser } from '../../actions/auth';
import SpinnerComponent from '../layout/Spinner'; 
import { Form, Container, Row, Col, Label, Input, CustomInput, Spinner  } from 'reactstrap';

const EditUser = ({ auth: { user, loading }, editUser, history }) => {
  
  const [formData, setFormData] = useState({
    username: '',
    indivMailingListCheck: true,
    fullname:''
  });

  const [ isSubmitting, setIsSubmitting ] = useState(false);

  useEffect(() => {
    setFormData({
      username: loading || !user.username ? '' : user.username,
      indivMailingListCheck: loading ? true : user.indivMailingListCheck,
      fullname: loading || !user.fullname ? '' : user.fullname
    });
  }, [loading, user]);

  const { username, indivMailingListCheck, fullname } = formData;

  const onChange = e =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onCheckedChange = e => 
    setFormData({ ...formData, [e.target.name]: e.target.checked });

  const onSubmit = async e => {
    e.preventDefault();
    setIsSubmitting(true);
    await editUser(formData, history);
    setIsSubmitting(false);
  };

  return loading || !user ? (
    <SpinnerComponent />
  ) : (
    <Fragment>
      <Container>
      <h1 className='large text-primary-ms'>Edit User Info</h1>
      <Form className='form msstd-form' onSubmit={e => onSubmit(e)}>
          <Row className="my-3">
            <Col>
              <Label for='username'>Username or First Name (This will be seen by others on the site.)</Label>
              <Input type="text" 
                name="username" 
                id="username" 
                placeholder="Username or first name"
                value={username}
                onChange={e => onChange(e)} />
            </Col>
          </Row>
          { user.subscriberStatusActive ?
            <Row className="my-3">
              <Col>
                <Label for='fullname'>Full Name (for your MySchool+ I.D.)</Label>
                <Input type="text" 
                  name="fullname" 
                  id="fullname" 
                  placeholder="Full Name "
                  value={fullname}
                  onChange={e => onChange(e)} />
              </Col>
            </Row>
          :""}
          <Row className="my-3">
            <Col>
              <CustomInput type="switch" 
                id="indivMailingListCheck" 
                name="indivMailingListCheck" 
                checked={indivMailingListCheck} 
                onChange={e => onCheckedChange(e)} 
                label="Email Subscriber" 
              />
            </Col>
          </Row>
        <button
          disabled={isSubmitting}
          type="submit"
          className="btn btn-myschool-blue btn-set-WH"
        >
          <span>
            {isSubmitting ? (
              <Spinner size="sm" className='color-white'/>
            ) : (
              "Submit"
            )}
          </span>
        </button>
        <Link to="/dashboard" className='btn btn-light btn-set-WH'>Cancel</Link>
      </Form>
      </Container>
    </Fragment>
  );
};

EditUser.propTypes = {
  editUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { editUser }
)(withRouter(EditUser));
