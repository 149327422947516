import React, { Fragment, useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import LoginPopup from '../../auth/LoginPopup';
import RegisterPopup from '../../auth/RegisterPopup';
import { Modal, ModalBody } from 'reactstrap';
import EventInterest from '../../event-forms/EventInterest';
import EmailUsForm from '../../company/EmailUsForm';
import FeatureEvents from './FeatureEvents';
import ReactGA from 'react-ga';
import ChooseHostPath from '../../landing/Features/ChooseHostPath';

const EventsLanding = ({ isAuthenticated }) => {

  const history = useHistory();

  const [ loginModal, toggleLoginModal ] = useState(false);
  const [ registerModal, toggleRegisterModal ] = useState(false);
  const [ showInterestForm, toggleShowInterestForm] = useState(false);
  const [ displayModalEmailer, toggleModalEmailer ] = useState(false);

  const swapLoginRegister = () => {
    toggleLoginModal(!loginModal);
    toggleRegisterModal(!registerModal);
  };

  useEffect(() => {
    ReactGA.event({ category: "Events", action: "Learning about in-person events" });
  }, []);

  const choosePathRef = useRef(null);
  const scrollToChooseHostPath = () => {
      choosePathRef.current.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "start"
      });
  };   

  return (
    <Fragment>
      <div className='text-center'>
        
          <div>
              <div className="main">
                  <section className="section overflow-hidden">
                      <div className="container">
                          <div className="row justify-content-center">
                            <h2 className='my-3 color-myschool-blue'>
                              Interested in hosting events?
                            </h2>
                          </div>
                          <div className="row justify-content-center">
                            <h4 className='m-3 text-center color-myschool-blue'>
                             You can host classes, field trips, meetups and more!
                            </h4>
                          </div>
                          <div className="row justify-content-center">
                            <div className='mb-5'>
                              <FeatureEvents 
                                history={history} 
                                toggleLoginModal={toggleLoginModal} 
                                loginModal={loginModal} 
                                toggleRegisterModal={toggleRegisterModal} 
                                registerModal={registerModal} 
                                isAuthenticated={isAuthenticated}
                                />
                            </div>

                          </div>
                      </div>

                      <div>
                        <div className="row justify-content-center">
                          <h2 className='my-3 color-myschool-blue'>
                            Who can host events?
                          </h2>
                        </div>
                        <div className="row justify-content-center">
                          <h4 className='m-3 text-center color-myschool-blue'>
                           You must create a personal profile or a spot profile in order to host events!
                          </h4>
                        </div>
                        <ChooseHostPath showTitle={false} history={history} choosePathRef={choosePathRef}/>
                      </div>

                      <div className="row justify-content-center">

                          <div className="col-12 col-md-10 col-lg-7">
                              <p className="d-block mt-5 text-italic">
                                There are people with amazing skillsets all around us.  If we can leverage the skills, our children can get a more robust education, and we can all work a little less.
                              </p>
                              <p  className="d-block my-5">
                                <span className='text-underline-red text-bold'>LEARN</span>
                                {' '}
                                <span className='text-underline-yellow text-bold'>MORE</span>
                                {' '}
                                <span className='text-underline-blue text-bold'>TOGETHER</span>
                                {'.'}
                              </p>

                          </div>
                      </div>

                      <p className="d-block">It takes a village. Find yours on MySchoolatHome.</p>
                      <p className='mb-5'>
                        <button className='basic-button text-bold text-underlined' onClick={()=>toggleRegisterModal(true)}>
                          Register
                        </button> 
                        {' '} to become a part of the community today!
                      </p>
                  </section>
              </div>
          </div>
      </div>
        

      <Modal isOpen={loginModal} centered toggle={() => toggleLoginModal(!loginModal)}>
        <ModalBody className='text-center'>
          <LoginPopup loginModal={loginModal} toggleLoginModal={toggleLoginModal}/>
          <button onClick={()=>swapLoginRegister()} className='basic-button text-bold text-underlined'>I need to Register</button>
        </ModalBody>
      </Modal>

      <Modal isOpen={registerModal} centered toggle={() => toggleRegisterModal(!registerModal)}>
        <ModalBody className='text-center'>
          <RegisterPopup registerModal={registerModal} toggleRegisterModal={toggleRegisterModal} history={history}/>
          <button onClick={()=>swapLoginRegister()} className='basic-button text-bold text-underlined'>Go to Login</button>
        </ModalBody>
      </Modal>

      <Modal isOpen={showInterestForm} centered toggle={() => toggleShowInterestForm(!showInterestForm)}>
        <ModalBody className='text-center'>
          <button type='button' onClick={()=>toggleShowInterestForm(false)} className='basic-button float-right'><i className="fas fa-times"/></button>
          <EventInterest showInterestForm={showInterestForm} toggleShowInterestForm={toggleShowInterestForm} />
        </ModalBody>
      </Modal>

      <Modal isOpen={displayModalEmailer} centered toggle={() => toggleModalEmailer(!displayModalEmailer)}>
        <ModalBody className='text-center'>
          <button type='button' onClick={()=>toggleModalEmailer(false)} className='basic-button float-right'><i className="fas fa-times"/></button>
          <h4>No problem!  What would you like to see, or like to see more of?</h4>
          <EmailUsForm displayModalEmailer={displayModalEmailer} toggleModalEmailer={toggleModalEmailer} />
        </ModalBody>
      </Modal>
      

    </Fragment>
  );
};

EventsLanding.propTypes = {
  isAuthenticated: PropTypes.bool
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
});


export default connect(mapStateToProps, { })(EventsLanding);
