import React, { useState } from 'react';

const Pricing = ({history, user}) => {

    const [data, setData] = useState({
        heading: "Pricing",
        currentPrice: "$25 / year ",
        anchorStatement: `That's less than bringing the family to McDonalds!`,
        takeaway: `Feed your children's minds a healthy diet with MySchool+`,
        calcNotes: "*Savings estimates based on calculated savings over a one year period with a given usage of each MySchool+ feature.  Individual savings may be higher or lower.",
        priceCalc: "sum(average discounts $200)/2 + reciprocal savings ($75 avg savings between local membership fees - GBHA normed) + $50 in free curriculum value. + $25 (homeschool teacher savings)"
    });
    return (
        <section id="pricing" className="section price-plan-area bg-gray overflow-hidden py-5">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-md-10 col-lg-7">
                        {/* Section Heading */}
                        <div className="section-heading text-center">
                            <h2>{data.heading}</h2>
                            <div className='half-space'/>
                            <h4 className="mt-5 color-success">{data.currentPrice}</h4>
                            <h4 className="mt-5 color-success">{data.anchorStatement}</h4>
                            <h4 className="mt-5 color-myschool-blue">{data.takeaway}</h4>
                            {user && user.subscriberStatusActive ? 
                                ''
                                :
                                <div className='text-center my-3'>
                                    <button type='button' className='btn btn-myschool-blue my-4' onClick={()=>history.push('/myschoolplus/subscribe')}>
                                        Subscribe now!
                                    </button>
                                </div>
                            }
                            <div className='text-07rem'>{data.calcNotes}</div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Pricing;
