import React, { useState, Fragment } from 'react';
import { Col, Row, Button, Form, FormGroup, Label, Input } from 'reactstrap';
import { Link } from "react-router-dom";

const RegisterWithResourceRequest = ({ setAlert, registerAndFindMeResources, currentLatLong }) => {
  
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
    password2: ''
  });


  const [indivMailingListCheck, toggleIndivMailingListCheck] = useState(true);
  

  const { username, email, password, password2 } = formData;

  const onChange = e =>
      setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async e => {
    e.preventDefault();
    if (password !== password2) {
      setAlert('Passwords do not match', 'danger');
    } else {
      registerAndFindMeResources({ username, email, password, indivMailingListCheck, currentLatLong });
    }
  };


    return (
      <Fragment>
        <Form onSubmit={e => onSubmit(e)} className="msstd-form container">
          <h4> Registration </h4>
          <Row>
            <Col>
              <FormGroup>
                <Input type="text" 
                name="username" 
                id="username" 
                placeholder="First Name or User Name" 
                value={username}
                onChange={e => onChange(e)} 
                required/>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <FormGroup>
                <Input type="email" 
                    name="email" 
                    id="email" 
                    placeholder="Email Address"
                    value={email}
                    onChange={e => onChange(e)} 
                    required/>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Input type="password" 
                name="password" 
                id="password" 
                placeholder="Password"
                value={password}
                onChange={e => onChange(e)} 
                required/>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Input type="password" 
                name="password2" 
                id="password2" 
                placeholder="Confirm Password"
                value={password2}
                onChange={e => onChange(e)} 
                required/>
              </FormGroup>
            </Col>
          </Row>
          <FormGroup check>
            <Input type="checkbox" 
                name="indivMailingListCheck" 
                id="indivMailingListCheck"
                checked={indivMailingListCheck}
                onChange={() => toggleIndivMailingListCheck(!indivMailingListCheck)} />
            <Label htmlFor="indivMailingListCheck" check>Join our Email List? (We send periodic emails about new features. Opt out anytime.)</Label>
          </FormGroup>
          <Button type="submit">Sign up</Button>
        <div>
          Already have an account? <Link to='/login'>Log In</Link>
        </div>
        </Form>
                  
      </Fragment>
    );
};

export default RegisterWithResourceRequest;
