import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { Container, Card, CardBody, CardImg } from 'reactstrap';
import api from '../../utils/api';
import SpinnerComponent from '../layout/Spinner';
import { setAlert } from '../../actions/alert';
import { getPublicProfileById } from '../../actions/profile';
import ImageContainer from '../layout/ImageContainer';
import blankProfileImage from '../../images/profile-img-blank.svg';
import dayjs from 'dayjs';
import checkBlue from '../../images/check-blue_640-640.svg';
import xMarkRed from '../../images/x_mark-red.svg';


const MySchoolPlusStatus = ({ profile: {profileLoading, profile}, getPublicProfileById, auth }) => {

  const history = useHistory();
  const params = useParams();

  useEffect(()=> { 
      getPublicProfileById(params.userId);
  }, []);

  return !profile || profileLoading ? (
      <SpinnerComponent />
    ) : profile && !profileLoading ? (
    	<Container className="text-center">
		        <div className="idcard-wrapper">

					<div className="idcard-container">
						<div className="idcard-profile-img">
				            <ImageContainer
				              src={profile.image && profile.image !== 'default.jpg' ? profile.image: blankProfileImage}
				              thumb={blankProfileImage}
				              width='150px' 
				              height='150px' 
				              className='round-img' 
				              alt='profile image'
				            />
				        </div>
					    
					    <div className="idcard-content">
						    <div className="idcard-sub-content">
						        <h4>{profile.user && profile.user.username}</h4>
						        { profile && profile.user &&  profile.user.subscriberStatusActive &&  profile.user.subscriberStatusActive === true ? 
						        	<div>
						        		<div className='my-4'>
							            <img src={checkBlue} width='70px' alt='active check mark' className='fadeIn-slow'/>
							          </div>
							        	Status: <span className='color-success text-bold'>ACTIVE</span><span className='text-075rem'></span>
							        	<div className='mb-3'/>
							        </div>
						        	:   
						        	<div>
						        		<div className='my-4'>
							            <img src={xMarkRed} width='70px' alt='inactive x mark' className='fadeIn-slow'/>
							          </div>
							        	Status: <span className='color-danger text-bold'>INACTIVE</span><span className='text-075rem'></span>
							        </div>
						        }
						    </div>
						</div>
					</div>
				</div>
		</Container>
    ) : (
      <Container className='text-center'>
        <h2>Error: Please try again later.</h2>
      </Container>
    )  
};

MySchoolPlusStatus.propTypes = {
	profile: PropTypes.object.isRequired,
	getPublicProfileById: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
	profile: state.profile
});


export default connect(mapStateToProps, { getPublicProfileById })(MySchoolPlusStatus);
