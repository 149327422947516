import React, { Fragment, useState } from 'react';
import { Marker, Popup } from 'react-leaflet';
import { Link } from 'react-router-dom';
import { Row, Col, Card, CardBody, CardText, Button, Spinner } from 'reactstrap';
import capitalizeName from '../../../utils/capitalizeName';
import heartOutline from '../../../images/heart-outline.svg';
import heartFilled from '../../../images/heart-filled.svg';
import zoomToPoint from '../../../images/icon-zoom-to-point.svg';
import zoomOut from '../../../images/icon-zoom-out.svg';

const ResourceListItem = ({ resource, isSettingFav, favoriteResourceHandler, auth, setMapFlyTo, zoomInOut, isZoomedIn, history, toggleLoginModal }) => {

  return (
    <Card className='homeschoolmap-card' onTouchStart={() => setMapFlyTo([resource.lat, resource.long])} onMouseEnter={() => setMapFlyTo([resource.lat, resource.long])} >
      <CardBody className='homeschoolmap-cardbody'>
        <Row>

          <Col xs={2} className='homeschoolmap-list-actions'>
            <div className='d-flex align-items-center h-100 '>
              <button type='button' className='btn fav-button' onClick={()=>zoomInOut([resource.lat, resource.long])}>
                { isZoomedIn ? 
                  <img src={zoomOut} width='30px' />
                  :
                  <img src={zoomToPoint} width='30px' />
                }
              </button>
            </div>
          </Col>

          <Col xs={7} className='px-0'>
            <div className=''>
              <CardText className='text-bold'>{resource.resourceName}</CardText>
              <CardText className='text-bold'>{capitalizeName(resource.category)}</CardText>
              { resource && resource.subjects && Array.isArray(resource.subjects) && resource.subjects.length >0 &&
                <CardText><span className='text-bold'>{`Subjects: `}</span>{resource.subjects.join(', ')}</CardText>
              }
              {
                auth.isAuthenticated ? 
                <button className='basic-button text-12px' onClick={()=>history.push(`/claim-resource/${resource._id}`)}>Claim Spot</button>
                :
                <button className='basic-button text-12px' onClick={()=>toggleLoginModal(true)}>Claim Spot</button>
              }
              {' | '}
              {
                auth.isAuthenticated ? 
                <button className='basic-button text-12px' onClick={()=>history.push(`/edit-resource/${resource._id}`)}>Edit Spot</button>
                :
                <button className='basic-button text-12px' onClick={()=>toggleLoginModal(true)}>Edit Spot</button>
              }
            </div>
          </Col>

          <Col xs={3} className='homeschoolmap-list-actions'>
            <div className='homeschoolmap-list-actions-buttons'>
              <Button type='button' className='fav-button justify-content-center' disabled={isSettingFav} onClick={()=>favoriteResourceHandler(resource._id)}>
                {isSettingFav === false && auth.user && auth.user.myFavResources && auth.user.myFavResources.filter(fav => fav.toString() === resource._id).length > 0 ?
                  <img src={heartFilled} width="15px"/>
                   : 
                 isSettingFav === true ?
                  <Spinner style={{ width: '15px', height: '15px'}} type="grow" color="danger" />
                   : 
                  <img src={heartOutline} width="15px"/>
                } 
              </Button>
              <div className="my-1"/>
              <Button type='button' className='homeschoolmap-list-action-more' onClick={()=>history.push(`/resource/${resource._id}`)}>
                more...
              </Button>
            </div>
          </Col>

        </Row>
      </CardBody>
    </Card>
  );
};


export default ResourceListItem;