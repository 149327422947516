import React from 'react';
import { Badge } from 'reactstrap';

const data = [
    {
        fontAwesomeIcon: 'fas fa-calendar-check fa-3x color-myschool-yellow',
        title: 'Schedule your event',
        content: 'Click Host -> Create an Event to get started! You can make it a public event, or a private one just for your friends or co-op.',
        content2: 'You can charge a fixed about, offer it for free, or suggest a donation.'
    },
    {
        fontAwesomeIcon: 'fas fa-clipboard-list fa-3x color-myschool-red',
        title: 'Manage Your Bookings',
        content: 'When someone registers for your event, their registration information will be accessible from your dashboard and you get an alert via email!',
        content2: 'This makes it easy to keep everyone informed!'
    },
    {
        fontAwesomeIcon: 'fas fa-money-check-alt fa-3x color-myschool-blue',
        title: 'Get paid for your efforts',
        content: 'The money goes straight to your bank account!',
        content2: 'We take a portion of the event cost. See MySchool\'s T&C\'s for complete details on how we pay, about the platform fees, and all the stuff that our lawyers insist on.'
    }
]

const FeatureEvents = ({ toggleLoginModal, history, isAuthenticated}) => {

    return (
        <section id="features" className="section features-area style-two overflow-hidden">
            <div className="container">
                <div className="row">
                    {data.map((item, idx) => {
                        return(
                            <div key={`ffd_${idx}`} className="col-12 col-md-6 col-lg-4 my-3 res-margin">
                                {/* Image Box */}
                                <div className="image-box text-center icon-1 p-5">
                                    {/* Featured Image */}
                                    <div className="featured-img mb-3">
                                        <i className={item.fontAwesomeIcon} alt="" />{' '}
                                        {item.badge ? (<p><Badge color='warning'>{item.badge}</Badge></p>):''}
                                    </div>
                                    {/* Icon Text */}
                                    <div className="icon-text">
                                        <h4 className="mb-2">{item.title}</h4>
                                        <p>{item.content}</p>
                                        {item.content2 ? <p className='text-italic text-075rem'>{item.content2}</p>:''}
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </section>
    );
}

export default FeatureEvents;