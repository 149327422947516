module.exports = function formatTime(string){ 
    const hh = string.split(":")[0] * 1;
    const mm = string.split(":")[1]  * 1;
    let ampm = string.split(":")[2];

    if (ampm && (ampm == "AM" || ampm == "PM" )) {
        console.log(ampm);
    } else if (hh < 12) {
        ampm = 'AM';
    } else {
        ampm = 'PM';
    }

    let hhString;
    if (hh > 12) {
        hhString = (hh-12);
    } else if ( hh === 0  ) {
        hhString = 12;
    } else if ( hh < 10  ) {
        hhString = hh.toString();
    } else {
        hhString = hh;
    }

    let mmString;
    if (mm < 10) {
        mmString = "0"+mm.toString();
    } else {
        mmString = mm;
    }

    const timeString = `${hhString}:${mmString} ${ampm}`; 
    return timeString; 
};