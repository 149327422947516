import React, { useState, Fragment, useEffect } from 'react';
import { Container, Col, Row, Table, Button } from 'reactstrap';
import axios from 'axios';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import AuthModals from '../../auth/AuthModals';
import BackButton from '../../../utils/BackButton';
import ReactGA from 'react-ga';

const ReciprocalWrapper = ({ auth: {user, loading, isAuthenticated} }) => {

	const history = useHistory();

	const [ loginModal, toggleLoginModal ] = useState(false);
	const [ registerModal, toggleRegisterModal ] = useState(false);

	const [initData, setInitData] = useState({
        preHeading: "Premium",
        preHeadingspan: "Features",
        heading: "Reciprocal Navigator",
        headingText: "Learn more. Spend less.",
        headingTexttwo: "Some museums, nature centers, and other organizations are part of a larger networks. When you purchase a reciprocal level membership at one of these participating organizations, you get benefits at other spots in the network like free or reduced admission, store discounts, and more! Below is a list of the reciprocal programs that you can navigate on the MySchool Map with your MySchool+ membership!"
    });

	const [recip, setRecip] = useState([
		{
			id:1,
			name: 'Association of Children\'s Museums (ACM)',
			count: 200,
			countText: 'spots in the United States and Canada.',
			// maxSavings: 70,
			description: 'The ACM Reciprocal Network is a voluntary group within the ACM network of museums across the U.S. and Canada that reciprocate discounted admission to each other’s members.  Museums participating in the Reciprocal Network offer 50% off general admission for up to six (6) people. Additional visitors in your group will be asked to pay full admission.',
			link: 'https://findachildrensmuseum.org/reciprocal-network/',
			findQuery: 'reciprocals=acm&zoom=6'
		},
		{
			id:2,
			name: 'Association of Zoos and Aquariums (AZA)',
			count: 152,
			countText: 'spots in the United States.',
			// maxSavings: 70,
			description: 'The AZA in part provides services, collaboration opportunities, and accredidation to zoos and aquariums around the world, but they also run a robust reciprocal program that gives the public the chance to expand the places they can visit with significant discounts. If you join a participating zoo or aquarium marked as "FREE or 50% off", you can get FREE admission to other spots marked as "FREE or 50% off" and 50% off those marked "50% off" (so these are the best to join IF you have a local option that offers this). If you join an organization marked "50% off", you can get 50% off admission at any of the other participating zoos and aquariums. Zoos and aquariums that are already free to the public provide other member benefits (such as gift shop discounts). See the organization\'s website for complete rules.',
			link: 'https://www.aza.org/reciprocity?locale=en',
			findQuery: 'reciprocals=aza&zoom=7'
		},
		{
			id:3,
			name: 'Association of Science and Technology Centers (ASTC)',
			count: 335,
			countText: 'spots in the United States.',
			// maxSavings: 70,
			description: 'The ASTC Passport program provides benefits at other ASTC member spots when you travel outside your local area.  See the organization\'s website for complete rules.',
			link: 'https://www.astc.org/membership/find-an-astc-member/passport/',
			findQuery: 'reciprocals=astc&zoom=7'
		},
		{
			id:4,
			name: 'Association of Nature Center Administrators (ANCA)',
			count: 122,
			countText: 'nature centers in North America.',
			// maxSavings: ,
			description: 'ANCA is a nonprofit organization dedicated to promoting and supporting best leadership and management practices for nature and environmental learning centers. ',
			link: 'https://www.natctr.org/membership/reciprocal-program',
			findQuery: 'reciprocals=anca&zoom=6'
		},
		{
			id:5,
			name: 'Greater Baltimore History Alliance (GBHA)',
			count: 17,
			countText: 'spots in the Baltimore area.',
			// maxSavings: 175,
			description: 'The GBHA is a consortium of Baltimore area history museums dedicated to preserving, interpreting, and promoting local heritage through collaborative community services and educational programs. You must purchase a membership level that includes this program (and most of the family level memberships do) at one of the participating member museums.',
			link: 'https://baltimoremuseums.org/membership/programs/reciprocal-admission-program/',
			findQuery: 'reciprocals=gbha&zoom=10'
		},
	]);

	const findReciprocals = (query) => {
		ReactGA.event({ category: "MySchool_Plus", action: "Reciprocals", label: `${query}` });
		history.push(`/homeschoolmap?${query}`)
	}

  return(
		<div className='plan-table-wrapper ptb_50'>
			<Container>
				<BackButton history={history} backTo='/myschoolplus' color='blue' textString='Back to MySchool Plus'/>
				<div className="row justify-content-center">
            {/* Section Heading */}
            <div className="section-heading px-3 text-center">
                <span className="d-inline-block rounded-pill shadow border fw-5 px-4 py-2 mb-3">
                    <i className="far fa-lightbulb text-primary mr-1" />
                    <span className="text-primary">{initData.preHeading}</span>
                    {initData.preHeadingspan}
                </span>
                <h2>{initData.heading}</h2>
                <div className="mt-4 text-bold">
                    {initData.headingText}
                </div>
                <div className='mx-5'>
				  	<span className="text-bold">
				      	What is a reciprocal program?{" "}
				  	</span>
				  	{initData.headingTexttwo}
				</div>
            </div>
        </div>

	      

	      <div>
	      	{recip.map(r=> {
	      		return (
	      			<div key={`recip-${r.id}`} className='my-4'>
	      				<div className='mb-2'>
	      					<h4 className='text-bold'>
		      					{r.name}
	      					</h4>
      					</div>

      					<div className='ml-4'>
	      					<div>
	      						<h5 className='text-bold'>
		      						<span className='color-myschool-red text-1-2rem'>
		      							{r.count}
	      							</span> 
	      							{` ${r.countText}`}
	      							{r.maxSavings &&
		      							<span className='color-success text-1-2rem'>
		      								 {` Save up to $${r.maxSavings}!`}
	      								</span>
	      							}
	      						</h5>
	      					</div>
	      					<div>
		      					{`${r.description}`}
	      					</div>
	      					<div>
		      					<div className='d-inline-flex'>
				      				<div>
				      					<a href={r.link} className='btn btn-myschool-red' target='_blank' rel='noopener noreferrer'>
				      						Go to their website
				      					</a>
				      				</div>
			      					<div>
				      					<button 
				      						className='btn btn-myschool-blue' 
				      						onClick={ !isAuthenticated ?
				      							()=>toggleLoginModal(true)
				      							: isAuthenticated && (!user || !user.subscriberStatusActive) ?
				      							()=>history.push('/myschoolplus/subscribe')
				      							:
				      							()=>findReciprocals(`${r.findQuery}`)
				      							}
			      							>
				      						Find ones near you
				      						{!user || !user.subscriberStatusActive ? <span>{' '}(MySchool<span className="text-superscript">+</span> members only)</span>:``}
				      					</button>
				      				</div>
				      			</div>
			      			</div>
	      				</div>
	      				
	      			</div>
	      		)
	      	})}
	      </div>	
			</Container>

    	<AuthModals toggleLoginModal={toggleLoginModal} loginModal={loginModal} toggleRegisterModal={toggleRegisterModal} registerModal={registerModal} />
		</div>
    );
}

ReciprocalWrapper.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(  mapStateToProps, { })(ReciprocalWrapper);