import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Card, CardHeader, CardTitle, CardText, CardFooter, Badge, Button, Modal, ModalBody } from 'reactstrap';
import heartOutline from '../../images/heart-outline.svg';
import heartFilled from '../../images/heart-filled.svg';
import truncate from '../../utils/truncate';
import api from '../../utils/api';
import EmailProgram from './EmailProgram';
import ReactGA from 'react-ga';

const ProgramItem = ({ auth, program }) => {

  const { _id, distance, title, duration, email, phone, contact, subjects, gradeStart, gradeEnd, ageStart, ageEnd, costChild, costAdult, attachments, description, resource_info, provider_info, profile } = program;

  let host = {};
  if (provider_info && provider_info[0].providerName && provider_info[0]._id) 
    {
      host._id = provider_info[0]._id;
      host.type = 'provider';
      host.name = provider_info[0].providerName;
    } 
  else if (resource_info[0] && resource_info[0].resourceName && resource_info[0]._id) 
    {
      host._id = resource_info[0]._id;
      host.type = 'resource';
      host.name = resource_info[0].resourceName;
    } 

  const [ isTruncated, toggleIsTruncated] = useState(true);
  const [ showEmailModal, toggleEmailModal ] = useState(false);

  const emailProgram = () => {
    ReactGA.event({ category: "field-trip-program", action: "click on email program", label: `program: ${program._id}`});
  };

  const [showPhone, setShowPhone] = useState(false);
  const [numCopiedText, setNumCopiedText] = useState(false);
  const revealPhone = async (num) => {
    ReactGA.event({ category: "field-trip-program", action: "click on reveal program phone number", label: `program: ${program._id}` });
    setShowPhone(!showPhone);
    navigator.clipboard.writeText(`${num}`)
    .then(function() {
      setNumCopiedText('Copied to Clipboard!');
    }, function(err) {
      setNumCopiedText('Error copying to clipboard :(');
    });
  };

  const emailBody = encodeURIComponent(`Hi! I'm interested in organizing a trip for your program: ${program.title}.\n\nI'd prefer to host on [INSERT YOUR PREFERED DATE AND TIME HERE], but if that date isn't available, [INSERT ONE OR MORE ALTERNATE DATE/TIME OPTIONS].\n\nAlso, please let me know if any of the information below has changed...\n\nCost (child/adult): $${costChild} / $${costAdult}\nDuration: ${duration} hr\nDescription: ${description}\n\n  Please email me back so that we can coordinate!`)


  const createPillSubj = () => {
    if (subjects.includes('math')) return <Badge color="success" pill>{`Math`}</Badge>
    if (subjects.includes('socialstudies')) return <Badge color="success" pill>{`Social Studies`}</Badge>
    {/*  subject === 'General' ?
      <i className="fas fa-school fa-2x"/>
      : subject === 'math' ? 
      <i className="fas fa-square-root-alt fa-2x"/>
      : subject === 'English' ?
      <i className="fas fa-book fa-2x"/>
      : subject === 'Science' ?
      <i className="fas fa-atom fa-2x"/>
      : subject === 'Social Studies' ?
      <i className="fas fa-globe-americas fa-2x"/>
      : subject === 'Art' ?
      <i className="fas fa-palette fa-2x"/>
      : subject === 'Music' ?
      <i className="fas fa-music fa-2x"/>
      : subject === 'Physical Education' ?
      <i className="fas fa-running fa-2x"/>
      : subject === 'Health' ?
      <i className="fas fa-carrot fa-2x"/>
      : <i className="fas fa-school"/>
    */}
  };

  const pillColors = {
    'social studies': 'warning',
    'history': '#56123c',
    'goverment': 'warning',
    'english': 'danger',
    science: 'success',
    music: 'info',
    art: '#56123c',
    general: 'dark'
  }


  return (
    <Card className='lesson-card'>
      <CardHeader className='text-bold'>
        <div>{title}</div>
        {host && host.type && host.name ? <Link to={`/${host.type}/${host._id}`} className="text-09rem" >{`Host: ${host.name}`}</Link>:''}
      </CardHeader>
      <div className='m-2'>
        {distance && <Badge color="success" pill>{`${distance.toFixed(1)} miles away!`}</Badge>}
        <CardText>
          <strong>Ages</strong>{' '}{ageStart === ageEnd ? (
            `${ageEnd}`
            ) : ( `${ageStart}-${ageEnd}`)
          }{` / `} 
          {ageStart === ageEnd ? 
            `Grade ${gradeEnd}`
          : ( `Grades ${gradeStart}-${gradeEnd}`)
          }
        </CardText>
        <CardText>
          <strong>Subjects:</strong>{' '}
            {subjects.map((s,i) => {
                return <Badge key={i} className={`pill-color-${s.replace(/\s+/g, '')} mr-1`} pill>{s}</Badge>
              })
            }
        </CardText>
        <CardText>
          <strong>Length:</strong>{' '}<i className="far fa-clock"/>{` ${duration} hrs`}
        </CardText>
        <CardText>
          <strong>Description:</strong>{' '}
            {isTruncated && description.length >=150 ? truncate(description, 150): description}
            {description.length >=150 ? 
              <button className='float-right basic-button text-underlined text-09rem' type='button' onClick={()=> toggleIsTruncated(!isTruncated)}>
                <Badge color="info" pill>{isTruncated ? 'more': 'less'}</Badge>
              </button>
              :''
            }
        </CardText>
        { email ?
          <div className='text-center align-items-end'>
            <a href={`mailto:${email}?subject=Interested in your program: ${title}&body=${emailBody}`} target='_blank' rel='noopener noreferrer' onClick={()=>emailProgram()} className='btn block-button btn-myschool-blue'>
              Contact the program manager to set up a trip!
            </a>
          </div>
          :''
        }
        { !email && phone ?
          <div className='text-center align-items-end'>
            <button type='button' onClick={()=>revealPhone(phone)} className='btn block-button btn-myschool-blue'>
              {
                !showPhone ? `Must call to set up a trip. Click to reveal and copy phone number!`
                : <div>{phone}{' '}<span className='text-075rem'>({numCopiedText})</span></div>
              }
            </button>
          </div>
          :''
        }
      </div>
    </Card>

  );
};


ProgramItem.propTypes = {
  program: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(
  mapStateToProps,
  {  }
)(ProgramItem);



// import React, { useState } from 'react';
// import PropTypes from 'prop-types';
// import { connect } from 'react-redux';
// import { Card, CardHeader, CardTitle, CardText, CardFooter, Badge, Button, Modal, ModalBody } from 'reactstrap';
// import heartOutline from '../../images/heart-outline.svg';
// import heartFilled from '../../images/heart-filled.svg';
// import truncate from '../../utils/truncate';
// import api from '../../utils/api';
// import EmailProgram from './EmailProgram';

// const ProgramItem = ({ auth, program}) => {

//   const { _id, distance, title, duration, subjects, gradeStart, gradeEnd, ageStart, ageEnd, costChild, costAdult, attachments, description } = program;

//   const [ isTruncated, toggleIsTruncated] = useState(true);
//   const [ showEmailForm, toggleEmailForm ] = useState(false);

//   const cardData = (
//     <div className='m-2'>
//       {distance && <Badge color="success" pill>{`${distance.toFixed(1)} miles away!`}</Badge>}
//       <CardText>
//         <strong>Ages</strong>{' '}{ageStart === ageEnd ? (
//           `${ageEnd}`
//           ) : ( `${ageStart}-${ageEnd}`)
//         }{` / `} 
//         {ageStart === ageEnd ? 
//           `Grade ${gradeEnd}`
//         : ( `Grades ${gradeStart}-${gradeEnd}`)
//         }
//       </CardText>
//       <CardText>
//         <strong>Subjects:</strong>{' '} {subjects.join(", ")}
//       </CardText>
//       <CardText>
//         <i className="far fa-clock"/>{` ${duration} hrs`}
//       </CardText>
//       <CardText>
//         <strong>Description:</strong>{' '}
//           {isTruncated && description.length >=150 ? truncate(description, 150): description}
//           {description.length >=150 ? 
//             <button className='float-right basic-button text-underlined text-09rem' type='button' onClick={()=> toggleIsTruncated(!isTruncated)}>
//               <Badge color="info" pill>{isTruncated ? 'more': 'less'}</Badge>
//             </button>
//             :''
//           }
//       </CardText>
//       <div className='text-center align-items-end'>
//         <button type='button' onClick={()=>toggleEmailForm(!showEmailForm)} className='btn block-button btn-myschool-blue'>
//           Contact the program manager to set up a trip!
//         </button>
//       </div>
//     </div>
//   );

  // const createPillSubj = (s) => {
  //   console.log(s)
  // }
  // {/* subject === 'General' ?
  //   <i className="fas fa-school fa-2x"/>
  //   : subject === 'math' ? 
  //   <i className="fas fa-square-root-alt fa-2x"/>
  //   : subject === 'English' ?
  //   <i className="fas fa-book fa-2x"/>
  //   : subject === 'Science' ?
  //   <i className="fas fa-atom fa-2x"/>
  //   : subject === 'Social Studies' ?
  //   <i className="fas fa-globe-americas fa-2x"/>
  //   : subject === 'Art' ?
  //   <i className="fas fa-palette fa-2x"/>
  //   : subject === 'Music' ?
  //   <i className="fas fa-music fa-2x"/>
  //   : subject === 'Physical Education' ?
  //   <i className="fas fa-running fa-2x"/>
  //   : subject === 'Health' ?
  //   <i className="fas fa-carrot fa-2x"/>
  //   : <i className="fas fa-school"/>
  // }

//   return (
//     <div>
//       { !showEmailForm ?
//         <Card className='lesson-card'>
//           <CardHeader>
//           {title}
//           </CardHeader>
//           {cardData}
//         </Card>
//         :
//         <div>
//           <EmailProgram program={program} cardData={cardData}/>
//         </div>
//       }
//     </div>

//   );
// };


// ProgramItem.propTypes = {
//   program: PropTypes.object.isRequired,
//   auth: PropTypes.object.isRequired
// };

// const mapStateToProps = state => ({
//   auth: state.auth
// });

// export default connect(
//   mapStateToProps,
//   {  }
// )(ProgramItem);


