import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import { Modal, ModalBody } from 'reactstrap';
import capitalizeName from '../../utils/capitalizeName';
import moment from 'moment';
import LoginPopup from '../auth/LoginPopup';
import RegisterPopup from '../auth/RegisterPopup';
import CheckoutRegNotReq from './CheckoutRegNotReq';
import ReactGA from 'react-ga';

const EventDetails = ({event, auth, history, isPreview}) => {

  const disableRegistration = false;

  const [ loginModal, toggleLoginModal ] = useState(false);
  const [ registerModal, toggleRegisterModal ] = useState(false);
  const [ registerNowOption, setRegisterNowOption] = useState(true);

  const registerNowButton = () => {
    ReactGA.event({ category: "registerModal", action: "opt_to_register", label: 'event-click-through'});
    setRegisterNowOption(false);
  };

  const openRegisterModal = () => {
    ReactGA.event({ category: "registerModal", action: "open_modal", label: 'event-click-through'});
    toggleRegisterModal(!registerModal);
  };

  const goToEventCheckout = () => {
    ReactGA.event({ category: "event_registration", action: "click_book_now", label: `${event._id}`});
    history.push(`/events/${event._id}/checkout`);
  };

  let approxLat;
  if (event.lat) {
    approxLat = (event.lat * 1).toFixed(2);
  };
  let approxLong;
  if (event.long) {
    approxLong = (event.long * 1).toFixed(2);
  };

  const swapLoginRegister = () => {
    toggleLoginModal(!loginModal);
    toggleRegisterModal(!registerModal);
  };

  const goOffSiteWithUTM = (url) => {
    ReactGA.event({ category: "event", action: "click_through", label: `${event._id}`});
    const urlArray = url.split('?');
    // console.log(urlArray);
    let newUrl;
    if (urlArray.length > 1) {
      const searchWithUTM = urlArray[1].toString() + '&utm_source=myschoolathome.io';
      newUrl = urlArray[0] + '?' + searchWithUTM;
    } else {
      const utmOnly = 'utm_source=myschoolathome.io';
      newUrl = urlArray[0] + '?' + utmOnly;
    }
    window.open(newUrl);
  }

  const [ showImInModal, toggleImInModal ] = useState(false);
  const regNotReqSignup = () => {
    ReactGA.event({ category: "event_registration", action: "click_regNotReq_Event", label: `${event._id}`});
    toggleImInModal(true);
  };
  
  const bookNowButton = (
      <Fragment>
      { disableRegistration ?
          <div className='btn block-button btn-myschool-red' to={`/events/${event._id}/checkout`} disabled>
            Book Now! (Coming Soon!)
          </div>
        : isPreview ?
          <div className='btn block-button btn-myschool-red' to={`/events/${event._id}/checkout`} disabled>
            Book Now! (Disabled in Preview Mode)
          </div>
        : !event.isLive ?
          <div className='btn block-button btn-myschool-red' to={`/events/${event._id}/checkout`} disabled>
            (Bookings Closed by Host)
          </div>
        : event.deadlineReg && moment(event.deadlineReg).isBefore() ?
          <div className='btn block-button btn-myschool-red' to={`/events/${event._id}/checkout`} disabled>
            Booking Disabled (Registration Deadline Passed)
          </div>
        : !event.isOffSite && event.slotsAvailable && event.slotsAvailable*1 > 0 && event.regNotReq && event.regNotReq === true ? 
          <button className='btn block-button btn-myschool-red' onClick={()=>regNotReqSignup()} >
            Sign Up!
          </button>
        : !auth.isAuthenticated ? 
          <button className='btn block-button btn-myschool-red' onClick={() => openRegisterModal()}>
              {!event.isOffSite ? `Book Now!` : `Check out the event! (This will take you offsite)`}
          </button>
        : event.isOffSite === true && event.offSiteLink ? 
          <button className='btn block-button btn-myschool-red' onClick={()=>goOffSiteWithUTM(event.offSiteLink)}>
              {!event.isOffSite ? `Book Now!` : `Check out the event! (This will take you offsite)`}
          </button>
        : !event.isOffSite && event.slotsAvailable && event.slotsAvailable*1 > 0 && event.isDropOff === true && !event.isForParents? 
          <Link className='btn block-button btn-myschool-red' to={`/events/${event._id}/dropOff/checkout`} disabled>
            Book Now! (Drop off events currently disabled)
          </Link>
        : !event.isOffSite && !event.isVirtual && event.slotsAvailable && event.slotsAvailable*1 > 0 && event.isDropOff === false && !event.isForParents ? 
          <button className='btn block-button btn-myschool-red' onClick={() => goToEventCheckout()}>
            Book Now!
          </button>
        : !event.isOffSite && !event.isVirtual && event.slotsAvailable && event.slotsAvailable*1 <= 0 && event.includeWaitlist && event.includeWaitlist == true ? 
          <button className='btn block-button btn-myschool-red' onClick={() => goToEventCheckout()}>
            Event Full - Get on the waitlist!
          </button>
        : !event.isOffSite && !event.isVirtual && event.slotsAvailable && event.slotsAvailable*1 > 0 && event.isDropOff === false && event.isForParents ? 
          <Link className='btn block-button btn-myschool-red' to={`/events/${event._id}/parentEvent/checkout`}>
            Book Now!
          </Link>
        : !event.isOffSite && event.isVirtual && event.slotsAvailable && event.slotsAvailable*1 > 0 ? 
          <Link className='btn block-button btn-myschool-red' to={`/events/${event._id}/virtual/checkout`}>
            Book Now!
          </Link>
        : 
          <button
            type='button'
            className='btn block-button btn-myschool-red'
            disabled>
            Book Now! (Event full)
          </button>
      }
    </Fragment>
  );

  const addressSelector = (a, c, s, z) => {
    if (navigator.userAgent.toLowerCase().match(/mobile/i)) {
        const urlMobile=encodeURI(`https://maps.google.com/maps/dir/?daddr=${a}, ${c}, ${s} ${z}`);
        window.open(urlMobile,"","rel=noopener,rel=noreferrer");
      } else {
        const urlDesktop= encodeURI(`https://google.com/maps/dir/?api=1&destination=${a}, ${c}, ${s} ${z}`);
        window.open(urlDesktop,"","rel=noopener,rel=noreferrer");
      }
      
  }

  const latLongSelector = (la, lo) => {
    if (navigator.userAgent.toLowerCase().match(/mobile/i)) {
        const urlMobile=encodeURI(`https://maps.google.com/maps/dir/?daddr=${la},${lo}`);
        window.open(urlMobile,"","rel=noopener,rel=noreferrer");
      } else /* else use Google */{
        const urlDesktop= encodeURI(`https://google.com/maps/dir/?api=1&destination=${la}, ${lo}`);
        window.open(urlDesktop,"","rel=noopener,rel=noreferrer");
      }
  }

  return (
      <div className='pt-0'>
        <div className='mx-2 color-myschool-blue'>
          { event.isOffSite ?
            ''
            :
            <div className='pt-0'>
              <strong>Availability:</strong>
              {event.slotsAvailable && event.slotsTotal && event.slotsAvailable*1 > 0 ? 
                ` ${event.slotsAvailable} of ${event.slotsTotal} spots left!`
                :
                " Sorry, this event is full!"
              }
            </div>
          }
          {bookNowButton}
          {event.deadlineReg ?
            <div>
              <strong>Registration Deadline:</strong>{' '}{moment(event.deadlineReg).format("DD-MMM-YYYY")}
            </div>
            : ''
          }
          <div>
            <strong>Event location:</strong>
              {!event.isOffSite && !event.isVirtual ? 
                ` (Exact address will be provided in your confirmation email.)` 
                :
                  event.isVirtual && event.virtualLink ? 
                  ` Online: Event Link will be included in your confirmation email!`
                :
                  event.isVirtual && !event.virtualLink ? 
                  ` Online!`
                :
                ` ${event.address}`
              }
            {!event.isVirtual && <div className='pl-4'>{` ${event.city}, ${event.state} ${event.zip} `}</div>}
          </div>
          {!event.isVirtual &&
            <div className='pl-4'>
              { !event.isOffSite && approxLat && approxLong ? 
                <button onClick={()=>latLongSelector(approxLat, approxLong)} className='basic-button text-underlined text-14px'>
                  Get directions to the area on Google Maps!
                </button>
                :
                event.isOffSite && event.address && event.city && event.state && event.zip ?
                <button onClick={()=>addressSelector(event.address, event.city, event.state, event.zip)} className='basic-button text-underlined text-14px'>
                  Get Google Map Directions
                </button>
                :''
              }
            </div>
          }
          {!event.isOffSite && !event.isForParents ?
            <div>
              <strong>Are parents required to stay?</strong>
              <div className='pl-4'>
                {
                  (event.isDropOff === false && !event.isVirtual) ?
                  " Yes, parents MUST stay the entire event."
                  :
                  " No."
                }
              </div>
            </div>
            : ''
          }
          {event.description && event.description.length > 1 ?
            <div>
              <strong>Description:</strong>
              <div className='pl-4'>
                {event.description.split("\n").map((i,key) => {
                    return <div key={key}>{i}</div>;
                })}
              </div>

            </div>
            : ''
          }
          {event.learningGoals && event.learningGoals.length > 1 ?
            <div>
              <strong>What we will learn:</strong>
              <div className='pl-4'>
                {event.learningGoals.split("\n").map((i,key) => {
                    return <div key={key}>{i}</div>;
                })}
              </div>
            </div>
            : ''
          }
          {event.subjects && event.subjects.length > 0 ?
            <div>
              <strong>Topics covered:</strong>
                <div className='pl-4'>{` ${event.subjects.join(", ")}`}</div>
            </div>
            : ''
          }
          {event.preparations && event.preparations.length > 1 ?
            <div>
              <strong>What to prepare:</strong>
              <div className='pl-4'>
                {event.preparations.split("\n").map((i,key) => {
                    return <div key={key}>{i}</div>;
                })}
              </div>
            </div>
            : ''
          }
          {event.expectations && event.expectations.length > 1 ?
            <div>
              <strong>Expectations:</strong>
              <div className='pl-4'>
                {event.expectations.split("\n").map((i,key) => {
                    return <div key={key}>{i}</div>;
                })}
              </div>
            </div>
            : ''
          }
          {event.ifClassMissed && event.ifClassMissed.length > 1 ?
            <div>
              <strong>What to do if you miss a class:</strong>
              <div className='pl-4'>
                {event.ifClassMissed.split("\n").map((i,key) => {
                    return <div key={key}>{i}</div>;
                })}
              </div>
            </div>
            : ''
          }
          {event.uponCompletion && event.uponCompletion.length > 1 ?
            <div>
              <strong>Upon completion:</strong>
              <div className='pl-4'>
                {event.uponCompletion.split("\n").map((i,key) => {
                    return <div key={key}>{i}</div>;
                })}
              </div>
            </div>
            : ''
          }
          <div>
            {bookNowButton}
          </div>
        </div>


        <Modal isOpen={loginModal} centered toggle={() => toggleLoginModal(!loginModal)}>
          <ModalBody className='text-center'>
            <div className='float-right'>
              <button className="basic-button" 
                onClick={() => toggleLoginModal(false)}>
                <i className='fa fa-times color-light-grey'/>
              </button>
            </div>
            <div>Skip this step by logging in to your free account!</div>
            {event.isOffSite && event.offSiteLink && <div><button type='button' onClick={()=>goOffSiteWithUTM(event.offSiteLink)} className='basic-button text-underlined text-bold'>I'll do it later. Take me to the event.{' '}<i className="fas fa-chevron-right"/></button></div>}
            <LoginPopup loginModal={loginModal} toggleLoginModal={toggleLoginModal}/>
            <button onClick={()=>swapLoginRegister()} className='basic-button text-bold text-underlined'>I need to Register</button>
          </ModalBody>
        </Modal>

        <Modal isOpen={registerModal} centered toggle={() => toggleRegisterModal(!registerModal)}>
          <ModalBody className='text-center'>
            <div className='float-right'>
              <button className="basic-button" 
                onClick={() => toggleRegisterModal(false)}>
                <i className='fa fa-times color-light-grey'/>
              </button>
            </div>
            <div>Register and create a profile to receive email notifications of upcoming events near you!</div>
              {event.isOffSite && event.offSiteLink && 
                <div className='my-3'>
                  <button type='button' onClick={()=>goOffSiteWithUTM(event.offSiteLink)} className='basic-button text-underlined text-bold'>
                    I'll do it later. Take me to the event.{' '}<i className="fas fa-chevron-right"/>
                  </button>
                </div>
              }
            { registerNowOption ?
                <div className='my-3'>
                  <button type='button' onClick={()=>registerNowButton() } className='btn btn-modal-blue'>Register Now</button>
                </div>
              :
              <div>
                <RegisterPopup registerModal={registerModal} toggleRegisterModal={toggleRegisterModal} history={history}/>
              </div>
            }
            <button onClick={()=>swapLoginRegister()} className='basic-button text-underlined text-09rem'>Already Registered? <span className='text-bold'>Go to Login</span></button>
          </ModalBody>
        </Modal>

        <Modal isOpen={showImInModal} centered>
          <ModalBody className=''>
            <div className='d-flex justify-content-end'>
              <button className="basic-button" 
                onClick={() => toggleImInModal(!showImInModal)}>
                <i className='fa fa-times color-light-grey'/>
              </button>
            </div>
            <CheckoutRegNotReq event={event} history={history} toggleImInModal={toggleImInModal} />
          </ModalBody>
        </Modal>

      </div>
  );
};

EventDetails.defaultProps = {
  isPreview: false
};

export default EventDetails;
