function formatDate(date, format, locale) {
	
	let formatOptions = {};
	if (format === 'long') {
		formatOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
	} else if (format === 'med') {
		formatOptions = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
	}

	let localeOptions = 'en-US';

	if (locale) {
		localeOptions = locale;
	}

	return new Intl.DateTimeFormat(localeOptions, formatOptions).format(new Date(date));
}

export default formatDate;