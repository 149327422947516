import React, { useState } from 'react';
import mapImageSm from '../../../images/profileExample_764-420.png';
import mapImageLg from '../../../images/profileExample_764-560.png';
import ImageContainer from '../../layout/ImageContainer';

const DiscoverPeople = ({ peopleRef }) => {

    const [ data, setData ] = useState({
        heading: "People to Meet",
        subHeading1: "Homeschooling is SO much better when you're not doing it alone! Whether you're looking for social connections for your kids, yourself, or hoping to find other like-minded families that can help bring new skills and knowledge to your homeschooling journey, finding your people is so important.",
        subHeadingProblem: "How do you find other homeschoolers nearby though?!",
        subHeadingSolution: "We had this problem ourselves, which is why we built a solution! After creating your personal profile you'll be able to see other registered homeschooling families near you!",
        subHeading2: "...oh yeah, and this free btw."
    });

    return (
        <section ref={peopleRef} className="section discover-area overflow-hidden ptb_50">
            <div className="container">
                <div className="row justify-content-around align-items-center">
                    <div className="col-12 col-lg-7 order-2">
                        <div className="service-text pt-3 pt-lg-0">
                            <h2 className="text-capitalize mb-4">{data.heading}</h2>
                            <p className='my-3'>{data.subHeading1}</p>
                            <p className='my-3'><span className='text-italic'>{data.subHeadingProblem}</span>{` ${data.subHeadingSolution}`}</p>
                            <p className='my-3'>{data.subHeading2}</p>
                        </div>
                    </div>
                    <div className="col-12 col-lg-5 order-1">
                        <div className="service-thumb mx-auto">
                            <ImageContainer
                              src={mapImageSm}
                              thumb={mapImageSm}
                              className='d-inline d-lg-none' 
                              alt='map snippet with personal profile shown'
                            />
                            <ImageContainer
                              src={mapImageLg}
                              thumb={mapImageLg}
                              className='d-none d-lg-inline' 
                              alt='map snippet with personal profile shown'
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default DiscoverPeople;


