import React, { Fragment } from 'react';
import spinner from '../../images/mySchool_logo-shifting_gears.svg';

const SwitchingGearsSpinner = () => (
  <Fragment>
    <div className="myschool-switching-gears">
      <img
        className="spinner"
        src={spinner}
        style={{ width: '200px', margin: 'auto', display: 'block' }}
        alt='Loading...'
      />
    </div>
  </Fragment>
);

export default SwitchingGearsSpinner;
