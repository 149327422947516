import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import RegisterAndProfile from '../../auth/RegisterAndProfile';
import {useHistory} from 'react-router-dom';
import api from '../../../utils/api';

const EmpoweringParentsIdaho = ({auth}) => {

    const [data, setData] = useState({
        heading: "Empowering Parents Idaho",
        content: "Learning happens everywhere, and Idaho's Empowering Parents program makes it possible for your kids to learn in more places... but which ones are NEAR YOU? That's where MySchool can help.",
        content2: "Sign up, and we'll send you weekly email updates as more Empowering Parents providers are added to the map!",
        btnText: "Sign up and we'll send you new programs each week!",
        formHeading: "Sign up for updates!",
        formBtnText: "By signing up, you accept our",
        formBtnText_2: "Terms",
        formBtnText_3: "Privacy Policy"
    });

    const history = useHistory();
    useEffect(()=>{
        history.replace({search: "?networkTracking=Empowering%20Parents%20Idaho"})
    },[]);

    const [trackingEPIdaho, setTrackingEPIdaho] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const updateProfileNetworkTracking = async () => {
        setIsSubmitting(true);
        const profileUpd = await api.post('/profile/networkTracking',{networkTracking:"Empowering Parents Idaho"});
        setTrackingEPIdaho(true);
        setIsSubmitting(false);
    };

    return (
        <div>
            <div className="bg-myschool-blue pt-4 ptb_50 d-flex align-items-center">
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        {/* Welcome Intro Start */}
                        <div className="col-12 col-lg-7">
                            <div className="welcome-intro">
                                <h1 className="text-white">{data.heading}</h1>
                                <p className="text-white my-4">{data.content}</p>
                                <p className="text-white my-4">{data.content2}</p>
                            </div>
                        </div>
                        <div className="col-12 col-md-8 col-lg-5">
                            {/* Contact Box */}
                            <div className="bg-white text-center rounded p-4 p-sm-5 mt-5 mt-lg-0 shadow-lg">
                                {/* Contact Form */}
                                <div>
                                    <div className="contact-top">
                                    <h3 className="contact-title">{data.formHeading}</h3>
                                    <h5 className="text-secondary fw-3 py-3">{data.formText}</h5>
                                    </div>
                                    <div className="row">
                                    <div className="col-12">
                                        {auth && auth.user && auth.user.profile ?
                                            <div>
                                                <button 
                                                    type="button" 
                                                    className="btn btn-myschool-blue py-3 px-4" 
                                                    onClick={()=>updateProfileNetworkTracking()}
                                                    disabled={trackingEPIdaho}>
                                                    {   isSubmitting ?
                                                            <span className="blinker">Submitting...</span>
                                                        :
                                                        trackingEPIdaho ?
                                                            <span>You're all set up!</span>
                                                        :
                                                            <span>Click here to sign up!</span>
                                                    }
                                                </button>
                                            </div>
                                            :
                                            <RegisterAndProfile disableSkipThisStep={true}/>
                                        }
                                    </div>
                                    </div>
                                </div>
                                <p className="form-message" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="landing-angle">
                <svg viewBox="0 0 100 100" preserveAspectRatio="none">
                    <polygon fill="#375e97" points="0,0 100,0 0,100" />
                </svg>
            </div>
            
        </div>
    );
}

RegisterAndProfile.propTypes = {
    auth: PropTypes.object
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps, { })(EmpoweringParentsIdaho);