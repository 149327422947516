import React, { Fragment, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ProviderProfileItem from './ProviderProfileItem';
import { getMyProviders, deleteProvider } from '../../../actions/provider';
import SpinnerComponent from '../../layout/Spinner';

const DashboardProvider = ({ provider: {providerLoading, providers}, displaySection, getMyProviders, deleteProvider }) => {

  useEffect(() => {
    getMyProviders();
  }, []);

  return providerLoading ? (
    <SpinnerComponent />
  ) : (
    <Fragment>          

        { displaySection.providerInfo && providers ? 
          (
            <Fragment>
              <div className='text-center'>
                <h4 className='text-color-blue text-bold'>Become a Spot!</h4>
                <div className='my-2'>
                  <span className='text-bold'>We love spots!</span>
                  {' '}We want to leverage the digital world to connect people to spots in the real world.  As a registered member, you can
                  {' '}
                  <Link to="/create-resource" className='text-bold text-underline'>suggest spots</Link>
                  {' '} where you love to learn with your kids or let people know about a 
                  {' '}
                  <Link to="/create-provider" className='text-bold text-underline'>spot</Link>
                  {' '} that you run yourself by registering it below!
                </div>
                <Link to="/resource-options" className='text-bold text-underline'>Learn more about{' '}<span className='text-bold'>spots</span>{' '} here!</Link>
              </div>

              <div className='text-center'>
                <div className='my-3'>
                  <div className="center create-provider-wrapper">
                    <Link to='/create-provider' className="btn btn-myschool-red create-provider-btn text-uppercase p-3">
                      <div className='mb-1'>
                        <i className="fas fa-map-marked-alt"/>
                      </div>
                      <div>
                        Click here to put your organization on the map!
                      </div>
                    </Link>
                  </div>
                </div>
              </div>

              <div className='text-center'>
                <h4 className='text-color-blue text-bold'>Your Spots</h4>
              </div>
              <div className='providers'>
                {providers.map(p => (
                  <ProviderProfileItem key={p._id} provider={p} deleteProvider={deleteProvider}/>
                ))}
              {providers.length === 0 ? (<h6>You have not registered any spots of your own yet.</h6>) : ''}
              </div>
            </Fragment>
          ) : ''
        }

    </Fragment>
  );
};

DashboardProvider.propTypes = {
  getMyProviders: PropTypes.func.isRequired,
  deleteProvider: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  provider: state.provider
});

export default connect(mapStateToProps, { getMyProviders, deleteProvider })(DashboardProvider);     