import React, { Fragment, useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { updateResource, getResourceByResourceId } from '../../actions/resource';
import { Container } from 'reactstrap';
import Spinner from '../layout/Spinner';
import ResourceForm from './ResourceForm';

const EditResource = ({ updateResource, getResourceByResourceId, resource: {resourceLoading, resource} }) => {
  
  const history = useHistory();
  const params = useParams();

  const [formData, setFormData] = useState({
    category: '',
    resourceName: '',
    email: '',
    address_full: '',
    address: '',
    city:'',
    state:'',
    zip:'',
    educationalLeader: '',
    subjectOfferings: '',
    religiousAffiliation: '',
    genInfo: '',
    typicalDay: '',
    whenOperate: '',
    website: '',
    phone: ''
  });

  const [ tagData, setTagData ] = useState([]);

  const [ subjData, setSubjData ] = useState({
    math: false,
    english: false,
    science: false,
    'social studies': false,
    art: false,
    music: false,
    pe: false,
    health: false,
    history: false,
    'foreign language': false,
    engineering: false,
    'environmental science': false
  });

  const onSubjChange = e => 
    setSubjData({ ...subjData, [e.target.name]: e.target.checked });

  const onSubmit = async e => {
    e.preventDefault();
    setIsSubmitting(true);
    const subjArray = Object.keys(subjData).filter(key=>subjData[key] === true);
    const allData = {...formData, subjects: subjArray, tags: tagData };
    await updateResource(allData, history, params.resourceId);
    setIsSubmitting(false);
  };

  const [ isSubmitting, setIsSubmitting ] = useState(false);

  let isTagsUpd = false;
  
  useEffect(()=> {
    getResourceByResourceId(params.resourceId);
  },[params]);

  useEffect(() => {
  
    setFormData({
      ...formData,
        category: !resourceLoading && resource && resource.category ? resource.category : '',
        resourceName: !resourceLoading && resource && resource.resourceName ? resource.resourceName : '',
        email: !resourceLoading && resource && resource.email ? resource.email : '',
        address: !resourceLoading && resource && resource.address ? resource.address : '',
        city:!resourceLoading && resource && resource.city ? resource.city : '',
        state:!resourceLoading && resource && resource.state ? resource.state : '',
        zip:!resourceLoading && resource && resource.zip ? resource.zip : '',
        educationalLeader: !resourceLoading && resource && resource.educationalLeader ? resource.educationalLeader : '',
        subjectOfferings: !resourceLoading && resource && resource.subjectOfferings ? resource.subjectOfferings : '',
        religiousAffiliation: !resourceLoading && resource && resource.religiousAffiliation ? resource.religiousAffiliation : '',
        genInfo: !resourceLoading && resource && resource.genInfo ? resource.genInfo : '',
        typicalDay: !resourceLoading && resource && resource.typicalDay ? resource.typicalDay : '',
        website: !resourceLoading && resource && resource.website ? resource.website : '',
        phone: !resourceLoading && resource && resource.phone ? resource.phone : '',
      });
    if (!resourceLoading && resource && resource.tags ) {
      isTagsUpd = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resource, resourceLoading]);

  //remove subjects from tag array (re-added on back end so they don't get doubly added)
  useEffect(()=> {
    let filteredTags = [];
    if (!resourceLoading && resource && resource.subjects && isTagsUpd){
      filteredTags = resource.tags;
      for (let i=0; i < resource.subjects.length; i++) {
        const subj = resource.subjects[i].toString();
        // console.log(subj);
        filteredTags = filteredTags.filter(e => e !== subj);
      }
    }
    // console.log(filteredTags);
    setTagData(filteredTags);

  },[resource, resourceLoading, isTagsUpd]);

  //Set Boolean values for subject inputs
  useEffect(() => {
    let allSubjects = {
      math: false,
      english: false,
      science: false,
      'social studies': false,
      art: false,
      music: false,
      pe: false,
      health: false,
      history: false,
      'foreign language': false,
      engineering: false,
      'environmental science': false
    }
    const setSubjectBooleans = () => {
      for (let i=0; i < resource.subjects.length; i++) {
        const subj = resource.subjects[i];
        allSubjects = Object.assign(allSubjects, {[subj]: true});
      }
    };

    if (!resourceLoading && resource && resource.subjects && resource.subjects.length > 0) {
      setSubjectBooleans();
    }

    setSubjData(allSubjects);

  }, [resource, resourceLoading]);


  return resourceLoading || resource === null ? (
    <Spinner />
  ) : (
    <Fragment>
      <Container>
        <h1 className='large text-primary-ms'>Update This Spot</h1>
        <small>* required fields</small>
        <ResourceForm
          formData={formData}
          setFormData={setFormData}
          subjData={subjData}
          setSubjData={setSubjData}
          tagData={tagData}
          setTagData={setTagData}
          onSubmit={onSubmit}
          onSubjChange={onSubjChange}
          isSubmitting={isSubmitting}
          history={history}
          isEditForm={true}
        />
      </Container>

    </Fragment>
  );
};

EditResource.propTypes = {
  updateResource: PropTypes.func.isRequired,
  getResourceByResourceId: PropTypes.func.isRequired,
  resource: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  resource: state.resource
});

export default connect(
  mapStateToProps,
  { updateResource, getResourceByResourceId }
)(EditResource);
