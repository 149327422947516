import React, { useState, useRef, useEffect } from "react";
import ImageContainer from '../components/layout/ImageContainer';
import blankImage from '../images/icon-add-img.svg';
import blankProfileImage from '../images/profile-img-blank.svg';
import Crop from 'react-image-crop';

const ImageUpload = ({ file, setFile, fileSize, setFileSize, storeThumb, storedThumbnail, setStoredThumbnail, showFileSize, maxFileSize, isProfileImage }) => {
  
  const [thumbnail, setThumbnail] = useState("");

  const onFileChange = e => {
    if (e.target.files[0]) {
      setFile(e.target.files[0]);
      setFileSize(e.target.files[0].size);
      setThumbnail(URL.createObjectURL(e.target.files[0]));
      if (storeThumb) {
        setStoredThumbnail(URL.createObjectURL(e.target.files[0]));
      }
    }
  };

  const removeFile = () => {
    setFile('');
    setFileSize(0);
    setThumbnail('');
  };

  // Create a reference to the hidden file input element
  const hiddenFileInput = useRef(null);
  // Programatically click the hidden file input element
  // when the Button component is clicked
  const handleClick = e => {
    hiddenFileInput.current.click();
  };


  const [crop, setCrop] = useState({ aspect: 1 / 1 });

  useEffect(()=> {
    if (storedThumbnail) {
      setThumbnail(storedThumbnail);
    }
  },[]);

  return (
    <div style={{zIndex:2000}}>
      <input
        type="file"
        accept='image/*'
        id='image'
        name='image'
        onChange={e => onFileChange(e)}
        ref={hiddenFileInput}
        style={{display:'none'}}
      />
      <div className='text-center'>
        {fileSize === 0 ?
          <div className='text-center'>
            <button
              className='btn btn-modal-blue-border basic-button-MBP btn-40-circle' 
              onClick={(e)=>handleClick(e)}
              style={{position: 'absolute', textAlign: 'center', marginLeft: '40px', zIndex:2}}
              >
                <i className='fas fa-pen '/>
            </button>
          </div>
          :''
        }
        <button type='button' className='basic-button' onClick={(e)=>handleClick(e)}>
          <ImageContainer
            src={thumbnail ? thumbnail: isProfileImage ? blankProfileImage: blankImage}
            width='150px' 
            height='150px' 
            className='' 
            alt='profile image preview'
          />
        </button>
      </div>
      { showFileSize && fileSize ?
        <div className="text-center"> 
          <div className={fileSize > maxFileSize ? 'color-danger text-bold':''}>
            {fileSize > maxFileSize ? `Size: ${(fileSize/maxFileSize).toFixed(2)} MB (file must be less than 1MB)`:''}
            {' '}
            <button className='btn btn-modal-blue' onClick={()=>removeFile()}>x remove</button>
          </div>
          <div>
            <small>(maximum upload size is {` ${maxFileSize/1000000}MB`})</small>
          </div>
        </div>
        :''
      }
    </div>
  );
};

ImageUpload.defaultProps = {
  isProfileImage: true,
  storeThumb: false,
  showFileSize: true,
  maxFileSize: 1000000
};

export default ImageUpload;