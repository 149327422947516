import {
    GET_CHILDS,
    GET_CHILD
  } from '../actions/types';
  
  const initialState = {
    children:[],
    child: null,
    childLoading: true,
    error: {}
  };
  
  export default function(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
      case GET_CHILDS:
        return {
          ...state,
          children: payload,
          childLoading: false
        };
      case GET_CHILD:
        return {
          ...state,
          child: payload,
          childLoading: false
        };
      default:
        return state;
    }
  }
  