import React, { Fragment } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import LoginPopup from './LoginPopup';
import RegisterPopup from './RegisterPopup';
import RegisterAndProfile from './RegisterAndProfile';
import { useHistory } from 'react-router-dom';

const AuthModals = ({ toggleLoginModal, loginModal, toggleRegisterModal, registerModal }) => {
  
  const history = useHistory();
  
  //Favorites/hosts must login/register
  const swapLoginRegister = () => {
    toggleLoginModal(!loginModal);
    toggleRegisterModal(!registerModal);
  };

  return (
    <Fragment>
      <Modal isOpen={loginModal} centered toggle={() => toggleLoginModal(!loginModal)}>
        <ModalBody className='text-center'>
          <div className='d-flex justify-content-end'>
            <button className="basic-button" onClick={() => toggleLoginModal(!loginModal)}>
            <i className='fa fa-times color-light-grey'/>
            </button>
          </div>
          <LoginPopup loginModal={loginModal} toggleLoginModal={toggleLoginModal}/>
          <button type='button' onClick={()=>swapLoginRegister()} className='basic-button text-underlined'>Don't have an account?<span className="text-bold">{' '}Sign up!</span></button>
        </ModalBody>
      </Modal>

      <Modal isOpen={registerModal} centered toggle={() => toggleRegisterModal(!registerModal)}>
        <ModalBody className='text-center'>
          <div className='d-flex justify-content-end'>
            <button className="basic-button" onClick={() => toggleRegisterModal(!registerModal)}>
            <i className='fa fa-times color-light-grey'/>
            </button>
          </div>
          <RegisterAndProfile />
          {/*
          <RegisterPopup registerModal={registerModal} toggleRegisterModal={toggleRegisterModal} history={history}/>
          */}
          <button type='button' onClick={()=>swapLoginRegister()} className='basic-button text-underlined'>Have an account?<span className="text-bold">{' '}Login</span></button>
        </ModalBody>
      </Modal>

    </Fragment>
  );
};

export default AuthModals;