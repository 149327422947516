import React,  { useState }  from 'react';
import {DayPicker} from 'react-day-picker';
import dayjs from 'dayjs';
import 'react-day-picker/dist/style.css';

const CalendarSelectDays = ({dateArray}) => {
    


  //NOT CURRENTLY USABLE - NEED TO UPDATE TO REACT-DAY-PICKER V8//



  const [selectedDay, setSelectedDay] = useState(dateArray);

  const footer = selectedDay ? (
    <p>You selected {dayjs(selectedDay).format('DD-MM-YYYY')}.</p>
  ) : (
    <p>Please pick a day.</p>
  );

    return (
      <div>
        <DayPicker
          mode="multiple"
          min={1}
          selected={selectedDay}
          onSelect={setSelectedDay}
          footer={footer}
        />
      </div>
    );
}

CalendarSelectDays.defaultProps = {
  dateInput: dayjs()
};

export default CalendarSelectDays;


// import React,  {  }  from 'react';
// import DayPicker, { DateUtils } from 'react-day-picker';
// import Spinner from '../layout/Spinner';
// import 'react-day-picker/dist/style.css';

// export default class CalendarSelectDays extends React.Component {
//   constructor(props) {
//     super(props);
//     this.handleDayClick = this.handleDayClick.bind(this);
//     this.state = {
//       selected: [],
//       isCalendarReady: false
//     };
//   }

//   // convertToDate(utc) {
//   //   const date = new Date(utc);
//   // }

//   componentDidMount() {
//     if (this.props.dateRange) {
//       const convertedDateArray = this.props.dateRange.map(utc => new Date(utc));

//       this.setState({
//         selected: convertedDateArray,
//         isCalendarReady: true
//       });
//     } else {
//       this.setState({
//         isCalendarReady: true
//       });
//     }
//   }  

//   handleDayClick(day, { sel }) {
//     const { selected } = this.state; 
//     if (sel) {
//       const selectedIndex = selected.findIndex(selectedDay =>
//         DateUtils.isSameDay(selectedDay, day)
//       );
//       selected.splice(selectedIndex, 1);
//     } else {
//       selected.push(day);
//     }
//     this.props.onDateChange(selected);
//   }

//   render() {
//     return !this.state.isCalendarReady ? (
//       <Spinner />
//     ) : (
//       <div>
//         <DayPicker
//           selected={this.state.selected}
//           onDayClick={this.handleDayClick}
//         />
//       </div>
//     );
//   }
// }
