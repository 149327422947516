const calculateTakehomePay = (num) => {
	const stripeTakeRate = 0.029;
	const stripeTakeAmt = 0.3;
	const myschoolTakeRate = 0.071;
	const myschoolTakeAmt = 0.4;
	const applicationFeeRate = myschoolTakeRate + stripeTakeRate;
	const applicationFeeAmt = myschoolTakeAmt + stripeTakeAmt;

    const stripeCut = num * stripeTakeRate + stripeTakeAmt;
    const myschoolCut = (num - stripeCut) * myschoolTakeRate + myschoolTakeAmt;

    let hostTakehome;
	
	if (num == 0) {
		hostTakehome = 0
	} else {
		hostTakehome = (num - (num * applicationFeeRate) - applicationFeeAmt).toFixed(2)
	}

    return hostTakehome;
}

export default calculateTakehomePay;