import React, { useState, Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, useHistory, useParams, Redirect } from 'react-router-dom';
import { setAlert } from '../../actions/alert';
import Spinner from '../layout/Spinner';
import { Alert, Container, Col, Row, Button, Form, Input, InputGroup, InputGroupAddon, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { getEventByEventId } from '../../actions/event';
import {loadStripe} from '@stripe/stripe-js';
import {
  Elements
} from '@stripe/react-stripe-js';
import CheckoutEventPayment from './CheckoutEventPayment';
import CheckoutDropOffEventRegistrationChild from './CheckoutDropOffEventRegistrationChild';
import ChildRegCard from './ChildRegCard';
import LogoImg from '../../images/logo100-100.svg';
import axios from 'axios';
import api from '../../utils/api';

//set appropriate stripe publishable api key as per environment
let stripePublishableKey;
let stripePromise;

{ if (process.env.NODE_ENV === 'production') {
    stripePublishableKey = 'pk_live_FHNDDvhgvHgEyV7P5ZgoTMXN00rCtnOVyn';
  } else {
    stripePublishableKey = 'pk_test_yQJvwwUlfBm2IlgmcUgRgjEp00FMG97sdP';
  }
  stripePromise = loadStripe(stripePublishableKey);
}  

const CheckoutDropOffEventWrapper = ({ getEventByEventId, event: {event, eventLoading}, auth, setAlert }) => {
  
  const history = useHistory();
  const params = useParams();

  const [ displayRegistrationSummary, toggleRegistrationSummary ] = useState(true);
  const [ displayChildRegistrationForm, toggleChildRegistrationForm ] = useState(false);
  const [ displayPaymentForm, togglePaymentForm ] = useState(false);
  const [ displayModalBooking, toggleModalBooking ] = useState(false);

  const showChildRegForm = () => {
    toggleChildRegistrationForm(true);
  }

  const [checkoutFormData, setCheckoutFormData] = useState({
    checkoutEmail: '',
    donationAmount: 0
  });

  const [regChildFormData, setRegChildFormData] = useState({
    childName: '',
    childAge: '',
    parentalConsent: false,
    parentName: '',
    regEmail: '',
    phone:'',
    needsChaperone: false
  });

  const onCheckoutChange = e =>
    setCheckoutFormData({ ...checkoutFormData, [e.target.name]: e.target.value });

  const onChildRegistrationChange = e =>
    setRegChildFormData({ ...regChildFormData, [e.target.name]: e.target.value });
  const onChildRegistrationCheckChange = e =>
    setRegChildFormData({ ...regChildFormData, [e.target.name]: e.target.checked });
  const [ savedChildFormData, setSavedChildFormData ] = useState([]);

  const deleteSavedChild = (index) => {
    if (index > -1) {
      // 1. Make a shallow copy of the child array
      let copySavedChildren = [...savedChildFormData];
      // 2. Remove deleted child obj
      copySavedChildren.splice(index, 1);
      // 3. Set the state to revised version of the copy
      setSavedChildFormData(copySavedChildren);
    }
  };
  
  const validateChildForm = async () => {
    try {
      const configReg = { headers: {'Content-Type': 'application/json' } };
      const res = await axios.post('/api/events/validateDropOffRegistration', regChildFormData, configReg);
      if (res.status === 200) {
        // Validation succeeded, save to array
        setSavedChildFormData(savedChildFormData => [...savedChildFormData, regChildFormData]);
        setRegChildFormData({
          childName: '',
          childAge: '',
          parentalConsent: false,
          parentName: '',
          regEmail: '',
          phone:''
        });
        toggleChildRegistrationForm(false);
      }
      // console.log(res.status);
    } catch (err) {
      const errors = err.response.data.errors;
      if (errors) {
        window.scrollTo(0,0);
        errors.forEach(error => setAlert(error.msg, 'danger'));
      }
    }
  };

  const backToRegistration = () => {
    togglePaymentForm(false);
    toggleRegistrationSummary(true);
  };

  const goToConfirmPayment = (e) => {
    e.preventDefault();
    toggleModalBooking(true);
  };

  const togglePaymentModal = () => {
    toggleModalBooking(!displayModalBooking)
  }

  const [ submittingWOPayment, setSubmittingWOPayment] = useState(false);
  const [ noPaymentBookingSucceeded, setNoPaymentBookingSucceeded ] = useState(false);
  const [ noPaymentBookingFailed, setNoPaymentBookingFailed ] = useState(false);
  
  const bookWithoutDonation = async () => {
    setSubmittingWOPayment(true);
    try {
      const configReg = {headers: {'Content-Type': 'application/json'}};

      const logChildBookings = await api.post(`/eventRegistration/child/${params.eventId}`, savedChildFormData, configReg);
      // console.log(logChildBookings);

      if (logChildBookings.status === 200) {
        setSubmittingWOPayment(false);
        setNoPaymentBookingSucceeded(true);
      }
    } catch (err) {
      setSubmittingWOPayment(false);
      setNoPaymentBookingFailed(true);
      // const allRegData = {...savedChildFormData };
      // console.log(allRegData);
      // errInLoggingBookings = await axios.post(`/api/events/bookingError/${params.eventId}`, allRegData, configReg);
    }
    setTimeout(() => {
      history.goBack();
    }, 20000);
  };

  //Ensure that if a donation is made, it's over $1
  const [ disableReviewAndPay, setDisableReviewAndPay ] = useState(false);
  const [ disableReviewAndPayMsg, setDisableReviewAndPayMsg ] = useState(null);

  const donateGTEOne = (donation) => {
    if (donation*1 > 0 && donation*1 < 1) {
      console.log('donation amount err: ',donation*1);
      setDisableReviewAndPay(true);
      setDisableReviewAndPayMsg('Donations must be greater than $1');
    } else {
      console.log('donation amount: ',donation*1);
      setDisableReviewAndPay(false);
      setDisableReviewAndPayMsg(null);
    }
  }

  const [donationAlert, setDonationAlert] = useState(true);

  useEffect(() => {
    getEventByEventId(params.eventId);
  }, []);

  useEffect(() => {
    setCheckoutFormData({
        ...checkoutFormData,
        checkoutEmail: savedChildFormData[0] && savedChildFormData[0].regEmail ? savedChildFormData[0].regEmail : ''
      });
  }, [savedChildFormData]);


  if(event && (event.isOffSite || !event.isDropOff)) {
    return <Redirect to={`/events/${event._id}`} />;
  }

  return eventLoading || event === null ? (
    <Spinner />
  ) : (
    <Fragment>
      <Container>
      { displayRegistrationSummary ? 
          <Fragment>
            <h2 className='color-myschool-blue my-3'>Registration</h2>
            <Row>
              <Col md={6}>
                <button className="btn btn-myschool-blue" onClick={()=>showChildRegForm()}>+ Add Child</button>
                <h4 className='color-myschool-blue my-2'>
                  Registration Summary:
                </h4>
                <div>
                  <div className='text-bold'>
                    Children
                  </div>
                  { savedChildFormData && savedChildFormData.length > 0 ?
                      savedChildFormData.map((child, i) => <ChildRegCard child={child} key={i} index={i} deleteSavedChild={deleteSavedChild}/>)
                      :<div className='text-danger'>Please add at least one child</div>
                  }
                </div>
              </Col>
              { displayChildRegistrationForm ? 
                <Col md={6}>
                  <CheckoutDropOffEventRegistrationChild 
                    event={event}
                    regFormData={regChildFormData}
                    onChildRegistrationChange={onChildRegistrationChange}
                    onChildRegistrationCheckChange={onChildRegistrationCheckChange}
                    validateChildForm={validateChildForm}
                    toggleChildRegistrationForm={toggleChildRegistrationForm}
                  />
                </Col>
                : ''
              }
            </Row>
            <div className='my-3'>
              <button type='button' className='btn btn-myschool-blue' 
                onClick={
                  event.suggestDonation ? 
                  (
                    ()=>{
                      togglePaymentForm(true);
                      toggleRegistrationSummary(false);
                    }
                  )
                  :
                  (
                    ()=>{
                      toggleModalBooking(true);
                      toggleRegistrationSummary(false);
                    }
                  )
                } 
                disabled={ savedChildFormData.length < 1 }>
                On to Payment
              </button>
            </div>
          </Fragment>
        : ''
      }
   

      { displayPaymentForm ? 
          <Fragment>
            <h2 className='color-myschool-blue'> Payment</h2>
            <Alert className='alert-myschool-red' isOpen={donationAlert} toggle={()=>setDonationAlert(!donationAlert)}>
              This is a free class, but the host works hard and so do we!  If you'd like to make a donation, please indicate that in the form below. <i className="far fa-smile color-white"/>
            </Alert>
            <Form onSubmit={goToConfirmPayment}>
              { event.suggestDonation && event.donation ?
                  <Fragment>
                    Suggested Donation:{' $'}
                    {event.donation}
                    {' per person / $'}
                    {event.donation * (savedChildFormData.length)}
                    {' total.'}
                  </Fragment>
                  :''
              }
              <div>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">$</InputGroupAddon>
                  <Input type="number" 
                  name="donationAmount" 
                  id="donationAmount"
                  value={checkoutFormData.donationAmount}
                  min={0}
                  step="0.01"
                  onChange={e => onCheckoutChange(e)} 
                  required/>
                </InputGroup>
              </div>
              <div className='text-danger text-bold'>
                { disableReviewAndPayMsg }
              </div>
              <div className='my-3'>
                <Button type='submit' disabled={disableReviewAndPay} className='float-right btn-myschool-blue'>Review and Pay</Button>
                <Button type='button' onClick={backToRegistration}>Back to Registration</Button>
                </div>
            </Form>
          </Fragment>
        : ''
      }
      </Container>

      <Modal isOpen={displayModalBooking} toggle={togglePaymentModal}>
        <ModalHeader> 
          <img src={LogoImg} height="20" width ="20" alt='MySchool Logo'/><small> MySchoolatHome.io</small>
        </ModalHeader>
        <ModalBody className='stripeCheckout'>
          <h4 className='text-center text-color-blue'>{event.title}</h4>
          <div className='text-center'>
            Host: { 
              event.provider && event.provider.providerName ?
              event.provider.providerName
              : event.profile && event.user && event.user.username ?
              event.user.username
              :''
            }
          </div>
          <div className='text-center'>Total:{' '} 
            { event.suggestDonation ?
              <Fragment>
                Free!
                <div>{`Generous Donation: $${checkoutFormData.donationAmount}`}</div>
              </Fragment>
              :
              <Fragment>
                {`$`}{event.costChild * savedChildFormData.length}
              </Fragment>
            }
          </div>
          <hr/>
          { event.suggestDonation && checkoutFormData.donationAmount*1 === 0 ? 
            <form>
              <button
                disabled={submittingWOPayment || noPaymentBookingSucceeded || noPaymentBookingFailed}
                id="submitWOPayment"
                type='button'
                onClick={bookWithoutDonation}
              >
                <span id="button-text">
                  {submittingWOPayment ? (
                    <div className="spinner" id="spinner"></div>
                  ) : (
                    "Confirm booking without donation"
                  )}
                </span>
              </button>
              {/* Show any error that happens when processing the payment */}
              {noPaymentBookingFailed ? (
                <div className="card-error" role="alert">
                  Sorry, but we were unable to complete this booking.  Please try again.
                  <div>If the problem persists, please send us an <Link className='text-underline text-bold' to='/contact'>email</Link>.</div>
                  <div className='blinker'>You are being redirected...</div>
                </div>
              ):''}
              {/* Show a success message upon completion */}
                <div className={noPaymentBookingSucceeded ? "result-message text-center" : "result-message hidden"}>
                  <div className='text-bold text-1-5rem my-2'>Success!</div> You will receive a confirmation email shortly from MySchool.  
                  <div><strong>Gmail users, check your "Promotions"</strong></div>
                  Your host will reach out soon with more details for the upcoming event! If you do not receive an email within 24 hrs, please contact us. 
                  <div className='text-center color-myschool-blue'>
                    <button type='button' onClick={()=>history.goBack()} className='text-underline'>Go back now</button>
                    <div className='blinker font-size-0_7rem'>You will be automatically redirected momentarily...</div>
                  </div>
                </div>
            </form>
            :
            <Elements stripe={stripePromise}>
              <CheckoutEventPayment eventId={params.eventId} checkoutFormData={checkoutFormData} savedChildFormData={savedChildFormData} history={history} userId={auth.user._id} />
            </Elements>
          }
        </ModalBody>
      </Modal>

    </Fragment>
  );
};

CheckoutDropOffEventWrapper.propTypes = {
  event: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  setAlert: PropTypes.func.isRequired,
  getEventByEventId: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  event: state.event,
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { getEventByEventId, setAlert }
)(CheckoutDropOffEventWrapper);



