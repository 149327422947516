import React, { Fragment } from 'react';
import { Card, CardBody, Row, Col, Badge, Modal, ModalHeader, ModalBody } from 'reactstrap';
import Spinner from '../../layout/Spinner';
import { useHistory } from 'react-router-dom';

const MySchoolSpot = ({ spot }) => {  
  
  const history = useHistory();

  const entryData = (
    <Row className='align-items-center'>
		<Col xs={3}>
			{spot && spot.image ? 
				<img src={spot.image} width="100" alt="activity image"/>
				:
				<div>
					<svg height="100" width="100">
					   <circle cx="50" cy="50" r="40" stroke="black" strokeWidth="0" fill="#375e97" />
					</svg>
					<div style={{position:"absolute", top:"40px",left:"47px"}} className="text-color-white text-bold text-uppercase">
						Spot
					</div>
				</div>
			}
	  	</Col>
	  	<Col xs={9}>
	  		<div className="text-1-2rem">
				{spot && spot.name}
			</div>
	  	</Col>
    </Row>
  );

  return !spot ? (
    <Spinner />
    ) : (
    <Fragment> 
      <div className='my-3 mx-2'/>
      <Card onClick={()=>history.push(`/myschoolplus/visit/authority/${spot._id}`)} className="cursor-pointer">               
        <CardBody>
            <div>
              {entryData}
            </div>
        </CardBody> 
      </Card>
    </Fragment>
  )  

};

export default MySchoolSpot;