import React, {useState} from 'react';
import ArtifactCard from './ArtifactCard';

const ArtifactsWrapper = () => {

	const artifactExample1 = {
		_id:"629f58bd5a90c063555e677a",
		image:"https://www.usna.edu/100Objects/_files/images/thumb_01.jpg",
		video:"https://www.youtube.com/embed/SYT2o-Iehk0?start=87",
		question:"What is pictured here?",
		category:"history",
		answer:"The Crypt of John Paul Jones",
		authority: {
			_id: "622a28d16d69183284bbbb5c",
			name: "United States Naval Academy Museum at Preble Hall"
		}
	};

	const artifactExample2 = { 
		_id: "629f676cf69af686bf2ab3d1",
		image: "https://www.usna.edu/100Objects/_files/images/obj_51_photo.jpg",
		video: "https://www.youtube.com/embed/h5njTVJhIII",
		question: "What is pictured here?",
		category:"history",
		answer: "An Enigma Machine from WWII",
		authority: {
			_id: "622a28d16d69183284bbbb5c",
			name: "United States Naval Academy Museum at Preble Hall"
		}
	}

	const artifactExample3 = {
		_id: "629f69d2f69af686bf2ab3d2",
		image: "https://www.usna.edu/100Objects/_files/images/obj_09_photo.jpg",
		imagePadding:[0,90],
		video: "https://www.youtube.com/embed/L5bEBQEJVLY",
		question: "What is this nautical navigation tool?",
		category:"history",
		answer: "A sextant",
		description: "Before the advent of GPS, how did sailors navigate across the open ocean? They used tools like this sextant.",
		authority: {
			_id: "622a28d16d69183284bbbb5c",
			name: "United States Naval Academy Museum at Preble Hall"
		}
	}

	const artifactExample4 = {
		_id: "2",
		image: "https://msa.maryland.gov/msa/mdmanual/01glance/symbols/images/terp6a.jpg",
		question: "What is this creature?",
		category:"science",
		answer: "A Diamondback Terrapin",
		description: "This Terrapin's name is _____.  She is a carnivore, and her diet includes things like mollusks, insects, crustaceans, and small fish. It is the Maryland State Reptile and the mascot of the University of Maryland. ",
		authority: {
			_id: "6243634a4ab42913c8c69842",
			name: "Holt Park & Center for the Arts"
		}
	}

	const artifactExample5 = {
	  _id: "1",
	  image: "https://www.thesprucepets.com/thmb/aFIxaIpmlNJ_oEMe92x89HeExkM=/2048x1365/filters:fill(auto,1)/Ball-Python-59946ec7b501e80012911c00.jpg",
	  question: "What is this creature?",
	  category:"science",
	  answer: "A Ball Python",
	  description: "This ball python (or royal python) is named Regina (which means \"queen\"). She is native to Africa, and this non-venomous constrictor typically feeds on small mammals and birds. The name \"ball python\" refers to its tendency to curl into a ball when stressed or frightened.",
	  authority: {
			_id: "6243634a4ab42913c8c69842",
			name: "Holt Park & Center for the Arts"
		}
	}
	
	const artifactArray = [artifactExample1, artifactExample2, artifactExample3, artifactExample4, artifactExample5];
	return (
		<div>
			<div className="section-heading text-center" style={{paddingTop: "50px"}}>
                <h2>Your ArtiFACTS</h2>
            </div>
			<div className="container justify-content-between">
				<div className='artifact-card-flex-set'>
			        { artifactArray.map(artifact => (
			            <ArtifactCard 
			            	key={artifact._id} 
			            	artifact={artifact}
			            	/>
			          ))
			        }
		    	</div>
			</div>
		</div>
	)
}

export default ArtifactsWrapper;