import React, { useState, useEffect, Fragment } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { Link } from 'react-router-dom';
import api from '../../utils/api';
import ReactGA from 'react-ga';

const CheckoutEventPayment = ({ eventId, checkoutFormData, savedChildFormData, savedParentFormData, userId, history }) => {

  const [paySucceeded, setPaySucceeded] = useState(false);
  const [bookingSucceeded, setBookingSucceeded] = useState(false);
  const [stripeLoaded, setStripeLoaded] = useState(false);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [clientSecret, setClientSecret] = useState('');
  const [email, setEmail] = useState('');
  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    setEmail(checkoutFormData.checkoutEmail);
  },[]);

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    const createPaymentIntent = async () => {
      try {
        const body = JSON.stringify({...checkoutFormData, userId: userId, nChildren: savedChildFormData.length, nParents: savedParentFormData ? savedParentFormData.length : 0});
        // console.log(body);
        const config = {headers: {'Content-Type': 'application/json'}};
        const paymentIntent = await api.post(`/booking/create-payment-intent/event/${eventId}`, body, config);
        setClientSecret(paymentIntent.data.clientSecret);
      } catch (err) {
        console.error(err);
        let errorMsg;
        if (err.response && err.response.data && err.response.data.issue && err.response.data.issue === 'event_full') {
          errorMsg = err.response.data.msg;
          setLoadingMsg(errorMsg);
          setWaitlist(true);
        } else if (err.response && err.response.data) {
          errorMsg = err.response.data.msg;
          setLoadingMsg(errorMsg);
        }
        setLoadingError(true);
      }
    };

    createPaymentIntent();

  }, []);

  const handleSubmit = async e => {
    e.preventDefault();
    setProcessing(true);
    const payload = await stripe.confirmCardPayment(clientSecret, {
      receipt_email: email,
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: e.target.name.value
        }
      }
    });
    

    if (payload.error) {
      setError(`Payment failed ${payload.error.message}`);
      setProcessing(false);
    } else {
      setError(null);
      setPaySucceeded(true);
    }

    const logBookings = async () => {
      let logChildBookings = {};
      let logParentBookings = {};
      const dateNow = new Date();
      let queryEmail = '';
      if (savedParentFormData && savedParentFormData.length > 0 && savedParentFormData[0] && savedParentFormData[0].regEmail) {
        queryEmail = `email=${savedParentFormData[0].regEmail}`
      }
      
      try {
        // console.log(savedChildFormData);
        //Log registration info to userBooking array
        const configReg = {headers: {'Content-Type': 'application/json'}};
        logChildBookings = await api.post(`/eventRegistration/child/${eventId}?${queryEmail}`, savedChildFormData, configReg);

        if (savedParentFormData && savedParentFormData.length > 0) {
          // console.log(savedParentFormData);
          logParentBookings = await api.post(`/eventRegistration/parent/${eventId}`, savedParentFormData, configReg);
        }
        ReactGA.event({ category: "event_registration", action: "registration_complete", label: `${event._id}`});
        setProcessing(false);
        setBookingSucceeded(true);
      } catch (err) {
        console.log(err);
        setError(`Booking Error: Please contact info@myschoolathome.io for support.`);
        const errorMsg = JSON.stringify(
          {error: `During event booking the payment succeeded but logging bookings failed:
            # of ChildBookings logged: ${logChildBookings.data.length}
            # of ParentBookings logged: ${logParentBookings.data.length}
            eventID: ${eventId}
            date: ${dateNow}
            `
          });
        await api.post(`/company/error`, errorMsg, {headers: {'Content-Type': 'application/json'}});
      }
    } 
    
    if (!payload.error) {
      logBookings();
    }
    
    setTimeout(() => {
      history.push(`/events/${eventId}`);
    }, 20000);
  };


  const handleChange = async (e) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(e.empty);
    setError(e.error ? e.error.message : "");
  };

  const cardStyle = {
    style: {
      base: {
        color: "#375e97",
        fontFamily: 'Poppins, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "14px",
        "::placeholder": {
          color: "#375e97"
        }
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a"
      }
    }
  };

  const [loadingError, setLoadingError] = useState(false);
  const [loadingMsg, setLoadingMsg] = useState(undefined);
  const [waitlist, setWaitlist] = useState(false);
  const [waitlistSucceeded, setWaitlistSucceeded] = useState(false);

  const logWaitlist = async () => {
    setProcessing(true);
    let logChildBookings = {};
    let logParentBookings = {};
    const dateNow = new Date();
    let queryEmail = '';
    if (savedParentFormData && savedParentFormData.length > 0 && savedParentFormData[0] && savedParentFormData[0].regEmail) {
      queryEmail = `email=${savedParentFormData[0].regEmail}`
    }
    
    try {
      // console.log(savedChildFormData);
      //Log registration info to userBooking array
      const configReg = {headers: {'Content-Type': 'application/json'}};
      logChildBookings = await api.post(`/eventRegistration/child/${eventId}?${queryEmail}&status=waitlist`, savedChildFormData, configReg);

      if (savedParentFormData && savedParentFormData.length > 0) {
        // console.log(savedParentFormData);
        logParentBookings = await api.post(`/eventRegistration/parent/${eventId}?status=waitlist`, savedParentFormData, configReg);
      }
      ReactGA.event({ category: "event_registration", action: "registration_complete", label: `${event._id}`});
    } catch (err) {
      console.log(err);
      setError(`Booking Error: Please contact info@myschoolathome.io for support.`);
      const errorMsg = JSON.stringify(
        {error: `During event booking the payment succeeded but logging bookings failed:
          # of ChildBookings logged: ${logChildBookings.data.length}
          # of ParentBookings logged: ${logParentBookings.data.length}
          eventID: ${eventId}
          date: ${dateNow}
          `
        });
      await api.post(`/company/error`, errorMsg, {headers: {'Content-Type': 'application/json'}});
    }
    

    if (logChildBookings.status == 200 && logParentBookings.status == 200) {
      setError(null);
      setProcessing(false);
      setWaitlistSucceeded(true);
    }
    
    setTimeout(() => {
      history.push(`/events/${eventId}`);
    }, 10000);
  };

  return !clientSecret ? 
    (
      <Fragment>
        { !loadingError ?
            <div>...loading</div>
          :
          loadingError && waitlist === true && waitlistSucceeded === false ? 
            <button 
              type='button' 
              className='basic-button text-underlined' 
              onClick={()=>logWaitlist()}>
              {processing ? (
                  <div className="spinner" id="spinner"></div>
                ) : (
                  `${loadingMsg} Click to join the waitlist! You will NOT be charged.`
                )}
            </button>
          : loadingError && waitlist === true && waitlistSucceeded === true ? 
            <button 
              type='button' 
              className='basic-button text-underlined' 
              onClick={()=>history.goBack()}
              disabled={processing}>
              <span id="button-text">
                {processing ? (
                  <div className="spinner" id="spinner"></div>
                ) : (
                  `Success! You're on the waitlist! Click to go back to the event listing.`
                )}
              </span>
            </button>
          :
            <button 
              type='button' 
              className='basic-button text-underlined' 
              onClick={()=>history.goBack()}>
              {!loadingMsg ? 'Error: Go back to event listing': `${loadingMsg} Go back to listing.`}
            </button>
        }
      </Fragment>
    ):(
      <Fragment>
        <div className="stripeCheckout">
          <form id="payment-form" onSubmit={handleSubmit}>
            <input
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter email address"
            />
            <CardElement id="card-element" options={cardStyle} onChange={handleChange} />
            <button
              disabled={!stripe || processing || disabled || paySucceeded || bookingSucceeded}
              id="submit"
            >
              <span id="button-text">
                {processing ? (
                  <div className="spinner" id="spinner"></div>
                ) : (
                  "Pay"
                )}
              </span>
            </button>
            {/* Show any error that happens when processing the payment */}
            {error && (
              <div className="card-error" role="alert">
                {error}
                <div>If the problem persists, please send us an <Link className='text-underline text-bold' to='/contact'>email</Link>.</div>
              </div>
            )}
            {/* Show a success message upon completion */}
            <div className={paySucceeded && bookingSucceeded ? "result-message text-center" : "result-message hidden"}>
              <div className='text-bold text-1-5rem my-2'>Success!</div> You will receive a confirmation email shortly from MySchool.  
              <div><strong>Gmail users, check your "Promotions"</strong></div>
              Your host will reach out directly as needed for your upcoming event!
              <div className='text-center'>
                <Link to='/events' className='mt-3 mb-2'>Back to the event map</Link>
                <div className='blinker font-size-0_7rem'>You will be automatically redirected momentarily...</div>
              </div>
            </div>
          </form>
        </div>
      </Fragment>
  );
};


export default CheckoutEventPayment;



