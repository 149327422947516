import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Col, Row, Button, Form, FormGroup, Label, Input, Spinner, Modal, ModalBody } from 'reactstrap';
import ImageContainer from '../../layout/ImageContainer';
import QRScan from '../../../images/QR_scan_to_portfolio.gif';
import getFree from '../../../images/scavengerHunt_getMySchoolPlusFree.gif';
import CopyTextButton from '../../../utils/CopyTextButton';
import AreYouReady from './AreYouReady';
import Prizes from './Prizes';

const HowToPlay = ({history}) => {

    const [ data, setData ] = useState({
        heading: "How To Play",
        subHeading1_1: "Just scan a QR code at any MySchool partner location, and MySchool will keep track of all the places you go to learn! Anyone can play and build their child's portfolio, and each scan from a MySchool+ member is a chance to win educational prizes, including a",
        subHeading1_2: "$1000 grand prize!",
        heading2: "What does it cost?",
        subHeading2_1: "The game is totally free for any families to collect and track your spots. Prizes are only available for MySchool+ members, but",
        subHeading2_2: "you can score a free year of MySchool+ by sharing your unique referral link!"
    });

    const [showBigGif, setShowBigGif] = useState(false);
    
    const [partners, setPartners] = useState([
        {name:"American Visionary Art Museum", link: "/resource/61a4fa5a7f0be20e506692f0"},
        {name:"Baltimore Museum of Industry", link: "/provider/61fd6db09a12000e23a3b2fe"},
        {name:"Benjamin Banneker Park & Museum", link: "/resource/5fc7e47f112d8d0ea98d09b5"},
        {name:"Cromwell Valley Park - Willow Grove Nature Center", link: "/resource/61e5cefa78229659880ff687"},
        {name:"Fire Museum of Maryland", link: "/provider/61893d69a4e9340e50d3c595"},
        {name:"Hammond-Harwood House Museum", link: "/resource/5fa9a4eda506f80e8622cf96"},
        {name:"Holt Park & Center for the Arts", link: "/provider/6220d3c2ee00cd0e5abe82aa"},
        {name:"Irvine Nature Center", link: "/provider/5dbccf5192a789416c077c80"},
        {name:"Lillie Carroll Jackson Civil Rights Museum", link: "/resource/6230ab048f28010e4222a2bb"},
        {name:"Marshy Point Nature Center", link: "/resource/61152cb18067a60e39d1f87c"},
        {name:"Maryland Center for History and Culture", link: "/resource/5f88505771cb9e0ea741b5c0"},
        {name:"United States Naval Academy Museum at Preble Hall", link: "/resource/6213c87f520fa80e49e659b4"},
        {name:"Walters Art Museum", link: "/resource/5e5d681d4c24a03b78637d3e"}
    ]);

    const [ seeIfReady1, setSeeIfReady1 ] = useState(false);

    return (
        <section className="section discover-area overflow-hidden ptb_50">
            <div className="container">
                <div className="row justify-content-around align-items-center mb-4">
                    <div className="col-12 col-lg-7 order-2">
                        <div className="service-text pt-3 pt-lg-0">
                            <h2 className="text-capitalize mb-4">{data.heading}</h2>
                            <p className='my-3'>{data.subHeading1_1}{' '}
                                <span className='text-bold'>
                                    {data.subHeading1_2}
                                </span>
                            </p>
                        </div>
                    </div>
                    <div className="col-12 col-lg-5 order-1">
                        <div className="service-thumb mx-auto cursor-pointer" onClick={()=>setShowBigGif(true)}>
                            <ImageContainer
                              src={QRScan}
                              thumb={QRScan}
                              className='d-inline d-lg-none' 
                              alt='map snippet with personal profile shown'
                            />
                            <ImageContainer
                              src={QRScan}
                              thumb={QRScan}
                              className='d-none d-lg-inline' 
                              alt='map snippet with personal profile shown'
                            />
                        </div>
                        <div className='text-center'>
                            (Click on the video to enlarge it)
                        </div>
                    </div>
                </div>

                <div className="my-5">
                    <h2 className="text-center">
                        Prizes
                    </h2>
                    <Prizes />
                </div>

                <div className="row justify-content-around align-items-center mb-4 mt-5">
                    <div className="col-12 col-lg-7 order-1">
                        <div className="service-text pt-3 pt-lg-0">
                            <h2 className="text-capitalize mb-4">{data.heading2}</h2>
                            <p className='my-3'>
                                <span className='text-bold text-1-1rem'>
                                    It's Free!
                                </span>
                                {' '}{data.subHeading2_1}{' '}
                                <span className='text-bold'>
                                    {data.subHeading2_2}
                                </span>
                            </p>
                        </div>
                    </div>
                    <div className="col-12 col-lg-5 order-2">
                        <div className="service-thumb mx-auto cursor-pointer" onClick={()=>setShowBigGif(true)}>
                            <ImageContainer
                              src={getFree}
                              thumb={getFree}
                              className='d-inline d-lg-none' 
                              alt='map snippet with personal profile shown'
                            />
                            <ImageContainer
                              src={getFree}
                              thumb={getFree}
                              className='d-none d-lg-inline' 
                              alt='map snippet with personal profile shown'
                            />
                        </div>
                        <div className='text-center'>
                            (Click on the video to enlarge it)
                        </div>
                    </div>
                </div>

                {seeIfReady1 ?
                    <AreYouReady />
                    :
                    <div className='d-flex justify-content-center'>
                        <div className='col-6'> 
                            <button className='btn block-button btn-myschool-red' onClick={()=>setSeeIfReady1(true)}>Sign up now!</button>
                        </div>
                    </div>
                }

                <div className="text-center my-5">
                    <h2>
                        Participating Locations
                    </h2>
                    <div>
                        {
                            partners.map((p,i)=> {
                                return (
                                    <div key={`partner-${i}`} className="my-3">
                                        <Link to={p.link} className="basic-button text-underlined">
                                            {p.name}
                                        </Link> 
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div>
                        <button type="button" onClick={()=>history.push("/homeschoolmap")} className="btn btn-myschool-blue">
                            See them on our map!
                        </button>
                    </div>
                </div>

                <div className='my-5'>
                    <h4>Additional Rules</h4>
                    <div>
                        Each time you scan a QR code between June 1 and August 31, 2022, you'll get another chance to win one of the prizes (including the $1000 grand prize)! All prizes will be given out by random lottery after the game concludes. The prize drawings will occur in September 2022. Entries are limited to one per partner location, per day. You may visit multiple partner locations on a single day, and you'll receive one qualifying entry per partner location. You may also visit the same partner location on multiple days throughout the game, and receive one qualifying entry per visit.  So the more visits, the more you and your kids get to explore, and the more chances you'll have to win!
                    </div>
                </div>

                <div>
                    <div>
                        <span className='text-bold'>Have more questions?</span>
                        {' '}
                        <a href="https://facebook.com/groups/myschoolathome" target='_blank' rel='noopener noreferrer' className="basic-button text-bold text-09rem text-underlined">
                            Join our Facebook group
                        </a>  
                        {' '}to learn more and give us your thoughts on how to improve the game!{' '}
                    </div>
                    <div>
                        <span className='text-bold'>Don't do Facebook?</span>{' '} Shoot us an email at{' '} 
                        <a href="mailto:info@myschoolathome.io" target='_blank' rel='noopener noreferrer'>
                            info@myschoolathome.io!
                        </a>
                        <CopyTextButton text={"info@myschoolathome.io"}/>
                    </div>
                </div>

            </div>

            <Modal isOpen={showBigGif} centered size="lg" toggle={() => setShowBigGif(!showBigGif)} className='modal-transparent'>
                <ModalBody className='text-center'>
                    <div className='float-right'>
                        <button className="btn btn-myschool-blue color-white" onClick={() => setShowBigGif(false)}>
                            Close{' '}<i className='fa fa-times color-white'/>
                        </button>
                    </div>
                    <div className='pt_75'/>
                    <ImageContainer
                      src={QRScan}
                      thumb={QRScan}
                      className='' 
                      alt='map snippet with personal profile shown'
                    />
                </ModalBody>
            </Modal>
        </section>
    );
}

export default HowToPlay;