import React, { useState, useEffect } from 'react'; 
import { Container, Table, Button, Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';

const SponsorTable = () => {

	const [ loginModal, toggleLoginModal ] = useState(false);
	const [ registerModal, toggleRegisterModal ] = useState(false);

	const regularPrice = 25;
	const discountArray = [0,0.15,0.2,0.3,0.35,0.4,0.5];
	const discountStepArray = [0,10,50,100,250,500,1000];

	let prices = [25, 21.25, 20, 17.5, 16.25, 15, 12.5]
	let priceSteps = [0, 212.5, 1000, 1750, 4062.5, 7500, 12500];

	const [planPrices, setPlanPrices] = useState([
		{
			itemDesc:'<10',
			price: regularPrice,
			savings: "-"
		},
		{
			itemDesc:'10+',
			price: ((1-discountArray[1])*regularPrice+0.00001).toFixed(2),
			savings: `${discountArray[1]*100}% Off!`
		},
		{
			itemDesc:'50+',
			price: (1-discountArray[2])*regularPrice,
			savings: `${discountArray[2]*100}% Off!`
		},
		{
			itemDesc:'100+',
			price: ((1-discountArray[3])*regularPrice+0.00001).toFixed(2),
			savings: `${discountArray[3]*100}% Off!`
		},
		{
			itemDesc:'250+',
			price: ((1-discountArray[4])*regularPrice+0.00001).toFixed(2),
			savings: `${discountArray[4]*100}% Off!`
		},
		{
			itemDesc:'500+',
			price: (1-discountArray[5])*regularPrice,
			savings: `${discountArray[5]*100}% Off!`
		},
		{
			itemDesc:'1000+',
			price: ((1-discountArray[6])*regularPrice+0.00001).toFixed(2),
			savings: `${discountArray[6]*100}% Off!`
		}
	]);

	const [budget, setBudget] = useState(213);
	const [membershipQty, setMembershipQty] = useState(10);

	const calculateMembershipQty = (bud) => {
		// console.log(bud*1);
		// console.log("pricestepCheck0",bud*1,priceSteps[1],bud*1 < priceSteps[1]);
		// console.log("pricestepCheck1",bud*1 >= priceSteps[1] && bud*1 < priceSteps[2])
		// console.log("pricestepCheck2",bud*1 >= priceSteps[2] && bud*1 < priceSteps[3])
		// console.log("pricestepCheck3",bud*1 >= priceSteps[3] && bud*1 < priceSteps[4])
		// console.log("pricestepCheck4",bud*1 >= priceSteps[4] && bud*1 < priceSteps[5])
		// console.log("pricestepCheck5",bud*1 >= priceSteps[5] && bud*1 < priceSteps[6])
		if (bud*1 < priceSteps[1]) {
			// console.log(bud*1,"<",priceSteps[1]," - memberships", prices[0]);
			setMembershipQty(Math.floor(bud*1/prices[0]))
		} else if (bud*1 >= priceSteps[1] && bud*1 < priceSteps[2]) {
			// console.log(priceSteps[1],"<",bud*1,"<",priceSteps[2]);
			setMembershipQty(Math.floor((bud*1/prices[1])))
		} else if (bud*1 >= priceSteps[2] && bud*1 < priceSteps[3]) {
			// console.log(priceSteps[2],"<",bud*1,"<",priceSteps[3]);
			setMembershipQty(Math.floor((bud*1/prices[2])))
		} else if (bud*1 >= priceSteps[3] && bud*1 < priceSteps[4]) {
			// console.log(priceSteps[3],"<",bud*1,"<",priceSteps[4]);
			setMembershipQty(Math.floor((bud*1/prices[3])))
		} else if (bud*1 >= priceSteps[4] && bud*1 < priceSteps[5]) {
			// console.log(priceSteps[4],"<",bud*1,"<",priceSteps[5]);
			setMembershipQty(Math.floor((bud*1/prices[4])))
		} else if (bud*1 >= priceSteps[5] && bud*1 < priceSteps[6]) {
			// console.log(priceSteps[5],"<",bud*1,"<",priceSteps[6]);
			setMembershipQty(Math.floor((bud*1/prices[5])))
		} else {
			// console.log(priceSteps[6],"<",bud);
			setMembershipQty((bud/prices[6]).toFixed(0))
		}
	}

  return(
		<Container>
			<div className='text-center'>
		      	<h4 className='text-color-blue text-2rem mb-4'>
		      		Yearly Pricing
		      	</h4>
	      	</div>
			<Table borderless striped className='table-basic plan-table'>
    		<thead>
    			<tr>
					<th>&nbsp;</th>
					<th>&nbsp;</th>
					<th>&nbsp;</th>
				</tr>
    		</thead>
    		<tbody className='plan-outline text-075rem text-color-blue'>
    			<tr>
					<td className='text-1-1rem text-bold'># of memberships</td>
					<td className='text-1-1rem text-bold text-center'>Price/membership</td>
					<td className='text-1-1rem text-bold text-center color-success'>Savings</td>
				</tr>
					{planPrices.map((item,i)=> {
						return (
							<tr key={i} style={{height:'2.5rem'}}>
								<td style={{width:'30%'}} className="text-bold text-1-05rem">
									{item.itemDesc}
								</td>
								<td style={{width:'40%'}} className='text-center text-bold text-1-05rem'>
									${item.price}
								</td>
								<td style={{width:'30%'}} className='text-center text-bold text-1-05rem color-success'>
									{item.savings}
								</td>
							</tr>
						)
					})}
				</tbody>
    	</Table>
    	<div className="mt-5 text-center">
	    	<h4 className="mt-3 mb-4">Membership Calculator</h4>
	    	<div>
	    		Enter budget ($)
	    		<input type='number' name="budget" onChange={e=>setBudget(e.target.value)} value={budget} className="calculator-input"/>
	    		<button onClick={()=>calculateMembershipQty(budget)} type="button" className="btn btn-myschool-blue-border">{` recalculate `}</button>
	    		<input type='number' name="output" value={membershipQty} className="calculator-input" readOnly/>
	    		memberships
	    	</div>
		</div>
		</Container>
  );
}

export default SponsorTable;