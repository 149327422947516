import React, { useEffect, useState, Fragment } from "react";
import { Card, CardBody, Form, Row, Col, Label, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Spinner } from 'reactstrap';
import dayjs from 'dayjs';
import MySchoolSpot from './MySchoolSpot';
import MySchoolActivity from './MySchoolActivity';
import api from '../../../utils/api';

const MyChildSchoolResources = ({child}) => {
  
  const [activities, setActivities] = useState([]);
  const [spots, setSpots] = useState([]);
  const [resourcesReady, setResourcesReady] = useState(false);

  const getChildResources = async () => {
    const childResources = await api.get(`/child/mySchoolResources/${child._id}`);
    setSpots(childResources.data.spots);
    setActivities(childResources.data.activities);
    setResourcesReady(true);
  }

  useEffect(() => {
    getChildResources();
  },[child]);

  return (
    <div>
      <div>
        {spots && spots.length > 0 ?
          <div className='my-5'>
            <h4 className='text-center'>Your Spots</h4>
            {spots.map((s,i)=>{
              return <MySchoolSpot key={`spot-${i}`} spot={s}/>
            })}
          </div>
          :
          <div className='my-5'>
            You don't have any MySchool spots for this child yet. 
          </div>
        }
      </div>

      <div>
        {activities && activities.length > 0 ?
          <div className='my-5'>
            <h4 className='text-center'>Your Activities</h4>
            {activities.map((a,i)=>{
              return <MySchoolActivity key={`activity-${i}`} activity={a}/>
            })}
          </div>
          :
          <div className='my-5'>
            Select curriculum resources you use to make entry easier! (currently in beta - email us for early access!)
            <div>
              <Card onClick={()=>history.push(`/myschoolplus/activity/631f5275c994544dee46bf8e`)} className="cursor-pointer">               
                <CardBody>
                    <div>
                      <Row className='align-items-center'>
                        <Col xs={3}>
                          <div>
                            <svg height="100" width="100">
                               <circle cx="50" cy="50" r="40" stroke="black" strokeWidth="0" fill="#FB6542" />
                            </svg>
                            <div style={{position:"absolute", top:"38px",left:"38px"}} className="text-color-white">
                              General
                            </div>
                          </div>
                        </Col>
                        <Col xs={9}>
                          <div className="text-1-2rem">
                            Manual Entry
                          </div>
                        </Col>
                      </Row>
                    </div>
                </CardBody> 
              </Card>
            </div>
          </div>
        }
      </div>
    </div>
  );
}

export default MyChildSchoolResources;

