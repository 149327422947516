import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, useParams, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import Spinner from '../layout/Spinner';
import { Card, CardHeader, CardTitle, CardText, Container, Modal, ModalBody } from 'reactstrap';
import { getMeetupByMeetupId } from '../../actions/meetup';
import formatDate from '../../utils/formatDate';
import LoginPopup from '../auth/LoginPopup';
import RegisterPopup from '../auth/RegisterPopup';
import ProfileItem from '../profiles/ProfileItem';
import ReactGA from 'react-ga';

const MeetupVirtual = ({ auth, getMeetupByMeetupId, meetupVirtual: {meetup, meetupLoading} }) => {

	const params = useParams();
  const history = useHistory();

  //Favorites must login modal
	const [ loginModal, toggleLoginModal ] = useState(false);
  const [ registerModal, toggleRegisterModal ] = useState(false);
  const swapLoginRegister = () => {
      toggleLoginModal(!loginModal);
      toggleRegisterModal(!registerModal);
  };

	useEffect(() => {
    if (auth && auth.user) {
      getMeetupByMeetupId(params.meetupId);
    }
	}, [auth]);

  useEffect(() => {
    if (auth && auth.user && auth.user.username) {
      ReactGA.event({ category: "Virtual_Meetups", action: "Page visit", label: `${auth.user.username}` });
    }
  }, [auth]);

  return meetupLoading || !meetup ? (
        <Spinner />
      ) : (
      <Fragment>
        <Container>
          <div className='d-flex'>
            <div className='p-2'>
              <Link to={`/meetups`} className='btn btn-myschool-blue'><i className="fas fa-arrow-left"/>{' '}Back to Find Events</Link>
            </div>
          </div> 
          <div>
            <h4 className="color-myschool-blue mt-3 mb-2">
              {`Virtual Meetup on ${formatDate(meetup.date, 'long')}`}
            </h4> 
          </div>
          { meetup && meetup.attendees && meetup.attendees.map(
            (attendee)=> (
              attendee.profile ?
              <ProfileItem key={attendee._id} profile={attendee.profile} user={attendee}/>
              :
              "No profile available"
            ))
          }
        </Container>

        <Modal isOpen={loginModal} centered toggle={() => toggleLoginModal(!loginModal)}>
          <ModalBody className='text-center'>
            <div className='float-right'>
              <button className="basic-button" 
                onClick={() => toggleLoginModal(false)}>
                <i className='fa fa-times color-light-grey'/>
              </button>
            </div>
            You have to register and login to do that!
            <LoginPopup loginModal={loginModal} toggleLoginModal={toggleLoginModal}/>
            <button onClick={()=>swapLoginRegister()} className='basic-button text-bold text-underlined'>I need to Register</button>
          </ModalBody>
        </Modal>

        <Modal isOpen={registerModal} centered toggle={() => toggleRegisterModal(!registerModal)}>
          <ModalBody className='text-center'>
            <div className='float-right'>
              <button className="basic-button" 
                onClick={() => toggleRegisterModal(false)}>
                <i className='fa fa-times color-light-grey'/>
              </button>
            </div>
            <RegisterPopup registerModal={registerModal} toggleRegisterModal={toggleRegisterModal} history={history}/>
            <button onClick={()=>swapLoginRegister()} className='basic-button text-bold text-underlined'>Go to Login</button>
          </ModalBody>
        </Modal>
      </Fragment>
  );
};


MeetupVirtual.propTypes = {
  meetupVirtual: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  getMeetupByMeetupId: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  meetupVirtual: state.meetupVirtual
});

export default connect(
  mapStateToProps,
  { getMeetupByMeetupId }
)(MeetupVirtual);
