import React, { Fragment } from "react";
import { Row, Col, Button, Form, FormGroup, CustomInput, Input, Label } from "reactstrap";


const AddChildBriefForm = ({ setFormData, formData, togglecheckGuardian, checkGuardian, toggle, onSubmit, isVisible, toggleIsVisible}) => {

  const onDataChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });


  return (
    <Fragment>
      <Form onSubmit={(e) => onSubmit(e)} className="msstd-form">
        <FormGroup>
          <Label>Child Name/Nickname</Label>
          <Input
            type="text"
            name="childNameData"
            id="childNameData"
            placeholder="Child Name"
            value={formData.childNameData}
            onChange={(e) => onDataChange(e)}
          />
        </FormGroup>
        <FormGroup>
          <Label>Birthday</Label>
          <Input
            type="date"
            name="childBirthDay"
            id="childBirthDay"
            value={formData.childBirthDay}
            onChange={(e) => onDataChange(e)}
          />
        </FormGroup>
        <Row>
          <Col sm="6">
            <FormGroup>
              <Label>Child's Gender</Label>
              <Input
                  type="select"
                  name="gender"
                  id="gender"
                  value={formData.gender}
                  onChange={(e) => onDataChange(e)}
                >
                <option value='na'>Rather not say</option>
                <option value='female'>Female</option>
                <option value='male'>Male</option>
                <option value='other'>Other</option>
              </Input>
            </FormGroup>
          </Col>
          <Col sm="6">
            <FormGroup>
              <FormGroup>
                <Label>*Show gender/age on your profile (eg "G-12")</Label>
                <CustomInput type="switch" 
                  id="isVisible" 
                  name="isVisible" 
                  checked={isVisible} 
                  onChange={() => toggleIsVisible(!isVisible)} 
                  label={isVisible ? "Show":"Hidden"} 
                />
              </FormGroup>
            </FormGroup>
          </Col>
        </Row>
        <FormGroup style={{ padding: "20px" }}>
          <Input
            type="checkbox"
            name="checkGuardian"
            id="checkGuardian"
            checked={checkGuardian}
            value={checkGuardian}
            onChange={() => togglecheckGuardian(!checkGuardian)}
          />
          <Label htmlFor="checkGuardian">
            I am the parent or guardian of this child (required!)
          </Label>
        </FormGroup>
        <FormGroup>
          <Button type="submit" onClick={() => toggle()} disabled={!checkGuardian}>
            Submit
          </Button>
        </FormGroup>
      </Form>
    </Fragment>
  );
};


export default AddChildBriefForm;