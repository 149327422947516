import React, { } from 'react';
import { Marker, Popup } from 'react-leaflet';
import { Link } from 'react-router-dom';
import capitalizeName from '../../utils/capitalizeName';
import dayjs from 'dayjs';

const PortfolioMapMarker = ({ entry, partnerIcon, auth, history }) => {

  let location = [-71,25];
  if (entry.authority && entry.authority.location && entry.authority.location.coordinates) {
    location = entry.authority.location.coordinates;
    console.log('point location set', location);
  } 

  const entryInfo = (
    <div>
      <div>
        {entry.authority && entry.authority.name ? <span className='text-bold'>{entry.authority.name}</span>:''}
      </div>
      <div>
        {entry.timestamp ? `Visited on ${dayjs().format("DD-MMM-YYYY")}`:''}
      </div>
    </div>
  );

  return (
    <Marker key={entry._id} icon={partnerIcon} position = {[location[1],location[0]]}>
      <Popup>
        {entryInfo}
      </Popup>
    </Marker>
  );
};

export default PortfolioMapMarker;