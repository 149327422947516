import React, { useState, Fragment, useEffect } from 'react';
import { Form, Spinner, Alert } from 'reactstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { register } from '../../actions/auth';
import { setAlert } from '../../actions/alert'; 
import RegisterForm from './RegisterForm';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';

const RegisterPopup = ({ register, auth: {isAuthenticated, user}, toggleRegisterModal, setAlert, alerts }) => {
	
	const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
    password2: ''
  });

  const { username, email, password, password2 } = formData;

  const [indivMailingListCheck, toggleIndivMailingListCheck] = useState(true);
  const [ isSubmitting, setIsSubmitting ] = useState(false);
  
  const onSubmit = async e => {
    e.preventDefault();
    ReactGA.event({ category: "registerModal", action: "submit", label: "registration_complete"});
    setIsSubmitting(true);
    if (password !== password2) {
      setAlert('Passwords do not match.', 'danger');
    } else {
      await register({ username, email, password, indivMailingListCheck });
    }
  };

	//Trigger Page reload once authenticated AND user is loaded.  If done prior to user loaded, Firefox will not store token correctly.
  useEffect(()=> {
    if (isAuthenticated && user && user._id) {
      // window.location.reload();
      toggleRegisterModal(false);
    }
    // return () => clearTimeout(timeoutID);
  },[isAuthenticated, user]);

  useEffect(()=> {
    setIsSubmitting(false);
  },[alerts]);

  return (
    <Fragment>
      <Form onSubmit={e => onSubmit(e)} className="msstd-form container">
        { alerts !== null && alerts.length > 0 && 
          alerts.map(alert => (
            <div key={alert.id} className={`modal-alert alert-modal-${alert.alertType}`}>
              {alert.msg}
            </div>
          ))
        }
        <h4> Register </h4>
        <h5 className='color-light mt-2'> Join our community to find yours! It's Free!</h5>
        <div><small><Link className='text-underline' to='/why-register'>Registration helps keep our community safe. Find out why.</Link></small></div>
          <div className='my-3'/>
          <RegisterForm 
            formData={formData} 
            setFormData={setFormData} 
            indivMailingListCheck={indivMailingListCheck} 
            toggleIndivMailingListCheck={toggleIndivMailingListCheck} 
          />
          <button
          disabled={isSubmitting}
          type="submit"
          className="btn btn-modal-blue btn-set-WH"
        >
          <span>
            {isSubmitting ? (
              <Spinner size="sm" className='color-white'/>
            ) : (
              "Register"
            )}
          </span>
        </button>
      </Form>          
    </Fragment>
  );
};

RegisterPopup.propTypes = {
  register: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
  alerts: PropTypes.array.isRequired,
  auth: PropTypes.object
};

const mapStateToProps = state => ({
	auth: state.auth,
  alerts: state.alert
});

export default connect(mapStateToProps, { register, setAlert })(RegisterPopup);
