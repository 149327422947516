import React, { useEffect, Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Container, Table } from 'reactstrap';
import PropTypes from 'prop-types';
import { getDetailedUserData } from '../../../actions/admin';
import Spinner from '../../layout/Spinner';
import formatDate from '../../../utils/formatDate';
import AdminRoutes from '../AdminRoutes';

const UserList = ({ getDetailedUserData, admin: {adminLoading, users}, auth: {user, loading}}) => {
  
  useEffect(() => {
    if (user) {
      getDetailedUserData();
    }
  }, [loading, user]); //ensure user data loaded before fetching, otherwise 401 unauthorized response

  const [sortedUsers, setSortedUsers] = useState([]);
  const [ sortBy, setSortBy ] = useState('profile_info');
  const [ ascToggle, toggleAsc ] = useState(true);
  const [ updateTableByPropLength, setUpdateTableByPropLength ] = useState(false);
  const [ updateTableByNum, setUpdateTableByNum ] = useState(false);

  useEffect(() => {
    if (users) {
      const sorted = users.sort((a, b) => b[sortBy] - a[sortBy]);
      setSortedUsers(sorted);
    }
  }, [users]);

  useEffect(() => {
    const sortTableByLength = () => {
      let arrayUsers = sortedUsers;
      if (ascToggle === true) {
        arrayUsers.sort((a, b) => parseInt(b[sortBy].length) - parseInt(a[sortBy].length));
      } else {
        arrayUsers.sort((a, b) => parseInt(a[sortBy].length) - parseInt(b[sortBy].length));
      }
      setSortedUsers(arrayUsers);
      setUpdateTableByPropLength(false);
    }
    if (updateTableByPropLength === true) {
      sortTableByLength();
    }
  }, [updateTableByPropLength]);

  useEffect(() => {
    const sortTableByNum = () => {
      let arrayUsers = sortedUsers;
      if (ascToggle === true) {
        arrayUsers.sort((a, b) => parseInt(b[sortBy]) - parseInt(a[sortBy]));
      } else {
        arrayUsers.sort((a, b) => parseInt(a[sortBy]) - parseInt(b[sortBy]));
      }
      setSortedUsers(arrayUsers);
      setUpdateTableByNum(false);
    }
    if (updateTableByNum === true) {
      sortTableByNum();
    }

  }, [updateTableByNum]);


  const sortByPropLength = (sortProp) => {
    toggleAsc(!ascToggle);
    setSortBy(sortProp);
    setUpdateTableByPropLength(true);
  };

  const sortByPropNum = (sortProp) => {
    toggleAsc(!ascToggle);
    setSortBy(sortProp);
    setUpdateTableByNum(true);
  };




  // const [ showProfileInfo, toggleShowProfileInfo ] = useState(false);
  // const [ showProviderInfo, toggleShowProviderInfo ] = useState(false);
  // const [ showEventsInfo, toggleShowEventsInfo ] = useState(false);

    return adminLoading || !users || !user || loading ? (
    <Spinner />
  ) : (
    <div>
      <AdminRoutes />
        <Table striped className='admin-table'>
          <thead>
            <tr> 
              <th>#</th>
              <th>UserId</th>
              <th>Date{' '}<button type='button' className='basic-button' onClick={()=>sortByPropNum('createdAt')}>#</button></th>
              <th>Username</th>
              <th>Email</th>
              <th>City, State</th>
              <th>Profile{' '}<button type='button' className='basic-button' onClick={()=>sortByPropLength('profile_info')}>#</button></th>
              <th>#Providers{' '}<button type='button' className='basic-button' onClick={()=>sortByPropLength('provider_info')}>#</button></th>
              <th>#Resource{' '}<button type='button' className='basic-button' onClick={()=>sortByPropLength('resource_info')}>#</button></th>
              {/*<th>#Events{' '}<button type='button' className='basic-button' onClick={()=>sortByPropLength('events_info')}>#</button></th>*/}
            </tr>
          </thead>
          <tbody>
            {sortedUsers
              .map((user, i)=>
              (
                <tr key={user._id}>
                  <td>{i+1}</td>
                  <td>{user._id}</td>
                  <td>{formatDate(user.createdAt)}</td>
                  <td>{user.username}</td>
                  <td>{user.email}</td>
                  <td>{user.profile_info.length > 0 ? `${user.profile_info[0].city}, ${user.profile_info[0].state}`:''}</td>
                  <td>{user.profile_info.length > 0 ? (
                    <Link className='basic-button text-10px text-bold text-underlined' to={`/profile/${user._id}`} target="_blank" rel="noopener noreferrer">Yes</Link>
                    )
                    : 'No'}
                  </td>
                  <td>{user.provider_info.length} </td>
                  <td>{user.resource_info.length} </td>
                  {/*<td>{user.events_info.length}</td>*/}
                </tr>
              ))
            }
          </tbody>
        </Table>
    </div>
  );
};

UserList.propTypes = {
  getDetailedUserData: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  admin: state.admin,
  auth: state.auth
});


export default connect(mapStateToProps, { getDetailedUserData })(UserList);


// event - <EventBookingListItem key={`list-${booking._id}`}  booking={booking}/>