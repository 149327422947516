import React, { Fragment, useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { updateProvider, getProviderByProviderId } from '../../actions/provider';
import { Container } from 'reactstrap';
import Spinner from '../layout/Spinner';
import ProviderForm from './ProviderForm';

const EditProvider = ({ updateProvider, getProviderByProviderId, provider: {providerLoading, provider} }) => {
  const history = useHistory();
  const params = useParams();

  const [formData, setFormData] = useState({
    category: '',
    providerName: '',
    email: '',
    address: '',
    city:'',
    state:'',
    zip:'',
    hideAddress: false,
    edPhil: '',
    edAndEx:'',
    parentInvolveLevel: '',
    parentInvolveNarr: '',
    meetingFreqNum: '',
    meetingFreqUnit: '',
    meetingFreqNarr: '',
    slotsTotal: '',
    slotsPrefilled: '',
    userMembers: '',
    subjectOfferings: '',
    guidelines: '',
    enrollFee: '',
    enrollFeeUnit: '',
    isDropOff: '',
    genInfo: '',
    ageStart: '',
    ageEnd: '',
    whenOperate: '',
    typicalDay: '',
    educationalLeader: '',
    entranceFee: '',
    religiousAffiliation: '',
    website: '',
    phone: '',
    subjects: [],
    tags: [],
    twitter: '',
    facebook: '',
    linkedin: '',
    youtube: '',
    instagram: ''
  });

  const [ tagData, setTagData ] = useState([]);

  const [ subjData, setSubjData ] = useState({
    math: false,
    english: false,
    science: false,
    'social studies': false,
    art: false,
    music: false,
    pe: false,
    health: false,
    history: false,
    'foreign language': false,
    engineering: false,
    'environmental science': false
  });

  useEffect(()=> {
    getProviderByProviderId(params.providerId);
  },[params]);

  let isTagsUpd = false;

  useEffect(() => {
  
    setFormData({
      ...formData,
        category: !providerLoading && provider && provider.category ? provider.category : '',
        providerName: !providerLoading && provider && provider.providerName ? provider.providerName : '',
        email: !providerLoading && provider && provider.email ? provider.email : '',
        address: !providerLoading && provider && provider.address ? provider.address : '',
        city: !providerLoading && provider && provider.city ? provider.city : '',
        state: !providerLoading && provider && provider.state ? provider.state : '',
        zip: !providerLoading && provider && provider.zip ? provider.zip : '',
        hideAddress: !providerLoading && provider && provider.hideAddress === true ? true : false,
        edPhil: !providerLoading && provider && provider.edPhil ? provider.edPhil : '',
        edAndEx: !providerLoading && provider && provider.edAndEx ? provider.edAndEx : '',
        paymentAccepted: !providerLoading && provider && provider.paymentAccepted ? provider.paymentAccepted : '',
        parentInvolveLevel: !providerLoading && provider && provider.parentInvolveLevel ? provider.parentInvolveLevel : '',
        parentInvolveNarr: !providerLoading && provider && provider.parentInvolveNarr ? provider.parentInvolveNarr : '',
        meetingFreqNum: !providerLoading && provider && provider.meetingFreqNum ? provider.meetingFreqNum : '',
        meetingFreqUnit: !providerLoading && provider && provider.meetingFreqUnit ? provider.meetingFreqUnit : '',
        meetingFreqNarr: !providerLoading && provider && provider.meetingFreqNarr ? provider.meetingFreqNarr : '',
        slotsPrefilled: !providerLoading && provider && provider.slotsPrefilled ? provider.slotsPrefilled : '',
        slotsTotal: !providerLoading && provider && provider.slotsTotal ? provider.slotsTotal : '',
        userMembers: !providerLoading && provider && provider.userMembers ? provider.userMembers : '',
        subjectOfferings: !providerLoading && provider && provider.subjectOfferings ? provider.subjectOfferings : '',
        guidelines: !providerLoading && provider && provider.guidelines ? provider.guidelines : '',
        enrollFee: !providerLoading && provider && provider.enrollFee ? provider.enrollFee : '',
        enrollFeeUnit: !providerLoading && provider && provider.enrollFeeUnit ? provider.enrollFeeUnit : '',
        isDropOff: !providerLoading && provider && provider.isDropOff === true ? true : false,
        genInfo: !providerLoading && provider && provider.genInfo ? provider.genInfo : '',
        ageStart: !providerLoading && provider && provider.ageStart ? provider.ageStart : '',
        ageEnd: !providerLoading && provider && provider.ageEnd ? provider.ageEnd : '',
        typicalDay: !providerLoading && provider && provider.typicalDay ? provider.typicalDay : '',
        whenOperate: !providerLoading && provider && provider.whenOperate ? provider.whenOperate : '',
        entranceFee: !providerLoading && provider && provider.entranceFee ? provider.entranceFee : '',
        educationalLeader: !providerLoading && provider && provider.educationalLeader ? provider.educationalLeader : '',
        religiousAffiliation: !providerLoading && provider && provider.religiousAffiliation ? provider.religiousAffiliation : '',
        website: !providerLoading && provider && provider.website ? provider.website : '',
        phone: !providerLoading && provider && provider.phone ? provider.phone : '',
        subjects: !providerLoading && provider && provider.subjects ? provider.subjects : '',
        twitter: !providerLoading && provider && provider.social && provider.social.twitter ? provider.social.twitter : '',
        facebook: !providerLoading && provider && provider.social && provider.social.facebook ? provider.social.facebook : '',
        linkedin: !providerLoading && provider && provider.social && provider.social.linkedin ? provider.social.linkedin : '',
        youtube: !providerLoading && provider && provider.social && provider.social.youtube ? provider.social.youtube : '',
        instagram: !providerLoading && provider && provider.social && provider.social.instagram ? provider.social.instagram : '',
      });
    if (!providerLoading && provider && provider.tags ) {
      isTagsUpd = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.providerId, provider, providerLoading]);
  
  //remove subjects from tag array (re-added on back end so they don't get doubly added)
  useEffect(()=> {
    let filteredTags = [];
    if (!providerLoading && provider && provider.subjects && isTagsUpd){
      filteredTags = provider.tags;
      for (let i=0; i < provider.subjects.length; i++) {
        const subj = provider.subjects[i].toString();
        // console.log(subj);
        filteredTags = filteredTags.filter(e => e !== subj);
      }
    }
    // console.log(filteredTags);
    setTagData(filteredTags);

  },[provider, providerLoading, isTagsUpd]);

  //Set Boolean values for subject inputs
  useEffect(() => {
    let allSubjects = {
      math: false,
      english: false,
      science: false,
      'social studies': false,
      art: false,
      music: false,
      pe: false,
      health: false,
      history: false,
      'foreign language': false,
      engineering: false,
      'environmental science': false
    }
    const setSubjectBooleans = () => {
      for (let i=0; i < provider.subjects.length; i++) {
        const subj = provider.subjects[i];
        allSubjects = Object.assign(allSubjects, {[subj]: true});
      }
    };

    if (!providerLoading && provider && provider.subjects && provider.subjects.length > 0) {
      setSubjectBooleans();
    }

    setSubjData(allSubjects);

  }, [provider, providerLoading]);

  const onSubjChange = e => 
    setSubjData({ ...subjData, [e.target.name]: e.target.checked });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const onSubmit = async e => {
    e.preventDefault();
    setIsSubmitting(true);
    const subjArray = Object.keys(subjData).filter(key=>subjData[key] === true);
    const allData = {...formData, subjects: subjArray, tags: tagData };
    await updateProvider(allData, history, params.providerId);
    setIsSubmitting(false);
  };


  return providerLoading || provider === null ? (
    <Spinner />
  ) : (
    <Fragment>
      <Container>
        <h1 className='large text-primary-ms'>Create Your Spot</h1>
        <small>* required fields</small>
        <ProviderForm
          formData={formData}
          setFormData={setFormData}
          subjData={subjData}
          setSubjData={setSubjData}
          tagData={tagData}
          setTagData={setTagData}
          isSubmitting={isSubmitting}
          onSubmit={onSubmit}
          onSubjChange={onSubjChange}
          history={history}
        />
      </Container>

    </Fragment>
  );
};

EditProvider.propTypes = {
  updateProvider: PropTypes.func.isRequired,
  getProviderByProviderId: PropTypes.func.isRequired,
  provider: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  provider: state.provider
});

export default connect(
  mapStateToProps,
  { updateProvider, getProviderByProviderId }
)(EditProvider);
