import React, { Fragment, useState } from 'react';
import EmailUsForm from './EmailUsForm';
import { Modal, ModalBody } from 'reactstrap';

const Contact = () => {

    const [ displayModalEmailer, toggleModalEmailer] = useState(false);

    return(
        <Fragment>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-md-10 col-lg-7">
                        {/* Section Heading */}
                        <div className="section-heading text-center">
                            <h2>Drop us a Line!</h2>
                            <p className="d-block mt-4">We love hearing about what you like, what you don't like, what would enrich your homeschooling experience, or you can just email us to say hi.</p>
                            <address className='my-3'>
                                <div>Baltimore, MD, USA</div>
                                <i className="fa fa-envelope"></i>: <a href="mailto:info@myschoolathome.io" target='_blank' rel='noopener noreferrer'>info@myschoolathome.io</a>
                            </address>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <button 
                        className='btn btn-myschool-blue btn-150-circle' 
                        onClick={()=>toggleModalEmailer(!displayModalEmailer)}
                        >
                        <i className="fa fa-envelope fa-2x"/>
                        <div className='my-1'>Email Us!</div>
                    </button>
                </div>
            </div>

            <Modal isOpen={displayModalEmailer} centered toggle={() => toggleModalEmailer(!displayModalEmailer)}>
                <ModalBody className='text-center'>
                    <button type='button' onClick={()=>toggleModalEmailer(false)} className='basic-button float-right'><i className="fas fa-times"/></button>
                    <h4>Great! What's on your mind?</h4>
                    <EmailUsForm toggleModalEmailer={toggleModalEmailer} />
                </ModalBody>
            </Modal>

        </Fragment>
    );
}

export default Contact;