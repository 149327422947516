import React, { Fragment, useState, useEffect } from 'react';
import { Row, Col, Card, CardBody, CardText, Button, Spinner } from 'reactstrap';
import capitalizeName from '../../../utils/capitalizeName';
import ProviderListItem from './ProviderListItem';
import ResourceListItem from './ResourceListItem';
import GenericListItem from './GenericListItem';
import iconMapPlus from '../../../images/icon-map-plus.svg';
import ReactGA from 'react-ga';

const HomeschoolMapListWrapper = ({ profile, resources, providers, auth, user, bounds, setBounds, setMapFlyTo, history, setAlert, toggleLoginModal, updateFavProvider, updateFavResource, setCurrentZoom, showMyFavs, isNoLocation, showResources, showProfiles }) => {

  const [ isSettingFav, toggleIsSettingFav ] = useState(false);

  const favoriteResourceHandler = async (resourceId) => {
    toggleIsSettingFav(true);
    if (!auth.isAuthenticated) {
      toggleLoginModal(true);
      toggleIsSettingFav(false);
    } else {
      await updateFavResource(resourceId);
      toggleIsSettingFav(false);
    }
  };

  const favoriteProviderHandler = async (providerId) => {
    toggleIsSettingFav(true);
    if (!auth.isAuthenticated) {
      toggleLoginModal(true);
      toggleIsSettingFav(false);
    } else {
      await updateFavProvider(providerId);
      toggleIsSettingFav(false);
    }
  };

  const [ isZoomedIn, toggleIsZoomedIn ] = useState(false);
  const zoomInOut = ([lat, long]) => {
    if (isZoomedIn) {
      toggleIsZoomedIn(!isZoomedIn);
      setCurrentZoom(10);
    } else {
      toggleIsZoomedIn(!isZoomedIn);
      setMapFlyTo([lat, long]);
      setCurrentZoom(17);
    }
  }

  const handleProfileCardVote = () => {
    ReactGA.event({ category: "HomeschoolMap", action: "Vote", label: `profile cards for ${user.user._id}` });
    localStorage.setItem('profileMapCardVote',true);
    setVotedForProfileCard(true);
  };
  const [votedForProfileCard, setVotedForProfileCard] = useState(false);

  useEffect(()=> {
    if (localStorage.getItem('profileMapCardVote')) {
      setVotedForProfileCard(true);
    };
  },[]);

  return (
    <div className='homeschoolmap-list'>
      <div className='my-2 text-center'>
        <div>
          <button type='button'
            className='btn btn-myschool-blue-border btn-map-filter'
            onClick={()=>history.push('/post-a-spot')}>
            <span className='d-xs-none'>Add a spot!{' '}</span><img src={iconMapPlus} alt="add spot to map icon" height="12px" width="12px"/>
          </button>
          <button type='button' onClick={()=>history.push('/resource-options')} className='basic-button text-underline text-07rem'>Learn about Spots!</button>
        </div>
        <div className='blinker'>
          <i className="fas fa-arrow-down color-myschool-red"/><span className='text-07rem'>Scroll over an item to center the map on that point.</span><i className="fas fa-arrow-down color-myschool-red"/>
        </div>
      </div>
      { isNoLocation ?
        <Card body inverse color="info" className='text-center mx-1'>
          Zoom in to your area and then click "Search in this area" to find spots there.  You can enable location services for this site to avoid this in the future.
        </Card>
        :
        showMyFavs === false && providers && providers.length < 1 && resources && resources.length < 1 ?
        <Card body inverse color="info" className='text-center mx-1'>
          Currently we don't have any spots nearby.  
          <button className='btn btn-myschool-yellow' 
            onClick={
              !auth.isAuthenticated ?
              ()=>toggleLoginModal(true)
              :
              ()=>history.push('/create-resource')
            }>
            Suggest one
          </button> 
          to start building your local learning community!
        </Card>
        :''
      }


      { showResources && showMyFavs === false && resources && resources.length > 0 && 
        resources.map(reso => 
        <GenericListItem
          key={reso._id}
          resource = {reso}
          isSettingFav = {isSettingFav}
          favoriteResourceHandler = {favoriteResourceHandler}
          favoriteProviderHandler = {favoriteProviderHandler}
          auth = {auth}
          setMapFlyTo={setMapFlyTo}
          zoomInOut={zoomInOut}
          isZoomedIn={isZoomedIn}
          history={history}
          toggleLoginModal={toggleLoginModal}
        />
      )}   

      { showMyFavs === true && user && user.user && user.user.myFavProviders && user.user.myFavProviders.length > 0 ? 
        user.user.myFavProviders.map(pro => 
          <ProviderListItem
            key={pro._id}
            provider = {pro}
            isSettingFav = {isSettingFav}
            favoriteProviderHandler = {favoriteProviderHandler}
            auth = {auth}
            setMapFlyTo={setMapFlyTo}
            zoomInOut={zoomInOut}
            isZoomedIn={isZoomedIn}
            history={history}
          />
        )
        :
        showMyFavs === true && user && user.user && user.user.myFavProviders && user.user.myFavProviders.length < 1 ? 
        <div>{`No favorite member spots to show.`}</div>
        :''
      }

      { showMyFavs === true && user && user.user && user.user.myFavResources && user.user.myFavResources.length > 0 ?
        user.user.myFavResources.map(reso => 
          <ResourceListItem
            key={reso._id}
            resource = {reso}
            isSettingFav = {isSettingFav}
            favoriteResourceHandler = {favoriteResourceHandler}
            auth = {auth}
            setMapFlyTo={setMapFlyTo}
            zoomInOut={zoomInOut}
            isZoomedIn={isZoomedIn}
            history={history}
            toggleLoginModal={toggleLoginModal}
          />
        )
        : showMyFavs === true && user && user.user && user.user.myFavResources && user.user.myFavResources.length < 1 ?
        <div>{`No favorite suggested spots to show.`}</div>
        :''
      }  

      { showProfiles &&
        <Card className='homeschoolmap-card' >
          <CardBody className='homeschoolmap-cardbody text-center'>
            Working on it! (Click below to tell us you want it faster!)
            <div>
              <button type='button' onClick={()=>handleProfileCardVote()} className='btn btn-myschool-red' disabled={votedForProfileCard}>
                {votedForProfileCard ? `Thanks for your vote!`: `Upvote!`}
              </button>
            </div>
          </CardBody>
        </Card>
      } 

    </div>

  );
};


export default HomeschoolMapListWrapper;
