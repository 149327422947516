import {
  GET_ADMIN_LEVEL_USERDATA,
  ADMIN_ERROR
} from '../actions/types';

const initialState = {
  users: [],
  adminLoading: true,
  error: {}
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_ADMIN_LEVEL_USERDATA:
      return {
        ...state,
        users: payload,
        adminLoading: false,
      };
    case ADMIN_ERROR:
      return {
        ...state,
        adminLoading: false,
        users: null,
        error: payload
      };
    default:
      return state;
  }
}
