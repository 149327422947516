import React, {} from 'react';
import RegisterAndProfile from './RegisterAndProfile';

const RegisterAndProfilePageWrapper = () => {

  return (
    <div className='container'>
      <div className='row justify-content-center mx-3'>
        <div className='col-lg-6 col-md-8 col-sm-10'>
          <RegisterAndProfile isModal={false}/>
        </div>   
      </div>
    </div>     
  );
};


export default RegisterAndProfilePageWrapper;
