import React, { Component } from 'react';
import profilePic from '../../../images/Cora_and_Dad.jpg';

class Work extends Component {
    state = {
        data: {
            heading: "So, want to join the community?",
            headingText: ""
        }
    }
    render() {
        return (
            <section className="section work-area bg-overlay overflow-hidden ptb_100">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-lg-6">
                            {/* Work Content */}
                            <div className="work-content text-center">
                                <h2 className="text-white">{this.state.data.heading}</h2>
                                <p className="text-white my-3 mt-sm-4 mb-sm-5">{this.state.data.headingText}</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-4">
                            <div className="single-work text-center p-3">
                            <div className="work-icon">
                                <i className="fas fa-user-plus fa-3x color-white"/>
                            </div>
                            <h4 className="text-white py-3">Register</h4>
                            <p className="text-white">Registration is free, and it allows you to take advantage of everything we have to offer.  You can save your favorites to your profile, post your own resources, offer your services to other homeschoolers, etc.</p>
                        </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="single-work text-center p-3">
                            <div className="work-icon">
                                <img className="avatar-md round-img" src={profilePic} alt="" />
                            </div>
                            <h4 className="text-white py-3">Set up a profile</h4>
                            <p className="text-white">Set up a personal profile, and if you provide services to other homeschoolers, you can set up one or multiple provider profiles as well!</p>
                        </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="single-work text-center p-3">
                            <div className="work-icon">
                                <i className='fas fa-search-location fa-3x color-white' />
                            </div>
                            <h4 className="text-white py-3">Find your community</h4>
                            <p className="text-white">Search, filter, find, save to your dashboard.  Keep track of all your favorite resources like co-ops, parks, museums, etc.  Add your own resources so that other members can take advantage of them too!  Make your community into your school.</p>
                        </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Work;