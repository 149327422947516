import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardText, CardBody, CardTitle, CardSubtitle, Button, CardHeader, Row, Col } from 'reactstrap';
import blankProfileImage from '../../../images/profile-img-blank.svg';
import extendCircle from '../../../images/icon-extend-down-circle.svg';
import AddChild from '../../child/AddChild';
import ImageContainer from '../../layout/ImageContainer';
import ProfileStripeConnectStatusBadge from '../../payment/ProfileStripeConnectStatusBadge';
import folderBlue from '../../../images/folder-blue.svg';

const DashboardProfileItem = ({ displayInfo, toggleInfo, myProfile, displayModalPhotoUpload, toggleModalPhotoUpload, history, child  }) => {
  const [ isStripeConnected, setStripeConnected ] = useState(false);

  return (
    <Fragment>
      <div className='text-center my-3'>
        <h4 className='text-color-blue text-bold'>Profile Information</h4>
      </div>
      <Card>
        <CardHeader>
          <CardTitle>Personal Profile<Button className="basic-button float-right" onClick={() => toggleInfo(!displayInfo)}><img src={extendCircle} width="25px" alt="extend info dropdown"/></Button></CardTitle>
        </CardHeader> 

        { displayInfo &&
          <CardBody> 
            <CardSubtitle className='d-flex justify-content-end'>
              <Link 
                type='button' 
                className='btn btn-myschool-blue float-right' 
                to={`/getting-started`}
                >
                <i className='fas fa-user-circle' /> Edit Profile
              </Link>
              <Link to={`/profile/${myProfile.user._id}`} className='btn btn-primary float-right'>
                Go to your personal profile
              </Link>
            </CardSubtitle>
              <br/>
                <div className='text-center'>
                  <ImageContainer
                    src={myProfile.image && myProfile.image !== 'default.jpg' ? myProfile.image: blankProfileImage}
                    thumb={blankProfileImage}
                    width='150px' 
                    height='150px' 
                    className='round-img' 
                    alt='profile image'
                  />
                  <div>
                    <Button
                      onClick={() => toggleModalPhotoUpload(!displayModalPhotoUpload)}
                      type='button'
                      className='btn btn-myschool-blue'>
                      Upload Profile Photo
                    </Button>
                  </div>
                </div>
                <div className='text-bold'>
                  Stripe Connect Status:{' '}<ProfileStripeConnectStatusBadge myProfile={myProfile} setStripeConnected={setStripeConnected}/>{' '}
                  { !isStripeConnected && <button type='button' className='basic-button text-underlined text-bold text-danger text-09rem' onClick={()=>history.push('profile/choose-payment-method')}>Click to connect it now!</button>}
                </div>
                <Row>
                  <Col xs="auto">
                    <strong>On the homeschool map?</strong>{` `}{myProfile.showOnMap && myProfile.indivLat !== 25 ? `Yes, at (${myProfile.indivLat.toFixed(4)*1}, ${myProfile.indivLong.toFixed(4)*1}) `: 'No'}
                  </Col>
                </Row>
                <Row>
                  <Col xs="auto">
                    <strong>Personal Info:</strong>
                  </Col>
                  <Col xs="auto">
                    <div>{myProfile && myProfile.address}</div>
                    <div>{myProfile && myProfile.city}, {myProfile && myProfile.state} {myProfile && myProfile.zip}</div>
                    <div>{myProfile && myProfile.phone}</div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <CardText>
                      <strong>Educational Philosophy:</strong> {myProfile && myProfile.edPhil}
                    </CardText>
                    <CardText>
                      <strong>General Info:</strong> {myProfile && myProfile.bio}
                    </CardText>
                    <CardText>
                      <strong>What are you looking for?</strong> {myProfile && myProfile.lookingFor}
                    </CardText>
                    <CardText>
                      <strong>What do you want to share with others?</strong> {myProfile && myProfile.shareWithOthers}
                    </CardText>
                  </Col>
                </Row>

                <hr/>

                <Row>
                  <Col>
                  <CardSubtitle>Family Information</CardSubtitle>
                  </Col>
                </Row>
                <Row>
                  <AddChild />
                </Row>

                <div>
                  <h4 className='color-myschool-blue mb-4'>Child Portfolios</h4>
                </div>
                <div className='d-flex justify-content-around'>
                  {
                    child && child.children && child.children.length > 0 ? (
                        <div className=''>
                          {child.children.map(child => {
                            return (
                              <button key={`child-${child._id}`} className='basic-button portfolio-folder-container mx-2' onClick={()=>history.push(`/portfolio/child/${child._id}`)}>
                                <img src={folderBlue} alt='child portfolio link' className='portfolio-folder-image'/>
                                <div className='portfolio-folder-name text-center text-bold'>
                                  {child.name}
                                </div>
                              </button>
                            )
                          })}
                        </div>
                      )
                    : ''
                  }
                </div>

                <hr/>
                
                <Row>
                  <Col>
                    <CardSubtitle>Social Media Contact Info</CardSubtitle>
                  </Col>
                </Row>
                <Row>
                  <Col xs={{ size: 'auto', offset: 1 }}>
                    <div>
                      {myProfile.social && myProfile.social.twitter && (
                        <a href={myProfile.social.twitter} target='_blank' rel='noopener noreferrer'>
                          <i className='fab fa-twitter fa-2x' />
                          {myProfile.social.twitter}
                        </a>
                      )}
                    </div>
                    <div>
                      {myProfile.social && myProfile.social.facebook && (
                        <a href={myProfile.social.facebook} target='_blank' rel='noopener noreferrer'>
                          <i className='fab fa-facebook fa-2x' />
                          {myProfile.social.facebook}
                        </a>
                      )}
                    </div>
                    <div>
                      {myProfile.social && myProfile.social.youtube && (
                        <a href={myProfile.social.youtube} target='_blank' rel='noopener noreferrer'>
                          <i className='fab fa-youtube fa-2x' />
                          {myProfile.social.youtube}
                        </a>
                      )}
                    </div>
                    <div>
                      {myProfile.social && myProfile.social.linkedin && (
                        <a href={myProfile.social.linkedin} target='_blank' rel='noopener noreferrer'>
                          <i className='fab fa-linkedin fa-2x' />
                          {myProfile.social.linkedin}
                        </a>
                      )}
                    </div>
                    <div>
                      {myProfile.social && myProfile.social.instagram && (
                        <a href={myProfile.social.instagram} target='_blank' rel='noopener noreferrer'>
                          <i className='fab fa-instagram fa-2x' />
                          {myProfile.social.instagram}
                        </a>
                      )}
                    </div>
                  </Col>
                </Row>
              <br/>
              <hr/>
          </CardBody>
        }
      </Card>
    </Fragment>

  );
};

export default DashboardProfileItem;