import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Spinner from "../layout/Spinner";
import ProfileItem from "./ProfileItem";
import { Form, FormGroup, Input, Label, Button, Container, Col } from "reactstrap";
import { getProfilesWithin } from "../../actions/profile";
import copyCurrentUrl from '../../utils/copyCurrentURL';
import { setAlert } from '../../actions/alert';

const Profiles = ({
  setAlert,
  getProfilesWithin,
  profile: { profiles, profileLoading },
}) => {
  //Location to search within
  const [currentLatLong, setCurrentLatLong] = useState({
    lat: 39.3,
    long: -76.6,
  });
  const currentposition = [currentLatLong.lat, currentLatLong.long];
  const [currentZoom, setCurrentZoom] = useState(4);
  const [isMapReady, setMapReady] = useState(false);
  const [dist, setDist] = useState(50);

  const onDistChange = (e) => setDist(e.target.value);

  const handleUpdateQuery = (e) => {
    getProfilesWithin(currentLatLong, dist);
  };

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        let userLocation = {
          lat: position.coords.latitude,
          long: position.coords.longitude,
        };
        setCurrentLatLong(userLocation);
        getProfilesWithin(userLocation, dist);
        setCurrentZoom(10);
        setMapReady(true);
      },
      () => {
        alert(
          'You blocked your location, which is totally cool with us.  As a backup, we use your IP address location, which gives a general approximation of your location. Want to know more about location services and how to "hide" them? Check out this video that we think gave a good overview... https://www.youtube.com/watch?v=H234wK-3F_w '
        );
        fetch("https://ipapi.co/json")
          .then((res) => res.json())
          .then((iplocation) => {
            let userIpLocation = {
              lat: iplocation.latitude,
              long: iplocation.longitude,
            };
            setCurrentLatLong(userIpLocation);
            setCurrentZoom(10);
            getProfilesWithin(userIpLocation, dist);
            setMapReady(true);
          });
      }
    );
  }, []);

  const copyUrl = async () => {
    navigator.clipboard.writeText(`${window.location.origin}/findFamilies`)
    .then(function() {
      setAlert('Copied to Clipboard', 'success');
    }, function(err) {
      setAlert('Error copying to clipboard, sorry :(', 'danger');
      console.log(err);
    });
  };

  return profileLoading || !profiles ? (
        <Spinner />
      ) : !profileLoading && profiles && profiles.length < 1 ? (
        <div className='text-center'>
          <div className='my-4'>
            <i className="fas fa-lock fa-5x"/>
          </div>
          <h4 className='mb-5'>Unlock this feature by getting 10 people to register within 20 miles of you!</h4>
          <div>
            <div className=''>
              {`Currently there are ${profiles.length} other members nearby.`}
            </div>
            <button onClick={()=>copyUrl()} className='btn btn-myschool-blue m-3'>
              Click to copy the URL and share it with friends and on social media!
            </button>
          </div>
        </div>
      ) : (
        <Fragment>
          <Container>
            <h1 className="large text-color-blue">
              Find Local Families
            </h1>
            {/*
              <Col>
                <Form inline>
                  <FormGroup>
                    <Label for="dist">Search Within:</Label>
                    <Input
                      type="number"
                      name="dist"
                      id="dist"
                      onChange={(e) => onDistChange(e)}
                      value={dist}
                      className="col-xs-2"
                    />
                    <Label for="dist">miles </Label>{" "}
                  </FormGroup>
                  <Button
                    type="button"
                    className="btn btn-myschool-blue"
                    onClick={(e) => handleUpdateQuery(e)}
                  >
                    Submit
                  </Button>
                </Form>
              </Col>
            */}
            <div>Results: {profiles.length}</div>
            <div className="profiles">
              {profiles.length > 0 ? (
                profiles.map((profile) => (
                  <ProfileItem key={profile._id} profile={profile} />
                ))
              ) : (
                <h4>No profiles found...</h4>
              )}
            </div>
          </Container>
        </Fragment>
      )
};

Profiles.propTypes = {
  getProfilesWithin: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  setAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  profile: state.profile
});

export default connect(mapStateToProps, { setAlert, getProfilesWithin })(Profiles);
