import React, { Fragment } from 'react';
import CookieConsent from "react-cookie-consent";
import {Link} from 'react-router-dom';

const CookieConsentBanner = () => (
  <Fragment>
    <CookieConsent
	  location="bottom"
	  buttonText="Accept"
	  style={{ background: "#fff" }}
	  buttonStyle={{ background: "#375e97", color: "#fff", fontSize: "14px" }}
	  expires={365} //this is also the defualt
	  
	>
		<div className='color-myschool-blue'>
			This website or its third-party tools process personal data and use cookies or other identifiers, which are necessary for its functioning and required to achieve the purposes illustrated in our policies.
			You accept the use of cookies or other identifiers by closing or dismissing this notice, by clicking a link or button or by continuing to browse otherwise.  Please feel free to review our <Link to="termsofuse" className="text-bold text-underline">policies</Link> for more information.{" "}
		</div>
	</CookieConsent>
  </Fragment>
);


export default CookieConsentBanner;
