import api from '../utils/api';
import queryString from 'query-string';

import {
  GET_ADMIN_LEVEL_USERDATA,
  ADMIN_ERROR,
  GET_PROFILES_WITHIN,
} from './types';

// Get all user detailed data
export const getDetailedUserData = () => async dispatch => {
  try {
    
    const res = await api.get('/users/adminUserData');

    dispatch({
      type: GET_ADMIN_LEVEL_USERDATA,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: ADMIN_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};


//Get profiles near any input point
export const adminGetProfilesWithin = (position, dist) => async dispatch => {
    
  const { lat, long } = position;

  if (!dist ) {
    position = {...position, 
      dist: 100
    };
  }

  try {
    const res = await api.get(`/profile/admin/profiles-within/${lat}/${long}/${dist}`);
    dispatch({
      type: GET_PROFILES_WITHIN,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: ADMIN_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

//Get profiles with query
export const adminGetProfilesWithQuery = queryObject => async dispatch => {

  try {

    const query = queryString.stringify(queryObject);


    const res = await api.get(`/profile/admin?${query}`);
    console.log(res);
    dispatch({
      type: GET_PROFILES_WITHIN,
      payload: res.data.data
    });
  } catch (err) {
    dispatch({
      type: ADMIN_ERROR,
      payload: { msg: err.response.data.msg, status: err.response.status }
    });
  }
};


