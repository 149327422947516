import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SpinnerComponent from '../layout/Spinner';
import { Button, Input, Container, Row, Col, Form, FormGroup} from 'reactstrap';
import {PieChart} from 'react-minimal-pie-chart';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import convertMinsToHrsMins from '../../utils/convertMinsToHrsMins';
import truncate from '../../utils/truncate';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const PortfolioSummary = ({ portfolioStats, portfolio }) => {  
  
  const colorArray = ['#6A2135', '#375e97', '#FB6542', '#FFBB00','#016c10', '#f1bbb0', '#ce84e1', '#3f3f3f', '#aec5aa', '#5bc0de', '#DE795B', '#403797', '#378E97', '#FFC933']

  //stats data
  const { dataBySpot, dataBySubject } = portfolioStats;

  const [spotChart, setSpotChart] = useState([]);
  const [spotBarChart, setSpotBarChart] = useState({
    labels: [],
    datasets: [{
        label: '# of Visits',
        data: [],
        backgroundColor: '#375e97',
        borderWidth: 1
    }]
  });
  const [subjectChart, setSubjectChart] = useState([]);
  const [totalHours, setTotalHours] = useState(0);
  const [portfolioLength, setPortfolioLength] = useState(0);
  const [uniqueSpots, setUniqueSpots] = useState(0);

  //Time per subject pie chart
  useEffect(()=> {
    if (portfolioStats && portfolioStats.dataBySubject && Array.isArray(portfolioStats.dataBySubject)) {
      const subChart = portfolioStats.dataBySubject.map(({_id,totalDuration},i)=>{
        const subChartObj = {title:_id, value:totalDuration, color:colorArray[i]};
        return subChartObj;
      });
      setSubjectChart(subChart);
    }
  },[portfolioStats]);

  //Time per spot pie chart
  useEffect(()=> {
    if (portfolioStats && portfolioStats.dataBySpot && Array.isArray(portfolioStats.dataBySpot)) {
      const spotChart = portfolioStats.dataBySpot.map(({authority_info,totalDuration},i)=>{
        const spotChartObj = {value:totalDuration, color:colorArray[i]};
        if (authority_info[0] && authority_info[0].name) {spotChartObj.title = truncate(authority_info[0].name,30)} else {spotChartObj.title = ""}
        return spotChartObj;
      });
      setSpotChart(spotChart);
    }
  },[portfolioStats]);

  //Visits to top spots
  useEffect(()=> {
    const spotChartObj = {
        labels: [],
        datasets: [{
            label: '# of Visits',
            data: [],
            backgroundColor: '#375e97',
            borderWidth: 1
        }]
      }
    if (portfolioStats && portfolioStats.dataBySpot && Array.isArray(portfolioStats.dataBySpot)) {
      portfolioStats.dataBySpot.slice(0,5).map(({authority_info,count},i)=>{
        spotChartObj.datasets[0].data.push(count);
        if (authority_info[0] && authority_info[0].name) {
            spotChartObj.labels.push(truncate(authority_info[0].name,30))
          } else {
            spotChartObj.labels.push("")
          }
      });
      setSpotBarChart(spotChartObj);
    }
  },[portfolioStats]);

  

  //Sum total duration
  useEffect(()=> {
    if (portfolioStats && portfolioStats.dataBySpot && Array.isArray(portfolioStats.dataBySpot)) {
      const totalHoursExploring = portfolioStats.dataBySpot.reduce(function(prevValue, currValue){
        return prevValue + currValue.totalDuration
      },0);
      setTotalHours(convertMinsToHrsMins(totalHoursExploring));
    }
  },[portfolioStats]);

  //Set portfolio length
  useEffect(()=> {
    if (portfolio && Array.isArray(portfolio) && portfolio.length > 0 ) {
      setPortfolioLength(portfolio.length);
    }
  },[portfolio]);

  //Set unique spots visited
  useEffect(()=> {
    if (portfolioStats && portfolioStats.dataBySpot && Array.isArray(portfolioStats.dataBySpot) && portfolioStats.dataBySpot.length > 0 ) {
      setUniqueSpots(portfolioStats.dataBySpot.length);
    }
  },[portfolioStats]);

  return ( 
    <Fragment> 
      <Container className='justify-content-center'>
        <div className='card-flex-set justify-content-center'>
          <div className='card portfolio-summary-card'>
            <div className='portfolio-summary-card-value'>{totalHours}</div>
            <div>Total time exploring</div>
          </div>
          <div className='card portfolio-summary-card'>
            <div className='portfolio-summary-card-value'>{portfolioLength}</div>
            <div>Total visits</div>
          </div>
          <div className='card portfolio-summary-card'>
            <div className='portfolio-summary-card-value'>{uniqueSpots}</div>
            <div>Unique spots visited</div>
          </div>
        </div>
        <div className='card-flex-set justify-content-center'>
          <div className='card portfolio-chart-card'>
            <div>
              <h4 className='my-4'>Time Exploring Each Subject</h4>
              <PieChart 
                animate
                animationDuration={1000}
                animationEasing="ease-out"
                lineWidth={70}
                radius={50}
                style={{
                  height: '300px',
                  overflow: 'visible'
                }}
                data={subjectChart} 
                label={({ dataEntry, dataIndex }) =>
                  truncate(dataEntry.title,16,"")
                }
                labelPosition={70}
                labelStyle={(index) => ({
                  // fill: subjectChart[index].color,
                  fill: "#fff",
                  fontSize: '5px',
                  fontFamily: 'sans-serif',
                })}
              />
            </div>
            <div className="my-2 text-center">
              {
                subjectChart.map((data,i)=>{
                  return (
                    <span key={i} className="mx-2">
                      <i className="fas fa-circle" style={{color: data.color}}/>
                      {` ${data.title}`}
                    </span>
                  )
                })
              }
            </div>
          </div>
          <div className='card portfolio-chart-card'>
            <h4 className='my-4'>Your Top Spots</h4>
              <Bar
                data={spotBarChart}
                options={{
                  responsive: true
                }}
              />
          </div>
        </div>
      </Container>
    </Fragment>
  )  

};

export default PortfolioSummary;


// <PieChart 
//     animate
//     animationDuration={1000}
//     animationEasing="ease-out"
//     lineWidth={70}
//     radius={50}
//     style={{
//       height: '300px',
//       overflow: 'visible'
//     }}
//     data={spotChart} 
//   />
// </div>
// <div className="text-center">
//   {
//     spotChart.map((data,i)=>{
//       return (
//         <span key={i} className="mx-2">
//           <i className="fas fa-circle" style={{color: data.color}}/>
//           {` ${data.title}`}
//         </span>
//       )
//     })
//   }
// </div>