import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { Badge, Modal, ModalBody } from 'reactstrap';
import LoginPopup from '../../auth/LoginPopup';
import RegisterPopup from '../../auth/RegisterPopup';
import PropTypes from 'prop-types';

const initData = [
    {
        fontAwesomeIcon: "fas fa-map-marker-alt fa-2x color-myschool-red",
        title: "Create a Personal Profile",
        content: "Registering as an individual? Create a profile to connect with other homeschoolers and to host events!",
        authLink: "/create-host-profile",
        unauthLink: "/create-host-profile",
        unauthButton: false
    },
    {
        fontAwesomeIcon: "fas fa-map-marker-alt fa-2x color-myschool-blue",
        title: "Create a Spot Profile",
        content: "Registering a group, company, or organization? Create a spot profile and your organization! You'll appear on the homeschool map, and you'll be able to host events to connect with your local homeschooling community!",
        authLink: "/business",
        unauthLink: "/business",
        unauthButton: true
    }
]

const ChooseHostPath = ({ isAuthenticated, history, choosePathRef, showTitle }) => {

    const [ data, setData ] = useState(initData);

    const [ loginModal, toggleLoginModal ] = useState(false);
    const [ registerModal, toggleRegisterModal ] = useState(false);

    const swapLoginRegister = () => {
        toggleLoginModal(!loginModal);
        toggleRegisterModal(!registerModal);
    };
    
    return (
        <Fragment>
            <section id="features" ref={choosePathRef} className="section features-area style-two overflow-hidden ptb_50">
                <div className="container">
                    {showTitle && 
                        <div className='text-center my-3'>
                            <h2 className='color-myschool-blue'>Put yourself on the map!</h2>
                        </div>
                    }

                    <div className="row">

                        {data.map((item, idx) => {
                            return(
                                <div key={`ffd_${idx}`} className="col-12 col-md-6 my-3 res-margin">
                                    <button 
                                        className='basic-button'
                                        type='button' 
                                        onClick={ isAuthenticated ? 
                                            ()=> history.push(`${item.authLink}`)
                                            :
                                            !item.unauthButton ?
                                            ()=> history.push(`${item.unauthLink}`)
                                            :
                                            ()=> toggleRegisterModal(true)
                                            }
                                        >
                                        {/* Image Box */}
                                        <div className="image-box choose-path-card text-center icon-1 p-5">
                                            {/* Featured Image */}
                                            <div className="featured-img mb-3">
                                                {item.fontAwesomeIcon && <i className={item.fontAwesomeIcon} alt=""/>}{' '}
                                                {item.iconImage && <img src={item.iconImage} alt="" width='40px'/>}{' '}
                                                {item.badge ? (<p><Badge color='warning'>{item.badge}</Badge></p>):''}
                                            </div>
                                            {/* Icon Text */}
                                            <div className="icon-text">
                                                <h4 className="mb-2">{item.title}</h4>
                                                <p>{item.content}</p>
                                                {item.content2 ? <p className='text-italic text-075rem'>{item.content2}</p>:''}
                                            </div>
                                        </div>
                                    </button>
                                </div>
                            );
                        })}
                    </div>
                </div>

                <div className='text-center'>
                    <button type='button' onClick={()=>history.push('/compare-plans')} className='btn btn-myschool-blue'>Check out all the benefits to signing up!</button>
                </div>

            </section>

            <Modal isOpen={loginModal} centered toggle={() => toggleLoginModal(!loginModal)}>
                <ModalBody className='text-center'>
                  <LoginPopup loginModal={loginModal} toggleLoginModal={toggleLoginModal}/>
                  <button onClick={()=>swapLoginRegister()} className='basic-button text-bold text-underlined'>I need to Register</button>
                </ModalBody>
            </Modal>

            <Modal isOpen={registerModal} centered toggle={() => toggleRegisterModal(!registerModal)}>
                <ModalBody className='text-center'>
                  <RegisterPopup registerModal={registerModal} toggleRegisterModal={toggleRegisterModal} history={history}/>
                  <button onClick={()=>swapLoginRegister()} className='basic-button text-bold text-underlined'>Go to Login</button>
                </ModalBody>
            </Modal>
        </Fragment>
    );
}

ChooseHostPath.propTypes = {
  isAuthenticated: PropTypes.bool
};

ChooseHostPath.defaultProps = {
  showTitle: true,
};

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, { })(ChooseHostPath);