import React, { Fragment } from 'react';
import { Container } from 'reactstrap';

const EventBookingListItem = ({ booking }) => {

return (
    <Fragment>
      <Container className='outlined'>
        <ul>
          <li>Parent's Name: {booking && booking.parentName}</li>
          <ul>
            <li>Email: {booking && booking.regEmail}</li>
            <li>Phone: {booking && booking.phone}</li>
          </ul>
          <li>Child's Name: {booking && booking.childName}</li>
          <li>Age: {booking && booking.childAge}</li>
        </ul>
      </Container>
    </Fragment>
  );
};

export default EventBookingListItem;