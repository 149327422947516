import React, { Fragment, useState } from 'react';
import {Link} from 'react-router-dom';
import userIconImg from '../../images/user-map-icon.svg';
import resourceIconImg from '../../images/resource-map-icon.svg';
import partnerIconImg from '../../images/map-marker_partner_red_svg2.svg';
import yellowIconImg from '../../images/map-marker_yellow_svg.svg';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import timesIcon from '../../images/icon-times.svg';
import downTriangleIcon from '../../images/icon-down-triangle.svg';
import lockLocked from '../../images/icon-lock-locked.svg';

const HomeschoolmapKey = ({auth, history}) => {


  const [displayModalUser, toggleModalUser] = useState(false);
  const [displayModalLocked, toggleModalLocked] = useState(false);
  const [displayModalResource, toggleModalResource] = useState(false);
  const [ displayKey, toggleDisplayKey ] = useState(true);

  return (
    <div className='map-key-wrapper-top'>
      { displayKey ?
        <div className="homeschoolMapKeyBorder">
            <div className='d-flex justify-content-end'>
              <div className='homeschool-map-key-icon-wrapper'>
                <button type='button' className='basic-button cursor-pointer' onClick={()=>toggleDisplayKey(!displayKey)}>
                  <div className=''><img src={timesIcon} className='homeschool-map-key-icon' alt='collapse key'/></div>
                </button>
              </div>
            </div>
          
            <Fragment>
              <div className='text-center'>
                <div className='color-myschool-blue text-bold text-1-2rem'>Key{'    '}</div> 
              </div>
              <div className='line-2'/>
              <ul className="homeschoolMapKey">
                <li className="homeschoolMapKey">
                  <div>
                      <img src={userIconImg} alt="class icon" height="15px" width="15px"/>
                    <span className='text-075rem' >
                    {!auth.isAuthenticated && 
                      <img src={lockLocked} width='10' alt='members only lock'/>
                    }
                      <span>
                        Members
                        <span onClick={() => toggleModalUser(!displayModalUser)} className='cursor-pointer text-superscript' >
                          <i className='fas fa-info-circle color-myschool-blue'/>
                        </span>
                      </span>
                    </span>
                  </div>
                </li>
                
                <li className="homeschoolMapKey">
                  <div >
                    <img src={resourceIconImg} alt="resource icon" height="15px" width="15px"/>
                    <img src={yellowIconImg} alt="resource icon" height="15px" width="15px"/>
                    <span className='text-075rem'>
                      Learning Spots
                      <span onClick={() => toggleModalResource(!displayModalResource)} className='cursor-pointer text-superscript'><i className='fas fa-info-circle color-myschool-blue'/></span>
                    </span>
                  </div>
                </li>
                <li className="homeschoolMapKey">
                  <div >
                    <img src={partnerIconImg} alt="partner icon" height="15px" width="15px"/>
                    <span className='text-075rem'>
                      Partner Spots
                      <span onClick={() => history.push('/myschoolplus/play-and-learn')} className='cursor-pointer text-superscript'><i className='fas fa-info-circle color-myschool-blue'/></span>
                    </span>
                  </div>
                </li>
                
              </ul>
            </Fragment>
          </div>
        : 
          <button type='button' className='basic-button cursor-pointer' onClick={()=>toggleDisplayKey(!displayKey)}>
            <div className="homeschoolMapKeyBorderCollapsed text-center">
              <img src={downTriangleIcon} className='homeschool-map-key-icon-collapsed' alt='expand key'/>
            </div>
          </button>
        }
            
      <Modal centered isOpen={displayModalUser} toggle={() => toggleModalUser(!displayModalUser)}>
        <ModalHeader>
          <span className=' my-2 mb-4'>
            <img src={userIconImg} alt="class icon" height="30px" width="30px"/>
            <h4 className='d-inline-block color-myschool-blue'>Members</h4>
          </span>
        </ModalHeader>
        <ModalBody>
          {!auth.isAuthenticated ?
            <Fragment>
              <img src={lockLocked} width='20' alt='members only lock'/>
              You must sign up, create a profile, and opt in to the map to see other local members! 
              {' '}<Link to="/findFamilies" className='text-underlined'>Sign up now for free!</Link>
            </Fragment>
          :
            <Fragment>
              <h6 className=''>Members are families just like yours! Click on the red point to see their profile page or send them an email to connect!</h6>
            </Fragment>
          }
        </ModalBody>
      </Modal>

      <Modal centered isOpen={displayModalResource} toggle={() => toggleModalResource(!displayModalResource)}>
        <ModalHeader>
          <span className=' my-2 mb-4'>
            <img src={resourceIconImg} alt="class icon" height="30px" width="30px"/>
            <h4 className='d-inline-block color-myschool-blue'>Learning Spots</h4>
          </span>
        </ModalHeader>
        <ModalBody>
          <h6 className=''>
            Spots are either suggested by or operated by users. They could be businesses, museums, parks, nature centers, co-ops, etc that you can use to help educate your kids (and yourselves)! We attempt to validate and ensure that these postings are accurate and up to date on a regular basis, but rely strongly on user feedback to make this happen.  This map only grows with user help.  Have a spot you love?! <Link to='/resource-options' className='text-underlined'>Suggest it</Link>! Want to teach others what you're passionate about?{' '}<Link to='/resource-options' className='text-underlined'>Become a Spot</Link>{' '}today! 
          </h6>
        </ModalBody>
      </Modal>

    </div>
  )
};


export default HomeschoolmapKey;

    
        
            