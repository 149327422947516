import React, { useState, Fragment } from "react";
import { Form, Label, FormGroup, Input, Button, Spinner } from 'reactstrap';
import axios from 'axios'; 

const EventInterest = ({ toggleShowInterestForm }) => {

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    joiningClass: false,
    joiningFieldTrip: false,
    joiningOther: false,
    joiningAddInfo: '',
    hostingClass: false,
    hostingFieldTrip: false,
    hostingOther: false,
    hostingAddInfo: '',
    keepMeUpdated: true,
    inauguralHost: false
  });

  const { name, email, hostingClass, hostingFieldTrip, hostingOther, joiningAddInfo, joiningClass, joiningFieldTrip, joiningOther, hostingAddInfo, keepMeUpdated, inauguralHost } = formData;

  const onChange = e =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onCheckChange = e =>
    setFormData({ ...formData, [e.target.name]: e.target.checked });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [ isSuccess, setIsSuccess ] = useState(false);
  const onSubmit = async e => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const configReg = {headers: {'Content-Type': 'application/json'}};
      await axios.post(`api/eventInterest/interestForm`, formData, configReg);
      setIsSuccess(true);
      setIsSubmitting(false);
    } catch (err) {
      console.log(err);
    }
    setTimeout(() => {
      toggleShowInterestForm(false);
    }, 10000);
  };


  return !isSuccess ? 
    (
    <Fragment>
      <h4>Great!  Tell us a little more for some special opportunities (see bottom)!</h4>
      <Form className='form msstd-form text-left' onSubmit={e => onSubmit(e)}>
        <FormGroup>
          <Label className='text-bold'>Name</Label>
          <Input type="text" 
            name="name" 
            id="name" 
            placeholder="First Last Name"
            value={name}
            onChange={e => onChange(e)} 
            required
            />
        </FormGroup>
        <FormGroup>
          <Label className='text-bold'>Email</Label>
          <Input type="email" 
            name="email" 
            id="email" 
            placeholder="Email Address"
            value={email}
            onChange={e => onChange(e)} 
            required
            />
        </FormGroup>
        <div className='space'/>
        <Label className='text-bold'> Which would you like to find?</Label>
        <div><small>(check all that apply)</small></div>
        <FormGroup check>
          <Label check>
            <Input type="checkbox" name="joiningClass" checked={joiningClass} onChange={(e)=>onCheckChange(e)} />{' '}
            Classes
          </Label>
        </FormGroup>
        <FormGroup check>
          <Label check>
            <Input type="checkbox" name="joiningFieldTrip" checked={joiningFieldTrip} onChange={(e)=>onCheckChange(e)} />{' '}
            Field Trips
          </Label>
        </FormGroup>
        <FormGroup check>
          <Label check>
            <Input type="checkbox" name="joiningOther" checked={joiningOther} onChange={(e)=>onCheckChange(e)} />{' '}
            Other Events
          </Label>
        </FormGroup>
        {
          joiningOther === true ? (
            <FormGroup>
              <Label for="hostingAddInfo">What sort of events do you want to find most? (optional)</Label>
              <Input type="textarea" name="joiningAddInfo" id="joiningAddInfo" value={joiningAddInfo} onChange={(e)=>onChange(e)}/>
            </FormGroup>
          ):''
        }
        <div className='space'/>
        <Label className='text-bold'> Which would you like to host?</Label>
        <div><small>(check all that apply)</small></div>
        <FormGroup check>
          <Label check>
            <Input type="checkbox" name="hostingClass" checked={hostingClass} onChange={(e)=>onCheckChange(e)} />{' '}
            Classes
          </Label>
        </FormGroup>
        <FormGroup check>
          <Label check>
            <Input type="checkbox" name="hostingFieldTrip" checked={hostingFieldTrip} onChange={(e)=>onCheckChange(e)} />{' '}
            Field Trips
          </Label>
        </FormGroup>
        <FormGroup check>
          <Label check>
            <Input type="checkbox" name="hostingOther" checked={hostingOther} onChange={(e)=>onCheckChange(e)} />{' '}
            Other Events
          </Label>
        </FormGroup>
        {
          hostingOther === true ? (
            <FormGroup>
              <Label for="hostingAddInfo">What sort of events would you like to host? (optional)</Label>
              <Input type="textarea" name="hostingAddInfo" id="hostingAddInfo" value={hostingAddInfo} onChange={(e)=>onChange(e)}/>
            </FormGroup>
          ):''
        }
        <div className='space'/>
        <Label className='text-bold'>Special Opportunities</Label>
        <FormGroup check>
          <Label check>
            <Input type="checkbox" name="keepMeUpdated" checked={keepMeUpdated} onChange={(e)=>onCheckChange(e)} />{' '}
            Keep me updated (emails up to twice a month)
          </Label>
        </FormGroup>
        <FormGroup check>
          <Label check>
            <Input type="checkbox" name="inauguralHost" checked={inauguralHost} onChange={(e)=>onCheckChange(e)} />{' '}
            I'd like to be invited to be amongst the first members eligible to host events.
          </Label>
        </FormGroup>
        <Button type="submit" disabled={isSubmitting || isSuccess} className="btn btn-modal-blue btn-set-WH-long my-2">
                  <span>
            {isSubmitting ? (
              <Spinner size="sm" className='color-white'/>
            ) : (
              "Send"
            )}
          </span>
        </Button>
        <button type='button' className='btn btn-modal-light btn-set-WH-long' onClick={()=>toggleShowInterestForm(false)}>
          Cancel
        </button>
      </Form>
    </Fragment>
  ) : (
    <Fragment>
      <h4>Success!  Thanks for your input!</h4>
      <div>
        <button className="btn btn-modal-blue" onClick={()=>toggleShowInterestForm(false)}>Close</button>
      </div>
    </Fragment>
  );
};

export default EventInterest;
