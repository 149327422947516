import React, { useState } from 'react';
import {Modal, ModalBody} from 'reactstrap';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const CarouselComponent = ({items}) => {
  const [ zoomInModal, toggleZoomInModal ] = useState(false);
  const [ showImg, setShowImg ] = useState({});

  const zoomInOnImage = (itemImg) => {
    toggleZoomInModal(!zoomInModal);
    setShowImg(itemImg);
  };

  let {bkPt1, bkPt2, bkPt3} = items.length;  //{items.length > 3 ? 4: };
  // let carouselBreakPoint2 = 3;  //{items.length > 2 ? 3: items.length};
  // let carouselBreakPoint3 = 2;  //{items.length > 1 ? 2: items.length};

  if (items.length > 3) { bkPt1 = 4};
  if (items.length > 2) { bkPt2 = 3};
  if (items.length > 1) { bkPt3 = 2};

  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 1000,
    slidesToShow: bkPt1,
    slidesToScroll: 1,
    // focusOnSelect: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: bkPt2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: bkPt3,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <div className="my-3">
      <div>
        {items.length > 1 ?
          <Slider {...settings}>
           {items.map((item,i)=>{
              return (
                <div key={i} className="p-2" onClick={()=>zoomInOnImage(item)}>
                  <img src={item.src} alt={item.altText} className="cursor-pointer"/>
                  <div className="text-center">{item.caption}</div>
                </div>
              )
            })
           }
          </Slider>
          :
          <div className="p-2 text-center" onClick={()=>zoomInOnImage(items[0])}>
            <img src={items[0].src} alt={items[0].altText} width="350px" className="cursor-pointer"/>
            <div className="text-center">{items[0].caption}</div>
          </div>
        }
      </div>

      <Modal isOpen={zoomInModal} centered toggle={()=>toggleZoomInModal(!zoomInModal)}>
        <ModalBody className="text-center">
          <img src={showImg.src} alt={showImg.altText} />
          <div>{showImg.caption}</div>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default CarouselComponent;