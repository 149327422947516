import React, { Fragment, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Card, CardHeader, CardTitle, CardSubtitle, CardBody, Button, Row, Col, Modal, ModalBody, Alert } from 'reactstrap';
import blankProfileImage from '../../../images/profile-img-blank.svg';
import ImageContainer from '../../layout/ImageContainer';
import ProviderProfileImageUpload from './ProviderProfileImageUpload';
import extendCircle from '../../../images/icon-extend-down-circle.svg';
import { setAlert } from '../../../actions/alert';
import ProviderStripeConnectStatusBadge from '../../payment/ProviderStripeConnectStatusBadge';


const ProviderProfileItem = ({ provider, deleteProvider }) => {  
  
  const history = useHistory();
     
  const [displayProviderInfo, toggleProviderInfo] = useState(true);
  const [displayModalPhotoUpload, toggleModalPhotoUpload] = useState(false);

  const [ isSubmitting, setIsSubmitting ] = useState(false);
  const [ isLinkCopied, setLinkCopied ] = useState(false);
  const [ linkCopyFail, setLinkCopyFail ] = useState(false);

  const deleteProviderProfile = async () => {
    setIsSubmitting(true);
    await deleteProvider(provider._id);
    setIsSubmitting(false);
  }

  const copyProviderUrl = async () => {
    navigator.clipboard.writeText(`${window.location.origin}/provider/${provider._id}`)
    .then(function() {
      setLinkCopied(true);
    }, function(err) {
      setTimeout(()=> {
        setLinkCopyFail(true);
      }, 2000);
      console.log(err);
    });
  };
  
  return (
    <Fragment>
      <br/>
      <Card>
        <CardHeader>
          <CardTitle>{`Info for `}{provider && provider.providerName}{`'s Spot`} 
          <Button className="basic-button float-right" onClick={() => toggleProviderInfo(!displayProviderInfo)}><img src={extendCircle} width="25px" alt="extend info dropdown"/></Button></CardTitle>
        </CardHeader>                        

      { displayProviderInfo ? (
        <CardBody>
          <Alert color='info' className='text-center'>
            Share your spot profile on social media or by email to tell them about your spot!{' '}
              <button type='button' className='btn btn-myschool-blue' onClick={()=>copyProviderUrl()}>
                {
                  isLinkCopied ?
                  'Link copied!'
                  :
                  linkCopyFail ?
                  'Error, try again'
                  :
                  'Copy link'
                }
              </button>
          </Alert>
          <CardSubtitle className='d-flex justify-content-end'>
              <Link to={{
                    pathname: `/edit-provider/${provider._id}`,
                    state: {provider: provider}
                  }} className='btn btn-primary'>
                <i className='fas fa-user-circle' /> Edit Profile
              </Link>
              <Link to={`/provider/${provider._id}`} className='btn btn-myschool-blue'>
                Go to your spot profile
              </Link>
          </CardSubtitle>
              <br/>
                <div className='text-center'>
                  <ImageContainer
                    src={provider.image && provider.image !== 'default.jpg' ? provider.image: blankProfileImage}
                    thumb={blankProfileImage}
                    width='200px' 
                    height='200px' 
                    className='round-img' 
                    alt='spot profile image'
                  />
                  <div>
                    <Button
                      onClick={() => toggleModalPhotoUpload(!displayModalPhotoUpload)}
                      type='button'
                      className='btn btn-myschool-blue'>
                      Upload Profile Photo
                    </Button>
                  </div>
                </div>
                {provider && provider.stripeAccount ?
                  <div className='text-bold'>
                    Stripe Connect Status:{' '}<ProviderStripeConnectStatusBadge provider={provider}/>
                  </div>
                  :''
                }
                <Row>
                  <Col xs="auto">
                    <strong>Info:</strong>
                  </Col>
                  <Col xs="auto">
                    <div>Category{' - '} {provider && provider.category ? 
                      provider.category
                        .split(' ')
                        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
                        .join(' ')
                      :''}
                    </div>
                    <div>{provider && provider.providerName}</div>
                    <div>{provider && provider.address}  - Coordinates Mapped to: 
                      { provider.lat === 25 || provider.long === -71 ? 
                        ("Location not found, go to edit and resubmit to try the address again, or email info@myschoolathome.io and we will fix it for you!")
                        : `(${provider.lat}, ${provider.long})`
                      }
                    </div>
                    <div>{provider && provider.city}, {provider && provider.state} {provider && provider.zip}</div>
                    <div>{provider && provider.email}</div>
                    <div>{provider && provider.phone}</div>
                    <div>{provider && provider.website}</div>
                    <div>{provider && provider.contactName ? `Contact Name: ${provider.contactName}`:''}</div>
                  </Col>
                </Row>
                
                { provider && provider.religiousAffiliation ?
                  <Row>
                    <Col>
                      <div><strong>Religious Affiliation:</strong>{` ${provider.religiousAffiliation}`}</div>
                    </Col>
                  </Row>
                  : ''
                }

                <Row>
                  <Col>
                    <div><strong>Is this a drop-off provider?</strong> {provider && provider.isDropOff && provider.isDropOff === true ? `Yes` : `No`} </div>
                  </Col>
                </Row>

                {provider && provider.subjects && provider.subjects.length > 0 ?
                  <Row>
                    <Col>
                      <strong>Subjects that can be learned here:</strong> 
                        <div className='ml-2'>{provider.subjects.join(", ")}
                      </div>
                    </Col>
                  </Row>
                  : ''
                }

                {provider && provider.tags && provider.tags.length > 0 ?
                  <Row>
                    <Col>
                      <strong>Tags:</strong><small>{` (Note: subjects get automatically added to tags)`} </small>
                        <div className='ml-2'>{provider.tags.join(", ")}
                      </div>
                    </Col>
                  </Row>
                  : ''
                }

                {provider && provider.edPhil ?
                  <Row>
                    <Col>
                      <strong>Educational Philosophy:</strong> {provider.edPhil}
                    </Col>
                  </Row>
                  : ''
                }
                
                {provider && provider.edAndEx ?
                  <Row>
                    <Col>
                      <strong>Education/Experience:</strong> {provider.edAndEx}
                    </Col>
                  </Row>
                    : ''
                }
                
                <Row>
                  <Col xs="auto">
                    <strong>General Info:</strong> 
                  </Col>
                  <Col xs="auto">
                    {provider && provider.genInfo}
                  </Col>
                </Row>

                {provider && provider.ageStart && provider.ageEnd ?
                  <Row>
                    <Col>
                      <strong>Ages:</strong> {provider && provider.ageStart} to {provider && provider.ageEnd}
                    </Col>
                  </Row>
                  :''
                }

                {provider && provider.whenOperate ?
                  <Row>
                    <Col>
                      <strong>When do you operate?</strong> {provider.whenOperate} 
                    </Col>
                  </Row>
                  :''
                }

                {provider && provider.typicalDay ?
                  <Row>
                    <Col>
                      <strong>A typical day:</strong> {provider.typicalDay} 
                    </Col>
                  </Row>
                  :''
                }

                <Row>
                  <Col xs={{ size: 'auto', offset: 1 }}>
                    <div>
                      {provider.social && provider.social.twitter && (
                        <a href={provider.social.twitter} target='_blank' rel='noopener noreferrer'>
                          <i className='fab fa-twitter fa-2x' />
                          {provider.social.twitter}
                        </a>
                      )}
                    </div>
                    <div>
                      {provider.social && provider.social.facebook && (
                        <a href={provider.social.facebook} target='_blank' rel='noopener noreferrer'>
                          <i className='fab fa-facebook fa-2x' />
                          {provider.social.facebook}
                        </a>
                      )}
                    </div>
                    <div>
                      {provider.social && provider.social.youtube && (
                        <a href={provider.social.youtube} target='_blank' rel='noopener noreferrer'>
                          <i className='fab fa-youtube fa-2x' />
                          {provider.social.youtube}
                        </a>
                      )}
                    </div>
                    <div>
                      {provider.social && provider.social.linkedin && (
                        <a href={provider.social.linkedin} target='_blank' rel='noopener noreferrer'>
                          <i className='fab fa-linkedin fa-2x' />
                          {provider.social.linkedin}
                        </a>
                      )}
                    </div>
                    <div>
                      {provider.social && provider.social.instagram && (
                        <a href={provider.social.instagram} target='_blank' rel='noopener noreferrer'>
                          <i className='fab fa-instagram fa-2x' />
                          {provider.social.instagram}
                        </a>
                      )}
                    </div>
                  </Col>
                </Row>

                <div className='dash-buttons'>
                  <button type='button' className='btn btn-danger' disable={ isSubmitting.toString() } onClick={()=>deleteProviderProfile()}>
                    Delete Profile
                  </button>
                </div>
        </CardBody> 
        ) : ''
      }


      </Card>

    <Modal isOpen={displayModalPhotoUpload} toggle={() => toggleModalPhotoUpload(!displayModalPhotoUpload)}>
      <ModalBody>
        <ProviderProfileImageUpload 
          providerId={provider._id}
          displayProviderInfo={displayProviderInfo}
          toggleProviderInfo={toggleProviderInfo}
        />
      </ModalBody>
    </Modal>

    </Fragment>
  )
       

};

export default ProviderProfileItem;