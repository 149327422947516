import React, { useState, Fragment } from "react";
import { Form, Container, Row, Col, Label, FormGroup, Input, InputGroup, InputGroupAddon, CustomInput, Modal, ModalBody, Spinner } from 'reactstrap';
import capitalizeName from '../../utils/capitalizeName';
import EducationalPhilosophies from '../company/EducationalPhilosophies';
import isValidURL from '../../utils/isValidURL';

const ProviderForm = ({ formData, setFormData, subjData, tagData, setTagData, isSubmitting, onSubmit, onSubjChange, history }) => {

  const {
    category,
    providerName,
    email,
    address,
    city,
    state,
    zip,
    hideAddress,
    edPhil,
    edAndEx,
    parentInvolveLevel,
    parentInvolveNarr,
    meetingFreqNum,
    meetingFreqUnit,
    meetingFreqNarr,
    slotsTotal,
    slotsPrefilled,
    guidelines,
    enrollFee,
    enrollFeeUnit,
    isDropOff,
    genInfo,
    ageStart,
    ageEnd,
    whenOperate,
    typicalDay,
    educationalLeader,
    entranceFee,
    religiousAffiliation,
    website,
    phone,
    youtube,
    facebook,
    twitter,
    instagram,
    linkedin
  } = formData;

  const onChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onCheckedChange = e =>
    setFormData({ ...formData, [e.target.name]: e.target.checked });


  const [ tagEntry, setTagEntry ] = useState('');
  const changeTagEntry = e => {
    if(e.key !== ","){
      setTagEntry(e.target.value);
    }
  }

  const handleTagKeyPress = (e) => {
    if(e.key === "," || e.key === "Enter"){
      e.preventDefault();
      tagData.push(e.target.value);
      setTagEntry('');
    }
  };

  const handleTagButton = (e) => {
    e.preventDefault();
    tagData.push(e.target.value);
    setTagEntry('');
  };

  const onDeleteTag = (string) => {
    const tagArray = tagData.filter(e => e !== string);
    setTagData(tagArray);
  } 

  const [urlInvalid, setUrlInvalid] = useState(false);
  const onUrlChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    if (isValidURL(e.target.value) === false) {
      setUrlInvalid(true);
    } else {
      setUrlInvalid(false);
    }
  };

  const [displaySocialInputs, toggleSocialInputs] = useState(false);
  const [displayModalEdPhil, toggleModalEdPhil] = useState(false);

  return (
      <Fragment>
          <Form className='form provider-form' onSubmit={e => onSubmit(e)}>
            
                            
              <Row form>
                <Col md={12} lg={6}>
                  <FormGroup>
                    <Label className='text-bold'>*Name of Organization</Label>
                    <Input type="text" 
                      name="providerName" 
                      id="providerName" 
                      placeholder="*Name"
                      value={providerName}
                      onChange={e => onChange(e)} 
                      required/>
                  </FormGroup>
                </Col>
                <Col md={12} lg={6}>
                  <FormGroup>
                  <Label className='text-bold'>Email</Label>
                  <Input type="email" 
                      name="email" 
                      id="email" 
                      placeholder="Email Address"
                      value={email}
                      onChange={e => onChange(e)} 
                      />
                  </FormGroup>
                </Col>
              </Row>
              <Row form>
                <Col sm={12} md={8}>
                  <FormGroup>
                    <Label className='text-bold'>*Street Address</Label>
                    <Input type="text" 
                    name="address" 
                    id="address" 
                    placeholder="1234 Main St"
                    value={address}
                    onChange={e => onChange(e)} 
                    required/>
                  </FormGroup>
                </Col>
                <Col sm={12} md={4}>
                  <div className="space d-none d-md-block"/>
                  <FormGroup>
                      <CustomInput type="switch" 
                        id="hideAddress" 
                        name="hideAddress" 
                        checked={hideAddress} 
                        onChange={e => onCheckedChange(e)} 
                        label="Hide address on public profile" 
                      />
                  </FormGroup>
                </Col>
              </Row>
              <Row form>
                <Col md={12} lg={6}>
                  <Label className='text-bold'>*City</Label>
                  <FormGroup>
                    <Input type="text" 
                    name="city" 
                    id="city" 
                    placeholder="City"
                    value={city}
                    onChange={e => onChange(e)} 
                    required/>
                  </FormGroup>
                </Col>
                <Col md={12} lg={4}>
                  <Label className='text-bold'>*State</Label>
                  <FormGroup>
                    <Input type="select" 
                    name="state" 
                    id="state" 
                    value={state}
                    onChange={e => onChange(e)}
                    required>
                      <option value="">Select State...</option>
                      <option value="AL">Alabama</option>
                      <option value="AK">Alaska</option>
                      <option value="AZ">Arizona</option>
                      <option value="AR">Arkansas</option>
                      <option value="CA">California</option>
                      <option value="CO">Colorado</option>
                      <option value="CT">Connecticut</option>
                      <option value="DE">Delaware</option>
                      <option value="DC">District Of Columbia</option>
                      <option value="FL">Florida</option>
                      <option value="GA">Georgia</option>
                      <option value="GU">Guam</option>
                      <option value="HI">Hawaii</option>
                      <option value="ID">Idaho</option>
                      <option value="IL">Illinois</option>
                      <option value="IN">Indiana</option>
                      <option value="IA">Iowa</option>
                      <option value="KS">Kansas</option>
                      <option value="KY">Kentucky</option>
                      <option value="LA">Louisiana</option>
                      <option value="ME">Maine</option>
                      <option value="MD">Maryland</option>
                      <option value="MA">Massachusetts</option>
                      <option value="MI">Michigan</option>
                      <option value="MN">Minnesota</option>
                      <option value="MS">Mississippi</option>
                      <option value="MO">Missouri</option>
                      <option value="MT">Montana</option>
                      <option value="NE">Nebraska</option>
                      <option value="NV">Nevada</option>
                      <option value="NH">New Hampshire</option>
                      <option value="NJ">New Jersey</option>
                      <option value="NM">New Mexico</option>
                      <option value="NY">New York</option>
                      <option value="NC">North Carolina</option>
                      <option value="ND">North Dakota</option>
                      <option value="OH">Ohio</option>
                      <option value="OK">Oklahoma</option>
                      <option value="OR">Oregon</option>
                      <option value="PA">Pennsylvania</option>
                      <option value="Puerto Rico">Puerto Rico</option>
                      <option value="RI">Rhode Island</option>
                      <option value="SC">South Carolina</option>
                      <option value="SD">South Dakota</option>
                      <option value="TN">Tennessee</option>
                      <option value="TX">Texas</option>
                      <option value="UT">Utah</option>
                      <option value="VT">Vermont</option>
                      <option value="VA">Virginia</option>
                      <option value="U.S. Virgin Islands">U.S. Virgin Islands</option>
                      <option value="WA">Washington</option>
                      <option value="WV">West Virginia</option>
                      <option value="WI">Wisconsin</option>
                      <option value="WY">Wyoming</option>
                    </Input>
                  </FormGroup>
                </Col>
                <Col md={12} lg={2}>
                  <FormGroup>
                    <Label className='text-bold'>*Zip</Label>
                    <Input 
                    type="text" 
                    name="zip" 
                    id="zip" 
                    placeholder="Zip"
                    value={zip}
                    onChange={e => onChange(e)} 
                    required/>
                  </FormGroup>  
                </Col>
              </Row>

              <Row form>
                <Col md={12} lg={6}>
                  <FormGroup>
                    <Label className='text-bold'>*Category</Label>
                    <Input type="select" 
                    name="category" 
                    id="category" 
                    value={category}
                    onChange={e => onChange(e)} 
                    required>
                      <option value="">Select category...</option>
                      <option value="cooperative">Cooperative (Co-op)</option>
                      <option value="tutorial">Tutorial or Tutor-Led Collective</option>
                      <option value="hybrid homeschool">Hybrid Homeschool</option>
                      <option value="microschool">Microschool</option>
                      <option value="homeschool enrichment center">Homeschool Enrichment Center</option>
                      <option value="forest school">Forest School</option>
                      <option value="resource center">Homeschool Resource Center</option>
                      <option value="tutor">Private Tutor</option>
                      <option value="umbrella">Umbrella Organization</option>
                      <option value="support group">Support Group</option>
                      <option value="park">Park</option>
                      <option value="museum">Museum</option>
                      <option value="library">Library</option>
                      <option value="nature center">Nature Center</option>
                      <option value="farm">Farm</option>
                      <option value="aquarium">Aquarium</option>
                      <option value="zoo">Zoo</option>
                      <option value="other">Other Organization</option>
                    </Input>
                  </FormGroup>
                </Col>
              </Row>

              <Row form>
                <Col md={12} lg={4}>
                    <FormGroup>
                      <Label for="phone" className='text-bold'>Phone Number </Label>
                        <Input type="tel" 
                        name="phone" 
                        id="phone" 
                        value={phone}
                        placeholder="Phone Number"
                        onChange={e => onChange(e)}
                        >          
                        </Input>
                      </FormGroup>
                  </Col>
                  <Col md={12} lg={8}>
                    <FormGroup>
                        <Label for="phone" className='text-bold'>Website URL</Label>
                        <Input type="url"
                        invalid={urlInvalid} 
                        name="website" 
                        id="website" 
                        value={website}
                        placeholder="Website URL (http://...)"
                        onChange={e => onUrlChange(e)}
                        >          
                        </Input>
                      </FormGroup>
                      { urlInvalid &&
                        <div>
                          <ul className='text-danger'>
                            <li>-The URL must start with either "http://" or "https://"</li>
                          </ul>
                        </div>
                      }
                  </Col>
              </Row>

              <Row>
                <Col>
                  <FormGroup>
                    <Label for="religiousAffiliation" className='text-bold'>*Religious Affiliation</Label>
                    { category === "tutor" ? 
                      <div><small>If you do not plan to incorporate religious teaching into your classes, please indicate "Secular."</small></div>
                      :
                      <div><small>Please indicate if your organization incorporates religious teachings, otherwise choose "Secular."</small></div>
                    }
                    <Input type="select" 
                    name="religiousAffiliation" 
                    id="religiousAffiliation" 
                    value={religiousAffiliation}
                    onChange={e => onChange(e)}
                    required>
                      <option value="">Choose one...</option>
                      <option value="Secular">Secular</option>
                      <option value="Christianity">Christian</option>
                      <option value="Spiritual">Spiritual</option>
                      <option value="Judaism">Jewish</option>
                      <option value="Islam">Muslim</option>
                      <option value="Hinduism">Hindu</option>
                      <option value="Buddhism">Buddhist</option>
                    </Input>
                  </FormGroup>
                
                </Col>
              </Row>

              <Row form>
                <Col>
                  <FormGroup>
                    <Label for="genInfo" className='text-bold'>Tell people about your program. What is your primary goal for the kids? What differentiates you from others? What do you offer your kids and their families?</Label>
                    <Input 
                      type='textarea' 
                      rows='8'
                      name='genInfo'
                      value={genInfo}
                      onChange={e => onChange(e)}
                    />
                  </FormGroup>
                </Col>
              </Row>

             {/* category === 'cooperative' || category === 'tutorial' || category === 'homeschool enrichment center' || category === 'microschool' || category === 'umbrella' || category === 'park' || category === 'museum' || category === 'library' || category === 'natureCenter' ?

              <Fragment>
                <Row form>
                  <Col>
                    <FormGroup>
                      <Label for="educationalLeader" className='text-bold'>Educational leader or primary contact's name</Label>
                      <Input type="text" 
                        name="educationalLeader" 
                        id="educationalLeader" 
                        placeholder="Contact Name"
                        value={educationalLeader}
                        onChange={e => onChange(e)} />
                    </FormGroup>
                  </Col>
                </Row>
              </Fragment>
            : ''*/}

            { category === "cooperative" || category === 'tutorial' || category === 'hybrid homeschool' || category === 'umbrella' || category === 'tutor' || category === 'support group' || category === 'homeschool enrichment center' || category === 'microschool' ?

              <Fragment>
                <br/>
                <div className="text-bold">What ages do you cater to?</div>
                <Row form>
                  <Col md={12} lg={6}>
                    <FormGroup>
                      <Label for="ageStart" className='text-bold'>Age Range Start (0-18)</Label>
                      <Input type="number" 
                        name="ageStart" 
                        id="ageStart" 
                        value={ageStart}
                        onChange={e => onChange(e)} />
                    </FormGroup>
                  </Col>
                  <Col md={12} lg={6}>
                    <FormGroup>
                      <Label for="ageEnd" className='text-bold'>Age Range End (0-18)</Label>
                      <Input type="number" 
                        name="ageEnd" 
                        id="ageEnd" 
                        value={ageEnd}
                        onChange={e => onChange(e)} />
                    </FormGroup>
                  </Col>
                </Row>
              </Fragment>
            :''}

            { category === "cooperative" || category === 'tutorial' || category === 'hybrid homeschool' || category === 'umbrella' || category === 'tutor' || category === 'support group' || category === 'homeschool enrichment center' || category === 'microschool' ?

              <Fragment>
                <br/>
                <h5></h5>
                <Row form>
                  <Col>
                  <FormGroup>
                    <Label for="whenOperate" className='text-bold'>What days each week do you operate? What times? Year round/seasonally? (Add all scheduling details here)</Label>
                    <Input 
                      type='textarea' 
                      rows='3'
                      name='whenOperate'
                      value={whenOperate}
                      onChange={e => onChange(e)}
                    />
                  </FormGroup>
                </Col>
                </Row>
              </Fragment>
            :''}

            { category === "cooperative" || category === 'tutorial' || category === 'hybrid homeschool' || category === 'umbrella' || category === 'tutor' || category === 'support group' || category === 'homeschool enrichment center' || category === 'microschool' ?

              <Fragment>
                <br/>
                <h5></h5>
                <Row form>
                  <Col>
                  <FormGroup>
                    <Label for="typicalDay" className='text-bold'>Describe a typical day.</Label>
                    <Input 
                      type='textarea' 
                      rows='8'
                      name='typicalDay'
                      value={typicalDay}
                      onChange={e => onChange(e)}
                    />
                  </FormGroup>
                </Col>
                </Row>
              </Fragment>
            :''}

            {/* category === "cooperative" || category === 'tutorial' || category === 'umbrella' || category === 'homeschool enrichment center' || category === 'microschool' ?
              <Fragment>
                <Row form>
                  <Col md={12} lg={6}>
                    <FormGroup>
                      <Label for="slotsTotal" className='text-bold'>How many children can you accept into your group?</Label>
                      <Input type="number" 
                        name="slotsTotal" 
                        id="slotsTotal" 
                        value={slotsTotal}
                        onChange={e => onChange(e)} />
                    </FormGroup>
                  </Col>
                  <Col md={12} lg={6}>
                    <FormGroup>
                      <Label for="slotsPrefilled" className='text-bold'>How many of those slots are already filled?</Label>
                      <Input type="number" 
                        name="slotsPrefilled" 
                        id="slotsPrefilled" 
                        value={slotsPrefilled}
                        onChange={e => onChange(e)} />
                    </FormGroup>
                  </Col>
                </Row>
              </Fragment>

            : " " */}
     
            { category === "cooperative" || category === 'tutorial'  || category === 'hybrid homeschool' || category === 'forest school' ?

              <Row form>
                <Col>
                  <FormGroup>
                      <Label htmlFor="edPhil" className='text-bold'>
                        Educational Philosophy {' '}
                        <button type='button' className='basic-button text-superscript'
                          onClick={() => toggleModalEdPhil(!displayModalEdPhil)}>
                          <i className="fas fa-info-circle"/>
                        </button>
                      </Label>
                    <Input type="select" 
                    name="edPhil" 
                    id="edPhil" 
                    value={edPhil}
                    onChange={e => onChange(e)}>
                      <option value="">None</option>
                      <option value="Traditional">Traditional</option>
                      <option value="Classical Education">Classical Education</option>
                      <option value="Unit Study">Unit Study</option>
                      <option value="Leadership Education">Leadership Education</option>
                      <option value="Unschooling">Unschooling</option>
                      <option value="Montessori">Montessori</option>
                      <option value="Charlotte Mason">Charlotte Mason</option>
                      <option value="Eclectic">Eclectic</option>
                      <option value="Undecided">Undecided</option>
                    </Input>
                  </FormGroup>
                
                </Col>

              </Row>

            : '' }


            {/* category === "tutor" ?

              <Row form>
                <Col>
                  <FormGroup>
                    <Label for="edAndEx" className='text-bold'>What education and/or experiences qualify you to provide instruction? (Why should a parent trust you to adequately teach their child?)</Label>
                    <Input 
                      type='textarea'
                      placeholder='Degrees/experiences/certifications/lifelong personal interests in...?'
                      rows='7'
                      name='edAndEx'
                      value={edAndEx}
                      onChange={e => onChange(e)}
                    />
                  </FormGroup>
                </Col>
              </Row>

            : "" */}


            {/* category === 'cooperative' || category === 'tutorial' || category === 'resourceCenter' || category === 'umbrella' || category === 'support group' ?

              <Row form>
                <Col md={12} lg={6}>
                  <FormGroup>
                      <Label for="enrollFee" className='text-bold'>Enrollment Fee ($)</Label>
                      <div><small>Note: If registering for individual classes or services costs extra, please explain in the general info section.</small></div>
                      <Input type="number" 
                      name="enrollFee" 
                      id="enrollFee" 
                      value={enrollFee}
                      placeholder="200"
                      onChange={e => onChange(e)}
                      >          
                      </Input>
                    </FormGroup>
                </Col>
                <Col md={12} lg={3}>
                  <FormGroup inline>
                    <Label for="enrollFeeUnit" className='text-bold'>Fee Coverage Length</Label>
                    <div><small>(enrollment fee per ...?)</small></div>
                    <Input type="select" 
                      name="enrollFeeUnit" 
                      id="enrollFeeUnit" 
                      value={enrollFeeUnit}
                      onChange={e => onChange(e)}
                      > 
                        <option value="">Choose one...</option>
                        <option value="month">month</option>
                        <option value="quarter">quarter</option>
                        <option value="semester">semester</option>
                        <option value="year">year</option>
                    </Input>
                  </FormGroup>
                </Col>
              </Row>

            : ''*/}

            {/* category === 'park' || category === 'museum'  || category === 'natureCenter' ?
              <Row form>
                <Col>
                  <FormGroup>
                      <Label for="entranceFee" className='text-bold'>Entrance Fee ($)</Label>
                      <div><small>Note: If registering for individual classes or services costs extra, please explain in the general info section.</small></div>
                      <Input type="number" 
                      name="entranceFee" 
                      id="entranceFee" 
                      value={entranceFee}
                      placeholder="10"
                      onChange={e => onChange(e)}
                      >          
                      </Input>
                    </FormGroup>
                </Col>
              </Row>
            : ''*/}


            {/* category === 'cooperative' || category === 'tutorial' ?

              <Row form>
                <Col>
                  <FormGroup>
                    <Label for="parentInvolveLevel" className='text-bold'>Level of Involvement</Label>
                    <Input type="select" 
                      name="parentInvolveLevel" 
                      id="parentInvolveLevel" 
                      value={parentInvolveLevel}
                      onChange={e => onChange(e)}
                    > 
                      <option value="">Choose one...</option>
                      <option value="High">High</option>
                      <option value="Medium">Medium</option>
                      <option value="Low">Low</option>         
                    </Input>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label for="parentInvolveNarr" className='text-bold'>Briefly outline what duties families are expected to take on.</Label>
                    <Input 
                      type='textarea'
                      placeholder='Example: Families will co-teach, run administrative duties, clean up, and host the group in their home on a rotating basis.'
                      rows='5'
                      name='parentInvolveNarr'
                      value={parentInvolveNarr}
                      onChange={e => onChange(e)}
                    />
                  </FormGroup>
                </Col>
              </Row>

            : ''*/}

            { category === 'tutor' || category === 'cooperative' || category === 'tutorial' || category === 'hybrid homeschool' || category === 'forest school' ?

              <Row form>
                <Col>
                  <FormGroup>
                    <Label for="isDropOff" className='text-bold'>Can parents drop off their children?</Label>
                    <div><small>If not, parents must be there the entire time their children are on the premises. Please note that there may be state laws governing drop-off services.  Please check with your state to see if licenses are required. </small></div>
                    <Input type="select" 
                    name="isDropOff" 
                    id="isDropOff" 
                    value={isDropOff}
                    onChange={e => onChange(e)}>
                      <option value=''>Choose one...</option>
                      <option value='false'>No</option>
                      <option value='true'>Yes</option>
                    </Input>
                  </FormGroup>
                
                </Col>
              </Row>

            : '' }

            {/* category === 'cooperative' || category === 'tutorial' ?
              
              <Fragment>
                <h5>How often do you operate?</h5>
                <Row form>
                  <Col md={12} lg={3}>
                    <FormGroup>
                      <Label for="meetingFreqNum" className='text-bold'>Days?</Label>
                      <Input type="number" 
                        name="meetingFreqNum" 
                        id="meetingFreqNum" 
                        value={meetingFreqNum}
                        onChange={e => onChange(e)}
                        >        
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col md={12} lg={3}>
                    <FormGroup inline>
                      <Label for="meetingFreqUnit" className='text-bold'>...per?</Label>
                      <Input type="select" 
                        name="meetingFreqUnit" 
                        id="meetingFreqUnit" 
                        value={meetingFreqUnit}
                        onChange={e => onChange(e)}
                        > 
                          <option value="">Choose one...</option>
                          <option value="weekly">week</option>
                          <option value="monthly">month</option>
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col md={12} lg={6}>
                    <FormGroup>
                      <Label for="meetingFreqNarr" className='text-bold'>Describe the typical schedule.</Label>
                      <Input 
                        type='textarea'
                        placeholder='Example: We meet the first Friday of every month from 7:00am to 6:00pm.  You can come and go as you please during those times.  Classes fall in those hours and a monthly calendar will indicate when they are held.' 
                        rows='5'
                        name='meetingFreqNarr'
                        value={meetingFreqNarr}
                        onChange={e => onChange(e)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Fragment>

            : ""*/}

            {/* category === "cooperative" || category === "tutorial" ? 
              
              <Row form>
                <Col>
                  <FormGroup>
                    <Label for="guidelines" className='text-bold'>Outline any guidelines, rules, and regulations that you have.</Label>
                    <Input 
                      type='textarea' 
                      rows='8'
                      name='guidelines'
                      value={guidelines}
                      onChange={e => onChange(e)}
                    />
                  </FormGroup>
                </Col>
              </Row>

            : "" */}
              


              <Row>
                <Col>
                  <FormGroup>
                    <span className='text-bold'>What subjects can kids learn about from { category && category === 'tutor' ? 'you' : 'your organization' }?</span>
                    <Container>
                      {Object.keys(subjData).map(sub => 
                          <Label check key={sub} className='subjects-input'>
                            <Input type="checkbox" name={sub} checked={subjData[sub]} onChange={e => onSubjChange(e)}/>
                            {(sub === 'pe') ? 'PE' : ` ${capitalizeName(sub)}`}
                          </Label>
                        )
                      }
                    </Container>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <span className='text-bold'>Tags: </span> Add any other specific things people can learn from { category === 'tutor' ? ' you ' : ' your organization ' } or resources you at your disposal?
                    <div><small>(e.g. Subject subcategories like "algebra", "biology", or "piano", terms like "financial literacy", "local history", or "horsemanship", or resources like "3d printer", "planetarium", or "virtual reality equipment" )</small></div>
                    <div><small>Type a tag and enter it by pressing the "," or "Enter" keys.</small></div>
                    <div>
                      <InputGroup>
                        <Input type="text" name="tagEntry" id="tagEntry" value={tagEntry} onChange={(e)=>changeTagEntry(e)} onKeyPress={(e)=>handleTagKeyPress(e)}/>
                        <InputGroupAddon addonType="append" >
                          <button type='button' className='append-form-button btn-modal-blue' value={tagEntry} onClick={(e)=>handleTagButton(e)}>Add item</button>
                        </InputGroupAddon>
                      </InputGroup>
                      {tagData.map((tag, index) =>
                        (
                          <span key={index} className='btn btn-modal-red'>
                            {tag}
                            {' '}
                            <button type='button' className='basic-button' onClick={()=>onDeleteTag(tag)}><i className='fas fa-times color-white'/></button>
                          </span>
                        ))
                      }
                    </div>
                  </FormGroup>
                </Col>
              </Row>


            <div className='my-2'>
              <button
                onClick={() => toggleSocialInputs(!displaySocialInputs)}
                type='button'
                className='btn btn-modal-blue'
              >
                Add Social Network Links
              </button>
              <span>Optional</span>
            </div>

            {displaySocialInputs && (
              <Fragment>
                <div className='form-group social-input'>
                  <i className='fab fa-twitter fa-2x' />
                  <input
                    type='text'
                    placeholder='https://twitter.com/...'
                    name='twitter'
                    value={twitter}
                    onChange={e => onChange(e)}
                  />
                </div>

                <div className='form-group social-input'>
                  <i className='fab fa-facebook fa-2x' />
                  <input
                    type='text'
                    placeholder='https://facebook.com/...'
                    name='facebook'
                    value={facebook}
                    onChange={e => onChange(e)}
                  />
                </div>

                <div className='form-group social-input'>
                  <i className='fab fa-youtube fa-2x' />
                  <input
                    type='text'
                    placeholder='https://youtube.com/...'
                    name='youtube'
                    value={youtube}
                    onChange={e => onChange(e)}
                  />
                </div>

                <div className='form-group social-input'>
                  <i className='fab fa-linkedin fa-2x' />
                  <input
                    type='text'
                    placeholder='https://linkedin.com/...'
                    name='linkedin'
                    value={linkedin}
                    onChange={e => onChange(e)}
                  />
                </div>

                <div className='form-group social-input'>
                  <i className='fab fa-instagram fa-2x' />
                  <input
                    type='text'
                    placeholder='https://instagram.com/...'
                    name='instagram'
                    value={instagram}
                    onChange={e => onChange(e)}
                  />
                </div>
              </Fragment>
            )}

            <button
              disabled={isSubmitting}
              type="submit"
              className="btn btn-modal-blue btn-set-WH"
              >
              <span>
                {isSubmitting ? (
                  <Spinner size="sm" className='color-white'/>
                ) : (
                  "Submit"
                )}
              </span>
            </button>

            <button type='button' className='btn btn-light btn-set-WH' onClick={()=>history.goBack()}>
              Cancel
            </button>
          </Form>

        <Modal isOpen={displayModalEdPhil} toggle={() => toggleModalEdPhil(!displayModalEdPhil)}>
          <ModalBody>
            <EducationalPhilosophies />
          </ModalBody>
        </Modal>
      </Fragment>
  );
}

export default ProviderForm;