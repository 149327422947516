import React, { useState, Fragment } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { uploadProfileImage } from "../../actions/profile";
import { Form, Label, FormGroup, Input, Button, Spinner } from 'reactstrap';
import ImageContainer from '../layout/ImageContainer';

const ProfileImageUpload = ({ uploadProfileImage }) => {
  
  const [ isSubmitting, setIsSubmitting ] = useState(false);
  const [file, setFile] = useState('');
  const [thumbnail, setThumbnail] = useState("");
  const [ fileSize, setFileSize ] = useState(0);

  const onFileChange = e => {
    if (e.target.files[0]) {
      setFile(e.target.files[0]);
      setFileSize(e.target.files[0].size);
      setThumbnail(URL.createObjectURL(e.target.files[0]));
    }
  };

  const onSubmit = async e => {
    e.preventDefault();
    setIsSubmitting(true);
    const formData1 = {
      image: file
    };

    const formData = Object.keys(formData1).reduce((f, k) => {
      f.append(k, formData1[k]);
      return f;
    }, new FormData());
    await uploadProfileImage(formData);

    window.location.reload();
  };

  return (
    <Fragment>
      <h1 className='large text-primary-ms'>Upload a Photo!</h1>
      <Form className='form' onSubmit={e => onSubmit(e)}>
        
	      <FormGroup>
	        <Label for="image">Upload Image (optional)  </Label>
	        <small>(maximum upload size is 1MB)</small>
	        <Input
	          type="file"
	          accept='image/*'
	          id='image'
	          name='image'
	          onChange={e => onFileChange(e)}
	        />
	      </FormGroup>
        { fileSize ? 
          <div className={fileSize > 1000000 ? 'color-danger text-bold':''}>
            {fileSize > 1000000 ? `Size: ${(fileSize/1000000).toFixed(2)} MB (file must be less than 1MB)`:''}
          </div>
          :''
        }
        {thumbnail ? (
          <div className='text-center'>
            <ImageContainer
              src={thumbnail}
              width='150px' 
              height='150px' 
              className='' 
              alt='profile image preview'
            />
          </div>
        ) : null}

        <button
          disabled={isSubmitting || fileSize > 1000000}
          type="submit"
          className="btn btn-modal-blue btn-set-WH"
        >
          <span>
            {isSubmitting ? (
              <Spinner size="sm" className='color-white'/>
            ) : (
              "Submit"
            )}
          </span>
        </button>
      </Form>
    </Fragment>
  );
};

ProfileImageUpload.propTypes = {
  uploadProfileImage: PropTypes.func.isRequired
};


export default connect(
  null,
  { uploadProfileImage },
)(withRouter(ProfileImageUpload));