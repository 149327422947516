import api from '../utils/api';
import { setAlert, setLongAlert } from './alert';

import {
  GET_RESOURCE_REVIEWS,
  ADD_RESOURCE_REVIEW,
  RESOURCE_REVIEW_DELETED,
  RESOURCE_REVIEW_ERROR
} from './types';

// Get all resource reviews
export const getResourceReviews = (resourceId) => async dispatch => {

  try {
    const res = await api.get(`/v1/resourceReviews/${resourceId}`);

    dispatch({
      type: GET_RESOURCE_REVIEWS,
      payload: res.data
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: RESOURCE_REVIEW_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};


//Post a resource review
export const createResourceReview = (review, rating, resourceId) => async dispatch => {

  const formData = {
    review,
    rating
  };


  try {

    const res = await api.post(`/v1/resourceReviews/${resourceId}`, formData);
    console.log(res.data);
    
    dispatch({
      type: ADD_RESOURCE_REVIEW,
      payload: res.data
    });

    dispatch(setAlert('Thanks for your review!', 'success'));
    
  } catch (err) {
    
    const errors = err.response.data.errors;

    if (errors) {
      window.scrollTo(0,0);
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    };

    console.log(err);
    if (err.response) {
        dispatch({
          type: RESOURCE_REVIEW_ERROR,
          payload: { msg: err.response.data, status: err.response.status }
        });
        // console.log(err.response);
        window.scrollTo(0,0);
        dispatch(setLongAlert(err.response.data.msg, 'danger'));
      } else if (err.request) {
        console.log(err.request);
      } else {
        console.log('Error msg: ', err.message);
      }
  }
};


// Delete resource review
export const deleteResourceReview = (reviewId) => async dispatch => {
  if (window.confirm('Are you sure? This CANNOT be undone!')) {
    
    try {
      await api.delete(`/v1/resourceReviews/${reviewId}`);
      
      dispatch({
        type: RESOURCE_REVIEW_DELETED,
        payload: reviewId
      });

      window.scrollTo(0,0);
      dispatch(setAlert('This review has been permanantly deleted.', 'success'));

    } catch (err) {
      if (err.response) {
        dispatch({
          type: RESOURCE_REVIEW_ERROR,
          payload: { msg: err.response.data, status: err.response.status }
        });
      } else if (err.request) {
        console.log(err.request);
      } else {
        console.log('Error msg', err.message);
      }
      window.scrollTo(0,0);
      dispatch(setAlert('There was an error. Please reload the page and try again.', 'danger'));
      console.log(err.config);
    }
  }
};
