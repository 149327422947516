import React, { Fragment, useState, useEffect } from "react";
import { Button, Container, Row, Col, Table, Modal, ModalHeader, ModalBody } from "reactstrap";
import Moment from "react-moment";
import {
  createUpdateChild,
  getAllChild,
  deleteChild,
} from "../../actions/child";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import AddChildBriefForm from './AddChildBriefForm';

const CreateChild = ({ getAllChild, child: {childLoading, children}, showChildTable }) => {
  
  const [modal, setModal] = useState(false);

  const toggle = () => {
    setModal(!modal);
  }

  const [formData, setFormData] = useState({
    child_id: "",
    childNameData: "",
    childBirthDay: "",
    gender: ""
  });

  const [checkGuardian, togglecheckGuardian] = useState(false);
  const [ isVisible, toggleIsVisible] = useState(true);

  const fetchChildren = async () => {
    await getAllChild();
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    if (formData.child_id != "") {
      const data = {
        child_id: formData.child_id,
        name: formData.childNameData,
        dob: formData.childBirthDay,
        isUserParentOrGuardian: checkGuardian,
        gender: formData.gender,
        isVisible: isVisible
      };
      await createUpdateChild(data);
      fetchChildren();
    } else {
      const data = {
        name: formData.childNameData,
        dob: formData.childBirthDay,
        isUserParentOrGuardian: checkGuardian,
        gender: formData.gender,
        isVisible: isVisible
      };
      await createUpdateChild(data);
      fetchChildren();
    }
  };

  function onEdit(id) {
    toggle();
    const data = childList.find((e) => e._id == id);
    // console.log(data);
    const date = data.dateOfBirth.substring(0, 10);
    // console.log(date);

    setFormData({
      child_id: data._id,
      childNameData: data.name,
      childBirthDay: date,
      gender: data.gender
    });
    toggleIsVisible(data.isVisible);
    togglecheckGuardian(data.isUserParentOrGuardian);
  }

  function onDelete(id) {
    const c = window.confirm("Are you sure?");

    if (c) {
      deleteChild(id);
      fetchChildren();
    }
  }

  const [childList, setchildList] = useState([]);

  useEffect(()=>{
    fetchChildren();
  },[]);

  useEffect(() => {
    setchildList(children);
  }, [children, childLoading]);

  const [whyAddChild, toggleWhyAddChild] = useState(false);

  return (
    <Fragment>
      <Container>
        <div className="dash-buttons">
          <Row>
            <Col>
              <div>
                <Button onClick={()=> {
                    setModal(!modal);
                    setFormData({
                      child_id: '',
                      childNameData: '',
                      childBirthDay: '',
                      gender: ''
                    });
                    togglecheckGuardian(false);
                    toggleIsVisible(true);
                    } 
                  } className="btn btn-modal-blue">
                  <i className="fas fa-plus" /> Add Child
                </Button>
              </div>
            </Col>
          </Row>
        </div>
        { showChildTable ?
          <Row>
            <Col>
            <div className='mt-4' />
            <h4>Children</h4>
            <br />
              <Table>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>DOB</th>
                    <th>Sex</th>
                    <th><i className="fas fa-edit"/></th>
                  </tr>
                </thead>
                <tbody className='text-075rem'>
                  { childList
                    ? childList.map((child, i) => (
                        <tr key={i}>
                          <td>{child.name}</td>
                          <td>
                            <Moment format="MM / DD / YYYY" utc>
                              {child.dateOfBirth}
                            </Moment>
                          </td>
                          <td>{child.gender === 'male' ? `M` : child.gender === 'female' ? `F` : child.gender === 'other' ? `O` : ``}</td>
                          <td>
                            <button onClick={() => onEdit(child._id)} type='button' className='basic-button'>
                              <i className="fas fa-edit"/>
                            </button>
                            {'   '}
                            <button onClick={() => onDelete(child._id)} type='button' className='basic-button'>
                              <i className="fas fa-trash"/>
                            </button>
                          </td>
                        </tr>
                      ))
                    : ""}
                </tbody>
              </Table>
            </Col>
          </Row>
          :''
        }
      </Container>

        <Modal isOpen={modal} toggle={toggle}>
          <ModalHeader toggle={toggle}>Add/Edit a Child</ModalHeader>
          <ModalBody>
            <AddChildBriefForm 
              setFormData={setFormData}
              formData={formData} 
              onSubmit={onSubmit}
              isVisible={isVisible}
              toggleIsVisible={toggleIsVisible}
              togglecheckGuardian={togglecheckGuardian}
              checkGuardian={checkGuardian}
              toggle={toggle}
            />
          </ModalBody>
        </Modal>

        <Modal isOpen={whyAddChild} toggle={()=>toggleWhyAddChild(!whyAddChild)}>
          <div className='d-flex justify-content-end mt-1 mx-3'>
            <button className="basic-button" onClick={() => toggleWhyAddChild(!whyAddChild)}>
            <i className='fa fa-times color-light-grey'/>
            </button>
          </div>
        <ModalBody>
            This sets up a portfolio for your child.  Each time you scan a MySchool QR code, a new entry will be created in their portfolio and it'll add another chance for you to win cool educational prizes!
          </ModalBody>
        </Modal>

    </Fragment>
  );
};

CreateChild.defaultProps = {
  showChildTable: true
};  

CreateChild.propTypes = {
  getAllChild: PropTypes.func.isRequired,
  child: PropTypes.object
};

const mapStateToProps = (state) =>({
  child: state.child
});

export default connect(mapStateToProps, { getAllChild })(CreateChild);
