import React, { Fragment } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import RegisterAndProfile from './RegisterAndProfile';
import { useHistory } from 'react-router-dom';

const RegisterAndProfileModal = ({ toggleRegAndProfModal, regAndProfModal }) => {
  
  return (
    <Fragment>
      <Modal isOpen={regAndProfModal} centered toggle={() => toggleRegAndProfModal(!regAndProfModal)}>
        <ModalBody className='text-center'>
          <div className='d-flex justify-content-end'>
            <button className="basic-button" onClick={() => toggleRegAndProfModal(!regAndProfModal)}>
            <i className='fa fa-times color-light-grey'/>
            </button>
          </div>
          <RegisterAndProfile />
        </ModalBody>
      </Modal>

    </Fragment>
  );
};

export default RegisterAndProfileModal;