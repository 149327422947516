import React, { useState, Fragment } from "react";
import { Form, Container, Row, Col, Label, FormGroup, Input, Tooltip, Spinner, Card, CardBody } from 'reactstrap';
import capitalizeName from '../../utils/capitalizeName';
import api from '../../utils/api';

const ResourceForm = ({ providers, formData, setFormData, subjData, tagData, setTagData, onSubmit, onSubjChange, history, isEditForm, isSubmitting }) => {

  const {
    creditedTo,
    category,
    resourceName,
    email,
    address,
    address_full,
    city,
    state,
    zip,
    subjectOfferings,
    religiousAffiliation,
    genInfo,
    website,
    phone
  } = formData;

  const onChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onCheckedChange = e =>
    setFormData({ ...formData, [e.target.name]: e.target.checked });

  const [ tagEntry, setTagEntry ] = useState('');
  const changeTagEntry = e => {
    if(e.key !== ","){
      setTagEntry(e.target.value);
    }
  }

  const handleTagKeyPress = (e) => {
    if(e.key === "," || e.key === "Enter"){
      e.preventDefault();
      tagData.push(e.target.value);
      setTagEntry('');
    }
  };

  const onDeleteTag = (string) => {
    const tagArray = tagData.filter(e => e !== string);
    setTagData(tagArray);
  };

  const [ textAreaCount, setTextAreaCount ] = useState(0);
  const [ showCreditTooltip, toggleCreditTooltip ] = useState(false);

  const onTextAreaChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setTextAreaCount(e.target.value.length);
  };

  const [searchAhead, setSearchAhead] = useState([]);
  const [gettingSearchAhead, setGettingSearchAhead] = useState(false);
  const getSearchAheadResults = async (string) => {
    setFormData({...formData, address: string});
    //search ahead updates every 5 characters
    if (string.length > 0 && string.length % 5 === 0) { 
      setGettingSearchAhead(true);
      const searchAheadResults = await api.get(`/v1/homeschoolmap/searchAhead/query?string=${string}`);
      setSearchAhead(searchAheadResults.data.data);
      setGettingSearchAhead(false);
    }
  };

  const addressInput = (slug) => {
    const selectedPoi = searchAhead.find(result => result.slug === slug);
    setSearchAhead([]);
    setFormData({...formData,
      address_full: selectedPoi.displayString, 
      city: selectedPoi.place.properties.city,
      state: selectedPoi.place.properties.stateCode,
      zip: selectedPoi.place.properties.postalCode,
      address: selectedPoi.place.properties.street
    });
  };

  // const [showStep, setShowStep] = useState(0);
  // const nextStep = () => setShowStep(showStep+1);

  return (
    <Fragment>
      <Form className='form bold-labels' onSubmit={e => onSubmit(e)}>

        <Row form>
          <Col md={6}>
            <FormGroup>
              <Label>*Resource Category</Label>
              <Input type="select" 
              name="category" 
              id="category" 
              value={category}
              onChange={e => onChange(e)} 
              required>
                <option value="">*Category</option>
                <option value="cooperative">Cooperative (Co-op)</option>
                <option value="tutorial">Tutorial or Tutor-Led Collective</option>
                <option value="hybrid homeschool">Hybrid Homeschool</option>
                <option value="homeschool enrichment center">Homeschool Enrichment Center</option>
                <option value="forest school">Forest School</option>
                <option value="microschool">Microschool</option>
                <option value="resource center">Homeschool Resource Center</option>
                <option value="umbrella">Umbrella Organization</option>
                <option value="support group">Support Group</option>
                <option value="park">Park</option>
                <option value="museum">Museum</option>
                <option value="library">Library</option>
                <option value="nature center">Nature Center</option>
                <option value="aquarium">Aquarium</option>
                <option value="zoo">Zoo</option>
                <option value="farm">Farm</option>
                <option value="other">Other Organization</option>
              </Input>
            </FormGroup>
          </Col>
        </Row>

        
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label>*Resource Name</Label>
                <Input type="text" 
                name="resourceName" 
                id="resourceName" 
                placeholder="Resource Name"
                value={resourceName}
                onChange={e => onChange(e)} 
                required/>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
            <Label>Email</Label>
            <Input type="email" 
                name="email" 
                id="email" 
                placeholder="Resource@email.com"
                value={email}
                onChange={e => onChange(e)} 
                />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col>
            <Label>*Street Address</Label>
            <FormGroup>
              <Input type="text"
                name="poi" 
                id="poi" 
                value={address}
                onChange={e => getSearchAheadResults(e.target.value)}
              >
              </Input>
              {gettingSearchAhead || searchAhead.length > 0 ?
                <Card className="my-1" style={{zIndex:1100}}>
                  {gettingSearchAhead ? 
                    <div className="blinker">
                      Finding spots...
                    </div>
                    :
                    <div>
                      <div className='float-right'>
                        <button className="basic-button" onClick={() => setSearchAhead([])}>
                        <i className='fa fa-times color-light-grey'/>
                        </button>
                      </div>
                      <ul>
                        {searchAhead.map((poi, i)=> {
                            return <option key={poi.slug} className="px-2 py-1 selected-item" onClick={() => addressInput(poi.slug)}>{poi.displayString}</option>
                          })
                        }
                      </ul>
                    </div>
                  }
                </Card>
                :''
              }
            </FormGroup>
            {/*<div>
              <button type='button' className='basic-button text-italic' onClick={()=>nextStep()}>Can't find it? Skip this step >></button> 
            </div>*/}
          </Col>
        </Row>

        <Row form>
          <Col md={6}>
            <Label>*City</Label>
            <FormGroup>
              <Input type="text" 
              name="city" 
              id="city" 
              placeholder="City"
              value={city}
              onChange={e => onChange(e)} 
              required/>
            </FormGroup>
          </Col>
          <Col md={4}>
            <Label>*State</Label>
            <FormGroup>
              <Input type="select" 
              name="state" 
              id="state" 
              value={state}
              onChange={e => onChange(e)}
              required>
                <option value="None">*Select State...</option>
                <option value="AL">Alabama</option>
                <option value="AK">Alaska</option>
                <option value="AZ">Arizona</option>
                <option value="AR">Arkansas</option>
                <option value="CA">California</option>
                <option value="CO">Colorado</option>
                <option value="CT">Connecticut</option>
                <option value="DE">Delaware</option>
                <option value="DC">District Of Columbia</option>
                <option value="FL">Florida</option>
                <option value="GA">Georgia</option>
                <option value="GU">Guam</option>
                <option value="HI">Hawaii</option>
                <option value="ID">Idaho</option>
                <option value="IL">Illinois</option>
                <option value="IN">Indiana</option>
                <option value="IA">Iowa</option>
                <option value="KS">Kansas</option>
                <option value="KY">Kentucky</option>
                <option value="LA">Louisiana</option>
                <option value="ME">Maine</option>
                <option value="MD">Maryland</option>
                <option value="MA">Massachusetts</option>
                <option value="MI">Michigan</option>
                <option value="MN">Minnesota</option>
                <option value="MS">Mississippi</option>
                <option value="MO">Missouri</option>
                <option value="MT">Montana</option>
                <option value="NE">Nebraska</option>
                <option value="NV">Nevada</option>
                <option value="NH">New Hampshire</option>
                <option value="NJ">New Jersey</option>
                <option value="NM">New Mexico</option>
                <option value="NY">New York</option>
                <option value="NC">North Carolina</option>
                <option value="ND">North Dakota</option>
                <option value="OH">Ohio</option>
                <option value="OK">Oklahoma</option>
                <option value="OR">Oregon</option>
                <option value="PA">Pennsylvania</option>
                <option value="Puerto Rico">Puerto Rico</option>
                <option value="RI">Rhode Island</option>
                <option value="SC">South Carolina</option>
                <option value="SD">South Dakota</option>
                <option value="TN">Tennessee</option>
                <option value="TX">Texas</option>
                <option value="UT">Utah</option>
                <option value="VT">Vermont</option>
                <option value="VA">Virginia</option>
                <option value="U.S. Virgin Islands">U.S. Virgin Islands</option>
                <option value="WA">Washington</option>
                <option value="WV">West Virginia</option>
                <option value="WI">Wisconsin</option>
                <option value="WY">Wyoming</option>
              </Input>
            </FormGroup>
          </Col>
          <Col md={2}>
            <FormGroup>
              <Label>*Zip</Label>
              <Input 
              type="text" 
              name="zip" 
              id="zip" 
              placeholder="Zip"
              value={zip}
              onChange={e => onChange(e)} 
              required/>
            </FormGroup>  
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="religiousAffiliation">Religious Affiliation</Label>
                <div><small>*Please indicate if the organization incorporates religious teachings, otherwise choose &quot;Secular.&quot;</small></div>
              <Input type="select" 
              name="religiousAffiliation" 
              id="religiousAffiliation" 
              value={religiousAffiliation}
              onChange={e => onChange(e)}
              >
                <option value="">Religious Affiliation</option>
                <option value="Secular">Secular</option>
                <option value="Christianity">Christianity</option>
                <option value="Islam">Islam</option>
                <option value="Hinduism">Hinduism</option>
                <option value="Buddhism">Buddhism</option>
                <option value="Judaism">Judaism</option>
              </Input>
            </FormGroup>
          
          </Col>
        </Row>

        <Row>
          <Col>
            <FormGroup>
              <Label for="genInfo">Why is this is good place for homeschoolers? (max 500 char.)</Label>
              <div><small>Any other important information to share (ages, operating times/seasons, etc)?  <strong>Character Count: {textAreaCount}</strong></small></div>
              <Input 
                type='textarea'
                maxLength='500'
                rows='5'
                name='genInfo'
                value={genInfo}
                onChange={e => onTextAreaChange(e)}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col md={4}>
              <FormGroup>
                <Label for="tel">Phone</Label>
                  <Input type="tel" 
                  name="phone" 
                  id="phone" 
                  value={phone}
                  placeholder="Phone Number"
                  onChange={e => onChange(e)}
                  >          
                  </Input>
                </FormGroup>
            </Col>
            <Col md={8}>
              <FormGroup>
                <Label for="website">Website</Label>
                  <Input type="url" 
                  name="website" 
                  id="website" 
                  value={website}
                  placeholder="Website URL (http://...)"
                  onChange={e => onChange(e)}
                  pattern="https?://.+"
                  >          
                  </Input>
                </FormGroup>
            </Col>
        </Row>

        <Row>
          <Col>
            <FormGroup>
              <span className='text-bold'>What subjects can kids learn about from here?</span>
              <Container>
                {Object.keys(subjData).map(sub => 
                    <Label check key={sub} className='subjects-input'>
                      <Input type="checkbox" name={sub} checked={subjData[sub]} onChange={e => onSubjChange(e)}/>
                      {(sub === 'pe') ? 'PE' : ` ${capitalizeName(sub)}`}
                    </Label>
                  )
                }
              </Container>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <span className='text-bold'>Tags: </span> Add any other specific things kids can learn here that people may search for?
              <div><small>(e.g. Subject subcategories like &quot;algebra&quot;, &quot;biology&quot;, or &quot;piano&quot; or terms like &quot;financial literacy&quot;, &quot;local history&quot;, or &quot;horsemanship&quot; )</small></div>
              <div><small>Type a tag and enter it by pressing the &quot;,&quot; or &quot;Enter&quot; keys.</small></div>
              <div>
                <Input type="text" name="tagEntry" id="tagEntry" value={tagEntry} onChange={(e)=>changeTagEntry(e)} onKeyPress={(e)=>handleTagKeyPress(e)}/>
                {tagData.map((tag, index) =>
                  (
                    <span key={index} className='btn btn-myschool-red'>
                      {tag}
                      {' '}
                      <button type='button' className='basic-button' onClick={()=>onDeleteTag(tag)}><i className='fas fa-times color-white'/></button>
                    </span>
                  ))
                }
              </div>
            </FormGroup>
          </Col>
        </Row>

        { !isEditForm &&
          <Row form>
            <Col>
              <FormGroup>
                <Label>Add a link to your spot?</Label>{' '}<span className='text-superscript' id="creditTooltip"><i className="fas fa-info-circle fa-2x"/></span>
                  <Tooltip placement="right" isOpen={showCreditTooltip} target="creditTooltip" toggle={()=>toggleCreditTooltip(!showCreditTooltip)}>
                    If you have a spot, you can add a link to your spot&apos;s profile page here.  It lets people know who suggested the resource, and increases your exposure to the community! 
                  </Tooltip>
                <Input type="select" 
                name="creditedTo" 
                id="creditedTo" 
                value={creditedTo}
                onChange={e => onChange(e)} 
                >
                  <option value="">Select one of your spots</option>
                  {providers && providers.length > 0 ? 
                    providers.map(provider => 
                      <option value={provider._id} key={provider._id}>{`${provider.providerName} (${provider.category})`}</option>
                      )
                    :
                    <option value="" disabled>(You have no registered spots)</option>
                  }
                </Input>
              </FormGroup>
            </Col>
          </Row>
        }

        <button
          disabled={isSubmitting}
          type="submit"
          className="btn btn-myschool-blue btn-set-WH"
          >
          <span>
            {isSubmitting ? (
              <Spinner size="sm" className='color-white'/>
            ) : (
              "Submit"
            )}
          </span>
        </button>
        <button type='button' className='btn btn-light btn-set-WH' onClick={()=>history.goBack()}>
          Go Back
        </button>
      </Form>
    </Fragment>
  );
};


ResourceForm.defaultProps = {
  isEditForm: false
};

export default ResourceForm;