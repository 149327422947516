import React, { Fragment, useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SpinnerComponent from '../../layout/Spinner';
import { Map } from 'react-leaflet';
import L from 'leaflet';
// import EventmapKey from './EventmapKey';
import EventMap from './EventMap';
import EventListWrapper from './EventListWrapper';
import EventMapFilters from './EventMapFilters';
import { getEventsWithin, getBoundedEvents, saveTextSearch } from '../../../actions/event'; 
import { Row, Col, Button, Modal, ModalBody, ButtonDropdown, DropdownToggle, DropdownMenu, Form } from 'reactstrap';
import queryString from 'query-string';
import LoginPopup from '../../auth/LoginPopup';
import RegisterPopup from '../../auth/RegisterPopup';
import axios from 'axios';
import SearchBar from '../../../utils/SearchBar';


const corner1 = L.latLng(90,180);
const corner2 = L.latLng(-90,-180);
const maxBounds = L.latLngBounds(corner1, corner2);
let APIKey;
if (process.env.NODE_ENV === 'production') {
  APIKey = process.env.REACT_APP_MAPTILER_API_KEY
} else {
  APIKey = process.env.REACT_APP_MAPTILER_API_TEST_KEY
}

// BEGIN MAP
const EventMapWrapper = ({ auth, event:{ events, eventLoading }, getEventsWithin, getBoundedEvents, saveTextSearch }) => {
  const history = useHistory();
  const location = useLocation();
  let locationSearch = location.search ? queryString.parse(location.search) : '';

  const [ loginModal, toggleLoginModal ] = useState(false);
  const [ registerModal, toggleRegisterModal ] = useState(false);
  const swapLoginRegister = () => {
    toggleLoginModal(!loginModal);
    toggleRegisterModal(!registerModal);
  };

  //MAP STATE
  const [currentLatLong, setCurrentLatLong] = useState({
    lat: 39.3,
    long: -76.6
  });

  const [userLatLong, setUserLatLong] = useState({
    lat: 39.3,
    long: -76.6
  });

  const [currentZoom, setCurrentZoom] = useState(4);
  const [bounds, setBounds] = useState({
    latMin: 39,
    latMax: 40,
    longMin: -78,
    longMax: -75
  });

  // //FILTER STATE
  // //checkboxes to show or not show the data currently in redux store
  // const [profileFilter, toggleProfileFilter] = useState(true);
  // const [isShowMyFavs, toggleShowMyFavs] = useState(false);

  //Dropdowns for query filters
  const [ dDFilters, toggleDDFilters ] =  useState(false);

  //Filter query Data
  const [ filterData, setFilterData ] = useState({
    category: '',
    dist: 50,
    isFree: true,
    isCost: true, 
    age: '',
    startAfter: '',
    startBefore: ''
  });

  //show hide 'update filter' button
  const [ showUpdateFilter, toggleShowUpdateFilter ] =  useState(false);

  const onFilterSubmit = async e => {
    e.preventDefault();
    await getEventsWithin(currentLatLong, filterData);
    saveTextSearch(currentLatLong, userLatLong, {age: filterData.age, category: filterData.category, dist: filterData.dist });
    toggleDDFilters(false);
    if (showUpdateFilter) {
      toggleShowUpdateFilter(false);
    };
  };

  const resetFilters = async () => {
    const filtersCleared = {
      category: '',
      dist: 50,
      isFree: true,
      isCost: true, 
      age: '',
      startAfter: '',
      startBefore: ''
    };
    setFilterData({
      ...filtersCleared
    });
    setTextSearch('');
    await getEventsWithin(currentLatLong, filtersCleared);
  };

  const [ textSearch, setTextSearch ] = useState("");
  const onTextSearchSubmit = () => {
    const query = {
      textSearch: textSearch
    }
    history.push({
       pathname: '/events',
       search: `?textSearch=${textSearch}`
    });
    getBoundedEvents(bounds, query);
    saveTextSearch(bounds, userLatLong, query);
    // setShowUpdateMapHere(false);
  };

  const currentposition = [currentLatLong.lat, currentLatLong.long];

  useEffect(() => {
    setBounds({
        latMin: currentLatLong.lat - 0.7,
        latMax: currentLatLong.lat + 0.7,
        longMin: currentLatLong.long - 1.5,
        longMax: currentLatLong.long + 1.5
    }); 
  },[currentLatLong]);

  //use query string to search on initial load
  useEffect(() => {
    if (locationSearch && locationSearch.textSearch) {
      setTextSearch(locationSearch.textSearch);
    }
  },[]);

  //Set location of map load and find events there
  const [isLocationLoaded, setLocationLoaded ] = useState(false);

  useEffect(() => {
    
    navigator.geolocation.getCurrentPosition(async (position) => {
        (locationSearch && locationSearch.lat && locationSearch.long) ? (
              setCurrentLatLong({ lat: locationSearch.lat, long: locationSearch.long})
            ) : (
              setCurrentLatLong({ lat: position.coords.latitude, long: position.coords.longitude })
        );
        setUserLatLong({ lat: position.coords.latitude, long: position.coords.longitude });
        
        (locationSearch && locationSearch.zoom) ? setCurrentZoom(15) : setCurrentZoom(10);
        setLocationLoaded(true);
      }, async () => {
        window.alert('You blocked your location, which is totally cool with us. We just use it to set up the starting position for the map. We\'ll center the map in your general area instead.');
        // below is for IP address location services, but Macs can prevent this
        try {
          const ipAddress =  await axios.get('https://ipapi.co/json');
          let userIpLocation = { lat: ipAddress.data.latitude, long: ipAddress.data.longitude};
          setCurrentLatLong(userIpLocation);
          setUserLatLong(userIpLocation);
          setCurrentZoom(10);
          setLocationLoaded(true);
        } catch (err) {
          window.alert('You blocked all location services, which is totally cool with us. We just use it to set up the starting position for the map, but you can just zoom in to your location and refresh the map there.  Re-enable location services anytime so you don\'t have to do that!');
          const noLocation = { lat: 0, long: 0 };
          setCurrentLatLong(noLocation);
          setUserLatLong(noLocation);
          setCurrentZoom(1);
          setLocationLoaded(true);
        }
      }
    );
  }, []);

  useEffect(()=> {
    if (isLocationLoaded) {
      if (locationSearch && locationSearch.textSearch) {
          const textQuery = {textSearch: locationSearch.textSearch}
          getEventsWithin(currentLatLong, textQuery);
        } else {
          getEventsWithin(currentLatLong, filterData);
        }
    }
  },[isLocationLoaded]);

  //Handle Map Focus
  const [ mapFlyTo, setMapFlyTo ] = useState(null);

  return !isLocationLoaded ? (
    <SpinnerComponent />
  ) : (
      <Fragment>
        <div>
          {<button 
            className='btn btn-myschool-red'
            type='button' 
            onClick={ auth.isAuthenticated ? 
                ()=> history.push(`/create-event`)
                :
                ()=> toggleRegisterModal(true)
                }
            >
            Host an Event
          </button>
          }
        </div>
        
        <div className="filterPane form">
          <Row className='align-items-center justify-content-start py-1'>
            <Col xs="auto" className='filter-search-items'>
              <ButtonDropdown group={false} direction="right" isOpen={dDFilters} toggle={() => toggleDDFilters(!dDFilters)}>
                <DropdownToggle
                  className='cursor-pointer btn btn-myschool-blue-border btn-map-filter'
                >
                    Filters 
                  <i className="fas fa-filter"/><i className="fas fa-caret-down"/>
                </DropdownToggle>
                <DropdownMenu className='event-filters-menu'>
                  <div className='m-2'>
                    <Form className='form' onSubmit={e => onFilterSubmit(e)}>
                      <EventMapFilters 
                        filterData={filterData} 
                        setFilterData={setFilterData}
                      />
                    </Form>
                  </div>
                </DropdownMenu>
              </ButtonDropdown> 
            </Col>
            <Col xs="auto" className='filter-search-items'>
              <Button 
                type='button'
                onClick={()=>resetFilters()}
                className='btn btn-myschool-blue'
                >
                Reset Filters
              </Button>
            </Col>
            <Col xs={12} sm={6} className='filter-search-items'>
              <SearchBar string={textSearch} setString={ setTextSearch } onClick={onTextSearchSubmit}/>
            </Col>
          </Row> 

          

          <div>
            Showing {events.length}{" "}
            results {"  "}
          </div>
          
        </div>
        {/**/}
        <Row>
          <Col sm={12} md={4} className='event-list-container'>
            <EventListWrapper
              events={events}
              auth={auth}
              setMapFlyTo={setMapFlyTo}
              history={history}
            />
          </Col>
          <Col sm={12} md={8} className='event-map-container'>
            <Map className="event-map" center={currentposition} zoom={currentZoom} maxBounds={maxBounds}>
              <EventMap 
                events={events}
                currentposition={currentposition}
                auth={auth}
                bounds={bounds}
                setBounds={setBounds}
                APIKey={APIKey}
                filterData={filterData}
                textSearch={textSearch}
                mapFlyTo={mapFlyTo}
                userLatLong={userLatLong}
              />
            </Map>
          </Col>
        </Row>
        

        <Modal isOpen={loginModal} centered toggle={() => toggleLoginModal(!loginModal)}>
          <ModalBody className='text-center'>
            <div className='float-right'>
              <button className="basic-button" 
                onClick={() => toggleLoginModal(false)}>
                <i className='fa fa-times color-light-grey'/>
              </button>
            </div>
            You have to register and login to do that!
            <LoginPopup loginModal={loginModal} toggleLoginModal={toggleLoginModal}/>
            <button onClick={()=>swapLoginRegister()} className='basic-button text-bold text-underlined'>I need to Register</button>
          </ModalBody>
        </Modal>

        <Modal isOpen={registerModal} centered toggle={() => toggleRegisterModal(!registerModal)}>
          <ModalBody className='text-center'>
            <div className='float-right'>
              <button className="basic-button" 
                onClick={() => toggleRegisterModal(false)}>
                <i className='fa fa-times color-light-grey'/>
              </button>
            </div>
            <RegisterPopup registerModal={registerModal} toggleRegisterModal={toggleRegisterModal} history={history}/>
            <button onClick={()=>swapLoginRegister()} className='basic-button text-bold text-underlined'>Go to Login</button>
          </ModalBody>
        </Modal>


      </Fragment>
  );
};

EventMapWrapper.propTypes = {
  event: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  getEventsWithin: PropTypes.func.isRequired,
  getBoundedEvents: PropTypes.func.isRequired,
  saveTextSearch: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  event: state.event
});

export default connect(
  mapStateToProps,
  { getEventsWithin, getBoundedEvents, saveTextSearch }
)(EventMapWrapper);