import api from '../utils/api';
import {setAlert} from './alert';
import {
  GET_MEETUP,
  MEETUP_ERROR
} from './types';


// Get meetup by meetup ID
export const getMeetupByMeetupId = meetupId => async dispatch => {
  // console.log(id);
  try {
    const res = await api.get(`/v1/meetupVirtual/${meetupId}`);
    
    dispatch({
      type: GET_MEETUP,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: MEETUP_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
    dispatch(setAlert("You are either not logged in, or not authorized to view this.",'danger'));
  }
};
