import React, { } from 'react';
import ReactGA from 'react-ga';

const MySchoolPlusDiscountItem = ({discount, isAuthenticated, isActiveSubscriber, history}) => {

    const becomeAMemberInvite = () => {
        ReactGA.event({ category: "MySchool_Plus", action: "Discount Teaser Click", label: `${discount.name}` });
        history.push('/myschoolplus/subscribe');
    }

    return (
            <div className="container">
                <div className="row justify-content-center">
                    {/* Section Heading */}
                    <div className="px-3 text-center mb-4">
                        <h4>{discount.name} - {discount.short_desc}</h4>
                    </div>
                </div>
                { discount.isMySchoolExclusive ?
                    <div className="text-center text-bold">
                        <span className="text-color-red px-1">MySchool</span>                    
                        <span className="text-color-yellow pr-1">Plus</span>
                        <span className="text-color-blue">Exclusive</span>
                    </div>
                    :''
                }
                {!isActiveSubscriber &&
                    <div>
                        <button type='button' className='btn block-button btn-modal-blue' onClick={()=>becomeAMemberInvite()}>
                            Become a member for full details
                        </button>
                    </div>
                }
                <div className='mb-2'>
                    {discount.description}
                </div>
                { discount.requirements && discount.requirements.length > 0  ?
                    <div>
                        <span className='text-bold text-1-2rem'>
                            Requirements:
                        </span>
                        <ul className='ml-5'>
                            {discount.requirements.map((item, i)=> {
                                return (
                                    <li key={`item-${i}`} style={{listStyleType:'square'}}> {item.name}
                                        {item.link ? 
                                            <a href={`${item.link}`} target='_blank' rel='noopener noreferrer' className='btn btn-modal-blue btn-small p-1'>
                                                Get it here
                                            </a>
                                            :''
                                        }
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                    :''
                }
                { discount.accessLink ?
                    <div className='text-center'>
                        <a href={`${discount.accessLink}`} target='_blank' rel='noopener noreferrer' className='btn btn-modal-blue'>
                            Take me to the deal!
                        </a>
                    </div>
                    :''
                }
                { discount.linkQualifyDetails ?
                    <div className='text-italic mt-3'>
                        <a href={`${discount.linkQualifyDetails}`} target='_blank' rel='noopener noreferrer'>
                            *See full qualifications details >>
                        </a>
                    </div>
                    :''
                }
            </div>
    )
};

export default MySchoolPlusDiscountItem;