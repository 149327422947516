import {
  GET_FAMILY,
  PUT_FAMILY,
  DELETE_FAMILY,
  FAMILY_ERROR,
  CLEAR_FAMILY
} from '../actions/types';

const initialState = {
  myFamily: null,
  familyLoading: true,
  error: {}
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_FAMILY:
    case PUT_FAMILY:
      return {
        ...state,
        myFamily: payload,
        familyLoading: false
      };
    case FAMILY_ERROR:
      return {
        ...state,
        error: payload,
        familyLoading: false
      };
    case CLEAR_FAMILY:
      return {
        ...state,
        myProfile: null,
        familyLoading: false
      };
    default:
      return state;
  }
}
