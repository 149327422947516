import React from 'react';

const BackButton = ({history, backTo, textString, btnColor}) => {
	return (
		<div>
			<button type='button' className={`btn btn-myschool-${btnColor}`} onClick={backTo && backTo.length > 0 ?()=>history.push(backTo) : ()=>history.goBack()}>
				<i className='fas fa-chevron-left'/>{` ${textString}`}
			</button>
		</div>
	)
};

BackButton.defaultProps = {
	btnColor: 'blue',
	textString: 'Back'
};

export default BackButton;