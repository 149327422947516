import React, { Fragment, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Container, Form, Row, Col, FormGroup, Label, Input, Button, Spinner } from 'reactstrap';
import {setAlert} from '../../actions/alert'; 
// import axios from 'axios';
import api from '../../utils/api';

const ClaimResource = ({ auth }) => {
  
  const history = useHistory();
  const params = useParams();
  const [ isSubmitting, setIsSubmitting ] = useState(false);
  const [ isSuccess, setIsSuccess ] = useState(false);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    position: ''
  });

  const {
    name,
    email,
    position
  } = formData;

  const onChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = async e => {
    e.preventDefault();
    setIsSubmitting(true);
    let claimData;
    if ( auth && auth.user && auth.user._id ) {
      claimData = {...formData, userId: auth.user._id};
    } else {
      claimData = {...formData};
    }
    const jsonFormData = JSON.stringify(claimData);
    try {
      const res = await api.post(`/resources/claim/${params.resourceId}`, jsonFormData);
      // console.log(res);
      if (res.status === 200) {
        setIsSubmitting(false);
        setIsSuccess(true);
        setFormData({
          name: '',
          email:'',
          position:''
        });
        setTimeout(()=> {
          history.push('/homeschoolmap')
        }, 4000);
      }
    } catch (err) {
      setIsSubmitting(false);
      setIsSuccess(false);
      setAlert('Error: Sorry!  Email use directly at info@myschoolathome.io and we will sort it out!', 'danger');
      console.log(err);
    }
  };

  return (
    <Fragment>
      <Container>
        <div className='text-center my-5'>
          <h2 className='large text-primary-ms'>Do you operate this spot?</h2>
          <h5 className='color-myschool-blue'>If so, please fill out the form below so we can verify you.  Then we&apos;ll convert it to a free resource member profile so that you can control the information and host events through right through the site!</h5>
          <h5 className='color-myschool-blue'>If not, please{' '}<button className='btn btn-myschool-blue' onClick={()=> history.goBack()}>Go Back</button>.</h5>
        </div>

        { !isSuccess ?
          <Fragment> 
            <small>* required fields</small>
            <Form className='form' onSubmit={e => onSubmit(e)}>
              
                <Row form>
                  <Col md={6}>
                    <FormGroup>
                      <Label>*Full name</Label>
                      <Input type="text" 
                      name="name" 
                      id="name" 
                      placeholder='First & Last Name'
                      value={name}
                      onChange={e => onChange(e)} 
                      required/>
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                    <Label>*Email</Label>
                    <Input type="email" 
                        name="email" 
                        id="email" 
                        placeholder="Email Address"
                        value={email}
                        onChange={e => onChange(e)} 
                        />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <Label>Your position in the organization</Label>
                      <Input 
                      type="text" 
                      name="position" 
                      id="position" 
                      placeholder="Position in Organization"
                      value={position}
                      onChange={e => onChange(e)} 
                      required/>
                    </FormGroup>  
                  </Col>
                </Row>

              <Button type="submit" disabled={isSubmitting || isSuccess} className="btn btn-myschool-blue btn-set-WH">
                <span>
                  {isSubmitting ? (
                    <Spinner size="sm" className='color-white'/>
                  ) : (
                    "Submit"
                  )}
                </span>
              </Button>
              <button className='btn btn-light btn-set-WH' onClick={()=>history.goBack()}>
                Cancel
              </button>
            </Form>
          </Fragment>
          :
          <Fragment>
            <div className='text-center'>
              <h2>Success! Request Submitted</h2>
              <div className='blinker'>We will redirect you momentarily...</div>
            </div>
          </Fragment>
        }
      </Container>
    </Fragment>
  );
};

ClaimResource.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps,{})(ClaimResource);

