import React, { useState, Fragment } from 'react';

const CopyTextButton = ({ text }) => {
  const [ copySuccess, setCopySuccess ] = useState(false);
  const [ copyError, setCopyError ] = useState(false);
  const [ copyText, setCopyText ] = useState('');

  const copyUrl = async () => {
      try {
          await navigator.clipboard.writeText(`${text}`);
          setCopySuccess(true);
          setCopyText('success');
          setTimeout(()=> {
              setCopySuccess(false);
              setCopyText('');
          },3000);
      } catch (err) {
          setCopyError(true);
          setCopyText('error');
          setTimeout(()=> {
              setCopyError(false);
              setCopyText('');
          },3000);
      }
  };

  return (
    <Fragment>
      <button 
        type='button' 
        className=
          {copySuccess ? `btn btn-success p-1`
          : copyError ? `btn btn-danger p-1`
          :`btn color-myschool-blue p-1`
          } 
          onClick={()=>copyUrl()}><i className="fas fa-copy"/>
          {` ${copyText}`}
      </button>
    </Fragment>
  );
}


export default CopyTextButton;