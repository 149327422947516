import React, { useState, useRef } from "react";
import useIntersectionObserver from "../../utils/useIntersectionObserver";


//source code: https://medium.com/frontend-digest/progressively-loading-images-in-react-107cb075417a

const ImageContainer = props => {
  const ref = useRef();
  const [isVisible, setIsVisible] = useState(false);

  //The Intersection Observer API provides a way to asynchronously observe changes in the intersection of a target element with an ancestor element or with a top-level document’s viewport — developer.mozilla.org
  
  useIntersectionObserver({
      target: ref,
      onIntersect: ([{ isIntersecting }], observerElement) => {
        if (isIntersecting) {
          setIsVisible(true);
          observerElement.unobserve(ref.current);
        }
      }
    });
  //  const aspectRatio = (props.height / props.width) * 100;
  const aspectRatio = 1*10; //square for now, may change down the line

  return (
    <div
      ref={ref}
      className="image-container mb-2"
    >
      {isVisible && (
        <img className={props.className} width={props.width} src={props.src} alt={props.alt} />
       )}
    </div>
  );
};

export default ImageContainer;