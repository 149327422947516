import React from 'react';
import SwitchingGearsSpinner from './SwitchingGearsSpinner';

const ErrorComponent = () => {
  return (
    <div className="text-center my-5">
      <h1>Sorry! Something went wrong.</h1>
      <div className="my-5">
        <SwitchingGearsSpinner />
      </div>
      <h2>We'll get right on it to fix this issue!</h2>
    </div>
  );
};

export class AppError extends React.Component {
  
  state = {
    hasError: false,
  };

  static getDerivedStateFromError = error => {
    return { hasError: true };
  };

  componentDidCatch = (error, info) => {
    this.setState({ error, info });
  };

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    return hasError ? <ErrorComponent /> : children;
  }
}