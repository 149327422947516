import React, { Fragment, useState } from 'react';
import { CardText, Badge, CustomInput, Label } from 'reactstrap';
import { Link } from 'react-router-dom';
import formatDate from '../../../../utils/formatDate';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { deleteEvent } from '../../../../actions/event';
import api from '../../../../utils/api';
import Alert from '../../../layout/Alert';
import queryString from 'query-string';

const EventDashboardBriefListing = ({ event, showBookingsLink, history, deleteEvent }) => {

  let eventCatColor;
  if (event.category === "Field Trip") {
    eventCatColor = "science"
  } else if (event.category === "Class") {
    eventCatColor = "myschool-red"
  } else if (event.category === "Other")  {
    eventCatColor = "myschool-yellow"
  }
  else {
    eventCatColor = "myschool-blue"
  };

  const queryEventObj = () => {
    const eventCopyData = {};
    if (event && event.category) eventCopyData.category = event.category;
    if (event && event.title) eventCopyData.title = event.title;
    if (event && event.description) eventCopyData.description = event.description;
    if (event && event.subjects) eventCopyData.subjects = event.subjects;
    if (event && event.classContact) eventCopyData.classContact = event.classContact;
    if (event && event.contactEmail) eventCopyData.contactEmail = event.contactEmail;
    if (event && event.address) eventCopyData.address = event.address;
    if (event && event.city) eventCopyData.city = event.city;
    if (event && event.state) eventCopyData.state = event.state;
    if (event && event.zip) eventCopyData.zip = event.zip;
    if (event && event.startTime) eventCopyData.startTime = event.startTime;
    if (event && event.endTime) eventCopyData.endTime = event.endTime;
    if (event && event.costChild) eventCopyData.costChild = event.costChild;
    if (event && event.costAdult) eventCopyData.costAdult = event.costAdult;
    if (event && event.suggestDonation === false) {eventCopyData.suggestDonation = false}else{eventCopyData.suggestDonation = true};
    if (event && event.donation) eventCopyData.donation = event.donation;
    if (event && event.ageStart) eventCopyData.ageStart = event.ageStart;
    if (event && event.ageEnd) eventCopyData.ageEnd = event.ageEnd;
    if (event && event.isDropOff === false) {eventCopyData.isDropOff = false}else{eventCopyData.isDropOff = true};
    if (event && event.slotsTotal) eventCopyData.slotsTotal = event.slotsTotal;
    if (event && event.slotsPrefilled) eventCopyData.slotsPrefilled = event.slotsPrefilled;
    if (event && event.meetingInstructions) eventCopyData.meetingInstructions = event.meetingInstructions;
    if (event && event.preparations) eventCopyData.preparations = event.preparations;
    if (event && event.expectations) eventCopyData.expectations = event.expectations;
    if (event && event.ifClassMissed) eventCopyData.ifClassMissed = event.ifClassMissed;
    if (event && event.learningGoals) eventCopyData.learningGoals = event.learningGoals;
    if (event && event.uponCompletion) eventCopyData.uponCompletion = event.uponCompletion;
    if (event && event.isUnlisted === false) {eventCopyData.isUnlisted = false}else{eventCopyData.isUnlisted = true};
    if (event && event.isOffSite === false) {eventCopyData.isOffSite = false}else{eventCopyData.isOffSite = true};
    if (event && event.offSiteLink) eventCopyData.offSiteLink = event.offSiteLink;
    if (event && event.isFreeMeetup === false) {eventCopyData.isFreeMeetup = false}else{eventCopyData.isFreeMeetup = true};
    if (event && event.isForParents === false) {eventCopyData.isForParents = false}else{eventCopyData.isForParents = true};

    const eventString = queryString.stringify(eventCopyData,{arrayFormat: 'bracket'});
    // console.log(eventString);
    if (event && event.profile && event.profile._id) {
      history.push(`/create-event/profile/${event.profile._id}?${eventString}`);
    } else if (event && event.provider && event.provider._id) {
      history.push(`/create-event/provider/${event.provider._id}?${eventString}`);
    }
    
  };

  const [ isLive, toggleIsLive ] = useState(event.isLive); 
  const [isToggling, setIsToggling] = useState(false); 
  const toggleIsLiveBookings = async (id, userId) => {
    setIsToggling(true);
    try {
      const res = await api.put(`/events/${id}/toggleIsLive`,{userId});
      if (res.status === 200) {
        toggleIsLive(!isLive);
      }
    } catch (err) {
      console.log('error',err);
    }
    setIsToggling(false);
  };

  return (
    <div>
      <div className='d-flex justify-content-end'>
        <Label className='mx-1'>{isLive ? `Live`: `Disabled`}</Label>
        <CustomInput 
          type="switch" 
          id="isLiveSwitch" 
          name="isLiveSwitch"
          checked={isLive}
          disabled={isToggling}
          onChange={()=>toggleIsLiveBookings(event._id, event.user)}/>
      </div>
      <CardText>
        <Badge className={`badge-${eventCatColor}`} pill>{event && event.category}</Badge>{' '}<strong>{event && event.title}</strong>{` / Event Starts: `}{event && event.startDate && formatDate(event.startDate, 'long')}
        <button 
          type='button'
          className='btn btn-myschool-blue basic-button-MBP btn-40-circle float-right mx-1' 
          onClick={()=>history.push(`/edit-event/${event._id}`)}
          >
          <i className='fas fa-pen '/>
        </button>
        <button type='button' 
          className='btn btn-danger basic-button-MBP btn-40-circle float-right mx-1' 
          onClick={()=>deleteEvent(event._id)}
          disabled={event.userBookings && event.userBookings.length > 0}
          >
          <i className='fas fa-trash-alt'/>
        </button>
        <button type='button' 
          className='btn btn-myschool-blue basic-button-MBP btn-40-circle float-right mx-1' 
          onClick={()=>queryEventObj()}
          >
          <i className='fas fa-copy'/>
        </button>
      </CardText>
      <CardText>
        <strong>Bookings:</strong>
        {event.userBookings && event.userBookings.length > 0 ? 
          <span>
            <span className='color-myschool-red text-bold text-2rem'>
              {` ${event.userBookings.length} `}
            </span>
            "bookings so far!"
          </span>
          :
          " No bookings yet."
        }
      </CardText>
      <div><i className="fas fa-arrow-down"/><small>Copy the link below and send it out or post it on Facebook to generate interest!</small><i className="fas fa-arrow-down"/></div>
      <CardText>
        <strong>Event listing:</strong>{' '}{`https://myschoolathome.io/events/${event._id}`}
      </CardText>
      { showBookingsLink ? 
        <Link className='text-underlined d-flex justify-content-end' to={`/dashboard/myEvents/${event._id}`}>Go to Booking Info...</Link>
        :''
      } 
    </div>
  );
};

EventDashboardBriefListing.propTypes = {
  deleteEvent: PropTypes.func.isRequired
};

export default connect(null,{ deleteEvent })(EventDashboardBriefListing);
