import React, { useState, Fragment } from 'react';
import { Container, Col, Row, Button, Form, FormGroup, Input, Modal, ModalHeader, ModalBody, Spinner } from 'reactstrap';
import { useHistory } from 'react-router-dom';

const WhyRegister = () => {
    const history = useHistory();

    return(
    	<Fragment>
	        <Container>
                <div>
                    <button type='button' className='btn btn-myschool-blue' onClick={()=>history.goBack()}>Go Back</button>
	        	</div>
                <div className='space'/>
	            <div className="section-heading text-center">
                    <h2>Why do we require registration?</h2>
                </div>
                <div className='my-4'>
                    Our goal is to make the resources on our site as accessible as possible to families, and while registration adds a layer of complication to the process, it also helps keep our community safe.  While 99.9% of people are looking to offer valuable content and some amazing spots to the rest of the community, occasionally we get a bad apple.  By requiring registration, we can ensure that anyone contibuting abusive or otherwise unacceptable content is removed from the site.  If you have any concerns about registration, or believe we are asking for too much, please reach out! 
                </div>
                <div className='text-center'>
                	Reach out here:{' '}<a href="mailto:info@myschoolathome.io" target='_blank' rel='noopener noreferrer'>info@myschoolathome.io</a>
                </div>
            </Container>
      </Fragment>
    );
}

export default WhyRegister;


