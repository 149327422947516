import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col, Card, Button, Badge, Modal, ModalBody } from 'reactstrap';
import { Link } from 'react-router-dom';
import CopyTextButton from '../../../utils/CopyTextButton';
import dayjs from 'dayjs';
import Carousel from '../../../utils/CarouselComponent';
import MySchoolPlusAccessButton from '../../../utils/MySchoolPlusAccessButton';
import VideoLesson from '../../lessons/VideoLesson';
import ReactGA from 'react-ga';

const ResourceDetails = ({ resource,  history, user }) => {

  const weekday = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];

  const [showLessonModal, toggleLessonModal] = useState(false);
  const [activeLesson, setActiveLesson] = useState({});
  const openLesson = (lessonId) => {
    const activeLesson = resource.lessons.find(l=>l._id===lessonId);
    ReactGA.event({ category: "Resource Lessons", action: "Open lesson", label: `${activeLesson}` });
    setActiveLesson(activeLesson);
    toggleLessonModal(!showLessonModal);
  };

    return (
        <div>
            {/* Single Blog Details */}
            <article className="single-resource-details">
                {/* Provider Content */}
                <div className="resource-content sApp-resource">
                    {/* Provider Details */}
                    <div className="resource-details">
                        <h2 className="py-3">{resource.resourceName}</h2>
                        {resource.isPortfolioPartner ? 
                          <div className="mb-2">
                            <Button type="button" className='btn btn-myschool-red' onClick={()=>history.push("/scavenger-hunt")}>
                              Scavenger Hunt Partner
                            </Button>
                          </div>
                          :''
                        }
                        <Row>
                          <Col>
                            {resource && resource.email ? (
                              <div>
                                <strong>Email:</strong>
                                  <a href={`mailto:${resource.email}`} target='_blank' rel='noopener noreferrer'>
                                    {` ${resource.email}`}
                                  </a>
                                  {' '}
                                  { (navigator.clipboard) ? 
                                    <CopyTextButton text={resource.email} />
                                    : ''
                                  }
                              </div>
                              ):''
                            }
                            {resource && resource.phone ? (
                              <div>
                                <strong>Phone:</strong>{` ${resource.phone}`}
                              </div>
                              ):''
                            }
                            {resource && resource.website ? (
                              <div>
                                <a href={resource.website} target='_blank' rel='noopener noreferrer'>{resource.website}</a>
                              </div>
                              ):''
                            }
                            {resource && resource.religiousAffiliation ? (
                              <div>
                                <strong>Religious Affiliation:</strong>{` ${resource.religiousAffiliation}`}
                              </div>
                              ):''
                            }
                          </Col>
                        </Row>

                        {resource && resource.genInfo &&
                          <Row>
                            <Col>
                              <strong>Info:</strong> {resource.genInfo}
                            </Col>
                          </Row>
                        }

                        { resource && resource.entryFees ? 
                          <Row>
                            <Col>
                                <div>
                                  <div><span className='text-bold'>Entry Fee:{' '}</span>
                                    {resource && resource.entryFees.isFree ?
                                      `Free`
                                      :
                                      <span>
                                      {resource.entryFees.fees.map((fee,i)=> {
                                          return <span key={i}>{i>0 ? ', ':''}{fee.name}{`: `}{fee.price > 0 ? `$${fee.price}`:'Free'}</span>
                                        })
                                      }
                                      </span>
                                    }
                                    </div>
                                </div>
                            </Col>
                          </Row>
                          :''
                        }

                        { resource && ((resource.freeEntryOptions && resource.freeEntryOptions.length > 0) || (resource.reciprocals && resource.reciprocals.length > 0)) ? 
                          <Row>
                            <Col>
                                <div>
                                  <div>
                                    <span className='text-bold'>
                                        Ways to get in free:{' '}
                                    </span>
                                    <div className="ml-3">
                                      {user && user.subscriberStatusActive ?
                                        <div>
                                          {resource && resource.freeEntryOptions ?
                                            <div>
                                              {resource.freeEntryOptions.map((option,i)=> {
                                                  return (
                                                    <div key={i}>
                                                      <span className="text-bold">
                                                        {option.name}
                                                      </span>
                                                      {option.link ?
                                                        <a href={option.link} target='_blank' rel='noopener noreferrer'>{` - ${option.description} >>`}</a>
                                                        :
                                                        <span>{` - ${option.description}`}</span>
                                                      }
                                                    </div>
                                                  )
                                                })
                                              }
                                            </div>
                                            :''
                                          }
                                          { resource.reciprocals && resource.reciprocals.length > 0 ?
                                            <div className='text-bold'>
                                              {`Join a reciprocal program: `}
                                              {resource.reciprocals.map((recip,i)=> {
                                                  return (
                                                      <Badge key={i} className="pill-color-blue text-09rem mr-1 cursor-pointer" pill onClick={()=>history.push("/myschoolplus/reciprocals")}>
                                                        {recip}
                                                        {resource.reciprocalData && resource.reciprocalData[recip] && ` $${resource.reciprocalData[recip].price}`}
                                                      </Badge>
                                                    )
                                                  }
                                              )}
                                            </div>
                                            :''
                                          }
                                        </div>
                                        :
                                        <div>
                                          <MySchoolPlusAccessButton />
                                        </div>
                                      }
                                    </div>
                                  </div>
                                </div>
                            </Col>
                          </Row>
                          :''
                        }


                        { resource && resource.opHours ? 
                          <Row>
                            <Col>
                                <div>
                                  <div className='text-bold'>Hours of Operation:</div>
                                  {resource.opHours.map((dow,i)=> {
                                    return <div key={i} className="ml-4">{weekday[i]}{`: `}{dow}</div>
                                    }
                                  )}
                                </div>
                            </Col>
                          </Row>
                          :''
                        }

                        {
                          resource && resource.lessons && resource.lessons.length > 0 ? 
                            <div className="my-3">
                              <div className='text-bold'>Learn about this spot!</div>
                              {resource.lessons.map((lesson,i)=> {
                                return (
                                  <Row key={i} className="my-1 mx-2">
                                    <Col md={4}>
                                      <div>{lesson.name}</div>
                                      <div>
                                        <button className="btn btn-myschool-blue" onClick={()=>openLesson(lesson._id)}>Watch Now</button>
                                      </div>
                                    </Col>
                                    <Col md={8}>
                                      <button className="btn basic-button" onClick={()=>openLesson(lesson._id)}><img src={lesson.displayImage} alt="lesson image"/></button>
                                    </Col>
                                  </Row>
                                  )
                                }
                              )}
                            </div>
                            :''
                        }
                        
                    </div>
                </div>                
            </article>

            <Modal size="lg" isOpen={showLessonModal} centered toggle={() => toggleLessonModal(!showLessonModal)}>
              <ModalBody className='text-center'>
                <div>
                  <VideoLesson lesson={activeLesson} />
                </div>
                <Button type='button' onClick={()=>toggleLessonModal(false)} className='basic-button text-underlined'>Close</Button>
              </ModalBody>
            </Modal>

        </div>
    );
}

export default ResourceDetails;