import React, { useEffect, useState } from 'react';
import { Container } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import api from '../../utils/api';
import SpinnerComponent from '../layout/Spinner';
import { Table, Badge, Modal, ModalBody } from 'reactstrap';
import MySchoolPlusDiscountItem from './MySchoolPlusDiscountItem';
import BackButton from '../../utils/BackButton';
import AuthModals from '../auth/AuthModals';
import dayjs from 'dayjs';
import myschoolPlusLogo from "../../images/logo_myschool-plus_150-150.svg";

const MySchoolPlusDiscounts = ({auth: {isAuthenticated, loading, user}}) => {

    const history = useHistory();
    const [ loginModal, toggleLoginModal ] = useState(false);
    const [ registerModal, toggleRegisterModal ] = useState(false);

    const [discounts, setDiscounts] = useState([]);

    // const getPublicDiscountData = async () => {
    //     const disc = await api.get('/v1/discounts/public');
    //     // console.log('public:',disc);
    //     setDiscounts(disc.data.data);
    // };
    const getMemberDiscountData = async () => {
        const disc = await api.get('/v1/discounts/members');
        // console.log('member:',disc);
        setDiscounts(disc.data.data);
    }

    const [isActiveSubscriber, setActiveSubscriber] = useState(false);

    useEffect(()=> {
        if (isAuthenticated && user && user.subscriberStatusActive) {
            setActiveSubscriber(true);
        } 
    },[isAuthenticated, user]);

    useEffect(()=> {
        getMemberDiscountData();
    },[]);

    const becomeAMemberInvite = () => {
        ReactGA.event({ category: "MySchool_Plus", action: "Discounts", label: `Main discount page` });
        history.push('/myschoolplus/subscribe');
    }

    const [initData, setInitData] = useState({
        preHeading: "Premium",
        preHeadingspan: "Features",
        heading: "MySchool Plus Discounts",
        headingText: "Learn more. Spend less.",
        headingTexttwo: "Below are organizations currently offering discounts. Do you need a MySchool Plus membership for ALL the discounts? Many, but not all. However, MySchool provides you with the research, documentation, and instructions on how to qualify for these programs, PLUS you're supporting our work to EXPAND these discounts!"
    });

    const [showDiscountModal, toggleDiscountModal] = useState(false);
    const [ discountModalData, setDiscountModalData] = useState(null);

    const seeDiscountInfo = (discount) => {
        setDiscountModalData({...discount});
        toggleDiscountModal(true);
    }

    return (
        <section id="features" className="section features-area style-two overflow-hidden ptb_100">
            <div className="container">
                <BackButton history={history} backTo='/myschoolplus' color='blue' textString='Back to MySchool Plus'/>
                <div className="row justify-content-center">
                    {/* Section Heading */}
                    <div className="section-heading px-3 text-center">
                        <span className="d-inline-block rounded-pill shadow border fw-5 px-4 py-2 mb-3">
                            <i className="far fa-lightbulb text-primary mr-1" />
                            <span className="text-primary">{initData.preHeading}</span>
                            {initData.preHeadingspan}
                        </span>
                        <h2>{initData.heading}</h2>
                        <div className="mt-4 text-bold">
                            {initData.headingText}
                        </div>
                        <div>
                            {initData.headingTexttwo}
                        </div>
                    </div>
                </div>
                {!isActiveSubscriber ? 
                    <div className='text-center'>
                        <button type='button' onClick={()=>becomeAMemberInvite()} className='btn btn-myschool-blue'>
                            Become a Member Now!
                        </button>
                    </div>
                    :''
                }
                <div className='text-1-2rem text-bold mb-2'>
                    Available Nationally{discounts.length > 0 ? 
                            <span>
                                {` - `}
                                <span className='color-myschool-red text-1-5rem'>
                                    {discounts.length}
                                </span>
                                {` active discounts!`}
                            </span>
                        :''
                    }
                </div>
                <div>
                    <Table hover className='table-basic'>
                        <thead>
                            <tr>
                                <th>Organization</th>
                                <th>Discount</th>
                                <th className="d-none d-sm-block">Tags</th>
                                <th>Details</th>
                            </tr>
                        </thead>
                        <tbody> 
                            {discounts.map((item, idx) => {
                                    return (
                                        <tr key={`ffd_${idx}`} >
                                            <td className='py-2'>
                                                {item.name}
                                            </td>
                                            <td className='py-2'>
                                                { item.isMySchoolExclusive ?
                                                    <img src={myschoolPlusLogo} alt="myschool plus logo" height="30px" width="30px" className="mr-1"/>
                                                    :''
                                                }{item.short_desc}
                                                { item.expires &&
                                                    <div className='text-italic color-myschool-red'>
                                                        {`Expires ${dayjs(item.expires).format('MMM-DD')}`}
                                                    </div>
                                                }
                                            </td>
                                            <td className='py-2 d-none d-sm-block'>
                                                {item.tags.map((s,i) => {
                                                      return <Badge key={i} className={`pill-color-${s.replace(/\s+/g, '')} mr-1`} pill>{s}</Badge>
                                                    })
                                                }             
                                            </td>
                                            <td className='py-2'>
                                                <button type='button' className='btn btn-myschool-blue-border btn-small'
                                                    onClick={ !isAuthenticated ?
                                                        ()=>toggleLoginModal(true)
                                                        : isAuthenticated && !isActiveSubscriber ?
                                                        ()=>becomeAMemberInvite()
                                                        :
                                                        ()=>seeDiscountInfo(item)
                                                    }
                                                >
                                                {isActiveSubscriber ? `Full Details >>`:`MySchool+ Members Only`}
                                                </button>
                                            </td>
                                        </tr>
                                    );
                                })
                            }
                        </tbody>
                    </Table>
                </div>
            </div>

            <Modal isOpen={showDiscountModal} centered toggle={()=>toggleDiscountModal(!showDiscountModal)}>
                <ModalBody>
                    <div className='d-flex justify-content-end'>
                        <button className="basic-button" onClick={() => toggleDiscountModal(false)}>
                            <i className='fa fa-times color-myschool-blue'/>
                        </button>
                    </div>
                    <MySchoolPlusDiscountItem discount={discountModalData} isAuthenticated={isAuthenticated} isActiveSubscriber={isActiveSubscriber} history={history}/>
                </ModalBody>
            </Modal>

            <AuthModals toggleLoginModal={toggleLoginModal} loginModal={loginModal} toggleRegisterModal={toggleRegisterModal} registerModal={registerModal} />
        </section>
    )
};

MySchoolPlusDiscounts.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(
  mapStateToProps,
  {  },
)(MySchoolPlusDiscounts);