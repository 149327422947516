import React from "react";
import { Redirect, useParams } from "react-router-dom";


const ProviderRedirect = () => {

	const params = useParams();

	return <Redirect to={`/provider/${params.providerId}`} />;
};

export default ProviderRedirect;
