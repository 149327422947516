import api from '../utils/api';
import {
  GET_CHILDS,
  SELECTED_CHILD,
  GET_CHILD,
  GET_AWAKE_BED_TIME,
  ADD_AWAKE_BED_TIME,
  UPDATE_AWAKE_BED_TIME,
} from "./types";


// Create child profile
export const createUpdateChild = async (child) => {
  try {

    if (child.child_id) {
      await api.put("/child", child);
    } else {
      await api.post("/child", child);
    }

  } catch (err) {
    console.log(err);
  }
};

// Get all children profiles by parent/guardian id
export const getAllChild = () => async (dispatch) => {
  try {

    const res = await api.get("/child");
    dispatch({
      type: GET_CHILDS,
      payload: res.data,
    });

  } catch (err) {
    console.log(err);
  }
};

// Delete child profile
export const deleteChild = async (id) => {
  try {

    await api.delete("/child/" + id);

    // console.log(res);
  } catch (err) {
    console.log(err);
  }
};

// Add child wake sleep time
export const addWakeSleepTime = (timeData) => async (dispatch) => {
  try {

    const res = await api.post("/childawakesleep", timeData);
    dispatch({type:ADD_AWAKE_BED_TIME,payload:res.data});
  } catch (err) {
    console.log(err);
  }
};
// Add update wake sleep time
export const updateaWakeSleepTime = (timeData) => async (dispatch) => {
  try {
    
    const res = await api.put("/childawakesleep", timeData);
    dispatch({type:UPDATE_AWAKE_BED_TIME,payload:res.data});
  } catch (err) {
    console.log(err);
  }
};

export const getaWakeSleepTime = (child_id) => async (dispatch) => {
  try {
    
    const res = await api.get(`/childawakesleep/${child_id}`);
    dispatch({ type: GET_AWAKE_BED_TIME, payload: res.data });
  } catch (err) {
    console.log(err);
  }
};

export const getSelectedChild = (child) => async (dispatch) => {
  try {
    dispatch({
      type: SELECTED_CHILD,
      payload: child,
    });
  } catch (err) {
    console.log(err);
  }
};

export const getChildById = (childId) => async (dispatch) => {
  try {
    
    const res = await api.get(`/child/${childId}`);
    
    dispatch({ 
      type: GET_CHILD, 
      payload: res.data 
    });
    
  } catch (err) {
    console.log(err);
  }
};
