import React, { Fragment, useState, useEffect } from 'react';
import { FormGroup, Input, Label, Container, Row, Col, Button } from 'reactstrap';

// BEGIN MAP
const EventMapFilters = ({ filterData, setFilterData }) => {
  
  const onChange = e =>
    setFilterData({ ...filterData, [e.target.name]: e.target.value });

  const onCheckChange = e =>
    setFilterData({ ...filterData, [e.target.name]: e.target.checked });

  const { category, age, startAfter, startBefore, isFree, isCost } = filterData;

  return (
      <Fragment>
        <Container className='small-form text-09rem'>
          <Row form>
            <FormGroup>
              <Label for="category">Category</Label>
              <Input type="select" 
              name="category"
              value={category}
              onChange={e => onChange(e)} 
              >
                <option value=''>Any</option>
                <option value="Field Trip">Field Trip</option>
                <option value="Class">Class</option>
                <option value="Meetup">Meetup</option>
                <option value="Camp">Camp</option>
              </Input>
            </FormGroup>
          </Row>
          <FormGroup>
            <Label for="age">For what age?</Label>
            <Input
              type='number'
              name='age'
              placeholder='(any age if blank)'
              onChange={(e) => onChange(e)}
              value={age}/>
          </FormGroup>
          <Row form>
            <FormGroup check inline>
              <Label check>
                <Input type="checkbox" 
                  name="isFree"
                  id="isFree"
                  checked={isFree}
                  onChange={(e)=>onCheckChange(e)} /> Free
              </Label>
            </FormGroup>
            <FormGroup check inline>
              <Label check>
                <Input type="checkbox" 
                  name="isCost"
                  id="isCost"
                  checked={isCost}
                  onChange={(e)=>onCheckChange(e)} /> Fixed Cost
              </Label>
            </FormGroup>
          </Row>
          <Row form className='my-2'>
            <Col md={6}>
              <FormGroup>
                <Label for="startAfter">Starts after</Label>
                <Input 
                type="date" 
                name="startAfter" 
                id="startAfter"  
                value= {startAfter}
                onChange={e => onChange(e)} />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="startBefore">Starts before</Label>
                <Input 
                type="date" 
                name="startBefore" 
                id="startBefore" 
                value= {startBefore}
                onChange={e => onChange(e)} />
              </FormGroup>
            </Col>
          </Row>
          
          <Button type='submit' className='btn block-button btn-myschool-blue'>Submit</Button>
        </Container>
      </Fragment>
  );
};


export default EventMapFilters;


          {/*
          <Row form>
            <FormGroup inline>
              <Label for="costLow">From</Label>
              <InputGroup>
                <InputGroupAddon addonType="prepend">$</InputGroupAddon>
                <Input 
                  type="number" 
                  name="costLow" 
                  id="costLow" 
                  value= {costLow}
                  onChange={e => onChange(e)} />
              </InputGroup>
            </FormGroup>
            <FormGroup inline>
              <Label for="costHigh">to</Label>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">$</InputGroupAddon>
                  <Input 
                    type="number" 
                    name="costHigh" 
                    id="costHigh" 
                    value= {costHigh}
                    onChange={e => onChange(e)} />
                </InputGroup>
            </FormGroup>
          </Row>

          
            <Row form>
              <FormGroup>
                <Label htmlFor="textSearch">Search </Label>
                <Input type="text" 
                  name="textSearch"
                  value={textSearch}
                  onChange={e => onChange(e)}
                  className='mini-form-input'
                  placeholder='Search by Name'
                />
              </FormGroup>
            </Row>

            <Row form>
              <FormGroup check inline>
                <Label check>
                  <Input type="checkbox" /> Math
                </Label>
              </FormGroup>
              <FormGroup check inline>
                <Label check>
                   <Input type="checkbox" /> English
                </Label>
              </FormGroup>
              <FormGroup check inline>
                <Label check>
                   <Input type="checkbox" /> Social
                </Label>
              </FormGroup>
            </Row>
           */}