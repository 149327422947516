import React, { Fragment } from 'react';
import { Card, CardHeader, CardTitle, Container } from 'reactstrap';
import EventBriefInfo from './EventBriefInfo';
import EventDetails from './EventDetails';

const EventPreview = ({ formData, auth, history }) => {

  const slotsAvailable = formData.slotsTotal*1 - formData.slotsPrefilled*1;
  const previewData = {...formData, slotsAvailable: slotsAvailable};

  return (
      <Fragment>
        <Container>
          <Card className='class-card special-hover'>
            <CardHeader>
              <CardTitle className="text-primary-ms class-title">
                {previewData.title}
              </CardTitle>  
            </CardHeader>
            <EventBriefInfo event={previewData}/>
            <EventDetails event={previewData} auth={auth} history={history} isPreview={true}/>
          </Card>
        </Container>
      </Fragment>
  );
};


export default EventPreview;
