import React, { Fragment, useState, useEffect } from "react";
import { useHistory, Redirect, useParams } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { Container, Card } from 'reactstrap';
import ConnectStripeButton2 from '../../images/ConnectStripe_slate_2x.png'; 
import ConnectStripeButton from '../../images/ConnectStripe_blurple_2x.png'; 
import api from '../../utils/api';
import { setAlert } from '../../actions/alert';

const ProviderPayoutMethod = ({ isAuthenticated, setAlert }) => {

  const history = useHistory();
  const params = useParams();
  const [ redirectUrl, setRedirectUrl ] = useState('');

  const stripeConnect = async () => {
    try {
      const res = await api.post(`/v1/stripeConnect/stripe-connect-account/provider/${params.providerId}`, {});
      // console.log(res);  
      // console.log('url:',res.data.url);
      setRedirectUrl(res.data.url);
    } catch (err) {
      console.log(err);
      setAlert('Error. Try again later.', 'danger');
    } 
  }

  if (redirectUrl.length > 0) {
    window.location.href = redirectUrl;
  };

  return (
    <Container>
      <div className='text-center'>
        <h2 className='color-myschool-blue my-4'>Set Up a Stripe Account in Order to Host Events</h2>
        <Card className='p-3 box-shadow'>
          <div className='my-2'> MySchool uses Stripe to safely process payments, and payout hosts like YOU!  It's free to sign up and you have the satisfaction of knowing all your data is secured by the best payment processing company in the world. </div>
          <div className='my-2'>(Just click the button below to get started!)</div>
          <button className='basic-button my-4' onClick={()=>stripeConnect()}>
            <img src={ConnectStripeButton} width='200px' alt='Create Stripe Connect Account'/>
          </button>
          <div><a href='https://stripe.com/' target='_blank' rel='noopener noreferrer'>Learn more about Stripe</a></div>
        </Card>
      </div>
    </Container>
  );
};

ProviderPayoutMethod.propTypes = {
  isAuthenticated: PropTypes.bool,
  setAlert: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
});


export default connect(mapStateToProps, { setAlert })(ProviderPayoutMethod);
