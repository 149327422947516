import React, { useEffect, useState, Fragment } from "react";
import { useParams, useHistory } from 'react-router-dom';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import api from '../../utils/api';
import { Container, Row, Col, Label, FormGroup, Input, Button, Spinner, Alert } from 'reactstrap';
import SpinnerComponent from '../layout/Spinner';
import { updateEvent } from '../../actions/event';
import { getEventByEventId } from '../../actions/event';
import { setAlert } from '../../actions/alert';
import EventMapWrapper from './geoLocation/EventMapWrapper';
import EventForm from './EventForm';
import EventPreview from '../events/EventPreview';
import formatTimeFromDB from '../../utils/formatTimeFromDB';
import isValidURL from '../../utils/isValidURL';

const EditEvent = ({ event: { event, eventLoading }, getEventByEventId, updateEvent, auth, setAlert }) => {
  const history = useHistory();
  const params = useParams();
  //Local alerts
  const [ alertArray, setAlertArray ] = useState([]);

  //Sections
  const [ showSection1, toggleSection1 ] = useState(true);
  const [ showSection2, toggleSection2 ] = useState(false);
  const [ showPreview, setShowPreview ] = useState(false);
  const [ previewClosing, setPreviewClosing ] = useState(false);
  const onPreviewClose = () => {
    setShowPreview(false);
    setPreviewClosing(true);
    setTimeout(()=>{setPreviewClosing(false)},1000);
  };

  //Section Actions
  const swapSection12 = () => {
    toggleSection1(!showSection1);
    toggleSection2(!showSection2);
  };

  const [ mapMeData, setMapMeData] = useState({
    address: '',
    city:'',
    state:'',
    zip:'',
  });

  const onChangeMapMe = (e) => {
    setMapMeData({ ...mapMeData, [e.target.name]: e.target.value });
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const [hostInfo, setHostInfo ] = useState({
    hostType: '',
    hostId: '',
    hostName:'',
    email:''
  });

  const [formData, setFormData] = useState({
    category: 'Meetup',
    title: '',
    address: '',
    city:'',
    state:'',
    zip:'',
    lat: 25,
    long: -71,
    image: '',
    dateRange: [],
    startTime: '',
    endTime: '',
    costAdult: '',
    costChild:'',
    suggestDonation: false,
    donation: '',
    ageStart: '',
    ageEnd: '',
    slotsTotal: '',
    slotsPrefilled: '',
    website: '',
    phone: '',
    classContact: '',
    contactEmail: '',
    included: '',
    preparations: '',
    expectations: '',
    ifClassMissed: '',
    learningGoals: '',
    uponCompletion: '',
    description: '',
    subjects: [],
    isDropOff: false,
    isUnlisted: false,
    offSiteLink: '',
    isOffSite: false,
    isFreeMeetup: true,
    isForParents: false
  });


  const [isSubmitting, setIsSubmitting] = useState(false);
  const [ showMap, setShowMap ] = useState(false);

  const mapMe = async () => {
    setIsSubmitting(true);
    try {
      const config = { headers: { 'Content-Type': 'application/json' } };
      const jsonMapMeData = JSON.stringify(mapMeData);
      const res = await api.post('/events/geolocate', jsonMapMeData, config);
      if (res.status === 200) {
        setIsSubmitting(false);
        setFormData({
          ...formData,
          lat: res.data.lat,
          long: res.data.long
        });
        setShowMap(true);
      }
    } catch (err) {
      setIsSubmitting(false);
      const errors = err.response.data.errors;
      if (errors) {
          setAlertArray(errors);
          setTimeout(()=> {setAlertArray([])},5000);
      }
    }

  }

  const formValidateAndSubmit = (updateEventData) => {
    if (formData.description.length < 20) {
      window.scrollTo(0,0);
      setAlert('Please include a longer description', 'danger');
      setIsSubmitting(false);
    } else {
      updateEvent(updateEventData, params.eventId, history);
      setIsSubmitting(false);
    }
  };

  const onSubmit = async e => {
    e.preventDefault();
    setIsSubmitting(true);
    const postData = {...formData, isLive: true};
    formValidateAndSubmit(postData);    
  };


  const onSubmitSave = async e => {
    e.preventDefault();
    setIsSubmitting(true);
    const saveData = {...formData, isLive: false};
    formValidateAndSubmit(saveData);  
  };

  useEffect(()=> {
    if (params && params.eventId) {
      getEventByEventId(params.eventId);
    }
  }, [params]);

  useEffect(() => {
    if (event && event.provider && event.provider._id&& event.provider.providerName && event.provider.email) {
      setHostInfo({
        ...hostInfo,
        hostType: 'provider',
        hostId: event.provider._id,
        hostName: event.provider.providerName,
        email: event.provider.email
      });
    } else if (event && event.profile && event.user && event.user.username && event.user.email) {
      setHostInfo({
        ...hostInfo,
        hostType: 'profile',
        hostId: event.user._id,
        hostName: event.user.username,
        email: event.user.email
      });
    } else {
      setHostInfo({
        ...hostInfo,
        hostName: '(locked)',
        email: '(locked)'
      });
    }
  }, [event]);

  useEffect(() => {
    setMapMeData({
      ...mapMeData,
      address: !eventLoading && event && event.address ? event.address : '',
      city: !eventLoading && event && event.city ? event.city : '',
      state: !eventLoading && event && event.state ? event.state : '',
      zip: !eventLoading && event && event.zip ? event.zip : ''
    });
  }, [eventLoading, event]);

  useEffect(() => {
    setFormData({
      ...formData,
      category: !eventLoading && event && event.category ? event.category : '',
      address: !eventLoading && event && event.address ? event.address : '',
      city:!eventLoading && event && event.city ? event.city : '',
      state:!eventLoading && event && event.state ? event.state : '',
      zip:!eventLoading && event && event.zip ? event.zip : '',
      lat: !eventLoading && event && event.lat ? event.lat : '',
      long: !eventLoading && event && event.long ? event.long : '',
      title: !eventLoading && event && event.title ? event.title : '',
      image: !eventLoading && event && event.image ? event.image : '',
      dateRange: !eventLoading && event && event.dateRange ? event.dateRange : [],
      startTime: !eventLoading && event && event.startTime ? formatTimeFromDB(event.startTime) : '',
      endTime: !eventLoading && event && event.endTime ? formatTimeFromDB(event.endTime) : '',
      costAdult: !eventLoading && event && event.costAdult ? event.costAdult : '',
      costChild:!eventLoading && event && event.costChild ? event.costChild : '',
      suggestDonation: !eventLoading && event && event.suggestDonation === false ? false: true,
      donation: !eventLoading && event && event.donation ? event.donation : 0,
      ageStart: !eventLoading && event && event.ageStart ? event.ageStart : '',
      ageEnd: !eventLoading && event && event.ageEnd ? event.ageEnd : '',
      slotsTotal: !eventLoading && event && event.slotsTotal ? event.slotsTotal : '',
      slotsPrefilled: !eventLoading && event && event.slotsPrefilled ? event.slotsPrefilled : '',
      website: !eventLoading && event && event.website ? event.website : '',
      phone: !eventLoading && event && event.phone ? event.phone : '',
      classContact: !eventLoading && event && event.classContact ? event.classContact : '',
      contactEmail: !eventLoading && event && event.contactEmail ? event.contactEmail : '',
      preparations: !eventLoading && event && event.preparations ? event.preparations : '',
      expectations: !eventLoading && event && event.expectations ? event.expectations : '',
      ifClassMissed: !eventLoading && event && event.ifClassMissed ? event.ifClassMissed : '',
      learningGoals: !eventLoading && event && event.learningGoals ? event.learningGoals : '',
      uponCompletion: !eventLoading && event && event.uponCompletion ? event.uponCompletion : '',
      description: !eventLoading && event && event.description ? event.description : '',
      subjects: !eventLoading && event && event.subjects ? event.subjects : '',
      isDropOff: !eventLoading && event && event.isDropOff === true ? true: false,
      isUnlisted: !eventLoading && event && event.isUnlisted === true ? true: false,
      offSiteLink: !eventLoading && event && event.offSiteLink ? event.offSiteLink : '',
      isOffSite: !eventLoading && event && event.isOffSite === true ? true: false,
      isFreeMeetup: !eventLoading && event && event.isFreeMeetup === true ? true: false,
      isForParents: !eventLoading && event && event.isForParents === true ? true: false,
    });
  }, [eventLoading, event]);

  //USERBOOKINGS MADE ALREADY, DISABLE CERTAIN OPERATIONS
  const [disableDueToBookings, setDisableDueToBookings] = useState(false);
  useEffect(() => {
    if (event && event.userBookings && event.userBookings.length > 0) {
      toggleSection1(false);
      toggleSection2(true);
      setAlert('You are unable to change the address after bookings have been made.','myschool-red')
      setDisableDueToBookings(true);
    };
  }, [event]);

  return eventLoading ? (
      <SpinnerComponent className='color-myschool-blue'/>
    ) :
    !eventLoading && !event ? (
      <h2>Event not found</h2>
    ) : (
    <Fragment>
      <Container>
        <h2 className='large text-primary-ms'>Edit Event</h2>
        {alertArray.length > 0 ? 
          alertArray.map((alert, index) =>
              <Alert color="dark" key={index} className='z-index-1000'>
                {alert.msg}
              </Alert>
            )
          : ''
        }
            
        { showSection1 && !showMap ?
          <div className='my-3'>
            <FormGroup>
              <Label className='text-bold'>Great! Where is it going to take place?</Label>
              <Input type="text" 
                name="address" 
                id="address" 
                placeholder="Street Address: 1234 Main St"
                value={mapMeData.address}
                onChange={e => onChangeMapMe(e)} 
                disabled={disableDueToBookings}
              />
            </FormGroup>
            <FormGroup>
              <Input type="text" 
              name="city" 
              id="city" 
              placeholder="City"
              value={mapMeData.city}
              onChange={e => onChangeMapMe(e)}
              disabled={disableDueToBookings} 
              />
            </FormGroup>
            <Row form>
              <Col md={8}>
                <FormGroup>
                  <Input type="select" 
                  name="state" 
                  id="state" 
                  value={mapMeData.state}
                  onChange={e => onChangeMapMe(e)}
                  disabled={disableDueToBookings}
                  >
                    <option value="None">Select State...</option>
                    <option value="AL">Alabama</option>
                    <option value="AK">Alaska</option>
                    <option value="AZ">Arizona</option>
                    <option value="AR">Arkansas</option>
                    <option value="CA">California</option>
                    <option value="CO">Colorado</option>
                    <option value="CT">Connecticut</option>
                    <option value="DE">Delaware</option>
                    <option value="DC">District Of Columbia</option>
                    <option value="FL">Florida</option>
                    <option value="GA">Georgia</option>
                    <option value="GU">Guam</option>
                    <option value="HI">Hawaii</option>
                    <option value="ID">Idaho</option>
                    <option value="IL">Illinois</option>
                    <option value="IN">Indiana</option>
                    <option value="IA">Iowa</option>
                    <option value="KS">Kansas</option>
                    <option value="KY">Kentucky</option>
                    <option value="LA">Louisiana</option>
                    <option value="ME">Maine</option>
                    <option value="MD">Maryland</option>
                    <option value="MA">Massachusetts</option>
                    <option value="MI">Michigan</option>
                    <option value="MN">Minnesota</option>
                    <option value="MS">Mississippi</option>
                    <option value="MO">Missouri</option>
                    <option value="MT">Montana</option>
                    <option value="NE">Nebraska</option>
                    <option value="NV">Nevada</option>
                    <option value="NH">New Hampshire</option>
                    <option value="NJ">New Jersey</option>
                    <option value="NM">New Mexico</option>
                    <option value="NY">New York</option>
                    <option value="NC">North Carolina</option>
                    <option value="ND">North Dakota</option>
                    <option value="OH">Ohio</option>
                    <option value="OK">Oklahoma</option>
                    <option value="OR">Oregon</option>
                    <option value="PA">Pennsylvania</option>
                    <option value="Puerto Rico">Puerto Rico</option>
                    <option value="RI">Rhode Island</option>
                    <option value="SC">South Carolina</option>
                    <option value="SD">South Dakota</option>
                    <option value="TN">Tennessee</option>
                    <option value="TX">Texas</option>
                    <option value="UT">Utah</option>
                    <option value="VT">Vermont</option>
                    <option value="VA">Virginia</option>
                    <option value="U.S. Virgin Islands">U.S. Virgin Islands</option>
                    <option value="WA">Washington</option>
                    <option value="WV">West Virginia</option>
                    <option value="WI">Wisconsin</option>
                    <option value="WY">Wyoming</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Input 
                    type="text" 
                    name="zip" 
                    id="zip" 
                    placeholder="Zip"
                    value={mapMeData.zip}
                    onChange={e => onChangeMapMe(e)} 
                    disabled={disableDueToBookings}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row className='text-center'>
              <Col>
                <Button type="button" disabled={isSubmitting} className="btn btn-modal-blue btn-set-WH-long" onClick={()=>mapMe()}>
                  <span>
                    {isSubmitting ? (
                      <Spinner size="sm" className='color-white'/>
                    ) : (
                      "Map It!"
                    )}
                  </span>
                </Button>
              </Col>
            </Row>
          </div>
          : 
          showMap ?
            <Fragment>
              <EventMapWrapper formData={formData} setFormData={setFormData}/>
              <button type='button' className='btn btn-modal-blue my-1 float-left' disabled={ isSubmitting || !showMap } onClick={()=>setShowMap(false)}>Back</button>
              <button 
                type='button' 
                className='btn btn-modal-blue my-1 float-right' 
                disabled={ (!formData.lat && !formData.long) } 
                onClick={()=>{
                    swapSection12()
                    setShowMap(false);
                  }
                }>
                Next
              </button>
            </Fragment>
          : 
          ''
        }
                      
        { showSection2 & !showPreview ? 
          (
            <Fragment>  
              <EventForm 
                hostInfo={hostInfo}
                onSubmit={onSubmit}
                onSubmitSave={onSubmitSave}
                formData={formData}
                setFormData={setFormData}
                mapMeData={mapMeData}
                setAlert={setAlert}
                history={history}
                setShowPreview={setShowPreview}
                isSubmitting={isSubmitting}
                setIsSubmitting={setIsSubmitting}
                swapSection12={swapSection12}
                disableDueToBookings={disableDueToBookings}
                previewClosing={previewClosing}
                editMode={true}
              />
            </Fragment>
          )
          : showSection2 & showPreview ? 
          (
            <Fragment>  
              <div className='d-flex justify-content-end'>
                <Button className='btn btn-myschool-red' onClick={()=>onPreviewClose()}>Close Preview</Button>
              </div>
              <EventPreview 
                formData={formData}
                auth={auth}
                history={history}
              />
              <div className='d-flex justify-content-end'>
                <Button className='btn btn-myschool-red' onClick={()=>onPreviewClose()}>Close Preview</Button>
              </div>
            </Fragment>
          ) : ''
        }

      </Container>
    </Fragment>
  );
}

EditEvent.propTypes = {
  updateEvent: PropTypes.func.isRequired,
  event: PropTypes.object.isRequired,
  setAlert: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  getEventByEventId: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  event: state.event,
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { setAlert, updateEvent, getEventByEventId }
)(EditEvent);

