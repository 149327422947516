import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { Container, Modal, ModalBody } from 'reactstrap';
import SpinnerComponent from '../layout/Spinner';
import AuthModals from '../auth/AuthModals'; 
import { getMyIDCard } from '../../actions/profile';
import idCardBlue from '../../images/icon-id-card-blue.svg';
import dayjs from 'dayjs';
import MySchoolPlusIdCard from './MySchoolPlusIdCard';
import CreateProfilePreGeolocate from '../profile-forms/CreateProfilePreGeolocate';

const MySchoolPlusIdCardWrapper = ({ profile: {profileLoading, myProfile}, getMyIDCard, auth }) => {

  const { isAuthenticated, loading, user } = auth;

  const history = useHistory();
  const params = useParams();

  const [ loginModal, toggleLoginModal ] = useState(false);
  const [ registerModal, toggleRegisterModal ] = useState(false);

  const [ needsProfile, toggleNeedsProfile ] = useState(false);
  const [ createProfileModal, toggleCreateProfileModal ] = useState(false);

  useEffect(()=> { 
    if (!isAuthenticated) {
      toggleLoginModal(true);
    }
  }, []);

  useEffect(()=> { 
  	if (isAuthenticated) {
      getMyIDCard();
  	}
  }, [isAuthenticated]);

  const activateMembership = () => {
  	console.log('activateMembership');
  	toggleLoginModal(true);
  	// history.push('/myschoolplus/join');
  }

  return isAuthenticated && myProfile && !profileLoading ? (
    	<Container className="text-center">
	      <div className="idcard-wrapper">
	      	<MySchoolPlusIdCard 
	      		profile={myProfile}
	      		activateMembership={activateMembership}
	      		auth={auth}
            history={history}
	      		/>
				</div>
		</Container>
    ) : isAuthenticated && !myProfile ? (
      <Container className="text-center ptb_50">
        <div className='section-heading'>
          <div className="featured-img mb-3">
              <img src={idCardBlue} width='75' alt='myschool I.D. card icon'/>
          </div>
          <h4 className='section-heading'>You need to create a profile to access your I.D.!</h4>
          <button type='button' className='btn btn-myschool-blue' onClick={()=>toggleCreateProfileModal(true)}>
          Create profile now!
          </button>
        </div>

        <Modal isOpen={createProfileModal} centered toggle={() => toggleCreateProfileModal(!createProfileModal)}>
          <ModalBody className='text-center'>
            <CreateProfilePreGeolocate/>
          </ModalBody>
        </Modal>
      </Container>
    ) : !isAuthenticated ? (
      <Container  className='text-center'>
        <AuthModals loginModal={loginModal} toggleLoginModal={toggleLoginModal} registerModal={registerModal} toggleRegisterModal={toggleRegisterModal} />
        <button className='btn btn-myschool-blue my-5' type='button' onClick={()=>toggleLoginModal(true)}>Please Log In To View</button>
      </Container>
    ) : (
      <Container className='text-center'>
        <h2>Error: Please try again later.</h2>
      </Container>
    )  
};

MySchoolPlusIdCardWrapper.propTypes = {
	auth: PropTypes.object,
	profile: PropTypes.object.isRequired,
	getMyIDCard: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
	auth: state.auth,
	profile: state.profile
});


export default connect(mapStateToProps, { getMyIDCard })(MySchoolPlusIdCardWrapper);
