import React, { useState, Fragment, useEffect } from 'react';
import { Form, FormGroup, Input, Spinner } from 'reactstrap';
import { useHistory } from "react-router-dom";
import { connect } from 'react-redux';
import { login } from '../../actions/auth';
import PropTypes from 'prop-types';
// import FacebookLoginButton from './FacebookLoginButton';

const LoginPopup = ({ toggleLoginModal, login, auth: {isAuthenticated, user}, alerts }) => {
	const history = useHistory();
	const [formData, setFormData] = useState({
		email: '',
		password: ''
	});

  const { email, password } = formData;
	
	const onChange = e =>
	    setFormData({ ...formData, [e.target.name]: e.target.value });

  const [ isSubmitting, setIsSubmitting ] = useState(false);

  let timeoutID;
	const onSubmit = async e => {
		e.preventDefault();
		setIsSubmitting(true);
    await login(email,password);
	};

	//Trigger Page reload once authenticated AND user is loaded.  If done prior to user loaded, Firefox will not store token correctly.
  useEffect(()=> {
    if (isAuthenticated && user && user._id) {
      // window.location.reload();
      toggleLoginModal(false);
    }
  },[isAuthenticated, user]);

  useEffect(()=> {
    setIsSubmitting(false);
  },[alerts]);

  const forgotPassword = () => {
    toggleLoginModal(false);
    history.push('/forgot');
  };

  return (
    <Fragment>
      <Form onSubmit={e => onSubmit(e)} className="msstd-form container">
        { alerts !== null && alerts.length > 0 &&
            alerts.map(alert => (
              <div key={alert.id} className='modal-alert'>
                {alert.msg}
              </div>
            ))
        }
        <h4> Login </h4>
        {/* in dev
        <div className='mt-3'>
          <FacebookLoginButton />
        </div>
        */}
        <div className="mt-4" />
        <FormGroup>
          <Input type="email" 
              name="email" 
              id="email" 
              placeholder="Email Address"
              value={email}
              onChange={e => onChange(e)} />
        </FormGroup>
        <FormGroup>
          <Input type="password" 
              name="password" 
              id="password" 
              placeholder="Password"
              value={password}
              onChange={e => onChange(e)} />
        </FormGroup>
        <button
          disabled={isSubmitting}
          type="submit"
          className="btn btn-modal-blue btn-set-WH"
        >
          <span>
            {isSubmitting ? (
              <Spinner size="sm" className='color-white'/>
            ) : (
              "Sign In"
            )}
          </span>
        </button>
      <div>
        Forgot your password? ...if I had a nickel. <button type='button' onClick={()=>forgotPassword()} className='basic-button text-bold text-underline'>Reset Password</button>
      </div>
      </Form>          
    </Fragment>
  );
};

LoginPopup.propTypes = {
  login: PropTypes.func.isRequired,
  auth: PropTypes.object,
  alerts: PropTypes.array
};

const mapStateToProps = state => ({
	auth: state.auth,
  alerts: state.alert
});

export default connect(mapStateToProps, { login })(LoginPopup);
