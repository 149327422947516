import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Modal, ModalBody } from 'reactstrap';
import LoginPopup from '../../auth/LoginPopup';
import RegisterPopup from '../../auth/RegisterPopup';
import CreateProviderPreGeolocate from '../../classprovider-forms/CreateProviderPreGeolocate';
import checkYellow from '../../../images/check-yellow_640-640.svg';
import checkRed from '../../../images/check-red_640-640.svg';
import arrowFwdBlue from '../../../images/arrow-fwd-blue_640-640.svg';
import ProviderTable from '../ProviderTable';
import queryString from 'query-string';
// import CreateProvider from '../../classprovider-forms/CreateProvider';

const FeatureProviders = ({auth, provider: { providers } }) => {

  const history = useHistory();
  const goToHomeschoolMap = () => {
    history.push(`/homeschoolmap`);
  }

  const [ loginModal, toggleLoginModal ] = useState(false);
  const [ registerModal, toggleRegisterModal ] = useState(false);

  const swapLoginRegister = () => {
    toggleLoginModal(!loginModal);
    toggleRegisterModal(!registerModal);
  };

  const [ createProviderModal, toggleCreateProviderModal ] = useState(false);

  useEffect(()=> {
    if (auth.isAuthenticated && providers === null) {
      toggleLoginModal(false);
      toggleRegisterModal(false);
      toggleCreateProviderModal(true);
    };
  },[]);


  return (
      <Fragment>
        <section className="section features-area style-two overflow-hidden ptb_100 bg-overlay">
            <div className="container">
                <div className="row">

                  <div className="col-12 col-md-6 col-lg-4 my-3 res-margin">
                      <button type='button' className='basic-button' onClick={()=>toggleLoginModal(!loginModal)} disabled={auth.isAuthenticated}>
                          <div className="image-box text-center icon-1 p-5 find-family-feature-cards">
                              <div className="featured-img mb-3">
                                  <i className="fas fa-user-plus fa-2x color-myschool-red" alt="Register icon" />
                              </div>
                              <div className="icon-text">
                                  <h4 className="mb-2">1. Register and Login</h4>
                                  {auth.isAuthenticated ? 
                                    (
                                      <div>
                                        <img src={checkRed} width='50px' alt='check step 1'/>
                                        <p>Great! You're already done with this step!</p>
                                      </div>
                                    )
                                    : 
                                    <p>It's free and easy!  Click here to do it now!</p>
                                  }
                              </div>
                          </div>
                      </button>
                  </div>

                  <div className="col-12 col-md-6 col-lg-4 my-3 res-margin">
                      <button 
                        type='button' 
                        className='basic-button' 
                        onClick={!auth.isAuthenticated ? 
                          ()=>toggleLoginModal(!loginModal)
                          :
                          ()=>toggleCreateProviderModal(!createProviderModal)
                        }
                      >
                          <div className="image-box text-center icon-1 p-5 find-family-feature-cards">
                              <div className="featured-img mb-3">
                                  <i className="fas fa-address-card fa-2x color-myschool-yellow" alt="Create Business Profile" />
                              </div>
                              <div className="icon-text">
                                  <h4 className="mb-2">2. Create a Business Profile</h4>
                                  {providers && providers.length > 0 ? 
                                    (
                                      <div>
                                        <img src={checkYellow} width='50px' alt='check step 2'/>
                                        <p>Great! You're already done with this step too!</p>
                                      </div>
                                    )
                                    : 
                                    <div>
                                      <p>It's free and easy!  Click here to do it now!</p>
                                    </div>
                                  }
                                  
                              </div>
                          </div>
                      </button>
                  </div>

                  <div className="col-12 col-md-6 col-lg-4 my-3 res-margin">
                    <div className="image-box text-center icon-1 p-5 find-family-feature-cards">
                        <div className="featured-img mb-3">
                            <i className="fas fa-dollar-sign fa-2x color-myschool-blue" alt="dollar sign" />
                        </div>
                        <div className="icon-text">
                            <h4 className="mb-2">3. Connect your account!</h4>
                            <p> *Optional, but this allows you to host events, take bookings, accept payments, send automated confirmations, and more! We take a portion of the transaction, so if you don't get any bookings,<span className='text-bold'>{' '}you don't pay a thing!</span></p>
                            <hr/>
                            {auth.isAuthenticated && providers && providers.length > 0 ? 
                              (
                                <div>
                                  <ProviderTable providers={providers} />
                                </div>
                              )
                              : 
                              <p>You haven't registered any spots yet.</p>
                            }
                        </div>
                    </div>
                  </div>

                </div>
            </div>
        </section>

      <Modal isOpen={loginModal} centered toggle={() => toggleLoginModal(!loginModal)}>
        <ModalBody className='text-center'>
          <div className='float-right'>
            <button className="basic-button" onClick={() => toggleLoginModal(!loginModal)}>
            <i className='fa fa-times color-light-grey'/>
            </button>
          </div>
          <LoginPopup loginModal={loginModal} toggleLoginModal={toggleLoginModal}/>
          <button onClick={()=>swapLoginRegister()} className='basic-button text-bold text-underlined'>I need to Register</button>
        </ModalBody>
      </Modal>

      <Modal isOpen={registerModal} centered toggle={() => toggleRegisterModal(!registerModal)}>
        <ModalBody className='text-center'>
          <div className='float-right'>
            <button className="basic-button" onClick={() => toggleRegisterModal(!registerModal)}>
            <i className='fa fa-times color-light-grey'/>
            </button>
          </div>
          <RegisterPopup registerModal={registerModal} toggleRegisterModal={toggleRegisterModal} history={history}/>
          <button onClick={()=>swapLoginRegister()} className='basic-button text-bold text-underlined'>Go to Login</button>
        </ModalBody>
      </Modal>

      <Modal isOpen={createProviderModal} centered toggle={() => toggleCreateProviderModal(!createProviderModal)}>
        <ModalBody className='text-center'>
          <div className='float-right'>
            <button className="basic-button" onClick={() => toggleCreateProviderModal(!createProviderModal)}>
            <i className='fa fa-times color-light-grey'/>
            </button>
          </div>
          <div className='container-fluid'>
            <CreateProviderPreGeolocate/>
          </div>
        </ModalBody>
      </Modal>

    </Fragment>
  );
}

FeatureProviders.propTypes = {
  auth: PropTypes.object.isRequired,
  provider: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  provider: state.provider,
});

export default connect(
  mapStateToProps,
  { },
)(FeatureProviders);