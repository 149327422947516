import React, { Component } from 'react';
import { Badge } from 'reactstrap';
import iconUseSpot from '../../../images/icon-use-a-spot.svg';
import badgeLogo from '../../../images/badge_your-logo-here.svg';
import myschoolPlus from '../../../images/logo_myschool-plus_150-150.svg';
import tenPlus from '../../../images/tenPlus.svg';

const data = [
    {
        iconImage: tenPlus,
        title: 'What you do',
        content: 'Buy 10 or more MySchool+ memberships at discounted pricing (see table below).',
    },
    {
        iconImage: badgeLogo,
        title: 'What you get',
        content: "Your organization's logo and a link to your site on all sponsored members' dashboards for A FULL YEAR.",
    },
    {
        iconImage: myschoolPlus,
        title: 'What the member gets',
        content: 'A FULL YEAR of MySchool+ benefits and a cool badge on their dashboard!'
    }
]

class SponsorWhatIs2 extends Component {
    state = {
        data: []
    }
    componentDidMount(){
        this.setState({
            data: data
        })
    }
    render() {
        return (
            <section id="features" className="section features-area style-two overflow-hidden ptb_50">
                <div className="container">
                    <div className="text-center">
                        <h2 className="section-heading">How does it work?</h2>
                    </div>
                    <div className="row">
                        {this.state.data.map((item, idx) => {
                            return(
                                <div key={`ffd_${idx}`} className="col-12 col-md-6 col-lg-4 my-3 res-margin">
                                    {/* Image Box */}
                                    <div className="image-box text-center icon-1 p-5">
                                        {/* Featured Image */}
                                        <div className="featured-img mb-3">
                                            {item.fontAwesomeIcon && <i className={item.fontAwesomeIcon} alt=""/>}{' '}
                                            {item.iconImage && <img src={item.iconImage} alt="" width="60px"/>}{' '}
                                            {item.badge ? (<p><Badge color='warning'>{item.badge}</Badge></p>):''}
                                        </div>
                                        {/* Icon Text */}
                                        <div className="icon-text">
                                            <h4 className="mb-2">{item.title}</h4>
                                            <p>{item.content}</p>
                                            {item.content2 ? <p className='text-italic text-075rem'>{item.content2}</p>:''}
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </section>
        );
    }
}

export default SponsorWhatIs2;