import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Card, CardText, CardBody, CardTitle, Button, CardHeader } from 'reactstrap';
import PropTypes from 'prop-types';
import Spinner from '../../layout/Spinner';
import extendCircle from '../../../images/icon-extend-down-circle.svg';
import api from '../../../utils/api';
import { setAlert } from '../../../actions/alert';
import ImageContainer from '../../layout/ImageContainer';
import badgeDefault from '../../../images/badge_sponsor_default.svg';
import dayjs from 'dayjs';
import ReactGA from 'react-ga';

const DashboardUser = ({ auth: { user, loading }, displaySection, history }) => {

  const [displayInfo, toggleInfo] = useState(true);
  const customerPortalButton = async () => {
    try {
      const res = await api.post(`/v1/myschoolplus/create-portal-session`,{});
      window.location.href = res.data.url;
    } catch (err) {
      if (err && err.response && err.response.data && err.response.data.msg) {
        setAlert(err.response.data.msg, 'danger');
      }
      console.log(err);
    }
  }

  const [ copySuccess, setCopySuccess ] = useState(false);
  const [ copyText, setCopyText] = useState('Copy Referral Link');
  const copyReferralLink = async () => {
    ReactGA.event({ category: "referrals", action: "copy-referral-link", label: `${user._id}`});
    try {
      await navigator.clipboard.writeText(`${window.location.origin}/getting-started?referrer=${user._id}`)
      setCopySuccess(true);
      setCopyText('Successfully Copied');
      setTimeout(()=> {
          setCopySuccess(false);
          setCopyText('Copy Referral Link');
      },1500);
    } catch (err) {
      setCopyText('Error')
      setTimeout(()=> {
          setCopyText('Copy Referral Link');
      },1500);
    }
  };

  return loading || !user ? (
    <Spinner />
  ) : (
    <Fragment>          

        { displaySection.userInfo && user !== null ? 
          ( 
            <Fragment>
            <div className='my-3'>
              Thanks for being a member of MySchool!  As a registered user, you have full access to post, rate and review your favorite learning spots, contact spots, and sign up for events!  Want to see other homeschoolers nearby or host events?{' '}<Link to="/findFamilies" className='basic-button text-bold text-underline'>Create a personal profile!</Link>{' '}Do you run an organization that offers learning opportunities? {' '}<Link to="/create-provider" className='basic-button text-bold text-underline'>Create a spot profile!</Link>{' '}
            </div>
            <Card>
              <CardHeader>
                <CardTitle>User Information 
                  <Button className="basic-button float-right" onClick={() => toggleInfo(!displayInfo)}><img src={extendCircle} width="25px" alt="extend info dropdown"/>
                  </Button>
                </CardTitle>
              </CardHeader>

              { displayInfo &&
                <div className="d-flex">
                  <CardBody>
                    <div className="col">
                      <div className='my-1 d-flex flex-row-reverse'>
                        <Link to='/edit-user' className='btn btn-primary'>
                          <i className='fas fa-user-circle' /> Edit User
                        </Link>
                        <Link to='/forgot' className='btn btn-primary'>
                          Reset Password
                        </Link> 
                      </div>
                      <CardText>
                        <strong>Username:</strong> {user && user.username}
                      </CardText>
                      <CardText>
                        <strong>Email:</strong> {user && user.email}
                      </CardText>
                      <CardText>
                        <strong>Email List Subscriber:</strong> {user && user.indivMailingListCheck === true ? 'Yes' : 'No'} 
                      </CardText>
                      <div>
                        <div>
                          <span className="text-bold">MySchool+{" "}subscriber status:</span>{" "}
                          {
                            user.subscriberStatusActive && user.paidSubscriberStatus ? 
                              <div className="d-inline">
                                {`${user.paidSubscriberStatus} `}
                                <button type="button" className="btn btn-myschool-red btn-small" onClick={()=>customerPortalButton()}>Manage your Subscription</button>
                              </div>
                              :
                              user.subscriberStatusActive && user.isMyschoolPlusSponsored ? 
                              <div className="d-inline">
                                Active{" "}
                              </div>
                              :
                              <div className="d-inline">
                                Inactive{" "}
                                <button type="button" className="btn btn-myschool-red btn-small" onClick={()=>history.push('/myschoolplus/subscribe')}>Subscribe Now!</button>
                              </div>
                          }
                        </div>
                      </div>
                      { user.subscriberStatusActive ? 
                        <div>
                          <span className="text-bold">Full Name (for your digital homeschool I.D.):</span>{` `}{user.fullname} 
                        </div>
                        :''
                      }
                      {/*
                        <div className="text-bold mt-4 d-flex align-middle">
                          <div className="justify-content-start">
                            {user.paidSubscriberStatus === 'active' ? `Cost of your next MySchool+ payment` : `Get MySchool+ for only`}:
                            <span className="mx-2 text-2rem color-myschool-red d-inline align-middle">{`$${user.subscriberNextDiscount ? 25-user.subscriberNextDiscount : 25}`}</span>
                            <button 
                              type='button' 
                              className=
                                {copyText==='Successfully Copied' ? `btn btn-success`
                                : copyText==='Error' ? `btn btn-danger`
                                :`btn btn-myschool-blue`
                                } 
                              onClick={()=>copyReferralLink()}><i className="fas fa-copy"/>
                              {` ${copyText}`}
                            </button>
                          </div>
                        </div>
                      */}
                    </div>
                  </CardBody>
                  {user.isMyschoolPlusSponsored && 
                    user.myschoolPlusSponsorInfo &&
                    user.myschoolPlusSponsorInfo.badgeLink &&
                    dayjs().isBefore(dayjs(user.myschoolPlusSponsorInfo.expires))  ? //NEED TO ADD DATE.NOW()
                    <div className='col-3'>
                      <div style={{position:"absolute",top:"-5px"}} className="cursor-pointer" 
                        onClick={
                          !user.myschoolPlusSponsorInfo.useExternalLink && user.myschoolPlusSponsorInfo.provider ? ()=>history.push(`/provider/${user.myschoolPlusSponsorInfo.provider}`)
                          :
                          !user.myschoolPlusSponsorInfo.useExternalLink && user.myschoolPlusSponsorInfo.resource ? ()=>history.push(`/resource/${user.myschoolPlusSponsorInfo.resource}`)
                          :
                          user.myschoolPlusSponsorInfo.useExternalLink && user.myschoolPlusSponsorInfo.externalLink ? ()=>window.open(`${user.myschoolPlusSponsorInfo.externalLink}`, "_blank")
                          : ()=>console.log('Erorr: Unknown link')
                        }>
                        <ImageContainer
                          src={user.myschoolPlusSponsorInfo && user.myschoolPlusSponsorInfo.badgeLink ? user.myschoolPlusSponsorInfo.badgeLink : badgeDefault}
                          thumb={badgeDefault}
                          width='200px' 
                          height='200px' 
                          className='' 
                          alt='sponsor badge image'
                        />
                      </div>
                    </div>
                  :""
                  } 
                </div>
              }
            </Card>
            </Fragment>
          )
          :''
        }

    </Fragment>
  );
};

DashboardUser.propTypes = {
  auth: PropTypes.object.isRequired,
  setAlert: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});


export default connect(mapStateToProps, { setAlert })(DashboardUser);