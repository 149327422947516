import React, { Fragment, useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Spinner, Badge } from 'reactstrap';
import api from '../../utils/api';

const ProviderStripeConnectStatusBadge = ({ provider }) => {

  const history = useHistory();
  const params = useParams();
  const [ accountLoaded, setAccountLoaded] = useState(false);
  const [ accountDetails, setAccountDetails] = useState({});

  useEffect(()=> {
    const getStripeAccount = async () => {
      try {
        const stripeAccount = await api.get(`/v1/stripeConnect/stripe-connect-account/provider/${provider._id}`);
        // if (stripeAccount) console.log('stripeAccount Details: ',provider.providerName,stripeAccount);
        setAccountDetails(stripeAccount.data);
        setAccountLoaded(true);
      } catch (err) {
        setAccountLoaded(true);
      } 
    };
    if (provider && provider.stripeAccount) {
      getStripeAccount();
    } else {
      setAccountLoaded(true);
    };
  }, [provider]);

  return (
    <Fragment>
      {accountLoaded && accountDetails && accountDetails.details_submitted && accountDetails.charges_enabled ?
        <Badge color="success" pill>Stripe account connected!</Badge>
        :
        accountLoaded && accountDetails && accountDetails.details_submitted && !accountDetails.charges_enabled ?
        <button onClick={()=>history.push(`/provider/${provider._id}/choose-payment-method`)} className='basic-button'>
          <Badge color="warning" pill>In process, pending verification</Badge>
        </button>
        : accountLoaded && (!accountDetails || !accountDetails.details_submitted || !accountDetails.charges_enabled) ?
        <button onClick={()=>history.push(`/provider/${provider._id}/choose-payment-method`)} className='basic-button'>
          <Badge color="danger" pill>Not connected</Badge>
        </button>
        :
        <Spinner size="sm" className='color-myschool-blue'/>
      }
    </Fragment>

  );
};

export default ProviderStripeConnectStatusBadge;

