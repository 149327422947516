import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, ButtonGroup, Alert, Container, Row, Col, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';
import ReactGA from 'react-ga';
import Pricing from './Pricing';
import AuthModals from '../auth/AuthModals'; 

const MySchoolPlusSignUp = ({auth: {isAuthenticated, user}}) => {

    const history = useHistory();
    const [ loginModal, toggleLoginModal ] = useState(false);
    const [ registerModal, toggleRegisterModal ] = useState(false);

    const [initData, setInitData] = useState({
        preHeading: "Premium",
        preHeadingspan: "Features",
        heading: "MySchool Plus",
        headingText: "Learn more. Spend less.",
    });

    const [showIdMoreInfo, toggleIdMoreInfo ] = useState(false);
    const click = () => {
        console.log('click')
    }

    return (
        <div className="container">
            <div className="row justify-content-center text-center">
                {/* Section Heading */}
                <div className="section-heading">
                    <span className="d-inline-block rounded-pill shadow border fw-5 px-4 py-2 mb-3">
                        <i className="far fa-lightbulb text-primary mr-1" />
                        <span className="text-primary">{initData.preHeading}</span>
                        {initData.preHeadingspan}
                    </span>
                    <h2>{initData.heading}</h2>
                    <div className="mt-4 text-bold">
                        {initData.headingText}
                    </div>
                </div>
            </div> 
            <Container className='mt-5 mb-4'>
                <div className="text-center">
                    <div className="section-heading">
                        <h4>We're currently in Beta!</h4>
                    </div>
                </div>
                <div className='text-center'>
                  <a href={`mailto:info@myschoolathome.io?subject=I'm interested in MySchool+!`} target='_blank' rel='noopener noreferrer' className='btn btn-myschool-blue'>
                    Send us an email to request early access!
                  </a>
                </div>
            </Container>

            {/*!isAuthenticated && 
                <div className='text-center'>
                    <button onClick={()=>toggleLoginModal(true)} className='btn btn-modal-blue'>
                        Login or Register to get started! 
                    </button>
                </div>
            */}           

            <AuthModals loginModal={loginModal} toggleLoginModal={toggleLoginModal} registerModal={registerModal} toggleRegisterModal={toggleRegisterModal} />
        </div>
    );
}

MySchoolPlusSignUp.propTypes = {
    auth: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(
  mapStateToProps,
  {  },
)(MySchoolPlusSignUp);