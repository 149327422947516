import React from 'react';
import { Container } from 'reactstrap';

const TermsAndConditions = () => {

    const paragraphs = [  
        'Please read these terms and conditions carefully as they contain important information about your legal rights, remedies and obligations. By accessing or using the MySchool Platform, you agree to comply with and be subject to the following terms and conditions, as well as our Privacy Policy, which also governs your use of the MySchool Platform, and is incorporated herein by reference (collectively, the “Terms”).',
        'Last Updated: February 17, 2022',
        'First off, thank you for using MySchool’s service! If you ever work with anyone who does something better than we do, or you have ideas on how we can improve, please tell us. We are here to serve you, and if you ever doubt that then you are invited to tell us to get our acts together.',
        'These Terms constitute a legally binding agreement ("Agreement") between you and MySchool (as defined below) governing your access to and use of the MySchool website, including any subdomains thereof, and any other websites through which MySchool makes its services available (collectively, "Site"), our mobile, tablet and other smart device applications, and application program interfaces (collectively, "Application") and all associated services (collectively, "MySchool Services"). The Site, Application and MySchool Services together are hereinafter collectively referred to as the “MySchool Platform”.',
        'When these Terms mention “MySchool,” “we,” “us,” or “our,” it refers to MySchool, Inc., located at 651 N. Broad St., Suite 206, Middletown, 19709, United States.',
        'Any and all payment processing services through or in connection with your use of the MySchool Platform ("Payment Services") are provided to a third party service (Stripe - https://stripe.com/) and are subject to the terms of use of those Payment Services.',
        'Providers alone are responsible for identifying, understanding, and complying with all laws, rules and regulations that apply to their Listings and Provider Services (as defined below).',
    ];

    const list = [
        {
            title: 'Scope of MySchool Services',
            list: [ 
                'The MySchool Platform is an online marketplace that enables registered users (“Members”) and certain third parties who offer services (Members, Resource Members, and third parties who offer services are collectively referred to as “Providers” and the services they offer are “Provider Services”) to publish such Provider Services on the MySchool Platform (“Listings”) and to communicate and transact with Members that are seeking to book such Provider Services (Members using Provider Services are “MySchoolers”).',
                'As the provider of the MySchool Platform, MySchool does not own, create, sell, resell, provide, control, manage, offer, deliver, or supply any Listings or Provider Services. Providers alone are responsible for their Listings and Provider Services. When Members make or accept a booking, they are entering into a contract directly with each other. MySchool is not and does not become a party to or other participant in any contractual relationship between Members, nor is MySchool a public or non-public school. MySchool is not acting as an agent in any capacity for any Member.',
                'If you choose to use the MySchool Platform as a Provider (as defined below), your relationship with MySchool is limited to being an independent, third-party contractor, and not an employee, agent, joint venturer or partner of MySchool for any reason, and you act exclusively on your own behalf and for your own benefit, and not on behalf, or for the benefit, of MySchool. MySchool does not, and shall not be deemed to, direct or control you generally or in your performance under these Terms specifically, including in connection with your provision of the Provider Services. You acknowledge and agree that you have complete discretion whether to list Provider Services or otherwise engage in other relationships, business activities, or employment activities.',
                'To promote the MySchool Platform and to increase the exposure of Listings to potential MySchoolers, Listings and other Member Content may be displayed on other websites, in applications, within emails, and in online and offline advertisements.',
                'MySchool also provides additional services to MySchool Plus (denoted as "MySchool Plus" or "MySchool+") members through a subscription service. Futher details on that program are outlined below.',
                'The MySchool Platform may contain links to third-party websites or resources (“Third-Party Services”). Such Third-Party Services may be subject to different terms and conditions and privacy practices. MySchool is not responsible or liable for the availability or accuracy of such Third-Party Services, or the content, products, or services available from such Third-Party Services. Links to such Third-Party Services are not an endorsement by MySchool of such Third-Party Services.',
                'Due to the nature of the Internet, MySchool cannot guarantee the continuous and uninterrupted availability and accessibility of the MySchool Platform. MySchool may restrict the availability of the MySchool Platform or certain areas or features thereof, if this is necessary in view of capacity limits, the security or integrity of our servers, or to carry out maintenance measures that ensure the proper or improved functioning of the MySchool Platform. MySchool may improve, enhance and modify the MySchool Platform and introduce new MySchool Services from time to time.',
            ]            
        },
        {
            title: 'Eligibility & Usage of the MySchool Platform',
            list: [
                'In order to access and use the MySchool Platform or register an MySchool Account you must be an individual at least 18 years old or the responsible party for a business, organization or other legal entity in good standing under the law and able to enter into legally binding contracts for the business. You are not authorized to use this Site unless you are at least 18 years of age and able to enter into a legally binding contract. We do not knowingly collect the information of anyone under the age of 18. If you arrived on the Site after having been redirected or otherwise clicking on another website, you agree that these Terms shall govern your use of this Site.'
            ]
        },
        {
            title: 'Modification of these Terms',
            list: [
                'MySchool reserves the right to modify these Terms at any time in accordance with this provision. If we make changes to these Terms, we will post the revised Terms on the MySchool Platform and update the “Last Updated” date at the top of these Terms. If you disagree with the revised Terms, you may terminate this Agreement with immediate effect.'
            ]
        },
        {
            title: 'Personal Data that we Collect',
            list: [
                'Personal Data is any information that relates to an identified or identifiable individual. The Personal Data that you provide directly to us through our Applications will be apparent from the context in which you provide the data and submit it to MySchool. MySchool takes many precautions to protect this data, but we cannot guarantee that the site and our third party data service provider (MongoDB - https://www.mongodb.com/) will not be hacked. By submitting Personal Data, you acknowledge acceptance of this risk.'
            ]
        },
        {
            title: 'Provider Payments and Fees',
            list: [
                'We charge Providers who offer Provider Services (classes, camps, events, etc.) a 10% + $0.40 service fee. This is calculated after fees are deducted by MySchool\'s payment processor, and is deducted from the host’s payout before being sent to the host. Payouts are processed on the 1st day of every month following the receipt of payment. All MySchoolers that booked Provider Services should be notified of any cancellations as early as possible.'
            ]
        },
        {
            title: 'Event Standard Cancellation Policy',
            list: [
                'Event cancellations for those booked through MySchool\'s Services are accepted up to 24 hours before the Provider Service is to begin. The "beginning" of the Provider Service is either the day of the Provider Service, if it is a single day Provider Service, or the first day of a multi-day Provider Service, if it extends for more than one day. Cancellation before the 24-hour window will include the amount that the provider receives, minus the MySchool service fee and payment processing fee. Cancellations by the host will result in a complete refund, including all associated fees. All requests for cancellations should be sent by email to info@myschoolathome.io.'
            ]
        },
        {
            title: 'MySchool Plus Members',
            list: [
                'Myschoolers may opt to subscribe to MySchool+ for additional services. This subscription service can be cancelled at anytime from your user dashboard (https://myschoolathome.io/dashboard). Once cancelled, the User may access MySchool+ services through the end of the subscription period, at which time access will expire and the User will not be charged for a recurring subscription.'
            ]
        },
        {
            title: 'Limited License to Use the Site',
            list: [
                'Users of this Site are granted a limited, revocable non-exclusive license to access the Site and the content and services provided on the Site solely for the purpose of advertising Provider Services, searching for Provider Services, or for any other purpose clearly stated on the Site, all in accordance with the Terms. Any use of the Site that is not for one of these purposes or otherwise in accordance with the Terms or as otherwise authorized by us in writing is expressly prohibited.'
            ]
        },
        {
            title: 'Proprietary Rights and Downloading of Information from the Site',
            list: [
                'The Site and all content and information on the Site are protected by copyright as a collective work and/or compilation under applicable U.S. and international copyright laws and conventions and database rights. You agree to abide by any and all copyright notices, information, or restrictions contained in or relating to any content on the Site. Copying, storing, or otherwise accessing the Site or any content on the Site other than for your personal, noncommercial use (other than in accordance with a valid listing for Provider Services) is expressly prohibited without prior written permission from us. As part of your inquiry about Provider Services, for your own personal, noncommercial use and not for further distribution, you may download, display and/or print one copy of any portion of the Site. You may not modify the same, and you must reproduce our copyright notice in the form displayed on the relevant portion(s) of the Site that you desire to download, display or print.'
            ]
        },
        {
            title: 'Your email Address and Data; Our Privacy Policy; Data Transmittal',
            list: [
                'When you provide your email address, name, or other information to us in connection with your use or access to the Site, any service or tool provided on the Site or otherwise, you agree to allow the Site and its affiliated websites to add your email address, name, or other information provided to our database of users. You may receive one or more promotional emails from the Site. You are welcome to opt not to receive such promotional emails from the Site at any time. Please review our Privacy Policy for more information regarding our email and other data collection practices and safeguards, and how to opt not to receive such emails. Your use of the Site signifies your acknowledgment of, and agreement with, our Privacy Policy. You agree that we may access and use your user-contributed content in accordance with these Terms or our Privacy Policy and we agree that we will only disclose your user-contributed content in accordance with these Terms and our Privacy Policy. '
            ]
        },
        {
            title: 'Identity Verification',
            list: [
                'You agree to (i) keep your password and online ID for both your account with us and your email account secure and strictly confidential, providing it only to authorized users of your accounts, (ii) instruct each person to whom you give your online ID and password that he or she is not to disclose it to any unauthorized person, (iii) notify us immediately and select a new online ID and password if you believe your password for either your account with us or your email account may have become known to an unauthorized person, and (iv) notify us immediately if you are contacted by anyone requesting your online ID and password. Further, if we suspect any unauthorized access to your account, upon our request, you agree to promptly change your ID and password and take any other related action as we may reasonably request.',
                'We discourage you from giving anyone access to your online ID and password for your account with us and your email account. However, if you do give someone your online ID and online password, or if you fail to adequately safeguard such information, you are responsible for any and all transactions that the person performs while using your account with us and/or your email account, even those transactions that are fraudulent or that you did not intend or want performed. ',
                'EACH USER ACKNOWLEDGES AND AGREES THAT: (1) MYSCHOOL WILL NOT HAVE ANY LIABILITY TO ANY USER FOR ANY UNAUTHORIZED TRANSACTION MADE USING ANY USER’S ID OR PASSWORD; AND (2) THE UNAUTHORIZED USE OF YOUR ONLINE ID AND PASSWORD FOR YOUR MYSCHOOL ACCOUNT OR YOUR EMAIL ACCOUNT COULD CAUSE YOU TO INCUR LIABILITY TO BOTH MYSCHOOL AND OTHER USERS. '
            ]
        },
        {
            title: 'Limitations on Communications and Use of Other Users\' Information; No Spam.',
            list: [
                'You agree that, with respect to other users\' personal information that you obtain directly or indirectly from or through the Site or through any Site-related communication, transaction or software, we have granted to you a license to use such information only for: (i) Site-related communications that are not unsolicited commercial messages, (ii) using services offered through the Site, and (iii) inquiring about or otherwise facilitating a financial transaction between you and the other user related to the purpose of the Site (such as inquiring about or booking a Provider Service). Any other purpose will require express permission from the user. You may not use any such information for any unlawful purpose or with any unlawful intent. ',
                'In all cases, you must give users an opportunity to remove their information from your address book or database or other records and a chance to review what information you have collected about them. In addition, under no circumstances, except as defined in this provision, may you disclose personal information about another user to any third party without the consent of the other user. You agree that other users may use your personal information to communicate with you in accordance with this provision. Further, you agree that you will protect other users\' personal information with the same degree of care that you protect your own confidential information (using at minimum a reasonable standard of care), and you assume all liability for the misuse, loss, or unauthorized transfer of such information.',
                'We do not tolerate spam or unsolicited commercial electronic communications of any kind. Therefore, without limiting the foregoing, you are not licensed to add a Site user, even a user who has purchased Provider Services from you, to your mailing list (email or physical mail) without the user\'s express consent. You may not use any tool or service on the Site to send spam or unsolicited commercial electronic communications of any kind or in any other way that would violate these Terms. '
            ]
        },
        {
            title: 'Responsibility for Provider Services Listings, Reviews and Other User-Contributed Content.',
            list: [
                'We have no duty to pre-screen content posted on the Site by users, whether directly contributed by a user or contributed by us or a third party on behalf of a user (collectively, “user-contributed content”). We are not responsible for user-contributed content and we specifically disclaim all liability for user-contributed content.',
                'We reserve the right to decline to permit the posting on the Site of or to remove from the Site any user-contributed content that violates these Terms, as determined in our discretion. Users remain responsible for reviewing their user-contributed content to ensure it is accurate and not misleading.',
                'By submitting or authorizing user-contributed content, you grant to us a perpetual, worldwide, irrevocable, unrestricted, non-exclusive, royalty-free, and fully paid-up license to use, copy, license, sublicense (through multiple tiers), adapt, distribute, display, publicly perform, reproduce, transmit, modify, edit, and otherwise exploit the copy, the photographs and the likenesses (if any) of any of your user-contributed content, in connection with our business. ',
                'You further grant us the ability to register copyright in and protect the user-contributed content, including the images, copy, and content available via any member’s listing, from the unauthorized use of the user-contributed content by unaffiliated third parties who may, from time to time, attempt to pirate such information via electronic or other means. This includes, but is not limited to, the right to file suit to seek injunctive relief to protect such material on behalf of and in your name. You further agree to appear and assist us—at our expense and control—with protecting such copyrighted material from unauthorized redistribution.',
                'You agree that we may sublicense all the rights granted to us under these Terms to one or more third parties we may contract with to display all or part of the listings for Provider Services or otherwise provide promotional or other services related to our business. In the event that you retain any rights of attribution, integrity, or any other moral rights in any user-contributed content, you hereby waive your right to assert these or to require that any personally identifying information be used in connection with the user-contributed content or any derivative works thereof and affirm that you have no objection to the publication, use, modification, deletion or exploitation of the user-contributed content by us or our affiliates.',
            ]
        },
        {
            title: 'Social Media or Third-Party Websites.',
            list: [
                'If the Site offers a tool or service that allows us to access or use any profile or other information about you that you have provided to Facebook or another third-party website (each a “Social Media Site”) and you decide to use such tool or service, you acknowledge and agree that:',
                '(i) The information or content that is a part of your Social Media Site profile, which you have designated as "public" will be considered user-generated content under these Terms and we shall have the same rights and responsibilities we have with respect to user-generated content under these Terms;',
                '(ii) In the event that the social media content was for any reason misclassified with a public or similar designation or is otherwise inaccurate or to which you do not agree with for any reason, you agree to work with the Social Media Site to make any changes or resolve any disputes and acknowledge that we will not be able to provide you with recourse; and',
                '(iii) The operation of your profile and account with and on the Social Media Site shall continue to be governed by the terms and conditions and privacy policy of such Social Media Site.'
            ]
        },
        {
            title: 'Notification of Infringement.',
            list: [
                'We respect the intellectual property rights of others, and MySchool does not permit, condone, or tolerate the posting of any content on the Site that infringes any person\'s copyright. MySchool will terminate, in appropriate circumstances, a Member or Provider who is the source of repeat infringement of copyright. Should you become aware of or suspect any copyright infringement on this Site, please contact us at: info@myschoolathome.io.'
            ]
        },
        {
            title: 'Information Provided by You',
            list: [
                'Any information provided by you to MySchool, including but not limited to feedback, data, questions, comments, suggestions, or the like, shall be deemed to be non-confidential. MySchool shall have no obligation of any kind with respect to such information and shall be free to reproduce, use, disclose, and distribute the information to others without limitation.',
                'Additionally, MySchool shall be free to use any ideas, concepts, know-how, or techniques contained in such information for any purpose whatsoever, including but not limited to developing and marketing products incorporating such information.'
            ]
        },
        {
            title: 'Limitation of Liability',
            list: [
                'IN NO EVENT WILL MYSCHOOL OR ITS OFFICERS, MEMBERS, CONSULTANTS, AGENTS, AND/OR EMPLOYEES (COLLECTIVELY, THE “MYSCHOOL GROUP”), OR ANY THIRD-PARTY PROVIDER OF A SERVICE OR TOOL OFFERED ON THE SITE (EACH A “THIRD-PARTY PROVIDER”), BE LIABLE FOR ANY LOST PROFITS OR ANY INDIRECT, CONSEQUENTIAL, SPECIAL, INCIDENTAL, OR PUNITIVE DAMAGES ARISING OUT OF, BASED ON, OR RESULTING FROM (A) OUR SITE, (B) THESE TERMS, (C) ANY BREACH OF THESE TERMS BY YOU OR A THIRD PARTY, (D) USE OF THE SITE, TOOLS, OR SERVICES WE PROVIDE, OR ANY THIRD-PARTY PROVIDER PROVIDES, RELATED TO THE BUSINESS WE OPERATE ON THE SITE, BY YOU OR ANY THIRD PARTY, (E) ANY USER-CONTRIBUTED CONTENT, (F) INTERACTION BETWEEN OUR SITE AND ANY THIRD-PARTY SITE, INCLUDING WITHOUT LIMITATION A SOCIAL MEDIA SITE, FACILITATED BY A TOOL OR SERVICE ON OUR SITE, AND/OR (G) ANY ACTUAL OR ATTEMPTED COMMUNICATION OR TRANSACTION, INCLUDING WITHOUT LIMITATION, ANY PAYMENT TRANSACTION (EVEN IF WE OR ANY THIRD PARTY PROVIDER RECEIVES A FEE IN CONNECTION THEREWITH) BETWEEN USERS, IN EACH CASE, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THESE LIMITATIONS AND EXCLUSIONS APPLY WITHOUT REGARD TO WHETHER THE DAMAGES ARISE FROM (1) BREACH OF CONTRACT, (2) BREACH OF WARRANTY, (3) STRICT LIABILITY, (4) TORT, (5) NEGLIGENCE, OR (6) ANY OTHER CAUSE OF ACTION, TO THE MAXIMUM EXTENT SUCH EXCLUSION AND LIMITATIONS ARE NOT PROHIBITED BY APPLICABLE LAW.',
                'IF YOU ARE DISSATISFIED WITH THE SITE, YOU DO NOT AGREE WITH ANY PART OF THE TERMS, OR HAVE ANY OTHER DISPUTE OR CLAIM WITH OR AGAINST US, ANY THIRD PARTY PROVIDER OR ANY USER OF THE SITE WITH RESPECT TO THESE TERMS OR THE SITE, THEN YOUR SOLE AND EXCLUSIVE REMEDY AGAINST US IS TO DISCONTINUE USING THE SITE. IN ALL EVENTS, OUR LIABILITY, AND THE LIABILITY OF MYSCHOOL TO YOU OR ANY THIRD PARTY IN ANY CIRCUMSTANCE ARISING OUT OF OR IN CONNECTION WITH THE SITE IS LIMITED TO THE GREATER OF (A) THE AMOUNT OF FEES YOU PAY TO US IN THE TWELVE MONTHS PRIOR TO THE ACTION GIVING RISE TO LIABILITY, OR (B) $100.00 IN THE AGGREGATE FOR ALL CLAIMS. '
            ]
        },
        {
            title: 'Disclaimers',
            list: [
                'THE SITE, INCLUDING ALL CONTENT, SOFTWARE, FUNCTIONS, MATERIALS AND INFORMATION MADE AVAILABLE ON OR ACCESSED THROUGH THE SITE, IS PROVIDED “AS IS.“ TO THE FULLEST EXTENT PERMISSIBLE BY LAW, WE MAKE NO REPRESENTATIONS OR WARRANTIES OF ANY KIND WHATSOEVER FOR THE CONTENT ON THE SITE OR THE MATERIALS, INFORMATION AND FUNCTIONS MADE ACCESSIBLE BY THE SOFTWARE USED ON OR ACCESSED THROUGH THE SITE, FOR ANY PRODUCTS OR SERVICES OR HYPERTEXT LINKS TO THIRD PARTIES OR FOR ANY BREACH OF SECURITY ASSOCIATED WITH THE TRANSMISSION OF SENSITIVE INFORMATION THROUGH THE SITE OR ANY LINKED SITE, EVEN IF WE BECOME AWARE OF ANY SUCH BREACHES. FURTHER, WE EXPRESSLY DISCLAIM ANY EXPRESS OR IMPLIED WARRANTIES, INCLUDING, WITHOUT LIMITATION, NON-INFRINGEMENT, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR ACCURACY. WE DO NOT WARRANT THAT THE FUNCTIONS CONTAINED ON THE SITE OR ANY MATERIALS OR CONTENT CONTAINED THEREIN WILL BE UNINTERRUPTED OR ERROR FREE, THAT DEFECTS WILL BE CORRECTED, OR THAT THE SITE OR THE SERVER THAT MAKES IT AVAILABLE IS FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. YOU ACKNOWLEDGE AND AGREE THAT ANY TRANSMISSION TO AND FROM THIS SITE IS NOT CONFIDENTIAL AND YOUR COMMUNICATIONS OR USER-CONTRIBUTED CONTENT MAY BE READ OR INTERCEPTED BY OTHERS. YOU FURTHER ACKNOWLEDGE AND AGREE THAT BY SUBMITTING COMMUNICATIONS OR USER-CONTRIBUTED CONTENT TO US AND BY POSTING INFORMATION ON THE SITE, NO CONFIDENTIAL, FIDUCIARY, CONTRACTUALLY-IMPLIED OR OTHER RELATIONSHIP IS CREATED BETWEEN YOU AND US OTHER THAN PURSUANT TO THESE TERMS.',
                'MYSCHOOL MAKES NO WARRANTY AS TO THE ACCURACY, COMPLETENESS, OR CURRENCY OF THE CONTENT ON THIS SITE. IT IS YOUR RESPONSIBILITY TO VERIFY ANY INFORMATION BEFORE RELYING ON IT. THE CONTENT OF THIS SITE MAY INCLUDE TECHNICAL INACCURACIES OR TYPOGRAPHICAL ERRORS. FROM TIME TO TIME, CHANGES ARE MADE TO THE CONTENT HEREIN. MYSCHOOL MAY MAKE CHANGES IN THE PRODUCTS AND/OR THE SERVICES DESCRIBED HEREIN AT ANY TIME.',
                'YOU ACKNOWLEDGE AND AGREE THAT YOU WILL NOT HOLD OR SEEK TO HOLD US OR ANY THIRD PARTY PROVIDER RESPONSIBLE FOR THE CONTENT PROVIDED BY ANY USER, INCLUDING, WITHOUT LIMITATION, ANY TRANSLATION THEREOF, AND YOU FURTHER ACKNOWLEDGE AND AGREE THAT WE ARE NOT A PARTY TO ANY TRANSACTION BETWEEN USERS OF THE SITE. WE HAVE NO CONTROL OVER AND DO NOT GUARANTEE (OTHER THAN PURSUANT TO ANY GUARANTEE THAT MAY BE OFFERED ON THE SITE) THE SAFETY OF ANY TRANSACTION, OR THE TRUTH OR ACCURACY OF ANY CONTENT PROVIDED ON THE SITE. '
            ]
        },
        {
            title: 'Release; Indemnification',
            list: [
                'IN THE EVENT THAT YOU HAVE A DISPUTE WITH ONE OR MORE OTHER USERS OF THE SITE OR ANY THIRD PARTY PROVIDER OR ANY THIRD PARTY WEBSITE THAT MAY BE LINKED TO OR FROM OR OTHERWISE INTERACT WITH THE SITE, YOU HEREBY AGREE TO RELEASE, REMISE AND FOREVER DISCHARGE MYSCHOOL, ITS RESPECTIVE AGENTS, MEMBERS, OFFICERS, EMPLOYEES, AND ALL OTHER RELATED PERSONS OR ENTITIES FROM ANY AND ALL MANNER OF RIGHTS, CLAIMS, COMPLAINTS, DEMANDS, CAUSES OF ACTION, PROCEEDINGS, LIABILITIES, OBLIGATIONS, LEGAL FEES, COSTS, AND DISBURSEMENTS OF ANY NATURE WHATSOEVER, WHETHER KNOWN OR UNKNOWN, WHICH NOW OR HEREAFTER ARISE FROM, RELATE TO, OR ARE CONNECTED WITH SUCH DISPUTE AND/OR YOUR USE OF THE SITE.',
                'IF YOU ARE A CALIFORNIA RESIDENT, YOU WAIVE CALIFORNIA CIVIL CODE SECTION 1542, WHICH SAYS: “A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS THAT THE CREDITOR OR RELEASING PARTY DOES NOT KNOW OR SUSPECT TO EXIST IN HIS OR HER FAVOR AT THE TIME OF EXECUTING THE RELEASE AND THAT, IF KNOWN BY HIM OR HER, WOULD HAVE MATERIALLY AFFECTED HIS OR HER SETTLEMENT WITH THE DEBTOR OR RELEASED PARTY.” ',
                'YOU HEREBY AGREE TO INDEMNIFY, DEFEND AND HOLD MYSCHOOL HARMLESS FROM AND AGAINST ANY AND ALL LIABILITY AND COSTS INCURRED BY MYSCHOOL IN CONNECTION WITH ANY CLAIM ARISING OUT OF YOUR USE OF THE SITE OR OTHERWISE RELATING TO THE BUSINESS WE CONDUCT ON THE SITE (INCLUDING, WITHOUT LIMITATION, ANY POTENTIAL OR ACTUAL COMMUNICATION, TRANSACTION OR DISPUTE BETWEEN YOU AND ANY OTHER USER OR THIRD PARTY), ANY CONTENT POSTED BY YOU OR ON YOUR BEHALF OR POSTED BY OTHER USERS OF YOUR ACCOUNT TO THE SITE, ANY USE OF ANY TOOL OR SERVICE PROVIDED BY A THIRD PARTY PROVIDER, ANY USE OF A TOOL OR SERVICE OFFERED BY US THAT INTERACTS WITH A THIRD PARTY WEBSITE, INCLUDING WITHTOUT LIMITATION ANY SOCIAL MEDIA SITE OR ANY BREACH BY YOU OF THESE TERMS OR THE REPRESENTATIONS, WARRANTIES AND COVENANTS MADE BY YOU HEREIN, INCLUDING WITHOUT LIMITATION, ATTORNEYS\' FEES AND COSTS. YOU SHALL COOPERATE AS FULLY AS REASONABLY REQUIRED IN THE DEFENSE OF ANY CLAIM. ',
                'WE RESERVE THE RIGHT, AT OUR OWN EXPENSE, TO ASSUME THE EXCLUSIVE DEFENSE AND CONTROL OF ANY MATTER OTHERWISE SUBJECT TO INDEMNIFICATION BY YOU AND YOU SHALL NOT IN ANY EVENT SETTLE ANY MATTER WITHOUT OUR WRITTEN CONSENT. '
            ]
        },
        {
            title: 'Governing Law; Jurisdiction',
            list: [
                'You agree that these Terms and your use of the Site shall be governed in all respect by the internal substantive laws of the State of Maryland, without regard to any conflict of laws provisions, and shall not be governed by the United Nations Convention on the International Sale of Goods. You further agree to submit to the exclusive jurisdiction and venue in the state and federal courts located in the State of Maryland for all disputes, cases, and controversies regarding this Site, your use of this Site, and your relationship with MySchool. '
            ]
        },
        {
            title: 'Waiver of Jury Trial',
            list: [
                'EACH PARTY HERETO HEREBY WAIVES, TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, ANY RIGHT IT MAY HAVE TO A TRIAL BY JURY IN ANY LEGAL PROCEEDING DIRECTLY OR INDIRECTLY ARISING OUT OF OR RELATING TO THIS SITE OR THE TRANSACTIONS CONTEMPLATED HEREBY (WHETHER BASED ON CONTRACT, TORT OR ANY OTHER THEORY).'
            ]
        },
        {
            title: 'GENERAL',
            list: [
                'Except as explicitly stated otherwise, any notices to us shall be mailed to: MySchool, Attn: Justin Shell, 10807 Falls Rd. #43, Brooklandville, MD 21022.',
                'When we need to send you notice, it will be sent to the email address you provide to the Site during the registration or booking or inquiry process or as later updated in your account (if applicable). Notice shall be deemed given upon receipt or 24 hours after an email is sent, unless the sending party is notified that the email address is invalid. ',
                'We may change, suspend, or discontinue any aspect of the Site at any time, including the availability of any Site features, database, or content. We may also impose limits on certain features or services or restrict your access to parts or the entire Site without notice or liability. This version of the Terms became effective on the date set forth above and this version amends the version effective before such date. We reserve the right, in our sole discretion, to amend these Terms, in whole or in part, at any time. Notification of any amendment will be posted on the Site by the indication of the last amendment date at the top of these Terms and will be effective immediately. ',
                'We may immediately terminate any user’s access to or use of the Site due to such user’s breach of these Terms or any other unauthorized use of the Site. However, we do not guarantee that we will take action against all breaches of these Terms. Our failure to take immediate action with respect to a breach by you or others does not waive our right to act with respect to such breach or any other breach. ',
                'These Terms constitute the entire agreement between us and you with respect to the matters set forth herein, and supersede any prior agreement between us and you with respect to your use of the Site. Headings in these Terms are for reference only and do not limit the scope or extent of such section. In the event of any conflict between these Terms and any other terms and conditions applicable to a product, tool or service offered on our Site, the Terms herein shall prevail. If any portion of these Terms is found to be invalid or unenforceable by any court of competent jurisdiction, the other provisions of these Terms shall remain in full force and effect. Further, any provision of these Terms held invalid or unenforceable only in part or degree will remain in full force and effect to the extent not held invalid or unenforceable.',
                'MySchool’s Site is open to users of every race, color, religion, national origin, ancestry, sex, gender identity, sexual orientation, and marital status. We expect all users to demonstrate respect and tolerance in all interactions with other users—both online and off-line—and we reserve the right to remove anyone from our Site who fails to abide by these principles.',
            ]
        },
        {
            title: 'Appearance in Search Results',
            list: [
                'We cannot guarantee that your listing for Provider Services will appear in any specific order in search results on the Site. Search order will fluctuate based on a variety of factors such as search parameters, listing quality, user preferences, member response time, and other factors that we may deem important to the user experience from time to time. Listing appearance or order in any particular search result may also vary depending on the search criteria used by the particular user. We reserve the right to apply various search algorithms or to use methods to optimize match results for a particular user’s experiences and the overall marketplace. Search results and order may appear different on MySchool’s mobile application than they appear on the Site. To optimize the search experience for users and improve the match process, MySchool retains the right to run occasional tests that will be limited in duration but may alter how we display listings and search results.'
            ]
        },
        {
            title: 'Photographs',
            list: [
                'Photographs may not include children or adults if you do not have their legal consent or any information or images that would violate the privacy rights, intellectual property rights (including but not limited to copyright rights) or any other rights of a third party. We reserve the right to not display or to remove any photographs that we determine, in our sole discretion, do not meet these Terms or are otherwise unacceptable to us.',
                'By submitting a photograph to us, the Provider represents and warrants that (a) it holds all intellectual property rights (including but not limited to all copyright rights), (b) that any people in the photograph have given permission for their likeness to be displayed in an online advertisement on the Site, (c) that the photograph accurately and fairly represents the subject of the photograph and has not been altered in any manner that would mislead a viewer of that photograph, and (d) that it will indemnify and hold harmless the Site and MySchool from any cause of action arising from any misrepresentation with respect to any and all photographs so submitted or in the event of claims that the use of a photograph violates another party’s copyright.',
                'It is the Provider’s responsibility to obtain any and all permissions required to use, post and grant the foregoing rights in all photographic and other material used in its listings. The Provider warrants that it is the owner of the copyright in such material or is authorized by the owner thereof to grant to us the rights therein contained and agrees to provide any proof of such rights to us that we may request. Each Provider further agrees that we may reproduce in whole or in part any photographic material supplied by such Provider in the promotion of either such Provider’s Services or the Site.'
            ]
        },
        {
            title: 'Uses of Our Trademarks or Logos',
            list: [
                'The MySchool name and logo and all related product and service names, design marks and slogans are trademarks, service marks, or registered trademarks of MySchool, Inc. and may not be used in any manner without the prior written consent of MySchool. Other products and service marks are trademarks of their respective owners.'
            ]
        }
    ];
                

    return(
        <Container>
           <h2 className='section-heading'>Terms and Conditions</h2>
            { paragraphs.map((item, i) => 
                <p key={i}className='py-2'>
                    {item}
                </p>
            )} 

            <ol className='pl-2'>
                { list.map((l, i) => 
                    (
                        <li key={i}>
                            <div className='text-bold'>{l.title}</div>
                            <ol className='py-3 pl-4' type="I">
                                { l.list.length > 1 ?
                                    l.list.map((l2, i2)=> 
                                        <li key={i2}>
                                            {l2}
                                        </li>
                                    )
                                    :
                                    l.list.map((l2, i2)=> 
                                        <div key={i2}>
                                            {l2}
                                        </div>
                                    )
                                }
                            </ol>
                        </li>
                    )
                    
                )}
            </ol>
        </Container>
    );
}

export default TermsAndConditions;
