import React, { Fragment, useRef, useState, useEffect } from 'react';
import { Jumbotron, Button, ButtonGroup, Container, Row, Col, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// import HeroSection from './HeroSection/Hero2';
// import RegisterBusinessSection from './RegisterSection/LandingRegisterInvite';
// import FeatureSection from './Features/FeatureFive';
// import ReviewSection from './ReviewSection/ReviewTwo';
// import queryString from 'query-string';
import FeatureProviders from './create-provider-funnel/FeatureProviders';
import ReactGA from 'react-ga';
import { getMyProviders } from "../../actions/provider";

const BusinessLanding = ({auth, getMyProviders, provider}) => {

    useEffect(() => {
        if (auth.isAuthenticated) {
            getMyProviders();
        } 
    }, [auth]);

    const history = useHistory();

    return(
        <Fragment>
            <div className="homepage-5">
                <div className="main">
                    <h2 className='text-center color-myschool-blue mb-4'>
                        Families are looking for more educational options!
                    </h2>
                    <h4 className='text-center color-myschool-blue mb-4'>
                        Create a free profile below so they can find you!
                    </h4>
                    <FeatureProviders provider={provider}/>
                </div>
            </div>
        </Fragment>
    );
}

BusinessLanding.propTypes = {
  auth: PropTypes.object.isRequired,
  getMyProviders: PropTypes.func.isRequired,
  provider: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  provider: state.provider,
});

export default connect(
  mapStateToProps,
  { getMyProviders },
)(BusinessLanding);